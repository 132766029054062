/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    StandKpiType,
    StandKpiTypeFromJSON,
    StandKpiTypeFromJSONTyped,
    StandKpiTypeToJSON,
} from './StandKpiType';

/**
 * 
 * @export
 * @interface DrillingStandDto
 */
export interface DrillingStandDto {
    /**
     * 
     * @type {number}
     * @memberof DrillingStandDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingStandDto
     */
    externalId: number;
    /**
     * 
     * @type {StandKpiType}
     * @memberof DrillingStandDto
     */
    kpiTypeId: StandKpiType;
    /**
     * 
     * @type {boolean}
     * @memberof DrillingStandDto
     */
    isOutlier: boolean;
    /**
     * 
     * @type {DateDto}
     * @memberof DrillingStandDto
     */
    standReferenceDate: DateDto;
    /**
     * 
     * @type {number}
     * @memberof DrillingStandDto
     */
    value: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingStandDto
     */
    standId: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingStandDto
     */
    standNumber: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingStandDto
     */
    targetValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DrillingStandDto
     */
    operationClassId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof DrillingStandDto
     */
    startAt: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof DrillingStandDto
     */
    endAt: DateDto;
    /**
     * 
     * @type {number}
     * @memberof DrillingStandDto
     */
    startDepth: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingStandDto
     */
    endDepth: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingStandDto
     */
    startBitDepth: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingStandDto
     */
    endBitDepth: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingStandDto
     */
    shiftId: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingStandDto
     */
    holeSizeId: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingStandDto
     */
    holeSectionId: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingStandDto
     */
    directionalIntervalId: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingStandDto
     */
    wellId: number;
}

export function DrillingStandDtoFromJSON(json: any): DrillingStandDto {
    return DrillingStandDtoFromJSONTyped(json, false);
}

export function DrillingStandDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrillingStandDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'externalId': json['ExternalId'],
        'kpiTypeId': StandKpiTypeFromJSON(json['KpiTypeId']),
        'isOutlier': json['IsOutlier'],
        'standReferenceDate': DateDtoFromJSON(json['StandReferenceDate']),
        'value': json['Value'],
        'standId': json['StandId'],
        'standNumber': json['StandNumber'],
        'targetValue': !exists(json, 'TargetValue') ? undefined : json['TargetValue'],
        'operationClassId': json['OperationClassId'],
        'startAt': DateDtoFromJSON(json['StartAt']),
        'endAt': DateDtoFromJSON(json['EndAt']),
        'startDepth': json['StartDepth'],
        'endDepth': json['EndDepth'],
        'startBitDepth': json['StartBitDepth'],
        'endBitDepth': json['EndBitDepth'],
        'shiftId': json['ShiftId'],
        'holeSizeId': json['HoleSizeId'],
        'holeSectionId': json['HoleSectionId'],
        'directionalIntervalId': json['DirectionalIntervalId'],
        'wellId': json['WellId'],
    };
}

export function DrillingStandDtoToJSON(value?: DrillingStandDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'ExternalId': value.externalId,
        'KpiTypeId': StandKpiTypeToJSON(value.kpiTypeId),
        'IsOutlier': value.isOutlier,
        'StandReferenceDate': DateDtoToJSON(value.standReferenceDate),
        'Value': value.value,
        'StandId': value.standId,
        'StandNumber': value.standNumber,
        'TargetValue': value.targetValue,
        'OperationClassId': value.operationClassId,
        'StartAt': DateDtoToJSON(value.startAt),
        'EndAt': DateDtoToJSON(value.endAt),
        'StartDepth': value.startDepth,
        'EndDepth': value.endDepth,
        'StartBitDepth': value.startBitDepth,
        'EndBitDepth': value.endBitDepth,
        'ShiftId': value.shiftId,
        'HoleSizeId': value.holeSizeId,
        'HoleSectionId': value.holeSectionId,
        'DirectionalIntervalId': value.directionalIntervalId,
        'WellId': value.wellId,
    };
}

