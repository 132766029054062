/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LensTabWithVisibilityDto,
    LensTabWithVisibilityDtoFromJSON,
    LensTabWithVisibilityDtoFromJSONTyped,
    LensTabWithVisibilityDtoToJSON,
} from './LensTabWithVisibilityDto';

/**
 * 
 * @export
 * @interface DashboardPersonalTabs
 */
export interface DashboardPersonalTabs {
    /**
     * 
     * @type {Array<LensTabWithVisibilityDto>}
     * @memberof DashboardPersonalTabs
     */
    personalTabs: Array<LensTabWithVisibilityDto>;
}

export function DashboardPersonalTabsFromJSON(json: any): DashboardPersonalTabs {
    return DashboardPersonalTabsFromJSONTyped(json, false);
}

export function DashboardPersonalTabsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardPersonalTabs {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'personalTabs': ((json['PersonalTabs'] as Array<any>).map(LensTabWithVisibilityDtoFromJSON)),
    };
}

export function DashboardPersonalTabsToJSON(value?: DashboardPersonalTabs | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PersonalTabs': ((value.personalTabs as Array<any>).map(LensTabWithVisibilityDtoToJSON)),
    };
}

