/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    Int32DecimalFactIndicatorSetDto,
    Int32DecimalFactIndicatorSetDtoFromJSON,
    Int32DecimalFactIndicatorSetDtoFromJSONTyped,
    Int32DecimalFactIndicatorSetDtoToJSON,
} from './Int32DecimalFactIndicatorSetDto';
import {
    ParameterByDepthTrackComparisonWellDto,
    ParameterByDepthTrackComparisonWellDtoFromJSON,
    ParameterByDepthTrackComparisonWellDtoFromJSONTyped,
    ParameterByDepthTrackComparisonWellDtoToJSON,
} from './ParameterByDepthTrackComparisonWellDto';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    ServiceMessageDto,
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import {
    StopWatchDto,
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';

/**
 * 
 * @export
 * @interface ParameterByDepthKpiComparisonDto
 */
export interface ParameterByDepthKpiComparisonDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof ParameterByDepthKpiComparisonDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof ParameterByDepthKpiComparisonDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ParameterByDepthKpiComparisonDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof ParameterByDepthKpiComparisonDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {Array<ParameterByDepthTrackComparisonWellDto>}
     * @memberof ParameterByDepthKpiComparisonDto
     */
    facts: Array<ParameterByDepthTrackComparisonWellDto>;
    /**
     * 
     * @type {Int32DecimalFactIndicatorSetDto}
     * @memberof ParameterByDepthKpiComparisonDto
     */
    indicators: Int32DecimalFactIndicatorSetDto;
    /**
     * 
     * @type {ResultDataState}
     * @memberof ParameterByDepthKpiComparisonDto
     */
    dataState: ResultDataState;
}

export function ParameterByDepthKpiComparisonDtoFromJSON(json: any): ParameterByDepthKpiComparisonDto {
    return ParameterByDepthKpiComparisonDtoFromJSONTyped(json, false);
}

export function ParameterByDepthKpiComparisonDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterByDepthKpiComparisonDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': !exists(json, 'Messages') ? undefined : (json['Messages'] === null ? null : (json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'facts': ((json['Facts'] as Array<any>).map(ParameterByDepthTrackComparisonWellDtoFromJSON)),
        'indicators': Int32DecimalFactIndicatorSetDtoFromJSON(json['Indicators']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function ParameterByDepthKpiComparisonDtoToJSON(value?: ParameterByDepthKpiComparisonDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value.stopWatch),
        'Messages': value.messages === undefined ? undefined : (value.messages === null ? null : (value.messages as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value.userLensId,
        'LastUpdatedAt': DateDtoToJSON(value.lastUpdatedAt),
        'Facts': ((value.facts as Array<any>).map(ParameterByDepthTrackComparisonWellDtoToJSON)),
        'Indicators': Int32DecimalFactIndicatorSetDtoToJSON(value.indicators),
        'DataState': ResultDataStateToJSON(value.dataState),
    };
}

