/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OperationTimeDto
 */
export interface OperationTimeDto {
    /**
     * 
     * @type {number}
     * @memberof OperationTimeDto
     */
    drillingDuration: number;
    /**
     * 
     * @type {number}
     * @memberof OperationTimeDto
     */
    trippingDuration: number;
    /**
     * 
     * @type {number}
     * @memberof OperationTimeDto
     */
    casingDuration: number;
    /**
     * 
     * @type {number}
     * @memberof OperationTimeDto
     */
    otherDuration: number;
    /**
     * 
     * @type {number}
     * @memberof OperationTimeDto
     */
    readonly drillingSplit: number;
    /**
     * 
     * @type {number}
     * @memberof OperationTimeDto
     */
    readonly trippingSplit: number;
    /**
     * 
     * @type {number}
     * @memberof OperationTimeDto
     */
    readonly casingSplit: number;
    /**
     * 
     * @type {number}
     * @memberof OperationTimeDto
     */
    readonly otherSplit: number;
}

export function OperationTimeDtoFromJSON(json: any): OperationTimeDto {
    return OperationTimeDtoFromJSONTyped(json, false);
}

export function OperationTimeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationTimeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'drillingDuration': json['DrillingDuration'],
        'trippingDuration': json['TrippingDuration'],
        'casingDuration': json['CasingDuration'],
        'otherDuration': json['OtherDuration'],
        'drillingSplit': json['DrillingSplit'],
        'trippingSplit': json['TrippingSplit'],
        'casingSplit': json['CasingSplit'],
        'otherSplit': json['OtherSplit'],
    };
}

export function OperationTimeDtoToJSON(value?: OperationTimeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DrillingDuration': value.drillingDuration,
        'TrippingDuration': value.trippingDuration,
        'CasingDuration': value.casingDuration,
        'OtherDuration': value.otherDuration,
    };
}

