/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RigLeaderboardScoreKpiAco,
    RigLeaderboardScoreKpiAcoFromJSON,
    RigLeaderboardScoreKpiAcoFromJSONTyped,
    RigLeaderboardScoreKpiAcoToJSON,
} from './RigLeaderboardScoreKpiAco';

/**
 * 
 * @export
 * @interface RigLeaderboardScoreSubgroupAco
 */
export interface RigLeaderboardScoreSubgroupAco {
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreSubgroupAco
     */
    kpiSubgroupName: string;
    /**
     * 
     * @type {Array<RigLeaderboardScoreKpiAco>}
     * @memberof RigLeaderboardScoreSubgroupAco
     */
    kpis: Array<RigLeaderboardScoreKpiAco>;
}

export function RigLeaderboardScoreSubgroupAcoFromJSON(json: any): RigLeaderboardScoreSubgroupAco {
    return RigLeaderboardScoreSubgroupAcoFromJSONTyped(json, false);
}

export function RigLeaderboardScoreSubgroupAcoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigLeaderboardScoreSubgroupAco {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kpiSubgroupName': json['KpiSubgroupName'],
        'kpis': ((json['Kpis'] as Array<any>).map(RigLeaderboardScoreKpiAcoFromJSON)),
    };
}

export function RigLeaderboardScoreSubgroupAcoToJSON(value?: RigLeaderboardScoreSubgroupAco | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'KpiSubgroupName': value.kpiSubgroupName,
        'Kpis': ((value.kpis as Array<any>).map(RigLeaderboardScoreKpiAcoToJSON)),
    };
}

