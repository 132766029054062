/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccountRigAccessDto,
    AccountRigAccessDtoFromJSON,
    AccountRigAccessDtoFromJSONTyped,
    AccountRigAccessDtoToJSON,
} from './AccountRigAccessDto';

/**
 * 
 * @export
 * @interface AccountRigAccessSetDto
 */
export interface AccountRigAccessSetDto {
    /**
     * 
     * @type {boolean}
     * @memberof AccountRigAccessSetDto
     */
    futureRigAccess: boolean;
    /**
     * 
     * @type {Array<AccountRigAccessDto>}
     * @memberof AccountRigAccessSetDto
     */
    wellAccess?: Array<AccountRigAccessDto> | null;
}

export function AccountRigAccessSetDtoFromJSON(json: any): AccountRigAccessSetDto {
    return AccountRigAccessSetDtoFromJSONTyped(json, false);
}

export function AccountRigAccessSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountRigAccessSetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'futureRigAccess': json['FutureRigAccess'],
        'wellAccess': !exists(json, 'WellAccess') ? undefined : (json['WellAccess'] === null ? null : (json['WellAccess'] as Array<any>).map(AccountRigAccessDtoFromJSON)),
    };
}

export function AccountRigAccessSetDtoToJSON(value?: AccountRigAccessSetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'FutureRigAccess': value.futureRigAccess,
        'WellAccess': value.wellAccess === undefined ? undefined : (value.wellAccess === null ? null : (value.wellAccess as Array<any>).map(AccountRigAccessDtoToJSON)),
    };
}

