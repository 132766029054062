/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorBoundary } from "@sentry/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { BaseWellDto, WellInfoDto } from "apis/oag";
import { UserRoleType, WellsApi, WellStatusType } from "apis/oag";
// TODO ADD MP Tracking
import { AutoComplete, Button, FormItem, Input, Select } from "atoms/Form";
import { toast } from "atoms/toast";
import { Title } from "atoms/Typography";
import CoreLayout, { Section, SectionLayout } from "components/Layout";
import { Loader } from "components/Loader";
import { WebGLSupportBanner } from "components/WebGLSupportBanner/WebGLSupportBanner";
import Map from "components/WellEditor/Map";
import Pane, { PaneDivider } from "components/WellEditor/Pane";
import { useGetBasins } from "hooks/useBasins";
import { useCountries } from "hooks/useCountries";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useFormations } from "hooks/useFormations";
import { useOperators } from "hooks/useOperators";
import { useRigs } from "hooks/useRigs";
import { useWellBasic } from "hooks/useWellBasic";
import { useWellQuickSearch } from "hooks/useWellQuickSearch";
import mapboxgl from "mapbox-gl";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Track, useMixpanel } from "services/Mixpanel";
import styled from "styled-components";
import { apiConfig } from "utils/apiConfig";
import colors from "utils/colors";
import { defaultDateDto } from "utils/common";
import { Col, ConfigProvider, Row, Space } from "utils/componentLibrary";
import { highPrivilegeRoles, statusIconSelector } from "utils/helper";
import { PDQueryType } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";

import { useMergeModal } from "./MergeModal";

const SpaceDiv = styled.div`
  display: flex;
  gap: 8px;
`;

const DashedCircle = styled.div`
  width: 20px;
  height: 20px;
  border: 1px dashed ${colors.gray};
  border-radius: 20px;
`;

const wellsApi = new WellsApi(apiConfig);

type WellValues = {
  id: string;
  wellName: string;
  lat?: number | null;
  lng?: number | null;
  operator?: number | null;
  basin?: number | null;
  formation?: number | null;
  countryId: number;
  state?: string | null;
  county?: string | null;
  rig: number | null;
};

const WellEditor = ({ mode }: { mode?: "create" | "edit" }) => {
  const pageSeen = useRef(false);
  const { viewPage } = useMixpanel();

  const [fields, setFields] = useState<WellValues | null>(null);

  const [hasFeedback, setHasFeedback] = useState(false);

  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const params = useParams<{ wellId: string }>();
  const wellId = useMemo(() => Number(params.wellId), [params.wellId]);

  const wellDetails = useWellBasic(wellId, { enabled: mode === "edit" });
  const formations = useFormations();
  const operators = useOperators();
  const basins = useGetBasins();
  const rigs = useRigs();
  const { data: countries } = useCountries();

  useEffect(() => {
    if (mode === "edit" && !wellDetails.data?.name) return;
    if (viewPage && !pageSeen.current) {
      pageSeen.current = true;
      viewPage(`${(mode || "edit")[0].toUpperCase()}${(mode || "edit").slice(1)} Well`, {
        Mode: `${(mode || "edit")[0].toLocaleUpperCase()}${(mode || "edit").slice(1)}`,
        "Well Name": mode === "edit" ? wellDetails.data.name : "",
      });
    }
  }, [viewPage, mode, wellDetails.data?.name]);

  const [mergeSearchValue, setMergeSearchValue] = useState<string>("");
  const [selectedMergeWell, setSelectedMergeWell] = useState<BaseWellDto | null>(null);
  const mergeSearch = useWellQuickSearch({ query: mergeSearchValue, nonPendingOnly: true });
  const mergeSearchOptions = useMemo(() => {
    if (!mergeSearch.isLoading && mergeSearch.data) {
      return mergeSearch.data.map((e) => ({ value: e.id, label: e.name }));
    }
    return [];
  }, [mergeSearch]);

  const { data: userData } = useCurrentUser();
  const { node, open } = useMergeModal({
    userRole: userData?.role,
    onConfirm: async () => {
      const requestParams = {
        pendingId: wellId,
        targetId: selectedMergeWell?.id || 0,
      };

      if (userData?.role === UserRoleType.Administrator) {
        return wellsApi
          .apiWellsPendingIdMergeIntoTargetIdPut(requestParams)
          .then(() => {
            Track.interact("Edit Well - Merge Well", {
              "Selected Well": selectedMergeWell?.name,
            });
            toast.success({ message: "Well merged successfully!" });
            queryClient.invalidateQueries({ queryKey: [{ wellId, type: PDQueryType.WELL_BASIC }], exact: false });
            navigate(`/well/${selectedMergeWell?.id}/edit`);
          })
          .catch((err) => {
            toast.error({ message: "Error merging well." });
            console.error(err);
          });
      }

      return wellsApi
        .apiWellsPendingIdMergeRequestTargetIdPut(requestParams)
        .then(() => {
          Track.interact("Edit Well - Merge Well", {
            "Selected Well": selectedMergeWell?.name,
          });
          toast.success({ message: "Merge request sent successfully!" });
        })
        .catch((err) => {
          toast.error({ message: "Error sending merge request." });
          console.error(err);
        });
    },
  });
  const isLoading = useMemo(() => {
    return (
      (mode === "edit" ? wellDetails.isLoading : false) ||
      formations.isLoading ||
      operators.isLoading ||
      rigs.isLoading ||
      basins.isLoading
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basins.isLoading, formations.isLoading, operators.isLoading, rigs.isLoading, wellDetails.isLoading]);

  const placePointOnMap = () => {
    if (!fields?.lat || !fields?.lng) {
      return toast.error({
        message: "Invalid Latitude or Longitude",
      });
    }
    const lng = Number(fields?.lng);
    const lat = Number(fields?.lat);
    if (isNaN(lng) || isNaN(lat)) {
      return toast.error({
        message: "Invalid Latitude or Longitude",
      });
    }
    if (lat < -90 || lat > 90) {
      return toast.error({
        message: "Latitude must be between -90 and 90",
      });
    }
    if (lng < -180 || lng > 180) {
      return toast.error({
        message: "Longitude must be between -180 and 180",
      });
    }
    Track.interact("Edit Well - Place Well", {
      Latitude: lat,
      Longitude: lng,
    });
    setFields(
      fields
        ? {
            ...fields,
            lat,
            lng,
          }
        : null,
    );
    pointToAddress(lng, lat);
  };

  const pointToAddress = (lng: number, lat: number) => {
    fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${mapboxgl.accessToken}&types=postcode`,
    )
      .then((e) => e.json())
      .then((e) => {
        if (!e.features || e.features.length === 0) {
          return;
        }

        if (fields) {
          const foundCountry = e.features[0]?.context?.find((e: any) => e.id.indexOf("country") > -1)?.text;
          const foundCountryId = countries.find((e) => e.name === foundCountry)?.id;
          setFields({
            ...fields,
            countryId: foundCountryId ?? fields.countryId,
            state: e.features[0]?.context?.find((e: any) => e.id.indexOf("region") > -1)?.text ?? "",
            county: e.features[0]?.context?.find((e: any) => e.id.indexOf("district") > -1)?.text ?? "",
          });
        }
      });
  };
  const setWellForm = ({ wellDetails }: { wellDetails: WellInfoDto }) => {
    if (!wellDetails) return;
    setFields({
      id: wellDetails.externalId,
      wellName: wellDetails.name,
      operator: wellDetails.operatorId,
      basin: wellDetails.basinId,
      formation: wellDetails.targetFormationId,
      lat: wellDetails.latitude,
      lng: wellDetails.longitude,
      countryId: wellDetails.countryId,
      state: wellDetails.state,
      county: wellDetails.county,
      rig: wellDetails.jobs[0]?.rigId,
    });
  };

  useEffect(() => {
    if (!wellDetails.isLoading && mode === "edit") {
      setWellForm({ wellDetails: wellDetails.data });
    }
  }, [wellDetails.isLoading, wellDetails.data, mode]);

  useEffect(() => {
    if (mode === "create") {
      setFields({
        id: "",
        wellName: "",
        countryId: 1,
        rig: null,
      });
    }
  }, [mode]);

  const updateWellData = useMutation({
    mutationFn: () => {
      if (!fields) throw new Error("Invalid Fields");
      return wellsApi
        .apiWellsPut({
          wellInfoDto: {
            id: wellId,
            externalId: wellDetails.data.externalId,
            name: fields.wellName,
            operatorId: fields.operator ?? -1,
            basinId: fields.basin,
            status: wellDetails.data.status,
            targetFormationId: fields.formation,
            longitude: fields.lng,
            latitude: fields.lat,
            countryId: fields.countryId,
            state: fields.state,
            county: fields.county,
            jobs: [
              {
                externalId: wellDetails.data.jobs[0].externalId,
                id: wellDetails.data.jobs[0].id,
                rigId: Number(fields.rig),
              },
            ],

            // The below are populate from Snowflake directly
            isEverGreen: false,
            isBess: false,

            lastWellFactUpdateAt: defaultDateDto.from,
          },
        })
        .then(() => {
          Track.interact("Edit Well - Update Well");
          toast.success({
            message: "Well details updated!",
          });
        });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [{ wellId, type: PDQueryType.WELL_BASIC }], exact: false });
    },
  });
  const location = useLocation();

  const validateFields = useCallback((fields: WellValues) => {
    const errors: string[] = [];
    if (!fields.wellName) {
      errors.push("Well Name is required");
    }
    if (!fields.operator) {
      errors.push("Well Operator is required");
    }
    if (!fields.rig) {
      errors.push("Rig is required");
    }
    return errors;
  }, []);

  const createWell = () => {
    if (!fields) throw new Error("Invalid Fields");
    const errors = validateFields(fields);
    if (errors.length > 0) {
      setHasFeedback(true);
      return errors.forEach((error) => {
        toast.error({
          message: error,
        });
      });
    }
    wellsApi
      .apiWellsPost({
        wellInfoDto: {
          name: fields.wellName,
          operatorId: fields.operator ?? -1,
          basinId: fields.basin,
          targetFormationId: fields.formation,
          longitude: fields.lng,
          latitude: fields.lat,
          countryId: fields.countryId,
          state: fields.state,
          county: fields.county,
          jobs: [
            {
              rigId: Number(fields.rig),
              externalId: 0,
              id: 0,
            },
          ],
          // TB TODO Are those correct; as far as i know yes because we can not edit an evergreen well
          id: 0,
          status: WellStatusType.Pending,
          externalId: "",
          isEverGreen: false,
          isBess: false,
          lastWellFactUpdateAt: defaultDateDto.from,
        },
      })
      .then((data) => {
        if (data.id) {
          Track.interact("Create Well");
          navigate(`/well/${data.id}/edit`);
          toast.success({
            message: "Well created successfully!",
          });
        }
      })
      .catch(() => {
        return toast.error({
          message: "Something went wrong.",
        });
      });
  };

  const updateWell = () => {
    if (!fields) throw new Error("Invalid Fields");
    const errors = validateFields(fields);
    if (errors.length > 0) {
      setHasFeedback(true);
      return errors.forEach((error) => {
        toast.error({
          message: error,
        });
      });
    }
    updateWellData.mutate();
  };

  const onCancel = () => {
    if (mode === "edit") {
      Track.clickEvent("Edit Well - Cancel");
      if (location.pathname.includes("well")) navigate(`/well/${wellId}`);
      else navigate(`/evergreen/${wellId}`);
    } else {
      Track.clickEvent("Create Well - Cancel");
      navigate(`/`);
    }
  };

  const uniqueRigIdsLabel = useMemo(() => {
    const uniqueRigIds = [...new Set((wellDetails?.data?.jobs ?? []).map((e) => e.rigId))];
    return uniqueRigIds.map((e) => rigs?.data?.byId[e]?.shortName || "").join(", ");
  }, [wellDetails?.data?.jobs, rigs?.data]);

  const disableFields = useMemo(() => !!wellDetails.data?.externalId, [wellDetails.data]);
  const { atomThemeVariant, themeStyle } = useCustomTheme();

  return (
    <ConfigProvider
      renderEmpty={() => <span style={{ color: themeStyle.colors.primary_typography }}> No results </span>}
    >
      <CoreLayout>
        <SectionLayout
          header={
            <Section>
              <Row justify="space-between" align="middle">
                <Col flex="0 auto">
                  <Title level={3} variant={atomThemeVariant}>
                    {mode === "create" ? "Create" : "Edit"} Well
                  </Title>
                </Col>
                <Col flex="0 auto">
                  <Space>
                    <Button block onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button type="primary" block onClick={() => (mode === "create" ? createWell() : updateWell())}>
                      {mode === "create" ? "Create" : "Update"}
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Section>
          }
        >
          <Section
            style={{
              padding: "40px",
              background: themeStyle.colors.alt_tertiary_bg,
            }}
          >
            {fields === null ? (
              <Loader withWrapper centered />
            ) : (
              <div>
                <Row gutter={[16, 16]}>
                  <Col span={16}>
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <Pane title="Main Information" secondary={mode === "edit" ? `#${wellId}` : ""}>
                          <Row gutter={[12, 12]}>
                            <Col span={24}>
                              <Row gutter={8} align="middle">
                                <Col span={4}>
                                  <Title level={3} variant={atomThemeVariant}>
                                    Well Name*
                                  </Title>
                                </Col>
                                <Col span={20}>
                                  <FormItem validateStatus={!hasFeedback || fields?.wellName ? undefined : "error"}>
                                    <Input
                                      placeholder={isLoading ? "Loading..." : "Well Name"}
                                      disabled={disableFields}
                                      value={fields?.wellName}
                                      onChange={(e) => setFields({ ...fields, wellName: e.target.value })}
                                    />
                                  </FormItem>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={24}>
                              <Row gutter={8} align="middle">
                                <Col span={4}>
                                  <Title level={3} variant={atomThemeVariant}>
                                    Operator*
                                  </Title>
                                </Col>
                                <Col span={20}>
                                  <FormItem validateStatus={!hasFeedback || fields?.operator ? undefined : "error"}>
                                    {!isLoading ? (
                                      <Select
                                        disabled={disableFields}
                                        placeholder={isLoading ? "Loading..." : "Operator"}
                                        options={operators.data.list.map((e) => ({ value: e.id, label: e.name }))}
                                        showSearch
                                        optionFilterProp="label"
                                        value={fields?.operator}
                                        onChange={(e) => setFields({ ...fields, operator: e as number })}
                                      />
                                    ) : null}
                                  </FormItem>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={24}>
                              <Row gutter={8} align="middle">
                                <Col span={4}>
                                  <Title level={3} variant={atomThemeVariant}>
                                    Basin
                                  </Title>
                                </Col>
                                <Col span={20}>
                                  <FormItem>
                                    {!isLoading ? (
                                      <Select
                                        allowClear
                                        removeIcon={false}
                                        placeholder={"Rift"}
                                        options={basins.data
                                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                                          .map((basin) => ({
                                            value: basin.id,
                                            label: basin.name,
                                          }))}
                                        showSearch
                                        optionFilterProp="label"
                                        value={fields?.basin}
                                        onChange={(e) => setFields({ ...fields, basin: e as number })}
                                      />
                                    ) : null}
                                  </FormItem>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={24}>
                              <Row gutter={8} align="middle">
                                <Col span={4}>
                                  <Title level={3} variant={atomThemeVariant}>
                                    Target Formation
                                  </Title>
                                </Col>
                                <Col span={20}>
                                  <FormItem>
                                    {!isLoading ? (
                                      <Select
                                        allowClear
                                        placeholder={"Target Formation"}
                                        options={formations?.data?.list
                                          .sort((a, b) => ((a?.name || "") > (b?.name || "") ? 1 : -1))
                                          .map((formation) => ({
                                            value: formation.id,
                                            label: formation.name,
                                          }))}
                                        showSearch
                                        optionFilterProp="label"
                                        value={fields?.formation}
                                        onChange={(e) => setFields({ ...fields, formation: e as number })}
                                      />
                                    ) : null}
                                  </FormItem>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Pane>
                      </Col>
                      <Col span={24}>
                        <Pane title="Well Location">
                          <Row>
                            <Col span={24}>
                              <Row gutter={8}>
                                <Col span={10}>
                                  <FormItem>
                                    <Input
                                      placeholder={isLoading ? "Loading..." : "Latitude (eg: 37.8001)"}
                                      onKeyDown={(event) => event.keyCode === 69 && event.preventDefault()}
                                      type="number"
                                      disabled={disableFields}
                                      min={-90}
                                      max={90}
                                      value={fields?.lat || ""}
                                      onChange={(e) => setFields({ ...fields, lat: Number(e.target.value) })}
                                    />
                                  </FormItem>
                                </Col>
                                <Col span={10}>
                                  <FormItem>
                                    <Input
                                      placeholder={isLoading ? "Loading..." : "Longitude  (eg: -96.0022)"}
                                      onKeyDown={(event) => event.keyCode === 69 && event.preventDefault()}
                                      disabled={disableFields}
                                      type="number"
                                      min={-180}
                                      max={180}
                                      value={fields?.lng || ""}
                                      onChange={(e) => setFields({ ...fields, lng: Number(e.target.value) })}
                                    />
                                  </FormItem>
                                </Col>
                                <Col span={4}>
                                  <Button type="primary" disabled={disableFields} block onClick={placePointOnMap}>
                                    Place
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={24}>
                              <PaneDivider />
                              <ErrorBoundary fallback={<WebGLSupportBanner />}>
                                {!fields?.lat || !fields?.lng ? (
                                  <Map key={`empty-map`} />
                                ) : (
                                  <Map
                                    lng={fields.lng && fields.lng >= -180 && fields.lng <= 180 ? fields.lng : undefined}
                                    lat={fields.lat && fields.lat >= -90 && fields.lat <= 90 ? fields.lat : undefined}
                                  />
                                )}
                              </ErrorBoundary>
                              <PaneDivider />
                            </Col>
                            <Col span={24}>
                              <Row gutter={[12, 12]}>
                                <Col span={24}>
                                  <Row gutter={8} align="middle">
                                    <Col span={4}>
                                      <Title level={3} variant={atomThemeVariant}>
                                        Country
                                      </Title>
                                    </Col>
                                    <Col span={20}>
                                      <FormItem>
                                        <Select
                                          disabled={disableFields}
                                          placeholder={isLoading ? "Loading..." : "Country"}
                                          style={{ width: "100%" }}
                                          options={(countries ?? []).map((country) => ({
                                            value: country.id,
                                            label: country.name,
                                          }))}
                                          showSearch
                                          optionFilterProp="label"
                                          value={fields?.countryId}
                                          onChange={(e) => setFields({ ...fields, countryId: e as number })}
                                        />
                                      </FormItem>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col span={24}>
                                  <Row gutter={8} align="middle">
                                    <Col span={4}>
                                      <Title level={3} variant={atomThemeVariant}>
                                        State / Province
                                      </Title>
                                    </Col>
                                    <Col span={20}>
                                      <FormItem>
                                        <Input
                                          disabled={disableFields}
                                          placeholder={isLoading ? "Loading..." : "State / Province"}
                                          value={fields?.state || ""}
                                          onChange={(e) => setFields({ ...fields, state: e.target.value })}
                                        />
                                      </FormItem>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col span={24}>
                                  <Row gutter={8} align="middle">
                                    <Col span={4}>
                                      <Title level={3} variant={atomThemeVariant}>
                                        County
                                      </Title>
                                    </Col>
                                    <Col span={20}>
                                      <FormItem>
                                        <Input
                                          disabled={disableFields}
                                          placeholder={isLoading ? "Loading..." : "County"}
                                          value={fields?.county || ""}
                                          onChange={(e) => setFields({ ...fields, county: e.target.value })}
                                        />
                                      </FormItem>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Pane>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <Pane title="Well Status">
                          {isLoading ? "Loading..." : null}
                          {!isLoading ? (
                            <Title level={3} variant={atomThemeVariant}>
                              <Space>
                                <span>{statusIconSelector(wellDetails?.data?.status || WellStatusType.Pending)}</span>
                                <span>{wellDetails?.data?.status || "Pending"}</span>
                              </Space>
                            </Title>
                          ) : null}
                        </Pane>
                      </Col>
                      <Col span={24}>
                        <Pane title="Rig(s)*">
                          {!isLoading ? (
                            <FormItem
                              validateStatus={!hasFeedback || fields?.rig ? undefined : "error"}
                              style={{ color: themeStyle.colors.primary_typography }}
                            >
                              {!disableFields ? (
                                <Select
                                  placeholder={isLoading ? "Loading..." : "Rigs"}
                                  style={{ width: "100%" }}
                                  options={(rigs.data?.list ?? []).map((e) => ({ value: e.id, label: e.externalId }))}
                                  value={fields?.rig}
                                  onChange={(e) => setFields({ ...fields, rig: e as number })}
                                />
                              ) : (
                                uniqueRigIdsLabel
                              )}
                            </FormItem>
                          ) : null}
                        </Pane>
                      </Col>
                      {(!wellDetails.data || wellDetails.data?.status === WellStatusType.Pending) &&
                      userData?.role &&
                      highPrivilegeRoles.includes(userData?.role) ? (
                        <>
                          <Col span={24}>
                            <Pane title="Merge with System Well ID">
                              {mode === "edit" ? (
                                <SpaceDiv>
                                  <AutoComplete
                                    allowClear
                                    options={mergeSearchOptions}
                                    placeholder="Search by Well"
                                    style={{ width: "100%" }}
                                    onChange={(e) => setMergeSearchValue(e as string)}
                                    onSelect={(e) => {
                                      const well = mergeSearch.data?.find(
                                        (i) => i.id.toString() === (e as number).toString(),
                                      );
                                      if (well && well.name) {
                                        setSelectedMergeWell(well);
                                        setMergeSearchValue(well.name);
                                      }
                                    }}
                                    value={mergeSearchValue}
                                    popupClassName="all-wells-autocomplete"
                                  />
                                  <Button
                                    size="large"
                                    type="primary"
                                    onClick={() => {
                                      open();
                                    }}
                                  >
                                    {userData?.role === UserRoleType.Administrator ? "Merge" : "Request Merge"}
                                  </Button>
                                </SpaceDiv>
                              ) : (
                                <Space>
                                  <DashedCircle />
                                  <Title level={3} variant="faded">
                                    Create the well first before merging it.
                                  </Title>
                                </Space>
                              )}
                            </Pane>
                          </Col>
                          <Col span={24}>
                            <Title level={3} variant="faded">
                              Upon submitting a Merge request, an email will be sent to a clarity admin to evaluate the
                              request.
                            </Title>
                          </Col>
                        </>
                      ) : (
                        wellDetails.data?.externalId && (
                          <Col span={24}>
                            <Pane title="System Well ID">
                              <Title level={3} variant={atomThemeVariant}>
                                {wellDetails.data.externalId}
                              </Title>
                            </Pane>
                          </Col>
                        )
                      )}
                    </Row>
                  </Col>
                </Row>
              </div>
            )}
          </Section>
        </SectionLayout>
      </CoreLayout>
      {node}
    </ConfigProvider>
  );
};

export default WellEditor;
