/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RigStateType = {
    Rih: 'Rih',
    RihRotate: 'RihRotate',
    Pooh: 'Pooh',
    PoohRotate: 'PoohRotate',
    ReamUp: 'ReamUp',
    ReamDown: 'ReamDown',
    WashIn: 'WashIn',
    WashOut: 'WashOut',
    RotatingOnly: 'RotatingOnly',
    Circulating: 'Circulating',
    CirculatingWithRotation: 'CirculatingWithRotation',
    RotateDrill: 'RotateDrill',
    SlideDrill: 'SlideDrill',
    InSlips: 'InSlips',
    SlipAndCut: 'SlipAndCut',
    Static: 'Static',
    OutOfHole: 'OutOfHole',
    DataGap: 'DataGap',
    InsufficientData: 'InsufficientData',
    Unknown: 'Unknown'
} as const;
export type RigStateType = typeof RigStateType[keyof typeof RigStateType];


export function RigStateTypeFromJSON(json: any): RigStateType {
    return RigStateTypeFromJSONTyped(json, false);
}

export function RigStateTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigStateType {
    return json as RigStateType;
}

export function RigStateTypeToJSON(value?: RigStateType | null): any {
    return value as any;
}

