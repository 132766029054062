/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ShiftType = {
    Day: 'Day',
    Night: 'Night'
} as const;
export type ShiftType = typeof ShiftType[keyof typeof ShiftType];


export function ShiftTypeFromJSON(json: any): ShiftType {
    return ShiftTypeFromJSONTyped(json, false);
}

export function ShiftTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShiftType {
    return json as ShiftType;
}

export function ShiftTypeToJSON(value?: ShiftType | null): any {
    return value as any;
}

