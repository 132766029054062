/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    DimensionType,
    DimensionTypeFromJSON,
    DimensionTypeFromJSONTyped,
    DimensionTypeToJSON,
} from './DimensionType';
import {
    PivotType,
    PivotTypeFromJSON,
    PivotTypeFromJSONTyped,
    PivotTypeToJSON,
} from './PivotType';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    RigCardAverages,
    RigCardAveragesFromJSON,
    RigCardAveragesFromJSONTyped,
    RigCardAveragesToJSON,
} from './RigCardAverages';
import {
    RigCardFactDto,
    RigCardFactDtoFromJSON,
    RigCardFactDtoFromJSONTyped,
    RigCardFactDtoToJSON,
} from './RigCardFactDto';
import {
    ServiceMessageDto,
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import {
    StandKpiType,
    StandKpiTypeFromJSON,
    StandKpiTypeFromJSONTyped,
    StandKpiTypeToJSON,
} from './StandKpiType';
import {
    StopWatchDto,
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';

/**
 * 
 * @export
 * @interface RigCardFactSeriesDto
 */
export interface RigCardFactSeriesDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof RigCardFactSeriesDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof RigCardFactSeriesDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardFactSeriesDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof RigCardFactSeriesDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {ResultDataState}
     * @memberof RigCardFactSeriesDto
     */
    dataState: ResultDataState;
    /**
     * 
     * @type {DimensionType}
     * @memberof RigCardFactSeriesDto
     */
    dimension: DimensionType;
    /**
     * 
     * @type {PivotType}
     * @memberof RigCardFactSeriesDto
     */
    pivot: PivotType;
    /**
     * 
     * @type {StandKpiType}
     * @memberof RigCardFactSeriesDto
     */
    kpiType: StandKpiType;
    /**
     * 
     * @type {RigCardAverages}
     * @memberof RigCardFactSeriesDto
     */
    averages: RigCardAverages;
    /**
     * 
     * @type {Array<RigCardFactDto>}
     * @memberof RigCardFactSeriesDto
     */
    facts: Array<RigCardFactDto>;
}

export function RigCardFactSeriesDtoFromJSON(json: any): RigCardFactSeriesDto {
    return RigCardFactSeriesDtoFromJSONTyped(json, false);
}

export function RigCardFactSeriesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigCardFactSeriesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': !exists(json, 'Messages') ? undefined : (json['Messages'] === null ? null : (json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
        'dimension': DimensionTypeFromJSON(json['Dimension']),
        'pivot': PivotTypeFromJSON(json['Pivot']),
        'kpiType': StandKpiTypeFromJSON(json['KpiType']),
        'averages': RigCardAveragesFromJSON(json['Averages']),
        'facts': ((json['Facts'] as Array<any>).map(RigCardFactDtoFromJSON)),
    };
}

export function RigCardFactSeriesDtoToJSON(value?: RigCardFactSeriesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value.stopWatch),
        'Messages': value.messages === undefined ? undefined : (value.messages === null ? null : (value.messages as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value.userLensId,
        'LastUpdatedAt': DateDtoToJSON(value.lastUpdatedAt),
        'DataState': ResultDataStateToJSON(value.dataState),
        'Dimension': DimensionTypeToJSON(value.dimension),
        'Pivot': PivotTypeToJSON(value.pivot),
        'KpiType': StandKpiTypeToJSON(value.kpiType),
        'Averages': RigCardAveragesToJSON(value.averages),
        'Facts': ((value.facts as Array<any>).map(RigCardFactDtoToJSON)),
    };
}

