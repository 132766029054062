/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlanFormationDto
 */
export interface PlanFormationDto {
    /**
     * 
     * @type {number}
     * @memberof PlanFormationDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof PlanFormationDto
     */
    formationId: number;
    /**
     * 
     * @type {number}
     * @memberof PlanFormationDto
     */
    measuredDepth: number;
    /**
     * 
     * @type {number}
     * @memberof PlanFormationDto
     */
    trueVerticalDepth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanFormationDto
     */
    porePressure?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanFormationDto
     */
    fracturePressure?: number | null;
}

export function PlanFormationDtoFromJSON(json: any): PlanFormationDto {
    return PlanFormationDtoFromJSONTyped(json, false);
}

export function PlanFormationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanFormationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'formationId': json['FormationId'],
        'measuredDepth': json['MeasuredDepth'],
        'trueVerticalDepth': !exists(json, 'TrueVerticalDepth') ? undefined : json['TrueVerticalDepth'],
        'porePressure': !exists(json, 'PorePressure') ? undefined : json['PorePressure'],
        'fracturePressure': !exists(json, 'FracturePressure') ? undefined : json['FracturePressure'],
    };
}

export function PlanFormationDtoToJSON(value?: PlanFormationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'FormationId': value.formationId,
        'MeasuredDepth': value.measuredDepth,
        'TrueVerticalDepth': value.trueVerticalDepth,
        'PorePressure': value.porePressure,
        'FracturePressure': value.fracturePressure,
    };
}

