/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OutlierFlaggingType,
    OutlierFlaggingTypeFromJSON,
    OutlierFlaggingTypeFromJSONTyped,
    OutlierFlaggingTypeToJSON,
} from './OutlierFlaggingType';
import {
    StandKpiType,
    StandKpiTypeFromJSON,
    StandKpiTypeFromJSONTyped,
    StandKpiTypeToJSON,
} from './StandKpiType';
import {
    TimeUnit,
    TimeUnitFromJSON,
    TimeUnitFromJSONTyped,
    TimeUnitToJSON,
} from './TimeUnit';
import {
    VisualAidType,
    VisualAidTypeFromJSON,
    VisualAidTypeFromJSONTyped,
    VisualAidTypeToJSON,
} from './VisualAidType';

/**
 * 
 * @export
 * @interface KpiTypeUserLensDto
 */
export interface KpiTypeUserLensDto {
    /**
     * 
     * @type {number}
     * @memberof KpiTypeUserLensDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof KpiTypeUserLensDto
     */
    lensTabId: number;
    /**
     * 
     * @type {number}
     * @memberof KpiTypeUserLensDto
     */
    lensTemplateId: number;
    /**
     * 
     * @type {boolean}
     * @memberof KpiTypeUserLensDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KpiTypeUserLensDto
     */
    label: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KpiTypeUserLensDto
     */
    isSystem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KpiTypeUserLensDto
     */
    squeezesDisplay: boolean;
    /**
     * 
     * @type {TimeUnit}
     * @memberof KpiTypeUserLensDto
     */
    selectedTimeUnit: TimeUnit;
    /**
     * 
     * @type {boolean}
     * @memberof KpiTypeUserLensDto
     */
    isManualYaxis: boolean;
    /**
     * 
     * @type {number}
     * @memberof KpiTypeUserLensDto
     */
    yaxisStart?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KpiTypeUserLensDto
     */
    yaxisEnd?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof KpiTypeUserLensDto
     */
    showsOutliers: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KpiTypeUserLensDto
     */
    showOperationCount: boolean;
    /**
     * 
     * @type {OutlierFlaggingType}
     * @memberof KpiTypeUserLensDto
     */
    outlierFlaggingType: OutlierFlaggingType;
    /**
     * 
     * @type {number}
     * @memberof KpiTypeUserLensDto
     */
    outlierMin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KpiTypeUserLensDto
     */
    outlierMax?: number | null;
    /**
     * 
     * @type {Array<VisualAidType>}
     * @memberof KpiTypeUserLensDto
     */
    selectedVisualAids: Array<VisualAidType>;
    /**
     * 
     * @type {StandKpiType}
     * @memberof KpiTypeUserLensDto
     */
    kpiTypeId: StandKpiType;
    /**
     * 
     * @type {boolean}
     * @memberof KpiTypeUserLensDto
     */
    showStandKpiComments: boolean;
}

export function KpiTypeUserLensDtoFromJSON(json: any): KpiTypeUserLensDto {
    return KpiTypeUserLensDtoFromJSONTyped(json, false);
}

export function KpiTypeUserLensDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): KpiTypeUserLensDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'lensTabId': json['LensTabId'],
        'lensTemplateId': json['LensTemplateId'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'label': json['Label'],
        'isSystem': json['IsSystem'],
        'squeezesDisplay': json['SqueezesDisplay'],
        'selectedTimeUnit': TimeUnitFromJSON(json['SelectedTimeUnit']),
        'isManualYaxis': json['IsManualYaxis'],
        'yaxisStart': !exists(json, 'YaxisStart') ? undefined : json['YaxisStart'],
        'yaxisEnd': !exists(json, 'YaxisEnd') ? undefined : json['YaxisEnd'],
        'showsOutliers': json['ShowsOutliers'],
        'showOperationCount': json['ShowOperationCount'],
        'outlierFlaggingType': OutlierFlaggingTypeFromJSON(json['OutlierFlaggingType']),
        'outlierMin': !exists(json, 'OutlierMin') ? undefined : json['OutlierMin'],
        'outlierMax': !exists(json, 'OutlierMax') ? undefined : json['OutlierMax'],
        'selectedVisualAids': ((json['SelectedVisualAids'] as Array<any>).map(VisualAidTypeFromJSON)),
        'kpiTypeId': StandKpiTypeFromJSON(json['KpiTypeId']),
        'showStandKpiComments': json['ShowStandKpiComments'],
    };
}

export function KpiTypeUserLensDtoToJSON(value?: KpiTypeUserLensDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'LensTabId': value.lensTabId,
        'LensTemplateId': value.lensTemplateId,
        'IsAvailableOnReports': value.isAvailableOnReports,
        'Label': value.label,
        'IsSystem': value.isSystem,
        'SqueezesDisplay': value.squeezesDisplay,
        'SelectedTimeUnit': TimeUnitToJSON(value.selectedTimeUnit),
        'IsManualYaxis': value.isManualYaxis,
        'YaxisStart': value.yaxisStart,
        'YaxisEnd': value.yaxisEnd,
        'ShowsOutliers': value.showsOutliers,
        'ShowOperationCount': value.showOperationCount,
        'OutlierFlaggingType': OutlierFlaggingTypeToJSON(value.outlierFlaggingType),
        'OutlierMin': value.outlierMin,
        'OutlierMax': value.outlierMax,
        'SelectedVisualAids': ((value.selectedVisualAids as Array<any>).map(VisualAidTypeToJSON)),
        'KpiTypeId': StandKpiTypeToJSON(value.kpiTypeId),
        'ShowStandKpiComments': value.showStandKpiComments,
    };
}

