/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PlanActivityDto,
    PlanActivityDtoFromJSON,
    PlanActivityDtoFromJSONTyped,
    PlanActivityDtoToJSON,
} from './PlanActivityDto';

/**
 * 
 * @export
 * @interface PlanActivitySetDto
 */
export interface PlanActivitySetDto {
    /**
     * 
     * @type {number}
     * @memberof PlanActivitySetDto
     */
    startDepth: number;
    /**
     * 
     * @type {Array<PlanActivityDto>}
     * @memberof PlanActivitySetDto
     */
    activities: Array<PlanActivityDto>;
}

export function PlanActivitySetDtoFromJSON(json: any): PlanActivitySetDto {
    return PlanActivitySetDtoFromJSONTyped(json, false);
}

export function PlanActivitySetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanActivitySetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startDepth': json['StartDepth'],
        'activities': ((json['Activities'] as Array<any>).map(PlanActivityDtoFromJSON)),
    };
}

export function PlanActivitySetDtoToJSON(value?: PlanActivitySetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StartDepth': value.startDepth,
        'Activities': ((value.activities as Array<any>).map(PlanActivityDtoToJSON)),
    };
}

