/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PlanDrillingParameterDto,
    PlanDrillingParameterDtoFromJSON,
    PlanDrillingParameterDtoFromJSONTyped,
    PlanDrillingParameterDtoToJSON,
} from './PlanDrillingParameterDto';

/**
 * 
 * @export
 * @interface PlanDrillingParameterSetDto
 */
export interface PlanDrillingParameterSetDto {
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterSetDto
     */
    startDepth: number;
    /**
     * 
     * @type {Array<PlanDrillingParameterDto>}
     * @memberof PlanDrillingParameterSetDto
     */
    drillingParameters: Array<PlanDrillingParameterDto>;
}

export function PlanDrillingParameterSetDtoFromJSON(json: any): PlanDrillingParameterSetDto {
    return PlanDrillingParameterSetDtoFromJSONTyped(json, false);
}

export function PlanDrillingParameterSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanDrillingParameterSetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startDepth': json['StartDepth'],
        'drillingParameters': ((json['DrillingParameters'] as Array<any>).map(PlanDrillingParameterDtoFromJSON)),
    };
}

export function PlanDrillingParameterSetDtoToJSON(value?: PlanDrillingParameterSetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StartDepth': value.startDepth,
        'DrillingParameters': ((value.drillingParameters as Array<any>).map(PlanDrillingParameterDtoToJSON)),
    };
}

