/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OutliersFilteringType,
    OutliersFilteringTypeFromJSON,
    OutliersFilteringTypeFromJSONTyped,
    OutliersFilteringTypeToJSON,
} from './OutliersFilteringType';

/**
 * 
 * @export
 * @interface ParameterHeatmapTrackUserLensDto
 */
export interface ParameterHeatmapTrackUserLensDto {
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapTrackUserLensDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapTrackUserLensDto
     */
    trackId: number;
    /**
     * 
     * @type {OutliersFilteringType}
     * @memberof ParameterHeatmapTrackUserLensDto
     */
    outliersType: OutliersFilteringType;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapTrackUserLensDto
     */
    outliersMinPercentile?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapTrackUserLensDto
     */
    outliersMaxPercentile?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapTrackUserLensDto
     */
    outliersMinValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapTrackUserLensDto
     */
    outliersMaxValue?: number | null;
}

export function ParameterHeatmapTrackUserLensDtoFromJSON(json: any): ParameterHeatmapTrackUserLensDto {
    return ParameterHeatmapTrackUserLensDtoFromJSONTyped(json, false);
}

export function ParameterHeatmapTrackUserLensDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterHeatmapTrackUserLensDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'trackId': json['TrackId'],
        'outliersType': OutliersFilteringTypeFromJSON(json['OutliersType']),
        'outliersMinPercentile': !exists(json, 'OutliersMinPercentile') ? undefined : json['OutliersMinPercentile'],
        'outliersMaxPercentile': !exists(json, 'OutliersMaxPercentile') ? undefined : json['OutliersMaxPercentile'],
        'outliersMinValue': !exists(json, 'OutliersMinValue') ? undefined : json['OutliersMinValue'],
        'outliersMaxValue': !exists(json, 'OutliersMaxValue') ? undefined : json['OutliersMaxValue'],
    };
}

export function ParameterHeatmapTrackUserLensDtoToJSON(value?: ParameterHeatmapTrackUserLensDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'TrackId': value.trackId,
        'OutliersType': OutliersFilteringTypeToJSON(value.outliersType),
        'OutliersMinPercentile': value.outliersMinPercentile,
        'OutliersMaxPercentile': value.outliersMaxPercentile,
        'OutliersMinValue': value.outliersMinValue,
        'OutliersMaxValue': value.outliersMaxValue,
    };
}

