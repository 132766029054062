/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OperationCategoryType,
    OperationCategoryTypeFromJSON,
    OperationCategoryTypeFromJSONTyped,
    OperationCategoryTypeToJSON,
} from './OperationCategoryType';
import {
    WedgeBreakdownSlice,
    WedgeBreakdownSliceFromJSON,
    WedgeBreakdownSliceFromJSONTyped,
    WedgeBreakdownSliceToJSON,
} from './WedgeBreakdownSlice';

/**
 * 
 * @export
 * @interface WedgeBreakdownDto
 */
export interface WedgeBreakdownDto {
    /**
     * 
     * @type {number}
     * @memberof WedgeBreakdownDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof WedgeBreakdownDto
     */
    name?: string | null;
    /**
     * 
     * @type {OperationCategoryType}
     * @memberof WedgeBreakdownDto
     */
    operationCategory: OperationCategoryType;
    /**
     * 
     * @type {Array<WedgeBreakdownSlice>}
     * @memberof WedgeBreakdownDto
     */
    slices?: Array<WedgeBreakdownSlice> | null;
}

export function WedgeBreakdownDtoFromJSON(json: any): WedgeBreakdownDto {
    return WedgeBreakdownDtoFromJSONTyped(json, false);
}

export function WedgeBreakdownDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WedgeBreakdownDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': !exists(json, 'Name') ? undefined : json['Name'],
        'operationCategory': OperationCategoryTypeFromJSON(json['OperationCategory']),
        'slices': !exists(json, 'Slices') ? undefined : (json['Slices'] === null ? null : (json['Slices'] as Array<any>).map(WedgeBreakdownSliceFromJSON)),
    };
}

export function WedgeBreakdownDtoToJSON(value?: WedgeBreakdownDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'Name': value.name,
        'OperationCategory': OperationCategoryTypeToJSON(value.operationCategory),
        'Slices': value.slices === undefined ? undefined : (value.slices === null ? null : (value.slices as Array<any>).map(WedgeBreakdownSliceToJSON)),
    };
}

