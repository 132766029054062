import { useEffect, useState } from "react";

import { useDirectionalIntervals } from "./useDirectionalIntervals";
import { useHoleSections } from "./useHoleSections";
import { useHoleSizes } from "./useHoleSizes";
import { useReferenceFilters } from "./useReferenceFilters";

export const useFiltersLoading = () => {
  const [filtersLoading, setFiltersLoading] = useState(false);
  const { data: holeSections, isLoading: isLoadingHoleSections } = useHoleSections();
  const { data: holeSizes, isLoading: isLoadingHoleSizes } = useHoleSizes();
  const { data: directionalIntervals, isLoading: isLoadingDirectionalIntervals } = useDirectionalIntervals();
  const { data: availableFilters, isLoading: isLoadingAvailableFilters } = useReferenceFilters();

  useEffect(() => {
    setFiltersLoading(
      isLoadingAvailableFilters || isLoadingHoleSections || isLoadingHoleSizes || isLoadingDirectionalIntervals,
    );
  }, [isLoadingAvailableFilters, isLoadingDirectionalIntervals, isLoadingHoleSections, isLoadingHoleSizes]);

  return {
    filtersLoading,
    holeSections,
    holeSizes,
    directionalIntervals,
    availableFilters,
  };
};
