import ButtonGroup from "antd/lib/button/button-group";
import { PDComponent } from "components/PDComponents";
import { useCurrentUser } from "hooks/useCurrentUser";
import {
  LeaderboardMetricSelectionContext,
  NetTimeType,
  ScoreType,
  SelectedMetric,
} from "pages/AllRigs/components/LeaderboardSection/LeaderboardMetricSelectionContext";
import { useCallback, useContext, useMemo } from "react";
import { Track } from "services/Mixpanel";
import { Dropdown } from "utils/componentLibrary";

import * as Styled from "./StyledComponents";
import { DROPDOWN_WIDTH } from "./StyledComponents";

export const ScoreSelector = () => {
  const { selectedMetric, setSelectedMetric, setSelectedNetTime, selectedNetTime, selectedScore, setSelectedScore } =
    useContext(LeaderboardMetricSelectionContext)!;
  const { data: user } = useCurrentUser();

  const DropdownItemsNetTimes = useMemo(
    () => (
      <Styled.DropdownContainer>
        {Object.entries(NetTimeType).map(([key, value]) => {
          return (
            <Styled.DropdownItem
              key={key}
              onClick={() => {
                Track.interact("Rig Leaderboard - Change net times type", {
                  "Selected type": value,
                });
                setSelectedNetTime(value as NetTimeType);
              }}
            >
              {value}
            </Styled.DropdownItem>
          );
        })}
      </Styled.DropdownContainer>
    ),
    [setSelectedNetTime],
  );

  const DropdownItemsScores = useMemo(
    () => (
      <Styled.DropdownContainer>
        {Object.entries(ScoreType).map(([key, value]) => {
          return (
            <Styled.DropdownItem
              key={key}
              onClick={() => {
                Track.interact("Rig Leaderboard - Change score type", {
                  "Selected type": value,
                });
                setSelectedScore(value as ScoreType);
              }}
            >
              {value}
            </Styled.DropdownItem>
          );
        })}
      </Styled.DropdownContainer>
    ),
    [setSelectedScore],
  );

  const displayedOption = useCallback((option: string) => {
    return (
      <Styled.NetTimeLabel>
        <Styled.NetTimeLabelText>{option}</Styled.NetTimeLabelText>
        <PDComponent.SvgIcon name="chevronDown" />
      </Styled.NetTimeLabel>
    );
  }, []);

  return (
    <Styled.Container>
      <Styled.ScoresButton
        $active={selectedMetric === SelectedMetric.KPI}
        disabled={!user?.canAccessRigScorecard}
        onClick={() => {
          Track.interact("Rig Leaderboard - Change group tab", {
            "Selected Tab": "KPI Values",
          });
          setSelectedMetric(SelectedMetric.KPI);
        }}
        icon={<PDComponent.SvgIcon name="kpi" height={18} />}
      >
        KPI Values
      </Styled.ScoresButton>

      {user?.canAccessRigScorecard ? (
        <>
          <ButtonGroup>
            <Styled.ScoresButton
              $active={selectedMetric === SelectedMetric.Scores}
              onClick={() => {
                setSelectedMetric(SelectedMetric.Scores);
                Track.interact("Rig Leaderboard - Change group tab", {
                  "Selected Tab": "Scores",
                });
                if (selectedMetric !== SelectedMetric.Scores) {
                  Track.interact("Rig Leaderboard - Change score type", {
                    "Selected type": ScoreType.OperatorScore,
                  });
                  setSelectedScore(ScoreType.OperatorScore);
                }
              }}
              icon={<PDComponent.SvgIcon name="outliersEnabled" />}
              disabled={selectedMetric === SelectedMetric.Scores}
            >
              Scores
            </Styled.ScoresButton>

            {selectedMetric === SelectedMetric.Scores ? (
              <Dropdown overlay={DropdownItemsScores} placement="bottomRight" trigger={["click"]}>
                <Styled.ScoresButton
                  $width={DROPDOWN_WIDTH}
                  $active={selectedMetric === SelectedMetric.Scores}
                  onClick={() => setSelectedMetric(SelectedMetric.Scores)}
                  $hasBorder={true}
                >
                  {displayedOption(selectedScore || "")}
                </Styled.ScoresButton>
              </Dropdown>
            ) : null}
          </ButtonGroup>

          <ButtonGroup>
            <Styled.ScoresButton
              $active={selectedMetric === SelectedMetric.NetTimes}
              onClick={() => {
                setSelectedMetric(SelectedMetric.NetTimes);
                Track.interact("Rig Leaderboard - Change group tab", {
                  "Selected Tab": "Net Times",
                });
                if (selectedMetric !== SelectedMetric.NetTimes) {
                  Track.interact("Rig Leaderboard - Change net times type", {
                    "Selected type": NetTimeType.Target,
                  });
                  setSelectedNetTime(NetTimeType.Target);
                }
              }}
              icon={<PDComponent.SvgIcon name="chartWinLoss" height={18} />}
              disabled={selectedMetric === SelectedMetric.NetTimes}
            >
              Net Times
            </Styled.ScoresButton>

            {selectedMetric === SelectedMetric.NetTimes ? (
              <Dropdown overlay={DropdownItemsNetTimes} placement="bottomRight" trigger={["click"]}>
                <Styled.ScoresButton
                  $width={DROPDOWN_WIDTH}
                  $active={selectedMetric === SelectedMetric.NetTimes}
                  onClick={() => setSelectedMetric(SelectedMetric.NetTimes)}
                  $hasBorder={true}
                >
                  {displayedOption(selectedNetTime || "")}
                </Styled.ScoresButton>
              </Dropdown>
            ) : null}
          </ButtonGroup>
        </>
      ) : null}
    </Styled.Container>
  );
};
