/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GeneratorStatsSummaryDto
 */
export interface GeneratorStatsSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof GeneratorStatsSummaryDto
     */
    totalEnergyInKWh: number;
    /**
     * 
     * @type {number}
     * @memberof GeneratorStatsSummaryDto
     */
    totalRunHours: number;
    /**
     * 
     * @type {number}
     * @memberof GeneratorStatsSummaryDto
     */
    maxLoad: number;
}

export function GeneratorStatsSummaryDtoFromJSON(json: any): GeneratorStatsSummaryDto {
    return GeneratorStatsSummaryDtoFromJSONTyped(json, false);
}

export function GeneratorStatsSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneratorStatsSummaryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalEnergyInKWh': json['TotalEnergyInKWh'],
        'totalRunHours': json['TotalRunHours'],
        'maxLoad': json['MaxLoad'],
    };
}

export function GeneratorStatsSummaryDtoToJSON(value?: GeneratorStatsSummaryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'TotalEnergyInKWh': value.totalEnergyInKWh,
        'TotalRunHours': value.totalRunHours,
        'MaxLoad': value.maxLoad,
    };
}

