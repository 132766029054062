/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface GhgEmissionsFactDto
 */
export interface GhgEmissionsFactDto {
    /**
     * 
     * @type {DateDto}
     * @memberof GhgEmissionsFactDto
     */
    at: DateDto;
    /**
     * 
     * @type {number}
     * @memberof GhgEmissionsFactDto
     */
    emissionRate: number;
}

export function GhgEmissionsFactDtoFromJSON(json: any): GhgEmissionsFactDto {
    return GhgEmissionsFactDtoFromJSONTyped(json, false);
}

export function GhgEmissionsFactDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GhgEmissionsFactDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'at': DateDtoFromJSON(json['At']),
        'emissionRate': json['EmissionRate'],
    };
}

export function GhgEmissionsFactDtoToJSON(value?: GhgEmissionsFactDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'At': DateDtoToJSON(value.at),
        'EmissionRate': value.emissionRate,
    };
}

