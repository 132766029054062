import type { BaseFocalQueryDto } from "apis/oag";
import { initialZoomData } from "components/WellDashboard/ChartControls/index";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import type { IFiltersType, IZoomData } from "reducers/types";
import { defaultDateDto } from "utils/common";

import { useSelectedWell } from "./useSelectedWell";
export const DEFAULT_SAMPLE_COUNT = 500;

export const useFilterParams: () => BaseFocalQueryDto = () => {
  const wellId = useSelectedWell();

  if (wellId === null) {
    throw new Error("WellId should not be null");
  }

  const [
    { sections: sectionIds, operationTime, directionalIntervals: directionalIntervalIds, holeSizes: holeSizeIds },
  ] = useStateQuery<IFiltersType>(URL_STATE_PARAM.FILTERS_WIDGET, {
    sections: null,
    directionalIntervals: null,
    operationTime: null,
    holeSizes: null,
  });

  const [
    { ts_start: zoomStartDuration, ts_end: zoomEndDuration, depth_start: zoomStartDepth, depth_end: zoomEndDepth },
  ] = useStateQuery<IZoomData>(URL_STATE_PARAM.ZOOM_WIDGET, initialZoomData);

  return {
    useMockData: false,
    from: defaultDateDto.from,
    to: defaultDateDto.to,
    sampleCount: DEFAULT_SAMPLE_COUNT,

    comparisonWellIds: null,
    selectedFilters: {
      sectionIds,
      directionalIntervalIds,
      holeSizeIds,
      includeFlatTime:
        operationTime === null || operationTime === undefined ? true : operationTime.includes("Flat Time"),
      includeSlidingTime:
        operationTime === null || operationTime === undefined ? true : operationTime.includes("Sliding Time"),
      includeRotatingTime:
        operationTime === null || operationTime === undefined ? true : operationTime.includes("Rotating Time"),
      includeNullHoleDepth: true,
    },
    operationTime,
    zoomStartDuration,
    zoomEndDuration,
    zoomStartDepth,
    zoomEndDepth,
    wellId: wellId,
  };
};
