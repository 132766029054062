/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OutlierFlaggingType,
    OutlierFlaggingTypeFromJSON,
    OutlierFlaggingTypeFromJSONTyped,
    OutlierFlaggingTypeToJSON,
} from './OutlierFlaggingType';
import {
    PivotType,
    PivotTypeFromJSON,
    PivotTypeFromJSONTyped,
    PivotTypeToJSON,
} from './PivotType';
import {
    StackingType,
    StackingTypeFromJSON,
    StackingTypeFromJSONTyped,
    StackingTypeToJSON,
} from './StackingType';
import {
    StandKpiGroup,
    StandKpiGroupFromJSON,
    StandKpiGroupFromJSONTyped,
    StandKpiGroupToJSON,
} from './StandKpiGroup';
import {
    TimeUnit,
    TimeUnitFromJSON,
    TimeUnitFromJSONTyped,
    TimeUnitToJSON,
} from './TimeUnit';
import {
    VisualAidType,
    VisualAidTypeFromJSON,
    VisualAidTypeFromJSONTyped,
    VisualAidTypeToJSON,
} from './VisualAidType';

/**
 * 
 * @export
 * @interface PivotKpiGroupUserLensDto
 */
export interface PivotKpiGroupUserLensDto {
    /**
     * 
     * @type {number}
     * @memberof PivotKpiGroupUserLensDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof PivotKpiGroupUserLensDto
     */
    lensTabId: number;
    /**
     * 
     * @type {number}
     * @memberof PivotKpiGroupUserLensDto
     */
    lensTemplateId: number;
    /**
     * 
     * @type {boolean}
     * @memberof PivotKpiGroupUserLensDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PivotKpiGroupUserLensDto
     */
    label: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PivotKpiGroupUserLensDto
     */
    isSystem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PivotKpiGroupUserLensDto
     */
    squeezesDisplay: boolean;
    /**
     * 
     * @type {TimeUnit}
     * @memberof PivotKpiGroupUserLensDto
     */
    selectedTimeUnit: TimeUnit;
    /**
     * 
     * @type {boolean}
     * @memberof PivotKpiGroupUserLensDto
     */
    isManualYaxis: boolean;
    /**
     * 
     * @type {number}
     * @memberof PivotKpiGroupUserLensDto
     */
    yaxisStart?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PivotKpiGroupUserLensDto
     */
    yaxisEnd?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PivotKpiGroupUserLensDto
     */
    showsOutliers: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PivotKpiGroupUserLensDto
     */
    showOperationCount: boolean;
    /**
     * 
     * @type {OutlierFlaggingType}
     * @memberof PivotKpiGroupUserLensDto
     */
    outlierFlaggingType: OutlierFlaggingType;
    /**
     * 
     * @type {number}
     * @memberof PivotKpiGroupUserLensDto
     */
    outlierMin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PivotKpiGroupUserLensDto
     */
    outlierMax?: number | null;
    /**
     * 
     * @type {Array<VisualAidType>}
     * @memberof PivotKpiGroupUserLensDto
     */
    selectedVisualAids: Array<VisualAidType>;
    /**
     * 
     * @type {StackingType}
     * @memberof PivotKpiGroupUserLensDto
     */
    stackingType: StackingType;
    /**
     * 
     * @type {StandKpiGroup}
     * @memberof PivotKpiGroupUserLensDto
     */
    kpiGroupId: StandKpiGroup;
    /**
     * 
     * @type {PivotType}
     * @memberof PivotKpiGroupUserLensDto
     */
    defaultPivotLevel1: PivotType;
    /**
     * 
     * @type {PivotType}
     * @memberof PivotKpiGroupUserLensDto
     */
    defaultPivotLevel2: PivotType;
    /**
     * 
     * @type {PivotType}
     * @memberof PivotKpiGroupUserLensDto
     */
    defaultPivotLevel3: PivotType;
}

export function PivotKpiGroupUserLensDtoFromJSON(json: any): PivotKpiGroupUserLensDto {
    return PivotKpiGroupUserLensDtoFromJSONTyped(json, false);
}

export function PivotKpiGroupUserLensDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PivotKpiGroupUserLensDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'lensTabId': json['LensTabId'],
        'lensTemplateId': json['LensTemplateId'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'label': json['Label'],
        'isSystem': json['IsSystem'],
        'squeezesDisplay': json['SqueezesDisplay'],
        'selectedTimeUnit': TimeUnitFromJSON(json['SelectedTimeUnit']),
        'isManualYaxis': json['IsManualYaxis'],
        'yaxisStart': !exists(json, 'YaxisStart') ? undefined : json['YaxisStart'],
        'yaxisEnd': !exists(json, 'YaxisEnd') ? undefined : json['YaxisEnd'],
        'showsOutliers': json['ShowsOutliers'],
        'showOperationCount': json['ShowOperationCount'],
        'outlierFlaggingType': OutlierFlaggingTypeFromJSON(json['OutlierFlaggingType']),
        'outlierMin': !exists(json, 'OutlierMin') ? undefined : json['OutlierMin'],
        'outlierMax': !exists(json, 'OutlierMax') ? undefined : json['OutlierMax'],
        'selectedVisualAids': ((json['SelectedVisualAids'] as Array<any>).map(VisualAidTypeFromJSON)),
        'stackingType': StackingTypeFromJSON(json['StackingType']),
        'kpiGroupId': StandKpiGroupFromJSON(json['KpiGroupId']),
        'defaultPivotLevel1': PivotTypeFromJSON(json['DefaultPivotLevel1']),
        'defaultPivotLevel2': PivotTypeFromJSON(json['DefaultPivotLevel2']),
        'defaultPivotLevel3': PivotTypeFromJSON(json['DefaultPivotLevel3']),
    };
}

export function PivotKpiGroupUserLensDtoToJSON(value?: PivotKpiGroupUserLensDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'LensTabId': value.lensTabId,
        'LensTemplateId': value.lensTemplateId,
        'IsAvailableOnReports': value.isAvailableOnReports,
        'Label': value.label,
        'IsSystem': value.isSystem,
        'SqueezesDisplay': value.squeezesDisplay,
        'SelectedTimeUnit': TimeUnitToJSON(value.selectedTimeUnit),
        'IsManualYaxis': value.isManualYaxis,
        'YaxisStart': value.yaxisStart,
        'YaxisEnd': value.yaxisEnd,
        'ShowsOutliers': value.showsOutliers,
        'ShowOperationCount': value.showOperationCount,
        'OutlierFlaggingType': OutlierFlaggingTypeToJSON(value.outlierFlaggingType),
        'OutlierMin': value.outlierMin,
        'OutlierMax': value.outlierMax,
        'SelectedVisualAids': ((value.selectedVisualAids as Array<any>).map(VisualAidTypeToJSON)),
        'StackingType': StackingTypeToJSON(value.stackingType),
        'KpiGroupId': StandKpiGroupToJSON(value.kpiGroupId),
        'DefaultPivotLevel1': PivotTypeToJSON(value.defaultPivotLevel1),
        'DefaultPivotLevel2': PivotTypeToJSON(value.defaultPivotLevel2),
        'DefaultPivotLevel3': PivotTypeToJSON(value.defaultPivotLevel3),
    };
}

