/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TagBottomFingerprintStandSeriesDto
 */
export interface TagBottomFingerprintStandSeriesDto {
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintStandSeriesDto
     */
    standId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof TagBottomFingerprintStandSeriesDto
     */
    facts: Array<number>;
}

export function TagBottomFingerprintStandSeriesDtoFromJSON(json: any): TagBottomFingerprintStandSeriesDto {
    return TagBottomFingerprintStandSeriesDtoFromJSONTyped(json, false);
}

export function TagBottomFingerprintStandSeriesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagBottomFingerprintStandSeriesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'standId': json['StandId'],
        'facts': json['Facts'],
    };
}

export function TagBottomFingerprintStandSeriesDtoToJSON(value?: TagBottomFingerprintStandSeriesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StandId': value.standId,
        'Facts': value.facts,
    };
}

