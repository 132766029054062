/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    ScoreComplianceStateType,
    ScoreComplianceStateTypeFromJSON,
    ScoreComplianceStateTypeFromJSONTyped,
    ScoreComplianceStateTypeToJSON,
} from './ScoreComplianceStateType';
import {
    StandKpiSliceDto,
    StandKpiSliceDtoFromJSON,
    StandKpiSliceDtoFromJSONTyped,
    StandKpiSliceDtoToJSON,
} from './StandKpiSliceDto';

/**
 * 
 * @export
 * @interface StandKpiDetailsDto
 */
export interface StandKpiDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof StandKpiDetailsDto
     */
    standNumber: number;
    /**
     * 
     * @type {number}
     * @memberof StandKpiDetailsDto
     */
    standId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof StandKpiDetailsDto
     */
    startAt: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof StandKpiDetailsDto
     */
    endAt: DateDto;
    /**
     * 
     * @type {number}
     * @memberof StandKpiDetailsDto
     */
    startDepth: number;
    /**
     * 
     * @type {number}
     * @memberof StandKpiDetailsDto
     */
    endDepth: number;
    /**
     * 
     * @type {boolean}
     * @memberof StandKpiDetailsDto
     */
    isDayShift: boolean;
    /**
     * 
     * @type {number}
     * @memberof StandKpiDetailsDto
     */
    value: number;
    /**
     * 
     * @type {number}
     * @memberof StandKpiDetailsDto
     */
    targetValue?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof StandKpiDetailsDto
     */
    isOutlier: boolean;
    /**
     * 
     * @type {ScoreComplianceStateType}
     * @memberof StandKpiDetailsDto
     */
    complianceState: ScoreComplianceStateType;
    /**
     * 
     * @type {Array<StandKpiSliceDto>}
     * @memberof StandKpiDetailsDto
     */
    slices?: Array<StandKpiSliceDto> | null;
}

export function StandKpiDetailsDtoFromJSON(json: any): StandKpiDetailsDto {
    return StandKpiDetailsDtoFromJSONTyped(json, false);
}

export function StandKpiDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandKpiDetailsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'standNumber': json['StandNumber'],
        'standId': json['StandId'],
        'startAt': DateDtoFromJSON(json['StartAt']),
        'endAt': DateDtoFromJSON(json['EndAt']),
        'startDepth': json['StartDepth'],
        'endDepth': json['EndDepth'],
        'isDayShift': json['IsDayShift'],
        'value': json['Value'],
        'targetValue': !exists(json, 'TargetValue') ? undefined : json['TargetValue'],
        'isOutlier': json['IsOutlier'],
        'complianceState': ScoreComplianceStateTypeFromJSON(json['ComplianceState']),
        'slices': !exists(json, 'Slices') ? undefined : (json['Slices'] === null ? null : (json['Slices'] as Array<any>).map(StandKpiSliceDtoFromJSON)),
    };
}

export function StandKpiDetailsDtoToJSON(value?: StandKpiDetailsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StandNumber': value.standNumber,
        'StandId': value.standId,
        'StartAt': DateDtoToJSON(value.startAt),
        'EndAt': DateDtoToJSON(value.endAt),
        'StartDepth': value.startDepth,
        'EndDepth': value.endDepth,
        'IsDayShift': value.isDayShift,
        'Value': value.value,
        'TargetValue': value.targetValue,
        'IsOutlier': value.isOutlier,
        'ComplianceState': ScoreComplianceStateTypeToJSON(value.complianceState),
        'Slices': value.slices === undefined ? undefined : (value.slices === null ? null : (value.slices as Array<any>).map(StandKpiSliceDtoToJSON)),
    };
}

