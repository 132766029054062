import { Group } from "@visx/group";
import { Bar } from "@visx/shape";
import type { TvdHoleDirectionDto } from "apis/oag";
import { WellStatusType } from "apis/oag";
import { LETTER_WIDTH, RIGHT_AXIS_PADDING } from "components/TvDChart/constants";
import type { ScaleLinear } from "d3-scale";
import { useDirectionalIntervals } from "hooks/useDirectionalIntervals";
import { useAppSelector } from "reducers/store";
import colors from "utils/colors";
import { secondsInDay } from "utils/common";
import { Tooltip } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

export const DirectionalIntervals = ({
  chartWidth,
  directionalIntervalsData,
  paddingLeft,
  xScale,
  isLoading: isLoadingTvd,
  isReport,
}: {
  chartWidth: number;
  directionalIntervalsData: TvdHoleDirectionDto[];
  paddingLeft: number;
  xScale: ScaleLinear<number, number, never>;
  isLoading: boolean;
  isReport?: boolean;
}) => {
  const { data: directionalIntervals, isLoading } = useDirectionalIntervals();
  const selectedWellDetails = useAppSelector((state) => state.timeline.currentWellDetails);
  const isActiveWell = selectedWellDetails?.status === WellStatusType.Active;
  const {
    isDark,
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const colorDirectionalInterval: { [index: string]: string } = {
    Vertical: isDark ? colors.revolver : colors.spring_thaw,
    Build: themeColors.primary_scroll_bg,
    Lateral: isDark ? colors.bastille : colors.off_grey,
    Unknown: themeColors.alt_primary_bg,
  };

  return (
    <Group>
      {!isLoading &&
        !isLoadingTvd &&
        directionalIntervalsData.map((d, i) => {
          const name = directionalIntervals?.find((e) => e.id === d.directionalIntervalId)?.name ?? "";
          const color = colorDirectionalInterval[name];
          // Check selected well details
          const isLast = i === directionalIntervalsData.length - 1;
          let width =
            (i === 0 ? paddingLeft : 0) +
            xScale((d.endDynamicDuration - d.startDynamicDuration) / secondsInDay) +
            (isLast && !isActiveWell
              ? (!isReport ? RIGHT_AXIS_PADDING * 2 : 0) + chartWidth - xScale(d.endDynamicDuration / secondsInDay)
              : 0);
          if (width < 0) width = 0;
          const x = (i === 0 ? 0 : paddingLeft) + (xScale((d.startDynamicDuration ?? 0) / secondsInDay) ?? 0);
          const showText = () => {
            if (width > 75) return true;
            return isLast && !isActiveWell;
          };
          const textX = x + width / 2 - (LETTER_WIDTH * name.length) / 2;
          return (
            <Tooltip
              key={`${d.startDynamicDuration}-${d.endDynamicDuration}-${d.directionalIntervalId}-${i}-tooltip`}
              placement="top"
              overlayStyle={{ whiteSpace: "pre-line" }}
              title={name}
              arrowPointAtCenter
              destroyTooltipOnHide
            >
              <Group key={`${d.startDynamicDuration}-${d.endDynamicDuration}-${d.directionalIntervalId}-${i}-group`}>
                <Bar
                  width={Number.isNaN(width) ? 0 : width}
                  x={Number.isNaN(x) ? 0 : x}
                  height={38}
                  fill={color}
                  y="calc(100% - 38px)"
                />
                {isActiveWell && isLast ? (
                  <Bar
                    width={3}
                    x={(Number.isNaN(x) ? 0 : x) + (Number.isNaN(width) ? 0 : width)}
                    height={38}
                    fill={colors.well_color}
                    y="calc(100% - 38px)"
                  />
                ) : null}
                {showText() && (
                  <text
                    fill={themeColors.primary_typography}
                    fontSize={14}
                    letterSpacing={-0.2}
                    pointerEvents="none"
                    textAnchor="right"
                    x={Number.isNaN(textX) ? 0 : textX}
                    // TODO -38px does nothing :(
                    style={{
                      userSelect: "none",
                    }}
                    y={isReport ? "98.5%" : "97.5%"}
                  >
                    {name}
                  </text>
                )}
              </Group>
            </Tooltip>
          );
        })}
    </Group>
  );
};
