/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PlanActivityDto,
    PlanActivityDtoFromJSON,
    PlanActivityDtoFromJSONTyped,
    PlanActivityDtoToJSON,
} from './PlanActivityDto';
import {
    PlanDrillingParameterDto,
    PlanDrillingParameterDtoFromJSON,
    PlanDrillingParameterDtoFromJSONTyped,
    PlanDrillingParameterDtoToJSON,
} from './PlanDrillingParameterDto';
import {
    PlanFormationDto,
    PlanFormationDtoFromJSON,
    PlanFormationDtoFromJSONTyped,
    PlanFormationDtoToJSON,
} from './PlanFormationDto';

/**
 * 
 * @export
 * @interface PlanDto
 */
export interface PlanDto {
    /**
     * 
     * @type {number}
     * @memberof PlanDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof PlanDto
     */
    wellId: number;
    /**
     * 
     * @type {string}
     * @memberof PlanDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDto
     */
    startHoleDepth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDto
     */
    startFormationDepth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDto
     */
    startParameterDepth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDto
     */
    totalDuration: number;
    /**
     * 
     * @type {Array<PlanActivityDto>}
     * @memberof PlanDto
     */
    activities: Array<PlanActivityDto>;
    /**
     * 
     * @type {Array<PlanFormationDto>}
     * @memberof PlanDto
     */
    formations: Array<PlanFormationDto>;
    /**
     * 
     * @type {Array<PlanDrillingParameterDto>}
     * @memberof PlanDto
     */
    drillingParameters: Array<PlanDrillingParameterDto>;
}

export function PlanDtoFromJSON(json: any): PlanDto {
    return PlanDtoFromJSONTyped(json, false);
}

export function PlanDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'wellId': json['WellId'],
        'description': !exists(json, 'Description') ? undefined : json['Description'],
        'startHoleDepth': !exists(json, 'StartHoleDepth') ? undefined : json['StartHoleDepth'],
        'startFormationDepth': !exists(json, 'StartFormationDepth') ? undefined : json['StartFormationDepth'],
        'startParameterDepth': !exists(json, 'StartParameterDepth') ? undefined : json['StartParameterDepth'],
        'totalDuration': json['TotalDuration'],
        'activities': ((json['Activities'] as Array<any>).map(PlanActivityDtoFromJSON)),
        'formations': ((json['Formations'] as Array<any>).map(PlanFormationDtoFromJSON)),
        'drillingParameters': ((json['DrillingParameters'] as Array<any>).map(PlanDrillingParameterDtoFromJSON)),
    };
}

export function PlanDtoToJSON(value?: PlanDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'WellId': value.wellId,
        'Description': value.description,
        'StartHoleDepth': value.startHoleDepth,
        'StartFormationDepth': value.startFormationDepth,
        'StartParameterDepth': value.startParameterDepth,
        'TotalDuration': value.totalDuration,
        'Activities': ((value.activities as Array<any>).map(PlanActivityDtoToJSON)),
        'Formations': ((value.formations as Array<any>).map(PlanFormationDtoToJSON)),
        'DrillingParameters': ((value.drillingParameters as Array<any>).map(PlanDrillingParameterDtoToJSON)),
    };
}

