/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParameterByDepthRoadmapBoundariesDto
 */
export interface ParameterByDepthRoadmapBoundariesDto {
    /**
     * 
     * @type {number}
     * @memberof ParameterByDepthRoadmapBoundariesDto
     */
    measuredDepth: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterByDepthRoadmapBoundariesDto
     */
    min?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ParameterByDepthRoadmapBoundariesDto
     */
    max?: number | null;
}

export function ParameterByDepthRoadmapBoundariesDtoFromJSON(json: any): ParameterByDepthRoadmapBoundariesDto {
    return ParameterByDepthRoadmapBoundariesDtoFromJSONTyped(json, false);
}

export function ParameterByDepthRoadmapBoundariesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterByDepthRoadmapBoundariesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'measuredDepth': json['MeasuredDepth'],
        'min': !exists(json, 'Min') ? undefined : json['Min'],
        'max': !exists(json, 'Max') ? undefined : json['Max'],
    };
}

export function ParameterByDepthRoadmapBoundariesDtoToJSON(value?: ParameterByDepthRoadmapBoundariesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'MeasuredDepth': value.measuredDepth,
        'Min': value.min,
        'Max': value.max,
    };
}

