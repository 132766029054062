/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    PowerLoadDistributionType,
    PowerLoadDistributionTypeFromJSON,
    PowerLoadDistributionTypeFromJSONTyped,
    PowerLoadDistributionTypeToJSON,
} from './PowerLoadDistributionType';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    RigPowerDistributionBarDto,
    RigPowerDistributionBarDtoFromJSON,
    RigPowerDistributionBarDtoFromJSONTyped,
    RigPowerDistributionBarDtoToJSON,
} from './RigPowerDistributionBarDto';
import {
    ServiceMessageDto,
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import {
    StopWatchDto,
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';

/**
 * 
 * @export
 * @interface PowerLoadDistributionFactSetDto
 */
export interface PowerLoadDistributionFactSetDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof PowerLoadDistributionFactSetDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof PowerLoadDistributionFactSetDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof PowerLoadDistributionFactSetDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof PowerLoadDistributionFactSetDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {PowerLoadDistributionType}
     * @memberof PowerLoadDistributionFactSetDto
     */
    distributionType: PowerLoadDistributionType;
    /**
     * 
     * @type {Array<RigPowerDistributionBarDto>}
     * @memberof PowerLoadDistributionFactSetDto
     */
    bars?: Array<RigPowerDistributionBarDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof PowerLoadDistributionFactSetDto
     */
    availableDistributionReferenceIds?: Array<number> | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof PowerLoadDistributionFactSetDto
     */
    dataState: ResultDataState;
}

export function PowerLoadDistributionFactSetDtoFromJSON(json: any): PowerLoadDistributionFactSetDto {
    return PowerLoadDistributionFactSetDtoFromJSONTyped(json, false);
}

export function PowerLoadDistributionFactSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PowerLoadDistributionFactSetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': !exists(json, 'Messages') ? undefined : (json['Messages'] === null ? null : (json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'distributionType': PowerLoadDistributionTypeFromJSON(json['DistributionType']),
        'bars': !exists(json, 'Bars') ? undefined : (json['Bars'] === null ? null : (json['Bars'] as Array<any>).map(RigPowerDistributionBarDtoFromJSON)),
        'availableDistributionReferenceIds': !exists(json, 'AvailableDistributionReferenceIds') ? undefined : json['AvailableDistributionReferenceIds'],
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function PowerLoadDistributionFactSetDtoToJSON(value?: PowerLoadDistributionFactSetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value.stopWatch),
        'Messages': value.messages === undefined ? undefined : (value.messages === null ? null : (value.messages as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value.userLensId,
        'LastUpdatedAt': DateDtoToJSON(value.lastUpdatedAt),
        'DistributionType': PowerLoadDistributionTypeToJSON(value.distributionType),
        'Bars': value.bars === undefined ? undefined : (value.bars === null ? null : (value.bars as Array<any>).map(RigPowerDistributionBarDtoToJSON)),
        'AvailableDistributionReferenceIds': value.availableDistributionReferenceIds,
        'DataState': ResultDataStateToJSON(value.dataState),
    };
}

