/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DashboardType,
    DashboardTypeFromJSON,
    DashboardTypeFromJSONTyped,
    DashboardTypeToJSON,
} from './DashboardType';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    TimelineEventType,
    TimelineEventTypeFromJSON,
    TimelineEventTypeFromJSONTyped,
    TimelineEventTypeToJSON,
} from './TimelineEventType';

/**
 * 
 * @export
 * @interface ActualTimelineEventDto
 */
export interface ActualTimelineEventDto {
    /**
     * 
     * @type {number}
     * @memberof ActualTimelineEventDto
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof ActualTimelineEventDto
     */
    deletedAtUtc?: Date | null;
    /**
     * 
     * @type {TimelineEventType}
     * @memberof ActualTimelineEventDto
     */
    type: TimelineEventType;
    /**
     * 
     * @type {string}
     * @memberof ActualTimelineEventDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActualTimelineEventDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ActualTimelineEventDto
     */
    authorId: number;
    /**
     * 
     * @type {string}
     * @memberof ActualTimelineEventDto
     */
    authorDisplayName: string;
    /**
     * 
     * @type {number}
     * @memberof ActualTimelineEventDto
     */
    cumulativeDuration: number;
    /**
     * 
     * @type {number}
     * @memberof ActualTimelineEventDto
     */
    holeDepth?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ActualTimelineEventDto
     */
    isSetByDepth: boolean;
    /**
     * 
     * @type {number}
     * @memberof ActualTimelineEventDto
     */
    wellId: number;
    /**
     * 
     * @type {number}
     * @memberof ActualTimelineEventDto
     */
    planEventId?: number | null;
    /**
     * 
     * @type {DateDto}
     * @memberof ActualTimelineEventDto
     */
    at: DateDto;
    /**
     * 
     * @type {DashboardType}
     * @memberof ActualTimelineEventDto
     */
    dashboardType: DashboardType;
}

export function ActualTimelineEventDtoFromJSON(json: any): ActualTimelineEventDto {
    return ActualTimelineEventDtoFromJSONTyped(json, false);
}

export function ActualTimelineEventDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActualTimelineEventDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'deletedAtUtc': !exists(json, 'DeletedAtUtc') ? undefined : (json['DeletedAtUtc'] === null ? null : new Date(json['DeletedAtUtc'])),
        'type': TimelineEventTypeFromJSON(json['Type']),
        'title': !exists(json, 'Title') ? undefined : json['Title'],
        'description': !exists(json, 'Description') ? undefined : json['Description'],
        'authorId': json['AuthorId'],
        'authorDisplayName': json['AuthorDisplayName'],
        'cumulativeDuration': json['CumulativeDuration'],
        'holeDepth': !exists(json, 'HoleDepth') ? undefined : json['HoleDepth'],
        'isSetByDepth': json['IsSetByDepth'],
        'wellId': json['WellId'],
        'planEventId': !exists(json, 'PlanEventId') ? undefined : json['PlanEventId'],
        'at': DateDtoFromJSON(json['At']),
        'dashboardType': DashboardTypeFromJSON(json['DashboardType']),
    };
}

export function ActualTimelineEventDtoToJSON(value?: ActualTimelineEventDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'DeletedAtUtc': value.deletedAtUtc === undefined ? undefined : (value.deletedAtUtc === null ? null : value.deletedAtUtc.toISOString()),
        'Type': TimelineEventTypeToJSON(value.type),
        'Title': value.title,
        'Description': value.description,
        'AuthorId': value.authorId,
        'AuthorDisplayName': value.authorDisplayName,
        'CumulativeDuration': value.cumulativeDuration,
        'HoleDepth': value.holeDepth,
        'IsSetByDepth': value.isSetByDepth,
        'WellId': value.wellId,
        'PlanEventId': value.planEventId,
        'At': DateDtoToJSON(value.at),
        'DashboardType': DashboardTypeToJSON(value.dashboardType),
    };
}

