/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserRigAccessDto,
    UserRigAccessDtoFromJSON,
    UserRigAccessDtoFromJSONTyped,
    UserRigAccessDtoToJSON,
} from './UserRigAccessDto';

/**
 * 
 * @export
 * @interface UserRigAccessSetDto
 */
export interface UserRigAccessSetDto {
    /**
     * 
     * @type {boolean}
     * @memberof UserRigAccessSetDto
     */
    futureRigAccess: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserRigAccessSetDto
     */
    operatorFutureRigAccess: boolean;
    /**
     * 
     * @type {Array<UserRigAccessDto>}
     * @memberof UserRigAccessSetDto
     */
    wellAccess?: Array<UserRigAccessDto> | null;
}

export function UserRigAccessSetDtoFromJSON(json: any): UserRigAccessSetDto {
    return UserRigAccessSetDtoFromJSONTyped(json, false);
}

export function UserRigAccessSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRigAccessSetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'futureRigAccess': json['FutureRigAccess'],
        'operatorFutureRigAccess': json['OperatorFutureRigAccess'],
        'wellAccess': !exists(json, 'WellAccess') ? undefined : (json['WellAccess'] === null ? null : (json['WellAccess'] as Array<any>).map(UserRigAccessDtoFromJSON)),
    };
}

export function UserRigAccessSetDtoToJSON(value?: UserRigAccessSetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'FutureRigAccess': value.futureRigAccess,
        'OperatorFutureRigAccess': value.operatorFutureRigAccess,
        'WellAccess': value.wellAccess === undefined ? undefined : (value.wellAccess === null ? null : (value.wellAccess as Array<any>).map(UserRigAccessDtoToJSON)),
    };
}

