import { useSuspenseQuery } from "@tanstack/react-query";
import type { ApiWellsIdRangesPutRequest, WellRangesDto } from "apis/oag";
import { WellsApi } from "apis/oag";
import { initialZoomData } from "components/WellDashboard/ChartControls/index";
import { useParams } from "react-router-dom";
import type { IFiltersType, IZoomData } from "reducers/types";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto, TVD_SAMPLE_COUNT } from "utils/common";
import type { PDWellRangesQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

import { URL_STATE_PARAM, useStateQuery } from "./navigation/useQueryState";

const wells = new WellsApi(apiConfig);

export function useWellRanges() {
  const params = useParams<{ wellId: string }>();
  const wellDataID = Number(params.wellId);

  const [comparisonWellIds] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WIDGET, []);

  const [{ operationTime, sections, directionalIntervals, holeSizes }] = useStateQuery<IFiltersType>(
    URL_STATE_PARAM.FILTERS_WIDGET,
    {
      operationTime: [],
      sections: [],
      directionalIntervals: [],
      holeSizes: [],
    },
  );
  const [
    { ts_start: zoomStartDuration, ts_end: zoomEndDuration, depth_start: zoomStartDepth, depth_end: zoomEndDepth },
  ] = useStateQuery<IZoomData>(URL_STATE_PARAM.ZOOM_WIDGET, initialZoomData);

  const requestParameters: ApiWellsIdRangesPutRequest = {
    id: wellDataID,
    baseFocalQueryDto: {
      wellId: wellDataID,
      selectedFilters: {
        sectionIds: sections,
        directionalIntervalIds: directionalIntervals,
        holeSizeIds: holeSizes,
        includeFlatTime: (operationTime || [])?.length > 0 ? (operationTime || []).includes("Flat Time") : true,
        includeSlidingTime: (operationTime || [])?.length > 0 ? (operationTime || []).includes("Sliding Time") : true,
        includeRotatingTime: (operationTime || [])?.length > 0 ? (operationTime || []).includes("Rotating Time") : true,
        includeNullHoleDepth: true,
      },
      comparisonWellIds,
      zoomStartDepth,
      zoomEndDepth,
      zoomStartDuration,
      zoomEndDuration,
      useMockData: false,
      sampleCount: TVD_SAMPLE_COUNT,
      from: defaultDateDto.from,
      to: defaultDateDto.to,
    },
  };

  const queryKey: PDWellRangesQueryKey = {
    type: PDQueryType.WELL_RANGES,
    uid: RequestUID.wellRanges,
    params: requestParameters,
  };

  return useSuspenseQuery<WellRangesDto>({
    queryKey: [queryKey],
    queryFn: () => wells.apiWellsIdRangesPut(requestParameters),
    ...{
      enabled: wellDataID !== null,
    },
  });
}
