import type { RigCardFactDto, RigCardFactValue, StandKpiType } from "apis/oag";
import { TimeUnit } from "apis/oag";
import { PDComponent } from "components/PDComponents";
import { RigScoreCardCommentsModal } from "components/RigScoreCardCommentsModal";
import { useRigNavigationFilters } from "hooks/navigation/useRigNavigationFilters";
import { useRigs } from "hooks/useRigs";
import { useSelectedRig } from "hooks/useSelectedRig";
import { useWellShortInfoSuspended } from "hooks/useWellShortInfo";
import { cardKpiTypesMap } from "pages/AllRigs/components/LeaderboardSection/utils";
import { TSummary } from "pages/RigLeaderboard/components/ScoreBreakout/SummarizedComments";
import { useScoreBreakoutModal } from "pages/RigLeaderboard/components/ScoreBreakout/useScoreBreakoutModal";
import type { CustomTagProps } from "pages/RigScoreCard/LeftPane/components/CustomTag";
import { CustomTag } from "pages/RigScoreCard/LeftPane/components/CustomTag";
import { constructDetailedUrl, useScoreCardTags } from "pages/RigScoreCard/LeftPane/utils";
import * as CommonStyled from "pages/RigScoreCard/RightPane/StyledComponents";
import type {
  RequiredCommentsViewmodel,
  VisibleExceptionViewmodel,
} from "pages/RigScoreCard/RightPane/useRequiredComments";
import { BenchmarkType, useScoreBenchmarkContext } from "pages/RigScoreCard/ScoreBenchmarkContext";
import React, { useCallback, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { Track } from "services/Mixpanel";
import { useTimeUom, useUOM, UtilDimensions } from "utils/format";

import * as Styled from "./StyledComponents";

interface ActionableRowProps {
  type: "kpi" | "exception" | "wellscore";
  fact: RequiredCommentsViewmodel | VisibleExceptionViewmodel;
  kpiName: string;
  kpiGroupName: string;
  kpiDescription: string;
  rank?: string | number;
  wellName?: string;
  kpiDuration?: number;
  exceptionTimeLabel?: string;
  jobsLabel?: string;
}

export const ActionableCommentRow: React.FC<ActionableRowProps> = ({
  type,
  fact,
  kpiName,
  rank,
  wellName,
  kpiGroupName,
  kpiDuration,
  exceptionTimeLabel,
  jobsLabel,
  kpiDescription,
}) => {
  const rigCardScore = fact.cardFacts;
  const defaultUom = useUOM(rigCardScore.dimension);
  const percentageUom = useUOM(UtilDimensions.Percentage);
  const hourUom = useTimeUom(TimeUnit.Hour);
  const dayUom = useTimeUom(TimeUnit.Day);
  const factInfo = useAppSelector((state) => state.rigDashboard.factInfo);
  const dispatch = useAppDispatch();

  const { openScoreBreakout, scoreBreakoutElement } = useScoreBreakoutModal();

  const { data: wellShortInfo } = useWellShortInfoSuspended();
  const { scoreBenchmark } = useScoreBenchmarkContext();

  const { getTagsByAveragesOrFact: getBorderlessTags } = useScoreCardTags({ borderless: true });
  const { getTagsByAveragesOrFact } = useScoreCardTags();

  const { score: scoreTag } = useMemo(
    () => getTagsByAveragesOrFact({ fact: fact.wellFact }),
    [fact.wellFact, getTagsByAveragesOrFact],
  );

  const rigId = useSelectedRig();
  const { data: navigationFilters } = useRigNavigationFilters();

  const [commentsModalInfo, setCommentsModalInfo] = useState<{
    kpiType: StandKpiType;
    wellFact: RigCardFactDto;
    wellNumber: number;
    wellId: number;
    lensId: number;
    kpiValue: RigCardFactValue;
    tags: CustomTagProps[];
    wellName: string;
    baseValue?: number | null;
    targetValue?: number | null;
    value?: number | null;
    exception?: number | null;
    kpiLabels: React.ReactElement[];
    hasDrillerComments: boolean;
  } | null>(null);
  const selectedRig = useSelectedRig();
  const { data: rigs } = useRigs({ staleTime: Infinity });

  const rigName = useMemo(() => {
    return rigs.byId[selectedRig]?.shortName;
  }, [rigs.byId, selectedRig]);

  const navigateToDetailedView = useCallback(
    (wellFact: RigCardFactDto) => {
      const showNavigationButton = !!navigationFilters.find(
        (lensNavigation) => lensNavigation.sourceLensId === fact.cardFacts.userLensId,
      )?.targetLensId;

      if (showNavigationButton) {
        const targetLens = navigationFilters.find(
          (lensNavigation) => lensNavigation.sourceLensId === fact.cardFacts.userLensId,
        );
        return constructDetailedUrl(
          +wellFact.label,
          targetLens?.targetLensId,
          targetLens?.filters,
          rigId.toString(),
          location.search.slice(1),
        );
      }
      return "";
    },
    [fact.cardFacts.userLensId, navigationFilters, rigId],
  );

  const uom = useMemo(() => {
    if (rigCardScore.dimension === "Undefined") return percentageUom;
    if (rigCardScore.kpiType === cardKpiTypesMap.LocationRigMoveTime) return dayUom;
    if (
      [
        cardKpiTypesMap.WalkSpudTime,
        cardKpiTypesMap.WalkTime,
        cardKpiTypesMap.NippleTimeSurface,
        cardKpiTypesMap.PressureTestTimeSurface,
        cardKpiTypesMap.NippleTimeIntermediate,
        cardKpiTypesMap.PressureTestTimeIntermediate,
        cardKpiTypesMap.SlipCutTime,
      ].includes(rigCardScore.kpiType)
    )
      return hourUom;

    return defaultUom;
  }, [rigCardScore.dimension, rigCardScore.kpiType, percentageUom, dayUom, hourUom, defaultUom]);

  const getWellTags = useCallback(
    (fact: RigCardFactDto, isScore?: boolean) => {
      const tags = getBorderlessTags({ fact });
      if (isScore) {
        return [tags.score, tags.opportunityTime];
      } else return [tags.score, tags.opportunityTime, tags.timeSaved];
    },
    [getBorderlessTags],
  );

  const handleOnCommentsClick = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>, isScore?: boolean) => {
      ev?.stopPropagation();

      dispatch({
        type: "ADD_FACT_INFO",
        payload: {
          cardGroupId: fact.rigGroupFacts.tabId,
          kpiId: fact.cardFacts.kpiType,
          wellId: +fact.wellFact.label,
        },
      });

      const wellFact = fact.wellFact;
      Track.clickEvent("Rig Scorecard - Bar Clicked", {
        "Well Rank": wellFact.kpiValue.rank,
        "Well Id": +wellFact.label,
      });
      const cardTargetValue =
        scoreBenchmark === BenchmarkType.OperatorTarget ? wellFact.targetValue : wellFact.pdBenchmarkValue;

      let kpiValue = wellFact.kpiValue;
      if (isScore) {
        kpiValue = scoreBenchmark === BenchmarkType.OperatorTarget ? wellFact.operatorScore : wellFact.rigScore;
      }

      const value = kpiValue.value;

      const regularKpiLabels = [
        <span key={kpiName}>
          <b>{kpiName}:</b> {uom.display(kpiValue.value)}
        </span>,
      ];

      const scoresKpiLabels = [
        <span key={`adj-well-scores`}>
          <b>Well Scores:</b> {uom.display(kpiValue.value)}
        </span>,
      ];

      setCommentsModalInfo({
        kpiType: rigCardScore.kpiType,
        wellFact,
        wellNumber: wellFact.kpiValue.rank,
        wellId: +wellFact.label,
        kpiValue,
        tags: getWellTags(wellFact, isScore),
        lensId: fact.cardFacts.userLensId,
        wellName: wellShortInfo?.byId?.[+wellFact?.label]?.name ?? "",
        targetValue: isScore ? null : cardTargetValue || 0,
        baseValue: isScore ? null : wellFact.baseValue || 0,
        value: value || 0,
        exception: wellFact.kpiValue.exception,
        kpiLabels: isScore ? scoresKpiLabels : regularKpiLabels,
        hasDrillerComments: !isScore,
      });
    },
    [
      dispatch,
      fact.cardFacts.kpiType,
      fact.cardFacts.userLensId,
      fact.rigGroupFacts.tabId,
      fact.wellFact,
      getWellTags,
      kpiName,
      rigCardScore.kpiType,
      scoreBenchmark,
      uom,
      wellShortInfo?.byId,
    ],
  );

  const handleOnModalClose = useCallback(() => {
    setCommentsModalInfo(null);
  }, []);

  const CommentsModalElement = useMemo(() => {
    return commentsModalInfo ? (
      <RigScoreCardCommentsModal
        kpiType={commentsModalInfo.kpiType}
        wellNumber={commentsModalInfo.wellNumber}
        rigName={rigName || ""}
        wellId={commentsModalInfo.wellId}
        wellName={commentsModalInfo.wellName || "Unknown Well"}
        kpiName={kpiGroupName}
        kpiDescription={kpiDescription}
        kpiLabels={commentsModalInfo.kpiLabels}
        baseValue={commentsModalInfo.baseValue !== null ? uom.display(commentsModalInfo.baseValue) : null}
        targetValue={commentsModalInfo.targetValue !== null ? uom.display(Number(commentsModalInfo.targetValue)) : null}
        unformattedValue={(commentsModalInfo.value || 0) + (commentsModalInfo.exception || 0)}
        lensId={fact.cardFacts.userLensId}
        tags={commentsModalInfo.tags}
        navigateToDetailedView={() => navigateToDetailedView(commentsModalInfo.wellFact)}
        onClose={handleOnModalClose}
        hasDrillerComments={commentsModalInfo.hasDrillerComments}
      />
    ) : null;
  }, [
    commentsModalInfo,
    fact.cardFacts.userLensId,
    handleOnModalClose,
    kpiDescription,
    kpiGroupName,
    navigateToDetailedView,
    rigName,
    uom,
  ]);

  const isActive = useMemo(() => {
    return (
      factInfo?.cardGroupId === fact.rigGroupFacts.tabId &&
      factInfo?.kpiId === fact.cardFacts.kpiType &&
      factInfo?.wellId === +fact.wellFact.label
    );
  }, [fact, factInfo]);

  const handleOnClick = useCallback(() => {
    isActive
      ? dispatch({
          type: "ADD_FACT_INFO",
          payload: null,
        })
      : dispatch({
          type: "ADD_FACT_INFO",
          payload: {
            cardGroupId: fact.rigGroupFacts.tabId,
            kpiId: fact.cardFacts.kpiType,
            wellId: +fact.wellFact.label,
          },
        });
  }, [dispatch, fact.cardFacts.kpiType, fact.rigGroupFacts.tabId, fact.wellFact.label, isActive]);

  const handleOnClicksScoreBreakout = useCallback(() => {
    const opportunityKey = scoreBenchmark === BenchmarkType.OperatorTarget ? "targetDeltaTime" : "invisibleLostTime";
    const opportunityTime = fact?.wellFact.scoreSummary?.[opportunityKey];

    const scoreBreakoutInfo = {
      name: `#${fact?.wellFact.operatorScore.rank} ${wellShortInfo?.byId?.[+fact?.wellFact?.label]?.name ?? ""}`,
      id: selectedRig,
      type: "",
      selectedWell: +fact?.wellFact?.label,
      opportunityTime,
      score:
        scoreBenchmark === BenchmarkType.OperatorTarget
          ? fact?.wellFact?.operatorScore?.value
          : fact?.wellFact?.rigScore?.value,
      scoreSummary: fact?.wellFact.scoreSummary,
      summaryType: TSummary.Well,
      wellInfo: rigCardScore.facts.map((fact) => {
        return {
          wellId: +fact.label,
          rigScore: fact.rigScore?.value || 0,
          operatorScore: fact.operatorScore?.value || 0,
        };
      }),
    };

    openScoreBreakout(scoreBreakoutInfo);
  }, [fact, openScoreBreakout, rigCardScore.facts, scoreBenchmark, selectedRig, wellShortInfo?.byId]);

  const WellScoreRow = (
    <>
      <CommonStyled.RowPadding paddingVertical={10} onClick={handleOnClick} isClickable isActive={isActive} hasBorder>
        <CommonStyled.RequiredCommentRow>
          <CommonStyled.KpiDescription>
            <Styled.WellTitleScores>
              #{rank} {wellName}
            </Styled.WellTitleScores>
            <Styled.JobsList>{jobsLabel}</Styled.JobsList>
          </CommonStyled.KpiDescription>

          <CommonStyled.ControlsContainer>
            <CustomTag {...scoreTag} variant="filled" onClick={handleOnClicksScoreBreakout} />
            <CommonStyled.CommentIconContainer onClick={(ev) => handleOnCommentsClick(ev, true)}>
              <PDComponent.SvgIcon name="chat" />
            </CommonStyled.CommentIconContainer>
          </CommonStyled.ControlsContainer>
        </CommonStyled.RequiredCommentRow>
      </CommonStyled.RowPadding>
      {CommentsModalElement}
      {scoreBreakoutElement}
    </>
  );

  const KpiCommentRow = (
    <>
      <CommonStyled.RowPadding paddingVertical={10} onClick={handleOnClick} isClickable isActive={isActive} hasBorder>
        <CommonStyled.RequiredCommentRow>
          <CommonStyled.KpiDescription>
            <span>
              <strong>{kpiName}:</strong> {uom.display(fact?.wellFact.kpiValue?.value ?? 0)}
            </span>
            Target: {uom.display(fact?.wellFact.targetValue ?? 0)}
          </CommonStyled.KpiDescription>

          <CommonStyled.ControlsContainer>
            <CustomTag {...scoreTag} />
            <CommonStyled.CommentIconContainer onClick={handleOnCommentsClick}>
              <PDComponent.SvgIcon name="chat" />
            </CommonStyled.CommentIconContainer>
          </CommonStyled.ControlsContainer>
        </CommonStyled.RequiredCommentRow>
      </CommonStyled.RowPadding>
      {CommentsModalElement}
    </>
  );

  const ExceptionCommentRow = (
    <>
      <Styled.ExceptionRow isClickable hasBorder isActive={isActive} onClick={handleOnClick}>
        <Styled.Info>
          <Styled.WellTitleException>
            #{rank} {wellName}
          </Styled.WellTitleException>

          <Styled.ExceptionMetricsRow>
            <Styled.OverflowEllipsis>
              <Styled.FaintText>{kpiGroupName}</Styled.FaintText>
            </Styled.OverflowEllipsis>
            <Styled.OverflowEllipsis>
              <Styled.FaintText>Approver:</Styled.FaintText>{" "}
              {(fact as VisibleExceptionViewmodel).comment?.exceptionApproverDisplayName ?? "Unknown approver"}
            </Styled.OverflowEllipsis>
          </Styled.ExceptionMetricsRow>

          <Styled.ExceptionMetricsRowBigFont>
            <Styled.OverflowEllipsis>{kpiName}</Styled.OverflowEllipsis>
            <Styled.OverflowEllipsis>{uom.display(kpiDuration)}</Styled.OverflowEllipsis>
          </Styled.ExceptionMetricsRowBigFont>

          <Styled.ExceptionMetricsRowBigFont>
            <Styled.Bolder>
              <Styled.OverflowEllipsis>Exception hours</Styled.OverflowEllipsis>
            </Styled.Bolder>
            <Styled.Bolder>
              <Styled.OverflowEllipsis>{exceptionTimeLabel}</Styled.OverflowEllipsis>
            </Styled.Bolder>
          </Styled.ExceptionMetricsRowBigFont>
        </Styled.Info>
        <CommonStyled.CommentIconContainer onClick={handleOnCommentsClick}>
          <PDComponent.SvgIcon name="chat" />
        </CommonStyled.CommentIconContainer>
      </Styled.ExceptionRow>
      {CommentsModalElement}
    </>
  );

  switch (type) {
    case "kpi":
      return KpiCommentRow;
    case "exception":
      return ExceptionCommentRow;
    case "wellscore":
      return WellScoreRow;
    default:
      return KpiCommentRow;
  }
};
