/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StandKpiSliceDto,
    StandKpiSliceDtoFromJSON,
    StandKpiSliceDtoFromJSONTyped,
    StandKpiSliceDtoToJSON,
} from './StandKpiSliceDto';

/**
 * 
 * @export
 * @interface StackedStandKpiDetailByShiftDto
 */
export interface StackedStandKpiDetailByShiftDto {
    /**
     * 
     * @type {number}
     * @memberof StackedStandKpiDetailByShiftDto
     */
    dayTargetValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StackedStandKpiDetailByShiftDto
     */
    nightTargetValue?: number | null;
    /**
     * 
     * @type {Array<StandKpiSliceDto>}
     * @memberof StackedStandKpiDetailByShiftDto
     */
    daySlices?: Array<StandKpiSliceDto> | null;
    /**
     * 
     * @type {Array<StandKpiSliceDto>}
     * @memberof StackedStandKpiDetailByShiftDto
     */
    nightSlices?: Array<StandKpiSliceDto> | null;
    /**
     * 
     * @type {number}
     * @memberof StackedStandKpiDetailByShiftDto
     */
    operationCount: number;
    /**
     * 
     * @type {number}
     * @memberof StackedStandKpiDetailByShiftDto
     */
    dayOperationCount: number;
    /**
     * 
     * @type {number}
     * @memberof StackedStandKpiDetailByShiftDto
     */
    nightOperationCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof StackedStandKpiDetailByShiftDto
     */
    isOutlier: boolean;
}

export function StackedStandKpiDetailByShiftDtoFromJSON(json: any): StackedStandKpiDetailByShiftDto {
    return StackedStandKpiDetailByShiftDtoFromJSONTyped(json, false);
}

export function StackedStandKpiDetailByShiftDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StackedStandKpiDetailByShiftDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dayTargetValue': !exists(json, 'DayTargetValue') ? undefined : json['DayTargetValue'],
        'nightTargetValue': !exists(json, 'NightTargetValue') ? undefined : json['NightTargetValue'],
        'daySlices': !exists(json, 'DaySlices') ? undefined : (json['DaySlices'] === null ? null : (json['DaySlices'] as Array<any>).map(StandKpiSliceDtoFromJSON)),
        'nightSlices': !exists(json, 'NightSlices') ? undefined : (json['NightSlices'] === null ? null : (json['NightSlices'] as Array<any>).map(StandKpiSliceDtoFromJSON)),
        'operationCount': json['OperationCount'],
        'dayOperationCount': json['DayOperationCount'],
        'nightOperationCount': json['NightOperationCount'],
        'isOutlier': json['IsOutlier'],
    };
}

export function StackedStandKpiDetailByShiftDtoToJSON(value?: StackedStandKpiDetailByShiftDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DayTargetValue': value.dayTargetValue,
        'NightTargetValue': value.nightTargetValue,
        'DaySlices': value.daySlices === undefined ? undefined : (value.daySlices === null ? null : (value.daySlices as Array<any>).map(StandKpiSliceDtoToJSON)),
        'NightSlices': value.nightSlices === undefined ? undefined : (value.nightSlices === null ? null : (value.nightSlices as Array<any>).map(StandKpiSliceDtoToJSON)),
        'OperationCount': value.operationCount,
        'DayOperationCount': value.dayOperationCount,
        'NightOperationCount': value.nightOperationCount,
        'IsOutlier': value.isOutlier,
    };
}

