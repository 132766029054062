/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RigCardFactKpiValue
 */
export interface RigCardFactKpiValue {
    /**
     * 
     * @type {number}
     * @memberof RigCardFactKpiValue
     */
    value?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardFactKpiValue
     */
    highlightedValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardFactKpiValue
     */
    readonly rank: number;
    /**
     * 
     * @type {number}
     * @memberof RigCardFactKpiValue
     */
    readonly trend: number;
    /**
     * 
     * @type {number}
     * @memberof RigCardFactKpiValue
     */
    exception?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardFactKpiValue
     */
    highlightedException?: number | null;
}

export function RigCardFactKpiValueFromJSON(json: any): RigCardFactKpiValue {
    return RigCardFactKpiValueFromJSONTyped(json, false);
}

export function RigCardFactKpiValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigCardFactKpiValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'Value') ? undefined : json['Value'],
        'highlightedValue': !exists(json, 'HighlightedValue') ? undefined : json['HighlightedValue'],
        'rank': json['Rank'],
        'trend': json['Trend'],
        'exception': !exists(json, 'Exception') ? undefined : json['Exception'],
        'highlightedException': !exists(json, 'HighlightedException') ? undefined : json['HighlightedException'],
    };
}

export function RigCardFactKpiValueToJSON(value?: RigCardFactKpiValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Value': value.value,
        'HighlightedValue': value.highlightedValue,
        'Exception': value.exception,
        'HighlightedException': value.highlightedException,
    };
}

