/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    ServiceMessageDto,
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import {
    StopWatchDto,
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';
import {
    WedgeComparisonWellDto,
    WedgeComparisonWellDtoFromJSON,
    WedgeComparisonWellDtoFromJSONTyped,
    WedgeComparisonWellDtoToJSON,
} from './WedgeComparisonWellDto';
import {
    WedgeSummaryDto,
    WedgeSummaryDtoFromJSON,
    WedgeSummaryDtoFromJSONTyped,
    WedgeSummaryDtoToJSON,
} from './WedgeSummaryDto';
import {
    WedgeSummaryValuesDto,
    WedgeSummaryValuesDtoFromJSON,
    WedgeSummaryValuesDtoFromJSONTyped,
    WedgeSummaryValuesDtoToJSON,
} from './WedgeSummaryValuesDto';

/**
 * 
 * @export
 * @interface WedgeComparisonDto
 */
export interface WedgeComparisonDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof WedgeComparisonDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof WedgeComparisonDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof WedgeComparisonDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof WedgeComparisonDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {Array<WedgeComparisonWellDto>}
     * @memberof WedgeComparisonDto
     */
    comparisons?: Array<WedgeComparisonWellDto> | null;
    /**
     * 
     * @type {WedgeSummaryDto}
     * @memberof WedgeComparisonDto
     */
    summary: WedgeSummaryDto;
    /**
     * 
     * @type {Array<WedgeSummaryValuesDto>}
     * @memberof WedgeComparisonDto
     */
    summaryByKpiNames?: Array<WedgeSummaryValuesDto> | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof WedgeComparisonDto
     */
    dataState: ResultDataState;
}

export function WedgeComparisonDtoFromJSON(json: any): WedgeComparisonDto {
    return WedgeComparisonDtoFromJSONTyped(json, false);
}

export function WedgeComparisonDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WedgeComparisonDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': !exists(json, 'Messages') ? undefined : (json['Messages'] === null ? null : (json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'comparisons': !exists(json, 'Comparisons') ? undefined : (json['Comparisons'] === null ? null : (json['Comparisons'] as Array<any>).map(WedgeComparisonWellDtoFromJSON)),
        'summary': WedgeSummaryDtoFromJSON(json['Summary']),
        'summaryByKpiNames': !exists(json, 'SummaryByKpiNames') ? undefined : (json['SummaryByKpiNames'] === null ? null : (json['SummaryByKpiNames'] as Array<any>).map(WedgeSummaryValuesDtoFromJSON)),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function WedgeComparisonDtoToJSON(value?: WedgeComparisonDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value.stopWatch),
        'Messages': value.messages === undefined ? undefined : (value.messages === null ? null : (value.messages as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value.userLensId,
        'LastUpdatedAt': DateDtoToJSON(value.lastUpdatedAt),
        'Comparisons': value.comparisons === undefined ? undefined : (value.comparisons === null ? null : (value.comparisons as Array<any>).map(WedgeComparisonWellDtoToJSON)),
        'Summary': WedgeSummaryDtoToJSON(value.summary),
        'SummaryByKpiNames': value.summaryByKpiNames === undefined ? undefined : (value.summaryByKpiNames === null ? null : (value.summaryByKpiNames as Array<any>).map(WedgeSummaryValuesDtoToJSON)),
        'DataState': ResultDataStateToJSON(value.dataState),
    };
}

