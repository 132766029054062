/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StandKpiType,
    StandKpiTypeFromJSON,
    StandKpiTypeFromJSONTyped,
    StandKpiTypeToJSON,
} from './StandKpiType';

/**
 * 
 * @export
 * @interface WedgeBreakdownSlice
 */
export interface WedgeBreakdownSlice {
    /**
     * 
     * @type {string}
     * @memberof WedgeBreakdownSlice
     */
    name: string;
    /**
     * 
     * @type {Array<StandKpiType>}
     * @memberof WedgeBreakdownSlice
     */
    kpis: Array<StandKpiType>;
}

export function WedgeBreakdownSliceFromJSON(json: any): WedgeBreakdownSlice {
    return WedgeBreakdownSliceFromJSONTyped(json, false);
}

export function WedgeBreakdownSliceFromJSONTyped(json: any, ignoreDiscriminator: boolean): WedgeBreakdownSlice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['Name'],
        'kpis': ((json['Kpis'] as Array<any>).map(StandKpiTypeFromJSON)),
    };
}

export function WedgeBreakdownSliceToJSON(value?: WedgeBreakdownSlice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Name': value.name,
        'Kpis': ((value.kpis as Array<any>).map(StandKpiTypeToJSON)),
    };
}

