import { useQuery } from "@tanstack/react-query";
import type { DateDto, ListWellQueryDto } from "apis/oag";
import { WellsApi } from "apis/oag";
import type { RangeType } from "atoms/DatePicker";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { useDebounce } from "use-debounce";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import { dateToDateOnlyDto, last30Days, today } from "utils/helper";
import type { PDWellSummariesQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

import { URL_STATE_PARAM, useStateQuery } from "./navigation/useQueryState";
import { useDashboardType } from "./useDashboardType";
import { useOperatorsActiveRigs } from "./useOperatorsActiveRigs";
import { useRigsActiveOperators } from "./useRigsActiveOperators";

const wells = new WellsApi(apiConfig);

export function useWellSummaries(isRigs?: boolean) {
  const { isEvergreen } = useDashboardType();
  const reduxState = isEvergreen ? "evergreenWells" : "allWells";

  const searchState = useAppSelector((state) => state[reduxState].search);
  const [operators] = useStateQuery<Array<number> | null>(URL_STATE_PARAM.SELECTED_OPERATORS_RIG_SCORECARD, null);
  const operatorsState = useAppSelector((state) => (isRigs ? operators : state[reduxState].operators));
  const statusState = useAppSelector((state) => state[reduxState].status);
  const startDateState = useAppSelector((state) => state[reduxState].startDate);
  const endDateState = useAppSelector((state) => state[reduxState].endDate);
  const rigIdsStateAllWells = useAppSelector((state) => state[reduxState].rigIds);
  const formationState = useAppSelector((state) => state[reduxState].formationIds);

  const [rigIdsStateAllRigs] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WELLS_RIGS_WIDGET, []);
  const { dashboardId, rigId } = useParams<{
    dashboardId: string;
    rigId: string;
  }>();
  const selectedRig = dashboardId ? Number.parseInt(dashboardId) : Number.parseInt(rigId || "-1");
  const [period] = useStateQuery<RangeType>(URL_STATE_PARAM.PERIOD_RIG_WIDGET, {
    startDate: last30Days,
    endDate: today,
  });
  const rigIdsState = useMemo(
    () => (isRigs ? [selectedRig, ...rigIdsStateAllRigs] : rigIdsStateAllWells),
    [isRigs, rigIdsStateAllRigs, rigIdsStateAllWells, selectedRig],
  );
  const dispatch = useAppDispatch();

  const rigIds = useOperatorsActiveRigs(operatorsState, isEvergreen, { refetchOnMount: false });
  const operatorIds = useRigsActiveOperators(rigIdsState, isEvergreen, { refetchOnMount: false });

  const startDate = useMemo(() => {
    if (isRigs) {
      return period.startDate;
    }
    return startDateState || defaultDateDto.from.utc;
  }, [isRigs, period.startDate, startDateState]);

  const endDate = useMemo(() => {
    if (isRigs) {
      return period.endDate;
    }
    return endDateState || defaultDateDto.to.utc;
  }, [isRigs, period.endDate, endDateState]);

  const requestQuery: ListWellQueryDto = useMemo(() => {
    return {
      search: isRigs ? "" : searchState,
      operatorIds:
        operatorIds.data?.filter((operatorId) => (operatorsState ? operatorsState.includes(operatorId) : true)) ?? null,
      rigIds: rigIds.data?.filter((rigId) => (rigIdsState ? rigIdsState.includes(rigId) : true)) ?? null,
      status: isRigs ? null : statusState,
      from: dateToDateOnlyDto(startDate || defaultDateDto.from.utc),
      to: dateToDateOnlyDto(endDate || defaultDateDto.to.utc),
      includeEverGreenOnly: isEvergreen,
      formationIds: formationState ?? null,
    };
  }, [isRigs, searchState, operatorIds.data, rigIds.data, statusState, startDate, endDate, isEvergreen, formationState, operatorsState, rigIdsState]);

  const [debouncedQuery] = useDebounce(requestQuery, 1000, {
    leading: true,
    equalityFn: (a, b) => JSON.stringify(a) === JSON.stringify(b),
  });

  const queryKey: PDWellSummariesQueryKey = {
    type: PDQueryType.WELL_SUMMARIES,
    uid: RequestUID.wellSummaries,
    debouncedQuery,
  };

  const data = useQuery({
    queryKey: [queryKey],
    queryFn: () => {
      return wells.apiWellsSummariesPut({
        listWellQueryDto: debouncedQuery,
      });
    },
      enabled:
        !!debouncedQuery.operatorIds &&
        !!debouncedQuery.rigIds &&
        !!operatorIds.data &&
        !!rigIds.data &&
        (isRigs ? true : rigIdsStateAllWells !== undefined),
      staleTime: 10000,
   
 } );
 useEffect(() => {
  if (data.isFetched) {
    const wells = data?.data?.wells || [];
      if (wells) {
        const lastUpdatedAtDate = wells.reduce((acc: DateDto | null, well) => {
          const freshDate = isRigs ? well.lastRigStateFactUpdateAt : well.lastWellFactUpdateAt;
          if (!freshDate) return acc;
          const date1 = new Date(freshDate.utc).getTime();
          const date2 = acc ? new Date(acc.utc).getTime() : null;
          if (!date2) return freshDate;
          if (date1 > date2) return freshDate;
          return acc;
        }, null);
        dispatch({
          type: (() => {
            if (isRigs) return "SET_LAST_REFRESH_DATE";
            if (isEvergreen) return "EVERGREEN_WELLS_SET_LAST_UPDATE_DATE";
            return "ALL_WELLS_SET_LAST_UPDATE_DATE";
          })(),
          payload: lastUpdatedAtDate,
        });
      }
    }
  }, [data, dispatch, isEvergreen, isRigs]);
  return data;
}
