import { DimensionType, PivotType } from "apis/oag";
import { Text, Title } from "atoms/Typography";
import { usePivotLabels } from "hooks/usePivotLabels";
import type { FactWithLabel } from "pages/AllRigs/components/LeaderboardSection/Card/interfaces";
import { useHoveredDataContext } from "pages/AllRigs/components/LeaderboardSection/helpers/HoveredDataContext";
import {
  SelectedMetric,
  useLeaderboardMetricSelectionContext,
} from "pages/AllRigs/components/LeaderboardSection/LeaderboardMetricSelectionContext";
import {
  isTimePivot,
  LABEL_TRUNCATION_LENGTH,
  TABLE_HEADER_HEIGHT,
} from "pages/AllRigs/components/LeaderboardSection/utils";
import { useSelectedRigsContext } from "pages/AllRigs/RigList/SelectedRigsContext";
import type { FC } from "react";
import { useMemo } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import type { ListChildComponentProps } from "react-window";
import { FixedSizeList as List } from "react-window";
import styled from "styled-components";
import { Space } from "utils/componentLibrary";
import type { UOMHelper } from "utils/format";
import { truncateMiddleString } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

import {
  StyledBodyCell,
  StyledHeaderCell,
  StyledKpiValue,
  StyledLabelText,
  StyledTableContainer,
  StyledTableHeader,
  StyledTableRow,
  StyledTag,
  StyledTagContainer,
} from "./styled";

const LABEL_MIN_WIDTH = 100;
const LABEL_WIDTH_DIVIDED = 30;

const StyledList = styled(List)`
  outline: none;
  overflow-y: scroll !important;
`;

const StyledRow = styled.div`
  padding: 0px;
`;

export const AnonymizedData = styled.div`
  width: 40px;
  height: 14px;
  flex-grow: 0;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};
  align-self: center;
  margin-left: auto;
  margin-right: auto;
`;

const SingleRow: FC<{
  details: FactWithLabel;
  width: number;
  isSplit: boolean;
  uom: UOMHelper;
  pivotType: PivotType;
}> = ({ details, uom, pivotType, isSplit, width }) => {
  const { rank, trend, value, highlightedValue, label } = details;
  const { isDataAnonymity } = useSelectedRigsContext();
  const { setHoveredDataRank } = useHoveredDataContext();

  const { getLabelFromKey } = usePivotLabels();
  const { atomThemeVariant } = useCustomTheme();

  const { selectedMetric } = useLeaderboardMetricSelectionContext();
  const isNetTimes = useMemo(() => selectedMetric === SelectedMetric.NetTimes, [selectedMetric]);

  const cellValue = useMemo(() => {
    return value || value === 0 ? uom.display(value, { fractionDigits: 1, unit: "" }) : "";
  }, [uom, value]);

  const cellAbbrv = useMemo(() => {
    return uom.dimension === DimensionType.Seconds ? (isNetTimes ? "hr" : "min") : uom.abbr;
  }, [isNetTimes, uom.abbr, uom.dimension]);

  const showNonAnonymizedData = useMemo(
    () =>
      isDataAnonymity && ([PivotType.Rig, PivotType.Well, PivotType.Operator] as PivotType[]).includes(pivotType)
        ? highlightedValue
        : true,
    [highlightedValue, isDataAnonymity, pivotType],
  );

  return (
    <StyledTableRow
      $isHighlighted={!!(!isTimePivot(pivotType) && highlightedValue)}
      {...(isSplit
        ? {
            onMouseOver: () => {
              setHoveredDataRank(rank);
            },
            onMouseOut: () => {
              setHoveredDataRank(null);
            },
          }
        : {})}
    >
      <StyledBodyCell>
        <StyledLabelText variant={atomThemeVariant}>{rank}</StyledLabelText>
      </StyledBodyCell>
      <StyledBodyCell>
        {showNonAnonymizedData ? (
          <StyledLabelText variant={atomThemeVariant}>
            {truncateMiddleString(
              getLabelFromKey(label, pivotType),
              width > LABEL_MIN_WIDTH ? width / LABEL_WIDTH_DIVIDED : LABEL_TRUNCATION_LENGTH,
            )}
          </StyledLabelText>
        ) : (
          <AnonymizedData />
        )}
      </StyledBodyCell>
      <StyledBodyCell>
        <StyledTagContainer>
          <StyledTag $positiveValue={trend >= 0}>
            {trend < 0 ? "" : "+"}
            {(trend * 100).toFixed(1)}%
          </StyledTag>
        </StyledTagContainer>
      </StyledBodyCell>
      <StyledBodyCell>
        <Space size={2}>
          <StyledKpiValue variant={atomThemeVariant}>{cellValue}</StyledKpiValue>
          <Title level={5} variant="faded">
            {cellAbbrv}
          </Title>
        </Space>
      </StyledBodyCell>
    </StyledTableRow>
  );
};

const TableRow = ({
  style,
  index,
  data,
  uom,
  width,
  pivotType,
  isSplit,
}: ListChildComponentProps & {
  uom: UOMHelper;
  pivotType: PivotType;
  isSplit: boolean;
  width: number;
}) => (
  <StyledRow style={style}>
    <SingleRow details={data[index]} uom={uom} width={width} pivotType={pivotType} isSplit={isSplit} />
  </StyledRow>
);
const ListScroll = ({
  height,
  width,
  data,
  uom,
  pivotType,
  isSplit,
}: {
  height: number;
  width: number;
  data: FactWithLabel[];
  uom: UOMHelper;
  pivotType: PivotType;
  isSplit: boolean;
}) => (
  <StyledList height={height} width={width} itemSize={24} itemCount={data.length} itemData={data}>
    {({ style, index, data }) => (
      <TableRow
        {...{
          width,
          style,
          index,
          data,
          uom,
          pivotType,
          isSplit,
        }}
      />
    )}
  </StyledList>
);
export default function LeaderboardTable({
  data,
  uom,
  pivotType,
  selectedMetric,
  isSplit,
  isExpanded,
}: {
  data: FactWithLabel[];
  uom: UOMHelper;
  pivotType: PivotType;
  selectedMetric: SelectedMetric;
  isSplit?: boolean;
  isExpanded?: boolean;
}) {
  const customColumnLabel = useMemo(() => {
    switch (selectedMetric) {
      case SelectedMetric.NetTimes:
        return "Net Time";
      case SelectedMetric.Scores:
        return "Score";
      default:
        return "KPI";
    }
  }, [selectedMetric]);

  return (
    <StyledTableContainer $isExpanded={!!isExpanded}>
      <>
        <StyledTableHeader>
          <StyledHeaderCell>
            <Text primary="description" variant="faded" fontSize={14}>
              {isTimePivot(pivotType) ? "Order" : "Rank"}
            </Text>
          </StyledHeaderCell>
          <StyledHeaderCell>
            <Text primary="description" variant="faded" fontSize={14}>
              {pivotType}
            </Text>
          </StyledHeaderCell>
          <StyledHeaderCell>
            <Text primary="description" variant="faded" fontSize={14}>
              {pivotType === PivotType.Well || pivotType === PivotType.Rig ? "Avg Comp." : "Trend"}
            </Text>
          </StyledHeaderCell>
          <StyledHeaderCell>
            <Text primary="description" variant="faded" fontSize={14}>
              {customColumnLabel}
            </Text>
          </StyledHeaderCell>
        </StyledTableHeader>

        {data ? (
          <AutoSizer>
            {({ height, width }: { height: number; width: number }) => {
              const tableHeight = height - TABLE_HEADER_HEIGHT > 0 ? height - TABLE_HEADER_HEIGHT : 0;
              return (
                <ListScroll
                  height={tableHeight}
                  width={width - 12}
                  data={data}
                  uom={uom}
                  pivotType={pivotType}
                  isSplit={!!isSplit}
                />
              );
            }}
          </AutoSizer>
        ) : null}
      </>
    </StyledTableContainer>
  );
}
