import { Button } from "atoms/Form";
import { PDComponent } from "components/PDComponents";
import type { IOption } from "components/PDComponents/Search/utils";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useRigToWells } from "hooks/useRigToWells";
import { useWellShortInfo } from "hooks/useWellShortInfo";
import { useLayoutEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { PrimarySelectorEnum } from "reducers/rigDashboardReducer";
import { Track } from "services/Mixpanel";
import { Popover, Tooltip } from "utils/componentLibrary";
import { generateQuickGuid } from "utils/helper";

// TODO make offset selector agnostic from well or rig

export const WellSelector = ({
  detailed,
  urlStateParam = URL_STATE_PARAM.SELECTED_WELLS_RIG_DASHBOARD,
  numSelectRecent,
  isFilteringIgnored,
  isGroupable = false,
  dontSetSelectedWells,
}: {
  detailed?: boolean;
  urlStateParam?: URL_STATE_PARAM;
  numSelectRecent?: number;
  isFilteringIgnored?: boolean;
  isGroupable?: boolean;
  dontSetSelectedWells?: boolean;
}) => {
  const [showSelect, setShowSelect] = useState(false);

  // use the suspended version for those 2
  const { data: wellData } = useWellShortInfo();
  const { data: availableWells, isFetching } = useRigToWells({
    isFilteringIgnored,
  });
  const location = useLocation();
  const isScorecard = location.pathname?.startsWith("/rig/scorecard");
  const [primarySelector] = useStateQuery<PrimarySelectorEnum>(
    URL_STATE_PARAM.PRIMARY_SELECTOR_RIGS,
    PrimarySelectorEnum.NONE,
  );
  const [operators] = useStateQuery<Array<number> | null>(URL_STATE_PARAM.SELECTED_OPERATORS_RIGS, null);
  const [selectedWells, setSelectedWells] = useStateQuery<Array<number> | null>(
    detailed ? URL_STATE_PARAM.SELECTED_WELLS_RIG_WIDGET : urlStateParam,
    null,
    detailed ? [] : [URL_STATE_PARAM.SELECTED_WELLS_RIG_WIDGET],
  );

  const onOffsetWellsChange = (selectedOffsetWells: number[] = []) => {
    Track.interact("Rig Dashboard - Well Selection");
    // TODO add errors too many selected
    setSelectedWells(selectedOffsetWells);
    setShowSelect(false);
    return true;
  };

  const options = useMemo<Array<IOption<number>>>(() => {
    if (primarySelector === PrimarySelectorEnum.OPERATOR) {
      return (wellData?.list ?? [])
        .filter((well) => (operators || []).includes(well.operatorId))
        .filter((e) => (availableWells ?? []).includes(e.id))
        .map((filteredWell) => ({
          id: filteredWell.id,
          name: filteredWell.name || "",
          description:
            filteredWell.jobExternalIds.length && isScorecard ? `Job: ${filteredWell.jobExternalIds.join(", ")}` : null,
          groupId: filteredWell.padExternalId || generateQuickGuid(),
        }));
    }
    return (wellData?.list ?? [])
      .filter((e) => (availableWells ?? []).includes(e.id))
      .sort((a, b) => (availableWells || []).indexOf(a.id) - (availableWells || []).indexOf(b.id))
      .map((filteredWell) => ({
        id: filteredWell.id,
        name: filteredWell.name || "",
        description:
          filteredWell.jobExternalIds.length && isScorecard ? `Job: ${filteredWell.jobExternalIds.join(", ")}` : null,
        groupId: filteredWell.padExternalId || generateQuickGuid(),
      }));
  }, [availableWells, operators, primarySelector, isScorecard, wellData?.list]);

  useLayoutEffect(() => {
    if (dontSetSelectedWells) return;
    if (isFetching) return;
    const recent = (availableWells || []).slice(0, numSelectRecent ? numSelectRecent : (availableWells || []).length);
    setSelectedWells(recent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dontSetSelectedWells, availableWells, isFetching, numSelectRecent]);

  const [isGroupChecked, setIsGroupChecked] = useState(false);

  return (
    <Tooltip title={isScorecard ? "Wells" : "Offset Wells"}>
      <Popover
        content={
          <div>
            <PDComponent.ComboBoxMultiSelect
              noSelectAll
              numSelectRecent={numSelectRecent}
              placeholder="Search"
              width={425}
              height={329}
              options={options}
              getLastNItemsIds={() => (availableWells || []).slice(0, 5)}
              values={selectedWells || []}
              onChange={onOffsetWellsChange}
              groupingOptions={
                isGroupable
                  ? {
                      isGroupChecked,
                      setIsGroupChecked,
                      groupName: "Pads",
                    }
                  : undefined
              }
            />
          </div>
        }
        trigger="click"
        placement="bottom"
        open={showSelect}
        onOpenChange={(e) => setShowSelect(e)}
        destroyTooltipOnHide
      >
        <Button
          trackingName="Add Wells"
          size="large"
          icon={<PDComponent.SvgIcon name="wellInfo" />}
          $engaged={showSelect}
          loading={isFetching}
        >
          {(selectedWells || [])?.length >= 0 ? `${(selectedWells || []).length} Wells` : null}
        </Button>
      </Popover>
    </Tooltip>
  );
};
