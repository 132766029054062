/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    DimensionType,
    DimensionTypeFromJSON,
    DimensionTypeFromJSONTyped,
    DimensionTypeToJSON,
} from './DimensionType';
import {
    GhgEmissionsFactDto,
    GhgEmissionsFactDtoFromJSON,
    GhgEmissionsFactDtoFromJSONTyped,
    GhgEmissionsFactDtoToJSON,
} from './GhgEmissionsFactDto';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    ServiceMessageDto,
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import {
    StopWatchDto,
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';

/**
 * 
 * @export
 * @interface GhgEmissionsFactSetDto
 */
export interface GhgEmissionsFactSetDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof GhgEmissionsFactSetDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof GhgEmissionsFactSetDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof GhgEmissionsFactSetDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof GhgEmissionsFactSetDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {DimensionType}
     * @memberof GhgEmissionsFactSetDto
     */
    dimensionType: DimensionType;
    /**
     * 
     * @type {Array<GhgEmissionsFactDto>}
     * @memberof GhgEmissionsFactSetDto
     */
    facts?: Array<GhgEmissionsFactDto> | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof GhgEmissionsFactSetDto
     */
    dataState: ResultDataState;
}

export function GhgEmissionsFactSetDtoFromJSON(json: any): GhgEmissionsFactSetDto {
    return GhgEmissionsFactSetDtoFromJSONTyped(json, false);
}

export function GhgEmissionsFactSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GhgEmissionsFactSetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': !exists(json, 'Messages') ? undefined : (json['Messages'] === null ? null : (json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'dimensionType': DimensionTypeFromJSON(json['DimensionType']),
        'facts': !exists(json, 'Facts') ? undefined : (json['Facts'] === null ? null : (json['Facts'] as Array<any>).map(GhgEmissionsFactDtoFromJSON)),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function GhgEmissionsFactSetDtoToJSON(value?: GhgEmissionsFactSetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value.stopWatch),
        'Messages': value.messages === undefined ? undefined : (value.messages === null ? null : (value.messages as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value.userLensId,
        'LastUpdatedAt': DateDtoToJSON(value.lastUpdatedAt),
        'DimensionType': DimensionTypeToJSON(value.dimensionType),
        'Facts': value.facts === undefined ? undefined : (value.facts === null ? null : (value.facts as Array<any>).map(GhgEmissionsFactDtoToJSON)),
        'DataState': ResultDataStateToJSON(value.dataState),
    };
}

