import type { UseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { ApiPivotKpiGroupUserLensesIdPivotFactsPutRequest, KpiPivotFactSetDto } from "apis/oag";
import {
  LensTemplateType,
  PivotKpiGroupUserLensesApi,
  PivotKpiTypeUserLensesApi,
  PivotOrderType,
  PivotType,
} from "apis/oag";
import type { RangeType } from "atoms/DatePicker";
import {
  getDefaultPivotOrderTypeByPivot,
  getDisplayOptions,
} from "components/RigComparisonSelectors/DisplayOptionsModal";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useSelectedRig } from "hooks/useSelectedRig";
import { assign } from "lodash";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import type { IFiltersType } from "reducers/types";
import { apiConfig } from "utils/apiConfig";
import { TVD_SAMPLE_COUNT } from "utils/common";
import { dateToDateOnlyDto } from "utils/helper";
import { last30Days, today } from "utils/helper";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

import { pivotsOrderArrayToObject } from "./useStackedKpiMock";

export type TOrder = {
  pivotLevel1Order: PivotOrderType;
  pivotLevel2Order: PivotOrderType;
  pivotLevel3Order: PivotOrderType;
};
const stackedApi = new PivotKpiGroupUserLensesApi(apiConfig);
const singleApi = new PivotKpiTypeUserLensesApi(apiConfig);

export const getCorrectOrder = (
  crtPivots: Array<PivotType>,
  displayOptions: {
    pivot: Array<PivotType>;
    order: Array<PivotOrderType>;
  },
  isComparison: boolean,
) => {
  const indexPivotRig = (displayOptions.pivot ?? []).findIndex((e) => e === PivotType.Rig);
  // remove index 3 from displayOptions.order
  const pivotIndexes = displayOptions.pivot.reduce<Array<number>>((a, e, i) => {
    if (e === (isComparison ? PivotType.StandNumber : PivotType.Rig) || e === PivotType.None) {
      a.push(i);
    }
    return a;
  }, []);
  const pivotLevelIndex = crtPivots.findIndex((e) => e === PivotType.Rig);
  const order = displayOptions.order.filter((_x, i) => !pivotIndexes.includes(i));

  if (isComparison) {
    if (indexPivotRig !== -1) {
      return assign(Array(3).fill(PivotOrderType.Unknown), [...displayOptions.order]);
    }
    return assign(Array(3).fill(PivotOrderType.Unknown), [
      ...order.slice(0, pivotLevelIndex),
      PivotOrderType.UserInputAscending,
      ...order.slice(pivotLevelIndex),
    ]);
  }
  return assign(Array(2).fill(PivotOrderType.Unknown), [...order]) as unknown as PivotOrderType[];
};

export const pivotArrayToObject: (pivots: Array<PivotType>) => {
  pivotLevel1: PivotType;
  pivotLevel2: PivotType;
  pivotLevel3: PivotType;
} = (pivots: Array<PivotType>) => {
  const MAX_LEN_PIVOTS = 3;

  return Object.assign(
    {},
    ...assign(
      new Array(MAX_LEN_PIVOTS).fill(PivotType.None),
      pivots.filter((e) => e !== PivotType.None),
    ).map((option: PivotType, index) => ({
      [`pivotLevel${index + 1}`]: option,
    })),
  ) as {
    pivotLevel1: PivotType;
    pivotLevel2: PivotType;
    pivotLevel3: PivotType;
  };
};

export function useKpiGroupPivotFacts(
  lensId: number,
  options?: Omit<UseQueryOptions<KpiPivotFactSetDto>, "queryKey" | "queryFn">,
  stacked = false,
) {
  const { lensId: id } = useParams<{ lensId: string }>();
  const isDetailedView = !!id;
  const [filters] = useStateQuery<IFiltersType>(URL_STATE_PARAM.FILTERS_WIDGET, {
    sections: null,
    directionalIntervals: null,
    operationTime: null,
    holeSizes: null,
  });
  const {
    sections: sectionIds,
    operationTime,
    directionalIntervals: directionalIntervalIds,
    holeSizes: holeSizeIds,
  } = filters;
  const [displayOptions] = useStateQuery<{
    pivot: Array<PivotType>;
    order: Array<PivotOrderType>;
  }>(
    URL_STATE_PARAM.DATA_GROUPS_DASHBOARD,
    {
      pivot: [PivotType.Day, PivotType.None],
      order: [PivotType.Day, PivotType.None].map((e) => getDefaultPivotOrderTypeByPivot(e)),
    },
    [URL_STATE_PARAM.DATA_GROUPS_WIDGET],
  );
  const [offsetRigs] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WELLS_RIGS_WIDGET, []);
  const [selectedWells] = useStateQuery<Array<number>>(URL_STATE_PARAM.SELECTED_WELLS_RIG_WIDGET, []);
  const [periodState] = useStateQuery<RangeType>(
    isDetailedView ? URL_STATE_PARAM.PERIOD_RIG_WIDGET : URL_STATE_PARAM.PERIOD_RIG_DASHBOARD,
    {
      startDate: last30Days,
      endDate: today,
    },
  );
  const selectedRig = useSelectedRig();
  const pivots = pivotArrayToObject(getDisplayOptions(offsetRigs.length > 0)(displayOptions.pivot));
  const order = pivotsOrderArrayToObject(getCorrectOrder(Object.values(pivots), displayOptions, offsetRigs.length > 0));

  const getRequestParameters: (selectedRigIds: Array<number>) => ApiPivotKpiGroupUserLensesIdPivotFactsPutRequest = useCallback((selectedRigIds) => ({
    id: lensId ?? -1,
    rigKpiPivotQueryDto: {
      useMockData: false,
      sampleCount: TVD_SAMPLE_COUNT,
      comparisonWellIds: selectedWells,
      selectedFilters: {
        sectionIds,
        directionalIntervalIds,
        holeSizeIds,
        includeFlatTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Flat Time"),
        includeSlidingTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Sliding Time"),
        includeRotatingTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Rotating Time"),
        includeNullHoleDepth: true,
      },
      from: dateToDateOnlyDto(periodState.startDate || last30Days),
      to: dateToDateOnlyDto(periodState.endDate || today),
      selectedRigIds,
      ...order,
      ...pivots,
    },
  }), [directionalIntervalIds, holeSizeIds, lensId, operationTime, order, periodState.endDate, periodState.startDate, pivots, sectionIds, selectedWells]);

  const queryKey: PDLensFactsQueryKey<ApiPivotKpiGroupUserLensesIdPivotFactsPutRequest> & {
    templateType: "PivotKpiType" | "PivotKpiGroup";
  } = {
    type: PDQueryType.FACTS,
    uid: RequestUID.kpiGroupPivotFacts,
    // Don't trigger reset on sorted rigs updates
    params: getRequestParameters([]),
    lensId,
    templateType: stacked ? LensTemplateType.PivotKpiGroup : LensTemplateType.PivotKpiType,
  };

  return useSuspenseQuery<KpiPivotFactSetDto>({
    queryKey: [queryKey],
    queryFn: ({ signal }) => {
      if (stacked)
        return stackedApi.apiPivotKpiGroupUserLensesIdPivotFactsPut(getRequestParameters([selectedRig, ...offsetRigs]), { signal })
      return singleApi.apiPivotKpiTypeUserLensesIdPivotFactsPut(getRequestParameters([selectedRig, ...offsetRigs]), { signal });
    },
    ...options,
  });
}
