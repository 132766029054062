import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { RigsApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDRigsActiveOperatorsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const rigs = new RigsApi(apiConfig);

export function useRigsActiveOperators(
  rigIds: number[] | null,
  filterEverGreenOnly: boolean,
  options: Omit<UseQueryOptions<Array<number>>, "queryKey" | "queryFn"> = {},
) {
  const requestBody = rigIds ? rigIds.filter((e) => e) : undefined;

  const RigsActiveOperatorQueryKey: PDRigsActiveOperatorsQueryKey = {
    uid: RequestUID.rigsActiveOperators,
    type: PDQueryType.RIGS_ACTIVE_OPERATORS,
    requestBody,
    filterEverGreenOnly,
  };

  return useQuery<Array<number>>({
    queryKey: [RigsActiveOperatorQueryKey],
    queryFn: () => {
      return rigs.apiRigsActiveOperatorIdsFilterEverGreenOnlyPut({
        requestBody,
        filterEverGreenOnly,
      });
    },
    ...options,
    refetchOnMount: false,
  });
}
