/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    TagBottomFingerprintOverallFactDto,
    TagBottomFingerprintOverallFactDtoFromJSON,
    TagBottomFingerprintOverallFactDtoFromJSONTyped,
    TagBottomFingerprintOverallFactDtoToJSON,
} from './TagBottomFingerprintOverallFactDto';
import {
    TagBottomFingerprintStandSeriesDto,
    TagBottomFingerprintStandSeriesDtoFromJSON,
    TagBottomFingerprintStandSeriesDtoFromJSONTyped,
    TagBottomFingerprintStandSeriesDtoToJSON,
} from './TagBottomFingerprintStandSeriesDto';

/**
 * 
 * @export
 * @interface TagBottomFingerprintTrackDetailsDto
 */
export interface TagBottomFingerprintTrackDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintTrackDetailsDto
     */
    trackId: number;
    /**
     * 
     * @type {Array<TagBottomFingerprintStandSeriesDto>}
     * @memberof TagBottomFingerprintTrackDetailsDto
     */
    standSeries: Array<TagBottomFingerprintStandSeriesDto>;
    /**
     * 
     * @type {Array<TagBottomFingerprintOverallFactDto>}
     * @memberof TagBottomFingerprintTrackDetailsDto
     */
    overallFacts: Array<TagBottomFingerprintOverallFactDto>;
    /**
     * 
     * @type {ResultDataState}
     * @memberof TagBottomFingerprintTrackDetailsDto
     */
    dataState: ResultDataState;
}

export function TagBottomFingerprintTrackDetailsDtoFromJSON(json: any): TagBottomFingerprintTrackDetailsDto {
    return TagBottomFingerprintTrackDetailsDtoFromJSONTyped(json, false);
}

export function TagBottomFingerprintTrackDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagBottomFingerprintTrackDetailsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trackId': json['TrackId'],
        'standSeries': ((json['StandSeries'] as Array<any>).map(TagBottomFingerprintStandSeriesDtoFromJSON)),
        'overallFacts': ((json['OverallFacts'] as Array<any>).map(TagBottomFingerprintOverallFactDtoFromJSON)),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function TagBottomFingerprintTrackDetailsDtoToJSON(value?: TagBottomFingerprintTrackDetailsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'TrackId': value.trackId,
        'StandSeries': ((value.standSeries as Array<any>).map(TagBottomFingerprintStandSeriesDtoToJSON)),
        'OverallFacts': ((value.overallFacts as Array<any>).map(TagBottomFingerprintOverallFactDtoToJSON)),
        'DataState': ResultDataStateToJSON(value.dataState),
    };
}

