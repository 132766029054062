/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const GeneratorSlotType = {
    None: 'None',
    Generator1: 'Generator1',
    Generator2: 'Generator2',
    Generator3: 'Generator3',
    Generator4: 'Generator4'
} as const;
export type GeneratorSlotType = typeof GeneratorSlotType[keyof typeof GeneratorSlotType];


export function GeneratorSlotTypeFromJSON(json: any): GeneratorSlotType {
    return GeneratorSlotTypeFromJSONTyped(json, false);
}

export function GeneratorSlotTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneratorSlotType {
    return json as GeneratorSlotType;
}

export function GeneratorSlotTypeToJSON(value?: GeneratorSlotType | null): any {
    return value as any;
}

