/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    FiltersDto,
    FiltersDtoFromJSON,
    FiltersDtoFromJSONTyped,
    FiltersDtoToJSON,
} from './FiltersDto';
import {
    PivotOrderType,
    PivotOrderTypeFromJSON,
    PivotOrderTypeFromJSONTyped,
    PivotOrderTypeToJSON,
} from './PivotOrderType';
import {
    PivotType,
    PivotTypeFromJSON,
    PivotTypeFromJSONTyped,
    PivotTypeToJSON,
} from './PivotType';

/**
 * 
 * @export
 * @interface RigKpiPivotQueryDto
 */
export interface RigKpiPivotQueryDto {
    /**
     * 
     * @type {boolean}
     * @memberof RigKpiPivotQueryDto
     */
    useMockData: boolean;
    /**
     * 
     * @type {number}
     * @memberof RigKpiPivotQueryDto
     */
    sampleCount: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof RigKpiPivotQueryDto
     */
    comparisonWellIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof RigKpiPivotQueryDto
     */
    selectedRigIds?: Array<number> | null;
    /**
     * 
     * @type {FiltersDto}
     * @memberof RigKpiPivotQueryDto
     */
    selectedFilters: FiltersDto;
    /**
     * 
     * @type {number}
     * @memberof RigKpiPivotQueryDto
     */
    zoomStartDuration?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigKpiPivotQueryDto
     */
    zoomEndDuration?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigKpiPivotQueryDto
     */
    zoomStartDepth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigKpiPivotQueryDto
     */
    zoomEndDepth?: number | null;
    /**
     * 
     * @type {DateDto}
     * @memberof RigKpiPivotQueryDto
     */
    from: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof RigKpiPivotQueryDto
     */
    to: DateDto;
    /**
     * 
     * @type {PivotType}
     * @memberof RigKpiPivotQueryDto
     */
    pivotLevel1: PivotType;
    /**
     * 
     * @type {PivotType}
     * @memberof RigKpiPivotQueryDto
     */
    pivotLevel2: PivotType;
    /**
     * 
     * @type {PivotType}
     * @memberof RigKpiPivotQueryDto
     */
    pivotLevel3: PivotType;
    /**
     * 
     * @type {PivotOrderType}
     * @memberof RigKpiPivotQueryDto
     */
    pivotLevel1Order: PivotOrderType;
    /**
     * 
     * @type {PivotOrderType}
     * @memberof RigKpiPivotQueryDto
     */
    pivotLevel2Order: PivotOrderType;
    /**
     * 
     * @type {PivotOrderType}
     * @memberof RigKpiPivotQueryDto
     */
    pivotLevel3Order: PivotOrderType;
}

export function RigKpiPivotQueryDtoFromJSON(json: any): RigKpiPivotQueryDto {
    return RigKpiPivotQueryDtoFromJSONTyped(json, false);
}

export function RigKpiPivotQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigKpiPivotQueryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'useMockData': json['UseMockData'],
        'sampleCount': json['SampleCount'],
        'comparisonWellIds': !exists(json, 'ComparisonWellIds') ? undefined : json['ComparisonWellIds'],
        'selectedRigIds': !exists(json, 'SelectedRigIds') ? undefined : json['SelectedRigIds'],
        'selectedFilters': FiltersDtoFromJSON(json['SelectedFilters']),
        'zoomStartDuration': !exists(json, 'ZoomStartDuration') ? undefined : json['ZoomStartDuration'],
        'zoomEndDuration': !exists(json, 'ZoomEndDuration') ? undefined : json['ZoomEndDuration'],
        'zoomStartDepth': !exists(json, 'ZoomStartDepth') ? undefined : json['ZoomStartDepth'],
        'zoomEndDepth': !exists(json, 'ZoomEndDepth') ? undefined : json['ZoomEndDepth'],
        'from': DateDtoFromJSON(json['From']),
        'to': DateDtoFromJSON(json['To']),
        'pivotLevel1': PivotTypeFromJSON(json['PivotLevel1']),
        'pivotLevel2': PivotTypeFromJSON(json['PivotLevel2']),
        'pivotLevel3': PivotTypeFromJSON(json['PivotLevel3']),
        'pivotLevel1Order': PivotOrderTypeFromJSON(json['PivotLevel1Order']),
        'pivotLevel2Order': PivotOrderTypeFromJSON(json['PivotLevel2Order']),
        'pivotLevel3Order': PivotOrderTypeFromJSON(json['PivotLevel3Order']),
    };
}

export function RigKpiPivotQueryDtoToJSON(value?: RigKpiPivotQueryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'UseMockData': value.useMockData,
        'SampleCount': value.sampleCount,
        'ComparisonWellIds': value.comparisonWellIds,
        'SelectedRigIds': value.selectedRigIds,
        'SelectedFilters': FiltersDtoToJSON(value.selectedFilters),
        'ZoomStartDuration': value.zoomStartDuration,
        'ZoomEndDuration': value.zoomEndDuration,
        'ZoomStartDepth': value.zoomStartDepth,
        'ZoomEndDepth': value.zoomEndDepth,
        'From': DateDtoToJSON(value.from),
        'To': DateDtoToJSON(value.to),
        'PivotLevel1': PivotTypeToJSON(value.pivotLevel1),
        'PivotLevel2': PivotTypeToJSON(value.pivotLevel2),
        'PivotLevel3': PivotTypeToJSON(value.pivotLevel3),
        'PivotLevel1Order': PivotOrderTypeToJSON(value.pivotLevel1Order),
        'PivotLevel2Order': PivotOrderTypeToJSON(value.pivotLevel2Order),
        'PivotLevel3Order': PivotOrderTypeToJSON(value.pivotLevel3Order),
    };
}

