/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccountDto,
    AccountDtoFromJSON,
    AccountDtoToJSON,
    AccountQueryDto,
    AccountQueryDtoFromJSON,
    AccountQueryDtoToJSON,
    AccountRigAccessSetDto,
    AccountRigAccessSetDtoFromJSON,
    AccountRigAccessSetDtoToJSON,
    OperatorClaimDto,
    OperatorClaimDtoFromJSON,
    OperatorClaimDtoToJSON,
    OperatorClaimsQueryDto,
    OperatorClaimsQueryDtoFromJSON,
    OperatorClaimsQueryDtoToJSON,
    OperatorDto,
    OperatorDtoFromJSON,
    OperatorDtoToJSON,
    UserQueryDto,
    UserQueryDtoFromJSON,
    UserQueryDtoToJSON,
    UserWithAccessCountDto,
    UserWithAccessCountDtoFromJSON,
    UserWithAccessCountDtoToJSON,
    WellAccessQueryDto,
    WellAccessQueryDtoFromJSON,
    WellAccessQueryDtoToJSON,
} from '../models';

export interface ApiOperatorsAccountsPutRequest {
    accountQueryDto?: AccountQueryDto;
}

export interface ApiOperatorsActiveRigIdsFilterEverGreenOnlyPutRequest {
    filterEverGreenOnly: boolean;
    requestBody?: Array<number>;
}

export interface ApiOperatorsIdActiveAllPutRequest {
    id: number;
}

export interface ApiOperatorsIdClaimsGetRequest {
    id: number;
}

export interface ApiOperatorsIdClaimsPutRequest {
    id: number;
    operatorClaimsQueryDto?: OperatorClaimsQueryDto;
}

export interface ApiOperatorsIdInactiveAllPutRequest {
    id: number;
}

export interface ApiOperatorsIdUsersPutRequest {
    id: number;
    userQueryDto?: UserQueryDto;
}

export interface ApiOperatorsIdWellAccessPutRequest {
    id: number;
    wellAccessQueryDto?: WellAccessQueryDto;
}

/**
 * 
 */
export class OperatorsApi extends runtime.BaseAPI {

    /**
     */
    async apiOperatorsAccountsPutRaw(requestParameters: ApiOperatorsAccountsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<AccountDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Operators/Accounts`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AccountQueryDtoToJSON(requestParameters.accountQueryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AccountDtoFromJSON));
    }

    /**
     */
    async apiOperatorsAccountsPut(requestParameters: ApiOperatorsAccountsPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<AccountDto>> {
        const response = await this.apiOperatorsAccountsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOperatorsActiveRigIdsFilterEverGreenOnlyPutRaw(requestParameters: ApiOperatorsActiveRigIdsFilterEverGreenOnlyPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.filterEverGreenOnly === null || requestParameters.filterEverGreenOnly === undefined) {
            throw new runtime.RequiredError('filterEverGreenOnly','Required parameter requestParameters.filterEverGreenOnly was null or undefined when calling apiOperatorsActiveRigIdsFilterEverGreenOnlyPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Operators/ActiveRigIds/{filterEverGreenOnly}`.replace(`{${"filterEverGreenOnly"}}`, encodeURIComponent(String(requestParameters.filterEverGreenOnly))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiOperatorsActiveRigIdsFilterEverGreenOnlyPut(requestParameters: ApiOperatorsActiveRigIdsFilterEverGreenOnlyPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<number>> {
        const response = await this.apiOperatorsActiveRigIdsFilterEverGreenOnlyPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOperatorsGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OperatorDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Operators`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OperatorDtoFromJSON));
    }

    /**
     */
    async apiOperatorsGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OperatorDto>> {
        const response = await this.apiOperatorsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOperatorsIdActiveAllPutRaw(requestParameters: ApiOperatorsIdActiveAllPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiOperatorsIdActiveAllPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Operators/{id}/ActiveAll`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiOperatorsIdActiveAllPut(requestParameters: ApiOperatorsIdActiveAllPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.apiOperatorsIdActiveAllPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOperatorsIdClaimsGetRaw(requestParameters: ApiOperatorsIdClaimsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OperatorClaimDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiOperatorsIdClaimsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Operators/{id}/Claims`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OperatorClaimDtoFromJSON));
    }

    /**
     */
    async apiOperatorsIdClaimsGet(requestParameters: ApiOperatorsIdClaimsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OperatorClaimDto>> {
        const response = await this.apiOperatorsIdClaimsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOperatorsIdClaimsPutRaw(requestParameters: ApiOperatorsIdClaimsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OperatorClaimDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiOperatorsIdClaimsPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Operators/{id}/Claims`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OperatorClaimsQueryDtoToJSON(requestParameters.operatorClaimsQueryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OperatorClaimDtoFromJSON));
    }

    /**
     */
    async apiOperatorsIdClaimsPut(requestParameters: ApiOperatorsIdClaimsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OperatorClaimDto>> {
        const response = await this.apiOperatorsIdClaimsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOperatorsIdInactiveAllPutRaw(requestParameters: ApiOperatorsIdInactiveAllPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiOperatorsIdInactiveAllPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Operators/{id}/InactiveAll`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiOperatorsIdInactiveAllPut(requestParameters: ApiOperatorsIdInactiveAllPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.apiOperatorsIdInactiveAllPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOperatorsIdUsersPutRaw(requestParameters: ApiOperatorsIdUsersPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<UserWithAccessCountDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiOperatorsIdUsersPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Operators/{id}/Users`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserQueryDtoToJSON(requestParameters.userQueryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserWithAccessCountDtoFromJSON));
    }

    /**
     */
    async apiOperatorsIdUsersPut(requestParameters: ApiOperatorsIdUsersPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<UserWithAccessCountDto>> {
        const response = await this.apiOperatorsIdUsersPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOperatorsIdWellAccessPutRaw(requestParameters: ApiOperatorsIdWellAccessPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AccountRigAccessSetDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiOperatorsIdWellAccessPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Operators/{id}/WellAccess`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WellAccessQueryDtoToJSON(requestParameters.wellAccessQueryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountRigAccessSetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiOperatorsIdWellAccessPut(requestParameters: ApiOperatorsIdWellAccessPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AccountRigAccessSetDto> {
        const response = await this.apiOperatorsIdWellAccessPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
