/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ClaraResponseVerdictType = {
    Unknown: 'Unknown',
    Positive: 'Positive',
    Negative: 'Negative'
} as const;
export type ClaraResponseVerdictType = typeof ClaraResponseVerdictType[keyof typeof ClaraResponseVerdictType];


export function ClaraResponseVerdictTypeFromJSON(json: any): ClaraResponseVerdictType {
    return ClaraResponseVerdictTypeFromJSONTyped(json, false);
}

export function ClaraResponseVerdictTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClaraResponseVerdictType {
    return json as ClaraResponseVerdictType;
}

export function ClaraResponseVerdictTypeToJSON(value?: ClaraResponseVerdictType | null): any {
    return value as any;
}

