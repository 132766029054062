/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClaimPermissionType,
    ClaimPermissionTypeFromJSON,
    ClaimPermissionTypeFromJSONTyped,
    ClaimPermissionTypeToJSON,
} from './ClaimPermissionType';
import {
    WellStatusType,
    WellStatusTypeFromJSON,
    WellStatusTypeFromJSONTyped,
    WellStatusTypeToJSON,
} from './WellStatusType';

/**
 * 
 * @export
 * @interface ClaimWellInfo
 */
export interface ClaimWellInfo {
    /**
     * 
     * @type {number}
     * @memberof ClaimWellInfo
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ClaimWellInfo
     */
    name?: string | null;
    /**
     * 
     * @type {WellStatusType}
     * @memberof ClaimWellInfo
     */
    status: WellStatusType;
    /**
     * 
     * @type {ClaimPermissionType}
     * @memberof ClaimWellInfo
     */
    permission: ClaimPermissionType;
}

export function ClaimWellInfoFromJSON(json: any): ClaimWellInfo {
    return ClaimWellInfoFromJSONTyped(json, false);
}

export function ClaimWellInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClaimWellInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': !exists(json, 'Name') ? undefined : json['Name'],
        'status': WellStatusTypeFromJSON(json['Status']),
        'permission': ClaimPermissionTypeFromJSON(json['Permission']),
    };
}

export function ClaimWellInfoToJSON(value?: ClaimWellInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'Name': value.name,
        'Status': WellStatusTypeToJSON(value.status),
        'Permission': ClaimPermissionTypeToJSON(value.permission),
    };
}

