/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PlanTimelineEventDto,
    PlanTimelineEventDtoFromJSON,
    PlanTimelineEventDtoFromJSONTyped,
    PlanTimelineEventDtoToJSON,
} from './PlanTimelineEventDto';
import {
    TimelineSegmentDto,
    TimelineSegmentDtoFromJSON,
    TimelineSegmentDtoFromJSONTyped,
    TimelineSegmentDtoToJSON,
} from './TimelineSegmentDto';

/**
 * 
 * @export
 * @interface PlanTimelineDto
 */
export interface PlanTimelineDto {
    /**
     * 
     * @type {Array<TimelineSegmentDto>}
     * @memberof PlanTimelineDto
     */
    sections?: Array<TimelineSegmentDto> | null;
    /**
     * 
     * @type {Array<TimelineSegmentDto>}
     * @memberof PlanTimelineDto
     */
    phases?: Array<TimelineSegmentDto> | null;
    /**
     * 
     * @type {Array<PlanTimelineEventDto>}
     * @memberof PlanTimelineDto
     */
    events?: Array<PlanTimelineEventDto> | null;
}

export function PlanTimelineDtoFromJSON(json: any): PlanTimelineDto {
    return PlanTimelineDtoFromJSONTyped(json, false);
}

export function PlanTimelineDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanTimelineDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sections': !exists(json, 'Sections') ? undefined : (json['Sections'] === null ? null : (json['Sections'] as Array<any>).map(TimelineSegmentDtoFromJSON)),
        'phases': !exists(json, 'Phases') ? undefined : (json['Phases'] === null ? null : (json['Phases'] as Array<any>).map(TimelineSegmentDtoFromJSON)),
        'events': !exists(json, 'Events') ? undefined : (json['Events'] === null ? null : (json['Events'] as Array<any>).map(PlanTimelineEventDtoFromJSON)),
    };
}

export function PlanTimelineDtoToJSON(value?: PlanTimelineDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Sections': value.sections === undefined ? undefined : (value.sections === null ? null : (value.sections as Array<any>).map(TimelineSegmentDtoToJSON)),
        'Phases': value.phases === undefined ? undefined : (value.phases === null ? null : (value.phases as Array<any>).map(TimelineSegmentDtoToJSON)),
        'Events': value.events === undefined ? undefined : (value.events === null ? null : (value.events as Array<any>).map(PlanTimelineEventDtoToJSON)),
    };
}

