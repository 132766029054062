/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PowerConsumptionByOperationUserLensDto,
    PowerConsumptionByOperationUserLensDtoFromJSON,
    PowerConsumptionByOperationUserLensDtoToJSON,
    PowerConsumptionFactSetDto,
    PowerConsumptionFactSetDtoFromJSON,
    PowerConsumptionFactSetDtoToJSON,
    RigPowerQueryDto,
    RigPowerQueryDtoFromJSON,
    RigPowerQueryDtoToJSON,
} from '../models';

export interface ApiPowerConsumptionUserLensesIdFactsPutRequest {
    id: number;
    rigPowerQueryDto?: RigPowerQueryDto;
}

export interface ApiPowerConsumptionUserLensesIdPutRequest {
    id: number;
    powerConsumptionByOperationUserLensDto?: PowerConsumptionByOperationUserLensDto;
}

export interface ApiPowerConsumptionUserLensesPostRequest {
    powerConsumptionByOperationUserLensDto?: PowerConsumptionByOperationUserLensDto;
}

/**
 * 
 */
export class PowerConsumptionUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiPowerConsumptionUserLensesIdFactsPutRaw(requestParameters: ApiPowerConsumptionUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PowerConsumptionFactSetDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPowerConsumptionUserLensesIdFactsPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/PowerConsumptionUserLenses/{id}/Facts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RigPowerQueryDtoToJSON(requestParameters.rigPowerQueryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PowerConsumptionFactSetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiPowerConsumptionUserLensesIdFactsPut(requestParameters: ApiPowerConsumptionUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PowerConsumptionFactSetDto> {
        const response = await this.apiPowerConsumptionUserLensesIdFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPowerConsumptionUserLensesIdPutRaw(requestParameters: ApiPowerConsumptionUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PowerConsumptionByOperationUserLensDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPowerConsumptionUserLensesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/PowerConsumptionUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PowerConsumptionByOperationUserLensDtoToJSON(requestParameters.powerConsumptionByOperationUserLensDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PowerConsumptionByOperationUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiPowerConsumptionUserLensesIdPut(requestParameters: ApiPowerConsumptionUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PowerConsumptionByOperationUserLensDto> {
        const response = await this.apiPowerConsumptionUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPowerConsumptionUserLensesPostRaw(requestParameters: ApiPowerConsumptionUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PowerConsumptionByOperationUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/PowerConsumptionUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PowerConsumptionByOperationUserLensDtoToJSON(requestParameters.powerConsumptionByOperationUserLensDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PowerConsumptionByOperationUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiPowerConsumptionUserLensesPost(requestParameters: ApiPowerConsumptionUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PowerConsumptionByOperationUserLensDto> {
        const response = await this.apiPowerConsumptionUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
