/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    RigStateType,
    RigStateTypeFromJSON,
    RigStateTypeFromJSONTyped,
    RigStateTypeToJSON,
} from './RigStateType';

/**
 * 
 * @export
 * @interface ParameterByDepthTrackValuesDto
 */
export interface ParameterByDepthTrackValuesDto {
    /**
     * 
     * @type {number}
     * @memberof ParameterByDepthTrackValuesDto
     */
    holeDepth: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterByDepthTrackValuesDto
     */
    value?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ParameterByDepthTrackValuesDto
     */
    duration: number;
    /**
     * 
     * @type {DateDto}
     * @memberof ParameterByDepthTrackValuesDto
     */
    timestamp: DateDto;
    /**
     * 
     * @type {RigStateType}
     * @memberof ParameterByDepthTrackValuesDto
     */
    rigStateId: RigStateType;
}

export function ParameterByDepthTrackValuesDtoFromJSON(json: any): ParameterByDepthTrackValuesDto {
    return ParameterByDepthTrackValuesDtoFromJSONTyped(json, false);
}

export function ParameterByDepthTrackValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterByDepthTrackValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'holeDepth': json['HoleDepth'],
        'value': !exists(json, 'Value') ? undefined : json['Value'],
        'duration': json['Duration'],
        'timestamp': DateDtoFromJSON(json['Timestamp']),
        'rigStateId': RigStateTypeFromJSON(json['RigStateId']),
    };
}

export function ParameterByDepthTrackValuesDtoToJSON(value?: ParameterByDepthTrackValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'HoleDepth': value.holeDepth,
        'Value': value.value,
        'Duration': value.duration,
        'Timestamp': DateDtoToJSON(value.timestamp),
        'RigStateId': RigStateTypeToJSON(value.rigStateId),
    };
}

