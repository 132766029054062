import type { DateDto } from "apis/oag";
import type { RangeType } from "atoms/DatePicker";
import { last90Days, today } from "utils/helper";

import type { IAction } from "./types";

export interface IFilterOptionValue {
  value?: string | number | null;
  id?: string | number | null;
}
export interface IFilterOptions {
  type: IFilterOptionValue[];
  horsePower: IFilterOptionValue[];
  country: IFilterOptionValue[];
  operatingCenter: IFilterOptionValue[];
  technology: IFilterOptionValue[];
  holeSection: IFilterOptionValue[];
  directionalInterval: IFilterOptionValue[];
  holeSize: IFilterOptionValue[];
  wellStatus: IFilterOptionValue[];
}

interface IAllRigsState {
  lastAllRigsRefreshDate: DateDto | null;
  operators: number[] | null;
  period: RangeType;
  rigIds: number[] | null;
  filters: IFilterOptions | null;
  allWellsIncluded: boolean;
  exceptions: boolean;
  formationIds: number[] | null;
}

const initialState: IAllRigsState = {
  lastAllRigsRefreshDate: null,
  operators: null,
  rigIds: null,
  period: { startDate: last90Days, endDate: today },
  filters: null,
  allWellsIncluded: true,
  exceptions: false,
  formationIds: null,
};

interface ISetFiltersAction extends IAction {
  type: "ALL_RIGS_SET_FILTERS";
  payload: IAllRigsState["filters"];
}

interface ISetOperatorsAction extends IAction {
  type: "ALL_RIGS_SET_OPERATORS";
  payload: {
    operators: IAllRigsState["operators"];
  };
}

interface ISetRigsAction extends IAction {
  type: "ALL_RIGS_SET_RIGS";
  payload: {
    rigIds: IAllRigsState["rigIds"];
  };
}

interface ISetPeriodAction extends IAction {
  type: "ALL_RIGS_SET_PERIOD";
  payload: IAllRigsState["period"];
}
interface ISetLastUpdateDateAction extends IAction {
  type: "ALL_RIGS_SET_LAST_UPDATE_DATE";
  payload: IAllRigsState["lastAllRigsRefreshDate"];
}

interface IResetAllRigsFilterers extends IAction {
  type: "RESET_ALL_RIGS_FILTERS_90_DAYS";
  payload?: IAllRigsState["filters"];
}

interface IFullWellsIncluded extends IAction {
  type: "ALL_RIGS_FULL_WELLS_INCLUDED";
  payload: IAllRigsState["allWellsIncluded"];
}
interface IExceptionsIncluded extends IAction {
  type: "ALL_RIGS_EXCEPTIONS_INCLUDED";
  payload: IAllRigsState["exceptions"];
}

interface IFormationIds extends IAction {
  type: "ALL_RIGS_FORMATION_IDS";
  payload: IAllRigsState["formationIds"];
}

type AvailableActions =
  | IExceptionsIncluded
  | IFullWellsIncluded
  | IResetAllRigsFilterers
  | ISetFiltersAction
  | ISetLastUpdateDateAction
  | ISetOperatorsAction
  | ISetPeriodAction
  | IFormationIds
  | ISetRigsAction;

function allRigsReducer(state: IAllRigsState = initialState, action: AvailableActions): IAllRigsState {
  switch (action.type) {
    case "ALL_RIGS_SET_LAST_UPDATE_DATE":
      return {
        ...state,
        lastAllRigsRefreshDate: action.payload,
      };
    case "ALL_RIGS_SET_OPERATORS":
      return {
        ...state,
        operators: action.payload.operators,
      };
    case "ALL_RIGS_SET_RIGS":
      return {
        ...state,
        rigIds: action.payload.rigIds,
      };
    case "ALL_RIGS_SET_FILTERS":
      return {
        ...state,
        filters: action.payload,
      };
    case "ALL_RIGS_SET_PERIOD":
      return {
        ...state,
        period: action.payload,
      };
    case "ALL_RIGS_FULL_WELLS_INCLUDED":
      return {
        ...state,
        allWellsIncluded: action.payload,
      };
    case "ALL_RIGS_EXCEPTIONS_INCLUDED":
      return {
        ...state,
        exceptions: action.payload,
      };
    case "RESET_ALL_RIGS_FILTERS_90_DAYS":
      return {
        ...state,
        period: { startDate: last90Days, endDate: today },
        filters: action.payload ? action.payload : null,
      };
    case "ALL_RIGS_FORMATION_IDS":
      return {
        ...state,
        formationIds: action.payload,
      };
    default:
      return state;
  }
}

export default allRigsReducer;
