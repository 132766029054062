/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RigPowerSummaryValuesDto,
    RigPowerSummaryValuesDtoFromJSON,
    RigPowerSummaryValuesDtoFromJSONTyped,
    RigPowerSummaryValuesDtoToJSON,
} from './RigPowerSummaryValuesDto';

/**
 * 
 * @export
 * @interface RigPowerSummaryDto
 */
export interface RigPowerSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof RigPowerSummaryDto
     */
    totalActiveDuration: number;
    /**
     * 
     * @type {number}
     * @memberof RigPowerSummaryDto
     */
    totalCumulativeDuration: number;
    /**
     * 
     * @type {number}
     * @memberof RigPowerSummaryDto
     */
    totalEnergyInKWh: number;
    /**
     * 
     * @type {number}
     * @memberof RigPowerSummaryDto
     */
    totalMeanPower: number;
    /**
     * 
     * @type {number}
     * @memberof RigPowerSummaryDto
     */
    totalCapacityInKWh: number;
    /**
     * 
     * @type {number}
     * @memberof RigPowerSummaryDto
     */
    maxLoad: number;
    /**
     * 
     * @type {RigPowerSummaryValuesDto}
     * @memberof RigPowerSummaryDto
     */
    generator1Summary: RigPowerSummaryValuesDto;
    /**
     * 
     * @type {RigPowerSummaryValuesDto}
     * @memberof RigPowerSummaryDto
     */
    generator2Summary: RigPowerSummaryValuesDto;
    /**
     * 
     * @type {RigPowerSummaryValuesDto}
     * @memberof RigPowerSummaryDto
     */
    generator3Summary: RigPowerSummaryValuesDto;
    /**
     * 
     * @type {RigPowerSummaryValuesDto}
     * @memberof RigPowerSummaryDto
     */
    generator4Summary: RigPowerSummaryValuesDto;
    /**
     * 
     * @type {RigPowerSummaryValuesDto}
     * @memberof RigPowerSummaryDto
     */
    availablePower: RigPowerSummaryValuesDto;
    /**
     * 
     * @type {RigPowerSummaryValuesDto}
     * @memberof RigPowerSummaryDto
     */
    stateOfCharge: RigPowerSummaryValuesDto;
    /**
     * 
     * @type {RigPowerSummaryValuesDto}
     * @memberof RigPowerSummaryDto
     */
    rigDemand: RigPowerSummaryValuesDto;
    /**
     * 
     * @type {RigPowerSummaryValuesDto}
     * @memberof RigPowerSummaryDto
     */
    bessOutput: RigPowerSummaryValuesDto;
}

export function RigPowerSummaryDtoFromJSON(json: any): RigPowerSummaryDto {
    return RigPowerSummaryDtoFromJSONTyped(json, false);
}

export function RigPowerSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigPowerSummaryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalActiveDuration': json['TotalActiveDuration'],
        'totalCumulativeDuration': json['TotalCumulativeDuration'],
        'totalEnergyInKWh': json['TotalEnergyInKWh'],
        'totalMeanPower': json['TotalMeanPower'],
        'totalCapacityInKWh': json['TotalCapacityInKWh'],
        'maxLoad': json['MaxLoad'],
        'generator1Summary': RigPowerSummaryValuesDtoFromJSON(json['Generator1Summary']),
        'generator2Summary': RigPowerSummaryValuesDtoFromJSON(json['Generator2Summary']),
        'generator3Summary': RigPowerSummaryValuesDtoFromJSON(json['Generator3Summary']),
        'generator4Summary': RigPowerSummaryValuesDtoFromJSON(json['Generator4Summary']),
        'availablePower': RigPowerSummaryValuesDtoFromJSON(json['AvailablePower']),
        'stateOfCharge': RigPowerSummaryValuesDtoFromJSON(json['StateOfCharge']),
        'rigDemand': RigPowerSummaryValuesDtoFromJSON(json['RigDemand']),
        'bessOutput': RigPowerSummaryValuesDtoFromJSON(json['BessOutput']),
    };
}

export function RigPowerSummaryDtoToJSON(value?: RigPowerSummaryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'TotalActiveDuration': value.totalActiveDuration,
        'TotalCumulativeDuration': value.totalCumulativeDuration,
        'TotalEnergyInKWh': value.totalEnergyInKWh,
        'TotalMeanPower': value.totalMeanPower,
        'TotalCapacityInKWh': value.totalCapacityInKWh,
        'MaxLoad': value.maxLoad,
        'Generator1Summary': RigPowerSummaryValuesDtoToJSON(value.generator1Summary),
        'Generator2Summary': RigPowerSummaryValuesDtoToJSON(value.generator2Summary),
        'Generator3Summary': RigPowerSummaryValuesDtoToJSON(value.generator3Summary),
        'Generator4Summary': RigPowerSummaryValuesDtoToJSON(value.generator4Summary),
        'AvailablePower': RigPowerSummaryValuesDtoToJSON(value.availablePower),
        'StateOfCharge': RigPowerSummaryValuesDtoToJSON(value.stateOfCharge),
        'RigDemand': RigPowerSummaryValuesDtoToJSON(value.rigDemand),
        'BessOutput': RigPowerSummaryValuesDtoToJSON(value.bessOutput),
    };
}

