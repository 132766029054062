/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PlanDrillingParameterDto,
    PlanDrillingParameterDtoFromJSON,
    PlanDrillingParameterDtoFromJSONTyped,
    PlanDrillingParameterDtoToJSON,
} from './PlanDrillingParameterDto';

/**
 * 
 * @export
 * @interface PlanDrillingParameterDtoListAuditTrailVersionDto
 */
export interface PlanDrillingParameterDtoListAuditTrailVersionDto {
    /**
     * 
     * @type {Date}
     * @memberof PlanDrillingParameterDtoListAuditTrailVersionDto
     */
    asOf: Date;
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDtoListAuditTrailVersionDto
     */
    authorId: number;
    /**
     * 
     * @type {Array<PlanDrillingParameterDto>}
     * @memberof PlanDrillingParameterDtoListAuditTrailVersionDto
     */
    value?: Array<PlanDrillingParameterDto> | null;
}

export function PlanDrillingParameterDtoListAuditTrailVersionDtoFromJSON(json: any): PlanDrillingParameterDtoListAuditTrailVersionDto {
    return PlanDrillingParameterDtoListAuditTrailVersionDtoFromJSONTyped(json, false);
}

export function PlanDrillingParameterDtoListAuditTrailVersionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanDrillingParameterDtoListAuditTrailVersionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'asOf': (new Date(json['AsOf'])),
        'authorId': json['AuthorId'],
        'value': !exists(json, 'Value') ? undefined : (json['Value'] === null ? null : (json['Value'] as Array<any>).map(PlanDrillingParameterDtoFromJSON)),
    };
}

export function PlanDrillingParameterDtoListAuditTrailVersionDtoToJSON(value?: PlanDrillingParameterDtoListAuditTrailVersionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AsOf': (value.asOf.toISOString()),
        'AuthorId': value.authorId,
        'Value': value.value === undefined ? undefined : (value.value === null ? null : (value.value as Array<any>).map(PlanDrillingParameterDtoToJSON)),
    };
}

