import type { UseMutationResult } from "@tanstack/react-query";
import type { UserLensDto, VisualAidType } from "apis/oag";
import type { RangeType } from "atoms/DatePicker";
import { DatePicker } from "atoms/DatePicker";
import { Button } from "atoms/Form";
import { Title } from "atoms/Typography";
import { OrderedVisualAidType } from "components/Lenses/CommonLensSettingsModalContents";
import { MatrixAvailability } from "components/Lenses/ContainerLens/common/utils/constants";
import type { AbilityMatrixType } from "components/Lenses/ContainerLens/common/utils/getAbilityMatrix";
import { PDComponent } from "components/PDComponents";
import OffsetRigSelector from "components/RigComparisonSelectors/OffsetRigSelector";
import OperatorSelector from "components/RigComparisonSelectors/OperatorSelector";
import { WellSelector } from "components/RigComparisonSelectors/WellSelector";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useFiltersLoading } from "hooks/useFiltersLoading";
import { isEqual, xor } from "lodash";
import { useState } from "react";
import type { IFiltersType } from "reducers/types";
import { Track } from "services/Mixpanel";
import { Checkbox, Col, Popover, Row, Space, Tooltip } from "utils/componentLibrary";
import { last30Days, today } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

function Rigs({
  abilities,
  lens,
  isFiltersOpen,
  handleLensUpdate,
  setIsFiltersOpen,
}: {
  abilities: AbilityMatrixType;
  lens: UserLensDto & {
    selectedVisualAids?: Array<VisualAidType>;
  };
  handleLensUpdate: UseMutationResult<
    unknown,
    unknown,
    UserLensDto & {
      showsOutliers?: boolean;
      squeezesDisplay?: boolean;
      selectedVisualAids?: Array<VisualAidType>;
    },
    unknown
  >;
  isFiltersOpen: boolean;
  setIsFiltersOpen: (value: React.SetStateAction<boolean>) => void;
}) {
  const [filtersState] = useStateQuery<IFiltersType>(URL_STATE_PARAM.FILTERS_WIDGET, {
    operationTime: null,
    sections: null,
    directionalIntervals: null,
    holeSizes: null,
  });
  const { filtersLoading, availableFilters } = useFiltersLoading();
  const filtersActiveState =
    !isEqual(filtersState, {
      operationTime: null,
      sections: null,
      directionalIntervals: null,
      holeSizes: null,
    }) &&
    !(
      availableFilters &&
      filtersState &&
      xor(filtersState.directionalIntervals, availableFilters.directionalIntervalIds).length === 0 &&
      xor(filtersState.holeSizes, availableFilters.holeSizeIds).length === 0 &&
      xor(filtersState.sections, availableFilters.sectionIds).length === 0 &&
      (filtersState?.operationTime ?? []).includes("Flat Time") === availableFilters.includeFlatTime &&
      (filtersState?.operationTime ?? []).includes("Sliding Time") === availableFilters.includeSlidingTime &&
      (filtersState?.operationTime ?? []).includes("Rotating Time") === availableFilters.includeRotatingTime
    );

  const [periodState, setPeriodState] = useStateQuery<RangeType>(URL_STATE_PARAM.PERIOD_RIG_WIDGET, {
    startDate: last30Days,
    endDate: today,
  });
  const visualAidsState = lens?.selectedVisualAids || [];
  const updatePeriod = (period: RangeType) => {
    Track.interact("Rig Detailed View - Update Period", {
      "Start Date": period.startDate?.toISOString?.() ?? "",
      "End Date": period.endDate?.toISOString?.() ?? "",
    });
    setPeriodState(period);
    return true;
  };

  const [visualAidsVisible, setVisualAidsVisible] = useState(false);
  const { themeStyle, atomThemeVariant } = useCustomTheme();

  return (
    <Space>
      <Tooltip title="Offset Rig Selector">
        <OffsetRigSelector detailed />
      </Tooltip>

      <Tooltip title="Well Selector">
        <WellSelector detailed />
      </Tooltip>

      {abilities.selectedVisualAids !== MatrixAvailability.NOT_SUPPORTED && (
        <Tooltip title="Visual Aids">
          <Popover
            content={
              <Space
                direction="vertical"
                style={{
                  padding: "12px 16px",
                  backgroundColor: themeStyle.colors.primary_bg,
                }}
              >
                <Title level={3} variant={atomThemeVariant} weight={500}>
                  Visual Aids
                </Title>
                <Checkbox.Group
                  value={visualAidsState}
                  onChange={(e) => {
                    Track.interact("Lens - Visual Aids", {
                      Selection: e,
                    });
                    handleLensUpdate.mutate({ ...lens, selectedVisualAids: e as VisualAidType[] });
                  }}
                  style={{ maxWidth: "250px" }}
                >
                  <Row gutter={[0, 6]}>
                    {Object.keys(OrderedVisualAidType)
                      .filter((e) => e !== OrderedVisualAidType.None)
                      .map((e) => {
                        return (
                          <Col span={24} key={e}>
                            <Checkbox value={e}>{e}</Checkbox>
                          </Col>
                        );
                      })}
                  </Row>
                </Checkbox.Group>
              </Space>
            }
            trigger={["click"]}
            placement="bottom"
            open={visualAidsVisible}
            onOpenChange={(e) => setVisualAidsVisible(e)}
            destroyTooltipOnHide
          >
            <Button
              icon={<PDComponent.SvgIcon name="cdArchive" />}
              $engaged={visualAidsVisible}
              type={visualAidsState?.length > 0 ? "primary" : "default"}
              ghost={visualAidsState?.length > 0}
            />
          </Popover>
        </Tooltip>
      )}
      <Tooltip title="Operator Selector">
        {/* Operators Selector */}
        <OperatorSelector />
      </Tooltip>

      {abilities.filters !== MatrixAvailability.NOT_SUPPORTED && (
        <Tooltip title="Filter">
          <Button
            size="large"
            icon={<PDComponent.SvgIcon name="filter" />}
            onClick={() => setIsFiltersOpen((fl: boolean) => !fl)}
            disabled={abilities.filters === MatrixAvailability.NOT_AVAILABLE}
            $engaged={isFiltersOpen}
            loading={filtersLoading}
            type={filtersActiveState ? "primary" : "default"}
            ghost={filtersActiveState}
          />
        </Tooltip>
      )}

      {abilities.period !== MatrixAvailability.NOT_SUPPORTED && (
        <DatePicker selection={periodState} onApply={updatePeriod} />
      )}
    </Space>
  );
}

export default Rigs;
