/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RigLeaderboardScoreGroupAco,
    RigLeaderboardScoreGroupAcoFromJSON,
    RigLeaderboardScoreGroupAcoFromJSONTyped,
    RigLeaderboardScoreGroupAcoToJSON,
} from './RigLeaderboardScoreGroupAco';

/**
 * 
 * @export
 * @interface RigLeaderboardScoreSummaryAco
 */
export interface RigLeaderboardScoreSummaryAco {
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreSummaryAco
     */
    rigName: string;
    /**
     * 
     * @type {Array<RigLeaderboardScoreGroupAco>}
     * @memberof RigLeaderboardScoreSummaryAco
     */
    scorecardKpis: Array<RigLeaderboardScoreGroupAco>;
}

export function RigLeaderboardScoreSummaryAcoFromJSON(json: any): RigLeaderboardScoreSummaryAco {
    return RigLeaderboardScoreSummaryAcoFromJSONTyped(json, false);
}

export function RigLeaderboardScoreSummaryAcoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigLeaderboardScoreSummaryAco {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rigName': json['RigName'],
        'scorecardKpis': ((json['ScorecardKpis'] as Array<any>).map(RigLeaderboardScoreGroupAcoFromJSON)),
    };
}

export function RigLeaderboardScoreSummaryAcoToJSON(value?: RigLeaderboardScoreSummaryAco | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'RigName': value.rigName,
        'ScorecardKpis': ((value.scorecardKpis as Array<any>).map(RigLeaderboardScoreGroupAcoToJSON)),
    };
}

