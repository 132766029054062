/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    PivotBarDto,
    PivotBarDtoFromJSON,
    PivotBarDtoFromJSONTyped,
    PivotBarDtoToJSON,
} from './PivotBarDto';
import {
    PivotKey,
    PivotKeyFromJSON,
    PivotKeyFromJSONTyped,
    PivotKeyToJSON,
} from './PivotKey';
import {
    PivotType,
    PivotTypeFromJSON,
    PivotTypeFromJSONTyped,
    PivotTypeToJSON,
} from './PivotType';

/**
 * 
 * @export
 * @interface PivotTree1Dto
 */
export interface PivotTree1Dto {
    /**
     * 
     * @type {PivotKey}
     * @memberof PivotTree1Dto
     */
    key: PivotKey;
    /**
     * 
     * @type {PivotType}
     * @memberof PivotTree1Dto
     */
    pivotType: PivotType;
    /**
     * 
     * @type {number}
     * @memberof PivotTree1Dto
     */
    averageValue: number;
    /**
     * 
     * @type {number}
     * @memberof PivotTree1Dto
     */
    focalAverage: number;
    /**
     * 
     * @type {number}
     * @memberof PivotTree1Dto
     */
    comparisonAverage: number;
    /**
     * 
     * @type {number}
     * @memberof PivotTree1Dto
     */
    comparisonAverageRelativeDiff: number;
    /**
     * 
     * @type {number}
     * @memberof PivotTree1Dto
     */
    operationCount: number;
    /**
     * 
     * @type {number}
     * @memberof PivotTree1Dto
     */
    targetValue?: number | null;
    /**
     * 
     * @type {Array<PivotBarDto>}
     * @memberof PivotTree1Dto
     */
    children: Array<PivotBarDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PivotTree1Dto
     */
    readonly isOutlier: boolean;
    /**
     * 
     * @type {DateDto}
     * @memberof PivotTree1Dto
     */
    referenceDate: DateDto;
}

export function PivotTree1DtoFromJSON(json: any): PivotTree1Dto {
    return PivotTree1DtoFromJSONTyped(json, false);
}

export function PivotTree1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PivotTree1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': PivotKeyFromJSON(json['Key']),
        'pivotType': PivotTypeFromJSON(json['PivotType']),
        'averageValue': json['AverageValue'],
        'focalAverage': json['FocalAverage'],
        'comparisonAverage': json['ComparisonAverage'],
        'comparisonAverageRelativeDiff': json['ComparisonAverageRelativeDiff'],
        'operationCount': json['OperationCount'],
        'targetValue': !exists(json, 'TargetValue') ? undefined : json['TargetValue'],
        'children': ((json['Children'] as Array<any>).map(PivotBarDtoFromJSON)),
        'isOutlier': json['IsOutlier'],
        'referenceDate': DateDtoFromJSON(json['ReferenceDate']),
    };
}

export function PivotTree1DtoToJSON(value?: PivotTree1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Key': PivotKeyToJSON(value.key),
        'PivotType': PivotTypeToJSON(value.pivotType),
        'AverageValue': value.averageValue,
        'FocalAverage': value.focalAverage,
        'ComparisonAverage': value.comparisonAverage,
        'ComparisonAverageRelativeDiff': value.comparisonAverageRelativeDiff,
        'OperationCount': value.operationCount,
        'TargetValue': value.targetValue,
        'Children': ((value.children as Array<any>).map(PivotBarDtoToJSON)),
        'ReferenceDate': DateDtoToJSON(value.referenceDate),
    };
}

