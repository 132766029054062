/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    WellJobsDto,
    WellJobsDtoFromJSON,
    WellJobsDtoFromJSONTyped,
    WellJobsDtoToJSON,
} from './WellJobsDto';
import {
    WellStatusType,
    WellStatusTypeFromJSON,
    WellStatusTypeFromJSONTyped,
    WellStatusTypeToJSON,
} from './WellStatusType';

/**
 * 
 * @export
 * @interface WellDrillingSummaryDto
 */
export interface WellDrillingSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof WellDrillingSummaryDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof WellDrillingSummaryDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingSummaryDto
     */
    latitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingSummaryDto
     */
    longitude?: number | null;
    /**
     * 
     * @type {Array<WellJobsDto>}
     * @memberof WellDrillingSummaryDto
     */
    jobs?: Array<WellJobsDto> | null;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingSummaryDto
     */
    operatorId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDrillingSummaryDto
     */
    spudDateTime: DateDto;
    /**
     * 
     * @type {WellStatusType}
     * @memberof WellDrillingSummaryDto
     */
    status: WellStatusType;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingSummaryDto
     */
    cumulativeDuration: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingSummaryDto
     */
    lastBitDepth: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingSummaryDto
     */
    lastHoleDepth: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingSummaryDto
     */
    numberOfStrings: number;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDrillingSummaryDto
     */
    lastTvdFactUpdatedAt: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDrillingSummaryDto
     */
    lastWellFactUpdateAt: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDrillingSummaryDto
     */
    lastRigStateFactUpdateAt: DateDto;
}

export function WellDrillingSummaryDtoFromJSON(json: any): WellDrillingSummaryDto {
    return WellDrillingSummaryDtoFromJSONTyped(json, false);
}

export function WellDrillingSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WellDrillingSummaryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': !exists(json, 'Name') ? undefined : json['Name'],
        'latitude': !exists(json, 'Latitude') ? undefined : json['Latitude'],
        'longitude': !exists(json, 'Longitude') ? undefined : json['Longitude'],
        'jobs': !exists(json, 'Jobs') ? undefined : (json['Jobs'] === null ? null : (json['Jobs'] as Array<any>).map(WellJobsDtoFromJSON)),
        'operatorId': json['OperatorId'],
        'spudDateTime': DateDtoFromJSON(json['SpudDateTime']),
        'status': WellStatusTypeFromJSON(json['Status']),
        'cumulativeDuration': json['CumulativeDuration'],
        'lastBitDepth': json['LastBitDepth'],
        'lastHoleDepth': json['LastHoleDepth'],
        'numberOfStrings': json['NumberOfStrings'],
        'lastTvdFactUpdatedAt': DateDtoFromJSON(json['LastTvdFactUpdatedAt']),
        'lastWellFactUpdateAt': DateDtoFromJSON(json['LastWellFactUpdateAt']),
        'lastRigStateFactUpdateAt': DateDtoFromJSON(json['LastRigStateFactUpdateAt']),
    };
}

export function WellDrillingSummaryDtoToJSON(value?: WellDrillingSummaryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'Name': value.name,
        'Latitude': value.latitude,
        'Longitude': value.longitude,
        'Jobs': value.jobs === undefined ? undefined : (value.jobs === null ? null : (value.jobs as Array<any>).map(WellJobsDtoToJSON)),
        'OperatorId': value.operatorId,
        'SpudDateTime': DateDtoToJSON(value.spudDateTime),
        'Status': WellStatusTypeToJSON(value.status),
        'CumulativeDuration': value.cumulativeDuration,
        'LastBitDepth': value.lastBitDepth,
        'LastHoleDepth': value.lastHoleDepth,
        'NumberOfStrings': value.numberOfStrings,
        'LastTvdFactUpdatedAt': DateDtoToJSON(value.lastTvdFactUpdatedAt),
        'LastWellFactUpdateAt': DateDtoToJSON(value.lastWellFactUpdateAt),
        'LastRigStateFactUpdateAt': DateDtoToJSON(value.lastRigStateFactUpdateAt),
    };
}

