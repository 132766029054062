import type {
  RigCardFactDto,
  RigCardFactSeriesDto,
  RigCardFactSetDto,
  RigCardGroupFactDto,
  ScorecardKpiCommentInfoDto,
} from "apis/oag";
import { ScoreComplianceStateType, StandKpiType } from "apis/oag";
import { ScorecardExceptionStatusType } from "apis/oag/models/ScorecardExceptionStatusType";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useRigScorecardCardFacts } from "hooks/useRigScorecardCardFacts";
import { useScoreCardComments } from "hooks/useScoreCardComments";
import { BenchmarkType, useScoreBenchmarkContext } from "pages/RigScoreCard/ScoreBenchmarkContext";
import { useAppSelector } from "reducers/store";

export interface RequiredCommentsViewmodel {
  rigGroupFacts: RigCardGroupFactDto;
  cardFacts: RigCardFactSeriesDto;
  wellFact: RigCardFactDto;
}

export interface VisibleExceptionViewmodel extends RequiredCommentsViewmodel {
  comment: ScorecardKpiCommentInfoDto;
}

export const useRequiredComments = () => {
  const [rigSelectedWells] = useStateQuery<Array<number>>(URL_STATE_PARAM.SELECTED_WELLS_RIG_SCORECARD, []);
  const { data: commentsByLens } = useScoreCardComments(rigSelectedWells);
  const isExceptionsEnabled = useAppSelector((state) => state.allRigs.exceptions);

  const { data: rigScores } = useRigScorecardCardFacts(rigSelectedWells, isExceptionsEnabled, {
    staleTime: Infinity,
  });

  const { scoreBenchmark } = useScoreBenchmarkContext();

  function parseFacSet(factSet?: RigCardFactSetDto) {
    let actionCompletedCount = 0;

    const requiredFacts: RequiredCommentsViewmodel[] = [];
    const wellScoreFacts: RequiredCommentsViewmodel[] = [];
    const pendingExceptionFacts: VisibleExceptionViewmodel[] = [];
    const approvedExceptionFacts: VisibleExceptionViewmodel[] = [];

    factSet?.cardGroups?.forEach((cardGroup) => {
      return cardGroup.facts?.forEach((cardSeries) => {
        const lensId = cardSeries.userLensId;
        const comments = commentsByLens?.find((comm) => comm.scorecardLensId === lensId)?.comments ?? [];

        cardSeries.facts?.forEach((fact) => {
          const model = { rigGroupFacts: cardGroup, cardFacts: cardSeries, wellFact: fact };
          // Operator target
          if (scoreBenchmark === BenchmarkType.OperatorTarget) {
            if (fact.operatorScoreComplianceState === ScoreComplianceStateType.ActionPending) {
              cardSeries.kpiType === StandKpiType.NUMBER_0 ? wellScoreFacts.push(model) : requiredFacts.push(model);
            } else if (fact.operatorScoreComplianceState === ScoreComplianceStateType.ActionCompleted) {
              actionCompletedCount++;
            }
          }

          // PD benchmark rig
          if (scoreBenchmark === BenchmarkType.PDBenchmark) {
            if (fact.rigScoreComplianceState === ScoreComplianceStateType.ActionPending) {
              cardSeries.kpiType === StandKpiType.NUMBER_0 ? wellScoreFacts.push(model) : requiredFacts.push(model);
            } else if (fact.rigScoreComplianceState === ScoreComplianceStateType.ActionCompleted) {
              actionCompletedCount++;
            }
          }

          const wellIdFromFacts = +fact.label; // TODO fix this throughout the codebase

          comments
            .filter((comment) => comment.wellId === wellIdFromFacts)
            .forEach((comment) => {
              const exceptionInfo = {
                rigGroupFacts: cardGroup,
                cardFacts: cardSeries,
                wellFact: fact,
                comment,
              };

              if (comment.exceptionStatus === ScorecardExceptionStatusType.Pending) {
                pendingExceptionFacts.push(exceptionInfo);
              } else if (comment.exceptionStatus === ScorecardExceptionStatusType.Approved) {
                approvedExceptionFacts.push(exceptionInfo);
              }
            });
        });
      });
    });

    const total = (requiredFacts.length ?? 0) + (wellScoreFacts.length ?? 0) + (pendingExceptionFacts.length ?? 0);
    const percentageCompleted =
      total > 0 ? Math.round((actionCompletedCount / (total + actionCompletedCount)) * 100) : 100;

    return {
      requiredFacts,
      wellScoreFacts,
      pendingExceptionFacts,
      approvedExceptionFacts,
      actionCompletedCount,
      total,
      percentageCompleted,
    };
  }

  // We parse two different data sets since we take the total count only from one of them, no matter if exception is selected or not
  // Code is optimized for readability. if !isExceptionEnabled then we don't need a second parse
  const { requiredFacts, pendingExceptionFacts, approvedExceptionFacts, wellScoreFacts } = parseFacSet(rigScores);
  const { percentageCompleted, total } = parseFacSet(rigScores);

  return {
    pendingActionCount: total,
    percentageCompleted,
    requiredFacts,
    pendingExceptionFacts,
    approvedExceptionFacts,
    wellScoreFacts,
  };
};
