/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    ParameterByDepthTrackComparisonWellDto,
    ParameterByDepthTrackComparisonWellDtoFromJSON,
    ParameterByDepthTrackComparisonWellDtoFromJSONTyped,
    ParameterByDepthTrackComparisonWellDtoToJSON,
} from './ParameterByDepthTrackComparisonWellDto';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    ServiceMessageDto,
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import {
    StopWatchDto,
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';

/**
 * 
 * @export
 * @interface StickSlipByDepthSetDto
 */
export interface StickSlipByDepthSetDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof StickSlipByDepthSetDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof StickSlipByDepthSetDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof StickSlipByDepthSetDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof StickSlipByDepthSetDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {ResultDataState}
     * @memberof StickSlipByDepthSetDto
     */
    dataState: ResultDataState;
    /**
     * 
     * @type {number}
     * @memberof StickSlipByDepthSetDto
     */
    wellId: number;
    /**
     * 
     * @type {Array<ParameterByDepthTrackComparisonWellDto>}
     * @memberof StickSlipByDepthSetDto
     */
    facts: Array<ParameterByDepthTrackComparisonWellDto>;
}

export function StickSlipByDepthSetDtoFromJSON(json: any): StickSlipByDepthSetDto {
    return StickSlipByDepthSetDtoFromJSONTyped(json, false);
}

export function StickSlipByDepthSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StickSlipByDepthSetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': !exists(json, 'Messages') ? undefined : (json['Messages'] === null ? null : (json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
        'wellId': json['WellId'],
        'facts': ((json['Facts'] as Array<any>).map(ParameterByDepthTrackComparisonWellDtoFromJSON)),
    };
}

export function StickSlipByDepthSetDtoToJSON(value?: StickSlipByDepthSetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value.stopWatch),
        'Messages': value.messages === undefined ? undefined : (value.messages === null ? null : (value.messages as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value.userLensId,
        'LastUpdatedAt': DateDtoToJSON(value.lastUpdatedAt),
        'DataState': ResultDataStateToJSON(value.dataState),
        'WellId': value.wellId,
        'Facts': ((value.facts as Array<any>).map(ParameterByDepthTrackComparisonWellDtoToJSON)),
    };
}

