/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const StackingType = {
    Values: 'Values',
    Distribution: 'Distribution'
} as const;
export type StackingType = typeof StackingType[keyof typeof StackingType];


export function StackingTypeFromJSON(json: any): StackingType {
    return StackingTypeFromJSONTyped(json, false);
}

export function StackingTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): StackingType {
    return json as StackingType;
}

export function StackingTypeToJSON(value?: StackingType | null): any {
    return value as any;
}

