/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlanTvdPointDto
 */
export interface PlanTvdPointDto {
    /**
     * 
     * @type {number}
     * @memberof PlanTvdPointDto
     */
    cumulativeDuration: number;
    /**
     * 
     * @type {number}
     * @memberof PlanTvdPointDto
     */
    dynamicDuration: number;
    /**
     * 
     * @type {number}
     * @memberof PlanTvdPointDto
     */
    holeDepth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanTvdPointDto
     */
    dynamicHoleDepth?: number | null;
}

export function PlanTvdPointDtoFromJSON(json: any): PlanTvdPointDto {
    return PlanTvdPointDtoFromJSONTyped(json, false);
}

export function PlanTvdPointDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanTvdPointDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cumulativeDuration': json['CumulativeDuration'],
        'dynamicDuration': json['DynamicDuration'],
        'holeDepth': !exists(json, 'HoleDepth') ? undefined : json['HoleDepth'],
        'dynamicHoleDepth': !exists(json, 'DynamicHoleDepth') ? undefined : json['DynamicHoleDepth'],
    };
}

export function PlanTvdPointDtoToJSON(value?: PlanTvdPointDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'CumulativeDuration': value.cumulativeDuration,
        'DynamicDuration': value.dynamicDuration,
        'HoleDepth': value.holeDepth,
        'DynamicHoleDepth': value.dynamicHoleDepth,
    };
}

