/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RigCardFactSetDto,
    RigCardFactSetDtoFromJSON,
    RigCardFactSetDtoToJSON,
    RigFleetPerformanceCardUserLensDto,
    RigFleetPerformanceCardUserLensDtoFromJSON,
    RigFleetPerformanceCardUserLensDtoToJSON,
    RigFleetPerformanceFactQueryDto,
    RigFleetPerformanceFactQueryDtoFromJSON,
    RigFleetPerformanceFactQueryDtoToJSON,
} from '../models';

export interface ApiRigFleetPerformanceCardUserLensesAllFactsPutRequest {
    rigFleetPerformanceFactQueryDto?: RigFleetPerformanceFactQueryDto;
}

export interface ApiRigFleetPerformanceCardUserLensesIdPutRequest {
    id: number;
    rigFleetPerformanceCardUserLensDto?: RigFleetPerformanceCardUserLensDto;
}

/**
 * 
 */
export class RigFleetPerformanceCardUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiRigFleetPerformanceCardUserLensesAllFactsPutRaw(requestParameters: ApiRigFleetPerformanceCardUserLensesAllFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RigCardFactSetDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/RigFleetPerformanceCardUserLenses/All/Facts`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RigFleetPerformanceFactQueryDtoToJSON(requestParameters.rigFleetPerformanceFactQueryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RigCardFactSetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiRigFleetPerformanceCardUserLensesAllFactsPut(requestParameters: ApiRigFleetPerformanceCardUserLensesAllFactsPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RigCardFactSetDto> {
        const response = await this.apiRigFleetPerformanceCardUserLensesAllFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRigFleetPerformanceCardUserLensesIdPutRaw(requestParameters: ApiRigFleetPerformanceCardUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RigFleetPerformanceCardUserLensDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiRigFleetPerformanceCardUserLensesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/RigFleetPerformanceCardUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RigFleetPerformanceCardUserLensDtoToJSON(requestParameters.rigFleetPerformanceCardUserLensDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RigFleetPerformanceCardUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiRigFleetPerformanceCardUserLensesIdPut(requestParameters: ApiRigFleetPerformanceCardUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RigFleetPerformanceCardUserLensDto> {
        const response = await this.apiRigFleetPerformanceCardUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
