import { ErrorBoundary } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { Title } from "atoms/Typography";
import CoreLayout, { SectionLayout } from "components/Layout";
import { WebGLSupportBanner } from "components/WebGLSupportBanner/WebGLSupportBanner";
import type { FC } from "react";
import { useEffect, useRef } from "react";
import { useMixpanel } from "services/Mixpanel";
import styled from "styled-components";
import { Space } from "utils/componentLibrary";
import { PdCardBody } from "utils/componentLibrary/Card";
import { PDQueryType } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";

import AllWellsHeader from "./components/AllWellsHeader";
import KPIsContainer from "./components/KPIsContainer";
import MapView from "./components/MapView";
import { WellsMapViewProvider } from "./components/MapView/useWellsMapView";
import WellsContainer from "./components/WellsContainer";
import { PaddedContainer, StyledContainer } from "./StyledComponents";

const StyledHeaderContainer = styled.div`
  margin: 0 24px;
  height: 36px;
  display: flex;
  align-items: center;
`;

const StyledScrollMask = styled.div`
  position: absolute;
  right: 0;
  height: 100%;
  background: ${({ theme }) => theme.themeStyle.colors.primary_bg};
`;

const StyledSpace = styled(Space)`
  height: 100%;
  background: ${({ theme }) => theme.themeStyle.colors.primary_bg};
  ${PdCardBody} {
    padding: 0px 7px;
  }
`;

const StyledMapviewWrapper = styled.div`
  margin-top: 68px;
`;

const StyledSidebarWrapper = styled.div`
  height: calc(100vh - 205px);
`;

const Main: FC = () => {
  const queryClient = useQueryClient();

  const pageSeen = useRef(false);
  const { viewPage } = useMixpanel();

  useEffect(() => {
    if (viewPage && !pageSeen.current) {
      pageSeen.current = true;
      viewPage("All Wells");
    }
  }, [viewPage]);

  useEffect(() => {
    return () => {
      queryClient.cancelQueries({ queryKey: [{ type: PDQueryType.WELL_TVD_POINTS }], exact: false });
    };
  }, [queryClient]);

  const { atomThemeVariant } = useCustomTheme();
  return (
    <WellsMapViewProvider>
      <StyledContainer>
        <AllWellsHeader />
      </StyledContainer>

      <StyledMapviewWrapper>
        <ErrorBoundary fallback={<WebGLSupportBanner />}>
          <MapView />
        </ErrorBoundary>
      </StyledMapviewWrapper>
      <PaddedContainer>
        <CoreLayout
          sidebar={
            <SectionLayout
              CustomChildrenWrapper={StyledSidebarWrapper}
              header={
                <StyledHeaderContainer>
                  <Title level={3} variant={atomThemeVariant} weight={600}>
                    Wells KPI&apos;s
                  </Title>
                  <StyledScrollMask />
                </StyledHeaderContainer>
              }
            >
              <StyledSpace align="start">
                <KPIsContainer />
              </StyledSpace>
            </SectionLayout>
          }
        >
          <WellsContainer />
        </CoreLayout>
      </PaddedContainer>
    </WellsMapViewProvider>
  );
};

export default Main;
