/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DashboardCopyDto,
    DashboardCopyDtoFromJSON,
    DashboardCopyDtoToJSON,
    DashboardDto,
    DashboardDtoFromJSON,
    DashboardDtoToJSON,
    DashboardPersonalTabs,
    DashboardPersonalTabsFromJSON,
    DashboardPersonalTabsToJSON,
    DashboardType,
    DashboardTypeFromJSON,
    DashboardTypeToJSON,
} from '../models';

export interface ApiDashboardsCopyPutRequest {
    dashboardCopyDto?: DashboardCopyDto;
}

export interface ApiDashboardsTypeGetRequest {
    type: DashboardType;
}

export interface ApiDashboardsTypePutRequest {
    type: DashboardType;
    dashboardPersonalTabs?: DashboardPersonalTabs;
}

/**
 * 
 */
export class DashboardsApi extends runtime.BaseAPI {

    /**
     */
    async apiDashboardsCopyPutRaw(requestParameters: ApiDashboardsCopyPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Dashboards/Copy`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardCopyDtoToJSON(requestParameters.dashboardCopyDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiDashboardsCopyPut(requestParameters: ApiDashboardsCopyPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.apiDashboardsCopyPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDashboardsTypeGetRaw(requestParameters: ApiDashboardsTypeGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DashboardDto>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling apiDashboardsTypeGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Dashboards/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiDashboardsTypeGet(requestParameters: ApiDashboardsTypeGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DashboardDto> {
        const response = await this.apiDashboardsTypeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDashboardsTypePutRaw(requestParameters: ApiDashboardsTypePutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DashboardDto>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling apiDashboardsTypePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Dashboards/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardPersonalTabsToJSON(requestParameters.dashboardPersonalTabs),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiDashboardsTypePut(requestParameters: ApiDashboardsTypePutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DashboardDto> {
        const response = await this.apiDashboardsTypePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
