/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportGenerationParametersDto
 */
export interface ReportGenerationParametersDto {
    /**
     * 
     * @type {number}
     * @memberof ReportGenerationParametersDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ReportGenerationParametersDto
     */
    reportIdUrlPlaceHolder?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportGenerationParametersDto
     */
    payload?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportGenerationParametersDto
     */
    pageUrls: Array<string>;
}

export function ReportGenerationParametersDtoFromJSON(json: any): ReportGenerationParametersDto {
    return ReportGenerationParametersDtoFromJSONTyped(json, false);
}

export function ReportGenerationParametersDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportGenerationParametersDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'reportIdUrlPlaceHolder': !exists(json, 'ReportIdUrlPlaceHolder') ? undefined : json['ReportIdUrlPlaceHolder'],
        'payload': !exists(json, 'Payload') ? undefined : json['Payload'],
        'pageUrls': json['PageUrls'],
    };
}

export function ReportGenerationParametersDtoToJSON(value?: ReportGenerationParametersDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'ReportIdUrlPlaceHolder': value.reportIdUrlPlaceHolder,
        'Payload': value.payload,
        'PageUrls': value.pageUrls,
    };
}

