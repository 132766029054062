/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BroomstickDto,
    BroomstickDtoFromJSON,
    BroomstickDtoToJSON,
    TorqueAndDragType,
    TorqueAndDragTypeFromJSON,
    TorqueAndDragTypeToJSON,
} from '../models';

export interface ApiBroomsticksModelsWellIdTypeGetRequest {
    wellId: number;
    type: TorqueAndDragType;
}

/**
 * 
 */
export class BroomsticksApi extends runtime.BaseAPI {

    /**
     */
    async apiBroomsticksGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<BroomstickDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Broomsticks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BroomstickDtoFromJSON));
    }

    /**
     */
    async apiBroomsticksGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<BroomstickDto>> {
        const response = await this.apiBroomsticksGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiBroomsticksModelsWellIdTypeGetRaw(requestParameters: ApiBroomsticksModelsWellIdTypeGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.wellId === null || requestParameters.wellId === undefined) {
            throw new runtime.RequiredError('wellId','Required parameter requestParameters.wellId was null or undefined when calling apiBroomsticksModelsWellIdTypeGet.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling apiBroomsticksModelsWellIdTypeGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Broomsticks/Models/{wellId}/{type}`.replace(`{${"wellId"}}`, encodeURIComponent(String(requestParameters.wellId))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiBroomsticksModelsWellIdTypeGet(requestParameters: ApiBroomsticksModelsWellIdTypeGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<string>> {
        const response = await this.apiBroomsticksModelsWellIdTypeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
