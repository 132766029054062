/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface TimelineSegmentDto
 */
export interface TimelineSegmentDto {
    /**
     * 
     * @type {number}
     * @memberof TimelineSegmentDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TimelineSegmentDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TimelineSegmentDto
     */
    startCumulativeDuration: number;
    /**
     * 
     * @type {number}
     * @memberof TimelineSegmentDto
     */
    endCumulativeDuration?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TimelineSegmentDto
     */
    startHoleDepth: number;
    /**
     * 
     * @type {number}
     * @memberof TimelineSegmentDto
     */
    endHoleDepth?: number | null;
    /**
     * 
     * @type {DateDto}
     * @memberof TimelineSegmentDto
     */
    at: DateDto;
}

export function TimelineSegmentDtoFromJSON(json: any): TimelineSegmentDto {
    return TimelineSegmentDtoFromJSONTyped(json, false);
}

export function TimelineSegmentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimelineSegmentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'description': !exists(json, 'Description') ? undefined : json['Description'],
        'startCumulativeDuration': json['StartCumulativeDuration'],
        'endCumulativeDuration': !exists(json, 'EndCumulativeDuration') ? undefined : json['EndCumulativeDuration'],
        'startHoleDepth': json['StartHoleDepth'],
        'endHoleDepth': !exists(json, 'EndHoleDepth') ? undefined : json['EndHoleDepth'],
        'at': DateDtoFromJSON(json['At']),
    };
}

export function TimelineSegmentDtoToJSON(value?: TimelineSegmentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'Description': value.description,
        'StartCumulativeDuration': value.startCumulativeDuration,
        'EndCumulativeDuration': value.endCumulativeDuration,
        'StartHoleDepth': value.startHoleDepth,
        'EndHoleDepth': value.endHoleDepth,
        'At': DateDtoToJSON(value.at),
    };
}

