/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BoilerFuelFlowRateFactDto,
    BoilerFuelFlowRateFactDtoFromJSON,
    BoilerFuelFlowRateFactDtoFromJSONTyped,
    BoilerFuelFlowRateFactDtoToJSON,
} from './BoilerFuelFlowRateFactDto';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    ServiceMessageDto,
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import {
    StopWatchDto,
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';

/**
 * 
 * @export
 * @interface BoilerFuelFlowRateResultDto
 */
export interface BoilerFuelFlowRateResultDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof BoilerFuelFlowRateResultDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof BoilerFuelFlowRateResultDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof BoilerFuelFlowRateResultDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof BoilerFuelFlowRateResultDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {Array<BoilerFuelFlowRateFactDto>}
     * @memberof BoilerFuelFlowRateResultDto
     */
    boiler1DieselFacts?: Array<BoilerFuelFlowRateFactDto> | null;
    /**
     * 
     * @type {Array<BoilerFuelFlowRateFactDto>}
     * @memberof BoilerFuelFlowRateResultDto
     */
    boiler2DieselFacts?: Array<BoilerFuelFlowRateFactDto> | null;
    /**
     * 
     * @type {Array<BoilerFuelFlowRateFactDto>}
     * @memberof BoilerFuelFlowRateResultDto
     */
    boilerGasFacts?: Array<BoilerFuelFlowRateFactDto> | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof BoilerFuelFlowRateResultDto
     */
    dataState: ResultDataState;
}

export function BoilerFuelFlowRateResultDtoFromJSON(json: any): BoilerFuelFlowRateResultDto {
    return BoilerFuelFlowRateResultDtoFromJSONTyped(json, false);
}

export function BoilerFuelFlowRateResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BoilerFuelFlowRateResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': !exists(json, 'Messages') ? undefined : (json['Messages'] === null ? null : (json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'boiler1DieselFacts': !exists(json, 'Boiler1DieselFacts') ? undefined : (json['Boiler1DieselFacts'] === null ? null : (json['Boiler1DieselFacts'] as Array<any>).map(BoilerFuelFlowRateFactDtoFromJSON)),
        'boiler2DieselFacts': !exists(json, 'Boiler2DieselFacts') ? undefined : (json['Boiler2DieselFacts'] === null ? null : (json['Boiler2DieselFacts'] as Array<any>).map(BoilerFuelFlowRateFactDtoFromJSON)),
        'boilerGasFacts': !exists(json, 'BoilerGasFacts') ? undefined : (json['BoilerGasFacts'] === null ? null : (json['BoilerGasFacts'] as Array<any>).map(BoilerFuelFlowRateFactDtoFromJSON)),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function BoilerFuelFlowRateResultDtoToJSON(value?: BoilerFuelFlowRateResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value.stopWatch),
        'Messages': value.messages === undefined ? undefined : (value.messages === null ? null : (value.messages as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value.userLensId,
        'LastUpdatedAt': DateDtoToJSON(value.lastUpdatedAt),
        'Boiler1DieselFacts': value.boiler1DieselFacts === undefined ? undefined : (value.boiler1DieselFacts === null ? null : (value.boiler1DieselFacts as Array<any>).map(BoilerFuelFlowRateFactDtoToJSON)),
        'Boiler2DieselFacts': value.boiler2DieselFacts === undefined ? undefined : (value.boiler2DieselFacts === null ? null : (value.boiler2DieselFacts as Array<any>).map(BoilerFuelFlowRateFactDtoToJSON)),
        'BoilerGasFacts': value.boilerGasFacts === undefined ? undefined : (value.boilerGasFacts === null ? null : (value.boilerGasFacts as Array<any>).map(BoilerFuelFlowRateFactDtoToJSON)),
        'DataState': ResultDataStateToJSON(value.dataState),
    };
}

