/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OperatorStatusType,
    OperatorStatusTypeFromJSON,
    OperatorStatusTypeFromJSONTyped,
    OperatorStatusTypeToJSON,
} from './OperatorStatusType';
import {
    UserStatusType,
    UserStatusTypeFromJSON,
    UserStatusTypeFromJSONTyped,
    UserStatusTypeToJSON,
} from './UserStatusType';

/**
 * 
 * @export
 * @interface AccountQueryDto
 */
export interface AccountQueryDto {
    /**
     * 
     * @type {string}
     * @memberof AccountQueryDto
     */
    search?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AccountQueryDto
     */
    wellIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AccountQueryDto
     */
    rigIds?: Array<number> | null;
    /**
     * 
     * @type {Array<OperatorStatusType>}
     * @memberof AccountQueryDto
     */
    status?: Array<OperatorStatusType> | null;
    /**
     * 
     * @type {Array<UserStatusType>}
     * @memberof AccountQueryDto
     */
    userStatus?: Array<UserStatusType> | null;
}

export function AccountQueryDtoFromJSON(json: any): AccountQueryDto {
    return AccountQueryDtoFromJSONTyped(json, false);
}

export function AccountQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountQueryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'search': !exists(json, 'Search') ? undefined : json['Search'],
        'wellIds': !exists(json, 'WellIds') ? undefined : json['WellIds'],
        'rigIds': !exists(json, 'RigIds') ? undefined : json['RigIds'],
        'status': !exists(json, 'Status') ? undefined : (json['Status'] === null ? null : (json['Status'] as Array<any>).map(OperatorStatusTypeFromJSON)),
        'userStatus': !exists(json, 'UserStatus') ? undefined : (json['UserStatus'] === null ? null : (json['UserStatus'] as Array<any>).map(UserStatusTypeFromJSON)),
    };
}

export function AccountQueryDtoToJSON(value?: AccountQueryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Search': value.search,
        'WellIds': value.wellIds,
        'RigIds': value.rigIds,
        'Status': value.status === undefined ? undefined : (value.status === null ? null : (value.status as Array<any>).map(OperatorStatusTypeToJSON)),
        'UserStatus': value.userStatus === undefined ? undefined : (value.userStatus === null ? null : (value.userStatus as Array<any>).map(UserStatusTypeToJSON)),
    };
}

