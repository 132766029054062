/* eslint-disable react/no-multi-comp */
import { DimensionType, WellStatusType } from "apis/oag";
import { Title } from "atoms/Typography";
import { SCROLLBAR_GAP_AROUND, SCROLLBAR_GAP_PX } from "components/Layout/LayoutUtils";
import { Loader } from "components/Loader";
import { PDComponent } from "components/PDComponents";
import { useRigSort } from "hooks/useRigSort";
import { useWellSummaries } from "hooks/useWellSummaries";
import { useFilteredAllWellsContext } from "pages/AllWells/useFilteredAllWells";
import type { FC } from "react";
import { memo, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import colors from "utils/colors";
import { responsiveBreakpoints } from "utils/common";
import { Col, Row, Space } from "utils/componentLibrary";
import { SortBy, SortDirections } from "utils/enums";
import { useUOM } from "utils/format";
import { sortByRigs, sortByValue, sortWellsByEnd } from "utils/helper";

import SingleWell from "./SingleWell";

const LimitedContainer = styled.div`
  height: calc(100% - 75px);
  padding-left: 16px;

  width: 100%;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
    visibility: hidden;
  }
`;

const IconHolder = styled.div`
  height: 40px;
  width: 40px;
  font-size: 20px;
  background: ${({ theme: { themeStyle } }) => themeStyle.colors.tertiary_chart_bg};
  border-radius: 100%;
  padding: 10px;
  display: flex;
  box-shadow: 0px 1px 2px 0px ${({ theme: { themeStyle } }) => themeStyle.colors.primary_accent};
  color: ${colors.gray};
  margin: 0 auto;
`;

const StyledRow = styled.div`
  padding: 8px 0px;
  padding-right: ${SCROLLBAR_GAP_AROUND}px;
  background: ${({ theme: { themeStyle } }) => themeStyle.colors.primary_bg};
`;

const StyledHeader = styled.div`
  margin: 0px calc(${SCROLLBAR_GAP_PX}px) 0 16px;
  height: 60px;
  background: ${({ theme: { themeStyle } }) => themeStyle.colors.alt_quaterniary_bg};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const WellContainer = styled.div`
  padding-left: 56px;
`;

const InfoContainer = styled.div`
  display: flex;
`;

const StyledTitle = styled.h3`
  color: ${({ theme: { themeStyle } }) => themeStyle.colors.primary_typography};
  font-weight: 500;
  font-size: 16px;
  margin: 0px;
`;

export const StyledChevron = styled.span.attrs({ children: <PDComponent.SvgIcon name="chevronDown" /> })<{
  $isActive?: boolean;
  $isFaded: boolean;
}>`
  svg {
    width: 14px;
    color: ${(props) => {
      if (props.$isActive) {
        return props.theme.themeStyle.colors.primary_typography;
      }
      return props.$isFaded ? colors.off_state : colors.gray;
    }};
  }
`;

export const SortIcons = styled.div`
  display: flex;
  flex-direction: column;
  & ${StyledChevron}:first-child {
    transform: rotate(180deg);
  }
  & ${StyledChevron}:last-child {
    margin-top: -8px;
  }
`;

const TitleContainer = styled.div<{ width?: number }>`
  display: flex;
  width: ${(props) => (props.width ? `${props.width}px` : "78px")};
  cursor: pointer;
  gap: 5px;
  padding: 0px;
  transition: all 0.3s ease;
  align-items: center;
  margin-left: 16px;
  @media only screen and (max-width: ${responsiveBreakpoints.xl}px) {
    margin-left: 8px;
  }
  @media only screen and (min-width: ${responsiveBreakpoints.xxl}px) {
    margin-left: 24px;
  }
`;

const LoaderContainer = styled(Row)`
  position: relative;
`;

const WellsContainer: FC = () => {
  const wellSummaries = useWellSummaries(false);

  const { setVisibleWellsList, visibleWellsList } = useFilteredAllWellsContext();
  const [sortBy, setSortBy] = useState(SortBy.End);
  const [sortDirection, setSortDirection] = useState(SortDirections.Desc);

  function SortIcon({ column }: { column?: SortBy }) {
    return (
      <SortIcons>
        <StyledChevron
          $isActive={sortBy === column && sortDirection === SortDirections.Asc}
          $isFaded={sortBy === column && sortDirection !== SortDirections.Asc}
        />
        <StyledChevron
          $isActive={sortBy === column && sortDirection === SortDirections.Desc}
          $isFaded={sortBy === column && sortDirection !== SortDirections.Desc}
        />
      </SortIcons>
    );
  }

  const handleSort = (newSortBy: SortBy) => {
    if (sortBy === newSortBy) {
      setSortDirection(sortDirection === SortDirections.Asc ? SortDirections.Desc : SortDirections.Asc);
    } else {
      setSortDirection(SortDirections.Asc);
    }
    setSortBy(newSortBy);
  };
  const rigSort = useRigSort();

  const unit = useUOM(DimensionType.Metres);
  const sortedWellSummaries = useMemo(
    () =>
      [...(wellSummaries?.data?.wells ?? [])]?.sort((a, b) => {
        switch (sortBy) {
          case SortBy.Rigs:
            return sortByRigs(a, b, sortDirection, rigSort);
          case SortBy.Start:
            if (a.status === WellStatusType.Pending) {
              return sortDirection === SortDirections.Asc ? 1 : -1;
            } else if (b.status === WellStatusType.Pending) {
              return sortDirection === SortDirections.Asc ? -1 : 1;
            }

            return sortByValue(a.spudDateTime?.utc.getTime(), b.spudDateTime?.utc.getTime(), sortDirection);

          case SortBy.End:
            return sortWellsByEnd(a, b, sortDirection, rigSort);

          case SortBy.Days:
            return sortByValue(+a.cumulativeDuration, +b.cumulativeDuration, sortDirection);

          case SortBy.Hole:
            return sortByValue(+a.lastHoleDepth, +b.lastHoleDepth, sortDirection);

          case SortBy.Bit:
            return sortByValue(+a.lastBitDepth, +b.lastBitDepth, sortDirection);

          case SortBy.Wells:
          default:
            return sortDirection === SortDirections.Asc
              ? (a.name ?? "").localeCompare(b.name ?? "")
              : (b.name ?? "").localeCompare(a.name ?? "");
        }
      }),
    [sortBy, rigSort, sortDirection, wellSummaries?.data?.wells],
  );

  // TODO For some reason sorted well summaries changes too often
  const sortedWellSummariesIds = useMemo(() => sortedWellSummaries.map((well) => well.id), [sortedWellSummaries]);
  const itemData = useMemo(
    () => sortedWellSummaries.filter((well) => (visibleWellsList ?? []).includes(well.id)) ?? [],
    [sortedWellSummaries, visibleWellsList],
  );

  useEffect(() => {
    if (sortedWellSummariesIds?.length) {
      setVisibleWellsList(sortedWellSummariesIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setVisibleWellsList, JSON.stringify(sortedWellSummariesIds)]);

  if (wellSummaries.isLoading || !wellSummaries.data) {
    return (
      <LoaderContainer>
        <Loader withWrapper />
      </LoaderContainer>
    );
  }

  const ColumnTitle = ({ column, title, width }: { column?: SortBy; title: string; width?: number }) => {
    return (
      <TitleContainer
        width={width}
        onClick={() => {
          if (column) handleSort(column);
        }}
      >
        <StyledTitle>{title}</StyledTitle>
        {column ? <SortIcon column={column} /> : null}
      </TitleContainer>
    );
  };
  const noResults =
    !wellSummaries.isLoading && !(wellSummaries.data?.wells?.length && wellSummaries.data?.wells?.length >= 1);

  if (noResults) {
    return (
      <Row justify="center" align="middle" style={{ marginTop: "120px" }}>
        <Col>
          <Space
            direction="vertical"
            style={{
              alignItems: "center",
            }}
          >
            <IconHolder>
              <PDComponent.SvgIcon name="list" />
            </IconHolder>
            <Title level={3} variant="faded">
              No Results
            </Title>
          </Space>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <StyledHeader>
        <WellContainer>
          <ColumnTitle column={SortBy.Wells} title="Wells" />
        </WellContainer>
        <InfoContainer>
          <ColumnTitle column={SortBy.Rigs} title="Rigs" width={96} />
          <ColumnTitle column={SortBy.Start} title="Start date" width={95} />
          <ColumnTitle column={SortBy.End} title="End date" width={85} />
          <ColumnTitle column={SortBy.Days} title="Days" width={58} />
          <ColumnTitle column={SortBy.Hole} title={`Hole(${unit.abbr})`} />
          <ColumnTitle column={SortBy.Bit} title={`Bit(${unit.abbr})`} />
          <ColumnTitle title={`TvD`} />
          <div style={{ width: "calc(52px)" }} />
        </InfoContainer>
      </StyledHeader>

      <LimitedContainer key={sortBy + sortDirection}>
        {!wellSummaries.isLoading && wellSummaries.data ? (
          <PDComponent.VirtualizedList items={itemData} itemSizePx={67} scrollbarDistance={4} overscanCount={2}>
            {(well, idx) => (
              <StyledRow key={`${well.id}-${idx}`}>
                <SingleWell wellDetails={well} />
              </StyledRow>
            )}
          </PDComponent.VirtualizedList>
        ) : null}
      </LimitedContainer>
    </>
  );
};

export default memo(WellsContainer);
