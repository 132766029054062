/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface ActualTvdPointDto
 */
export interface ActualTvdPointDto {
    /**
     * 
     * @type {number}
     * @memberof ActualTvdPointDto
     */
    cumulativeDuration: number;
    /**
     * 
     * @type {number}
     * @memberof ActualTvdPointDto
     */
    dynamicDuration: number;
    /**
     * 
     * @type {number}
     * @memberof ActualTvdPointDto
     */
    holeDepth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActualTvdPointDto
     */
    dynamicHoleDepth?: number | null;
    /**
     * 
     * @type {DateDto}
     * @memberof ActualTvdPointDto
     */
    at: DateDto;
    /**
     * 
     * @type {number}
     * @memberof ActualTvdPointDto
     */
    operationClassId: number;
    /**
     * 
     * @type {number}
     * @memberof ActualTvdPointDto
     */
    bitDepth?: number | null;
}

export function ActualTvdPointDtoFromJSON(json: any): ActualTvdPointDto {
    return ActualTvdPointDtoFromJSONTyped(json, false);
}

export function ActualTvdPointDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActualTvdPointDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cumulativeDuration': json['CumulativeDuration'],
        'dynamicDuration': json['DynamicDuration'],
        'holeDepth': !exists(json, 'HoleDepth') ? undefined : json['HoleDepth'],
        'dynamicHoleDepth': !exists(json, 'DynamicHoleDepth') ? undefined : json['DynamicHoleDepth'],
        'at': DateDtoFromJSON(json['At']),
        'operationClassId': json['OperationClassId'],
        'bitDepth': !exists(json, 'BitDepth') ? undefined : json['BitDepth'],
    };
}

export function ActualTvdPointDtoToJSON(value?: ActualTvdPointDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'CumulativeDuration': value.cumulativeDuration,
        'DynamicDuration': value.dynamicDuration,
        'HoleDepth': value.holeDepth,
        'DynamicHoleDepth': value.dynamicHoleDepth,
        'At': DateDtoToJSON(value.at),
        'OperationClassId': value.operationClassId,
        'BitDepth': value.bitDepth,
    };
}

