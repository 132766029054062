/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    ZTorqueOnOffPair,
    ZTorqueOnOffPairFromJSON,
    ZTorqueOnOffPairFromJSONTyped,
    ZTorqueOnOffPairToJSON,
} from './ZTorqueOnOffPair';

/**
 * 
 * @export
 * @interface ZTorqueSummaryDto
 */
export interface ZTorqueSummaryDto {
    /**
     * 
     * @type {ResultDataState}
     * @memberof ZTorqueSummaryDto
     */
    dataState: ResultDataState;
    /**
     * 
     * @type {number}
     * @memberof ZTorqueSummaryDto
     */
    wellId: number;
    /**
     * 
     * @type {ZTorqueOnOffPair}
     * @memberof ZTorqueSummaryDto
     */
    onOffRatio: ZTorqueOnOffPair;
    /**
     * 
     * @type {ZTorqueOnOffPair}
     * @memberof ZTorqueSummaryDto
     */
    averageTorqueStdDev: ZTorqueOnOffPair;
    /**
     * 
     * @type {ZTorqueOnOffPair}
     * @memberof ZTorqueSummaryDto
     */
    averageRateOfPenetration: ZTorqueOnOffPair;
    /**
     * 
     * @type {ZTorqueOnOffPair}
     * @memberof ZTorqueSummaryDto
     */
    averageSssi: ZTorqueOnOffPair;
}

export function ZTorqueSummaryDtoFromJSON(json: any): ZTorqueSummaryDto {
    return ZTorqueSummaryDtoFromJSONTyped(json, false);
}

export function ZTorqueSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZTorqueSummaryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataState': ResultDataStateFromJSON(json['DataState']),
        'wellId': json['WellId'],
        'onOffRatio': ZTorqueOnOffPairFromJSON(json['OnOffRatio']),
        'averageTorqueStdDev': ZTorqueOnOffPairFromJSON(json['AverageTorqueStdDev']),
        'averageRateOfPenetration': ZTorqueOnOffPairFromJSON(json['AverageRateOfPenetration']),
        'averageSssi': ZTorqueOnOffPairFromJSON(json['AverageSssi']),
    };
}

export function ZTorqueSummaryDtoToJSON(value?: ZTorqueSummaryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DataState': ResultDataStateToJSON(value.dataState),
        'WellId': value.wellId,
        'OnOffRatio': ZTorqueOnOffPairToJSON(value.onOffRatio),
        'AverageTorqueStdDev': ZTorqueOnOffPairToJSON(value.averageTorqueStdDev),
        'AverageRateOfPenetration': ZTorqueOnOffPairToJSON(value.averageRateOfPenetration),
        'AverageSssi': ZTorqueOnOffPairToJSON(value.averageSssi),
    };
}

