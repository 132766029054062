/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PlanFormationDto,
    PlanFormationDtoFromJSON,
    PlanFormationDtoFromJSONTyped,
    PlanFormationDtoToJSON,
} from './PlanFormationDto';

/**
 * 
 * @export
 * @interface PlanFormationSetDto
 */
export interface PlanFormationSetDto {
    /**
     * 
     * @type {number}
     * @memberof PlanFormationSetDto
     */
    startDepth: number;
    /**
     * 
     * @type {Array<PlanFormationDto>}
     * @memberof PlanFormationSetDto
     */
    formations: Array<PlanFormationDto>;
}

export function PlanFormationSetDtoFromJSON(json: any): PlanFormationSetDto {
    return PlanFormationSetDtoFromJSONTyped(json, false);
}

export function PlanFormationSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanFormationSetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startDepth': json['StartDepth'],
        'formations': ((json['Formations'] as Array<any>).map(PlanFormationDtoFromJSON)),
    };
}

export function PlanFormationSetDtoToJSON(value?: PlanFormationSetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StartDepth': value.startDepth,
        'Formations': ((value.formations as Array<any>).map(PlanFormationDtoToJSON)),
    };
}

