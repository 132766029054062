/* eslint-disable react/forbid-dom-props */
import { useVirtualizer } from "@tanstack/react-virtual";
import { PDComponent } from "components/PDComponents";
import { type ScrollHintContainerProps } from "components/PDComponents/ScrollHintContainer/ScrollHintContainer";
import { ListRowItem } from "components/PDComponents/VirtualizedList/ListRowItem";
import type { ReactElement } from "react";
import React, { startTransition, useEffect } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import type { FixedSizeListProps } from "react-window";

interface VirtualizedListProps<T> {
  items: T[];
  itemSizePx: number;
  children(item: T, index: number): ReactElement;
  listProps?: Partial<FixedSizeListProps<{ items: T[] }>>;
  scrollbarDistance?: number;
  scrollHintProps?: ScrollHintContainerProps;
  hideScrollHints?: boolean;
  overscanCount?: number;
}

export function _VirtualizedList<T>({
  items,
  itemSizePx,
  hideScrollHints,
  scrollbarDistance = 0,
  overscanCount = 10,
  children,
}: VirtualizedListProps<T>) {
  const parentRef = React.useRef(null);

  const rowVirtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => itemSizePx,
    overscan: overscanCount,
  });

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    startTransition(() => {
      timeoutId = setTimeout(() => {
        // To force update for some items not being rendered because of autosizer delay
        rowVirtualizer._willUpdate();
      }, 0);
    });
    () => clearTimeout(timeoutId);
  }, [rowVirtualizer]);

  return (
    <AutoSizer>
      {({ height, width }: { width: number; height: number }) => {
        const containerStyle = {
          height: `${height}px`,
          width: `${width - scrollbarDistance}px`,
          overflow: "auto",
        };

        return (
          <PDComponent.ScrollHintContainer ref={parentRef} style={containerStyle} disabled={hideScrollHints}>
            <div
              style={{
                height: `${rowVirtualizer.getTotalSize()}px`,
                width: "100%",
                position: "relative",
              }}
            >
              {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                const item = items[virtualRow.index];
                return (
                  <ListRowItem virtualRow={virtualRow} key={virtualRow.index}>
                    {children(item, virtualRow.index)}
                  </ListRowItem>
                );
              })}
            </div>
          </PDComponent.ScrollHintContainer>
        );
      }}
    </AutoSizer>
  );
}
