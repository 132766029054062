/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    RigPowerFactDto,
    RigPowerFactDtoFromJSON,
    RigPowerFactDtoFromJSONTyped,
    RigPowerFactDtoToJSON,
} from './RigPowerFactDto';
import {
    ServiceMessageDto,
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import {
    StopWatchDto,
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';

/**
 * 
 * @export
 * @interface PowerConsumptionFactSetDto
 */
export interface PowerConsumptionFactSetDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof PowerConsumptionFactSetDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof PowerConsumptionFactSetDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof PowerConsumptionFactSetDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof PowerConsumptionFactSetDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {Array<RigPowerFactDto>}
     * @memberof PowerConsumptionFactSetDto
     */
    facts?: Array<RigPowerFactDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof PowerConsumptionFactSetDto
     */
    availableOperationClasses?: Array<number> | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof PowerConsumptionFactSetDto
     */
    dataState: ResultDataState;
}

export function PowerConsumptionFactSetDtoFromJSON(json: any): PowerConsumptionFactSetDto {
    return PowerConsumptionFactSetDtoFromJSONTyped(json, false);
}

export function PowerConsumptionFactSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PowerConsumptionFactSetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': !exists(json, 'Messages') ? undefined : (json['Messages'] === null ? null : (json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'facts': !exists(json, 'Facts') ? undefined : (json['Facts'] === null ? null : (json['Facts'] as Array<any>).map(RigPowerFactDtoFromJSON)),
        'availableOperationClasses': !exists(json, 'AvailableOperationClasses') ? undefined : json['AvailableOperationClasses'],
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function PowerConsumptionFactSetDtoToJSON(value?: PowerConsumptionFactSetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value.stopWatch),
        'Messages': value.messages === undefined ? undefined : (value.messages === null ? null : (value.messages as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value.userLensId,
        'LastUpdatedAt': DateDtoToJSON(value.lastUpdatedAt),
        'Facts': value.facts === undefined ? undefined : (value.facts === null ? null : (value.facts as Array<any>).map(RigPowerFactDtoToJSON)),
        'AvailableOperationClasses': value.availableOperationClasses,
        'DataState': ResultDataStateToJSON(value.dataState),
    };
}

