/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface GeneratorStatusBarFactDto
 */
export interface GeneratorStatusBarFactDto {
    /**
     * 
     * @type {number}
     * @memberof GeneratorStatusBarFactDto
     */
    onlineGeneratorCount: number;
    /**
     * 
     * @type {number}
     * @memberof GeneratorStatusBarFactDto
     */
    indexStart: number;
    /**
     * 
     * @type {number}
     * @memberof GeneratorStatusBarFactDto
     */
    indexEnd: number;
    /**
     * 
     * @type {DateDto}
     * @memberof GeneratorStatusBarFactDto
     */
    from: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof GeneratorStatusBarFactDto
     */
    to: DateDto;
}

export function GeneratorStatusBarFactDtoFromJSON(json: any): GeneratorStatusBarFactDto {
    return GeneratorStatusBarFactDtoFromJSONTyped(json, false);
}

export function GeneratorStatusBarFactDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneratorStatusBarFactDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'onlineGeneratorCount': json['OnlineGeneratorCount'],
        'indexStart': json['IndexStart'],
        'indexEnd': json['IndexEnd'],
        'from': DateDtoFromJSON(json['From']),
        'to': DateDtoFromJSON(json['To']),
    };
}

export function GeneratorStatusBarFactDtoToJSON(value?: GeneratorStatusBarFactDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'OnlineGeneratorCount': value.onlineGeneratorCount,
        'IndexStart': value.indexStart,
        'IndexEnd': value.indexEnd,
        'From': DateDtoToJSON(value.from),
        'To': DateDtoToJSON(value.to),
    };
}

