import { WellStatusType } from "apis/oag";
import { CustomSwitch } from "atoms/common";
import type { RangeType } from "atoms/DatePicker";
import { DatePicker } from "atoms/DatePicker";
import { Button } from "atoms/Form";
import { Title } from "atoms/Typography";
import AllRigFilters from "components/AllRigs/Filters";
import type { FilterTypes } from "components/AllRigs/Filters/useFiltersCategories";
import { useFiltersCategories } from "components/AllRigs/Filters/useFiltersCategories";
import { getTitleRealTimeIndicator } from "components/DetailsTopBar";
import { PDComponent } from "components/PDComponents";
import type { IOption } from "components/PDComponents/Search/utils";
import { RealTimeDataEnum, RealTimeIndicator } from "components/RealTimeIndicator";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useDashboardType } from "hooks/useDashboardType";
import { useFormations } from "hooks/useFormations";
import { useOperators } from "hooks/useOperators";
import { useOperatorsActiveRigs } from "hooks/useOperatorsActiveRigs";
import { useRigs } from "hooks/useRigs";
import { useRigsActiveOperators } from "hooks/useRigsActiveOperators";
import { useRigSort } from "hooks/useRigSort";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import type { IFilterOptionValue } from "reducers/allRigsReducer";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { PDRoutesMapping } from "routes";
import { Track } from "services/Mixpanel";
import colors from "utils/colors";
import { Col, Popover, Space, Tooltip } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

import type { FilterTag } from "./FilterTags";
import { FilterTags } from "./FilterTags";
import * as Styled from "./styled";
import { StyledTitle } from "./styled";

function Header({ totalRigs }: { totalRigs: number }) {
  const dispatch = useAppDispatch();
  const operators = useOperators();
  const rigs = useRigs();
  const sortByRigs = useRigSort();
  const { isEvergreen } = useDashboardType();
  const { data: currentUser } = useCurrentUser();

  const realTimeDataState = useAppSelector((state) => state.global.realTimeData);
  const periodState = useAppSelector((state) => state.allRigs.period);
  const allWellsIncluded = useAppSelector((state) => state.allRigs.allWellsIncluded);

  const [showOperatorPopup, setShowOperatorPopup] = useState(false);
  const [showRigPopup, setShowRigPopup] = useState(false);

  const _operatorsState = useAppSelector((state) => state.allRigs.operators);
  const _rigIdsState = useAppSelector((state) => state.allRigs.rigIds);
  const operatorsState = useMemo(() => {
    return _operatorsState?.length ? _operatorsState : operators?.data?.list.map((e) => e.id);
  }, [_operatorsState, operators?.data?.list]);
  const rigIdsState = useMemo(() => {
    return _rigIdsState?.length ? _rigIdsState : rigs?.data?.list.map((e) => e.id);
  }, [_rigIdsState, rigs?.data?.list]);

  const rigIds = useOperatorsActiveRigs(operatorsState, isEvergreen);
  const operatorIds = useRigsActiveOperators(rigIdsState || [], isEvergreen);
  const exceptions = useAppSelector((state) => state.allRigs.exceptions);
  const { data: formations } = useFormations();
  const formationIdsState = useAppSelector((state) => state.allRigs.formationIds);
  const [showFormationsPopup, setShowFormationsPopup] = useState(false);

  const rigOptions = useMemo(() => {
    const list = rigIds.data;
    const set = new Set(list ?? []);
    if (rigs.data?.byId)
      return Array.from(set)
        .sort(sortByRigs)
        .map((rigId) => ({
          id: rigId,
          name: (rigs.data?.byId[rigId]?.shortName || "").toString(),
        }));
    else return [];
  }, [rigIds.data, rigs.data?.byId, sortByRigs]);

  const operatorOptions = useMemo(() => {
    const list = operatorIds.data;

    const set = new Set(list ?? []);

    if (operators.data?.byId) return Array.from(set).map((operatorId) => operators.data?.byId[operatorId]);
    else return [];
  }, [operatorIds.data, operators.data?.byId]);

  const updateOperators = useCallback(
    (operatorIds: number[] | null) => {
      dispatch({
        type: "ALL_RIGS_SET_OPERATORS",
        payload: {
          operators: operatorIds ? operatorIds.slice().sort((a, b) => a - b) : null,
        },
      });
      return true;
    },
    [dispatch],
  );

  const updateRigs = useCallback(
    (rigIds: number[] | null) => {
      dispatch({
        type: "ALL_RIGS_SET_RIGS",
        payload: {
          rigIds: rigIds ? rigIds.slice().sort((a, b) => a - b) : null,
        },
      });
      return true;
    },
    [dispatch],
  );
  const [hasLoaded, setHasLoaded] = useState({
    operators: false,
    rigs: false,
  });

  const updateFormations = useCallback(
    (formationIds: number[] | null) => {
      dispatch({
        type: "ALL_RIGS_FORMATION_IDS",
        payload: formationIds ? formationIds.slice().sort((a, b) => a - b) : null,
      });
      return true;
    },
    [dispatch],
  );

  useEffect(() => {
    if (rigs.data && !hasLoaded.rigs) {
      updateRigs(rigs.data.list.map((e) => e.id));
      setHasLoaded((prev) => ({
        ...prev,
        rigs: true,
      }));
    }
  }, [rigs.data, hasLoaded.rigs, updateRigs]);
  useEffect(() => {
    if (operators.data && !hasLoaded.operators) {
      updateOperators(operators.data.list.map((e) => e.id));
      setHasLoaded((prev) => ({
        ...prev,
        operators: true,
      }));
    }
  }, [hasLoaded.operators, operators.data, updateOperators]);
  useEffect(() => {
    updateFormations(null);
    return () => {
      updateFormations(null);
    };
  }, [updateFormations]);

  const updatePeriod = useCallback(
    (period: RangeType) => {
      Track.interact("All rigs - Update Period", {
        "Start Date": period.startDate?.toISOString?.() ?? "",
        "End Date": period.endDate?.toISOString?.() ?? "",
      });

      dispatch({
        type: "ALL_RIGS_SET_PERIOD",
        payload: period,
      });
      return true;
    },
    [dispatch],
  );

  const resetFilters = useCallback(() => {
    updateOperators(null);
    updateRigs(null);
    setShowOperatorPopup(false);
    setShowRigPopup(false);
    setShowFormationsPopup(false);
  }, [updateOperators, updateRigs]);

  const { atomThemeVariant } = useCustomTheme();

  const { localFilters, setLocalFilters, allFilters, initialFilters } = useFiltersCategories();

  const tags = useMemo(() => {
    let tags: FilterTag[] = [];
    if (!operators.data || !rigs.data) return [];

    // OPERATOR STATE FILTERS
    const operatorSelectedOptionsCount = operatorOptions.reduce((acc, operatorOption) => {
      if (operatorsState?.includes(operatorOption?.id)) return acc + 1;
      else return acc;
    }, 0);

    if (operatorIds.data && operatorSelectedOptionsCount < operatorOptions.length && operatorsState?.length >= 1) {
      const plusText = ` +${operatorsState?.length}`;
      tags = tags.concat({
        onRemove: () => updateOperators(null),
        text: operatorsState?.length > 1 ? `Operators ${plusText}` : operators.data.byId[operatorsState[0]].name || "",
      });
    }

    // RIG FILTERS
    const rigSelectedOptionsCount = rigOptions.reduce((acc, rigOption) => {
      if (rigIdsState?.includes(rigOption.id)) return acc + 1;
      else return acc;
    }, 0);

    if (rigIds?.data && rigSelectedOptionsCount < rigOptions.length && rigIdsState?.length >= 1) {
      const plusText = ` +${rigSelectedOptionsCount}`;
      tags = tags.concat({
        onRemove: () => updateRigs(null),
        text: rigIdsState?.length > 1 ? `Rigs ${plusText}` : rigs.data.byId[rigIdsState[0]].shortName || "",
      });
    }

    if (formationIdsState && formationIdsState.length) {
      tags = tags.concat({
        onRemove: () => updateFormations(null),
        text:
          formationIdsState.length > 1
            ? `Formations +${formationIdsState.length}`
            : formations?.list.find((formation) => formation.id === formationIdsState[0])?.name || "",
      });
    }

    // MODAL FILTERS
    (Object.keys(allFilters) as Array<FilterTypes>).forEach((filterType) => {
      const selectedFilterOptions = localFilters?.[filterType];
      const allFilterItems = allFilters?.[filterType]?.options;
      if (
        selectedFilterOptions &&
        selectedFilterOptions.length &&
        allFilterItems &&
        selectedFilterOptions?.length !== allFilterItems?.length
      ) {
        const label = selectedFilterOptions[0].value;

        const finalFilterText =
          (selectedFilterOptions?.length > 1 ? label + ` +${selectedFilterOptions?.length - 1}` : label) + "";

        tags = tags.concat({
          onRemove: () =>
            setLocalFilters((prev) => {
              if (prev) {
                prev[filterType] = allFilterItems;
                dispatch({ type: "ALL_RIGS_SET_FILTERS", payload: { ...prev } });
                return { ...prev };
              }
              return prev;
            }),
          text: finalFilterText,
        });
      }
    });

    return tags;
  }, [
    allFilters,
    dispatch,
    formationIdsState,
    formations?.list,
    localFilters,
    operatorIds.data,
    operatorOptions,
    operators.data,
    operatorsState,
    rigIds?.data,
    rigIdsState,
    rigOptions,
    rigs.data,
    setLocalFilters,
    updateFormations,
    updateOperators,
    updateRigs,
  ]);

  const location = useLocation();

  return (
    <Styled.HeaderContainerRow justify="space-between" align="middle" wrap={false}>
      <Col span={3}>
        <Title level={3} variant={atomThemeVariant} weight={500}>
          Rigs ({totalRigs})
        </Title>
      </Col>

      <Col>
        <Space>
          <FilterTags tags={tags} />
          <AllRigFilters />
          {/* Rig Selection Controls */}
          {!rigs.isLoading && (
            <Tooltip title="Rig">
              <Popover
                content={
                  <PDComponent.ComboBoxMultiSelect
                    placeholder="Search Rigs"
                    options={rigOptions}
                    values={rigIdsState ?? (rigOptions ?? []).map((rig) => rig.id)}
                    onReset={() => {
                      Track.clickEvent("All rigs - Reset Rigs");

                      resetFilters();
                    }}
                    onChange={(e: number[]) => {
                      Track.interact("All Rigs - Rigs", {
                        Action: "Update",
                      });
                      updateRigs(e);
                      setShowRigPopup(false);
                    }}
                  />
                }
                trigger="click"
                placement="bottom"
                open={showRigPopup}
                onOpenChange={(e) => setShowRigPopup(e)}
                destroyTooltipOnHide
              >
                <Button
                  size="large"
                  loading={rigIds.isLoading}
                  icon={<PDComponent.SvgIcon name="rig" />}
                  type={
                    rigIdsState !== null && (rigIdsState ?? [])?.length !== rigs.data?.list.length
                      ? "primary"
                      : "default"
                  }
                  ghost={rigIdsState !== null && (rigIdsState ?? [])?.length !== rigs.data?.list.length}
                  $engaged={showRigPopup}
                />
              </Popover>
            </Tooltip>
          )}
          {/* Operator Selection Controls */}
          {!operators.isLoading && (
            <Tooltip title="Operator">
              <Popover
                content={
                  <PDComponent.ComboBoxMultiSelect
                    placeholder="Search"
                    options={operatorOptions as IOption<number>[]}
                    values={operatorsState ?? (operatorOptions ?? []).filter((op) => op).map((op) => op.id)}
                    onReset={() => {
                      Track.clickEvent("All rigs - Reset Operators");
                      resetFilters();
                    }}
                    onChange={(e) => {
                      Track.interact("All Rigs - Operator", {
                        Action: "Update",
                      });
                      updateOperators(e);
                      setShowOperatorPopup(false);
                    }}
                  />
                }
                trigger="click"
                placement="bottom"
                open={showOperatorPopup}
                onOpenChange={(e) => setShowOperatorPopup(e)}
                destroyTooltipOnHide
              >
                <Button
                  size="large"
                  loading={operatorIds.isLoading}
                  icon={<PDComponent.SvgIcon name="identification" />}
                  type={
                    operatorsState !== null &&
                    (operatorsState ?? operatorOptions ?? []).length !== operators.data?.list.length
                      ? "primary"
                      : "default"
                  }
                  ghost={(operatorsState ?? operatorOptions ?? []).length !== operators.data?.list.length}
                  $engaged={showOperatorPopup}
                />
              </Popover>
            </Tooltip>
          )}
          {/* Full Wells Included */}
          <Tooltip title="Full Wells Included">
            <Button
              size="large"
              loading={rigIds.isLoading || operatorIds.isLoading}
              style={{
                border:
                  !(rigIds.isLoading || operatorIds.isLoading) && allWellsIncluded
                    ? `1px solid ${colors.well_color}`
                    : undefined,
              }}
              icon={
                <PDComponent.SvgIcon
                  name="allWellsIncluded"
                  style={{
                    color:
                      !(rigIds.isLoading || operatorIds.isLoading) && allWellsIncluded ? colors.well_color : undefined,
                  }}
                />
              }
              onClick={() => {
                Track.interact("All Rigs - Full Wells Included", {
                  State: !allWellsIncluded ? "Active" : "Disabled",
                });
                dispatch({
                  type: "ALL_RIGS_FULL_WELLS_INCLUDED",
                  payload: !allWellsIncluded,
                });
              }}
            />
          </Tooltip>
          {/* Formation Filter */}
          {formations?.list && location.pathname === PDRoutesMapping.private.allRigs.path ? (
            <Tooltip title="Target Formation">
              <Popover
                content={
                  <PDComponent.ComboBoxMultiSelect
                    placeholder="Target Formation Name"
                    options={formations?.list.map((formation) => ({
                      id: formation.id,
                      name: formation.name || "Unknown formation",
                    }))}
                    values={formationIdsState ?? formations?.list.map((formation) => formation.id)}
                    onReset={() => {
                      Track.interact(`All rigs - Formations Reset`);
                      updateFormations(null);
                      setShowFormationsPopup(false);
                    }}
                    onChange={(e) => {
                      updateFormations(e);
                      setShowFormationsPopup(false);
                    }}
                  />
                }
                trigger="click"
                placement="bottom"
                open={showFormationsPopup}
                onOpenChange={(e) => setShowFormationsPopup(e)}
                destroyTooltipOnHide
              >
                <Button
                  size="large"
                  icon={<PDComponent.SvgIcon name="formation" />}
                  type={
                    formationIdsState && (formationIdsState ?? [])?.length !== formations.list.length
                      ? "primary"
                      : "default"
                  }
                  ghost={!!(formationIdsState && (formationIdsState ?? [])?.length !== formations.list.length)}
                  $engaged={showFormationsPopup}
                />
              </Popover>
            </Tooltip>
          ) : null}

          {/* Period Filter */}
          <DatePicker selection={periodState} onApply={updatePeriod} />

          <PDComponent.VerticalDivider />

          <Tooltip title={getTitleRealTimeIndicator(realTimeDataState)}>
            <Button
              size="large"
              onClick={() => {
                Track.interact("All Rigs - Real Time", {
                  State: realTimeDataState === RealTimeDataEnum.ACTIVE ? "Disabled" : "Active",
                });
                dispatch({
                  type: "SET_REAL_TIME_DATA",
                  payload:
                    realTimeDataState === RealTimeDataEnum.ACTIVE ? RealTimeDataEnum.DISABLED : RealTimeDataEnum.ACTIVE,
                });
              }}
              icon={<RealTimeIndicator realTimeDataState={realTimeDataState} />}
            />
          </Tooltip>
          {currentUser?.canAccessRigScorecard ? (
            <>
              <PDComponent.VerticalDivider />
              <Space>
                <StyledTitle variant={atomThemeVariant}>Exceptions</StyledTitle>
                <CustomSwitch
                  $isChecked={exceptions}
                  checked={exceptions}
                  onChange={(e) => {
                    dispatch({
                      type: "ALL_RIGS_EXCEPTIONS_INCLUDED",
                      payload: e,
                    });
                    Track.interact("Rig Leaderboard - Exceptions", { Value: e ? "On" : "Off" });
                  }}
                />
              </Space>
              <PDComponent.VerticalDivider />

              <Styled.Buttons size="large">
                <Link to={PDRoutesMapping.private.rigsLeaderboard.path}>
                  <Button
                    shape="round"
                    size="large"
                    type={location.pathname === PDRoutesMapping.private.rigsLeaderboard.path ? "primary" : "default"}
                    onClick={() => {
                      Track.interact("All Rigs - Leaderboard");

                      dispatch({
                        type: "RESET_ALL_RIGS_FILTERS_90_DAYS",
                        payload: {
                          ...initialFilters,
                          wellStatus: [
                            ...initialFilters.wellStatus.filter(
                              (option: IFilterOptionValue) => option.value === WellStatusType.Completed,
                            ),
                          ],
                        },
                      });
                    }}
                  >
                    Leaderboard
                  </Button>
                </Link>

                <Link to={PDRoutesMapping.private.allRigs.path}>
                  <Button
                    shape="round"
                    size="large"
                    type={location.pathname === PDRoutesMapping.private.allRigs.path ? "primary" : "default"}
                    onClick={() => {
                      Track.interact("All metrics - Fleet Performance");
                      dispatch({ type: "RESET_ALL_RIGS_FILTERS_90_DAYS" });
                    }}
                  >
                    Fleet Performance
                  </Button>
                </Link>
              </Styled.Buttons>
            </>
          ) : null}
        </Space>
      </Col>
    </Styled.HeaderContainerRow>
  );
}

export default Header;
