/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface FuelTankVolumeFactDto
 */
export interface FuelTankVolumeFactDto {
    /**
     * 
     * @type {number}
     * @memberof FuelTankVolumeFactDto
     */
    wellId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof FuelTankVolumeFactDto
     */
    at: DateDto;
    /**
     * 
     * @type {number}
     * @memberof FuelTankVolumeFactDto
     */
    value: number;
}

export function FuelTankVolumeFactDtoFromJSON(json: any): FuelTankVolumeFactDto {
    return FuelTankVolumeFactDtoFromJSONTyped(json, false);
}

export function FuelTankVolumeFactDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FuelTankVolumeFactDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'wellId': json['WellId'],
        'at': DateDtoFromJSON(json['At']),
        'value': json['Value'],
    };
}

export function FuelTankVolumeFactDtoToJSON(value?: FuelTankVolumeFactDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'WellId': value.wellId,
        'At': DateDtoToJSON(value.at),
        'Value': value.value,
    };
}

