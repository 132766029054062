/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ClaimType = {
    Unknown: 'Unknown',
    Well: 'Well',
    FutureWellAccess: 'FutureWellAccess',
    FutureRigAccess: 'FutureRigAccess'
} as const;
export type ClaimType = typeof ClaimType[keyof typeof ClaimType];


export function ClaimTypeFromJSON(json: any): ClaimType {
    return ClaimTypeFromJSONTyped(json, false);
}

export function ClaimTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClaimType {
    return json as ClaimType;
}

export function ClaimTypeToJSON(value?: ClaimType | null): any {
    return value as any;
}

