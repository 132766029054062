/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OperatorStatusType,
    OperatorStatusTypeFromJSON,
    OperatorStatusTypeFromJSONTyped,
    OperatorStatusTypeToJSON,
} from './OperatorStatusType';

/**
 * 
 * @export
 * @interface AccountDto
 */
export interface AccountDto {
    /**
     * 
     * @type {number}
     * @memberof AccountDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof AccountDto
     */
    activeProfileId: number;
    /**
     * 
     * @type {number}
     * @memberof AccountDto
     */
    userCount: number;
    /**
     * 
     * @type {number}
     * @memberof AccountDto
     */
    activeUserCount: number;
    /**
     * 
     * @type {number}
     * @memberof AccountDto
     */
    operatorRigCount: number;
    /**
     * 
     * @type {number}
     * @memberof AccountDto
     */
    operatorWellCount: number;
    /**
     * 
     * @type {number}
     * @memberof AccountDto
     */
    claimedWellCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDto
     */
    ongoingAccess: boolean;
    /**
     * 
     * @type {OperatorStatusType}
     * @memberof AccountDto
     */
    status: OperatorStatusType;
}

export function AccountDtoFromJSON(json: any): AccountDto {
    return AccountDtoFromJSONTyped(json, false);
}

export function AccountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': json['Name'],
        'activeProfileId': json['ActiveProfileId'],
        'userCount': json['UserCount'],
        'activeUserCount': json['ActiveUserCount'],
        'operatorRigCount': json['OperatorRigCount'],
        'operatorWellCount': json['OperatorWellCount'],
        'claimedWellCount': json['ClaimedWellCount'],
        'ongoingAccess': json['OngoingAccess'],
        'status': OperatorStatusTypeFromJSON(json['Status']),
    };
}

export function AccountDtoToJSON(value?: AccountDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'Name': value.name,
        'ActiveProfileId': value.activeProfileId,
        'UserCount': value.userCount,
        'ActiveUserCount': value.activeUserCount,
        'OperatorRigCount': value.operatorRigCount,
        'OperatorWellCount': value.operatorWellCount,
        'ClaimedWellCount': value.claimedWellCount,
        'OngoingAccess': value.ongoingAccess,
        'Status': OperatorStatusTypeToJSON(value.status),
    };
}

