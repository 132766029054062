/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScoreSummaryLensDto,
    ScoreSummaryLensDtoFromJSON,
    ScoreSummaryLensDtoFromJSONTyped,
    ScoreSummaryLensDtoToJSON,
} from './ScoreSummaryLensDto';

/**
 * 
 * @export
 * @interface ScoreSummaryTabDto
 */
export interface ScoreSummaryTabDto {
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryTabDto
     */
    targetDeltaTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryTabDto
     */
    invisibleLostTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryTabDto
     */
    tabId: number;
    /**
     * 
     * @type {Array<ScoreSummaryLensDto>}
     * @memberof ScoreSummaryTabDto
     */
    children: Array<ScoreSummaryLensDto>;
    /**
     * 
     * @type {string}
     * @memberof ScoreSummaryTabDto
     */
    description?: string | null;
}

export function ScoreSummaryTabDtoFromJSON(json: any): ScoreSummaryTabDto {
    return ScoreSummaryTabDtoFromJSONTyped(json, false);
}

export function ScoreSummaryTabDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScoreSummaryTabDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'targetDeltaTime': !exists(json, 'TargetDeltaTime') ? undefined : json['TargetDeltaTime'],
        'invisibleLostTime': !exists(json, 'InvisibleLostTime') ? undefined : json['InvisibleLostTime'],
        'tabId': json['TabId'],
        'children': ((json['Children'] as Array<any>).map(ScoreSummaryLensDtoFromJSON)),
        'description': !exists(json, 'Description') ? undefined : json['Description'],
    };
}

export function ScoreSummaryTabDtoToJSON(value?: ScoreSummaryTabDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'TargetDeltaTime': value.targetDeltaTime,
        'InvisibleLostTime': value.invisibleLostTime,
        'TabId': value.tabId,
        'Children': ((value.children as Array<any>).map(ScoreSummaryLensDtoToJSON)),
        'Description': value.description,
    };
}

