/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    DrillingTaskSummary,
    DrillingTaskSummaryFromJSON,
    DrillingTaskSummaryFromJSONTyped,
    DrillingTaskSummaryToJSON,
} from './DrillingTaskSummary';
import {
    FiltersDto,
    FiltersDtoFromJSON,
    FiltersDtoFromJSONTyped,
    FiltersDtoToJSON,
} from './FiltersDto';
import {
    PivotDepthType,
    PivotDepthTypeFromJSON,
    PivotDepthTypeFromJSONTyped,
    PivotDepthTypeToJSON,
} from './PivotDepthType';
import {
    PivotLevelSummaryDto,
    PivotLevelSummaryDtoFromJSON,
    PivotLevelSummaryDtoFromJSONTyped,
    PivotLevelSummaryDtoToJSON,
} from './PivotLevelSummaryDto';
import {
    PivotSummaryByKpiDto,
    PivotSummaryByKpiDtoFromJSON,
    PivotSummaryByKpiDtoFromJSONTyped,
    PivotSummaryByKpiDtoToJSON,
} from './PivotSummaryByKpiDto';
import {
    PivotTargetSegment,
    PivotTargetSegmentFromJSON,
    PivotTargetSegmentFromJSONTyped,
    PivotTargetSegmentToJSON,
} from './PivotTargetSegment';
import {
    PivotTree1Dto,
    PivotTree1DtoFromJSON,
    PivotTree1DtoFromJSONTyped,
    PivotTree1DtoToJSON,
} from './PivotTree1Dto';
import {
    PivotTree2Dto,
    PivotTree2DtoFromJSON,
    PivotTree2DtoFromJSONTyped,
    PivotTree2DtoToJSON,
} from './PivotTree2Dto';
import {
    PivotTree3Dto,
    PivotTree3DtoFromJSON,
    PivotTree3DtoFromJSONTyped,
    PivotTree3DtoToJSON,
} from './PivotTree3Dto';
import {
    PivotType,
    PivotTypeFromJSON,
    PivotTypeFromJSONTyped,
    PivotTypeToJSON,
} from './PivotType';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    ServiceMessageDto,
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import {
    StopWatchDto,
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';

/**
 * 
 * @export
 * @interface KpiPivotFactSetDto
 */
export interface KpiPivotFactSetDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof KpiPivotFactSetDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof KpiPivotFactSetDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof KpiPivotFactSetDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof KpiPivotFactSetDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {FiltersDto}
     * @memberof KpiPivotFactSetDto
     */
    availableFilters: FiltersDto;
    /**
     * 
     * @type {ResultDataState}
     * @memberof KpiPivotFactSetDto
     */
    dataState: ResultDataState;
    /**
     * 
     * @type {PivotDepthType}
     * @memberof KpiPivotFactSetDto
     */
    depth: PivotDepthType;
    /**
     * 
     * @type {PivotTree1Dto}
     * @memberof KpiPivotFactSetDto
     */
    dataDepth1: PivotTree1Dto;
    /**
     * 
     * @type {PivotTree2Dto}
     * @memberof KpiPivotFactSetDto
     */
    dataDepth2: PivotTree2Dto;
    /**
     * 
     * @type {PivotTree3Dto}
     * @memberof KpiPivotFactSetDto
     */
    dataDepth3: PivotTree3Dto;
    /**
     * 
     * @type {Array<PivotSummaryByKpiDto>}
     * @memberof KpiPivotFactSetDto
     */
    summaryByKpi?: Array<PivotSummaryByKpiDto> | null;
    /**
     * 
     * @type {Array<PivotLevelSummaryDto>}
     * @memberof KpiPivotFactSetDto
     */
    pivotLevel1Summaries?: Array<PivotLevelSummaryDto> | null;
    /**
     * 
     * @type {Array<PivotLevelSummaryDto>}
     * @memberof KpiPivotFactSetDto
     */
    pivotLevel2Summaries?: Array<PivotLevelSummaryDto> | null;
    /**
     * 
     * @type {Array<PivotLevelSummaryDto>}
     * @memberof KpiPivotFactSetDto
     */
    pivotLevel3Summaries?: Array<PivotLevelSummaryDto> | null;
    /**
     * 
     * @type {PivotType}
     * @memberof KpiPivotFactSetDto
     */
    pivotLevel1: PivotType;
    /**
     * 
     * @type {PivotType}
     * @memberof KpiPivotFactSetDto
     */
    pivotLevel2: PivotType;
    /**
     * 
     * @type {PivotType}
     * @memberof KpiPivotFactSetDto
     */
    pivotLevel3: PivotType;
    /**
     * 
     * @type {Array<PivotTargetSegment>}
     * @memberof KpiPivotFactSetDto
     */
    targetSegmentsLevel1?: Array<PivotTargetSegment> | null;
    /**
     * 
     * @type {Array<PivotTargetSegment>}
     * @memberof KpiPivotFactSetDto
     */
    targetSegmentsLevel2?: Array<PivotTargetSegment> | null;
    /**
     * 
     * @type {Array<PivotTargetSegment>}
     * @memberof KpiPivotFactSetDto
     */
    targetSegmentsLevel3?: Array<PivotTargetSegment> | null;
    /**
     * 
     * @type {DrillingTaskSummary}
     * @memberof KpiPivotFactSetDto
     */
    drillingTaskSummary: DrillingTaskSummary;
}

export function KpiPivotFactSetDtoFromJSON(json: any): KpiPivotFactSetDto {
    return KpiPivotFactSetDtoFromJSONTyped(json, false);
}

export function KpiPivotFactSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): KpiPivotFactSetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': !exists(json, 'Messages') ? undefined : (json['Messages'] === null ? null : (json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'availableFilters': FiltersDtoFromJSON(json['AvailableFilters']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
        'depth': PivotDepthTypeFromJSON(json['Depth']),
        'dataDepth1': PivotTree1DtoFromJSON(json['DataDepth1']),
        'dataDepth2': PivotTree2DtoFromJSON(json['DataDepth2']),
        'dataDepth3': PivotTree3DtoFromJSON(json['DataDepth3']),
        'summaryByKpi': !exists(json, 'SummaryByKpi') ? undefined : (json['SummaryByKpi'] === null ? null : (json['SummaryByKpi'] as Array<any>).map(PivotSummaryByKpiDtoFromJSON)),
        'pivotLevel1Summaries': !exists(json, 'PivotLevel1Summaries') ? undefined : (json['PivotLevel1Summaries'] === null ? null : (json['PivotLevel1Summaries'] as Array<any>).map(PivotLevelSummaryDtoFromJSON)),
        'pivotLevel2Summaries': !exists(json, 'PivotLevel2Summaries') ? undefined : (json['PivotLevel2Summaries'] === null ? null : (json['PivotLevel2Summaries'] as Array<any>).map(PivotLevelSummaryDtoFromJSON)),
        'pivotLevel3Summaries': !exists(json, 'PivotLevel3Summaries') ? undefined : (json['PivotLevel3Summaries'] === null ? null : (json['PivotLevel3Summaries'] as Array<any>).map(PivotLevelSummaryDtoFromJSON)),
        'pivotLevel1': PivotTypeFromJSON(json['PivotLevel1']),
        'pivotLevel2': PivotTypeFromJSON(json['PivotLevel2']),
        'pivotLevel3': PivotTypeFromJSON(json['PivotLevel3']),
        'targetSegmentsLevel1': !exists(json, 'TargetSegmentsLevel1') ? undefined : (json['TargetSegmentsLevel1'] === null ? null : (json['TargetSegmentsLevel1'] as Array<any>).map(PivotTargetSegmentFromJSON)),
        'targetSegmentsLevel2': !exists(json, 'TargetSegmentsLevel2') ? undefined : (json['TargetSegmentsLevel2'] === null ? null : (json['TargetSegmentsLevel2'] as Array<any>).map(PivotTargetSegmentFromJSON)),
        'targetSegmentsLevel3': !exists(json, 'TargetSegmentsLevel3') ? undefined : (json['TargetSegmentsLevel3'] === null ? null : (json['TargetSegmentsLevel3'] as Array<any>).map(PivotTargetSegmentFromJSON)),
        'drillingTaskSummary': DrillingTaskSummaryFromJSON(json['DrillingTaskSummary']),
    };
}

export function KpiPivotFactSetDtoToJSON(value?: KpiPivotFactSetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value.stopWatch),
        'Messages': value.messages === undefined ? undefined : (value.messages === null ? null : (value.messages as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value.userLensId,
        'LastUpdatedAt': DateDtoToJSON(value.lastUpdatedAt),
        'AvailableFilters': FiltersDtoToJSON(value.availableFilters),
        'DataState': ResultDataStateToJSON(value.dataState),
        'Depth': PivotDepthTypeToJSON(value.depth),
        'DataDepth1': PivotTree1DtoToJSON(value.dataDepth1),
        'DataDepth2': PivotTree2DtoToJSON(value.dataDepth2),
        'DataDepth3': PivotTree3DtoToJSON(value.dataDepth3),
        'SummaryByKpi': value.summaryByKpi === undefined ? undefined : (value.summaryByKpi === null ? null : (value.summaryByKpi as Array<any>).map(PivotSummaryByKpiDtoToJSON)),
        'PivotLevel1Summaries': value.pivotLevel1Summaries === undefined ? undefined : (value.pivotLevel1Summaries === null ? null : (value.pivotLevel1Summaries as Array<any>).map(PivotLevelSummaryDtoToJSON)),
        'PivotLevel2Summaries': value.pivotLevel2Summaries === undefined ? undefined : (value.pivotLevel2Summaries === null ? null : (value.pivotLevel2Summaries as Array<any>).map(PivotLevelSummaryDtoToJSON)),
        'PivotLevel3Summaries': value.pivotLevel3Summaries === undefined ? undefined : (value.pivotLevel3Summaries === null ? null : (value.pivotLevel3Summaries as Array<any>).map(PivotLevelSummaryDtoToJSON)),
        'PivotLevel1': PivotTypeToJSON(value.pivotLevel1),
        'PivotLevel2': PivotTypeToJSON(value.pivotLevel2),
        'PivotLevel3': PivotTypeToJSON(value.pivotLevel3),
        'TargetSegmentsLevel1': value.targetSegmentsLevel1 === undefined ? undefined : (value.targetSegmentsLevel1 === null ? null : (value.targetSegmentsLevel1 as Array<any>).map(PivotTargetSegmentToJSON)),
        'TargetSegmentsLevel2': value.targetSegmentsLevel2 === undefined ? undefined : (value.targetSegmentsLevel2 === null ? null : (value.targetSegmentsLevel2 as Array<any>).map(PivotTargetSegmentToJSON)),
        'TargetSegmentsLevel3': value.targetSegmentsLevel3 === undefined ? undefined : (value.targetSegmentsLevel3 === null ? null : (value.targetSegmentsLevel3 as Array<any>).map(PivotTargetSegmentToJSON)),
        'DrillingTaskSummary': DrillingTaskSummaryToJSON(value.drillingTaskSummary),
    };
}

