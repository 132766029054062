/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScoreSummaryTabGroupDto,
    ScoreSummaryTabGroupDtoFromJSON,
    ScoreSummaryTabGroupDtoFromJSONTyped,
    ScoreSummaryTabGroupDtoToJSON,
} from './ScoreSummaryTabGroupDto';

/**
 * 
 * @export
 * @interface ScoreSummaryRootDto
 */
export interface ScoreSummaryRootDto {
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryRootDto
     */
    targetDeltaTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryRootDto
     */
    invisibleLostTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryRootDto
     */
    rigId?: number | null;
    /**
     * 
     * @type {Array<ScoreSummaryTabGroupDto>}
     * @memberof ScoreSummaryRootDto
     */
    children: Array<ScoreSummaryTabGroupDto>;
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryRootDto
     */
    operatorScore?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryRootDto
     */
    rigScore?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryRootDto
     */
    readonly targetDeltaTimeMaxRange: number;
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryRootDto
     */
    readonly invisibleLostTimeMaxRange: number;
}

export function ScoreSummaryRootDtoFromJSON(json: any): ScoreSummaryRootDto {
    return ScoreSummaryRootDtoFromJSONTyped(json, false);
}

export function ScoreSummaryRootDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScoreSummaryRootDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'targetDeltaTime': !exists(json, 'TargetDeltaTime') ? undefined : json['TargetDeltaTime'],
        'invisibleLostTime': !exists(json, 'InvisibleLostTime') ? undefined : json['InvisibleLostTime'],
        'rigId': !exists(json, 'RigId') ? undefined : json['RigId'],
        'children': ((json['Children'] as Array<any>).map(ScoreSummaryTabGroupDtoFromJSON)),
        'operatorScore': !exists(json, 'OperatorScore') ? undefined : json['OperatorScore'],
        'rigScore': !exists(json, 'RigScore') ? undefined : json['RigScore'],
        'targetDeltaTimeMaxRange': json['TargetDeltaTimeMaxRange'],
        'invisibleLostTimeMaxRange': json['InvisibleLostTimeMaxRange'],
    };
}

export function ScoreSummaryRootDtoToJSON(value?: ScoreSummaryRootDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'TargetDeltaTime': value.targetDeltaTime,
        'InvisibleLostTime': value.invisibleLostTime,
        'RigId': value.rigId,
        'Children': ((value.children as Array<any>).map(ScoreSummaryTabGroupDtoToJSON)),
        'OperatorScore': value.operatorScore,
        'RigScore': value.rigScore,
    };
}

