/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    TorqueAndDragInspectionResultDto,
    TorqueAndDragInspectionResultDtoFromJSON,
    TorqueAndDragInspectionResultDtoToJSON,
    TorqueAndDragQueryDto,
    TorqueAndDragQueryDtoFromJSON,
    TorqueAndDragQueryDtoToJSON,
    TorqueAndDragResultDto,
    TorqueAndDragResultDtoFromJSON,
    TorqueAndDragResultDtoToJSON,
    TorqueAndDragUserLensDto,
    TorqueAndDragUserLensDtoFromJSON,
    TorqueAndDragUserLensDtoToJSON,
} from '../models';

export interface ApiTorqueAndDragUserLensesIdPutRequest {
    id: number;
    torqueAndDragUserLensDto?: TorqueAndDragUserLensDto;
}

export interface ApiTorqueAndDragUserLensesIdSingleFactsPutRequest {
    id: number;
    torqueAndDragQueryDto?: TorqueAndDragQueryDto;
}

export interface ApiTorqueAndDragUserLensesPostRequest {
    torqueAndDragUserLensDto?: TorqueAndDragUserLensDto;
}

export interface ApiTorqueAndDragUserLensesUserLensIdInspectionDetailsPointIdGetRequest {
    userLensId: number;
    pointId: number;
}

/**
 * 
 */
export class TorqueAndDragUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiTorqueAndDragUserLensesIdPutRaw(requestParameters: ApiTorqueAndDragUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TorqueAndDragUserLensDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiTorqueAndDragUserLensesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/TorqueAndDragUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TorqueAndDragUserLensDtoToJSON(requestParameters.torqueAndDragUserLensDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TorqueAndDragUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTorqueAndDragUserLensesIdPut(requestParameters: ApiTorqueAndDragUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TorqueAndDragUserLensDto> {
        const response = await this.apiTorqueAndDragUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTorqueAndDragUserLensesIdSingleFactsPutRaw(requestParameters: ApiTorqueAndDragUserLensesIdSingleFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TorqueAndDragResultDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiTorqueAndDragUserLensesIdSingleFactsPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/TorqueAndDragUserLenses/{id}/SingleFacts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TorqueAndDragQueryDtoToJSON(requestParameters.torqueAndDragQueryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TorqueAndDragResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTorqueAndDragUserLensesIdSingleFactsPut(requestParameters: ApiTorqueAndDragUserLensesIdSingleFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TorqueAndDragResultDto> {
        const response = await this.apiTorqueAndDragUserLensesIdSingleFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTorqueAndDragUserLensesPostRaw(requestParameters: ApiTorqueAndDragUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TorqueAndDragUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/TorqueAndDragUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TorqueAndDragUserLensDtoToJSON(requestParameters.torqueAndDragUserLensDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TorqueAndDragUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTorqueAndDragUserLensesPost(requestParameters: ApiTorqueAndDragUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TorqueAndDragUserLensDto> {
        const response = await this.apiTorqueAndDragUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTorqueAndDragUserLensesUserLensIdInspectionDetailsPointIdGetRaw(requestParameters: ApiTorqueAndDragUserLensesUserLensIdInspectionDetailsPointIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TorqueAndDragInspectionResultDto>> {
        if (requestParameters.userLensId === null || requestParameters.userLensId === undefined) {
            throw new runtime.RequiredError('userLensId','Required parameter requestParameters.userLensId was null or undefined when calling apiTorqueAndDragUserLensesUserLensIdInspectionDetailsPointIdGet.');
        }

        if (requestParameters.pointId === null || requestParameters.pointId === undefined) {
            throw new runtime.RequiredError('pointId','Required parameter requestParameters.pointId was null or undefined when calling apiTorqueAndDragUserLensesUserLensIdInspectionDetailsPointIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/TorqueAndDragUserLenses/{userLensId}/InspectionDetails/{pointId}`.replace(`{${"userLensId"}}`, encodeURIComponent(String(requestParameters.userLensId))).replace(`{${"pointId"}}`, encodeURIComponent(String(requestParameters.pointId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TorqueAndDragInspectionResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTorqueAndDragUserLensesUserLensIdInspectionDetailsPointIdGet(requestParameters: ApiTorqueAndDragUserLensesUserLensIdInspectionDetailsPointIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TorqueAndDragInspectionResultDto> {
        const response = await this.apiTorqueAndDragUserLensesUserLensIdInspectionDetailsPointIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
