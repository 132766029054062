/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface TorqueAndDragPointDto
 */
export interface TorqueAndDragPointDto {
    /**
     * 
     * @type {number}
     * @memberof TorqueAndDragPointDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof TorqueAndDragPointDto
     */
    depth: number;
    /**
     * 
     * @type {number}
     * @memberof TorqueAndDragPointDto
     */
    measure: number;
    /**
     * 
     * @type {DateDto}
     * @memberof TorqueAndDragPointDto
     */
    startDate: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof TorqueAndDragPointDto
     */
    endDate: DateDto;
    /**
     * 
     * @type {number}
     * @memberof TorqueAndDragPointDto
     */
    startBitDepth: number;
    /**
     * 
     * @type {number}
     * @memberof TorqueAndDragPointDto
     */
    endBitDepth: number;
    /**
     * 
     * @type {number}
     * @memberof TorqueAndDragPointDto
     */
    readonly duration: number;
    /**
     * 
     * @type {number}
     * @memberof TorqueAndDragPointDto
     */
    readonly distance: number;
}

export function TorqueAndDragPointDtoFromJSON(json: any): TorqueAndDragPointDto {
    return TorqueAndDragPointDtoFromJSONTyped(json, false);
}

export function TorqueAndDragPointDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TorqueAndDragPointDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'depth': json['Depth'],
        'measure': json['Measure'],
        'startDate': DateDtoFromJSON(json['StartDate']),
        'endDate': DateDtoFromJSON(json['EndDate']),
        'startBitDepth': json['StartBitDepth'],
        'endBitDepth': json['EndBitDepth'],
        'duration': json['Duration'],
        'distance': json['Distance'],
    };
}

export function TorqueAndDragPointDtoToJSON(value?: TorqueAndDragPointDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'Depth': value.depth,
        'Measure': value.measure,
        'StartDate': DateDtoToJSON(value.startDate),
        'EndDate': DateDtoToJSON(value.endDate),
        'StartBitDepth': value.startBitDepth,
        'EndBitDepth': value.endBitDepth,
    };
}

