/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClaraResponseVerdictType,
    ClaraResponseVerdictTypeFromJSON,
    ClaraResponseVerdictTypeFromJSONTyped,
    ClaraResponseVerdictTypeToJSON,
} from './ClaraResponseVerdictType';

/**
 * 
 * @export
 * @interface ClaraFeedbackDto
 */
export interface ClaraFeedbackDto {
    /**
     * 
     * @type {number}
     * @memberof ClaraFeedbackDto
     */
    messageId: number;
    /**
     * 
     * @type {ClaraResponseVerdictType}
     * @memberof ClaraFeedbackDto
     */
    verdict: ClaraResponseVerdictType;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClaraFeedbackDto
     */
    verdictReasons?: Array<string> | null;
}

export function ClaraFeedbackDtoFromJSON(json: any): ClaraFeedbackDto {
    return ClaraFeedbackDtoFromJSONTyped(json, false);
}

export function ClaraFeedbackDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClaraFeedbackDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'messageId': json['MessageId'],
        'verdict': ClaraResponseVerdictTypeFromJSON(json['Verdict']),
        'verdictReasons': !exists(json, 'VerdictReasons') ? undefined : json['VerdictReasons'],
    };
}

export function ClaraFeedbackDtoToJSON(value?: ClaraFeedbackDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'MessageId': value.messageId,
        'Verdict': ClaraResponseVerdictTypeToJSON(value.verdict),
        'VerdictReasons': value.verdictReasons,
    };
}

