/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const StatCardDataType = {
    Unknown: 'Unknown',
    FuelConsumption: 'FuelConsumption',
    FuelFlow: 'FuelFlow',
    TotalActiveDuration: 'TotalActiveDuration',
    AveragePowerConsumptionPerSecond: 'AveragePowerConsumptionPerSecond',
    TotalEnergyConsumed: 'TotalEnergyConsumed',
    TotalEnergyCapacity: 'TotalEnergyCapacity',
    GhgEmissions: 'GhgEmissions'
} as const;
export type StatCardDataType = typeof StatCardDataType[keyof typeof StatCardDataType];


export function StatCardDataTypeFromJSON(json: any): StatCardDataType {
    return StatCardDataTypeFromJSONTyped(json, false);
}

export function StatCardDataTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatCardDataType {
    return json as StatCardDataType;
}

export function StatCardDataTypeToJSON(value?: StatCardDataType | null): any {
    return value as any;
}

