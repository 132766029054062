/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NotificationSubscriptionDto,
    NotificationSubscriptionDtoFromJSON,
    NotificationSubscriptionDtoFromJSONTyped,
    NotificationSubscriptionDtoToJSON,
} from './NotificationSubscriptionDto';

/**
 * 
 * @export
 * @interface NotificationSubscriptionRequestDto
 */
export interface NotificationSubscriptionRequestDto {
    /**
     * 
     * @type {Array<NotificationSubscriptionDto>}
     * @memberof NotificationSubscriptionRequestDto
     */
    subscriptions: Array<NotificationSubscriptionDto>;
}

export function NotificationSubscriptionRequestDtoFromJSON(json: any): NotificationSubscriptionRequestDto {
    return NotificationSubscriptionRequestDtoFromJSONTyped(json, false);
}

export function NotificationSubscriptionRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationSubscriptionRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subscriptions': ((json['Subscriptions'] as Array<any>).map(NotificationSubscriptionDtoFromJSON)),
    };
}

export function NotificationSubscriptionRequestDtoToJSON(value?: NotificationSubscriptionRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Subscriptions': ((value.subscriptions as Array<any>).map(NotificationSubscriptionDtoToJSON)),
    };
}

