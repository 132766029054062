/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LensNavigationFilters,
    LensNavigationFiltersFromJSON,
    LensNavigationFiltersToJSON,
    RigCardFactSetDto,
    RigCardFactSetDtoFromJSON,
    RigCardFactSetDtoToJSON,
    RigScorecardFactQueryDto,
    RigScorecardFactQueryDtoFromJSON,
    RigScorecardFactQueryDtoToJSON,
} from '../models';

export interface ApiRigScorecardUserLensesAllFactsPutRequest {
    rigScorecardFactQueryDto?: RigScorecardFactQueryDto;
}

/**
 * 
 */
export class RigScorecardUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiRigScorecardUserLensesAllFactsPutRaw(requestParameters: ApiRigScorecardUserLensesAllFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RigCardFactSetDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/RigScorecardUserLenses/All/Facts`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RigScorecardFactQueryDtoToJSON(requestParameters.rigScorecardFactQueryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RigCardFactSetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiRigScorecardUserLensesAllFactsPut(requestParameters: ApiRigScorecardUserLensesAllFactsPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RigCardFactSetDto> {
        const response = await this.apiRigScorecardUserLensesAllFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRigScorecardUserLensesNavigationFiltersGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<LensNavigationFilters>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/RigScorecardUserLenses/NavigationFilters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LensNavigationFiltersFromJSON));
    }

    /**
     */
    async apiRigScorecardUserLensesNavigationFiltersGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<LensNavigationFilters>> {
        const response = await this.apiRigScorecardUserLensesNavigationFiltersGetRaw(initOverrides);
        return await response.value();
    }

}
