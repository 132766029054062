/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ReportStatusType = {
    Pending: 'Pending',
    Ongoing: 'Ongoing',
    Done: 'Done',
    Error: 'Error'
} as const;
export type ReportStatusType = typeof ReportStatusType[keyof typeof ReportStatusType];


export function ReportStatusTypeFromJSON(json: any): ReportStatusType {
    return ReportStatusTypeFromJSONTyped(json, false);
}

export function ReportStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportStatusType {
    return json as ReportStatusType;
}

export function ReportStatusTypeToJSON(value?: ReportStatusType | null): any {
    return value as any;
}

