/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    PlanTvdPointDto,
    PlanTvdPointDtoFromJSON,
    PlanTvdPointDtoFromJSONTyped,
    PlanTvdPointDtoToJSON,
} from './PlanTvdPointDto';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    TvdSectionDto,
    TvdSectionDtoFromJSON,
    TvdSectionDtoFromJSONTyped,
    TvdSectionDtoToJSON,
} from './TvdSectionDto';

/**
 * 
 * @export
 * @interface PlanTvdSeriesDto
 */
export interface PlanTvdSeriesDto {
    /**
     * 
     * @type {number}
     * @memberof PlanTvdSeriesDto
     */
    wellId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof PlanTvdSeriesDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {ResultDataState}
     * @memberof PlanTvdSeriesDto
     */
    dataState: ResultDataState;
    /**
     * 
     * @type {Array<PlanTvdPointDto>}
     * @memberof PlanTvdSeriesDto
     */
    series?: Array<PlanTvdPointDto> | null;
    /**
     * 
     * @type {Array<TvdSectionDto>}
     * @memberof PlanTvdSeriesDto
     */
    sectionIntervals?: Array<TvdSectionDto> | null;
    /**
     * 
     * @type {number}
     * @memberof PlanTvdSeriesDto
     */
    planId: number;
}

export function PlanTvdSeriesDtoFromJSON(json: any): PlanTvdSeriesDto {
    return PlanTvdSeriesDtoFromJSONTyped(json, false);
}

export function PlanTvdSeriesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanTvdSeriesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'wellId': json['WellId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
        'series': !exists(json, 'Series') ? undefined : (json['Series'] === null ? null : (json['Series'] as Array<any>).map(PlanTvdPointDtoFromJSON)),
        'sectionIntervals': !exists(json, 'SectionIntervals') ? undefined : (json['SectionIntervals'] === null ? null : (json['SectionIntervals'] as Array<any>).map(TvdSectionDtoFromJSON)),
        'planId': json['PlanId'],
    };
}

export function PlanTvdSeriesDtoToJSON(value?: PlanTvdSeriesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'WellId': value.wellId,
        'LastUpdatedAt': DateDtoToJSON(value.lastUpdatedAt),
        'DataState': ResultDataStateToJSON(value.dataState),
        'Series': value.series === undefined ? undefined : (value.series === null ? null : (value.series as Array<any>).map(PlanTvdPointDtoToJSON)),
        'SectionIntervals': value.sectionIntervals === undefined ? undefined : (value.sectionIntervals === null ? null : (value.sectionIntervals as Array<any>).map(TvdSectionDtoToJSON)),
        'PlanId': value.planId,
    };
}

