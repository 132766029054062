/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StickSlipByDepthUserLensTrackItemDto,
    StickSlipByDepthUserLensTrackItemDtoFromJSON,
    StickSlipByDepthUserLensTrackItemDtoFromJSONTyped,
    StickSlipByDepthUserLensTrackItemDtoToJSON,
} from './StickSlipByDepthUserLensTrackItemDto';

/**
 * 
 * @export
 * @interface StickSlipByDepthUserLensDto
 */
export interface StickSlipByDepthUserLensDto {
    /**
     * 
     * @type {number}
     * @memberof StickSlipByDepthUserLensDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof StickSlipByDepthUserLensDto
     */
    lensTabId: number;
    /**
     * 
     * @type {number}
     * @memberof StickSlipByDepthUserLensDto
     */
    lensTemplateId: number;
    /**
     * 
     * @type {boolean}
     * @memberof StickSlipByDepthUserLensDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StickSlipByDepthUserLensDto
     */
    label: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StickSlipByDepthUserLensDto
     */
    isSystem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StickSlipByDepthUserLensDto
     */
    squeezesDisplay: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StickSlipByDepthUserLensDto
     */
    showZTorque: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StickSlipByDepthUserLensDto
     */
    showPercentageDifference: boolean;
    /**
     * 
     * @type {Array<StickSlipByDepthUserLensTrackItemDto>}
     * @memberof StickSlipByDepthUserLensDto
     */
    userLensTrackItems: Array<StickSlipByDepthUserLensTrackItemDto>;
}

export function StickSlipByDepthUserLensDtoFromJSON(json: any): StickSlipByDepthUserLensDto {
    return StickSlipByDepthUserLensDtoFromJSONTyped(json, false);
}

export function StickSlipByDepthUserLensDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StickSlipByDepthUserLensDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'lensTabId': json['LensTabId'],
        'lensTemplateId': json['LensTemplateId'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'label': json['Label'],
        'isSystem': json['IsSystem'],
        'squeezesDisplay': json['SqueezesDisplay'],
        'showZTorque': json['ShowZTorque'],
        'showPercentageDifference': json['ShowPercentageDifference'],
        'userLensTrackItems': ((json['UserLensTrackItems'] as Array<any>).map(StickSlipByDepthUserLensTrackItemDtoFromJSON)),
    };
}

export function StickSlipByDepthUserLensDtoToJSON(value?: StickSlipByDepthUserLensDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'LensTabId': value.lensTabId,
        'LensTemplateId': value.lensTemplateId,
        'IsAvailableOnReports': value.isAvailableOnReports,
        'Label': value.label,
        'IsSystem': value.isSystem,
        'SqueezesDisplay': value.squeezesDisplay,
        'ShowZTorque': value.showZTorque,
        'ShowPercentageDifference': value.showPercentageDifference,
        'UserLensTrackItems': ((value.userLensTrackItems as Array<any>).map(StickSlipByDepthUserLensTrackItemDtoToJSON)),
    };
}

