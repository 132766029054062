/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BatteryStateUserLensDto,
    BatteryStateUserLensDtoFromJSON,
    BatteryStateUserLensDtoFromJSONTyped,
    BatteryStateUserLensDtoToJSON,
} from './BatteryStateUserLensDto';
import {
    DieselNatGasRatioStatsUserLensDto,
    DieselNatGasRatioStatsUserLensDtoFromJSON,
    DieselNatGasRatioStatsUserLensDtoFromJSONTyped,
    DieselNatGasRatioStatsUserLensDtoToJSON,
} from './DieselNatGasRatioStatsUserLensDto';
import {
    DrillingProductivityUserLensDto,
    DrillingProductivityUserLensDtoFromJSON,
    DrillingProductivityUserLensDtoFromJSONTyped,
    DrillingProductivityUserLensDtoToJSON,
} from './DrillingProductivityUserLensDto';
import {
    FuelConsumptionByGeneratorUserLensDto,
    FuelConsumptionByGeneratorUserLensDtoFromJSON,
    FuelConsumptionByGeneratorUserLensDtoFromJSONTyped,
    FuelConsumptionByGeneratorUserLensDtoToJSON,
} from './FuelConsumptionByGeneratorUserLensDto';
import {
    FuelFlowRateUserLensDto,
    FuelFlowRateUserLensDtoFromJSON,
    FuelFlowRateUserLensDtoFromJSONTyped,
    FuelFlowRateUserLensDtoToJSON,
} from './FuelFlowRateUserLensDto';
import {
    FuelTankVolumeUserLensDto,
    FuelTankVolumeUserLensDtoFromJSON,
    FuelTankVolumeUserLensDtoFromJSONTyped,
    FuelTankVolumeUserLensDtoToJSON,
} from './FuelTankVolumeUserLensDto';
import {
    GeneratorLoadProfileUserLensDto,
    GeneratorLoadProfileUserLensDtoFromJSON,
    GeneratorLoadProfileUserLensDtoFromJSONTyped,
    GeneratorLoadProfileUserLensDtoToJSON,
} from './GeneratorLoadProfileUserLensDto';
import {
    GeneratorStatsUserLensDto,
    GeneratorStatsUserLensDtoFromJSON,
    GeneratorStatsUserLensDtoFromJSONTyped,
    GeneratorStatsUserLensDtoToJSON,
} from './GeneratorStatsUserLensDto';
import {
    GeneratorStatusBarUserLensDto,
    GeneratorStatusBarUserLensDtoFromJSON,
    GeneratorStatusBarUserLensDtoFromJSONTyped,
    GeneratorStatusBarUserLensDtoToJSON,
} from './GeneratorStatusBarUserLensDto';
import {
    GhgEmissionsUserLensDto,
    GhgEmissionsUserLensDtoFromJSON,
    GhgEmissionsUserLensDtoFromJSONTyped,
    GhgEmissionsUserLensDtoToJSON,
} from './GhgEmissionsUserLensDto';
import {
    KpiGroupUserLensDto,
    KpiGroupUserLensDtoFromJSON,
    KpiGroupUserLensDtoFromJSONTyped,
    KpiGroupUserLensDtoToJSON,
} from './KpiGroupUserLensDto';
import {
    KpiTypeUserLensDto,
    KpiTypeUserLensDtoFromJSON,
    KpiTypeUserLensDtoFromJSONTyped,
    KpiTypeUserLensDtoToJSON,
} from './KpiTypeUserLensDto';
import {
    NaturalGasStateUserLensDto,
    NaturalGasStateUserLensDtoFromJSON,
    NaturalGasStateUserLensDtoFromJSONTyped,
    NaturalGasStateUserLensDtoToJSON,
} from './NaturalGasStateUserLensDto';
import {
    ParameterByDepthUserLensDto,
    ParameterByDepthUserLensDtoFromJSON,
    ParameterByDepthUserLensDtoFromJSONTyped,
    ParameterByDepthUserLensDtoToJSON,
} from './ParameterByDepthUserLensDto';
import {
    ParameterHeatmapUserLensDto,
    ParameterHeatmapUserLensDtoFromJSON,
    ParameterHeatmapUserLensDtoFromJSONTyped,
    ParameterHeatmapUserLensDtoToJSON,
} from './ParameterHeatmapUserLensDto';
import {
    PivotKpiGroupUserLensDto,
    PivotKpiGroupUserLensDtoFromJSON,
    PivotKpiGroupUserLensDtoFromJSONTyped,
    PivotKpiGroupUserLensDtoToJSON,
} from './PivotKpiGroupUserLensDto';
import {
    PivotKpiTypeUserLensDto,
    PivotKpiTypeUserLensDtoFromJSON,
    PivotKpiTypeUserLensDtoFromJSONTyped,
    PivotKpiTypeUserLensDtoToJSON,
} from './PivotKpiTypeUserLensDto';
import {
    PowerConsumptionByOperationUserLensDto,
    PowerConsumptionByOperationUserLensDtoFromJSON,
    PowerConsumptionByOperationUserLensDtoFromJSONTyped,
    PowerConsumptionByOperationUserLensDtoToJSON,
} from './PowerConsumptionByOperationUserLensDto';
import {
    PowerLoadDistributionUserLensDto,
    PowerLoadDistributionUserLensDtoFromJSON,
    PowerLoadDistributionUserLensDtoFromJSONTyped,
    PowerLoadDistributionUserLensDtoToJSON,
} from './PowerLoadDistributionUserLensDto';
import {
    PowerLoadEfficiencyUserLensDto,
    PowerLoadEfficiencyUserLensDtoFromJSON,
    PowerLoadEfficiencyUserLensDtoFromJSONTyped,
    PowerLoadEfficiencyUserLensDtoToJSON,
} from './PowerLoadEfficiencyUserLensDto';
import {
    RigFleetPerformanceCardUserLensDto,
    RigFleetPerformanceCardUserLensDtoFromJSON,
    RigFleetPerformanceCardUserLensDtoFromJSONTyped,
    RigFleetPerformanceCardUserLensDtoToJSON,
} from './RigFleetPerformanceCardUserLensDto';
import {
    RigPowerUserLensDto,
    RigPowerUserLensDtoFromJSON,
    RigPowerUserLensDtoFromJSONTyped,
    RigPowerUserLensDtoToJSON,
} from './RigPowerUserLensDto';
import {
    RigScorecardUserLensDto,
    RigScorecardUserLensDtoFromJSON,
    RigScorecardUserLensDtoFromJSONTyped,
    RigScorecardUserLensDtoToJSON,
} from './RigScorecardUserLensDto';
import {
    StatCardUserLensDto,
    StatCardUserLensDtoFromJSON,
    StatCardUserLensDtoFromJSONTyped,
    StatCardUserLensDtoToJSON,
} from './StatCardUserLensDto';
import {
    StickSlipByDepthUserLensDto,
    StickSlipByDepthUserLensDtoFromJSON,
    StickSlipByDepthUserLensDtoFromJSONTyped,
    StickSlipByDepthUserLensDtoToJSON,
} from './StickSlipByDepthUserLensDto';
import {
    StickSlipByTimeUserLensDto,
    StickSlipByTimeUserLensDtoFromJSON,
    StickSlipByTimeUserLensDtoFromJSONTyped,
    StickSlipByTimeUserLensDtoToJSON,
} from './StickSlipByTimeUserLensDto';
import {
    TagBottomFingerprintUserLensDto,
    TagBottomFingerprintUserLensDtoFromJSON,
    TagBottomFingerprintUserLensDtoFromJSONTyped,
    TagBottomFingerprintUserLensDtoToJSON,
} from './TagBottomFingerprintUserLensDto';
import {
    TorqueAndDragUserLensDto,
    TorqueAndDragUserLensDtoFromJSON,
    TorqueAndDragUserLensDtoFromJSONTyped,
    TorqueAndDragUserLensDtoToJSON,
} from './TorqueAndDragUserLensDto';
import {
    WedgeUserLensDto,
    WedgeUserLensDtoFromJSON,
    WedgeUserLensDtoFromJSONTyped,
    WedgeUserLensDtoToJSON,
} from './WedgeUserLensDto';
import {
    WellDrillingSummaryUserLensDto,
    WellDrillingSummaryUserLensDtoFromJSON,
    WellDrillingSummaryUserLensDtoFromJSONTyped,
    WellDrillingSummaryUserLensDtoToJSON,
} from './WellDrillingSummaryUserLensDto';

/**
 * 
 * @export
 * @interface UserLensSetDto
 */
export interface UserLensSetDto {
    /**
     * 
     * @type {Array<KpiTypeUserLensDto>}
     * @memberof UserLensSetDto
     */
    kpiTypeUserLenses: Array<KpiTypeUserLensDto>;
    /**
     * 
     * @type {Array<KpiGroupUserLensDto>}
     * @memberof UserLensSetDto
     */
    kpiGroupUserLenses: Array<KpiGroupUserLensDto>;
    /**
     * 
     * @type {Array<DrillingProductivityUserLensDto>}
     * @memberof UserLensSetDto
     */
    drillingProductivityUserLenses: Array<DrillingProductivityUserLensDto>;
    /**
     * 
     * @type {Array<WedgeUserLensDto>}
     * @memberof UserLensSetDto
     */
    wedgeUserLenses: Array<WedgeUserLensDto>;
    /**
     * 
     * @type {Array<ParameterByDepthUserLensDto>}
     * @memberof UserLensSetDto
     */
    parameterByDepthUserLenses: Array<ParameterByDepthUserLensDto>;
    /**
     * 
     * @type {Array<ParameterHeatmapUserLensDto>}
     * @memberof UserLensSetDto
     */
    parameterHeatmapUserLenses: Array<ParameterHeatmapUserLensDto>;
    /**
     * 
     * @type {Array<WellDrillingSummaryUserLensDto>}
     * @memberof UserLensSetDto
     */
    wellDrillingSummaryUserLenses: Array<WellDrillingSummaryUserLensDto>;
    /**
     * 
     * @type {Array<TorqueAndDragUserLensDto>}
     * @memberof UserLensSetDto
     */
    torqueAndDragUserLenses: Array<TorqueAndDragUserLensDto>;
    /**
     * 
     * @type {Array<PivotKpiTypeUserLensDto>}
     * @memberof UserLensSetDto
     */
    pivotKpiTypeUserLenses: Array<PivotKpiTypeUserLensDto>;
    /**
     * 
     * @type {Array<PivotKpiGroupUserLensDto>}
     * @memberof UserLensSetDto
     */
    pivotKpiGroupUserLenses: Array<PivotKpiGroupUserLensDto>;
    /**
     * 
     * @type {Array<BatteryStateUserLensDto>}
     * @memberof UserLensSetDto
     */
    batteryStateUserLenses: Array<BatteryStateUserLensDto>;
    /**
     * 
     * @type {Array<RigPowerUserLensDto>}
     * @memberof UserLensSetDto
     */
    rigPowerUserLenses: Array<RigPowerUserLensDto>;
    /**
     * 
     * @type {Array<PowerConsumptionByOperationUserLensDto>}
     * @memberof UserLensSetDto
     */
    powerConsumptionByOperationUserLenses: Array<PowerConsumptionByOperationUserLensDto>;
    /**
     * 
     * @type {Array<StatCardUserLensDto>}
     * @memberof UserLensSetDto
     */
    statCardUserLenses: Array<StatCardUserLensDto>;
    /**
     * 
     * @type {Array<NaturalGasStateUserLensDto>}
     * @memberof UserLensSetDto
     */
    naturalGasStateUserLenses: Array<NaturalGasStateUserLensDto>;
    /**
     * 
     * @type {Array<GeneratorStatusBarUserLensDto>}
     * @memberof UserLensSetDto
     */
    generatorStatusBarUserLenses: Array<GeneratorStatusBarUserLensDto>;
    /**
     * 
     * @type {Array<FuelFlowRateUserLensDto>}
     * @memberof UserLensSetDto
     */
    fuelFlowRateUserLenses: Array<FuelFlowRateUserLensDto>;
    /**
     * 
     * @type {Array<GeneratorLoadProfileUserLensDto>}
     * @memberof UserLensSetDto
     */
    generatorLoadProfileUserLenses: Array<GeneratorLoadProfileUserLensDto>;
    /**
     * 
     * @type {Array<PowerLoadDistributionUserLensDto>}
     * @memberof UserLensSetDto
     */
    powerLoadDistributionUserLenses: Array<PowerLoadDistributionUserLensDto>;
    /**
     * 
     * @type {Array<GeneratorStatsUserLensDto>}
     * @memberof UserLensSetDto
     */
    generatorStatsUserLenses: Array<GeneratorStatsUserLensDto>;
    /**
     * 
     * @type {Array<FuelTankVolumeUserLensDto>}
     * @memberof UserLensSetDto
     */
    fuelTankVolumeUserLenses: Array<FuelTankVolumeUserLensDto>;
    /**
     * 
     * @type {Array<GhgEmissionsUserLensDto>}
     * @memberof UserLensSetDto
     */
    ghgEmissionsUserLenses: Array<GhgEmissionsUserLensDto>;
    /**
     * 
     * @type {Array<PowerLoadEfficiencyUserLensDto>}
     * @memberof UserLensSetDto
     */
    powerLoadEfficiencyUserLenses: Array<PowerLoadEfficiencyUserLensDto>;
    /**
     * 
     * @type {Array<FuelConsumptionByGeneratorUserLensDto>}
     * @memberof UserLensSetDto
     */
    fuelConsumptionByGeneratorUserLenses: Array<FuelConsumptionByGeneratorUserLensDto>;
    /**
     * 
     * @type {Array<DieselNatGasRatioStatsUserLensDto>}
     * @memberof UserLensSetDto
     */
    dieselNatGasRatioStatsUserLenses: Array<DieselNatGasRatioStatsUserLensDto>;
    /**
     * 
     * @type {Array<RigFleetPerformanceCardUserLensDto>}
     * @memberof UserLensSetDto
     */
    rigFleetPerformanceCardUserLenses: Array<RigFleetPerformanceCardUserLensDto>;
    /**
     * 
     * @type {Array<RigScorecardUserLensDto>}
     * @memberof UserLensSetDto
     */
    rigScorecardUserLenses: Array<RigScorecardUserLensDto>;
    /**
     * 
     * @type {Array<TagBottomFingerprintUserLensDto>}
     * @memberof UserLensSetDto
     */
    tagBottomFingerprintUserLenses: Array<TagBottomFingerprintUserLensDto>;
    /**
     * 
     * @type {Array<StickSlipByTimeUserLensDto>}
     * @memberof UserLensSetDto
     */
    stickSlipByTimeUserLenses: Array<StickSlipByTimeUserLensDto>;
    /**
     * 
     * @type {Array<StickSlipByDepthUserLensDto>}
     * @memberof UserLensSetDto
     */
    stickSlipByDepthUserLenses: Array<StickSlipByDepthUserLensDto>;
}

export function UserLensSetDtoFromJSON(json: any): UserLensSetDto {
    return UserLensSetDtoFromJSONTyped(json, false);
}

export function UserLensSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserLensSetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kpiTypeUserLenses': ((json['KpiTypeUserLenses'] as Array<any>).map(KpiTypeUserLensDtoFromJSON)),
        'kpiGroupUserLenses': ((json['KpiGroupUserLenses'] as Array<any>).map(KpiGroupUserLensDtoFromJSON)),
        'drillingProductivityUserLenses': ((json['DrillingProductivityUserLenses'] as Array<any>).map(DrillingProductivityUserLensDtoFromJSON)),
        'wedgeUserLenses': ((json['WedgeUserLenses'] as Array<any>).map(WedgeUserLensDtoFromJSON)),
        'parameterByDepthUserLenses': ((json['ParameterByDepthUserLenses'] as Array<any>).map(ParameterByDepthUserLensDtoFromJSON)),
        'parameterHeatmapUserLenses': ((json['ParameterHeatmapUserLenses'] as Array<any>).map(ParameterHeatmapUserLensDtoFromJSON)),
        'wellDrillingSummaryUserLenses': ((json['WellDrillingSummaryUserLenses'] as Array<any>).map(WellDrillingSummaryUserLensDtoFromJSON)),
        'torqueAndDragUserLenses': ((json['TorqueAndDragUserLenses'] as Array<any>).map(TorqueAndDragUserLensDtoFromJSON)),
        'pivotKpiTypeUserLenses': ((json['PivotKpiTypeUserLenses'] as Array<any>).map(PivotKpiTypeUserLensDtoFromJSON)),
        'pivotKpiGroupUserLenses': ((json['PivotKpiGroupUserLenses'] as Array<any>).map(PivotKpiGroupUserLensDtoFromJSON)),
        'batteryStateUserLenses': ((json['BatteryStateUserLenses'] as Array<any>).map(BatteryStateUserLensDtoFromJSON)),
        'rigPowerUserLenses': ((json['RigPowerUserLenses'] as Array<any>).map(RigPowerUserLensDtoFromJSON)),
        'powerConsumptionByOperationUserLenses': ((json['PowerConsumptionByOperationUserLenses'] as Array<any>).map(PowerConsumptionByOperationUserLensDtoFromJSON)),
        'statCardUserLenses': ((json['StatCardUserLenses'] as Array<any>).map(StatCardUserLensDtoFromJSON)),
        'naturalGasStateUserLenses': ((json['NaturalGasStateUserLenses'] as Array<any>).map(NaturalGasStateUserLensDtoFromJSON)),
        'generatorStatusBarUserLenses': ((json['GeneratorStatusBarUserLenses'] as Array<any>).map(GeneratorStatusBarUserLensDtoFromJSON)),
        'fuelFlowRateUserLenses': ((json['FuelFlowRateUserLenses'] as Array<any>).map(FuelFlowRateUserLensDtoFromJSON)),
        'generatorLoadProfileUserLenses': ((json['GeneratorLoadProfileUserLenses'] as Array<any>).map(GeneratorLoadProfileUserLensDtoFromJSON)),
        'powerLoadDistributionUserLenses': ((json['PowerLoadDistributionUserLenses'] as Array<any>).map(PowerLoadDistributionUserLensDtoFromJSON)),
        'generatorStatsUserLenses': ((json['GeneratorStatsUserLenses'] as Array<any>).map(GeneratorStatsUserLensDtoFromJSON)),
        'fuelTankVolumeUserLenses': ((json['FuelTankVolumeUserLenses'] as Array<any>).map(FuelTankVolumeUserLensDtoFromJSON)),
        'ghgEmissionsUserLenses': ((json['GhgEmissionsUserLenses'] as Array<any>).map(GhgEmissionsUserLensDtoFromJSON)),
        'powerLoadEfficiencyUserLenses': ((json['PowerLoadEfficiencyUserLenses'] as Array<any>).map(PowerLoadEfficiencyUserLensDtoFromJSON)),
        'fuelConsumptionByGeneratorUserLenses': ((json['FuelConsumptionByGeneratorUserLenses'] as Array<any>).map(FuelConsumptionByGeneratorUserLensDtoFromJSON)),
        'dieselNatGasRatioStatsUserLenses': ((json['DieselNatGasRatioStatsUserLenses'] as Array<any>).map(DieselNatGasRatioStatsUserLensDtoFromJSON)),
        'rigFleetPerformanceCardUserLenses': ((json['RigFleetPerformanceCardUserLenses'] as Array<any>).map(RigFleetPerformanceCardUserLensDtoFromJSON)),
        'rigScorecardUserLenses': ((json['RigScorecardUserLenses'] as Array<any>).map(RigScorecardUserLensDtoFromJSON)),
        'tagBottomFingerprintUserLenses': ((json['TagBottomFingerprintUserLenses'] as Array<any>).map(TagBottomFingerprintUserLensDtoFromJSON)),
        'stickSlipByTimeUserLenses': ((json['StickSlipByTimeUserLenses'] as Array<any>).map(StickSlipByTimeUserLensDtoFromJSON)),
        'stickSlipByDepthUserLenses': ((json['StickSlipByDepthUserLenses'] as Array<any>).map(StickSlipByDepthUserLensDtoFromJSON)),
    };
}

export function UserLensSetDtoToJSON(value?: UserLensSetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'KpiTypeUserLenses': ((value.kpiTypeUserLenses as Array<any>).map(KpiTypeUserLensDtoToJSON)),
        'KpiGroupUserLenses': ((value.kpiGroupUserLenses as Array<any>).map(KpiGroupUserLensDtoToJSON)),
        'DrillingProductivityUserLenses': ((value.drillingProductivityUserLenses as Array<any>).map(DrillingProductivityUserLensDtoToJSON)),
        'WedgeUserLenses': ((value.wedgeUserLenses as Array<any>).map(WedgeUserLensDtoToJSON)),
        'ParameterByDepthUserLenses': ((value.parameterByDepthUserLenses as Array<any>).map(ParameterByDepthUserLensDtoToJSON)),
        'ParameterHeatmapUserLenses': ((value.parameterHeatmapUserLenses as Array<any>).map(ParameterHeatmapUserLensDtoToJSON)),
        'WellDrillingSummaryUserLenses': ((value.wellDrillingSummaryUserLenses as Array<any>).map(WellDrillingSummaryUserLensDtoToJSON)),
        'TorqueAndDragUserLenses': ((value.torqueAndDragUserLenses as Array<any>).map(TorqueAndDragUserLensDtoToJSON)),
        'PivotKpiTypeUserLenses': ((value.pivotKpiTypeUserLenses as Array<any>).map(PivotKpiTypeUserLensDtoToJSON)),
        'PivotKpiGroupUserLenses': ((value.pivotKpiGroupUserLenses as Array<any>).map(PivotKpiGroupUserLensDtoToJSON)),
        'BatteryStateUserLenses': ((value.batteryStateUserLenses as Array<any>).map(BatteryStateUserLensDtoToJSON)),
        'RigPowerUserLenses': ((value.rigPowerUserLenses as Array<any>).map(RigPowerUserLensDtoToJSON)),
        'PowerConsumptionByOperationUserLenses': ((value.powerConsumptionByOperationUserLenses as Array<any>).map(PowerConsumptionByOperationUserLensDtoToJSON)),
        'StatCardUserLenses': ((value.statCardUserLenses as Array<any>).map(StatCardUserLensDtoToJSON)),
        'NaturalGasStateUserLenses': ((value.naturalGasStateUserLenses as Array<any>).map(NaturalGasStateUserLensDtoToJSON)),
        'GeneratorStatusBarUserLenses': ((value.generatorStatusBarUserLenses as Array<any>).map(GeneratorStatusBarUserLensDtoToJSON)),
        'FuelFlowRateUserLenses': ((value.fuelFlowRateUserLenses as Array<any>).map(FuelFlowRateUserLensDtoToJSON)),
        'GeneratorLoadProfileUserLenses': ((value.generatorLoadProfileUserLenses as Array<any>).map(GeneratorLoadProfileUserLensDtoToJSON)),
        'PowerLoadDistributionUserLenses': ((value.powerLoadDistributionUserLenses as Array<any>).map(PowerLoadDistributionUserLensDtoToJSON)),
        'GeneratorStatsUserLenses': ((value.generatorStatsUserLenses as Array<any>).map(GeneratorStatsUserLensDtoToJSON)),
        'FuelTankVolumeUserLenses': ((value.fuelTankVolumeUserLenses as Array<any>).map(FuelTankVolumeUserLensDtoToJSON)),
        'GhgEmissionsUserLenses': ((value.ghgEmissionsUserLenses as Array<any>).map(GhgEmissionsUserLensDtoToJSON)),
        'PowerLoadEfficiencyUserLenses': ((value.powerLoadEfficiencyUserLenses as Array<any>).map(PowerLoadEfficiencyUserLensDtoToJSON)),
        'FuelConsumptionByGeneratorUserLenses': ((value.fuelConsumptionByGeneratorUserLenses as Array<any>).map(FuelConsumptionByGeneratorUserLensDtoToJSON)),
        'DieselNatGasRatioStatsUserLenses': ((value.dieselNatGasRatioStatsUserLenses as Array<any>).map(DieselNatGasRatioStatsUserLensDtoToJSON)),
        'RigFleetPerformanceCardUserLenses': ((value.rigFleetPerformanceCardUserLenses as Array<any>).map(RigFleetPerformanceCardUserLensDtoToJSON)),
        'RigScorecardUserLenses': ((value.rigScorecardUserLenses as Array<any>).map(RigScorecardUserLensDtoToJSON)),
        'TagBottomFingerprintUserLenses': ((value.tagBottomFingerprintUserLenses as Array<any>).map(TagBottomFingerprintUserLensDtoToJSON)),
        'StickSlipByTimeUserLenses': ((value.stickSlipByTimeUserLenses as Array<any>).map(StickSlipByTimeUserLensDtoToJSON)),
        'StickSlipByDepthUserLenses': ((value.stickSlipByDepthUserLenses as Array<any>).map(StickSlipByDepthUserLensDtoToJSON)),
    };
}

