/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RigScorecardLensType,
    RigScorecardLensTypeFromJSON,
    RigScorecardLensTypeFromJSONTyped,
    RigScorecardLensTypeToJSON,
} from './RigScorecardLensType';
import {
    StandKpiType,
    StandKpiTypeFromJSON,
    StandKpiTypeFromJSONTyped,
    StandKpiTypeToJSON,
} from './StandKpiType';

/**
 * 
 * @export
 * @interface ScorecardKpiCommentDto
 */
export interface ScorecardKpiCommentDto {
    /**
     * 
     * @type {number}
     * @memberof ScorecardKpiCommentDto
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof ScorecardKpiCommentDto
     */
    deletedAtUtc?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ScorecardKpiCommentDto
     */
    wellId: number;
    /**
     * 
     * @type {string}
     * @memberof ScorecardKpiCommentDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScorecardKpiCommentDto
     */
    description?: string | null;
    /**
     * 
     * @type {StandKpiType}
     * @memberof ScorecardKpiCommentDto
     */
    kpiType: StandKpiType;
    /**
     * 
     * @type {RigScorecardLensType}
     * @memberof ScorecardKpiCommentDto
     */
    scorecardType: RigScorecardLensType;
    /**
     * 
     * @type {number}
     * @memberof ScorecardKpiCommentDto
     */
    exceptionTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScorecardKpiCommentDto
     */
    exceptionApproverId?: number | null;
}

export function ScorecardKpiCommentDtoFromJSON(json: any): ScorecardKpiCommentDto {
    return ScorecardKpiCommentDtoFromJSONTyped(json, false);
}

export function ScorecardKpiCommentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScorecardKpiCommentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'deletedAtUtc': !exists(json, 'DeletedAtUtc') ? undefined : (json['DeletedAtUtc'] === null ? null : new Date(json['DeletedAtUtc'])),
        'wellId': json['WellId'],
        'title': !exists(json, 'Title') ? undefined : json['Title'],
        'description': !exists(json, 'Description') ? undefined : json['Description'],
        'kpiType': StandKpiTypeFromJSON(json['KpiType']),
        'scorecardType': RigScorecardLensTypeFromJSON(json['ScorecardType']),
        'exceptionTime': !exists(json, 'ExceptionTime') ? undefined : json['ExceptionTime'],
        'exceptionApproverId': !exists(json, 'ExceptionApproverId') ? undefined : json['ExceptionApproverId'],
    };
}

export function ScorecardKpiCommentDtoToJSON(value?: ScorecardKpiCommentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'DeletedAtUtc': value.deletedAtUtc === undefined ? undefined : (value.deletedAtUtc === null ? null : value.deletedAtUtc.toISOString()),
        'WellId': value.wellId,
        'Title': value.title,
        'Description': value.description,
        'KpiType': StandKpiTypeToJSON(value.kpiType),
        'ScorecardType': RigScorecardLensTypeToJSON(value.scorecardType),
        'ExceptionTime': value.exceptionTime,
        'ExceptionApproverId': value.exceptionApproverId,
    };
}

