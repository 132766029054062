/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DashboardType,
    DashboardTypeFromJSON,
    DashboardTypeFromJSONTyped,
    DashboardTypeToJSON,
} from './DashboardType';

/**
 * 
 * @export
 * @interface UserLensTabShareDto
 */
export interface UserLensTabShareDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof UserLensTabShareDto
     */
    tabIds: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof UserLensTabShareDto
     */
    recipientProfileId: number;
    /**
     * 
     * @type {DashboardType}
     * @memberof UserLensTabShareDto
     */
    dashboardType: DashboardType;
}

export function UserLensTabShareDtoFromJSON(json: any): UserLensTabShareDto {
    return UserLensTabShareDtoFromJSONTyped(json, false);
}

export function UserLensTabShareDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserLensTabShareDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tabIds': json['TabIds'],
        'recipientProfileId': json['RecipientProfileId'],
        'dashboardType': DashboardTypeFromJSON(json['DashboardType']),
    };
}

export function UserLensTabShareDtoToJSON(value?: UserLensTabShareDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'TabIds': value.tabIds,
        'RecipientProfileId': value.recipientProfileId,
        'DashboardType': DashboardTypeToJSON(value.dashboardType),
    };
}

