import ButtonGroup from "antd/lib/button/button-group";
import { Title } from "atoms/Typography";
import styled from "styled-components";
import { Row } from "utils/componentLibrary";
import { zIndexLayer } from "utils/zIndex";

export const HeaderContainerRow = styled(Row)`
  padding: 0 24px;
  height: 64px;
  background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_chart_bg};
  border-bottom: ${({ theme }) => `1px solid ${theme.themeStyle.colors.primary_accent}`};
  z-index: ${zIndexLayer.high};
  overflow-x: auto;
`;

export const Buttons = styled(ButtonGroup)`
  a:first-child {
    .ant-btn {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
  a:last-child {
    .ant-btn {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`;

export const StyledTitle = styled(Title).attrs({
  level: 3,
})`
  white-space: nowrap;
`;
