/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScoreComplianceStateType,
    ScoreComplianceStateTypeFromJSON,
    ScoreComplianceStateTypeFromJSONTyped,
    ScoreComplianceStateTypeToJSON,
} from './ScoreComplianceStateType';
import {
    ScoreSummaryRootDto,
    ScoreSummaryRootDtoFromJSON,
    ScoreSummaryRootDtoFromJSONTyped,
    ScoreSummaryRootDtoToJSON,
} from './ScoreSummaryRootDto';
import {
    WellBarDto,
    WellBarDtoFromJSON,
    WellBarDtoFromJSONTyped,
    WellBarDtoToJSON,
} from './WellBarDto';

/**
 * 
 * @export
 * @interface RigLeaderboardDto
 */
export interface RigLeaderboardDto {
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    rigId: number;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    operatorScore?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    rigScore?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    operatorTrend?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    rigTrend?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    targetDeltaTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    invisibleLostTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    savedTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    totalDowntime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    totalExceptionCount: number;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    totalExceptionTime: number;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    approvedExceptionCount: number;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    approvedExceptionTime: number;
    /**
     * 
     * @type {ScoreComplianceStateType}
     * @memberof RigLeaderboardDto
     */
    operatorScoreComplianceState: ScoreComplianceStateType;
    /**
     * 
     * @type {ScoreComplianceStateType}
     * @memberof RigLeaderboardDto
     */
    rigScoreComplianceState: ScoreComplianceStateType;
    /**
     * 
     * @type {ScoreComplianceStateType}
     * @memberof RigLeaderboardDto
     */
    improvementScoreComplianceState: ScoreComplianceStateType;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    operatorScoreComplianceRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    rigScoreComplianceRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    improvementScoreComplianceRate?: number | null;
    /**
     * 
     * @type {ScoreSummaryRootDto}
     * @memberof RigLeaderboardDto
     */
    scoreSummary: ScoreSummaryRootDto;
    /**
     * 
     * @type {Array<WellBarDto>}
     * @memberof RigLeaderboardDto
     */
    wellBars: Array<WellBarDto>;
}

export function RigLeaderboardDtoFromJSON(json: any): RigLeaderboardDto {
    return RigLeaderboardDtoFromJSONTyped(json, false);
}

export function RigLeaderboardDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigLeaderboardDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rigId': json['RigId'],
        'operatorScore': !exists(json, 'OperatorScore') ? undefined : json['OperatorScore'],
        'rigScore': !exists(json, 'RigScore') ? undefined : json['RigScore'],
        'operatorTrend': !exists(json, 'OperatorTrend') ? undefined : json['OperatorTrend'],
        'rigTrend': !exists(json, 'RigTrend') ? undefined : json['RigTrend'],
        'targetDeltaTime': !exists(json, 'TargetDeltaTime') ? undefined : json['TargetDeltaTime'],
        'invisibleLostTime': !exists(json, 'InvisibleLostTime') ? undefined : json['InvisibleLostTime'],
        'savedTime': !exists(json, 'SavedTime') ? undefined : json['SavedTime'],
        'totalDowntime': !exists(json, 'TotalDowntime') ? undefined : json['TotalDowntime'],
        'totalExceptionCount': json['TotalExceptionCount'],
        'totalExceptionTime': json['TotalExceptionTime'],
        'approvedExceptionCount': json['ApprovedExceptionCount'],
        'approvedExceptionTime': json['ApprovedExceptionTime'],
        'operatorScoreComplianceState': ScoreComplianceStateTypeFromJSON(json['OperatorScoreComplianceState']),
        'rigScoreComplianceState': ScoreComplianceStateTypeFromJSON(json['RigScoreComplianceState']),
        'improvementScoreComplianceState': ScoreComplianceStateTypeFromJSON(json['ImprovementScoreComplianceState']),
        'operatorScoreComplianceRate': !exists(json, 'OperatorScoreComplianceRate') ? undefined : json['OperatorScoreComplianceRate'],
        'rigScoreComplianceRate': !exists(json, 'RigScoreComplianceRate') ? undefined : json['RigScoreComplianceRate'],
        'improvementScoreComplianceRate': !exists(json, 'ImprovementScoreComplianceRate') ? undefined : json['ImprovementScoreComplianceRate'],
        'scoreSummary': ScoreSummaryRootDtoFromJSON(json['ScoreSummary']),
        'wellBars': ((json['WellBars'] as Array<any>).map(WellBarDtoFromJSON)),
    };
}

export function RigLeaderboardDtoToJSON(value?: RigLeaderboardDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'RigId': value.rigId,
        'OperatorScore': value.operatorScore,
        'RigScore': value.rigScore,
        'OperatorTrend': value.operatorTrend,
        'RigTrend': value.rigTrend,
        'TargetDeltaTime': value.targetDeltaTime,
        'InvisibleLostTime': value.invisibleLostTime,
        'SavedTime': value.savedTime,
        'TotalDowntime': value.totalDowntime,
        'TotalExceptionCount': value.totalExceptionCount,
        'TotalExceptionTime': value.totalExceptionTime,
        'ApprovedExceptionCount': value.approvedExceptionCount,
        'ApprovedExceptionTime': value.approvedExceptionTime,
        'OperatorScoreComplianceState': ScoreComplianceStateTypeToJSON(value.operatorScoreComplianceState),
        'RigScoreComplianceState': ScoreComplianceStateTypeToJSON(value.rigScoreComplianceState),
        'ImprovementScoreComplianceState': ScoreComplianceStateTypeToJSON(value.improvementScoreComplianceState),
        'OperatorScoreComplianceRate': value.operatorScoreComplianceRate,
        'RigScoreComplianceRate': value.rigScoreComplianceRate,
        'ImprovementScoreComplianceRate': value.improvementScoreComplianceRate,
        'ScoreSummary': ScoreSummaryRootDtoToJSON(value.scoreSummary),
        'WellBars': ((value.wellBars as Array<any>).map(WellBarDtoToJSON)),
    };
}

