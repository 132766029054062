/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProfileOriginType = {
    Unknown: 'Unknown',
    Personal: 'Personal',
    Operator: 'Operator',
    Shared: 'Shared'
} as const;
export type ProfileOriginType = typeof ProfileOriginType[keyof typeof ProfileOriginType];


export function ProfileOriginTypeFromJSON(json: any): ProfileOriginType {
    return ProfileOriginTypeFromJSONTyped(json, false);
}

export function ProfileOriginTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileOriginType {
    return json as ProfileOriginType;
}

export function ProfileOriginTypeToJSON(value?: ProfileOriginType | null): any {
    return value as any;
}

