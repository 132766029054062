/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DrillingSplitsDto
 */
export interface DrillingSplitsDto {
    /**
     * 
     * @type {number}
     * @memberof DrillingSplitsDto
     */
    rotatingDuration: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingSplitsDto
     */
    slidingDuration: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingSplitsDto
     */
    rotatingDistance: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingSplitsDto
     */
    slidingDistance: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingSplitsDto
     */
    readonly rotatingDurationSplit: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingSplitsDto
     */
    readonly slidingDurationSplit: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingSplitsDto
     */
    readonly rotatingDistanceSplit: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingSplitsDto
     */
    readonly slidingDistanceSplit: number;
    /**
     * 
     * @type {boolean}
     * @memberof DrillingSplitsDto
     */
    readonly isEmpty: boolean;
}

export function DrillingSplitsDtoFromJSON(json: any): DrillingSplitsDto {
    return DrillingSplitsDtoFromJSONTyped(json, false);
}

export function DrillingSplitsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrillingSplitsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rotatingDuration': json['RotatingDuration'],
        'slidingDuration': json['SlidingDuration'],
        'rotatingDistance': json['RotatingDistance'],
        'slidingDistance': json['SlidingDistance'],
        'rotatingDurationSplit': json['RotatingDurationSplit'],
        'slidingDurationSplit': json['SlidingDurationSplit'],
        'rotatingDistanceSplit': json['RotatingDistanceSplit'],
        'slidingDistanceSplit': json['SlidingDistanceSplit'],
        'isEmpty': json['IsEmpty'],
    };
}

export function DrillingSplitsDtoToJSON(value?: DrillingSplitsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'RotatingDuration': value.rotatingDuration,
        'SlidingDuration': value.slidingDuration,
        'RotatingDistance': value.rotatingDistance,
        'SlidingDistance': value.slidingDistance,
    };
}

