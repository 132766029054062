/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    DrillingSplitsDto,
    DrillingSplitsDtoFromJSON,
    DrillingSplitsDtoFromJSONTyped,
    DrillingSplitsDtoToJSON,
} from './DrillingSplitsDto';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    ServiceMessageDto,
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import {
    StopWatchDto,
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';

/**
 * 
 * @export
 * @interface WellDrillingSummaryLensResultDto
 */
export interface WellDrillingSummaryLensResultDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof WellDrillingSummaryLensResultDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof WellDrillingSummaryLensResultDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingSummaryLensResultDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDrillingSummaryLensResultDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {DrillingSplitsDto}
     * @memberof WellDrillingSummaryLensResultDto
     */
    drillingSplits: DrillingSplitsDto;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingSummaryLensResultDto
     */
    totalConnectionCount: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingSummaryLensResultDto
     */
    reamingConnectionCount: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingSummaryLensResultDto
     */
    reamingDuration: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingSummaryLensResultDto
     */
    readonly reamingConnectionRatio: number;
    /**
     * 
     * @type {ResultDataState}
     * @memberof WellDrillingSummaryLensResultDto
     */
    dataState: ResultDataState;
}

export function WellDrillingSummaryLensResultDtoFromJSON(json: any): WellDrillingSummaryLensResultDto {
    return WellDrillingSummaryLensResultDtoFromJSONTyped(json, false);
}

export function WellDrillingSummaryLensResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WellDrillingSummaryLensResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': !exists(json, 'Messages') ? undefined : (json['Messages'] === null ? null : (json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'drillingSplits': DrillingSplitsDtoFromJSON(json['DrillingSplits']),
        'totalConnectionCount': json['TotalConnectionCount'],
        'reamingConnectionCount': json['ReamingConnectionCount'],
        'reamingDuration': json['ReamingDuration'],
        'reamingConnectionRatio': json['ReamingConnectionRatio'],
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function WellDrillingSummaryLensResultDtoToJSON(value?: WellDrillingSummaryLensResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value.stopWatch),
        'Messages': value.messages === undefined ? undefined : (value.messages === null ? null : (value.messages as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value.userLensId,
        'LastUpdatedAt': DateDtoToJSON(value.lastUpdatedAt),
        'DrillingSplits': DrillingSplitsDtoToJSON(value.drillingSplits),
        'TotalConnectionCount': value.totalConnectionCount,
        'ReamingConnectionCount': value.reamingConnectionCount,
        'ReamingDuration': value.reamingDuration,
        'DataState': ResultDataStateToJSON(value.dataState),
    };
}

