/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface InspectionTvdPointDto
 */
export interface InspectionTvdPointDto {
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    cumulativeDuration: number;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    dynamicDuration: number;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    holeDepth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    dynamicHoleDepth?: number | null;
    /**
     * 
     * @type {DateDto}
     * @memberof InspectionTvdPointDto
     */
    at: DateDto;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    operationClassId: number;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    bitDepth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    blockHeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    torque?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    hookLoad?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    standpipePressure?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    pumpOutput?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    rateOfPenetration?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    revolutionsPerSecond?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    differentialPressure?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    weightOnBit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    sssi?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    zTorqueStatus?: number | null;
}

export function InspectionTvdPointDtoFromJSON(json: any): InspectionTvdPointDto {
    return InspectionTvdPointDtoFromJSONTyped(json, false);
}

export function InspectionTvdPointDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InspectionTvdPointDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cumulativeDuration': json['CumulativeDuration'],
        'dynamicDuration': json['DynamicDuration'],
        'holeDepth': !exists(json, 'HoleDepth') ? undefined : json['HoleDepth'],
        'dynamicHoleDepth': !exists(json, 'DynamicHoleDepth') ? undefined : json['DynamicHoleDepth'],
        'at': DateDtoFromJSON(json['At']),
        'operationClassId': json['OperationClassId'],
        'bitDepth': !exists(json, 'BitDepth') ? undefined : json['BitDepth'],
        'blockHeight': !exists(json, 'BlockHeight') ? undefined : json['BlockHeight'],
        'torque': !exists(json, 'Torque') ? undefined : json['Torque'],
        'hookLoad': !exists(json, 'HookLoad') ? undefined : json['HookLoad'],
        'standpipePressure': !exists(json, 'StandpipePressure') ? undefined : json['StandpipePressure'],
        'pumpOutput': !exists(json, 'PumpOutput') ? undefined : json['PumpOutput'],
        'rateOfPenetration': !exists(json, 'RateOfPenetration') ? undefined : json['RateOfPenetration'],
        'revolutionsPerSecond': !exists(json, 'RevolutionsPerSecond') ? undefined : json['RevolutionsPerSecond'],
        'differentialPressure': !exists(json, 'DifferentialPressure') ? undefined : json['DifferentialPressure'],
        'weightOnBit': !exists(json, 'WeightOnBit') ? undefined : json['WeightOnBit'],
        'sssi': !exists(json, 'Sssi') ? undefined : json['Sssi'],
        'zTorqueStatus': !exists(json, 'ZTorqueStatus') ? undefined : json['ZTorqueStatus'],
    };
}

export function InspectionTvdPointDtoToJSON(value?: InspectionTvdPointDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'CumulativeDuration': value.cumulativeDuration,
        'DynamicDuration': value.dynamicDuration,
        'HoleDepth': value.holeDepth,
        'DynamicHoleDepth': value.dynamicHoleDepth,
        'At': DateDtoToJSON(value.at),
        'OperationClassId': value.operationClassId,
        'BitDepth': value.bitDepth,
        'BlockHeight': value.blockHeight,
        'Torque': value.torque,
        'HookLoad': value.hookLoad,
        'StandpipePressure': value.standpipePressure,
        'PumpOutput': value.pumpOutput,
        'RateOfPenetration': value.rateOfPenetration,
        'RevolutionsPerSecond': value.revolutionsPerSecond,
        'DifferentialPressure': value.differentialPressure,
        'WeightOnBit': value.weightOnBit,
        'Sssi': value.sssi,
        'ZTorqueStatus': value.zTorqueStatus,
    };
}

