/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface NaturalGasStateFactDto
 */
export interface NaturalGasStateFactDto {
    /**
     * 
     * @type {number}
     * @memberof NaturalGasStateFactDto
     */
    wellId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof NaturalGasStateFactDto
     */
    at: DateDto;
    /**
     * 
     * @type {number}
     * @memberof NaturalGasStateFactDto
     */
    pressure: number;
    /**
     * 
     * @type {number}
     * @memberof NaturalGasStateFactDto
     */
    temperature: number;
}

export function NaturalGasStateFactDtoFromJSON(json: any): NaturalGasStateFactDto {
    return NaturalGasStateFactDtoFromJSONTyped(json, false);
}

export function NaturalGasStateFactDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NaturalGasStateFactDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'wellId': json['WellId'],
        'at': DateDtoFromJSON(json['At']),
        'pressure': json['Pressure'],
        'temperature': json['Temperature'],
    };
}

export function NaturalGasStateFactDtoToJSON(value?: NaturalGasStateFactDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'WellId': value.wellId,
        'At': DateDtoToJSON(value.at),
        'Pressure': value.pressure,
        'Temperature': value.temperature,
    };
}

