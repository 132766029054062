/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    TorqueAndDragCurveDto,
    TorqueAndDragCurveDtoFromJSON,
    TorqueAndDragCurveDtoFromJSONTyped,
    TorqueAndDragCurveDtoToJSON,
} from './TorqueAndDragCurveDto';
import {
    TorqueAndDragType,
    TorqueAndDragTypeFromJSON,
    TorqueAndDragTypeFromJSONTyped,
    TorqueAndDragTypeToJSON,
} from './TorqueAndDragType';

/**
 * 
 * @export
 * @interface TorqueAndDragDataSetDto
 */
export interface TorqueAndDragDataSetDto {
    /**
     * 
     * @type {TorqueAndDragType}
     * @memberof TorqueAndDragDataSetDto
     */
    type: TorqueAndDragType;
    /**
     * 
     * @type {Array<TorqueAndDragCurveDto>}
     * @memberof TorqueAndDragDataSetDto
     */
    curves?: Array<TorqueAndDragCurveDto> | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof TorqueAndDragDataSetDto
     */
    dataState: ResultDataState;
}

export function TorqueAndDragDataSetDtoFromJSON(json: any): TorqueAndDragDataSetDto {
    return TorqueAndDragDataSetDtoFromJSONTyped(json, false);
}

export function TorqueAndDragDataSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TorqueAndDragDataSetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': TorqueAndDragTypeFromJSON(json['Type']),
        'curves': !exists(json, 'Curves') ? undefined : (json['Curves'] === null ? null : (json['Curves'] as Array<any>).map(TorqueAndDragCurveDtoFromJSON)),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function TorqueAndDragDataSetDtoToJSON(value?: TorqueAndDragDataSetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Type': TorqueAndDragTypeToJSON(value.type),
        'Curves': value.curves === undefined ? undefined : (value.curves === null ? null : (value.curves as Array<any>).map(TorqueAndDragCurveDtoToJSON)),
        'DataState': ResultDataStateToJSON(value.dataState),
    };
}

