/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StandKpiGroup,
    StandKpiGroupFromJSON,
    StandKpiGroupFromJSONTyped,
    StandKpiGroupToJSON,
} from './StandKpiGroup';
import {
    StandKpiType,
    StandKpiTypeFromJSON,
    StandKpiTypeFromJSONTyped,
    StandKpiTypeToJSON,
} from './StandKpiType';

/**
 * 
 * @export
 * @interface StandKpiGroupDto
 */
export interface StandKpiGroupDto {
    /**
     * 
     * @type {StandKpiGroup}
     * @memberof StandKpiGroupDto
     */
    id: StandKpiGroup;
    /**
     * 
     * @type {string}
     * @memberof StandKpiGroupDto
     */
    name?: string | null;
    /**
     * 
     * @type {StandKpiType}
     * @memberof StandKpiGroupDto
     */
    parentKpiType: StandKpiType;
    /**
     * 
     * @type {Array<StandKpiType>}
     * @memberof StandKpiGroupDto
     */
    kpiTypeIds: Array<StandKpiType>;
    /**
     * 
     * @type {boolean}
     * @memberof StandKpiGroupDto
     */
    acceptsAllStackingTypes: boolean;
}

export function StandKpiGroupDtoFromJSON(json: any): StandKpiGroupDto {
    return StandKpiGroupDtoFromJSONTyped(json, false);
}

export function StandKpiGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandKpiGroupDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': StandKpiGroupFromJSON(json['Id']),
        'name': !exists(json, 'Name') ? undefined : json['Name'],
        'parentKpiType': StandKpiTypeFromJSON(json['ParentKpiType']),
        'kpiTypeIds': ((json['KpiTypeIds'] as Array<any>).map(StandKpiTypeFromJSON)),
        'acceptsAllStackingTypes': json['AcceptsAllStackingTypes'],
    };
}

export function StandKpiGroupDtoToJSON(value?: StandKpiGroupDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': StandKpiGroupToJSON(value.id),
        'Name': value.name,
        'ParentKpiType': StandKpiTypeToJSON(value.parentKpiType),
        'KpiTypeIds': ((value.kpiTypeIds as Array<any>).map(StandKpiTypeToJSON)),
        'AcceptsAllStackingTypes': value.acceptsAllStackingTypes,
    };
}

