/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OperationCategoryType,
    OperationCategoryTypeFromJSON,
    OperationCategoryTypeFromJSONTyped,
    OperationCategoryTypeToJSON,
} from './OperationCategoryType';

/**
 * 
 * @export
 * @interface OperationClassDto
 */
export interface OperationClassDto {
    /**
     * 
     * @type {number}
     * @memberof OperationClassDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof OperationClassDto
     */
    externalId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OperationClassDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OperationClassDto
     */
    position: number;
    /**
     * 
     * @type {OperationCategoryType}
     * @memberof OperationClassDto
     */
    category: OperationCategoryType;
}

export function OperationClassDtoFromJSON(json: any): OperationClassDto {
    return OperationClassDtoFromJSONTyped(json, false);
}

export function OperationClassDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationClassDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'externalId': !exists(json, 'ExternalId') ? undefined : json['ExternalId'],
        'name': !exists(json, 'Name') ? undefined : json['Name'],
        'position': json['Position'],
        'category': OperationCategoryTypeFromJSON(json['Category']),
    };
}

export function OperationClassDtoToJSON(value?: OperationClassDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'ExternalId': value.externalId,
        'Name': value.name,
        'Position': value.position,
        'Category': OperationCategoryTypeToJSON(value.category),
    };
}

