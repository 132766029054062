import { Button } from "atoms/Form";
import type { Dict } from "mixpanel-browser";
import styled from "styled-components";
import colors from "utils/colors";
import { Col, Row, Space } from "utils/componentLibrary";
import { zIndexLayer } from "utils/zIndex";

export const FiltersBackground = styled.div<{ numRows: number; hideSecondCol: boolean; numCols: number }>`
  padding: 28px;
  height: ${({ numRows }) => numRows * 215}px;
  background-color: ${({ theme: { themeStyle } }) => themeStyle.colors.tertiary_bg};
  display: grid;
  grid-template-columns: repeat(${({ numCols }) => numCols - 1}, 1fr) ${({ hideSecondCol }) =>
      hideSecondCol ? null : "1fr"};
  gap: 18px;
`;

const Popup = styled(Row)`
  background-color: ${colors.white};
  z-index: ${zIndexLayer.shuttle};
  flex-direction: column;
`;

const FilterControls = styled(Row)`
  background-color: ${({ theme: { themeStyle } }) => themeStyle.colors.off_secondary_bg};
  border-bottom: solid 1px ${({ theme: { themeStyle } }) => themeStyle.colors.soft_accent};
  padding: 12px 28px;
`;

function FiltersPopup({
  isAllSelected,
  trackingProps,
  deselectAll,
  onCancel,
  applyCustomization,
  numRows = 2,
  numCols = 2,
  hideSecondCol = false,
  children,
}: {
  isAllSelected: boolean;
  trackingProps?: Dict;
  deselectAll: () => void;
  onCancel?: () => void;
  applyCustomization: () => void;
  numRows?: number;
  numCols?: number;
  hideSecondCol?: boolean;
  children: React.ReactNode;
}) {
  return (
    <Popup>
      <FilterControls>
        <Col flex="0 auto">
          <Button
            trackingName={isAllSelected ? "Deselect All" : "Select All"}
            trackingProps={trackingProps}
            size="large"
            onClick={deselectAll}
          >
            {isAllSelected ? "Deselect all" : "Select all"}
          </Button>
        </Col>
        <Col flex="1 1 auto">
          <Row justify="end">
            <Space>
              <Button trackingName="Cancel Filters" trackingProps={trackingProps} size="large" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                trackingName="Apply Filters"
                trackingProps={trackingProps}
                size="large"
                type="primary"
                onClick={applyCustomization}
              >
                Apply
              </Button>
            </Space>
          </Row>
        </Col>
      </FilterControls>
      <FiltersBackground numRows={numRows} numCols={numCols} hideSecondCol={hideSecondCol}>
        {children}
      </FiltersBackground>
    </Popup>
  );
}

export default FiltersPopup;
