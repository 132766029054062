/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClaimPermissionType,
    ClaimPermissionTypeFromJSON,
    ClaimPermissionTypeFromJSONTyped,
    ClaimPermissionTypeToJSON,
} from './ClaimPermissionType';
import {
    ClaimType,
    ClaimTypeFromJSON,
    ClaimTypeFromJSONTyped,
    ClaimTypeToJSON,
} from './ClaimType';

/**
 * 
 * @export
 * @interface OperatorClaimDto
 */
export interface OperatorClaimDto {
    /**
     * 
     * @type {number}
     * @memberof OperatorClaimDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof OperatorClaimDto
     */
    rigId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OperatorClaimDto
     */
    wellId?: number | null;
    /**
     * 
     * @type {ClaimType}
     * @memberof OperatorClaimDto
     */
    type: ClaimType;
    /**
     * 
     * @type {ClaimPermissionType}
     * @memberof OperatorClaimDto
     */
    permission: ClaimPermissionType;
    /**
     * 
     * @type {number}
     * @memberof OperatorClaimDto
     */
    operatorId: number;
}

export function OperatorClaimDtoFromJSON(json: any): OperatorClaimDto {
    return OperatorClaimDtoFromJSONTyped(json, false);
}

export function OperatorClaimDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperatorClaimDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'rigId': !exists(json, 'RigId') ? undefined : json['RigId'],
        'wellId': !exists(json, 'WellId') ? undefined : json['WellId'],
        'type': ClaimTypeFromJSON(json['Type']),
        'permission': ClaimPermissionTypeFromJSON(json['Permission']),
        'operatorId': json['OperatorId'],
    };
}

export function OperatorClaimDtoToJSON(value?: OperatorClaimDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'RigId': value.rigId,
        'WellId': value.wellId,
        'Type': ClaimTypeToJSON(value.type),
        'Permission': ClaimPermissionTypeToJSON(value.permission),
        'OperatorId': value.operatorId,
    };
}

