/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BaseFocalQueryDto,
    BaseFocalQueryDtoFromJSON,
    BaseFocalQueryDtoToJSON,
    FiltersDto,
    FiltersDtoFromJSON,
    FiltersDtoToJSON,
    StickSlipByTimeSetDto,
    StickSlipByTimeSetDtoFromJSON,
    StickSlipByTimeSetDtoToJSON,
    StickSlipByTimeUserLensDto,
    StickSlipByTimeUserLensDtoFromJSON,
    StickSlipByTimeUserLensDtoToJSON,
    ZTorqueSummaryDto,
    ZTorqueSummaryDtoFromJSON,
    ZTorqueSummaryDtoToJSON,
} from '../models';

export interface ApiStickSlipByTimeUserLensesAvailableFiltersPutRequest {
    baseFocalQueryDto?: BaseFocalQueryDto;
}

export interface ApiStickSlipByTimeUserLensesIdFactsPutRequest {
    id: number;
    baseFocalQueryDto?: BaseFocalQueryDto;
}

export interface ApiStickSlipByTimeUserLensesIdPutRequest {
    id: number;
    stickSlipByTimeUserLensDto?: StickSlipByTimeUserLensDto;
}

export interface ApiStickSlipByTimeUserLensesIdZTorqueSummaryPutRequest {
    id: number;
    baseFocalQueryDto?: BaseFocalQueryDto;
}

export interface ApiStickSlipByTimeUserLensesPostRequest {
    stickSlipByTimeUserLensDto?: StickSlipByTimeUserLensDto;
}

/**
 * 
 */
export class StickSlipByTimeUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiStickSlipByTimeUserLensesAvailableFiltersPutRaw(requestParameters: ApiStickSlipByTimeUserLensesAvailableFiltersPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FiltersDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/StickSlipByTimeUserLenses/AvailableFilters`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BaseFocalQueryDtoToJSON(requestParameters.baseFocalQueryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FiltersDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiStickSlipByTimeUserLensesAvailableFiltersPut(requestParameters: ApiStickSlipByTimeUserLensesAvailableFiltersPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FiltersDto> {
        const response = await this.apiStickSlipByTimeUserLensesAvailableFiltersPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStickSlipByTimeUserLensesIdFactsPutRaw(requestParameters: ApiStickSlipByTimeUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StickSlipByTimeSetDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiStickSlipByTimeUserLensesIdFactsPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/StickSlipByTimeUserLenses/{id}/Facts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BaseFocalQueryDtoToJSON(requestParameters.baseFocalQueryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StickSlipByTimeSetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiStickSlipByTimeUserLensesIdFactsPut(requestParameters: ApiStickSlipByTimeUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StickSlipByTimeSetDto> {
        const response = await this.apiStickSlipByTimeUserLensesIdFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStickSlipByTimeUserLensesIdPutRaw(requestParameters: ApiStickSlipByTimeUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StickSlipByTimeUserLensDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiStickSlipByTimeUserLensesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/StickSlipByTimeUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StickSlipByTimeUserLensDtoToJSON(requestParameters.stickSlipByTimeUserLensDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StickSlipByTimeUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiStickSlipByTimeUserLensesIdPut(requestParameters: ApiStickSlipByTimeUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StickSlipByTimeUserLensDto> {
        const response = await this.apiStickSlipByTimeUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStickSlipByTimeUserLensesIdZTorqueSummaryPutRaw(requestParameters: ApiStickSlipByTimeUserLensesIdZTorqueSummaryPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ZTorqueSummaryDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiStickSlipByTimeUserLensesIdZTorqueSummaryPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/StickSlipByTimeUserLenses/{id}/ZTorqueSummary`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BaseFocalQueryDtoToJSON(requestParameters.baseFocalQueryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ZTorqueSummaryDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiStickSlipByTimeUserLensesIdZTorqueSummaryPut(requestParameters: ApiStickSlipByTimeUserLensesIdZTorqueSummaryPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ZTorqueSummaryDto> {
        const response = await this.apiStickSlipByTimeUserLensesIdZTorqueSummaryPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStickSlipByTimeUserLensesPostRaw(requestParameters: ApiStickSlipByTimeUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StickSlipByTimeUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/StickSlipByTimeUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StickSlipByTimeUserLensDtoToJSON(requestParameters.stickSlipByTimeUserLensDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StickSlipByTimeUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiStickSlipByTimeUserLensesPost(requestParameters: ApiStickSlipByTimeUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StickSlipByTimeUserLensDto> {
        const response = await this.apiStickSlipByTimeUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
