/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReportStatusType,
    ReportStatusTypeFromJSON,
    ReportStatusTypeFromJSONTyped,
    ReportStatusTypeToJSON,
} from './ReportStatusType';

/**
 * 
 * @export
 * @interface ReportStatusDto
 */
export interface ReportStatusDto {
    /**
     * 
     * @type {number}
     * @memberof ReportStatusDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ReportStatusDto
     */
    filename?: string | null;
    /**
     * 
     * @type {ReportStatusType}
     * @memberof ReportStatusDto
     */
    status: ReportStatusType;
    /**
     * 
     * @type {Date}
     * @memberof ReportStatusDto
     */
    generationStartUtc?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ReportStatusDto
     */
    generationEndUtc?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ReportStatusDto
     */
    errorMessage?: string | null;
}

export function ReportStatusDtoFromJSON(json: any): ReportStatusDto {
    return ReportStatusDtoFromJSONTyped(json, false);
}

export function ReportStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportStatusDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'filename': !exists(json, 'Filename') ? undefined : json['Filename'],
        'status': ReportStatusTypeFromJSON(json['Status']),
        'generationStartUtc': !exists(json, 'GenerationStartUtc') ? undefined : (json['GenerationStartUtc'] === null ? null : new Date(json['GenerationStartUtc'])),
        'generationEndUtc': !exists(json, 'GenerationEndUtc') ? undefined : (json['GenerationEndUtc'] === null ? null : new Date(json['GenerationEndUtc'])),
        'errorMessage': !exists(json, 'ErrorMessage') ? undefined : json['ErrorMessage'],
    };
}

export function ReportStatusDtoToJSON(value?: ReportStatusDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'Filename': value.filename,
        'Status': ReportStatusTypeToJSON(value.status),
        'GenerationStartUtc': value.generationStartUtc === undefined ? undefined : (value.generationStartUtc === null ? null : value.generationStartUtc.toISOString()),
        'GenerationEndUtc': value.generationEndUtc === undefined ? undefined : (value.generationEndUtc === null ? null : value.generationEndUtc.toISOString()),
        'ErrorMessage': value.errorMessage,
    };
}

