/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlanDrillingParameterDto
 */
export interface PlanDrillingParameterDto {
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDto
     */
    measuredDepth: number;
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDto
     */
    wobMin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDto
     */
    wobMax?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDto
     */
    revolutionPerSecondMin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDto
     */
    revolutionPerSecondMax?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDto
     */
    ropMin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDto
     */
    ropMax?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDto
     */
    diffPressureMin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDto
     */
    diffPressureMax?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDto
     */
    torqueMin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDto
     */
    torqueMax?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDto
     */
    flowInMin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDto
     */
    flowInMax?: number | null;
}

export function PlanDrillingParameterDtoFromJSON(json: any): PlanDrillingParameterDto {
    return PlanDrillingParameterDtoFromJSONTyped(json, false);
}

export function PlanDrillingParameterDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanDrillingParameterDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'measuredDepth': json['MeasuredDepth'],
        'wobMin': !exists(json, 'WobMin') ? undefined : json['WobMin'],
        'wobMax': !exists(json, 'WobMax') ? undefined : json['WobMax'],
        'revolutionPerSecondMin': !exists(json, 'RevolutionPerSecondMin') ? undefined : json['RevolutionPerSecondMin'],
        'revolutionPerSecondMax': !exists(json, 'RevolutionPerSecondMax') ? undefined : json['RevolutionPerSecondMax'],
        'ropMin': !exists(json, 'RopMin') ? undefined : json['RopMin'],
        'ropMax': !exists(json, 'RopMax') ? undefined : json['RopMax'],
        'diffPressureMin': !exists(json, 'DiffPressureMin') ? undefined : json['DiffPressureMin'],
        'diffPressureMax': !exists(json, 'DiffPressureMax') ? undefined : json['DiffPressureMax'],
        'torqueMin': !exists(json, 'TorqueMin') ? undefined : json['TorqueMin'],
        'torqueMax': !exists(json, 'TorqueMax') ? undefined : json['TorqueMax'],
        'flowInMin': !exists(json, 'FlowInMin') ? undefined : json['FlowInMin'],
        'flowInMax': !exists(json, 'FlowInMax') ? undefined : json['FlowInMax'],
    };
}

export function PlanDrillingParameterDtoToJSON(value?: PlanDrillingParameterDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'MeasuredDepth': value.measuredDepth,
        'WobMin': value.wobMin,
        'WobMax': value.wobMax,
        'RevolutionPerSecondMin': value.revolutionPerSecondMin,
        'RevolutionPerSecondMax': value.revolutionPerSecondMax,
        'RopMin': value.ropMin,
        'RopMax': value.ropMax,
        'DiffPressureMin': value.diffPressureMin,
        'DiffPressureMax': value.diffPressureMax,
        'TorqueMin': value.torqueMin,
        'TorqueMax': value.torqueMax,
        'FlowInMin': value.flowInMin,
        'FlowInMax': value.flowInMax,
    };
}

