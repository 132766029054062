/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    FiltersDto,
    FiltersDtoFromJSON,
    FiltersDtoFromJSONTyped,
    FiltersDtoToJSON,
} from './FiltersDto';
import {
    WellStatusType,
    WellStatusTypeFromJSON,
    WellStatusTypeFromJSONTyped,
    WellStatusTypeToJSON,
} from './WellStatusType';

/**
 * 
 * @export
 * @interface AllRigsQueryDto
 */
export interface AllRigsQueryDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof AllRigsQueryDto
     */
    formationIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AllRigsQueryDto
     */
    operatorIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AllRigsQueryDto
     */
    rigIds?: Array<number> | null;
    /**
     * 
     * @type {DateDto}
     * @memberof AllRigsQueryDto
     */
    from: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof AllRigsQueryDto
     */
    to: DateDto;
    /**
     * 
     * @type {boolean}
     * @memberof AllRigsQueryDto
     */
    includeFullWellFacts: boolean;
    /**
     * 
     * @type {FiltersDto}
     * @memberof AllRigsQueryDto
     */
    selectedFilters: FiltersDto;
    /**
     * 
     * @type {boolean}
     * @memberof AllRigsQueryDto
     */
    includeAlphaRigs: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AllRigsQueryDto
     */
    includeNoneAlphaRigs: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AllRigsQueryDto
     */
    selectedClasses?: Array<string> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AllRigsQueryDto
     */
    selectedCountryIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AllRigsQueryDto
     */
    selectedHorsePowers?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AllRigsQueryDto
     */
    selectedOperatingCenters?: Array<string> | null;
    /**
     * 
     * @type {Array<WellStatusType>}
     * @memberof AllRigsQueryDto
     */
    selectedWellStatusTypes?: Array<WellStatusType> | null;
}

export function AllRigsQueryDtoFromJSON(json: any): AllRigsQueryDto {
    return AllRigsQueryDtoFromJSONTyped(json, false);
}

export function AllRigsQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllRigsQueryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'formationIds': !exists(json, 'FormationIds') ? undefined : json['FormationIds'],
        'operatorIds': !exists(json, 'OperatorIds') ? undefined : json['OperatorIds'],
        'rigIds': !exists(json, 'RigIds') ? undefined : json['RigIds'],
        'from': DateDtoFromJSON(json['From']),
        'to': DateDtoFromJSON(json['To']),
        'includeFullWellFacts': json['IncludeFullWellFacts'],
        'selectedFilters': FiltersDtoFromJSON(json['SelectedFilters']),
        'includeAlphaRigs': json['IncludeAlphaRigs'],
        'includeNoneAlphaRigs': json['IncludeNoneAlphaRigs'],
        'selectedClasses': !exists(json, 'SelectedClasses') ? undefined : json['SelectedClasses'],
        'selectedCountryIds': !exists(json, 'SelectedCountryIds') ? undefined : json['SelectedCountryIds'],
        'selectedHorsePowers': !exists(json, 'SelectedHorsePowers') ? undefined : json['SelectedHorsePowers'],
        'selectedOperatingCenters': !exists(json, 'SelectedOperatingCenters') ? undefined : json['SelectedOperatingCenters'],
        'selectedWellStatusTypes': !exists(json, 'SelectedWellStatusTypes') ? undefined : (json['SelectedWellStatusTypes'] === null ? null : (json['SelectedWellStatusTypes'] as Array<any>).map(WellStatusTypeFromJSON)),
    };
}

export function AllRigsQueryDtoToJSON(value?: AllRigsQueryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'FormationIds': value.formationIds,
        'OperatorIds': value.operatorIds,
        'RigIds': value.rigIds,
        'From': DateDtoToJSON(value.from),
        'To': DateDtoToJSON(value.to),
        'IncludeFullWellFacts': value.includeFullWellFacts,
        'SelectedFilters': FiltersDtoToJSON(value.selectedFilters),
        'IncludeAlphaRigs': value.includeAlphaRigs,
        'IncludeNoneAlphaRigs': value.includeNoneAlphaRigs,
        'SelectedClasses': value.selectedClasses,
        'SelectedCountryIds': value.selectedCountryIds,
        'SelectedHorsePowers': value.selectedHorsePowers,
        'SelectedOperatingCenters': value.selectedOperatingCenters,
        'SelectedWellStatusTypes': value.selectedWellStatusTypes === undefined ? undefined : (value.selectedWellStatusTypes === null ? null : (value.selectedWellStatusTypes as Array<any>).map(WellStatusTypeToJSON)),
    };
}

