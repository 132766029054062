/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RigLeaderboardScoreKpiAco
 */
export interface RigLeaderboardScoreKpiAco {
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreKpiAco
     */
    kpiName: string;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreKpiAco
     */
    kpiDescription: string;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardScoreKpiAco
     */
    timeVsTargetHrs: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardScoreKpiAco
     */
    kpiScorePercentage: number | null;
}

export function RigLeaderboardScoreKpiAcoFromJSON(json: any): RigLeaderboardScoreKpiAco {
    return RigLeaderboardScoreKpiAcoFromJSONTyped(json, false);
}

export function RigLeaderboardScoreKpiAcoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigLeaderboardScoreKpiAco {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kpiName': json['KpiName'],
        'kpiDescription': json['KpiDescription'],
        'timeVsTargetHrs': json['TimeVsTargetHrs'],
        'kpiScorePercentage': json['KpiScorePercentage'],
    };
}

export function RigLeaderboardScoreKpiAcoToJSON(value?: RigLeaderboardScoreKpiAco | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'KpiName': value.kpiName,
        'KpiDescription': value.kpiDescription,
        'TimeVsTargetHrs': value.timeVsTargetHrs,
        'KpiScorePercentage': value.kpiScorePercentage,
    };
}

