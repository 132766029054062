/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StandKpiType,
    StandKpiTypeFromJSON,
    StandKpiTypeFromJSONTyped,
    StandKpiTypeToJSON,
} from './StandKpiType';

/**
 * 
 * @export
 * @interface StandKpiCommentDto
 */
export interface StandKpiCommentDto {
    /**
     * 
     * @type {number}
     * @memberof StandKpiCommentDto
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof StandKpiCommentDto
     */
    deletedAtUtc?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof StandKpiCommentDto
     */
    wellId: number;
    /**
     * 
     * @type {string}
     * @memberof StandKpiCommentDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StandKpiCommentDto
     */
    description?: string | null;
    /**
     * 
     * @type {StandKpiType}
     * @memberof StandKpiCommentDto
     */
    kpiType: StandKpiType;
    /**
     * 
     * @type {number}
     * @memberof StandKpiCommentDto
     */
    standId: number;
    /**
     * 
     * @type {number}
     * @memberof StandKpiCommentDto
     */
    startRangeStandId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StandKpiCommentDto
     */
    endRangeStandId?: number | null;
}

export function StandKpiCommentDtoFromJSON(json: any): StandKpiCommentDto {
    return StandKpiCommentDtoFromJSONTyped(json, false);
}

export function StandKpiCommentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandKpiCommentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'deletedAtUtc': !exists(json, 'DeletedAtUtc') ? undefined : (json['DeletedAtUtc'] === null ? null : new Date(json['DeletedAtUtc'])),
        'wellId': json['WellId'],
        'title': !exists(json, 'Title') ? undefined : json['Title'],
        'description': !exists(json, 'Description') ? undefined : json['Description'],
        'kpiType': StandKpiTypeFromJSON(json['KpiType']),
        'standId': json['StandId'],
        'startRangeStandId': !exists(json, 'StartRangeStandId') ? undefined : json['StartRangeStandId'],
        'endRangeStandId': !exists(json, 'EndRangeStandId') ? undefined : json['EndRangeStandId'],
    };
}

export function StandKpiCommentDtoToJSON(value?: StandKpiCommentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'DeletedAtUtc': value.deletedAtUtc === undefined ? undefined : (value.deletedAtUtc === null ? null : value.deletedAtUtc.toISOString()),
        'WellId': value.wellId,
        'Title': value.title,
        'Description': value.description,
        'KpiType': StandKpiTypeToJSON(value.kpiType),
        'StandId': value.standId,
        'StartRangeStandId': value.startRangeStandId,
        'EndRangeStandId': value.endRangeStandId,
    };
}

