/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExceptionRequestNotificationDto,
    ExceptionRequestNotificationDtoFromJSON,
    ExceptionRequestNotificationDtoFromJSONTyped,
    ExceptionRequestNotificationDtoToJSON,
} from './ExceptionRequestNotificationDto';
import {
    ScorecardCommentNotificationDto,
    ScorecardCommentNotificationDtoFromJSON,
    ScorecardCommentNotificationDtoFromJSONTyped,
    ScorecardCommentNotificationDtoToJSON,
} from './ScorecardCommentNotificationDto';

/**
 * 
 * @export
 * @interface NotificationResultSetDto
 */
export interface NotificationResultSetDto {
    /**
     * 
     * @type {Array<ScorecardCommentNotificationDto>}
     * @memberof NotificationResultSetDto
     */
    scorecardCommentNotifications: Array<ScorecardCommentNotificationDto>;
    /**
     * 
     * @type {Array<ExceptionRequestNotificationDto>}
     * @memberof NotificationResultSetDto
     */
    exceptionRequestNotifications: Array<ExceptionRequestNotificationDto>;
}

export function NotificationResultSetDtoFromJSON(json: any): NotificationResultSetDto {
    return NotificationResultSetDtoFromJSONTyped(json, false);
}

export function NotificationResultSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationResultSetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scorecardCommentNotifications': ((json['ScorecardCommentNotifications'] as Array<any>).map(ScorecardCommentNotificationDtoFromJSON)),
        'exceptionRequestNotifications': ((json['ExceptionRequestNotifications'] as Array<any>).map(ExceptionRequestNotificationDtoFromJSON)),
    };
}

export function NotificationResultSetDtoToJSON(value?: NotificationResultSetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ScorecardCommentNotifications': ((value.scorecardCommentNotifications as Array<any>).map(ScorecardCommentNotificationDtoToJSON)),
        'ExceptionRequestNotifications': ((value.exceptionRequestNotifications as Array<any>).map(ExceptionRequestNotificationDtoToJSON)),
    };
}

