/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PlanDrillingParameterDtoListAuditTrailVersionDto,
    PlanDrillingParameterDtoListAuditTrailVersionDtoFromJSON,
    PlanDrillingParameterDtoListAuditTrailVersionDtoFromJSONTyped,
    PlanDrillingParameterDtoListAuditTrailVersionDtoToJSON,
} from './PlanDrillingParameterDtoListAuditTrailVersionDto';

/**
 * 
 * @export
 * @interface PlanRoadmapAuditTrailDto
 */
export interface PlanRoadmapAuditTrailDto {
    /**
     * 
     * @type {Date}
     * @memberof PlanRoadmapAuditTrailDto
     */
    firstUtc: Date;
    /**
     * 
     * @type {Date}
     * @memberof PlanRoadmapAuditTrailDto
     */
    lastUtc: Date;
    /**
     * 
     * @type {Array<PlanDrillingParameterDtoListAuditTrailVersionDto>}
     * @memberof PlanRoadmapAuditTrailDto
     */
    versions?: Array<PlanDrillingParameterDtoListAuditTrailVersionDto> | null;
}

export function PlanRoadmapAuditTrailDtoFromJSON(json: any): PlanRoadmapAuditTrailDto {
    return PlanRoadmapAuditTrailDtoFromJSONTyped(json, false);
}

export function PlanRoadmapAuditTrailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanRoadmapAuditTrailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstUtc': (new Date(json['FirstUtc'])),
        'lastUtc': (new Date(json['LastUtc'])),
        'versions': !exists(json, 'Versions') ? undefined : (json['Versions'] === null ? null : (json['Versions'] as Array<any>).map(PlanDrillingParameterDtoListAuditTrailVersionDtoFromJSON)),
    };
}

export function PlanRoadmapAuditTrailDtoToJSON(value?: PlanRoadmapAuditTrailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'FirstUtc': (value.firstUtc.toISOString()),
        'LastUtc': (value.lastUtc.toISOString()),
        'Versions': value.versions === undefined ? undefined : (value.versions === null ? null : (value.versions as Array<any>).map(PlanDrillingParameterDtoListAuditTrailVersionDtoToJSON)),
    };
}

