/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DateDto
 */
export interface DateDto {
    /**
     * 
     * @type {Date}
     * @memberof DateDto
     */
    utc: Date;
    /**
     * 
     * @type {number}
     * @memberof DateDto
     */
    minutesOffset: number;
    /**
     * 
     * @type {boolean}
     * @memberof DateDto
     */
    isDateOnly: boolean;
}

export function DateDtoFromJSON(json: any): DateDto {
    return DateDtoFromJSONTyped(json, false);
}

export function DateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'utc': (new Date(json['Utc'])),
        'minutesOffset': json['MinutesOffset'],
        'isDateOnly': json['IsDateOnly'],
    };
}

export function DateDtoToJSON(value?: DateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Utc': (value.utc.toISOString()),
        'MinutesOffset': value.minutesOffset,
        'IsDateOnly': value.isDateOnly,
    };
}

