import { Group } from "@visx/group";
import { Line } from "@visx/shape";
import type { UseTooltipParams } from "@visx/tooltip/lib/hooks/useTooltip";
import type { DateDto, ParameterByDepthTrackValuesDto } from "apis/oag";
import { useChartTooltip } from "components/Lenses/common/useChartTooltip";
import type { WithIndex } from "components/Lenses/ContainerLens/common/utils/utils";
import { bisector } from "d3-array";
import type { ScaleLinear } from "d3-scale";
import { clamp, min } from "lodash";
import { CHART_PADDING_TOP } from "pages/AllRigs/components/LeaderboardSection/utils";
import type { SetStateAction } from "react";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import type { UOMHelper } from "utils/format";
import { calculateExtremitiesBisectFix } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

import { TOOLTIP_HEIGHT, TOOLTIP_HORIZONTAL_PADDING, TOOLTIP_WIDTH } from "./constants";

export type TrackValuesWithIndex = WithIndex<ParameterByDepthTrackValuesDto>;

export interface TrackDetails {
  yScale: ScaleLinear<number, number, never>;
  axisScale: ScaleLinear<number, number, never>;
  key: string;
  color: string;
  fill: string;
  trackPoints: TrackValuesWithIndex[];
  label: string;
  uom?: UOMHelper;
}

type PointValues = Record<
  string,
  {
    value: number;
    label: string;
    uom?: UOMHelper;
    visibleDomain: number[];
  }
>;

export interface TooltipPoint {
  timestamp: DateDto;
  index: number;
  holeDepth: number;
  left: number;
  values: PointValues;
}

interface StickSlipByTimeTooltipData {
  pointerPosition: { x: number; y: number };
  setPointerPosition: React.Dispatch<SetStateAction<{ x: number; y: number }>>;
  isPointerInsideChart: boolean;
  setPointerInsideChart: React.Dispatch<SetStateAction<boolean>>;
}

export const TooltipDataContext = createContext<StickSlipByTimeTooltipData | undefined>(undefined);

export const useStickSlipByTimeContext = () => {
  const contextValue = useContext(TooltipDataContext);
  if (!contextValue) {
    throw new Error("useStickSlipByTimeContext must be used within a TooltipDataProvider");
  }
  return contextValue;
};

export const TooltipDataProvider = ({ children }: { children: React.ReactNode }) => {
  const [pointerPosition, setPointerPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [isPointerInsideChart, setPointerInsideChart] = useState(false);

  const contextValue = useMemo(
    () => ({ pointerPosition, setPointerPosition, isPointerInsideChart, setPointerInsideChart }),
    [pointerPosition, setPointerPosition, isPointerInsideChart, setPointerInsideChart],
  );
  return <TooltipDataContext.Provider value={contextValue}>{children}</TooltipDataContext.Provider>;
};

export const useStickSlipByTimeTooltip = ({
  containerRef,
  xScale,
  plotWidth,
  plotHeight,
  renderContent,
  tracks,
  trackValues,
}: {
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  xScale: ScaleLinear<number, number, never>;
  plotWidth: number;
  plotHeight: number;
  tracks: TrackDetails[];
  trackValues: TrackValuesWithIndex[];
  renderContent: (params: UseTooltipParams<TooltipPoint>) => React.ReactNode;
}) => {
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const { pointerPosition, setPointerPosition, isPointerInsideChart } = useStickSlipByTimeContext();
  const handlePointerMove = useCallback(
    (x: number, y: number) => {
      setPointerPosition({ x, y });
    },
    [setPointerPosition],
  );

  const { showTooltip, hideTooltip, tooltipElement } = useChartTooltip({
    containerRef,
    renderContent,
    rightTooltip: true,
    hideArrow: true,
  });

  const tooltipPoint = useMemo<TooltipPoint | null>(() => {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const bisectTime = bisector<TrackValuesWithIndex, number>((p) => {
      return p.index;
    }).left;

    if (pointerPosition.x < 0 || pointerPosition.x > plotWidth) {
      return null;
    }

    const x0 = xScale.invert(pointerPosition.x);

    const extremitiesBisectFix = calculateExtremitiesBisectFix(pointerPosition, plotWidth);

    const index = bisectTime(trackValues, x0 + extremitiesBisectFix);

    return trackValues[index]
      ? {
          timestamp: trackValues[index].timestamp,
          holeDepth: trackValues[index].holeDepth,
          index: trackValues[index].index,
          left: pointerPosition.x,
          values: tracks.reduce((acc, track) => {
            const value = track.trackPoints[index]?.value || 0;
            return {
              ...acc,
              [track.key]: { value, uom: track.uom, label: track.label, visibleDomain: track.yScale.domain() },
            };
          }, {} as PointValues),
        }
      : null;
  }, [pointerPosition, plotWidth, xScale, tracks, trackValues]);

  useEffect(() => {
    const tooltipWidth = TOOLTIP_WIDTH + TOOLTIP_HORIZONTAL_PADDING;
    const topPositionCalculated = tooltipPoint?.index
      ? (min(tracks.map((track) => track.yScale(track.trackPoints[tooltipPoint?.index]?.value || 0))) || 0) -
        TOOLTIP_HEIGHT / 2
      : 0;
    const topPosition = clamp(topPositionCalculated, TOOLTIP_HEIGHT / 2, plotHeight - TOOLTIP_HEIGHT / 2);

    const initialLeft = tooltipPoint?.left || 0;
    const tooltipLeft =
      initialLeft > plotWidth - tooltipWidth - TOOLTIP_HORIZONTAL_PADDING
        ? initialLeft - tooltipWidth - TOOLTIP_HORIZONTAL_PADDING
        : initialLeft;
    isPointerInsideChart && tooltipPoint
      ? showTooltip({
          tooltipLeft: tooltipLeft,
          tooltipTop: topPosition,
          tooltipData: tooltipPoint,
        })
      : hideTooltip();
  }, [tooltipPoint, isPointerInsideChart, showTooltip, hideTooltip, xScale, tracks, plotHeight, plotWidth]);

  const Tooltip =
    tooltipPoint && isPointerInsideChart ? (
      <>
        {tooltipElement}
        <Group>
          <Line
            from={{
              x: tooltipPoint.left,
              y: plotHeight,
            }}
            to={{
              x: tooltipPoint.left,
              y: -CHART_PADDING_TOP,
            }}
            strokeOpacity={0.3}
            stroke={themeColors.primary_typography}
            strokeWidth={2}
          />
          {Object.keys(tooltipPoint.values).map((key) => {
            const selectedTrack = tracks.find((track) => track.key === key);
            return (
              <g key={key}>
                <circle
                  cx={xScale(tooltipPoint.index)}
                  cy={selectedTrack?.yScale(tooltipPoint.values[key].value) || 0}
                  r={4}
                  fill={key === "Sssi" ? selectedTrack?.fill : selectedTrack?.color}
                />
                <circle
                  cx={xScale(tooltipPoint.index)}
                  cy={selectedTrack?.yScale(tooltipPoint.values[key].value) || 0}
                  r={1}
                  fill={"white"}
                />
              </g>
            );
          })}
        </Group>
      </>
    ) : null;

  return { handlePointerMove, tooltipPoint, tooltipElement: Tooltip };
};
