/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BroomstickCurveDto,
    BroomstickCurveDtoFromJSON,
    BroomstickCurveDtoFromJSONTyped,
    BroomstickCurveDtoToJSON,
} from './BroomstickCurveDto';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    TorqueAndDragType,
    TorqueAndDragTypeFromJSON,
    TorqueAndDragTypeFromJSONTyped,
    TorqueAndDragTypeToJSON,
} from './TorqueAndDragType';

/**
 * 
 * @export
 * @interface BroomstickCurveSetDto
 */
export interface BroomstickCurveSetDto {
    /**
     * 
     * @type {string}
     * @memberof BroomstickCurveSetDto
     */
    modelName?: string | null;
    /**
     * 
     * @type {TorqueAndDragType}
     * @memberof BroomstickCurveSetDto
     */
    torqueAndDragType: TorqueAndDragType;
    /**
     * 
     * @type {Array<BroomstickCurveDto>}
     * @memberof BroomstickCurveSetDto
     */
    curves?: Array<BroomstickCurveDto> | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof BroomstickCurveSetDto
     */
    dataState: ResultDataState;
}

export function BroomstickCurveSetDtoFromJSON(json: any): BroomstickCurveSetDto {
    return BroomstickCurveSetDtoFromJSONTyped(json, false);
}

export function BroomstickCurveSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BroomstickCurveSetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelName': !exists(json, 'ModelName') ? undefined : json['ModelName'],
        'torqueAndDragType': TorqueAndDragTypeFromJSON(json['TorqueAndDragType']),
        'curves': !exists(json, 'Curves') ? undefined : (json['Curves'] === null ? null : (json['Curves'] as Array<any>).map(BroomstickCurveDtoFromJSON)),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function BroomstickCurveSetDtoToJSON(value?: BroomstickCurveSetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ModelName': value.modelName,
        'TorqueAndDragType': TorqueAndDragTypeToJSON(value.torqueAndDragType),
        'Curves': value.curves === undefined ? undefined : (value.curves === null ? null : (value.curves as Array<any>).map(BroomstickCurveDtoToJSON)),
        'DataState': ResultDataStateToJSON(value.dataState),
    };
}

