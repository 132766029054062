/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BinCoordinates,
    BinCoordinatesFromJSON,
    BinCoordinatesFromJSONTyped,
    BinCoordinatesToJSON,
} from './BinCoordinates';

/**
 * 
 * @export
 * @interface ParameterHeatmapBin
 */
export interface ParameterHeatmapBin {
    /**
     * 
     * @type {BinCoordinates}
     * @memberof ParameterHeatmapBin
     */
    coordinates: BinCoordinates;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapBin
     */
    x: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapBin
     */
    y: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapBin
     */
    z: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapBin
     */
    distance: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapBin
     */
    duration: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapBin
     */
    factCount: number;
}

export function ParameterHeatmapBinFromJSON(json: any): ParameterHeatmapBin {
    return ParameterHeatmapBinFromJSONTyped(json, false);
}

export function ParameterHeatmapBinFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterHeatmapBin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'coordinates': BinCoordinatesFromJSON(json['Coordinates']),
        'x': json['X'],
        'y': json['Y'],
        'z': json['Z'],
        'distance': json['Distance'],
        'duration': json['Duration'],
        'factCount': json['FactCount'],
    };
}

export function ParameterHeatmapBinToJSON(value?: ParameterHeatmapBin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Coordinates': BinCoordinatesToJSON(value.coordinates),
        'X': value.x,
        'Y': value.y,
        'Z': value.z,
        'Distance': value.distance,
        'Duration': value.duration,
        'FactCount': value.factCount,
    };
}

