/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    FiltersDto,
    FiltersDtoFromJSON,
    FiltersDtoFromJSONTyped,
    FiltersDtoToJSON,
} from './FiltersDto';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    ServiceMessageDto,
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import {
    StandDrillingInfoDto,
    StandDrillingInfoDtoFromJSON,
    StandDrillingInfoDtoFromJSONTyped,
    StandDrillingInfoDtoToJSON,
} from './StandDrillingInfoDto';
import {
    StopWatchDto,
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';
import {
    TagBottomFingerprintTrackDetailsDto,
    TagBottomFingerprintTrackDetailsDtoFromJSON,
    TagBottomFingerprintTrackDetailsDtoFromJSONTyped,
    TagBottomFingerprintTrackDetailsDtoToJSON,
} from './TagBottomFingerprintTrackDetailsDto';

/**
 * 
 * @export
 * @interface TagBottomFingerprintFactSetDto
 */
export interface TagBottomFingerprintFactSetDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof TagBottomFingerprintFactSetDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof TagBottomFingerprintFactSetDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintFactSetDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof TagBottomFingerprintFactSetDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintFactSetDto
     */
    wellId: number;
    /**
     * 
     * @type {Array<TagBottomFingerprintTrackDetailsDto>}
     * @memberof TagBottomFingerprintFactSetDto
     */
    tracks: Array<TagBottomFingerprintTrackDetailsDto>;
    /**
     * 
     * @type {Array<StandDrillingInfoDto>}
     * @memberof TagBottomFingerprintFactSetDto
     */
    slipsToBottomStands: Array<StandDrillingInfoDto>;
    /**
     * 
     * @type {FiltersDto}
     * @memberof TagBottomFingerprintFactSetDto
     */
    availableFilters: FiltersDto;
    /**
     * 
     * @type {ResultDataState}
     * @memberof TagBottomFingerprintFactSetDto
     */
    dataState: ResultDataState;
}

export function TagBottomFingerprintFactSetDtoFromJSON(json: any): TagBottomFingerprintFactSetDto {
    return TagBottomFingerprintFactSetDtoFromJSONTyped(json, false);
}

export function TagBottomFingerprintFactSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagBottomFingerprintFactSetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': !exists(json, 'Messages') ? undefined : (json['Messages'] === null ? null : (json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'wellId': json['WellId'],
        'tracks': ((json['Tracks'] as Array<any>).map(TagBottomFingerprintTrackDetailsDtoFromJSON)),
        'slipsToBottomStands': ((json['SlipsToBottomStands'] as Array<any>).map(StandDrillingInfoDtoFromJSON)),
        'availableFilters': FiltersDtoFromJSON(json['AvailableFilters']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function TagBottomFingerprintFactSetDtoToJSON(value?: TagBottomFingerprintFactSetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value.stopWatch),
        'Messages': value.messages === undefined ? undefined : (value.messages === null ? null : (value.messages as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value.userLensId,
        'LastUpdatedAt': DateDtoToJSON(value.lastUpdatedAt),
        'WellId': value.wellId,
        'Tracks': ((value.tracks as Array<any>).map(TagBottomFingerprintTrackDetailsDtoToJSON)),
        'SlipsToBottomStands': ((value.slipsToBottomStands as Array<any>).map(StandDrillingInfoDtoToJSON)),
        'AvailableFilters': FiltersDtoToJSON(value.availableFilters),
        'DataState': ResultDataStateToJSON(value.dataState),
    };
}

