/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FiltersDto
 */
export interface FiltersDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof FiltersDto
     */
    sectionIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FiltersDto
     */
    directionalIntervalIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FiltersDto
     */
    holeSizeIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FiltersDto
     */
    crewMembers?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof FiltersDto
     */
    includeFlatTime: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FiltersDto
     */
    includeSlidingTime: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FiltersDto
     */
    includeRotatingTime: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FiltersDto
     */
    includeNullHoleDepth: boolean;
}

export function FiltersDtoFromJSON(json: any): FiltersDto {
    return FiltersDtoFromJSONTyped(json, false);
}

export function FiltersDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FiltersDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sectionIds': !exists(json, 'SectionIds') ? undefined : json['SectionIds'],
        'directionalIntervalIds': !exists(json, 'DirectionalIntervalIds') ? undefined : json['DirectionalIntervalIds'],
        'holeSizeIds': !exists(json, 'HoleSizeIds') ? undefined : json['HoleSizeIds'],
        'crewMembers': !exists(json, 'CrewMembers') ? undefined : json['CrewMembers'],
        'includeFlatTime': json['IncludeFlatTime'],
        'includeSlidingTime': json['IncludeSlidingTime'],
        'includeRotatingTime': json['IncludeRotatingTime'],
        'includeNullHoleDepth': json['IncludeNullHoleDepth'],
    };
}

export function FiltersDtoToJSON(value?: FiltersDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'SectionIds': value.sectionIds,
        'DirectionalIntervalIds': value.directionalIntervalIds,
        'HoleSizeIds': value.holeSizeIds,
        'CrewMembers': value.crewMembers,
        'IncludeFlatTime': value.includeFlatTime,
        'IncludeSlidingTime': value.includeSlidingTime,
        'IncludeRotatingTime': value.includeRotatingTime,
        'IncludeNullHoleDepth': value.includeNullHoleDepth,
    };
}

