/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DimensionType,
    DimensionTypeFromJSON,
    DimensionTypeFromJSONTyped,
    DimensionTypeToJSON,
} from './DimensionType';
import {
    TrackFactType,
    TrackFactTypeFromJSON,
    TrackFactTypeFromJSONTyped,
    TrackFactTypeToJSON,
} from './TrackFactType';

/**
 * 
 * @export
 * @interface TrackDto
 */
export interface TrackDto {
    /**
     * 
     * @type {number}
     * @memberof TrackDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TrackDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrackDto
     */
    description?: string | null;
    /**
     * 
     * @type {DimensionType}
     * @memberof TrackDto
     */
    dimension: DimensionType;
    /**
     * 
     * @type {TrackFactType}
     * @memberof TrackDto
     */
    factType: TrackFactType;
}

export function TrackDtoFromJSON(json: any): TrackDto {
    return TrackDtoFromJSONTyped(json, false);
}

export function TrackDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrackDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': !exists(json, 'Name') ? undefined : json['Name'],
        'description': !exists(json, 'Description') ? undefined : json['Description'],
        'dimension': DimensionTypeFromJSON(json['Dimension']),
        'factType': TrackFactTypeFromJSON(json['FactType']),
    };
}

export function TrackDtoToJSON(value?: TrackDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'Name': value.name,
        'Description': value.description,
        'Dimension': DimensionTypeToJSON(value.dimension),
        'FactType': TrackFactTypeToJSON(value.factType),
    };
}

