import type { CheckboxChangeEvent } from "antd/lib/checkbox";
import type { IFilterOptions } from "components/WellDashboard/ControlHeader/atoms/Filters";
import type { FC } from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "utils/colors";
import { Checkbox, Col, Row } from "utils/componentLibrary";
import { PdCol } from "utils/componentLibrary/Col";
import { CheckboxState } from "utils/enums";

const ItemsRow = styled(Row)`
  ${PdCol}:not(:last-of-type) {
    padding-bottom: 16px;
  }
`;

const FilterSection = styled.div`
  width: 250px;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 4px;
  flex-direction: column;
  display: flex;
  border: solid 1px ${({ theme }) => theme.themeStyle.colors.soft_accent};
  padding: 16px;
`;

const CommonCheckbox = styled(Checkbox)`
  padding-bottom: 16px;
  font-weight: bold;
`;

interface FilterGroup {
  options?: IFilterOptions[];
  onChange?: (key: string | number, e: CheckboxChangeEvent) => void;
  onChangeGroup?: (e: CheckboxChangeEvent) => void;
  label?: string;
  filterValues?: Array<string | number>;
}

const SelectFilter: FC<FilterGroup> = ({ options, onChange, onChangeGroup, label, filterValues }) => {
  const [commonCheckbox, setCommonCheckbox] = useState(CheckboxState.None);

  useEffect(() => {
    if (!options || !filterValues) return;
    if (options.every((option) => filterValues.includes(option.value))) setCommonCheckbox(CheckboxState.Selected);
    else if (options.some((option) => filterValues.includes(option.value))) setCommonCheckbox(CheckboxState.Partially);
    else setCommonCheckbox(CheckboxState.None);
  }, [filterValues, options]);

  return (
    <FilterSection>
      <CommonCheckbox
        indeterminate={commonCheckbox === CheckboxState.Partially}
        checked={commonCheckbox === CheckboxState.Selected}
        onChange={(e) => {
          setCommonCheckbox(e.target.checked ? CheckboxState.Selected : CheckboxState.None);
          if (onChangeGroup) onChangeGroup(e);
        }}
      >
        {label}
      </CommonCheckbox>
      <Checkbox.Group value={filterValues}>
        <ItemsRow>
          {options?.map((e) => (
            <Col key={e.value} span={24}>
              <Checkbox
                value={e.value}
                style={{
                  color: e.gray ? colors.gray : colors.neutral,
                }}
                onChange={(checkboxEvent) => {
                  if (onChange) onChange(e.value, checkboxEvent);
                }}
              >
                {e.label}
              </Checkbox>
            </Col>
          ))}
        </ItemsRow>
      </Checkbox.Group>
    </FilterSection>
  );
};

export default SelectFilter;
