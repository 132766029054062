/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WellKpiInfoDto,
    WellKpiInfoDtoFromJSON,
    WellKpiInfoDtoFromJSONTyped,
    WellKpiInfoDtoToJSON,
} from './WellKpiInfoDto';

/**
 * 
 * @export
 * @interface AllWellsKpiDto
 */
export interface AllWellsKpiDto {
    /**
     * 
     * @type {WellKpiInfoDto}
     * @memberof AllWellsKpiDto
     */
    drillingProductivity: WellKpiInfoDto;
    /**
     * 
     * @type {WellKpiInfoDto}
     * @memberof AllWellsKpiDto
     */
    avgWellDuration: WellKpiInfoDto;
    /**
     * 
     * @type {WellKpiInfoDto}
     * @memberof AllWellsKpiDto
     */
    avgTotalDrillingDistance: WellKpiInfoDto;
    /**
     * 
     * @type {WellKpiInfoDto}
     * @memberof AllWellsKpiDto
     */
    avgTotalDepth: WellKpiInfoDto;
    /**
     * 
     * @type {WellKpiInfoDto}
     * @memberof AllWellsKpiDto
     */
    avgWeightToWeight: WellKpiInfoDto;
    /**
     * 
     * @type {WellKpiInfoDto}
     * @memberof AllWellsKpiDto
     */
    avgSlipsToSlips: WellKpiInfoDto;
    /**
     * 
     * @type {WellKpiInfoDto}
     * @memberof AllWellsKpiDto
     */
    avgOnBottomRop: WellKpiInfoDto;
    /**
     * 
     * @type {WellKpiInfoDto}
     * @memberof AllWellsKpiDto
     */
    avgTrippingRunningSpeed: WellKpiInfoDto;
}

export function AllWellsKpiDtoFromJSON(json: any): AllWellsKpiDto {
    return AllWellsKpiDtoFromJSONTyped(json, false);
}

export function AllWellsKpiDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllWellsKpiDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'drillingProductivity': WellKpiInfoDtoFromJSON(json['DrillingProductivity']),
        'avgWellDuration': WellKpiInfoDtoFromJSON(json['AvgWellDuration']),
        'avgTotalDrillingDistance': WellKpiInfoDtoFromJSON(json['AvgTotalDrillingDistance']),
        'avgTotalDepth': WellKpiInfoDtoFromJSON(json['AvgTotalDepth']),
        'avgWeightToWeight': WellKpiInfoDtoFromJSON(json['AvgWeightToWeight']),
        'avgSlipsToSlips': WellKpiInfoDtoFromJSON(json['AvgSlipsToSlips']),
        'avgOnBottomRop': WellKpiInfoDtoFromJSON(json['AvgOnBottomRop']),
        'avgTrippingRunningSpeed': WellKpiInfoDtoFromJSON(json['AvgTrippingRunningSpeed']),
    };
}

export function AllWellsKpiDtoToJSON(value?: AllWellsKpiDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DrillingProductivity': WellKpiInfoDtoToJSON(value.drillingProductivity),
        'AvgWellDuration': WellKpiInfoDtoToJSON(value.avgWellDuration),
        'AvgTotalDrillingDistance': WellKpiInfoDtoToJSON(value.avgTotalDrillingDistance),
        'AvgTotalDepth': WellKpiInfoDtoToJSON(value.avgTotalDepth),
        'AvgWeightToWeight': WellKpiInfoDtoToJSON(value.avgWeightToWeight),
        'AvgSlipsToSlips': WellKpiInfoDtoToJSON(value.avgSlipsToSlips),
        'AvgOnBottomRop': WellKpiInfoDtoToJSON(value.avgOnBottomRop),
        'AvgTrippingRunningSpeed': WellKpiInfoDtoToJSON(value.avgTrippingRunningSpeed),
    };
}

