/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BatteryStateFactSummaryDto
 */
export interface BatteryStateFactSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof BatteryStateFactSummaryDto
     */
    totalEnergyInKWh: number;
    /**
     * 
     * @type {number}
     * @memberof BatteryStateFactSummaryDto
     */
    totalEnergyOutKWh: number;
}

export function BatteryStateFactSummaryDtoFromJSON(json: any): BatteryStateFactSummaryDto {
    return BatteryStateFactSummaryDtoFromJSONTyped(json, false);
}

export function BatteryStateFactSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatteryStateFactSummaryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalEnergyInKWh': json['TotalEnergyInKWh'],
        'totalEnergyOutKWh': json['TotalEnergyOutKWh'],
    };
}

export function BatteryStateFactSummaryDtoToJSON(value?: BatteryStateFactSummaryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'TotalEnergyInKWh': value.totalEnergyInKWh,
        'TotalEnergyOutKWh': value.totalEnergyOutKWh,
    };
}

