/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BarSliceDto,
    BarSliceDtoFromJSON,
    BarSliceDtoFromJSONTyped,
    BarSliceDtoToJSON,
} from './BarSliceDto';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    PivotKey,
    PivotKeyFromJSON,
    PivotKeyFromJSONTyped,
    PivotKeyToJSON,
} from './PivotKey';
import {
    PivotType,
    PivotTypeFromJSON,
    PivotTypeFromJSONTyped,
    PivotTypeToJSON,
} from './PivotType';
import {
    ShiftType,
    ShiftTypeFromJSON,
    ShiftTypeFromJSONTyped,
    ShiftTypeToJSON,
} from './ShiftType';

/**
 * 
 * @export
 * @interface PivotBarDto
 */
export interface PivotBarDto {
    /**
     * 
     * @type {PivotKey}
     * @memberof PivotBarDto
     */
    key: PivotKey;
    /**
     * 
     * @type {PivotType}
     * @memberof PivotBarDto
     */
    pivotType: PivotType;
    /**
     * 
     * @type {number}
     * @memberof PivotBarDto
     */
    averageValue: number;
    /**
     * 
     * @type {number}
     * @memberof PivotBarDto
     */
    focalAverage: number;
    /**
     * 
     * @type {number}
     * @memberof PivotBarDto
     */
    comparisonAverage: number;
    /**
     * 
     * @type {number}
     * @memberof PivotBarDto
     */
    comparisonAverageRelativeDiff: number;
    /**
     * 
     * @type {number}
     * @memberof PivotBarDto
     */
    operationCount: number;
    /**
     * 
     * @type {number}
     * @memberof PivotBarDto
     */
    targetValue?: number | null;
    /**
     * 
     * @type {Array<BarSliceDto>}
     * @memberof PivotBarDto
     */
    slices: Array<BarSliceDto>;
    /**
     * 
     * @type {number}
     * @memberof PivotBarDto
     */
    readonly totalValue: number;
    /**
     * 
     * @type {boolean}
     * @memberof PivotBarDto
     */
    isOutlier: boolean;
    /**
     * 
     * @type {ShiftType}
     * @memberof PivotBarDto
     */
    shift: ShiftType;
    /**
     * 
     * @type {DateDto}
     * @memberof PivotBarDto
     */
    referenceDate: DateDto;
}

export function PivotBarDtoFromJSON(json: any): PivotBarDto {
    return PivotBarDtoFromJSONTyped(json, false);
}

export function PivotBarDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PivotBarDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': PivotKeyFromJSON(json['Key']),
        'pivotType': PivotTypeFromJSON(json['PivotType']),
        'averageValue': json['AverageValue'],
        'focalAverage': json['FocalAverage'],
        'comparisonAverage': json['ComparisonAverage'],
        'comparisonAverageRelativeDiff': json['ComparisonAverageRelativeDiff'],
        'operationCount': json['OperationCount'],
        'targetValue': !exists(json, 'TargetValue') ? undefined : json['TargetValue'],
        'slices': ((json['Slices'] as Array<any>).map(BarSliceDtoFromJSON)),
        'totalValue': json['TotalValue'],
        'isOutlier': json['IsOutlier'],
        'shift': ShiftTypeFromJSON(json['Shift']),
        'referenceDate': DateDtoFromJSON(json['ReferenceDate']),
    };
}

export function PivotBarDtoToJSON(value?: PivotBarDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Key': PivotKeyToJSON(value.key),
        'PivotType': PivotTypeToJSON(value.pivotType),
        'AverageValue': value.averageValue,
        'FocalAverage': value.focalAverage,
        'ComparisonAverage': value.comparisonAverage,
        'ComparisonAverageRelativeDiff': value.comparisonAverageRelativeDiff,
        'OperationCount': value.operationCount,
        'TargetValue': value.targetValue,
        'Slices': ((value.slices as Array<any>).map(BarSliceDtoToJSON)),
        'IsOutlier': value.isOutlier,
        'Shift': ShiftTypeToJSON(value.shift),
        'ReferenceDate': DateDtoToJSON(value.referenceDate),
    };
}

