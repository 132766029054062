/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RigLeaderboardListAco,
    RigLeaderboardListAcoFromJSON,
    RigLeaderboardListAcoFromJSONTyped,
    RigLeaderboardListAcoToJSON,
} from './RigLeaderboardListAco';
import {
    StopWatchDto,
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';

/**
 * 
 * @export
 * @interface ClaraSessionDto
 */
export interface ClaraSessionDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof ClaraSessionDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {string}
     * @memberof ClaraSessionDto
     */
    sessionId: string;
    /**
     * 
     * @type {RigLeaderboardListAco}
     * @memberof ClaraSessionDto
     */
    context: RigLeaderboardListAco;
}

export function ClaraSessionDtoFromJSON(json: any): ClaraSessionDto {
    return ClaraSessionDtoFromJSONTyped(json, false);
}

export function ClaraSessionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClaraSessionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'sessionId': json['SessionId'],
        'context': RigLeaderboardListAcoFromJSON(json['Context']),
    };
}

export function ClaraSessionDtoToJSON(value?: ClaraSessionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value.stopWatch),
        'SessionId': value.sessionId,
        'Context': RigLeaderboardListAcoToJSON(value.context),
    };
}

