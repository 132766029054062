/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PowerLoadefficiencyFactDto
 */
export interface PowerLoadefficiencyFactDto {
    /**
     * 
     * @type {number}
     * @memberof PowerLoadefficiencyFactDto
     */
    consumedEnergyInKWh: number;
    /**
     * 
     * @type {number}
     * @memberof PowerLoadefficiencyFactDto
     */
    totalCapacityInKWh: number;
    /**
     * 
     * @type {number}
     * @memberof PowerLoadefficiencyFactDto
     */
    readonly proportion: number;
}

export function PowerLoadefficiencyFactDtoFromJSON(json: any): PowerLoadefficiencyFactDto {
    return PowerLoadefficiencyFactDtoFromJSONTyped(json, false);
}

export function PowerLoadefficiencyFactDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PowerLoadefficiencyFactDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'consumedEnergyInKWh': json['ConsumedEnergyInKWh'],
        'totalCapacityInKWh': json['TotalCapacityInKWh'],
        'proportion': json['Proportion'],
    };
}

export function PowerLoadefficiencyFactDtoToJSON(value?: PowerLoadefficiencyFactDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ConsumedEnergyInKWh': value.consumedEnergyInKWh,
        'TotalCapacityInKWh': value.totalCapacityInKWh,
    };
}

