import { Button } from "atoms/Form";
import { getTitleRealTimeIndicator } from "components/DetailsTopBar";
import { RealTimeDataEnum, RealTimeIndicator } from "components/RealTimeIndicator";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { Track } from "services/Mixpanel";
import { Tooltip } from "utils/componentLibrary";

export function RealtimeIndicator() {
  const [realTimeDataState, setRealtimeData] = useStateQuery<RealTimeDataEnum>(
    URL_STATE_PARAM.REALTIME_DATA_DASHBOARD,
    RealTimeDataEnum.UNAVAILABLE,
    [URL_STATE_PARAM.REALTIME_DATA_WIDGET],
  );

  return (
    <Tooltip title={getTitleRealTimeIndicator(realTimeDataState)}>
      <Button
        size="large"
        onClick={() => {
          if (realTimeDataState === RealTimeDataEnum.UNAVAILABLE) {
            Track.interact("Well Dashboard - Real Time", { State: "Unavailable" });
            return;
          }
          Track.interact("Well Dashboard - Real Time", {
            State: realTimeDataState === RealTimeDataEnum.ACTIVE ? "Disabled" : "Active",
          });
          setRealtimeData(
            realTimeDataState === RealTimeDataEnum.ACTIVE ? RealTimeDataEnum.DISABLED : RealTimeDataEnum.ACTIVE,
          );
        }}
        icon={<RealTimeIndicator realTimeDataState={realTimeDataState} />}
      />
    </Tooltip>
  );
}
