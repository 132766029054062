/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StandKpiSliceDto,
    StandKpiSliceDtoFromJSON,
    StandKpiSliceDtoFromJSONTyped,
    StandKpiSliceDtoToJSON,
} from './StandKpiSliceDto';

/**
 * 
 * @export
 * @interface StackedStandKpiComparisonInfoDto
 */
export interface StackedStandKpiComparisonInfoDto {
    /**
     * 
     * @type {number}
     * @memberof StackedStandKpiComparisonInfoDto
     */
    id: number;
    /**
     * 
     * @type {Array<StandKpiSliceDto>}
     * @memberof StackedStandKpiComparisonInfoDto
     */
    slices?: Array<StandKpiSliceDto> | null;
    /**
     * 
     * @type {number}
     * @memberof StackedStandKpiComparisonInfoDto
     */
    average: number;
    /**
     * 
     * @type {number}
     * @memberof StackedStandKpiComparisonInfoDto
     */
    targetAverage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StackedStandKpiComparisonInfoDto
     */
    operationCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof StackedStandKpiComparisonInfoDto
     */
    isOutlier: boolean;
}

export function StackedStandKpiComparisonInfoDtoFromJSON(json: any): StackedStandKpiComparisonInfoDto {
    return StackedStandKpiComparisonInfoDtoFromJSONTyped(json, false);
}

export function StackedStandKpiComparisonInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StackedStandKpiComparisonInfoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'slices': !exists(json, 'Slices') ? undefined : (json['Slices'] === null ? null : (json['Slices'] as Array<any>).map(StandKpiSliceDtoFromJSON)),
        'average': json['Average'],
        'targetAverage': !exists(json, 'TargetAverage') ? undefined : json['TargetAverage'],
        'operationCount': json['OperationCount'],
        'isOutlier': json['IsOutlier'],
    };
}

export function StackedStandKpiComparisonInfoDtoToJSON(value?: StackedStandKpiComparisonInfoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'Slices': value.slices === undefined ? undefined : (value.slices === null ? null : (value.slices as Array<any>).map(StandKpiSliceDtoToJSON)),
        'Average': value.average,
        'TargetAverage': value.targetAverage,
        'OperationCount': value.operationCount,
        'IsOutlier': value.isOutlier,
    };
}

