/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    DrillingTaskSummary,
    DrillingTaskSummaryFromJSON,
    DrillingTaskSummaryFromJSONTyped,
    DrillingTaskSummaryToJSON,
} from './DrillingTaskSummary';
import {
    FiltersDto,
    FiltersDtoFromJSON,
    FiltersDtoFromJSONTyped,
    FiltersDtoToJSON,
} from './FiltersDto';
import {
    Int32Int64FactIndicatorSetDto,
    Int32Int64FactIndicatorSetDtoFromJSON,
    Int32Int64FactIndicatorSetDtoFromJSONTyped,
    Int32Int64FactIndicatorSetDtoToJSON,
} from './Int32Int64FactIndicatorSetDto';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    ServiceMessageDto,
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import {
    SingleStandKpiDetailByDateDto,
    SingleStandKpiDetailByDateDtoFromJSON,
    SingleStandKpiDetailByDateDtoFromJSONTyped,
    SingleStandKpiDetailByDateDtoToJSON,
} from './SingleStandKpiDetailByDateDto';
import {
    SingleStandKpiDetailByDateShiftDto,
    SingleStandKpiDetailByDateShiftDtoFromJSON,
    SingleStandKpiDetailByDateShiftDtoFromJSONTyped,
    SingleStandKpiDetailByDateShiftDtoToJSON,
} from './SingleStandKpiDetailByDateShiftDto';
import {
    SingleStandKpiDetailByShiftDto,
    SingleStandKpiDetailByShiftDtoFromJSON,
    SingleStandKpiDetailByShiftDtoFromJSONTyped,
    SingleStandKpiDetailByShiftDtoToJSON,
} from './SingleStandKpiDetailByShiftDto';
import {
    SingleStandKpiSummaryDto,
    SingleStandKpiSummaryDtoFromJSON,
    SingleStandKpiSummaryDtoFromJSONTyped,
    SingleStandKpiSummaryDtoToJSON,
} from './SingleStandKpiSummaryDto';
import {
    StandKpiDetailsDto,
    StandKpiDetailsDtoFromJSON,
    StandKpiDetailsDtoFromJSONTyped,
    StandKpiDetailsDtoToJSON,
} from './StandKpiDetailsDto';
import {
    StopWatchDto,
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';

/**
 * 
 * @export
 * @interface SingleStandKpiDto
 */
export interface SingleStandKpiDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof SingleStandKpiDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof SingleStandKpiDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof SingleStandKpiDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {FiltersDto}
     * @memberof SingleStandKpiDto
     */
    availableFilters: FiltersDto;
    /**
     * 
     * @type {SingleStandKpiSummaryDto}
     * @memberof SingleStandKpiDto
     */
    summary: SingleStandKpiSummaryDto;
    /**
     * 
     * @type {DrillingTaskSummary}
     * @memberof SingleStandKpiDto
     */
    drillingTaskSummary: DrillingTaskSummary;
    /**
     * 
     * @type {Array<StandKpiDetailsDto>}
     * @memberof SingleStandKpiDto
     */
    detailsByStandDepth?: Array<StandKpiDetailsDto> | null;
    /**
     * 
     * @type {Array<SingleStandKpiDetailByDateDto>}
     * @memberof SingleStandKpiDto
     */
    detailsByDate?: Array<SingleStandKpiDetailByDateDto> | null;
    /**
     * 
     * @type {Array<SingleStandKpiDetailByDateShiftDto>}
     * @memberof SingleStandKpiDto
     */
    detailsByDateShift?: Array<SingleStandKpiDetailByDateShiftDto> | null;
    /**
     * 
     * @type {SingleStandKpiDetailByShiftDto}
     * @memberof SingleStandKpiDto
     */
    detailsByShift: SingleStandKpiDetailByShiftDto;
    /**
     * 
     * @type {Int32Int64FactIndicatorSetDto}
     * @memberof SingleStandKpiDto
     */
    indicators: Int32Int64FactIndicatorSetDto;
    /**
     * 
     * @type {ResultDataState}
     * @memberof SingleStandKpiDto
     */
    dataState: ResultDataState;
}

export function SingleStandKpiDtoFromJSON(json: any): SingleStandKpiDto {
    return SingleStandKpiDtoFromJSONTyped(json, false);
}

export function SingleStandKpiDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SingleStandKpiDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': !exists(json, 'Messages') ? undefined : (json['Messages'] === null ? null : (json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'availableFilters': FiltersDtoFromJSON(json['AvailableFilters']),
        'summary': SingleStandKpiSummaryDtoFromJSON(json['Summary']),
        'drillingTaskSummary': DrillingTaskSummaryFromJSON(json['DrillingTaskSummary']),
        'detailsByStandDepth': !exists(json, 'DetailsByStandDepth') ? undefined : (json['DetailsByStandDepth'] === null ? null : (json['DetailsByStandDepth'] as Array<any>).map(StandKpiDetailsDtoFromJSON)),
        'detailsByDate': !exists(json, 'DetailsByDate') ? undefined : (json['DetailsByDate'] === null ? null : (json['DetailsByDate'] as Array<any>).map(SingleStandKpiDetailByDateDtoFromJSON)),
        'detailsByDateShift': !exists(json, 'DetailsByDateShift') ? undefined : (json['DetailsByDateShift'] === null ? null : (json['DetailsByDateShift'] as Array<any>).map(SingleStandKpiDetailByDateShiftDtoFromJSON)),
        'detailsByShift': SingleStandKpiDetailByShiftDtoFromJSON(json['DetailsByShift']),
        'indicators': Int32Int64FactIndicatorSetDtoFromJSON(json['Indicators']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function SingleStandKpiDtoToJSON(value?: SingleStandKpiDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value.stopWatch),
        'Messages': value.messages === undefined ? undefined : (value.messages === null ? null : (value.messages as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value.userLensId,
        'LastUpdatedAt': DateDtoToJSON(value.lastUpdatedAt),
        'AvailableFilters': FiltersDtoToJSON(value.availableFilters),
        'Summary': SingleStandKpiSummaryDtoToJSON(value.summary),
        'DrillingTaskSummary': DrillingTaskSummaryToJSON(value.drillingTaskSummary),
        'DetailsByStandDepth': value.detailsByStandDepth === undefined ? undefined : (value.detailsByStandDepth === null ? null : (value.detailsByStandDepth as Array<any>).map(StandKpiDetailsDtoToJSON)),
        'DetailsByDate': value.detailsByDate === undefined ? undefined : (value.detailsByDate === null ? null : (value.detailsByDate as Array<any>).map(SingleStandKpiDetailByDateDtoToJSON)),
        'DetailsByDateShift': value.detailsByDateShift === undefined ? undefined : (value.detailsByDateShift === null ? null : (value.detailsByDateShift as Array<any>).map(SingleStandKpiDetailByDateShiftDtoToJSON)),
        'DetailsByShift': SingleStandKpiDetailByShiftDtoToJSON(value.detailsByShift),
        'Indicators': Int32Int64FactIndicatorSetDtoToJSON(value.indicators),
        'DataState': ResultDataStateToJSON(value.dataState),
    };
}

