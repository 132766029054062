/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserLensPositionDto
 */
export interface UserLensPositionDto {
    /**
     * 
     * @type {number}
     * @memberof UserLensPositionDto
     */
    lensId: number;
    /**
     * 
     * @type {number}
     * @memberof UserLensPositionDto
     */
    position: number;
    /**
     * 
     * @type {number}
     * @memberof UserLensPositionDto
     */
    height: number;
    /**
     * 
     * @type {number}
     * @memberof UserLensPositionDto
     */
    width: number;
}

export function UserLensPositionDtoFromJSON(json: any): UserLensPositionDto {
    return UserLensPositionDtoFromJSONTyped(json, false);
}

export function UserLensPositionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserLensPositionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lensId': json['LensId'],
        'position': json['Position'],
        'height': json['Height'],
        'width': json['Width'],
    };
}

export function UserLensPositionDtoToJSON(value?: UserLensPositionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'LensId': value.lensId,
        'Position': value.position,
        'Height': value.height,
        'Width': value.width,
    };
}

