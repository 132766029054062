/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const NotificationType = {
    None: 'None',
    ScorecardComment: 'ScorecardComment',
    ExceptionRequest: 'ExceptionRequest'
} as const;
export type NotificationType = typeof NotificationType[keyof typeof NotificationType];


export function NotificationTypeFromJSON(json: any): NotificationType {
    return NotificationTypeFromJSONTyped(json, false);
}

export function NotificationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationType {
    return json as NotificationType;
}

export function NotificationTypeToJSON(value?: NotificationType | null): any {
    return value as any;
}

