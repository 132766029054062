/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DataFeedWellDatesDto,
    DataFeedWellDatesDtoFromJSON,
    DataFeedWellDatesDtoFromJSONTyped,
    DataFeedWellDatesDtoToJSON,
} from './DataFeedWellDatesDto';

/**
 * 
 * @export
 * @interface FeedUpdateRequestDto
 */
export interface FeedUpdateRequestDto {
    /**
     * 
     * @type {Array<DataFeedWellDatesDto>}
     * @memberof FeedUpdateRequestDto
     */
    wellDates: Array<DataFeedWellDatesDto>;
}

export function FeedUpdateRequestDtoFromJSON(json: any): FeedUpdateRequestDto {
    return FeedUpdateRequestDtoFromJSONTyped(json, false);
}

export function FeedUpdateRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedUpdateRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'wellDates': ((json['WellDates'] as Array<any>).map(DataFeedWellDatesDtoFromJSON)),
    };
}

export function FeedUpdateRequestDtoToJSON(value?: FeedUpdateRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'WellDates': ((value.wellDates as Array<any>).map(DataFeedWellDatesDtoToJSON)),
    };
}

