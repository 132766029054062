/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClaraEndpointType,
    ClaraEndpointTypeFromJSON,
    ClaraEndpointTypeFromJSONTyped,
    ClaraEndpointTypeToJSON,
} from './ClaraEndpointType';
import {
    ClarityUiType,
    ClarityUiTypeFromJSON,
    ClarityUiTypeFromJSONTyped,
    ClarityUiTypeToJSON,
} from './ClarityUiType';
import {
    SystemOfMeasurementType,
    SystemOfMeasurementTypeFromJSON,
    SystemOfMeasurementTypeFromJSONTyped,
    SystemOfMeasurementTypeToJSON,
} from './SystemOfMeasurementType';
import {
    UiThemeType,
    UiThemeTypeFromJSON,
    UiThemeTypeFromJSONTyped,
    UiThemeTypeToJSON,
} from './UiThemeType';
import {
    UserRoleType,
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
} from './UserRoleType';
import {
    UserStatusType,
    UserStatusTypeFromJSON,
    UserStatusTypeFromJSONTyped,
    UserStatusTypeToJSON,
} from './UserStatusType';

/**
 * 
 * @export
 * @interface UserWithAccessCountDto
 */
export interface UserWithAccessCountDto {
    /**
     * 
     * @type {number}
     * @memberof UserWithAccessCountDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserWithAccessCountDto
     */
    externalId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserWithAccessCountDto
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithAccessCountDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithAccessCountDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithAccessCountDto
     */
    jobTitle?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UserWithAccessCountDto
     */
    expirationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof UserWithAccessCountDto
     */
    operatorId: number;
    /**
     * 
     * @type {number}
     * @memberof UserWithAccessCountDto
     */
    activeProfileId: number;
    /**
     * 
     * @type {UserRoleType}
     * @memberof UserWithAccessCountDto
     */
    role: UserRoleType;
    /**
     * 
     * @type {UserStatusType}
     * @memberof UserWithAccessCountDto
     */
    status: UserStatusType;
    /**
     * 
     * @type {SystemOfMeasurementType}
     * @memberof UserWithAccessCountDto
     */
    systemOfMeasurement: SystemOfMeasurementType;
    /**
     * 
     * @type {UiThemeType}
     * @memberof UserWithAccessCountDto
     */
    uiTheme: UiThemeType;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithAccessCountDto
     */
    canAccessCrew: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithAccessCountDto
     */
    canAccessRigScorecard: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithAccessCountDto
     */
    canSelectClaraEndpoint: boolean;
    /**
     * 
     * @type {ClarityUiType}
     * @memberof UserWithAccessCountDto
     */
    uiType: ClarityUiType;
    /**
     * 
     * @type {Date}
     * @memberof UserWithAccessCountDto
     */
    createdAtUtc?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithAccessCountDto
     */
    isMfaDisabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithAccessCountDto
     */
    isApprover: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithAccessCountDto
     */
    isInDemoMode: boolean;
    /**
     * 
     * @type {ClaraEndpointType}
     * @memberof UserWithAccessCountDto
     */
    claraEndpoint: ClaraEndpointType;
    /**
     * 
     * @type {number}
     * @memberof UserWithAccessCountDto
     */
    operatorWellCount: number;
    /**
     * 
     * @type {number}
     * @memberof UserWithAccessCountDto
     */
    claimedWellCount: number;
    /**
     * 
     * @type {number}
     * @memberof UserWithAccessCountDto
     */
    claimedRigCount: number;
}

export function UserWithAccessCountDtoFromJSON(json: any): UserWithAccessCountDto {
    return UserWithAccessCountDtoFromJSONTyped(json, false);
}

export function UserWithAccessCountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserWithAccessCountDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'externalId': !exists(json, 'ExternalId') ? undefined : json['ExternalId'],
        'userName': json['UserName'],
        'firstName': json['FirstName'],
        'lastName': json['LastName'],
        'jobTitle': !exists(json, 'JobTitle') ? undefined : json['JobTitle'],
        'expirationDate': !exists(json, 'ExpirationDate') ? undefined : (json['ExpirationDate'] === null ? null : new Date(json['ExpirationDate'])),
        'operatorId': json['OperatorId'],
        'activeProfileId': json['ActiveProfileId'],
        'role': UserRoleTypeFromJSON(json['Role']),
        'status': UserStatusTypeFromJSON(json['Status']),
        'systemOfMeasurement': SystemOfMeasurementTypeFromJSON(json['SystemOfMeasurement']),
        'uiTheme': UiThemeTypeFromJSON(json['UiTheme']),
        'canAccessCrew': json['CanAccessCrew'],
        'canAccessRigScorecard': json['CanAccessRigScorecard'],
        'canSelectClaraEndpoint': json['CanSelectClaraEndpoint'],
        'uiType': ClarityUiTypeFromJSON(json['UiType']),
        'createdAtUtc': !exists(json, 'CreatedAtUtc') ? undefined : (json['CreatedAtUtc'] === null ? null : new Date(json['CreatedAtUtc'])),
        'isMfaDisabled': json['IsMfaDisabled'],
        'isApprover': json['IsApprover'],
        'isInDemoMode': json['IsInDemoMode'],
        'claraEndpoint': ClaraEndpointTypeFromJSON(json['ClaraEndpoint']),
        'operatorWellCount': json['OperatorWellCount'],
        'claimedWellCount': json['ClaimedWellCount'],
        'claimedRigCount': json['ClaimedRigCount'],
    };
}

export function UserWithAccessCountDtoToJSON(value?: UserWithAccessCountDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'ExternalId': value.externalId,
        'UserName': value.userName,
        'FirstName': value.firstName,
        'LastName': value.lastName,
        'JobTitle': value.jobTitle,
        'ExpirationDate': value.expirationDate === undefined ? undefined : (value.expirationDate === null ? null : value.expirationDate.toISOString()),
        'OperatorId': value.operatorId,
        'ActiveProfileId': value.activeProfileId,
        'Role': UserRoleTypeToJSON(value.role),
        'Status': UserStatusTypeToJSON(value.status),
        'SystemOfMeasurement': SystemOfMeasurementTypeToJSON(value.systemOfMeasurement),
        'UiTheme': UiThemeTypeToJSON(value.uiTheme),
        'CanAccessCrew': value.canAccessCrew,
        'CanAccessRigScorecard': value.canAccessRigScorecard,
        'CanSelectClaraEndpoint': value.canSelectClaraEndpoint,
        'UiType': ClarityUiTypeToJSON(value.uiType),
        'CreatedAtUtc': value.createdAtUtc === undefined ? undefined : (value.createdAtUtc === null ? null : value.createdAtUtc.toISOString()),
        'IsMfaDisabled': value.isMfaDisabled,
        'IsApprover': value.isApprover,
        'IsInDemoMode': value.isInDemoMode,
        'ClaraEndpoint': ClaraEndpointTypeToJSON(value.claraEndpoint),
        'OperatorWellCount': value.operatorWellCount,
        'ClaimedWellCount': value.claimedWellCount,
        'ClaimedRigCount': value.claimedRigCount,
    };
}

