import type { RigSummaryDto } from "apis/oag";
import { SCROLLBAR_GAP_AROUND } from "components/Layout/LayoutUtils";
import NoData from "components/Lenses/common/NoData";
import { Loader } from "components/Loader";
import { useOperators } from "hooks/useOperators";
import { useRigSort } from "hooks/useRigSort";
import { useRigSummaries } from "hooks/useRigSummaries";
import { RigListRow } from "pages/AllRigs/RigList/RigListRow";
import { memo, useMemo } from "react";

import {
  StyledContainer,
  StyledMatchingRigsText,
  StyledNoDataContainer,
  StyledSpinnerContainer,
  StyledTextNoDataMessage,
  StyledWrapper,
} from "./styles";
import { PDComponent } from "components/PDComponents";

const ROW_HEIGHT = 55;
const TOP_DOWN_PADDING = 4;
const RIGHT_MARGIN = 16;

export interface RigViewModel extends RigSummaryDto {
  operatorName: string;
}

export const RigList: React.FC = memo(function RigList() {
  const { data: operators, isLoading: isLoadingOperators } = useOperators({ staleTime: Infinity });
  const { data: rigs, isLoading: isLoadingSummaries } = useRigSummaries({ staleTime: Infinity });
  const sortByRigs = useRigSort();
  const viewModelData: RigViewModel[] = useMemo(() => {
    const sortedRigs = [...(rigs || [])].sort((a, b) => sortByRigs(a.id, b.id));

    return sortedRigs.map((rig) => {
      return {
        ...rig,
        operatorName: operators?.byId?.[rig.operatorId]?.name || "Unknown",
      };
    });
  }, [operators?.byId, rigs, sortByRigs]);

  const isLoading = useMemo(() => isLoadingOperators || isLoadingSummaries, [isLoadingOperators, isLoadingSummaries]);
  const isInvalidDataState = useMemo(() => isLoading || !viewModelData.length, [isLoading, viewModelData?.length]);

  return (
    <StyledWrapper>
      <StyledContainer>
        {isInvalidDataState ? (
          isLoading ? (
            <StyledSpinnerContainer>
              <Loader withWrapper />
            </StyledSpinnerContainer>
          ) : (
            <>
              <StyledNoDataContainer>
                <NoData message=" " />
              </StyledNoDataContainer>

              <StyledTextNoDataMessage>
                <span>
                  <StyledMatchingRigsText>No matching rigs found.</StyledMatchingRigsText> <br />
                  Please try another configuration.
                </span>
              </StyledTextNoDataMessage>
            </>
          )
        ) : (
          <PDComponent.VirtualizedList
            items={viewModelData}
            itemSizePx={ROW_HEIGHT + TOP_DOWN_PADDING}
            scrollbarDistance={RIGHT_MARGIN + SCROLLBAR_GAP_AROUND}
          >
            {(rig, idx) => <RigListRow rig={rig} key={`${rig.id}-${idx}`} />}
          </PDComponent.VirtualizedList>
        )}
      </StyledContainer>
    </StyledWrapper>
  );
});
