import { ErrorBoundary } from "@sentry/react";
import { Title } from "atoms/Typography";
import CoreLayout, { SectionLayout } from "components/Layout";
import { WebGLSupportBanner } from "components/WebGLSupportBanner/WebGLSupportBanner";
import AllWellsHeader from "pages/AllWells/components/AllWellsHeader";
import MapView from "pages/AllWells/components/MapView";
import WellsContainer from "pages/AllWells/components/WellsContainer";
import type { FC } from "react";
import { usePageView } from "services/Mixpanel";
import styled from "styled-components";
import { Space } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";
import { zIndexLayer } from "utils/zIndex";

import KPIsContainer from "./components/KPIsContainer";

const StyledHeaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 24px;
  height: 36px;
  gap: 8px;
  ${Title} {
    color: ${(props) => props.theme.themeStyle.colors.disabled_typography} !important;
  }
  img {
    margin-top: 2px;
  }
`;

const StyledSpace = styled(Space)`
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.themeStyle.colors.primary_bg};
`;

const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.themeStyle.colors.alt_quaterniary_bg};
  padding: 16px 32px;
  z-index: ${zIndexLayer.sky};
  box-shadow: 0 1px 3px rgb(0 0 0 / 0.2);
  position: fixed;
  width: 100%;
`;

const PaddedContainer = styled.div`
  padding-right: 16px;
`;

const StyledMapviewWrapper = styled.div`
  margin-top: 68px;
`;

const StyledSidebarWrapper = styled.div`
  height: calc(100vh - 205px);
`;

export const Evergreen: FC = () => {
  usePageView("Evergreen Wells");

  const { isDark, atomThemeVariant } = useCustomTheme();

  return (
    <>
      <StyledContainer>
        <AllWellsHeader />
      </StyledContainer>

      <StyledMapviewWrapper>
        <ErrorBoundary fallback={<WebGLSupportBanner />}>
          <MapView />
        </ErrorBoundary>
      </StyledMapviewWrapper>

      <PaddedContainer>
        <CoreLayout
          sidebar={
            <SectionLayout
              CustomChildrenWrapper={StyledSidebarWrapper}
              header={
                <StyledHeaderContainer>
                  <Title level={4} variant={atomThemeVariant}>
                    Access custom dashboards at
                  </Title>
                  <a href="https://alphaanalytics.precisiondrilling.com/login" rel="noreferrer" target="_blank">
                    <img
                      height={17}
                      width={129}
                      src={
                        isDark ? "/assets/logos/alpha-analytics-white.png" : "/assets/logos/alpha-analytics-dark.png"
                      }
                      alt="Alpha analytics"
                    />
                  </a>
                </StyledHeaderContainer>
              }
            >
              <StyledSpace align="start">
                <KPIsContainer />
              </StyledSpace>
            </SectionLayout>
          }
        >
          <WellsContainer />
        </CoreLayout>
      </PaddedContainer>
    </>
  );
};
