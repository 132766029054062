import { scaleLinear } from "@visx/scale";
import type { BoilerFuelFlowRateFactDto, FuelFlowRateFactDto } from "apis/oag";
import { DimensionType } from "apis/oag";
import { Title } from "atoms/Typography";
import { LineChart } from "components/Lenses/common/LineChart";
import { CurveType } from "components/Lenses/common/LineChart/utils";
import { TooltipFadedValue, TooltipHighlightValue } from "components/Lenses/common/Tooltip";
import { NO_DATA_KPI_STRING } from "components/Lenses/constants";
import { LensLoadingContainer } from "components/Lenses/ContainerLens/common/LensLoadingContainer";
import * as CommonStyles from "components/Lenses/ContainerLens/common/StyledComponents";
import { StyledChartContainerFlexDiv } from "components/Lenses/ContainerLens/common/StyledComponents";
import type { WithIndex } from "components/Lenses/ContainerLens/common/utils/utils";
import {
  boilerColors,
  fuelColors,
  getInternalAxisMargin,
  LATERAL_AXIS_WIDTH,
  X_AXIS_HEIGHT,
} from "components/Lenses/ContainerLens/common/utils/utils";
import { useBoilerFuelFlowRateFacts } from "components/Lenses/ContainerLens/FuelFlowRate/common/fetcher";
import { DECIMAL_THRESHOLD, LEGEND_HEIGHT } from "components/Lenses/ContainerLens/FuelFlowRate/common/utils";
import { TOOLTIP_WIDTH_EMPTY } from "components/Lenses/ContainerLens/FuelFlowRate/utils";
import type { FuelFlowRateProps } from "components/Lenses/interfaces";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import { curveStepAfter } from "d3";
import { max, min } from "d3-array";
import { useEffect, useMemo, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { Col, Row } from "utils/componentLibrary";
import { DEFAULT_DATE_FORMAT, useUOM, UtilDimensions } from "utils/format";
import { formatTime } from "utils/helper";

const LENS_TITLE = "Boiler Fuel Flow Rate";

export type FuelFlowRateFactDtoWithIndex = WithIndex<FuelFlowRateFactDto>;

enum CurveKey {
  Boiler1Diesel = "Boiler1Diesel",
  Boiler2Diesel = "Boiler2Diesel",
  BoilerGas = "BoilerGas",
}

const addIndex = (facts: BoilerFuelFlowRateFactDto[]) => (facts ?? []).map((fact, index) => ({ ...fact, index }));

export const BoilerFuelRateChart: React.FC<FuelFlowRateProps> = ({ lens, detailed, setLensDate }) => {
  const { data: fuelFLowFacts } = useBoilerFuelFlowRateFacts(lens);

  useEffect(() => {
    if (fuelFLowFacts?.lastUpdatedAt && setLensDate) setLensDate(fuelFLowFacts.lastUpdatedAt);
  }, [fuelFLowFacts.lastUpdatedAt, setLensDate]);

  const { boiler1DieselFacts, boiler2DieselFacts, boilerGasFacts } = useMemo<{
    boiler1DieselFacts: FuelFlowRateFactDtoWithIndex[];
    boiler2DieselFacts: FuelFlowRateFactDtoWithIndex[];
    boilerGasFacts: FuelFlowRateFactDtoWithIndex[];
  }>(() => {
    return {
      boiler1DieselFacts: addIndex(fuelFLowFacts?.boiler1DieselFacts || []),
      boiler2DieselFacts: addIndex(fuelFLowFacts?.boiler2DieselFacts || []),
      boilerGasFacts: addIndex(fuelFLowFacts?.boilerGasFacts || []),
    };
  }, [fuelFLowFacts]);

  const natGasUom = useUOM(UtilDimensions.ALTERNATE_CubicMetresPerSecond);
  const dieselUom = useUOM(DimensionType.LitresPerSecond);

  const availableSeries = useMemo<FuelFlowRateFactDtoWithIndex[]>(
    () =>
      [...boiler2DieselFacts, ...boiler1DieselFacts, ...boilerGasFacts].sort(
        (a, b) => a.at.utc.getTime() - b.at.utc.getTime(),
      ),
    [boiler2DieselFacts, boiler1DieselFacts, boilerGasFacts],
  );

  const { width: chartWidthHook, height: chartHeightHook, ref: containerRef } = useResizeDetector();
  const { chartWidth, chartHeight } = {
    chartHeight: getSVGNormalizedValue(chartHeightHook ?? 0 - LEGEND_HEIGHT),
    chartWidth: getSVGNormalizedValue(chartWidthHook),
  };

  const plotWidth = getSVGNormalizedValue(chartWidth - 2 * LATERAL_AXIS_WIDTH);
  const plotHeight = getSVGNormalizedValue(chartHeight - X_AXIS_HEIGHT);

  const xScale = useMemo(() => {
    const startX = LATERAL_AXIS_WIDTH;
    const timeSeries = availableSeries.map(({ index }) => index);
    const [_min = 0, _max = 0] = [min(timeSeries), max(timeSeries)];

    return scaleLinear<number>({
      domain: [_min, _max],
      range: [startX, plotWidth + startX],
    });
  }, [availableSeries, plotWidth]);

  const internalAxisMargin = useMemo(() => getInternalAxisMargin(detailed), [detailed]);

  const dieselScale = useMemo(() => {
    const maxFromData =
      max([...boiler1DieselFacts.map((e) => e.value), ...boiler2DieselFacts.map((e) => e.value)]) || 0;
    const [minValue, maxValue] = [0, maxFromData];
    return scaleLinear<number>({
      range: [plotHeight, internalAxisMargin],
      domain: [minValue, maxValue],
      nice: true,
    });
  }, [boiler1DieselFacts, boiler2DieselFacts, internalAxisMargin, plotHeight]);

  const natGasScale = useMemo(() => {
    const maxFromData = boilerGasFacts ? max(boilerGasFacts.map((e) => e.value)) : 0;
    const [minValue, maxValue = 0] = [0, maxFromData];
    return scaleLinear<number>({
      range: [plotHeight, internalAxisMargin],
      domain: [minValue, maxValue],
      nice: true,
    });
  }, [boilerGasFacts, internalAxisMargin, plotHeight]);

  const [activeLegendItems, setActiveLegendItems] = useState<string[]>([
    CurveKey.Boiler1Diesel,
    CurveKey.Boiler2Diesel,
    CurveKey.BoilerGas,
  ]);

  const isEmptyTooltip = useMemo(() => {
    return (
      (activeLegendItems.includes(CurveKey.Boiler2Diesel) ? !boiler2DieselFacts?.length : true) &&
      (activeLegendItems.includes(CurveKey.Boiler1Diesel) ? !boiler1DieselFacts?.length : true) &&
      (activeLegendItems.includes(CurveKey.BoilerGas) ? !boilerGasFacts?.length : true)
    );
  }, [activeLegendItems, boiler1DieselFacts?.length, boiler2DieselFacts?.length, boilerGasFacts?.length]);

  return (
    <LensLoadingContainer
      key={lens.id}
      dataState={fuelFLowFacts.dataState}
      title={LENS_TITLE}
      isDetailed={false}
      LoadedComponent={
        <>
          <Row justify="space-between">
            <Col style={{ padding: 24 }}>
              <Title variant="faded" level={3}>
                {LENS_TITLE}
              </Title>
            </Col>
          </Row>

          <StyledChartContainerFlexDiv>
            <LineChart
              curves={[
                {
                  legendTitle: "Boiler Nat Gas Flow",
                  key: CurveKey.BoilerGas,
                  type: CurveType.NatGas,
                  data: boilerGasFacts,
                  yScale: natGasScale,
                  color: fuelColors.natGas,
                },
                {
                  legendTitle: "Boiler 1 Diesel Flow",
                  key: CurveKey.Boiler1Diesel,
                  type: CurveType.Diesel,
                  data: boiler1DieselFacts,
                  yScale: dieselScale,
                  curve: curveStepAfter,
                  color: boilerColors.boiler1,
                },
                {
                  legendTitle: "Boiler 2 Diesel Flow",
                  key: CurveKey.Boiler2Diesel,
                  type: CurveType.Diesel,
                  data: boiler2DieselFacts,
                  yScale: dieselScale,
                  curve: curveStepAfter,
                  color: boilerColors.boiler2,
                },
              ]}
              lensId={lens?.id}
              lensTemplateId={lens?.lensTemplateId}
              xScale={xScale}
              yScale={dieselScale}
              leftAxis={{
                scale: natGasScale,
                uom: natGasUom,
                label: natGasUom.abbr,
                useFuelFormatting: true,
              }}
              rightAxis={{
                scale: dieselScale,
                uom: dieselUom,
                label: dieselUom.abbr,
                useFuelFormatting: true,
              }}
              chartWidth={chartWidth}
              chartHeight={chartHeight}
              containerRef={containerRef}
              activeLegendItems={activeLegendItems}
              setActiveLegendItems={setActiveLegendItems}
              tooltipRenderer={({ tooltipData }) =>
                tooltipData ? (
                  <CommonStyles.TooltipContainer $width={isEmptyTooltip ? TOOLTIP_WIDTH_EMPTY : undefined}>
                    {!!boilerGasFacts?.length && activeLegendItems.includes(CurveKey.BoilerGas) && (
                      <CommonStyles.TooltipRow>
                        <CommonStyles.HighlightValue>
                          <CommonStyles.Indicator color={fuelColors.natGas} />
                          Nat Gas Flow Rate
                        </CommonStyles.HighlightValue>

                        <TooltipHighlightValue>
                          {natGasUom.display(tooltipData.values[CurveKey.BoilerGas], {
                            fractionDigits:
                              natGasUom.fromSI(tooltipData.values[CurveKey.BoilerGas]) > DECIMAL_THRESHOLD ? 2 : 3,
                          })}
                        </TooltipHighlightValue>
                      </CommonStyles.TooltipRow>
                    )}

                    {!!boiler1DieselFacts?.length && activeLegendItems.includes(CurveKey.Boiler1Diesel) && (
                      <CommonStyles.TooltipRow>
                        <CommonStyles.HighlightValue>
                          <CommonStyles.Indicator color={boilerColors.boiler1} />
                          Boiler 1 Diesel Flow
                        </CommonStyles.HighlightValue>

                        <TooltipHighlightValue>
                          {dieselUom.display(tooltipData.values[CurveKey.Boiler1Diesel], {
                            fractionDigits:
                              dieselUom.fromSI(tooltipData.values[CurveKey.Boiler1Diesel]) > DECIMAL_THRESHOLD ? 2 : 3,
                          })}
                        </TooltipHighlightValue>
                      </CommonStyles.TooltipRow>
                    )}

                    {!!boiler2DieselFacts?.length && activeLegendItems.includes(CurveKey.Boiler2Diesel) && (
                      <CommonStyles.TooltipRow>
                        <CommonStyles.HighlightValue>
                          <CommonStyles.Indicator color={boilerColors.boiler2} />
                          Boiler 2 Diesel Flow
                        </CommonStyles.HighlightValue>

                        <TooltipHighlightValue>
                          {dieselUom.display(tooltipData.values[CurveKey.Boiler2Diesel], {
                            fractionDigits:
                              dieselUom.fromSI(tooltipData.values[CurveKey.Boiler2Diesel]) > DECIMAL_THRESHOLD ? 2 : 3,
                          })}
                        </TooltipHighlightValue>
                      </CommonStyles.TooltipRow>
                    )}

                    {isEmptyTooltip ? (
                      <TooltipFadedValue style={{ display: "flex", justifyContent: "center" }}>
                        {tooltipData?.at
                          ? formatTime(tooltipData?.at, { formatStr: DEFAULT_DATE_FORMAT })
                          : NO_DATA_KPI_STRING}
                      </TooltipFadedValue>
                    ) : (
                      <CommonStyles.TooltipBorder>
                        <div />
                        <TooltipFadedValue>
                          {tooltipData?.at
                            ? formatTime(tooltipData?.at, { formatStr: DEFAULT_DATE_FORMAT })
                            : NO_DATA_KPI_STRING}
                        </TooltipFadedValue>
                      </CommonStyles.TooltipBorder>
                    )}
                  </CommonStyles.TooltipContainer>
                ) : null
              }
            />
          </StyledChartContainerFlexDiv>
        </>
      }
    />
  );
};
