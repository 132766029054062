/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DashboardType,
    DashboardTypeFromJSON,
    DashboardTypeFromJSONTyped,
    DashboardTypeToJSON,
} from './DashboardType';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    FiltersDto,
    FiltersDtoFromJSON,
    FiltersDtoFromJSONTyped,
    FiltersDtoToJSON,
} from './FiltersDto';
import {
    PivotType,
    PivotTypeFromJSON,
    PivotTypeFromJSONTyped,
    PivotTypeToJSON,
} from './PivotType';
import {
    WellStatusType,
    WellStatusTypeFromJSON,
    WellStatusTypeFromJSONTyped,
    WellStatusTypeToJSON,
} from './WellStatusType';

/**
 * 
 * @export
 * @interface RigScorecardFactQueryDto
 */
export interface RigScorecardFactQueryDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof RigScorecardFactQueryDto
     */
    formationIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof RigScorecardFactQueryDto
     */
    operatorIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof RigScorecardFactQueryDto
     */
    rigIds?: Array<number> | null;
    /**
     * 
     * @type {DateDto}
     * @memberof RigScorecardFactQueryDto
     */
    from: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof RigScorecardFactQueryDto
     */
    to: DateDto;
    /**
     * 
     * @type {boolean}
     * @memberof RigScorecardFactQueryDto
     */
    includeFullWellFacts: boolean;
    /**
     * 
     * @type {FiltersDto}
     * @memberof RigScorecardFactQueryDto
     */
    selectedFilters: FiltersDto;
    /**
     * 
     * @type {boolean}
     * @memberof RigScorecardFactQueryDto
     */
    includeAlphaRigs: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RigScorecardFactQueryDto
     */
    includeNoneAlphaRigs: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof RigScorecardFactQueryDto
     */
    selectedClasses?: Array<string> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof RigScorecardFactQueryDto
     */
    selectedCountryIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof RigScorecardFactQueryDto
     */
    selectedHorsePowers?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RigScorecardFactQueryDto
     */
    selectedOperatingCenters?: Array<string> | null;
    /**
     * 
     * @type {Array<WellStatusType>}
     * @memberof RigScorecardFactQueryDto
     */
    selectedWellStatusTypes?: Array<WellStatusType> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof RigScorecardFactQueryDto
     */
    selectedWellIds: Array<number>;
    /**
     * 
     * @type {DashboardType}
     * @memberof RigScorecardFactQueryDto
     */
    dashboard: DashboardType;
    /**
     * 
     * @type {Array<number>}
     * @memberof RigScorecardFactQueryDto
     */
    highlightedRigIds: Array<number>;
    /**
     * 
     * @type {PivotType}
     * @memberof RigScorecardFactQueryDto
     */
    pivot: PivotType;
    /**
     * 
     * @type {Array<number>}
     * @memberof RigScorecardFactQueryDto
     */
    tabIds?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof RigScorecardFactQueryDto
     */
    useExceptions: boolean;
}

export function RigScorecardFactQueryDtoFromJSON(json: any): RigScorecardFactQueryDto {
    return RigScorecardFactQueryDtoFromJSONTyped(json, false);
}

export function RigScorecardFactQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigScorecardFactQueryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'formationIds': !exists(json, 'FormationIds') ? undefined : json['FormationIds'],
        'operatorIds': !exists(json, 'OperatorIds') ? undefined : json['OperatorIds'],
        'rigIds': !exists(json, 'RigIds') ? undefined : json['RigIds'],
        'from': DateDtoFromJSON(json['From']),
        'to': DateDtoFromJSON(json['To']),
        'includeFullWellFacts': json['IncludeFullWellFacts'],
        'selectedFilters': FiltersDtoFromJSON(json['SelectedFilters']),
        'includeAlphaRigs': json['IncludeAlphaRigs'],
        'includeNoneAlphaRigs': json['IncludeNoneAlphaRigs'],
        'selectedClasses': !exists(json, 'SelectedClasses') ? undefined : json['SelectedClasses'],
        'selectedCountryIds': !exists(json, 'SelectedCountryIds') ? undefined : json['SelectedCountryIds'],
        'selectedHorsePowers': !exists(json, 'SelectedHorsePowers') ? undefined : json['SelectedHorsePowers'],
        'selectedOperatingCenters': !exists(json, 'SelectedOperatingCenters') ? undefined : json['SelectedOperatingCenters'],
        'selectedWellStatusTypes': !exists(json, 'SelectedWellStatusTypes') ? undefined : (json['SelectedWellStatusTypes'] === null ? null : (json['SelectedWellStatusTypes'] as Array<any>).map(WellStatusTypeFromJSON)),
        'selectedWellIds': json['SelectedWellIds'],
        'dashboard': DashboardTypeFromJSON(json['Dashboard']),
        'highlightedRigIds': json['HighlightedRigIds'],
        'pivot': PivotTypeFromJSON(json['Pivot']),
        'tabIds': !exists(json, 'TabIds') ? undefined : json['TabIds'],
        'useExceptions': json['UseExceptions'],
    };
}

export function RigScorecardFactQueryDtoToJSON(value?: RigScorecardFactQueryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'FormationIds': value.formationIds,
        'OperatorIds': value.operatorIds,
        'RigIds': value.rigIds,
        'From': DateDtoToJSON(value.from),
        'To': DateDtoToJSON(value.to),
        'IncludeFullWellFacts': value.includeFullWellFacts,
        'SelectedFilters': FiltersDtoToJSON(value.selectedFilters),
        'IncludeAlphaRigs': value.includeAlphaRigs,
        'IncludeNoneAlphaRigs': value.includeNoneAlphaRigs,
        'SelectedClasses': value.selectedClasses,
        'SelectedCountryIds': value.selectedCountryIds,
        'SelectedHorsePowers': value.selectedHorsePowers,
        'SelectedOperatingCenters': value.selectedOperatingCenters,
        'SelectedWellStatusTypes': value.selectedWellStatusTypes === undefined ? undefined : (value.selectedWellStatusTypes === null ? null : (value.selectedWellStatusTypes as Array<any>).map(WellStatusTypeToJSON)),
        'SelectedWellIds': value.selectedWellIds,
        'Dashboard': DashboardTypeToJSON(value.dashboard),
        'HighlightedRigIds': value.highlightedRigIds,
        'Pivot': PivotTypeToJSON(value.pivot),
        'TabIds': value.tabIds,
        'UseExceptions': value.useExceptions,
    };
}

