/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DashboardType,
    DashboardTypeFromJSON,
    DashboardTypeFromJSONTyped,
    DashboardTypeToJSON,
} from './DashboardType';
import {
    ProfileOriginType,
    ProfileOriginTypeFromJSON,
    ProfileOriginTypeFromJSONTyped,
    ProfileOriginTypeToJSON,
} from './ProfileOriginType';

/**
 * 
 * @export
 * @interface DashboardCopyDto
 */
export interface DashboardCopyDto {
    /**
     * 
     * @type {Array<DashboardType>}
     * @memberof DashboardCopyDto
     */
    types: Array<DashboardType>;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardCopyDto
     */
    excludeHiddenTabs: boolean;
    /**
     * 
     * @type {Array<ProfileOriginType>}
     * @memberof DashboardCopyDto
     */
    sourceOriginType: Array<ProfileOriginType>;
    /**
     * 
     * @type {ProfileOriginType}
     * @memberof DashboardCopyDto
     */
    targetOriginType: ProfileOriginType;
    /**
     * 
     * @type {number}
     * @memberof DashboardCopyDto
     */
    sourceProfileId: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardCopyDto
     */
    targetProfileId: number;
}

export function DashboardCopyDtoFromJSON(json: any): DashboardCopyDto {
    return DashboardCopyDtoFromJSONTyped(json, false);
}

export function DashboardCopyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardCopyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'types': ((json['Types'] as Array<any>).map(DashboardTypeFromJSON)),
        'excludeHiddenTabs': json['ExcludeHiddenTabs'],
        'sourceOriginType': ((json['SourceOriginType'] as Array<any>).map(ProfileOriginTypeFromJSON)),
        'targetOriginType': ProfileOriginTypeFromJSON(json['TargetOriginType']),
        'sourceProfileId': json['SourceProfileId'],
        'targetProfileId': json['TargetProfileId'],
    };
}

export function DashboardCopyDtoToJSON(value?: DashboardCopyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Types': ((value.types as Array<any>).map(DashboardTypeToJSON)),
        'ExcludeHiddenTabs': value.excludeHiddenTabs,
        'SourceOriginType': ((value.sourceOriginType as Array<any>).map(ProfileOriginTypeToJSON)),
        'TargetOriginType': ProfileOriginTypeToJSON(value.targetOriginType),
        'SourceProfileId': value.sourceProfileId,
        'TargetProfileId': value.targetProfileId,
    };
}

