import { AxisBottom, AxisRight } from "@visx/axis";
import { RectClipPath } from "@visx/clip-path";
import { LinearGradient } from "@visx/gradient";
import { GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";
import { BarRounded, Line } from "@visx/shape";
import { Text as VisXText } from "@visx/text";
import type { StandKpiType } from "apis/oag";
import { PivotType } from "apis/oag";
import type { ScrollbarRange } from "components/Lenses/common/Scrollbar";
import { Scrollbar } from "components/Lenses/common/Scrollbar";
import { TooltipHighlightValue } from "components/Lenses/common/Tooltip";
import { useChartTooltip } from "components/Lenses/common/useChartTooltip";
import { CLIP_SERIES_ID } from "components/Lenses/ContainerLens/common/utils/utils";
import { extent } from "d3";
import { useYAxisDisplayScale } from "hooks/charting/useYAxisDisplayScale";
import { usePivotLabels } from "hooks/usePivotLabels";
import { useRigSummaries } from "hooks/useRigSummaries";
import { useWellShortInfoSuspended } from "hooks/useWellShortInfo";
import { max } from "lodash";
import type { FactWithLabel } from "pages/AllRigs/components/LeaderboardSection/Card/interfaces";
import { useHoveredDataContext } from "pages/AllRigs/components/LeaderboardSection/helpers/HoveredDataContext";
import { SelectedMetric } from "pages/AllRigs/components/LeaderboardSection/LeaderboardMetricSelectionContext";
import { SelectedTooltipValue } from "pages/AllRigs/components/LeaderboardSection/styled";
import {
  BOTTOM_AXIS_HEIGHT,
  CHART_HORIZONTAL_PADDING,
  CHART_PADDING_TOP,
  getDefaultValueFromKpiType,
  HIGHLIGHT_RATIO,
  isTimePivot,
  LABEL_SPACE_HORIZONTAL,
  LABEL_TRUNCATION_LENGTH,
  MAX_NUMBER_DECIMAL_THRESHOLD,
  MIN_CHART_HEIGHT,
  MIN_CHART_WIDTH,
  RIGHT_AXIS_WIDTH,
  SCROLL_HEIGHT,
  SCROLL_PADDING,
  shouldDisplayHighlightBar,
  TOOLTIP_TOP,
} from "pages/AllRigs/components/LeaderboardSection/utils";
import { useSelectedRigsContext } from "pages/AllRigs/RigList/SelectedRigsContext";
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import colors from "utils/colors";
import type { UOMHelper } from "utils/format";
import { truncateMiddleString } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

import { StyledChart, StyledChartContainer, StyledPaddedContainer } from "./styled";

export const MINI_ZOOM_SCALER = 30;
const MINI_ZOOM_SCALER_EXPANDED = 90;

export default function LeaderboardChart({
  data,
  cardId,
  kpiType,
  uom,
  pivotType,
  selectedMetric,
  isExpanded = false,
  isWideBar = false,
  isSingleOnGrid = false,
}: {
  data: FactWithLabel[];
  cardId: number;
  kpiType: StandKpiType;
  uom: UOMHelper;
  pivotType: PivotType;
  selectedMetric: SelectedMetric;
  isExpanded?: boolean;
  isWideBar?: boolean;
  isSingleOnGrid?: boolean;
}) {
  const { width: chartWidthHook, height: chartHeightHook, ref: containerRef } = useResizeDetector<HTMLDivElement>();
  const { isDataAnonymity, selectedRigIds } = useSelectedRigsContext();
  const { hoveredDataRank, setHoveredDataRank } = useHoveredDataContext();

  const { getLabelFromKey } = usePivotLabels();
  const chartWidth = chartWidthHook || MIN_CHART_WIDTH;
  const chartHeight = chartHeightHook || MIN_CHART_HEIGHT;
  const plotHeight = chartHeight - BOTTOM_AXIS_HEIGHT - SCROLL_HEIGHT - SCROLL_PADDING * 2;
  const plotWidth = chartWidth - RIGHT_AXIS_WIDTH;

  const scrollbarWidth = plotWidth - SCROLL_PADDING * 2;
  const { data: wellShortInfo } = useWellShortInfoSuspended();

  const barExpansionMultiple = useMemo(() => {
    // Manually set bar width without interfering scale bandwidth adjustment or zoom.
    if (!isSingleOnGrid) {
      return isWideBar ? 3.5 : 1;
    }
    return isWideBar ? 1.5 : 0.25;
  }, [isSingleOnGrid, isWideBar]);

  const zoomRate = useMemo(() => {
    return Math.ceil(
      (data?.length * barExpansionMultiple) / (isExpanded ? MINI_ZOOM_SCALER_EXPANDED : MINI_ZOOM_SCALER),
    );
  }, [barExpansionMultiple, data?.length, isExpanded]);

  const [scrollbarRange, setScrollbarRange] = useState<ScrollbarRange>({ startX: 1 - 1 / zoomRate, endX: 1 });

  const isNetTimes = useMemo(() => selectedMetric === SelectedMetric.NetTimes, [selectedMetric]);
  const { data: rigs } = useRigSummaries({ staleTime: Infinity });

  useLayoutEffect(() => {
    setScrollbarRange({ startX: 1 - 1 / zoomRate, endX: 1 });
  }, [zoomRate]);

  const scrollbarScale = useMemo(
    () => scaleLinear<number>({ domain: [0, 1], range: [0, scrollbarWidth] }),
    [scrollbarWidth],
  );

  const xScale = useMemo(() => {
    const chartSize = plotWidth * zoomRate;
    const startX = chartSize * scrollbarRange.startX;

    return scaleBand<string>({
      range: [-startX, chartSize - startX],
      paddingInner: 0.3,
      paddingOuter: 0.5,
      domain: data.map((d) => d.label),
    });
  }, [data, plotWidth, zoomRate, scrollbarRange.startX]);

  const valueScale = useMemo(() => {
    const [min = 0, max = 0] = extent([
      ...data.map((d) => d.value || 0),
      ...data.map((d) => d.highlightedValue || 0),
      getDefaultValueFromKpiType(kpiType),
      0,
    ]);

    const maxAbs = Math.max(Math.abs(min), Math.abs(max));
    const normalDomain = [min, max];
    const symmetricalDomain = [-maxAbs, maxAbs];

    const domain = min < 0 ? symmetricalDomain : normalDomain;

    return scaleLinear<number>({
      range: [plotHeight, 0],
      round: true,
      domain,
    });
  }, [data, kpiType, plotHeight]);

  const { yDisplayOnlyScale, verticalAxisTicksCount } = useYAxisDisplayScale({
    tickContainerHeight: plotHeight,
    tickHeight: 14,
    originalScale: valueScale,
    uom: uom,
  });

  const { showTooltip, hideTooltip, tooltipElement } = useChartTooltip<FactWithLabel>({
    containerRef,
    renderContent: ({ tooltipData }) => {
      let rigLabel;
      let rigId = 0;

      if (pivotType === PivotType.Well) {
        const rigIds = wellShortInfo?.byId[+(tooltipData?.label || "0")]?.rigIds;
        const rig = (rigs || []).find((rigId) => rigIds.includes(rigId.id));
        rigId = rig?.id || 0;
        rigLabel = rig?.shortName || "";
      }

      return (
        <>
          {shouldDisplayHighlightBar(pivotType) && tooltipData?.highlightedValue ? (
            <SelectedTooltipValue>{uom.display(tooltipData?.highlightedValue)} </SelectedTooltipValue>
          ) : null}
          <TooltipHighlightValue>{uom.display(tooltipData?.value || 0)}</TooltipHighlightValue>
          {(isDataAnonymity && !isTimePivot(pivotType) ? tooltipData?.highlightedValue : true) ? (
            <span>{getLabelFromKey(tooltipData?.label || "", pivotType)}</span>
          ) : null}
          {!isDataAnonymity || selectedRigIds.includes(rigId) ? <span>{rigLabel}</span> : null}
          <span>#{tooltipData?.rank}</span>
        </>
      );
    },
  });

  const handleMouseOver = useCallback(
    (leftX: number, barData: FactWithLabel) => {
      showTooltip({
        tooltipLeft: leftX,
        tooltipTop: valueScale(max([barData?.value || 0, barData.highlightedValue || 0, 0]) || 0) + TOOLTIP_TOP,
        tooltipData: barData,
      });
    },
    [valueScale, showTooltip],
  );

  useEffect(() => {
    if (hoveredDataRank !== null) {
      const hoveredData = data.find((d) => d.rank === hoveredDataRank);
      if (!hoveredData) return;
      const leftX = (xScale(hoveredData?.label) || 0) + xScale.bandwidth() / 2;
      if (leftX >= 0 && leftX <= plotWidth) {
        showTooltip({
          tooltipLeft: leftX,
          tooltipTop: valueScale(max([hoveredData?.value, hoveredData?.highlightedValue]) || 0) + TOOLTIP_TOP,
          tooltipData: hoveredData,
        });
      }
    } else {
      hideTooltip();
    }
  }, [hoveredDataRank, data, plotWidth, plotHeight, valueScale, showTooltip, hideTooltip, xScale]);

  const numTicksHorizontal = useMemo(
    () => Math.floor((xScale.bandwidth() / LABEL_SPACE_HORIZONTAL) * xScale.domain().length),
    [xScale],
  );

  const LENS_CLIP_SERIES_ID = useMemo(
    () => `${CLIP_SERIES_ID}-${cardId}-${isExpanded.toString()}`,
    [cardId, isExpanded],
  );

  function getTickFormat(value: number) {
    const maxValue = max([Math.abs(yDisplayOnlyScale.domain()[1]), Math.abs(yDisplayOnlyScale.domain()[0])]) || 0;
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: maxValue < MAX_NUMBER_DECIMAL_THRESHOLD ? 1 : 0,
    }).format(value);
  }

  function getFillColor(d: FactWithLabel) {
    if (isNetTimes) {
      if (d.highlightedValue && !isTimePivot(pivotType)) {
        return colors.well_color;
      }
      if ((d.value || 0) > 0) {
        return `url(#green_gradient)`;
      } else {
        return `url(#purple_gradient)`;
      }
    }

    if (d.highlightedValue) {
      return shouldDisplayHighlightBar(pivotType) ? `url(#secondary_linear_gradient_leaderboard)` : colors.well_color;
    }

    return `url(#default_linear_gradient_leaderboard)`;
  }

  function getBarHeight(value: number) {
    if (valueScale.domain()[0] < 0) {
      if (value > 0) {
        return plotHeight - valueScale(value) - valueScale(0);
      } else {
        return valueScale(value) - valueScale(0);
      }
    }
    return plotHeight - valueScale(value);
  }
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  return (
    <StyledPaddedContainer>
      <StyledChartContainer ref={containerRef}>
        <StyledChart width={chartWidth} height={chartHeight}>
          <LinearGradient
            from={colors.marble_green_grey}
            to={colors.marble_green_grey}
            toOpacity={0.4}
            fromOpacity={0.8}
            id={`default_linear_gradient_leaderboard`}
          />
          <LinearGradient
            from={colors.marble_green_grey}
            to={colors.marble_green_grey}
            toOpacity={0.4}
            fromOpacity={0.8}
            id={`secondary_linear_gradient_leaderboard`}
          />
          <LinearGradient
            from={colors.diva}
            to={colors.perrywinkle}
            toOpacity={0.68}
            fromOpacity={1}
            id={`purple_gradient`}
          />
          <LinearGradient
            from={colors.fresco_green}
            to={colors.fresco_green}
            toOpacity={0.4}
            fromOpacity={0.8}
            id={`green_gradient`}
          />
          <RectClipPath
            y={0}
            x={-CHART_HORIZONTAL_PADDING}
            id={LENS_CLIP_SERIES_ID}
            height={chartHeight}
            width={plotWidth + CHART_HORIZONTAL_PADDING}
          />
          <Group clipPath={`url(#${LENS_CLIP_SERIES_ID})`} top={CHART_PADDING_TOP}>
            <GridRows
              width={plotWidth}
              height={plotHeight}
              numTicks={verticalAxisTicksCount}
              scale={yDisplayOnlyScale}
              stroke={themeColors.secondary_chart_accent}
              strokeWidth={1}
              strokeOpacity={1}
            />
            {data.map((d) => {
              const barWidth = xScale.bandwidth();
              const barHeight = getBarHeight(d.value || 0);
              const barX = xScale(d.label) || 0;
              const barY = (d.value || 0) > 0 ? valueScale(d.value || 0) : valueScale(0);
              const highlightedHeight = getBarHeight(d.highlightedValue || 0);
              const highlightedY = (d.value || 0) > 0 ? valueScale(d.highlightedValue || 0) : valueScale(0);
              const fill = getFillColor(d);

              return (
                <Group
                  key={`bar-group-${d.rank}-${d.value}`}
                  onMouseOver={() => {
                    setHoveredDataRank(d.rank);
                    handleMouseOver(barX + xScale.bandwidth() / 2, d);
                  }}
                  onMouseLeave={() => {
                    setHoveredDataRank(null);
                  }}
                  onMouseOut={hideTooltip}
                >
                  <BarRounded
                    radius={4}
                    top={(d.value || 0) > 0}
                    bottom={(d.value || 0) < 0}
                    key={`bar-${d.rank}-${d.value}`}
                    x={barX}
                    y={barY}
                    width={barWidth}
                    height={barHeight}
                    fill={fill}
                  />
                  {d.highlightedValue ? (
                    <BarRounded
                      radius={4}
                      top={d.highlightedValue > 0}
                      bottom={d.highlightedValue < 0}
                      key={`bar-highlight-${d.rank}-${d.value}`}
                      x={barX + barWidth * ((1 - HIGHLIGHT_RATIO) / 2)}
                      y={highlightedY}
                      width={barWidth * HIGHLIGHT_RATIO}
                      height={highlightedHeight}
                      fill={colors.well_color}
                    />
                  ) : null}
                </Group>
              );
            })}

            <AxisBottom
              hideAxisLine
              hideTicks
              numTicks={numTicksHorizontal}
              scale={xScale}
              top={plotHeight}
              tickLength={0}
              tickLabelProps={() => ({
                fontSize: "12px",
                fill: themeColors.primary_typography,
                letterSpacing: "-0.2px",
                textAnchor: "middle",
                style: {
                  transform: "translateY(5px)",
                },
              })}
              tickComponent={(props) => {
                if (!props.formattedValue) return;
                const value =
                  pivotType === PivotType.Well
                    ? "#" + data.find((d) => d.label === props.formattedValue)?.rank
                    : truncateMiddleString(getLabelFromKey(props.formattedValue, pivotType), LABEL_TRUNCATION_LENGTH);

                if (
                  isDataAnonymity && (pivotType === PivotType.Rig || pivotType === PivotType.Operator)
                    ? data.find((d) => d.label === props.formattedValue)?.highlightedValue
                    : true
                )
                  return (
                    <Group>
                      <VisXText
                        x={props.x}
                        y={props.y}
                        dy={6}
                        fontSize={12}
                        fill={themeColors.disabled_typography}
                        pointerEvents="none"
                        textAnchor="middle"
                      >
                        {value}
                      </VisXText>
                    </Group>
                  );
                return (
                  <rect
                    x={props.x - 10}
                    y={props.y - 6}
                    width={20}
                    height={12}
                    rx={2}
                    fill={themeColors.primary_accent}
                  />
                );
              }}
            />

            {isNetTimes ? (
              <Line
                from={{ x: xScale.range()[0], y: +valueScale(0) }}
                to={{ x: xScale.range()[1], y: +valueScale(0) }}
                strokeWidth={2}
                stroke={themeColors.primary_accent}
              />
            ) : null}
          </Group>
          <AxisRight
            top={CHART_PADDING_TOP}
            hideAxisLine
            hideTicks
            scale={yDisplayOnlyScale}
            numTicks={verticalAxisTicksCount}
            left={plotWidth}
            hideZero={yDisplayOnlyScale.domain()[0] === 0}
            tickFormat={(value) => getTickFormat(value as number)}
            tickLabelProps={() => ({
              fontSize: "12px",
              fill: themeColors.disabled_typography,
              letterSpacing: "-0.2px",
              textAnchor: "start",
              verticalAnchor: "middle",
            })}
          />
          {zoomRate > 1 && (
            <Group top={plotHeight + BOTTOM_AXIS_HEIGHT + SCROLL_PADDING} left={SCROLL_PADDING}>
              <Scrollbar
                width={scrollbarWidth}
                height={SCROLL_HEIGHT}
                scale={scrollbarScale}
                wheelCaptureContainer={containerRef}
                onScroll={setScrollbarRange}
                valueSpan={1 / zoomRate}
              />
            </Group>
          )}
          {tooltipElement}
        </StyledChart>
      </StyledChartContainer>
    </StyledPaddedContainer>
  );
}
