/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DataFeedRefreshInfoDto
 */
export interface DataFeedRefreshInfoDto {
    /**
     * 
     * @type {number}
     * @memberof DataFeedRefreshInfoDto
     */
    wellId: number;
    /**
     * 
     * @type {boolean}
     * @memberof DataFeedRefreshInfoDto
     */
    requiresWellRefresh: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataFeedRefreshInfoDto
     */
    requiresTvdRefresh: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataFeedRefreshInfoDto
     */
    requiresPerStandRefresh: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataFeedRefreshInfoDto
     */
    requiresRigStateRefresh: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataFeedRefreshInfoDto
     */
    requiresByDepthRefresh: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataFeedRefreshInfoDto
     */
    requiresBatteryStateRefresh: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataFeedRefreshInfoDto
     */
    requiresPowerManagementRefresh: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataFeedRefreshInfoDto
     */
    requiresFuelManagementRefresh: boolean;
}

export function DataFeedRefreshInfoDtoFromJSON(json: any): DataFeedRefreshInfoDto {
    return DataFeedRefreshInfoDtoFromJSONTyped(json, false);
}

export function DataFeedRefreshInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataFeedRefreshInfoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'wellId': json['WellId'],
        'requiresWellRefresh': json['RequiresWellRefresh'],
        'requiresTvdRefresh': json['RequiresTvdRefresh'],
        'requiresPerStandRefresh': json['RequiresPerStandRefresh'],
        'requiresRigStateRefresh': json['RequiresRigStateRefresh'],
        'requiresByDepthRefresh': json['RequiresByDepthRefresh'],
        'requiresBatteryStateRefresh': json['RequiresBatteryStateRefresh'],
        'requiresPowerManagementRefresh': json['RequiresPowerManagementRefresh'],
        'requiresFuelManagementRefresh': json['RequiresFuelManagementRefresh'],
    };
}

export function DataFeedRefreshInfoDtoToJSON(value?: DataFeedRefreshInfoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'WellId': value.wellId,
        'RequiresWellRefresh': value.requiresWellRefresh,
        'RequiresTvdRefresh': value.requiresTvdRefresh,
        'RequiresPerStandRefresh': value.requiresPerStandRefresh,
        'RequiresRigStateRefresh': value.requiresRigStateRefresh,
        'RequiresByDepthRefresh': value.requiresByDepthRefresh,
        'RequiresBatteryStateRefresh': value.requiresBatteryStateRefresh,
        'RequiresPowerManagementRefresh': value.requiresPowerManagementRefresh,
        'RequiresFuelManagementRefresh': value.requiresFuelManagementRefresh,
    };
}

