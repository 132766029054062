/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DashboardType,
    DashboardTypeFromJSON,
    DashboardTypeFromJSONTyped,
    DashboardTypeToJSON,
} from './DashboardType';
import {
    LensTemplateType,
    LensTemplateTypeFromJSON,
    LensTemplateTypeFromJSONTyped,
    LensTemplateTypeToJSON,
} from './LensTemplateType';
import {
    StandKpiType,
    StandKpiTypeFromJSON,
    StandKpiTypeFromJSONTyped,
    StandKpiTypeToJSON,
} from './StandKpiType';

/**
 * 
 * @export
 * @interface PivotKpiTypeLensTemplateDto
 */
export interface PivotKpiTypeLensTemplateDto {
    /**
     * 
     * @type {number}
     * @memberof PivotKpiTypeLensTemplateDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PivotKpiTypeLensTemplateDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PivotKpiTypeLensTemplateDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PivotKpiTypeLensTemplateDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {LensTemplateType}
     * @memberof PivotKpiTypeLensTemplateDto
     */
    type: LensTemplateType;
    /**
     * 
     * @type {DashboardType}
     * @memberof PivotKpiTypeLensTemplateDto
     */
    targetDashboardType: DashboardType;
    /**
     * 
     * @type {number}
     * @memberof PivotKpiTypeLensTemplateDto
     */
    nbCondensedDataPoints: number;
    /**
     * 
     * @type {boolean}
     * @memberof PivotKpiTypeLensTemplateDto
     */
    useBitDepth: boolean;
    /**
     * 
     * @type {number}
     * @memberof PivotKpiTypeLensTemplateDto
     */
    position: number;
    /**
     * 
     * @type {boolean}
     * @memberof PivotKpiTypeLensTemplateDto
     */
    canSelectTimeUnit: boolean;
    /**
     * 
     * @type {Array<StandKpiType>}
     * @memberof PivotKpiTypeLensTemplateDto
     */
    selectableKpiTypeIds?: Array<StandKpiType> | null;
}

export function PivotKpiTypeLensTemplateDtoFromJSON(json: any): PivotKpiTypeLensTemplateDto {
    return PivotKpiTypeLensTemplateDtoFromJSONTyped(json, false);
}

export function PivotKpiTypeLensTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PivotKpiTypeLensTemplateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': !exists(json, 'Name') ? undefined : json['Name'],
        'description': !exists(json, 'Description') ? undefined : json['Description'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'type': LensTemplateTypeFromJSON(json['Type']),
        'targetDashboardType': DashboardTypeFromJSON(json['TargetDashboardType']),
        'nbCondensedDataPoints': json['NbCondensedDataPoints'],
        'useBitDepth': json['UseBitDepth'],
        'position': json['Position'],
        'canSelectTimeUnit': json['CanSelectTimeUnit'],
        'selectableKpiTypeIds': !exists(json, 'SelectableKpiTypeIds') ? undefined : (json['SelectableKpiTypeIds'] === null ? null : (json['SelectableKpiTypeIds'] as Array<any>).map(StandKpiTypeFromJSON)),
    };
}

export function PivotKpiTypeLensTemplateDtoToJSON(value?: PivotKpiTypeLensTemplateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'Name': value.name,
        'Description': value.description,
        'IsAvailableOnReports': value.isAvailableOnReports,
        'Type': LensTemplateTypeToJSON(value.type),
        'TargetDashboardType': DashboardTypeToJSON(value.targetDashboardType),
        'NbCondensedDataPoints': value.nbCondensedDataPoints,
        'UseBitDepth': value.useBitDepth,
        'Position': value.position,
        'CanSelectTimeUnit': value.canSelectTimeUnit,
        'SelectableKpiTypeIds': value.selectableKpiTypeIds === undefined ? undefined : (value.selectableKpiTypeIds === null ? null : (value.selectableKpiTypeIds as Array<any>).map(StandKpiTypeToJSON)),
    };
}

