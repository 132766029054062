/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DimensionType = {
    Undefined: 'Undefined',
    Seconds: 'Seconds',
    MetresPerSecond: 'MetresPerSecond',
    Metres: 'Metres',
    JointsPerSecond: 'JointsPerSecond',
    RevolutionsPerSecond: 'RevolutionsPerSecond',
    Newtons: 'Newtons',
    Pascals: 'Pascals',
    NewtonMeters: 'NewtonMeters',
    CubicMetresPerSecond: 'CubicMetresPerSecond',
    Count: 'Count',
    Watts: 'Watts',
    KiloWattHours: 'KiloWattHours',
    CubicMetres: 'CubicMetres',
    Litres: 'Litres',
    LitresPerSecond: 'LitresPerSecond',
    Temperature: 'Temperature',
    KiloGramsPerSecond: 'KiloGramsPerSecond',
    KiloGrams: 'KiloGrams'
} as const;
export type DimensionType = typeof DimensionType[keyof typeof DimensionType];


export function DimensionTypeFromJSON(json: any): DimensionType {
    return DimensionTypeFromJSONTyped(json, false);
}

export function DimensionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DimensionType {
    return json as DimensionType;
}

export function DimensionTypeToJSON(value?: DimensionType | null): any {
    return value as any;
}

