/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WedgeSummaryValuesDto
 */
export interface WedgeSummaryValuesDto {
    /**
     * 
     * @type {number}
     * @memberof WedgeSummaryValuesDto
     */
    allAverage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WedgeSummaryValuesDto
     */
    focalAverage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WedgeSummaryValuesDto
     */
    comparisonAverage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WedgeSummaryValuesDto
     */
    comparisonAverageRelativeDiff?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WedgeSummaryValuesDto
     */
    focalDistribution?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WedgeSummaryValuesDto
     */
    comparisonDistribution?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WedgeSummaryValuesDto
     */
    comparisonDistributionRelativeDiff?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WedgeSummaryValuesDto
     */
    text?: string | null;
}

export function WedgeSummaryValuesDtoFromJSON(json: any): WedgeSummaryValuesDto {
    return WedgeSummaryValuesDtoFromJSONTyped(json, false);
}

export function WedgeSummaryValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WedgeSummaryValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allAverage': !exists(json, 'AllAverage') ? undefined : json['AllAverage'],
        'focalAverage': !exists(json, 'FocalAverage') ? undefined : json['FocalAverage'],
        'comparisonAverage': !exists(json, 'ComparisonAverage') ? undefined : json['ComparisonAverage'],
        'comparisonAverageRelativeDiff': !exists(json, 'ComparisonAverageRelativeDiff') ? undefined : json['ComparisonAverageRelativeDiff'],
        'focalDistribution': !exists(json, 'FocalDistribution') ? undefined : json['FocalDistribution'],
        'comparisonDistribution': !exists(json, 'ComparisonDistribution') ? undefined : json['ComparisonDistribution'],
        'comparisonDistributionRelativeDiff': !exists(json, 'ComparisonDistributionRelativeDiff') ? undefined : json['ComparisonDistributionRelativeDiff'],
        'text': !exists(json, 'Text') ? undefined : json['Text'],
    };
}

export function WedgeSummaryValuesDtoToJSON(value?: WedgeSummaryValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AllAverage': value.allAverage,
        'FocalAverage': value.focalAverage,
        'ComparisonAverage': value.comparisonAverage,
        'ComparisonAverageRelativeDiff': value.comparisonAverageRelativeDiff,
        'FocalDistribution': value.focalDistribution,
        'ComparisonDistribution': value.comparisonDistribution,
        'ComparisonDistributionRelativeDiff': value.comparisonDistributionRelativeDiff,
        'Text': value.text,
    };
}

