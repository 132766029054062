/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Int32Int64FactIndicatorDto,
    Int32Int64FactIndicatorDtoFromJSON,
    Int32Int64FactIndicatorDtoFromJSONTyped,
    Int32Int64FactIndicatorDtoToJSON,
} from './Int32Int64FactIndicatorDto';

/**
 * 
 * @export
 * @interface Int32Int64FactIndicatorSetDto
 */
export interface Int32Int64FactIndicatorSetDto {
    /**
     * 
     * @type {Array<Int32Int64FactIndicatorDto>}
     * @memberof Int32Int64FactIndicatorSetDto
     */
    holeSections?: Array<Int32Int64FactIndicatorDto> | null;
    /**
     * 
     * @type {Array<Int32Int64FactIndicatorDto>}
     * @memberof Int32Int64FactIndicatorSetDto
     */
    directionIntervals?: Array<Int32Int64FactIndicatorDto> | null;
    /**
     * 
     * @type {Array<Int32Int64FactIndicatorDto>}
     * @memberof Int32Int64FactIndicatorSetDto
     */
    formations?: Array<Int32Int64FactIndicatorDto> | null;
}

export function Int32Int64FactIndicatorSetDtoFromJSON(json: any): Int32Int64FactIndicatorSetDto {
    return Int32Int64FactIndicatorSetDtoFromJSONTyped(json, false);
}

export function Int32Int64FactIndicatorSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): Int32Int64FactIndicatorSetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'holeSections': !exists(json, 'HoleSections') ? undefined : (json['HoleSections'] === null ? null : (json['HoleSections'] as Array<any>).map(Int32Int64FactIndicatorDtoFromJSON)),
        'directionIntervals': !exists(json, 'DirectionIntervals') ? undefined : (json['DirectionIntervals'] === null ? null : (json['DirectionIntervals'] as Array<any>).map(Int32Int64FactIndicatorDtoFromJSON)),
        'formations': !exists(json, 'Formations') ? undefined : (json['Formations'] === null ? null : (json['Formations'] as Array<any>).map(Int32Int64FactIndicatorDtoFromJSON)),
    };
}

export function Int32Int64FactIndicatorSetDtoToJSON(value?: Int32Int64FactIndicatorSetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'HoleSections': value.holeSections === undefined ? undefined : (value.holeSections === null ? null : (value.holeSections as Array<any>).map(Int32Int64FactIndicatorDtoToJSON)),
        'DirectionIntervals': value.directionIntervals === undefined ? undefined : (value.directionIntervals === null ? null : (value.directionIntervals as Array<any>).map(Int32Int64FactIndicatorDtoToJSON)),
        'Formations': value.formations === undefined ? undefined : (value.formations === null ? null : (value.formations as Array<any>).map(Int32Int64FactIndicatorDtoToJSON)),
    };
}

