/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WellStatusType,
    WellStatusTypeFromJSON,
    WellStatusTypeFromJSONTyped,
    WellStatusTypeToJSON,
} from './WellStatusType';

/**
 * 
 * @export
 * @interface BaseWellDto
 */
export interface BaseWellDto {
    /**
     * 
     * @type {number}
     * @memberof BaseWellDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BaseWellDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof BaseWellDto
     */
    operatorId: number;
    /**
     * 
     * @type {WellStatusType}
     * @memberof BaseWellDto
     */
    status: WellStatusType;
    /**
     * 
     * @type {string}
     * @memberof BaseWellDto
     */
    externalId: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWellDto
     */
    padExternalId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BaseWellDto
     */
    isEverGreen: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BaseWellDto
     */
    isBess: boolean;
}

export function BaseWellDtoFromJSON(json: any): BaseWellDto {
    return BaseWellDtoFromJSONTyped(json, false);
}

export function BaseWellDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseWellDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': json['Name'],
        'operatorId': json['OperatorId'],
        'status': WellStatusTypeFromJSON(json['Status']),
        'externalId': json['ExternalId'],
        'padExternalId': !exists(json, 'PadExternalId') ? undefined : json['PadExternalId'],
        'isEverGreen': json['IsEverGreen'],
        'isBess': json['IsBess'],
    };
}

export function BaseWellDtoToJSON(value?: BaseWellDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'Name': value.name,
        'OperatorId': value.operatorId,
        'Status': WellStatusTypeToJSON(value.status),
        'ExternalId': value.externalId,
        'PadExternalId': value.padExternalId,
        'IsEverGreen': value.isEverGreen,
        'IsBess': value.isBess,
    };
}

