/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TorqueAndDragActivityType = {
    Unknown: 'Unknown',
    ReamDown: 'ReamDown',
    ReamUp: 'ReamUp',
    RotateOffBottom: 'RotateOffBottom',
    PickUp: 'PickUp',
    SlackOff: 'SlackOff',
    WashOut: 'WashOut',
    WashIn: 'WashIn',
    General: 'General',
    RotateOnBottom: 'RotateOnBottom'
} as const;
export type TorqueAndDragActivityType = typeof TorqueAndDragActivityType[keyof typeof TorqueAndDragActivityType];


export function TorqueAndDragActivityTypeFromJSON(json: any): TorqueAndDragActivityType {
    return TorqueAndDragActivityTypeFromJSONTyped(json, false);
}

export function TorqueAndDragActivityTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TorqueAndDragActivityType {
    return json as TorqueAndDragActivityType;
}

export function TorqueAndDragActivityTypeToJSON(value?: TorqueAndDragActivityType | null): any {
    return value as any;
}

