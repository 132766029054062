/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OperationCategoryType = {
    Drilling: 'Drilling',
    Casing: 'Casing',
    Tripping: 'Tripping',
    Other: 'Other'
} as const;
export type OperationCategoryType = typeof OperationCategoryType[keyof typeof OperationCategoryType];


export function OperationCategoryTypeFromJSON(json: any): OperationCategoryType {
    return OperationCategoryTypeFromJSONTyped(json, false);
}

export function OperationCategoryTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationCategoryType {
    return json as OperationCategoryType;
}

export function OperationCategoryTypeToJSON(value?: OperationCategoryType | null): any {
    return value as any;
}

