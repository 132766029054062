/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    RigCardAverages,
    RigCardAveragesFromJSON,
    RigCardAveragesFromJSONTyped,
    RigCardAveragesToJSON,
} from './RigCardAverages';
import {
    RigCardFactSeriesDto,
    RigCardFactSeriesDtoFromJSON,
    RigCardFactSeriesDtoFromJSONTyped,
    RigCardFactSeriesDtoToJSON,
} from './RigCardFactSeriesDto';

/**
 * 
 * @export
 * @interface RigCardGroupFactDto
 */
export interface RigCardGroupFactDto {
    /**
     * 
     * @type {ResultDataState}
     * @memberof RigCardGroupFactDto
     */
    dataState: ResultDataState;
    /**
     * 
     * @type {number}
     * @memberof RigCardGroupFactDto
     */
    tabId: number;
    /**
     * 
     * @type {RigCardAverages}
     * @memberof RigCardGroupFactDto
     */
    averages: RigCardAverages;
    /**
     * 
     * @type {Array<RigCardFactSeriesDto>}
     * @memberof RigCardGroupFactDto
     */
    facts: Array<RigCardFactSeriesDto>;
}

export function RigCardGroupFactDtoFromJSON(json: any): RigCardGroupFactDto {
    return RigCardGroupFactDtoFromJSONTyped(json, false);
}

export function RigCardGroupFactDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigCardGroupFactDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataState': ResultDataStateFromJSON(json['DataState']),
        'tabId': json['TabId'],
        'averages': RigCardAveragesFromJSON(json['Averages']),
        'facts': ((json['Facts'] as Array<any>).map(RigCardFactSeriesDtoFromJSON)),
    };
}

export function RigCardGroupFactDtoToJSON(value?: RigCardGroupFactDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DataState': ResultDataStateToJSON(value.dataState),
        'TabId': value.tabId,
        'Averages': RigCardAveragesToJSON(value.averages),
        'Facts': ((value.facts as Array<any>).map(RigCardFactSeriesDtoToJSON)),
    };
}

