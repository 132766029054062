/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TimelineEventType = {
    Note: 'Note',
    Instruction: 'Instruction',
    Warning: 'Warning',
    Hazard: 'Hazard'
} as const;
export type TimelineEventType = typeof TimelineEventType[keyof typeof TimelineEventType];


export function TimelineEventTypeFromJSON(json: any): TimelineEventType {
    return TimelineEventTypeFromJSONTyped(json, false);
}

export function TimelineEventTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimelineEventType {
    return json as TimelineEventType;
}

export function TimelineEventTypeToJSON(value?: TimelineEventType | null): any {
    return value as any;
}

