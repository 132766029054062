/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RigLeaderboardScoreCardCommentAco,
    RigLeaderboardScoreCardCommentAcoFromJSON,
    RigLeaderboardScoreCardCommentAcoFromJSONTyped,
    RigLeaderboardScoreCardCommentAcoToJSON,
} from './RigLeaderboardScoreCardCommentAco';
import {
    RigLeaderboardScoreSummaryAco,
    RigLeaderboardScoreSummaryAcoFromJSON,
    RigLeaderboardScoreSummaryAcoFromJSONTyped,
    RigLeaderboardScoreSummaryAcoToJSON,
} from './RigLeaderboardScoreSummaryAco';
import {
    RigLeaderboardWellAco,
    RigLeaderboardWellAcoFromJSON,
    RigLeaderboardWellAcoFromJSONTyped,
    RigLeaderboardWellAcoToJSON,
} from './RigLeaderboardWellAco';

/**
 * 
 * @export
 * @interface RigLeaderboardAco
 */
export interface RigLeaderboardAco {
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardAco
     */
    rigName: string;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardAco
     */
    rigType: string;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardAco
     */
    horsepowerRating: string;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardAco
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardAco
     */
    operatingCenter: string;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardAco
     */
    technology: string;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardAco
     */
    overallRigScorePercentage: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardAco
     */
    rigTrendPercentage: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardAco
     */
    timeVsTargetHrs: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardAco
     */
    timeVsBaseHrs: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardAco
     */
    rigDowntimePercentage: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardAco
     */
    totalExceptionCount: number;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardAco
     */
    totalExceptionTimeHrs: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardAco
     */
    scorecardTasksPercentage: number | null;
    /**
     * 
     * @type {RigLeaderboardScoreSummaryAco}
     * @memberof RigLeaderboardAco
     */
    scoreSummary: RigLeaderboardScoreSummaryAco;
    /**
     * 
     * @type {Array<RigLeaderboardWellAco>}
     * @memberof RigLeaderboardAco
     */
    wells: Array<RigLeaderboardWellAco>;
    /**
     * 
     * @type {Array<RigLeaderboardScoreCardCommentAco>}
     * @memberof RigLeaderboardAco
     */
    scorecardComments: Array<RigLeaderboardScoreCardCommentAco>;
}

export function RigLeaderboardAcoFromJSON(json: any): RigLeaderboardAco {
    return RigLeaderboardAcoFromJSONTyped(json, false);
}

export function RigLeaderboardAcoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigLeaderboardAco {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rigName': json['RigName'],
        'rigType': json['RigType'],
        'horsepowerRating': json['HorsepowerRating'],
        'country': json['Country'],
        'operatingCenter': json['OperatingCenter'],
        'technology': json['Technology'],
        'overallRigScorePercentage': json['OverallRigScorePercentage'],
        'rigTrendPercentage': json['RigTrendPercentage'],
        'timeVsTargetHrs': json['TimeVsTargetHrs'],
        'timeVsBaseHrs': json['TimeVsBaseHrs'],
        'rigDowntimePercentage': json['RigDowntimePercentage'],
        'totalExceptionCount': json['TotalExceptionCount'],
        'totalExceptionTimeHrs': json['TotalExceptionTimeHrs'],
        'scorecardTasksPercentage': json['ScorecardTasksPercentage'],
        'scoreSummary': RigLeaderboardScoreSummaryAcoFromJSON(json['ScoreSummary']),
        'wells': ((json['Wells'] as Array<any>).map(RigLeaderboardWellAcoFromJSON)),
        'scorecardComments': ((json['ScorecardComments'] as Array<any>).map(RigLeaderboardScoreCardCommentAcoFromJSON)),
    };
}

export function RigLeaderboardAcoToJSON(value?: RigLeaderboardAco | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'RigName': value.rigName,
        'RigType': value.rigType,
        'HorsepowerRating': value.horsepowerRating,
        'Country': value.country,
        'OperatingCenter': value.operatingCenter,
        'Technology': value.technology,
        'OverallRigScorePercentage': value.overallRigScorePercentage,
        'RigTrendPercentage': value.rigTrendPercentage,
        'TimeVsTargetHrs': value.timeVsTargetHrs,
        'TimeVsBaseHrs': value.timeVsBaseHrs,
        'RigDowntimePercentage': value.rigDowntimePercentage,
        'TotalExceptionCount': value.totalExceptionCount,
        'TotalExceptionTimeHrs': value.totalExceptionTimeHrs,
        'ScorecardTasksPercentage': value.scorecardTasksPercentage,
        'ScoreSummary': RigLeaderboardScoreSummaryAcoToJSON(value.scoreSummary),
        'Wells': ((value.wells as Array<any>).map(RigLeaderboardWellAcoToJSON)),
        'ScorecardComments': ((value.scorecardComments as Array<any>).map(RigLeaderboardScoreCardCommentAcoToJSON)),
    };
}

