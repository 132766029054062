/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SystemOfMeasurementType = {
    Metric: 'Metric',
    Imperial: 'Imperial'
} as const;
export type SystemOfMeasurementType = typeof SystemOfMeasurementType[keyof typeof SystemOfMeasurementType];


export function SystemOfMeasurementTypeFromJSON(json: any): SystemOfMeasurementType {
    return SystemOfMeasurementTypeFromJSONTyped(json, false);
}

export function SystemOfMeasurementTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SystemOfMeasurementType {
    return json as SystemOfMeasurementType;
}

export function SystemOfMeasurementTypeToJSON(value?: SystemOfMeasurementType | null): any {
    return value as any;
}

