/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DashboardType,
    DashboardTypeFromJSON,
    DashboardTypeFromJSONTyped,
    DashboardTypeToJSON,
} from './DashboardType';

/**
 * 
 * @export
 * @interface LensTabCreationQueryDto
 */
export interface LensTabCreationQueryDto {
    /**
     * 
     * @type {DashboardType}
     * @memberof LensTabCreationQueryDto
     */
    dashboardType: DashboardType;
    /**
     * 
     * @type {string}
     * @memberof LensTabCreationQueryDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof LensTabCreationQueryDto
     */
    copySourceTabId?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof LensTabCreationQueryDto
     */
    visibilityRigIds?: Array<number> | null;
}

export function LensTabCreationQueryDtoFromJSON(json: any): LensTabCreationQueryDto {
    return LensTabCreationQueryDtoFromJSONTyped(json, false);
}

export function LensTabCreationQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LensTabCreationQueryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dashboardType': DashboardTypeFromJSON(json['DashboardType']),
        'name': json['Name'],
        'copySourceTabId': !exists(json, 'CopySourceTabId') ? undefined : json['CopySourceTabId'],
        'visibilityRigIds': !exists(json, 'VisibilityRigIds') ? undefined : json['VisibilityRigIds'],
    };
}

export function LensTabCreationQueryDtoToJSON(value?: LensTabCreationQueryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DashboardType': DashboardTypeToJSON(value.dashboardType),
        'Name': value.name,
        'CopySourceTabId': value.copySourceTabId,
        'VisibilityRigIds': value.visibilityRigIds,
    };
}

