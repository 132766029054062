/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BoilerSlotType,
    BoilerSlotTypeFromJSON,
    BoilerSlotTypeFromJSONTyped,
    BoilerSlotTypeToJSON,
} from './BoilerSlotType';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    FiltersDto,
    FiltersDtoFromJSON,
    FiltersDtoFromJSONTyped,
    FiltersDtoToJSON,
} from './FiltersDto';
import {
    GeneratorSlotType,
    GeneratorSlotTypeFromJSON,
    GeneratorSlotTypeFromJSONTyped,
    GeneratorSlotTypeToJSON,
} from './GeneratorSlotType';

/**
 * 
 * @export
 * @interface GhgEmissionsQueryDto
 */
export interface GhgEmissionsQueryDto {
    /**
     * 
     * @type {boolean}
     * @memberof GhgEmissionsQueryDto
     */
    useMockData: boolean;
    /**
     * 
     * @type {number}
     * @memberof GhgEmissionsQueryDto
     */
    sampleCount: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof GhgEmissionsQueryDto
     */
    comparisonWellIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof GhgEmissionsQueryDto
     */
    selectedRigIds?: Array<number> | null;
    /**
     * 
     * @type {FiltersDto}
     * @memberof GhgEmissionsQueryDto
     */
    selectedFilters: FiltersDto;
    /**
     * 
     * @type {number}
     * @memberof GhgEmissionsQueryDto
     */
    zoomStartDuration?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GhgEmissionsQueryDto
     */
    zoomEndDuration?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GhgEmissionsQueryDto
     */
    zoomStartDepth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GhgEmissionsQueryDto
     */
    zoomEndDepth?: number | null;
    /**
     * 
     * @type {DateDto}
     * @memberof GhgEmissionsQueryDto
     */
    from: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof GhgEmissionsQueryDto
     */
    to: DateDto;
    /**
     * 
     * @type {number}
     * @memberof GhgEmissionsQueryDto
     */
    wellId: number;
    /**
     * 
     * @type {Array<GeneratorSlotType>}
     * @memberof GhgEmissionsQueryDto
     */
    selectedGenerators?: Array<GeneratorSlotType> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof GhgEmissionsQueryDto
     */
    selectedOperationClassIds?: Array<number> | null;
    /**
     * 
     * @type {Array<BoilerSlotType>}
     * @memberof GhgEmissionsQueryDto
     */
    selectedBoilers?: Array<BoilerSlotType> | null;
}

export function GhgEmissionsQueryDtoFromJSON(json: any): GhgEmissionsQueryDto {
    return GhgEmissionsQueryDtoFromJSONTyped(json, false);
}

export function GhgEmissionsQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GhgEmissionsQueryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'useMockData': json['UseMockData'],
        'sampleCount': json['SampleCount'],
        'comparisonWellIds': !exists(json, 'ComparisonWellIds') ? undefined : json['ComparisonWellIds'],
        'selectedRigIds': !exists(json, 'SelectedRigIds') ? undefined : json['SelectedRigIds'],
        'selectedFilters': FiltersDtoFromJSON(json['SelectedFilters']),
        'zoomStartDuration': !exists(json, 'ZoomStartDuration') ? undefined : json['ZoomStartDuration'],
        'zoomEndDuration': !exists(json, 'ZoomEndDuration') ? undefined : json['ZoomEndDuration'],
        'zoomStartDepth': !exists(json, 'ZoomStartDepth') ? undefined : json['ZoomStartDepth'],
        'zoomEndDepth': !exists(json, 'ZoomEndDepth') ? undefined : json['ZoomEndDepth'],
        'from': DateDtoFromJSON(json['From']),
        'to': DateDtoFromJSON(json['To']),
        'wellId': json['WellId'],
        'selectedGenerators': !exists(json, 'SelectedGenerators') ? undefined : (json['SelectedGenerators'] === null ? null : (json['SelectedGenerators'] as Array<any>).map(GeneratorSlotTypeFromJSON)),
        'selectedOperationClassIds': !exists(json, 'SelectedOperationClassIds') ? undefined : json['SelectedOperationClassIds'],
        'selectedBoilers': !exists(json, 'SelectedBoilers') ? undefined : (json['SelectedBoilers'] === null ? null : (json['SelectedBoilers'] as Array<any>).map(BoilerSlotTypeFromJSON)),
    };
}

export function GhgEmissionsQueryDtoToJSON(value?: GhgEmissionsQueryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'UseMockData': value.useMockData,
        'SampleCount': value.sampleCount,
        'ComparisonWellIds': value.comparisonWellIds,
        'SelectedRigIds': value.selectedRigIds,
        'SelectedFilters': FiltersDtoToJSON(value.selectedFilters),
        'ZoomStartDuration': value.zoomStartDuration,
        'ZoomEndDuration': value.zoomEndDuration,
        'ZoomStartDepth': value.zoomStartDepth,
        'ZoomEndDepth': value.zoomEndDepth,
        'From': DateDtoToJSON(value.from),
        'To': DateDtoToJSON(value.to),
        'WellId': value.wellId,
        'SelectedGenerators': value.selectedGenerators === undefined ? undefined : (value.selectedGenerators === null ? null : (value.selectedGenerators as Array<any>).map(GeneratorSlotTypeToJSON)),
        'SelectedOperationClassIds': value.selectedOperationClassIds,
        'SelectedBoilers': value.selectedBoilers === undefined ? undefined : (value.selectedBoilers === null ? null : (value.selectedBoilers as Array<any>).map(BoilerSlotTypeToJSON)),
    };
}

