/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    SurveyPointDto,
    SurveyPointDtoFromJSON,
    SurveyPointDtoFromJSONTyped,
    SurveyPointDtoToJSON,
} from './SurveyPointDto';

/**
 * 
 * @export
 * @interface SurveyDto
 */
export interface SurveyDto {
    /**
     * 
     * @type {Array<SurveyPointDto>}
     * @memberof SurveyDto
     */
    points?: Array<SurveyPointDto> | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof SurveyDto
     */
    dataState: ResultDataState;
}

export function SurveyDtoFromJSON(json: any): SurveyDto {
    return SurveyDtoFromJSONTyped(json, false);
}

export function SurveyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'points': !exists(json, 'Points') ? undefined : (json['Points'] === null ? null : (json['Points'] as Array<any>).map(SurveyPointDtoFromJSON)),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function SurveyDtoToJSON(value?: SurveyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Points': value.points === undefined ? undefined : (value.points === null ? null : (value.points as Array<any>).map(SurveyPointDtoToJSON)),
        'DataState': ResultDataStateToJSON(value.dataState),
    };
}

