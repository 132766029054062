/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DashboardBlockSize = {
    Unknown: 'Unknown',
    Small: 'Small',
    Medium: 'Medium',
    Large: 'Large',
    FullWidth: 'FullWidth'
} as const;
export type DashboardBlockSize = typeof DashboardBlockSize[keyof typeof DashboardBlockSize];


export function DashboardBlockSizeFromJSON(json: any): DashboardBlockSize {
    return DashboardBlockSizeFromJSONTyped(json, false);
}

export function DashboardBlockSizeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardBlockSize {
    return json as DashboardBlockSize;
}

export function DashboardBlockSizeToJSON(value?: DashboardBlockSize | null): any {
    return value as any;
}

