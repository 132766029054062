/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TorqueAndDragActivityType,
    TorqueAndDragActivityTypeFromJSON,
    TorqueAndDragActivityTypeFromJSONTyped,
    TorqueAndDragActivityTypeToJSON,
} from './TorqueAndDragActivityType';
import {
    TorqueAndDragPointDto,
    TorqueAndDragPointDtoFromJSON,
    TorqueAndDragPointDtoFromJSONTyped,
    TorqueAndDragPointDtoToJSON,
} from './TorqueAndDragPointDto';

/**
 * 
 * @export
 * @interface TorqueAndDragCurveDto
 */
export interface TorqueAndDragCurveDto {
    /**
     * 
     * @type {TorqueAndDragActivityType}
     * @memberof TorqueAndDragCurveDto
     */
    activity: TorqueAndDragActivityType;
    /**
     * 
     * @type {Array<TorqueAndDragPointDto>}
     * @memberof TorqueAndDragCurveDto
     */
    points?: Array<TorqueAndDragPointDto> | null;
}

export function TorqueAndDragCurveDtoFromJSON(json: any): TorqueAndDragCurveDto {
    return TorqueAndDragCurveDtoFromJSONTyped(json, false);
}

export function TorqueAndDragCurveDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TorqueAndDragCurveDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activity': TorqueAndDragActivityTypeFromJSON(json['Activity']),
        'points': !exists(json, 'Points') ? undefined : (json['Points'] === null ? null : (json['Points'] as Array<any>).map(TorqueAndDragPointDtoFromJSON)),
    };
}

export function TorqueAndDragCurveDtoToJSON(value?: TorqueAndDragCurveDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Activity': TorqueAndDragActivityTypeToJSON(value.activity),
        'Points': value.points === undefined ? undefined : (value.points === null ? null : (value.points as Array<any>).map(TorqueAndDragPointDtoToJSON)),
    };
}

