/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AllRigsDataFeedQueryDto,
    AllRigsDataFeedQueryDtoFromJSON,
    AllRigsDataFeedQueryDtoToJSON,
    AllWellsDataFeedQueryDto,
    AllWellsDataFeedQueryDtoFromJSON,
    AllWellsDataFeedQueryDtoToJSON,
    DataFeedRefreshInfoDto,
    DataFeedRefreshInfoDtoFromJSON,
    DataFeedRefreshInfoDtoToJSON,
    FeedUpdateRequestDto,
    FeedUpdateRequestDtoFromJSON,
    FeedUpdateRequestDtoToJSON,
    OperationCategoryType,
    OperationCategoryTypeFromJSON,
    OperationCategoryTypeToJSON,
} from '../models';

export interface ApiDataFeedsLastOperationCategoryWellWellIdGetRequest {
    wellId: number;
}

export interface ApiDataFeedsPutRequest {
    feedUpdateRequestDto?: FeedUpdateRequestDto;
}

export interface ApiDataFeedsRigsPutRequest {
    allRigsDataFeedQueryDto?: AllRigsDataFeedQueryDto;
}

export interface ApiDataFeedsWellsPutRequest {
    allWellsDataFeedQueryDto?: AllWellsDataFeedQueryDto;
}

/**
 * 
 */
export class DataFeedsApi extends runtime.BaseAPI {

    /**
     */
    async apiDataFeedsLastOperationCategoryWellWellIdGetRaw(requestParameters: ApiDataFeedsLastOperationCategoryWellWellIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OperationCategoryType>> {
        if (requestParameters.wellId === null || requestParameters.wellId === undefined) {
            throw new runtime.RequiredError('wellId','Required parameter requestParameters.wellId was null or undefined when calling apiDataFeedsLastOperationCategoryWellWellIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/DataFeeds/LastOperationCategory/Well/{wellId}`.replace(`{${"wellId"}}`, encodeURIComponent(String(requestParameters.wellId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OperationCategoryTypeFromJSON(jsonValue));
    }

    /**
     */
    async apiDataFeedsLastOperationCategoryWellWellIdGet(requestParameters: ApiDataFeedsLastOperationCategoryWellWellIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OperationCategoryType> {
        const response = await this.apiDataFeedsLastOperationCategoryWellWellIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDataFeedsPutRaw(requestParameters: ApiDataFeedsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<DataFeedRefreshInfoDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/DataFeeds`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FeedUpdateRequestDtoToJSON(requestParameters.feedUpdateRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DataFeedRefreshInfoDtoFromJSON));
    }

    /**
     */
    async apiDataFeedsPut(requestParameters: ApiDataFeedsPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<DataFeedRefreshInfoDto>> {
        const response = await this.apiDataFeedsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDataFeedsRigsPutRaw(requestParameters: ApiDataFeedsRigsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/DataFeeds/Rigs`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AllRigsDataFeedQueryDtoToJSON(requestParameters.allRigsDataFeedQueryDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiDataFeedsRigsPut(requestParameters: ApiDataFeedsRigsPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.apiDataFeedsRigsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDataFeedsWellsPutRaw(requestParameters: ApiDataFeedsWellsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/DataFeeds/Wells`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AllWellsDataFeedQueryDtoToJSON(requestParameters.allWellsDataFeedQueryDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiDataFeedsWellsPut(requestParameters: ApiDataFeedsWellsPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.apiDataFeedsWellsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
