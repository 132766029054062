/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DisplayOption = {
    StandDepth: 'StandDepth',
    Date: 'Date',
    DateShift: 'DateShift',
    Well: 'Well',
    HoleSection: 'HoleSection',
    DirectionInterval: 'DirectionInterval',
    Shift: 'Shift'
} as const;
export type DisplayOption = typeof DisplayOption[keyof typeof DisplayOption];


export function DisplayOptionFromJSON(json: any): DisplayOption {
    return DisplayOptionFromJSONTyped(json, false);
}

export function DisplayOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayOption {
    return json as DisplayOption;
}

export function DisplayOptionToJSON(value?: DisplayOption | null): any {
    return value as any;
}

