/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const GasType = {
    Unknown: 'Unknown',
    Co2: 'CO2',
    Ch4: 'CH4',
    N2O: 'N2O',
    Co: 'CO',
    Nox: 'NOx'
} as const;
export type GasType = typeof GasType[keyof typeof GasType];


export function GasTypeFromJSON(json: any): GasType {
    return GasTypeFromJSONTyped(json, false);
}

export function GasTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): GasType {
    return json as GasType;
}

export function GasTypeToJSON(value?: GasType | null): any {
    return value as any;
}

