/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RigCardDataType = {
    Unknown: 'Unknown',
    KpiValue: 'KpiValue',
    TargetValue: 'TargetValue',
    PdBenchmarkValue: 'PdBenchmarkValue',
    BaseValue: 'BaseValue',
    OperatorScore: 'OperatorScore',
    RigScore: 'RigScore',
    ImprovementScore: 'ImprovementScore',
    TargetDeltaTime: 'TargetDeltaTime',
    InvisibleLostTime: 'InvisibleLostTime',
    SavedTime: 'SavedTime'
} as const;
export type RigCardDataType = typeof RigCardDataType[keyof typeof RigCardDataType];


export function RigCardDataTypeFromJSON(json: any): RigCardDataType {
    return RigCardDataTypeFromJSONTyped(json, false);
}

export function RigCardDataTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigCardDataType {
    return json as RigCardDataType;
}

export function RigCardDataTypeToJSON(value?: RigCardDataType | null): any {
    return value as any;
}

