/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StatCardInfoDto,
    StatCardInfoDtoFromJSON,
    StatCardInfoDtoToJSON,
    StatCardQueryDto,
    StatCardQueryDtoFromJSON,
    StatCardQueryDtoToJSON,
    StatCardUserLensDto,
    StatCardUserLensDtoFromJSON,
    StatCardUserLensDtoToJSON,
} from '../models';

export interface ApiStatCardUserLensesIdFactsPutRequest {
    id: number;
    statCardQueryDto?: StatCardQueryDto;
}

export interface ApiStatCardUserLensesIdPutRequest {
    id: number;
    statCardUserLensDto?: StatCardUserLensDto;
}

export interface ApiStatCardUserLensesPostRequest {
    statCardUserLensDto?: StatCardUserLensDto;
}

/**
 * 
 */
export class StatCardUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiStatCardUserLensesIdFactsPutRaw(requestParameters: ApiStatCardUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StatCardInfoDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiStatCardUserLensesIdFactsPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/StatCardUserLenses/{id}/Facts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StatCardQueryDtoToJSON(requestParameters.statCardQueryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatCardInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiStatCardUserLensesIdFactsPut(requestParameters: ApiStatCardUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StatCardInfoDto> {
        const response = await this.apiStatCardUserLensesIdFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStatCardUserLensesIdPutRaw(requestParameters: ApiStatCardUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StatCardUserLensDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiStatCardUserLensesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/StatCardUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StatCardUserLensDtoToJSON(requestParameters.statCardUserLensDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatCardUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiStatCardUserLensesIdPut(requestParameters: ApiStatCardUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StatCardUserLensDto> {
        const response = await this.apiStatCardUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStatCardUserLensesPostRaw(requestParameters: ApiStatCardUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StatCardUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/StatCardUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatCardUserLensDtoToJSON(requestParameters.statCardUserLensDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatCardUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiStatCardUserLensesPost(requestParameters: ApiStatCardUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StatCardUserLensDto> {
        const response = await this.apiStatCardUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
