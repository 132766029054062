import { Arrow, StyledDropdownButton } from "atoms/common";
import { Title } from "atoms/Typography";
import { PDComponent } from "components/PDComponents";
import { StyledSpaceForLayout } from "components/PDComponents/Search/style";
import { useDashboardType } from "hooks/useDashboardType";
import { useRigs } from "hooks/useRigs";
import { useWellDetails } from "hooks/useWellDetails";
import { useWellShortInfoSuspended } from "hooks/useWellShortInfo";
import { useMemo, useState } from "react";
import { Track } from "services/Mixpanel";
import { useDebounce } from "use-debounce";
import { Col, Popover, Row, Space } from "utils/componentLibrary";
import { statusIconSelector } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

import { HoverCol, StyledButton } from "./styles";

export function WellSelector({
  selectedWell,
  onWellChange,
}: {
  selectedWell: number;
  onWellChange?: (well: number) => void;
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 200);
  const [showSelect, setShowSelect] = useState(false);
  const onWellChangeWrapper = (well: number) => {
    setSearchTerm("");
    setShowSelect(false);
    if (onWellChange) onWellChange(well);
  };

  const { data: wellShortInfo, isLoading: isLoadingWellData } = useWellShortInfoSuspended();
  const { data: selectedWellInfo, isLoading: isLoadingSelectedWellInfo } = useWellDetails(selectedWell);

  const { isEvergreen } = useDashboardType();

  const wellData = useMemo(
    () => wellShortInfo?.list?.filter((well) => (isEvergreen ? well.isEverGreen : true)),
    [wellShortInfo?.list, isEvergreen],
  );

  const { atomThemeVariant, themeStyle } = useCustomTheme();

  const rigs = useRigs();
  const selectionOptions = useMemo(
    () =>
      isLoadingWellData || isLoadingSelectedWellInfo
        ? []
        : (wellData ?? []).filter(
            (well) =>
              well.id !== selectedWell &&
              well?.name?.toLocaleLowerCase().includes(debouncedSearchTerm.toLocaleLowerCase().trim()),
          ),
    [debouncedSearchTerm, isLoadingSelectedWellInfo, isLoadingWellData, selectedWell, wellData],
  );

  const uniqueRigIdsLabel = useMemo(() => {
    const uniqueRigIds = [...new Set(selectedWellInfo?.jobs.map((e) => e.rigId))];
    return uniqueRigIds.map((e) => rigs?.data?.byId[e]?.shortName || "").join(", ");
  }, [selectedWellInfo?.jobs, rigs?.data]);

  return (
    <Popover
      style={{
        padding: 0,
      }}
      content={
        <StyledSpaceForLayout width={464}>
          <PDComponent.ListWithSearch
            width={464}
            placeholder={"Search..."}
            options={selectionOptions.map((well) => ({
              ...well,
              component: (
                <Row gutter={12} wrap={false}>
                  <HoverCol>
                    <Col flex="0 auto">{statusIconSelector(well.status)}</Col>
                    <Col flex="1 auto">
                      <Title
                        level={3}
                        variant={atomThemeVariant}
                        style={{
                          display: "inline-block",
                          width: "100%",
                        }}
                      >
                        {well.name}
                      </Title>
                    </Col>
                  </HoverCol>
                </Row>
              ),
            }))}
            values={selectionOptions.map((well) => well.id)}
            onClick={(option) => {
              Track.clickEvent(`Select Well - ${option.name}`);
              onWellChangeWrapper(option.id);
            }}
          />
        </StyledSpaceForLayout>
      }
      trigger="click"
      placement="bottomLeft"
      open={showSelect}
      onOpenChange={(show) => setShowSelect(show)}
      destroyTooltipOnHide
    >
      <StyledButton onClick={() => setShowSelect(!showSelect)}>
        <Space
          direction="horizontal"
          size={6}
          style={{
            padding: "9px 0",
          }}
        >
          {selectedWellInfo?.status ? statusIconSelector(selectedWellInfo?.status) : null}
          <Title
            level={3}
            variant={atomThemeVariant}
            title={selectedWellInfo?.name || ""}
            style={{ overflow: "hidden", textAlign: "left", maxWidth: "320px", textOverflow: "ellipsis" }}
          >
            {selectedWellInfo?.name}
          </Title>
          <span>·</span>
          <Title level={3} variant="faded" style={{ display: "inline-block" }}>
            {uniqueRigIdsLabel}
          </Title>
        </Space>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <StyledDropdownButton
            style={{
              paddingTop: showSelect ? 10 : 7,
            }}
          >
            <Arrow up={showSelect} color={themeStyle.colors.secondary_accent} />
          </StyledDropdownButton>
        </div>
      </StyledButton>
    </Popover>
  );
}
