import type { RigCardFactDto, RigFleetPerformanceCardUserLensDto } from "apis/oag";
import { isObject } from "lodash";
import {
  NetTimeType,
  ScoreType,
  SelectedMetric,
} from "pages/AllRigs/components/LeaderboardSection/LeaderboardMetricSelectionContext";
import { drillingProductivityTypes } from "pages/AllRigs/components/LeaderboardSection/utils";

import type { FactWithLabel } from "./interfaces";

export function groupAndSortFacts({
  selectedMetric,
  selectedNetTime,
  selectedScore,
  allFacts,
}: {
  selectedMetric: SelectedMetric;
  selectedNetTime?: NetTimeType;
  selectedScore?: ScoreType;
  allFacts: RigCardFactDto[];
}) {
  let unsortedFacts: Array<FactWithLabel> = [];
  if (selectedMetric === SelectedMetric.NetTimes) {
    if (selectedNetTime === NetTimeType.Target) {
      unsortedFacts = allFacts
        .filter((fact) => isObject(fact.targetDeltaTime))
        .map((fact) => ({ ...fact.targetDeltaTime, label: fact.label }));
    } else if (selectedNetTime === NetTimeType.LostTime) {
      unsortedFacts = allFacts
        .filter((fact) => isObject(fact.invisibleLostTime))
        .map((fact) => ({ ...fact.invisibleLostTime, label: fact.label }));
    } else if (selectedNetTime === NetTimeType.TimeSaved) {
      unsortedFacts = allFacts
        .filter((fact) => isObject(fact.savedTime))
        .map((fact) => ({ ...fact.savedTime, label: fact.label }));
    }
  } else if (selectedMetric === SelectedMetric.Scores) {
    if (selectedScore === ScoreType.OperatorScore) {
      unsortedFacts = allFacts
        .filter((fact) => isObject(fact.operatorScore))
        .map((fact) => ({ ...fact.operatorScore, label: fact.label }));
    } else if (selectedScore === ScoreType.ImprovementScore) {
      unsortedFacts = allFacts
        .filter((fact) => isObject(fact.improvementScore))
        .map((fact) => ({ ...fact.improvementScore, label: fact.label }));
    } else if (selectedScore === ScoreType.RigScore) {
      unsortedFacts = allFacts
        .filter((fact) => isObject(fact.rigScore))
        .map((fact) => ({ ...fact.rigScore, label: fact.label }));
    }
  } else {
    unsortedFacts = allFacts
      .filter((fact) => isObject(fact.kpiValue))
      .map((fact) => ({ ...fact.kpiValue, label: fact.label }));
  }

  return unsortedFacts.sort((a, b) => b.rank - a.rank);
}

export function isDrillingProductivityLens(lens: RigFleetPerformanceCardUserLensDto): boolean {
  return drillingProductivityTypes.includes(lens.cardKpiType);
}
