/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BinCoordinates,
    BinCoordinatesFromJSON,
    BinCoordinatesFromJSONTyped,
    BinCoordinatesToJSON,
} from './BinCoordinates';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    DecimalRange,
    DecimalRangeFromJSON,
    DecimalRangeFromJSONTyped,
    DecimalRangeToJSON,
} from './DecimalRange';
import {
    ParameterHeatmapBin,
    ParameterHeatmapBinFromJSON,
    ParameterHeatmapBinFromJSONTyped,
    ParameterHeatmapBinToJSON,
} from './ParameterHeatmapBin';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    ServiceMessageDto,
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import {
    StopWatchDto,
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';

/**
 * 
 * @export
 * @interface ParameterHeatmapResultSetDto
 */
export interface ParameterHeatmapResultSetDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof ParameterHeatmapResultSetDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof ParameterHeatmapResultSetDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapResultSetDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof ParameterHeatmapResultSetDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {ResultDataState}
     * @memberof ParameterHeatmapResultSetDto
     */
    dataState: ResultDataState;
    /**
     * 
     * @type {Array<ParameterHeatmapBin>}
     * @memberof ParameterHeatmapResultSetDto
     */
    bins?: Array<ParameterHeatmapBin> | null;
    /**
     * 
     * @type {ParameterHeatmapBin}
     * @memberof ParameterHeatmapResultSetDto
     */
    focalRecommendationBins: ParameterHeatmapBin;
    /**
     * 
     * @type {ParameterHeatmapBin}
     * @memberof ParameterHeatmapResultSetDto
     */
    offsetRecommendationBins: ParameterHeatmapBin;
    /**
     * 
     * @type {BinCoordinates}
     * @memberof ParameterHeatmapResultSetDto
     */
    lastCapturedBin: BinCoordinates;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapResultSetDto
     */
    readonly xBinCountComputed: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapResultSetDto
     */
    readonly yBinCountComputed: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapResultSetDto
     */
    xBinCountRequested: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapResultSetDto
     */
    yBinCountRequested: number;
    /**
     * 
     * @type {DecimalRange}
     * @memberof ParameterHeatmapResultSetDto
     */
    xRange: DecimalRange;
    /**
     * 
     * @type {DecimalRange}
     * @memberof ParameterHeatmapResultSetDto
     */
    yRange: DecimalRange;
}

export function ParameterHeatmapResultSetDtoFromJSON(json: any): ParameterHeatmapResultSetDto {
    return ParameterHeatmapResultSetDtoFromJSONTyped(json, false);
}

export function ParameterHeatmapResultSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterHeatmapResultSetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': !exists(json, 'Messages') ? undefined : (json['Messages'] === null ? null : (json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
        'bins': !exists(json, 'Bins') ? undefined : (json['Bins'] === null ? null : (json['Bins'] as Array<any>).map(ParameterHeatmapBinFromJSON)),
        'focalRecommendationBins': ParameterHeatmapBinFromJSON(json['FocalRecommendationBins']),
        'offsetRecommendationBins': ParameterHeatmapBinFromJSON(json['OffsetRecommendationBins']),
        'lastCapturedBin': BinCoordinatesFromJSON(json['LastCapturedBin']),
        'xBinCountComputed': json['XBinCountComputed'],
        'yBinCountComputed': json['YBinCountComputed'],
        'xBinCountRequested': json['XBinCountRequested'],
        'yBinCountRequested': json['YBinCountRequested'],
        'xRange': DecimalRangeFromJSON(json['XRange']),
        'yRange': DecimalRangeFromJSON(json['YRange']),
    };
}

export function ParameterHeatmapResultSetDtoToJSON(value?: ParameterHeatmapResultSetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value.stopWatch),
        'Messages': value.messages === undefined ? undefined : (value.messages === null ? null : (value.messages as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value.userLensId,
        'LastUpdatedAt': DateDtoToJSON(value.lastUpdatedAt),
        'DataState': ResultDataStateToJSON(value.dataState),
        'Bins': value.bins === undefined ? undefined : (value.bins === null ? null : (value.bins as Array<any>).map(ParameterHeatmapBinToJSON)),
        'FocalRecommendationBins': ParameterHeatmapBinToJSON(value.focalRecommendationBins),
        'OffsetRecommendationBins': ParameterHeatmapBinToJSON(value.offsetRecommendationBins),
        'LastCapturedBin': BinCoordinatesToJSON(value.lastCapturedBin),
        'XBinCountRequested': value.xBinCountRequested,
        'YBinCountRequested': value.yBinCountRequested,
        'XRange': DecimalRangeToJSON(value.xRange),
        'YRange': DecimalRangeToJSON(value.yRange),
    };
}

