/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BroomstickPointDto
 */
export interface BroomstickPointDto {
    /**
     * 
     * @type {number}
     * @memberof BroomstickPointDto
     */
    depth: number;
    /**
     * 
     * @type {number}
     * @memberof BroomstickPointDto
     */
    measure: number;
}

export function BroomstickPointDtoFromJSON(json: any): BroomstickPointDto {
    return BroomstickPointDtoFromJSONTyped(json, false);
}

export function BroomstickPointDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BroomstickPointDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'depth': json['Depth'],
        'measure': json['Measure'],
    };
}

export function BroomstickPointDtoToJSON(value?: BroomstickPointDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Depth': value.depth,
        'Measure': value.measure,
    };
}

