/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DimensionType,
    DimensionTypeFromJSON,
    DimensionTypeFromJSONTyped,
    DimensionTypeToJSON,
} from './DimensionType';

/**
 * 
 * @export
 * @interface RigPowerSummaryValuesDto
 */
export interface RigPowerSummaryValuesDto {
    /**
     * 
     * @type {DimensionType}
     * @memberof RigPowerSummaryValuesDto
     */
    dimension: DimensionType;
    /**
     * 
     * @type {number}
     * @memberof RigPowerSummaryValuesDto
     */
    min?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigPowerSummaryValuesDto
     */
    mean?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigPowerSummaryValuesDto
     */
    max?: number | null;
}

export function RigPowerSummaryValuesDtoFromJSON(json: any): RigPowerSummaryValuesDto {
    return RigPowerSummaryValuesDtoFromJSONTyped(json, false);
}

export function RigPowerSummaryValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigPowerSummaryValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dimension': DimensionTypeFromJSON(json['Dimension']),
        'min': !exists(json, 'Min') ? undefined : json['Min'],
        'mean': !exists(json, 'Mean') ? undefined : json['Mean'],
        'max': !exists(json, 'Max') ? undefined : json['Max'],
    };
}

export function RigPowerSummaryValuesDtoToJSON(value?: RigPowerSummaryValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Dimension': DimensionTypeToJSON(value.dimension),
        'Min': value.min,
        'Mean': value.mean,
        'Max': value.max,
    };
}

