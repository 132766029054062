/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    StandKpiType,
    StandKpiTypeFromJSON,
    StandKpiTypeFromJSONTyped,
    StandKpiTypeToJSON,
} from './StandKpiType';

/**
 * 
 * @export
 * @interface BarSliceDto
 */
export interface BarSliceDto {
    /**
     * 
     * @type {StandKpiType}
     * @memberof BarSliceDto
     */
    kpiTypeId: StandKpiType;
    /**
     * 
     * @type {number}
     * @memberof BarSliceDto
     */
    position: number;
    /**
     * 
     * @type {number}
     * @memberof BarSliceDto
     */
    value: number;
    /**
     * 
     * @type {number}
     * @memberof BarSliceDto
     */
    distribution: number;
    /**
     * 
     * @type {number}
     * @memberof BarSliceDto
     */
    targetValue?: number | null;
    /**
     * 
     * @type {DateDto}
     * @memberof BarSliceDto
     */
    minDate: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof BarSliceDto
     */
    maxDate: DateDto;
    /**
     * 
     * @type {number}
     * @memberof BarSliceDto
     */
    minDepth: number;
    /**
     * 
     * @type {number}
     * @memberof BarSliceDto
     */
    maxDepth: number;
}

export function BarSliceDtoFromJSON(json: any): BarSliceDto {
    return BarSliceDtoFromJSONTyped(json, false);
}

export function BarSliceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BarSliceDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kpiTypeId': StandKpiTypeFromJSON(json['KpiTypeId']),
        'position': json['Position'],
        'value': json['Value'],
        'distribution': json['Distribution'],
        'targetValue': !exists(json, 'TargetValue') ? undefined : json['TargetValue'],
        'minDate': DateDtoFromJSON(json['MinDate']),
        'maxDate': DateDtoFromJSON(json['MaxDate']),
        'minDepth': json['MinDepth'],
        'maxDepth': json['MaxDepth'],
    };
}

export function BarSliceDtoToJSON(value?: BarSliceDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'KpiTypeId': StandKpiTypeToJSON(value.kpiTypeId),
        'Position': value.position,
        'Value': value.value,
        'Distribution': value.distribution,
        'TargetValue': value.targetValue,
        'MinDate': DateDtoToJSON(value.minDate),
        'MaxDate': DateDtoToJSON(value.maxDate),
        'MinDepth': value.minDepth,
        'MaxDepth': value.maxDepth,
    };
}

