/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InspectionTvdPointDto,
    InspectionTvdPointDtoFromJSON,
    InspectionTvdPointDtoFromJSONTyped,
    InspectionTvdPointDtoToJSON,
} from './InspectionTvdPointDto';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    ServiceMessageDto,
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import {
    StandDrillingInfoDto,
    StandDrillingInfoDtoFromJSON,
    StandDrillingInfoDtoFromJSONTyped,
    StandDrillingInfoDtoToJSON,
} from './StandDrillingInfoDto';
import {
    StopWatchDto,
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';

/**
 * 
 * @export
 * @interface StandKpiInspectionResultDto
 */
export interface StandKpiInspectionResultDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof StandKpiInspectionResultDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof StandKpiInspectionResultDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof StandKpiInspectionResultDto
     */
    dataState: ResultDataState;
    /**
     * 
     * @type {number}
     * @memberof StandKpiInspectionResultDto
     */
    wellId: number;
    /**
     * 
     * @type {Array<InspectionTvdPointDto>}
     * @memberof StandKpiInspectionResultDto
     */
    series?: Array<InspectionTvdPointDto> | null;
    /**
     * 
     * @type {StandDrillingInfoDto}
     * @memberof StandKpiInspectionResultDto
     */
    stand: StandDrillingInfoDto;
}

export function StandKpiInspectionResultDtoFromJSON(json: any): StandKpiInspectionResultDto {
    return StandKpiInspectionResultDtoFromJSONTyped(json, false);
}

export function StandKpiInspectionResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandKpiInspectionResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': !exists(json, 'Messages') ? undefined : (json['Messages'] === null ? null : (json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'dataState': ResultDataStateFromJSON(json['DataState']),
        'wellId': json['WellId'],
        'series': !exists(json, 'Series') ? undefined : (json['Series'] === null ? null : (json['Series'] as Array<any>).map(InspectionTvdPointDtoFromJSON)),
        'stand': StandDrillingInfoDtoFromJSON(json['Stand']),
    };
}

export function StandKpiInspectionResultDtoToJSON(value?: StandKpiInspectionResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value.stopWatch),
        'Messages': value.messages === undefined ? undefined : (value.messages === null ? null : (value.messages as Array<any>).map(ServiceMessageDtoToJSON)),
        'DataState': ResultDataStateToJSON(value.dataState),
        'WellId': value.wellId,
        'Series': value.series === undefined ? undefined : (value.series === null ? null : (value.series as Array<any>).map(InspectionTvdPointDtoToJSON)),
        'Stand': StandDrillingInfoDtoToJSON(value.stand),
    };
}

