/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FuelType,
    FuelTypeFromJSON,
    FuelTypeFromJSONTyped,
    FuelTypeToJSON,
} from './FuelType';
import {
    GeneratorSlotType,
    GeneratorSlotTypeFromJSON,
    GeneratorSlotTypeFromJSONTyped,
    GeneratorSlotTypeToJSON,
} from './GeneratorSlotType';

/**
 * 
 * @export
 * @interface GeneratorStatsFactDto
 */
export interface GeneratorStatsFactDto {
    /**
     * 
     * @type {GeneratorSlotType}
     * @memberof GeneratorStatsFactDto
     */
    generator: GeneratorSlotType;
    /**
     * 
     * @type {string}
     * @memberof GeneratorStatsFactDto
     */
    generatorModel?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GeneratorStatsFactDto
     */
    rating?: number | null;
    /**
     * 
     * @type {FuelType}
     * @memberof GeneratorStatsFactDto
     */
    fuelType: FuelType;
}

export function GeneratorStatsFactDtoFromJSON(json: any): GeneratorStatsFactDto {
    return GeneratorStatsFactDtoFromJSONTyped(json, false);
}

export function GeneratorStatsFactDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneratorStatsFactDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'generator': GeneratorSlotTypeFromJSON(json['Generator']),
        'generatorModel': !exists(json, 'GeneratorModel') ? undefined : json['GeneratorModel'],
        'rating': !exists(json, 'Rating') ? undefined : json['Rating'],
        'fuelType': FuelTypeFromJSON(json['FuelType']),
    };
}

export function GeneratorStatsFactDtoToJSON(value?: GeneratorStatsFactDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Generator': GeneratorSlotTypeToJSON(value.generator),
        'GeneratorModel': value.generatorModel,
        'Rating': value.rating,
        'FuelType': FuelTypeToJSON(value.fuelType),
    };
}

