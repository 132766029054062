/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DrillingProductivityType,
    DrillingProductivityTypeFromJSON,
    DrillingProductivityTypeFromJSONTyped,
    DrillingProductivityTypeToJSON,
} from './DrillingProductivityType';
import {
    TimeUnit,
    TimeUnitFromJSON,
    TimeUnitFromJSONTyped,
    TimeUnitToJSON,
} from './TimeUnit';

/**
 * 
 * @export
 * @interface DrillingProductivityUserLensDto
 */
export interface DrillingProductivityUserLensDto {
    /**
     * 
     * @type {number}
     * @memberof DrillingProductivityUserLensDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingProductivityUserLensDto
     */
    lensTabId: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingProductivityUserLensDto
     */
    lensTemplateId: number;
    /**
     * 
     * @type {boolean}
     * @memberof DrillingProductivityUserLensDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DrillingProductivityUserLensDto
     */
    label: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DrillingProductivityUserLensDto
     */
    isSystem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DrillingProductivityUserLensDto
     */
    squeezesDisplay: boolean;
    /**
     * 
     * @type {TimeUnit}
     * @memberof DrillingProductivityUserLensDto
     */
    selectedTimeUnit: TimeUnit;
    /**
     * 
     * @type {boolean}
     * @memberof DrillingProductivityUserLensDto
     */
    isManualYaxis: boolean;
    /**
     * 
     * @type {number}
     * @memberof DrillingProductivityUserLensDto
     */
    yaxisStart?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DrillingProductivityUserLensDto
     */
    yaxisEnd?: number | null;
    /**
     * 
     * @type {DrillingProductivityType}
     * @memberof DrillingProductivityUserLensDto
     */
    drillingProductivityType: DrillingProductivityType;
}

export function DrillingProductivityUserLensDtoFromJSON(json: any): DrillingProductivityUserLensDto {
    return DrillingProductivityUserLensDtoFromJSONTyped(json, false);
}

export function DrillingProductivityUserLensDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrillingProductivityUserLensDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'lensTabId': json['LensTabId'],
        'lensTemplateId': json['LensTemplateId'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'label': json['Label'],
        'isSystem': json['IsSystem'],
        'squeezesDisplay': json['SqueezesDisplay'],
        'selectedTimeUnit': TimeUnitFromJSON(json['SelectedTimeUnit']),
        'isManualYaxis': json['IsManualYaxis'],
        'yaxisStart': !exists(json, 'YaxisStart') ? undefined : json['YaxisStart'],
        'yaxisEnd': !exists(json, 'YaxisEnd') ? undefined : json['YaxisEnd'],
        'drillingProductivityType': DrillingProductivityTypeFromJSON(json['DrillingProductivityType']),
    };
}

export function DrillingProductivityUserLensDtoToJSON(value?: DrillingProductivityUserLensDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'LensTabId': value.lensTabId,
        'LensTemplateId': value.lensTemplateId,
        'IsAvailableOnReports': value.isAvailableOnReports,
        'Label': value.label,
        'IsSystem': value.isSystem,
        'SqueezesDisplay': value.squeezesDisplay,
        'SelectedTimeUnit': TimeUnitToJSON(value.selectedTimeUnit),
        'IsManualYaxis': value.isManualYaxis,
        'YaxisStart': value.yaxisStart,
        'YaxisEnd': value.yaxisEnd,
        'DrillingProductivityType': DrillingProductivityTypeToJSON(value.drillingProductivityType),
    };
}

