import { Button as FormsButton } from "atoms/Form";
import styled from "styled-components";
import { Button, InputNumber } from "utils/componentLibrary";

export const StyledInputNumber = styled(InputNumber)`
  height: 36px;
  border-radius: 0;

  .ant-input-number-input-wrap {
    height: 100%;
  }

  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  background: ${({ theme }) => theme.themeStyle.colors.primary_accent};

  input {
    height: 100%;
    background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};
    border-color: transparent;
    border-radius: 0;
  }

  border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};

  .ant-input-number-handler-wrap {
    background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};
    border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};
    svg {
      fill: ${({ theme }) => theme.themeStyle.colors.primary_typography};
      color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
    }
  }

  .ant-input-number-handler-down {
    border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};
  }
`;

export const StyledRadiusButton = styled(Button)`
  background-color: ${({ theme }) => theme.themeStyle.colors.secondary_bg} !important;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography} !important;
  border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent} !important;
  min-width: 150px;
`;

export const StyledCircleButton = styled(FormsButton)`
  border-width: 1px;
`;
