/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RigCardAverages
 */
export interface RigCardAverages {
    /**
     * 
     * @type {number}
     * @memberof RigCardAverages
     */
    kpiValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardAverages
     */
    operatorScore?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardAverages
     */
    rigScore?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardAverages
     */
    improvementScore?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardAverages
     */
    targetDeltaTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardAverages
     */
    invisibleLostTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardAverages
     */
    savedTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardAverages
     */
    targetValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardAverages
     */
    pdBenchmarkValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardAverages
     */
    baseValue?: number | null;
}

export function RigCardAveragesFromJSON(json: any): RigCardAverages {
    return RigCardAveragesFromJSONTyped(json, false);
}

export function RigCardAveragesFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigCardAverages {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kpiValue': !exists(json, 'KpiValue') ? undefined : json['KpiValue'],
        'operatorScore': !exists(json, 'OperatorScore') ? undefined : json['OperatorScore'],
        'rigScore': !exists(json, 'RigScore') ? undefined : json['RigScore'],
        'improvementScore': !exists(json, 'ImprovementScore') ? undefined : json['ImprovementScore'],
        'targetDeltaTime': !exists(json, 'TargetDeltaTime') ? undefined : json['TargetDeltaTime'],
        'invisibleLostTime': !exists(json, 'InvisibleLostTime') ? undefined : json['InvisibleLostTime'],
        'savedTime': !exists(json, 'SavedTime') ? undefined : json['SavedTime'],
        'targetValue': !exists(json, 'TargetValue') ? undefined : json['TargetValue'],
        'pdBenchmarkValue': !exists(json, 'PdBenchmarkValue') ? undefined : json['PdBenchmarkValue'],
        'baseValue': !exists(json, 'BaseValue') ? undefined : json['BaseValue'],
    };
}

export function RigCardAveragesToJSON(value?: RigCardAverages | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'KpiValue': value.kpiValue,
        'OperatorScore': value.operatorScore,
        'RigScore': value.rigScore,
        'ImprovementScore': value.improvementScore,
        'TargetDeltaTime': value.targetDeltaTime,
        'InvisibleLostTime': value.invisibleLostTime,
        'SavedTime': value.savedTime,
        'TargetValue': value.targetValue,
        'PdBenchmarkValue': value.pdBenchmarkValue,
        'BaseValue': value.baseValue,
    };
}

