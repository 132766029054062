/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActualTvdPointDto,
    ActualTvdPointDtoFromJSON,
    ActualTvdPointDtoFromJSONTyped,
    ActualTvdPointDtoToJSON,
} from './ActualTvdPointDto';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    TvdHoleDirectionDto,
    TvdHoleDirectionDtoFromJSON,
    TvdHoleDirectionDtoFromJSONTyped,
    TvdHoleDirectionDtoToJSON,
} from './TvdHoleDirectionDto';
import {
    TvdSectionDto,
    TvdSectionDtoFromJSON,
    TvdSectionDtoFromJSONTyped,
    TvdSectionDtoToJSON,
} from './TvdSectionDto';

/**
 * 
 * @export
 * @interface ActualTvdSeriesDto
 */
export interface ActualTvdSeriesDto {
    /**
     * 
     * @type {number}
     * @memberof ActualTvdSeriesDto
     */
    wellId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof ActualTvdSeriesDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {ResultDataState}
     * @memberof ActualTvdSeriesDto
     */
    dataState: ResultDataState;
    /**
     * 
     * @type {Array<ActualTvdPointDto>}
     * @memberof ActualTvdSeriesDto
     */
    series?: Array<ActualTvdPointDto> | null;
    /**
     * 
     * @type {Array<TvdSectionDto>}
     * @memberof ActualTvdSeriesDto
     */
    sectionIntervals?: Array<TvdSectionDto> | null;
    /**
     * 
     * @type {Array<TvdHoleDirectionDto>}
     * @memberof ActualTvdSeriesDto
     */
    directionIntervals?: Array<TvdHoleDirectionDto> | null;
}

export function ActualTvdSeriesDtoFromJSON(json: any): ActualTvdSeriesDto {
    return ActualTvdSeriesDtoFromJSONTyped(json, false);
}

export function ActualTvdSeriesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActualTvdSeriesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'wellId': json['WellId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
        'series': !exists(json, 'Series') ? undefined : (json['Series'] === null ? null : (json['Series'] as Array<any>).map(ActualTvdPointDtoFromJSON)),
        'sectionIntervals': !exists(json, 'SectionIntervals') ? undefined : (json['SectionIntervals'] === null ? null : (json['SectionIntervals'] as Array<any>).map(TvdSectionDtoFromJSON)),
        'directionIntervals': !exists(json, 'DirectionIntervals') ? undefined : (json['DirectionIntervals'] === null ? null : (json['DirectionIntervals'] as Array<any>).map(TvdHoleDirectionDtoFromJSON)),
    };
}

export function ActualTvdSeriesDtoToJSON(value?: ActualTvdSeriesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'WellId': value.wellId,
        'LastUpdatedAt': DateDtoToJSON(value.lastUpdatedAt),
        'DataState': ResultDataStateToJSON(value.dataState),
        'Series': value.series === undefined ? undefined : (value.series === null ? null : (value.series as Array<any>).map(ActualTvdPointDtoToJSON)),
        'SectionIntervals': value.sectionIntervals === undefined ? undefined : (value.sectionIntervals === null ? null : (value.sectionIntervals as Array<any>).map(TvdSectionDtoToJSON)),
        'DirectionIntervals': value.directionIntervals === undefined ? undefined : (value.directionIntervals === null ? null : (value.directionIntervals as Array<any>).map(TvdHoleDirectionDtoToJSON)),
    };
}

