import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { AllRigsWithWellsQueryDto, ApiRigsOperationTimesPutRequest, OperationTimeDto } from "apis/oag";
import { RigsApi } from "apis/oag";
import type { RangeType } from "atoms/DatePicker";
import type { IFiltersType } from "reducers/types";
import { apiConfig } from "utils/apiConfig";
import { dateToDateOnlyDto } from "utils/helper";
import { last30Days, today } from "utils/helper";
import type { PDOperationTimeQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

import { URL_STATE_PARAM, useStateQuery } from "./navigation/useQueryState";
import { useSelectedRig } from "./useSelectedRig";

const rigs = new RigsApi(apiConfig);

export function useOperationTimes(options?: UseQueryOptions<OperationTimeDto>) {
  const [
    { sections: sectionIds, operationTime, directionalIntervals: directionalIntervalIds, holeSizes: holeSizeIds },
  ] = useStateQuery<IFiltersType>(URL_STATE_PARAM.FILTERS_WIDGET, {
    sections: null,
    directionalIntervals: null,
    operationTime: null,
    holeSizes: null,
  });
  const [periodState] = useStateQuery<RangeType>(URL_STATE_PARAM.PERIOD_RIG_WIDGET, {
    startDate: last30Days,
    endDate: today,
  });
  const [rigSelectedWells] = useStateQuery<Array<number> | null>(URL_STATE_PARAM.SELECTED_WELLS_RIG_WIDGET, null);
  const [offsetRigs] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WELLS_RIGS_WIDGET, []);

  const selectedRig = useSelectedRig();
  const [operatorsState] = useStateQuery<Array<number> | null>(URL_STATE_PARAM.SELECTED_OPERATORS_RIGS, null);
  const queryDto: AllRigsWithWellsQueryDto = {
    rigIds: [selectedRig, ...offsetRigs].filter((e) => e),
    selectedFilters: {
      sectionIds,
      directionalIntervalIds,
      holeSizeIds,
      includeFlatTime:
        operationTime === null || operationTime === undefined ? true : operationTime.includes("Flat Time"),
      includeSlidingTime:
        operationTime === null || operationTime === undefined ? true : operationTime.includes("Sliding Time"),
      includeRotatingTime:
        operationTime === null || operationTime === undefined ? true : operationTime.includes("Rotating Time"),
      includeNullHoleDepth: true,
    },
    from: dateToDateOnlyDto(periodState.startDate || last30Days),
    to: dateToDateOnlyDto(periodState.endDate || today),
    includeFullWellFacts: false,
    operatorIds: operatorsState,
    selectedWellIds: rigSelectedWells ?? [],
    // Always true because these filters are only for All rigs page
    includeAlphaRigs: true,
    includeNoneAlphaRigs: true,
  };

  const requestQuery: ApiRigsOperationTimesPutRequest = {
    allRigsWithWellsQueryDto: queryDto,
  };

  const RigsOperationTimesQueryKey: PDOperationTimeQueryKey = {
    uid: RequestUID.rigOperationTimes,
    periodState,
    params: queryDto,
    type: PDQueryType.RIG_OPERATION_TIME,
  };

  return useQuery<OperationTimeDto>({
    queryKey: [RigsOperationTimesQueryKey],
    queryFn: () => rigs.apiRigsOperationTimesPut(requestQuery),
    ...options,
  });
}
