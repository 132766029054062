/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScoreSummaryTabDto,
    ScoreSummaryTabDtoFromJSON,
    ScoreSummaryTabDtoFromJSONTyped,
    ScoreSummaryTabDtoToJSON,
} from './ScoreSummaryTabDto';

/**
 * 
 * @export
 * @interface ScoreSummaryTabGroupDto
 */
export interface ScoreSummaryTabGroupDto {
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryTabGroupDto
     */
    targetDeltaTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryTabGroupDto
     */
    invisibleLostTime?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ScoreSummaryTabGroupDto
     */
    groupKey?: string | null;
    /**
     * 
     * @type {Array<ScoreSummaryTabDto>}
     * @memberof ScoreSummaryTabGroupDto
     */
    children: Array<ScoreSummaryTabDto>;
}

export function ScoreSummaryTabGroupDtoFromJSON(json: any): ScoreSummaryTabGroupDto {
    return ScoreSummaryTabGroupDtoFromJSONTyped(json, false);
}

export function ScoreSummaryTabGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScoreSummaryTabGroupDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'targetDeltaTime': !exists(json, 'TargetDeltaTime') ? undefined : json['TargetDeltaTime'],
        'invisibleLostTime': !exists(json, 'InvisibleLostTime') ? undefined : json['InvisibleLostTime'],
        'groupKey': !exists(json, 'GroupKey') ? undefined : json['GroupKey'],
        'children': ((json['Children'] as Array<any>).map(ScoreSummaryTabDtoFromJSON)),
    };
}

export function ScoreSummaryTabGroupDtoToJSON(value?: ScoreSummaryTabGroupDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'TargetDeltaTime': value.targetDeltaTime,
        'InvisibleLostTime': value.invisibleLostTime,
        'GroupKey': value.groupKey,
        'Children': ((value.children as Array<any>).map(ScoreSummaryTabDtoToJSON)),
    };
}

