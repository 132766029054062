import type { UseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { StickSlipByDepthSetDto } from "apis/oag";
import { StickSlipByDepthUserLensesApi } from "apis/oag";
import type { ApiStickSlipByDepthUserLensesIdFactsPutRequest } from "apis/oag/apis/StickSlipByDepthUserLensesApi";
import { initialZoomData } from "components/WellDashboard/ChartControls";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useSelectedWell } from "hooks/useSelectedWell";
import { useAppDispatch } from "reducers/store";
import type { IFiltersType, IZoomData } from "reducers/types";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto, PARAMETER_LENS_SAMPLE_COUNT } from "utils/common";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const parameterApi = new StickSlipByDepthUserLensesApi(apiConfig);

export const useStickSlipByDepthFacts = (lensId: number, options?: UseQueryOptions<StickSlipByDepthSetDto>) => {
  const [comparisonWells] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WIDGET, []);
  const wellDataID = useSelectedWell();

  const [
    { sections: sectionIds, operationTime, directionalIntervals: directionalIntervalIds, holeSizes: holeSizeIds },
  ] = useStateQuery<IFiltersType>(URL_STATE_PARAM.FILTERS_WIDGET, {
    sections: null,
    directionalIntervals: null,
    operationTime: null,
    holeSizes: null,
  });
  const [
    { ts_start: zoomStartDuration, ts_end: zoomEndDuration, depth_start: zoomStartDepth, depth_end: zoomEndDepth },
  ] = useStateQuery<IZoomData>(URL_STATE_PARAM.ZOOM_WIDGET, initialZoomData);

  const dispatch = useAppDispatch();
  const requestParameters: ApiStickSlipByDepthUserLensesIdFactsPutRequest = {
    id: lensId ?? -1,
    byDepthUserLensQueryDto: {
      selectedFilters: {
        sectionIds,
        directionalIntervalIds,
        holeSizeIds,
        includeFlatTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Flat Time"),
        includeSlidingTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Sliding Time"),
        includeRotatingTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Rotating Time"),
        includeNullHoleDepth: true,
      },
      comparisonWellIds: comparisonWells,
      zoomStartDepth,
      zoomEndDepth,
      zoomStartDuration,
      zoomEndDuration,
      wellId: wellDataID,
      sampleCount: PARAMETER_LENS_SAMPLE_COUNT,
      useMockData: false,
      from: defaultDateDto.from,
      to: defaultDateDto.to,
      wellAggregationThreshold: 0,
      maxLookAheadDepthFromFocal: null, // no look ahead on Web
    },
  };

  const queryKey: PDLensFactsQueryKey<ApiStickSlipByDepthUserLensesIdFactsPutRequest> = {
    type: PDQueryType.FACTS,
    uid: RequestUID.stickSlipByDepthFacts,
    params: requestParameters,
    lensId,
    comparisonType: comparisonWells.length > 0 ? "single" : "comparison",
  };
  const requestReturn = useSuspenseQuery({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      parameterApi.apiStickSlipByDepthUserLensesIdFactsPut(requestParameters, { signal }).then((data) => {
        if (data?.lastUpdatedAt) {
          dispatch({
            type: "SET_BY_DEPTH_REFRESH_DATE",
            payload: { [+wellDataID]: data.lastUpdatedAt },
          });
        }
        return data;
      }),
    ...options,
  });

  return requestReturn;
};
