/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserRoleType,
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
} from './UserRoleType';
import {
    UserStatusType,
    UserStatusTypeFromJSON,
    UserStatusTypeFromJSONTyped,
    UserStatusTypeToJSON,
} from './UserStatusType';

/**
 * 
 * @export
 * @interface UserQueryDto
 */
export interface UserQueryDto {
    /**
     * 
     * @type {string}
     * @memberof UserQueryDto
     */
    search?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserQueryDto
     */
    wellIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserQueryDto
     */
    rigIds?: Array<number> | null;
    /**
     * 
     * @type {Array<UserStatusType>}
     * @memberof UserQueryDto
     */
    status?: Array<UserStatusType> | null;
    /**
     * 
     * @type {Array<UserRoleType>}
     * @memberof UserQueryDto
     */
    role?: Array<UserRoleType> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserQueryDto
     */
    operatorIds?: Array<number> | null;
    /**
     * 
     * @type {Date}
     * @memberof UserQueryDto
     */
    expireDateStart?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof UserQueryDto
     */
    expireDateEnd?: Date | null;
}

export function UserQueryDtoFromJSON(json: any): UserQueryDto {
    return UserQueryDtoFromJSONTyped(json, false);
}

export function UserQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserQueryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'search': !exists(json, 'Search') ? undefined : json['Search'],
        'wellIds': !exists(json, 'WellIds') ? undefined : json['WellIds'],
        'rigIds': !exists(json, 'RigIds') ? undefined : json['RigIds'],
        'status': !exists(json, 'Status') ? undefined : (json['Status'] === null ? null : (json['Status'] as Array<any>).map(UserStatusTypeFromJSON)),
        'role': !exists(json, 'Role') ? undefined : (json['Role'] === null ? null : (json['Role'] as Array<any>).map(UserRoleTypeFromJSON)),
        'operatorIds': !exists(json, 'OperatorIds') ? undefined : json['OperatorIds'],
        'expireDateStart': !exists(json, 'ExpireDateStart') ? undefined : (json['ExpireDateStart'] === null ? null : new Date(json['ExpireDateStart'])),
        'expireDateEnd': !exists(json, 'ExpireDateEnd') ? undefined : (json['ExpireDateEnd'] === null ? null : new Date(json['ExpireDateEnd'])),
    };
}

export function UserQueryDtoToJSON(value?: UserQueryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Search': value.search,
        'WellIds': value.wellIds,
        'RigIds': value.rigIds,
        'Status': value.status === undefined ? undefined : (value.status === null ? null : (value.status as Array<any>).map(UserStatusTypeToJSON)),
        'Role': value.role === undefined ? undefined : (value.role === null ? null : (value.role as Array<any>).map(UserRoleTypeToJSON)),
        'OperatorIds': value.operatorIds,
        'ExpireDateStart': value.expireDateStart === undefined ? undefined : (value.expireDateStart === null ? null : value.expireDateStart.toISOString()),
        'ExpireDateEnd': value.expireDateEnd === undefined ? undefined : (value.expireDateEnd === null ? null : value.expireDateEnd.toISOString()),
    };
}

