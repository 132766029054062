/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TimelineEventType,
    TimelineEventTypeFromJSON,
    TimelineEventTypeFromJSONTyped,
    TimelineEventTypeToJSON,
} from './TimelineEventType';

/**
 * 
 * @export
 * @interface PlanTimelineEventDto
 */
export interface PlanTimelineEventDto {
    /**
     * 
     * @type {number}
     * @memberof PlanTimelineEventDto
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof PlanTimelineEventDto
     */
    deletedAtUtc?: Date | null;
    /**
     * 
     * @type {TimelineEventType}
     * @memberof PlanTimelineEventDto
     */
    type: TimelineEventType;
    /**
     * 
     * @type {string}
     * @memberof PlanTimelineEventDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PlanTimelineEventDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PlanTimelineEventDto
     */
    authorId: number;
    /**
     * 
     * @type {string}
     * @memberof PlanTimelineEventDto
     */
    authorDisplayName: string;
    /**
     * 
     * @type {number}
     * @memberof PlanTimelineEventDto
     */
    cumulativeDuration: number;
    /**
     * 
     * @type {number}
     * @memberof PlanTimelineEventDto
     */
    holeDepth?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PlanTimelineEventDto
     */
    isSetByDepth: boolean;
    /**
     * 
     * @type {number}
     * @memberof PlanTimelineEventDto
     */
    planId: number;
    /**
     * 
     * @type {boolean}
     * @memberof PlanTimelineEventDto
     */
    isFutureEvent: boolean;
}

export function PlanTimelineEventDtoFromJSON(json: any): PlanTimelineEventDto {
    return PlanTimelineEventDtoFromJSONTyped(json, false);
}

export function PlanTimelineEventDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanTimelineEventDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'deletedAtUtc': !exists(json, 'DeletedAtUtc') ? undefined : (json['DeletedAtUtc'] === null ? null : new Date(json['DeletedAtUtc'])),
        'type': TimelineEventTypeFromJSON(json['Type']),
        'title': !exists(json, 'Title') ? undefined : json['Title'],
        'description': !exists(json, 'Description') ? undefined : json['Description'],
        'authorId': json['AuthorId'],
        'authorDisplayName': json['AuthorDisplayName'],
        'cumulativeDuration': json['CumulativeDuration'],
        'holeDepth': !exists(json, 'HoleDepth') ? undefined : json['HoleDepth'],
        'isSetByDepth': json['IsSetByDepth'],
        'planId': json['PlanId'],
        'isFutureEvent': json['IsFutureEvent'],
    };
}

export function PlanTimelineEventDtoToJSON(value?: PlanTimelineEventDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'DeletedAtUtc': value.deletedAtUtc === undefined ? undefined : (value.deletedAtUtc === null ? null : value.deletedAtUtc.toISOString()),
        'Type': TimelineEventTypeToJSON(value.type),
        'Title': value.title,
        'Description': value.description,
        'AuthorId': value.authorId,
        'AuthorDisplayName': value.authorDisplayName,
        'CumulativeDuration': value.cumulativeDuration,
        'HoleDepth': value.holeDepth,
        'IsSetByDepth': value.isSetByDepth,
        'PlanId': value.planId,
        'IsFutureEvent': value.isFutureEvent,
    };
}

