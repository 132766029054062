/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RichStandKpiCommentInfoDto,
    RichStandKpiCommentInfoDtoFromJSON,
    RichStandKpiCommentInfoDtoFromJSONTyped,
    RichStandKpiCommentInfoDtoToJSON,
} from './RichStandKpiCommentInfoDto';
import {
    ScorecardKpiCommentInfoDto,
    ScorecardKpiCommentInfoDtoFromJSON,
    ScorecardKpiCommentInfoDtoFromJSONTyped,
    ScorecardKpiCommentInfoDtoToJSON,
} from './ScorecardKpiCommentInfoDto';

/**
 * 
 * @export
 * @interface ScorecardKpiCommentGroupDto
 */
export interface ScorecardKpiCommentGroupDto {
    /**
     * 
     * @type {number}
     * @memberof ScorecardKpiCommentGroupDto
     */
    scorecardLensId: number;
    /**
     * 
     * @type {Array<ScorecardKpiCommentInfoDto>}
     * @memberof ScorecardKpiCommentGroupDto
     */
    comments: Array<ScorecardKpiCommentInfoDto>;
    /**
     * 
     * @type {Array<RichStandKpiCommentInfoDto>}
     * @memberof ScorecardKpiCommentGroupDto
     */
    standComments: Array<RichStandKpiCommentInfoDto>;
}

export function ScorecardKpiCommentGroupDtoFromJSON(json: any): ScorecardKpiCommentGroupDto {
    return ScorecardKpiCommentGroupDtoFromJSONTyped(json, false);
}

export function ScorecardKpiCommentGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScorecardKpiCommentGroupDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scorecardLensId': json['ScorecardLensId'],
        'comments': ((json['Comments'] as Array<any>).map(ScorecardKpiCommentInfoDtoFromJSON)),
        'standComments': ((json['StandComments'] as Array<any>).map(RichStandKpiCommentInfoDtoFromJSON)),
    };
}

export function ScorecardKpiCommentGroupDtoToJSON(value?: ScorecardKpiCommentGroupDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ScorecardLensId': value.scorecardLensId,
        'Comments': ((value.comments as Array<any>).map(ScorecardKpiCommentInfoDtoToJSON)),
        'StandComments': ((value.standComments as Array<any>).map(RichStandKpiCommentInfoDtoToJSON)),
    };
}

