import type { UseQueryResult } from "@tanstack/react-query";
import type { OperatorClaimDto, UserClaimDto, UserDto } from "apis/oag";
import { ClaimPermissionType, UserRoleType, WellStatusType } from "apis/oag";
import type { RangeType } from "atoms/DatePicker";
import { DatePicker } from "atoms/DatePicker";
import { Button, Input } from "atoms/Form";
import { Title } from "atoms/Typography";
import { ActionsHeader, PlanSectionLayout } from "components/Layout/Tabbed";
import { Loader } from "components/Loader";
import { PDComponent } from "components/PDComponents";
import { WellAccessMultiple } from "components/WellAccess";
import Pane from "components/WellEditor/Pane";
import { useAdminSingleUserWellAccess } from "hooks/admin/useAdminSingleUserWellAccess";
import { useRigs } from "hooks/useRigs";
import { useRigSort } from "hooks/useRigSort";
import { CheckboxSelector, Header } from "pages/Admin/Accounts/Editor/WellAccess";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch } from "reducers/store";
import { useAppSelector } from "reducers/store";
import { Col, Popover, Row, Space, Tooltip } from "utils/componentLibrary";
import { CheckboxState } from "utils/enums";
import { useCustomTheme } from "utils/useTheme";

const WellAccess = ({
  userId,
  singleUser,
  allClaimsCallback,
  setCanUpdate,
}: {
  userId: number;
  singleUser: UseQueryResult<UserDto, unknown>;
  allClaimsCallback: (x: UserClaimDto[] | OperatorClaimDto[]) => void;
  setCanUpdate: React.Dispatch<boolean>;
}) => {
  const dispatch = useAppDispatch();

  const deselectFunc = useRef<(y: boolean) => void>(() => void 0);

  const rigs = useRigs();

  const userWellAccess = useAdminSingleUserWellAccess({ userId }, singleUser.data?.role !== UserRoleType.Administrator);

  const period = useAppSelector((state) => state.admin.wellAccess.period);
  const rigIdsState = useAppSelector((state) => state.admin.wellAccess.rigs);
  const statusState = useAppSelector((state) => state.admin.wellAccess.wellStatus);
  const searchState = useAppSelector((state) => state.admin.wellAccess.search);

  const { atomThemeVariant } = useCustomTheme();
  const [showRigPopup, setShowRigPopup] = useState(false);
  const [showStatusPopup, setShowStatusPopup] = useState(false);

  const [quickSearchValue, setQuickSearchValue] = useState<string>(searchState || "");
  const [selected, setSelected] = useState(CheckboxState.None);
  const [futureWellSelected, setFutureWellSelected] = useState(CheckboxState.None);
  const [futureRigSelected, setFutureRigSelected] = useState(userWellAccess.data?.futureRigAccess);
  const [hasClaims, setHasClaims] = useState(false);
  const [commonFuture, setCommonFuture] = useState<boolean>(false);

  const [claims, setClaims] = useState<UserClaimDto[]>([]);
  useEffect(() => {
    if (!userWellAccess.isLoading && userWellAccess.data?.wellAccess) {
      setFutureRigSelected(userWellAccess.data.futureRigAccess);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userWellAccess.data]);

  useEffect(() => {
    if (!userWellAccess.data?.wellAccess) return;
    const futureAccess = claims.filter((claim) => claim.type === "FutureWellAccess");
    if (futureAccess.length === 0) setFutureWellSelected(CheckboxState.Disabled);
    else if (userWellAccess.data?.wellAccess?.length === 0) setSelected(CheckboxState.Disabled);
    else if (userWellAccess.data.wellAccess.every((e) => e.futureWellAccess === undefined))
      setFutureWellSelected(CheckboxState.Disabled);
    else if (futureAccess.every((e) => e.permission === "All")) {
      setFutureWellSelected(CheckboxState.Selected);
    } else if (futureAccess.some((e) => e.permission === "All")) setFutureWellSelected(CheckboxState.Partially);
    else {
      setFutureWellSelected(CheckboxState.None);
    }

    if (claims.every((e) => e.permission === "All")) setSelected(CheckboxState.Selected);
    else if (claims.some((e) => e.permission === "All")) setSelected(CheckboxState.Partially);
    else setSelected(CheckboxState.None);

    if (futureRigSelected) {
      allClaimsCallback([
        ...claims.filter((e) => e.type !== "FutureRigAccess"),
        {
          id: 0,
          type: "FutureRigAccess",
          permission: "All",
          userId: userId,
        },
      ]);
    } else allClaimsCallback([...claims.filter((e) => e.type !== "FutureRigAccess")]);
  }, [userId, allClaimsCallback, claims, futureRigSelected, userWellAccess?.data?.wellAccess]);

  const updateTimeRange = (period: RangeType) => {
    dispatch({
      type: "ADMIN_WELL_ACCESS_SET_TIME_RANGE",
      payload: {
        period,
      },
    });
    return true;
  };

  const sortByRigs = useRigSort();
  const updateRigs = useCallback(
    (rigIds: number[]) => {
      dispatch({
        type: "ADMIN_WELL_ACCESS_SET_RIGS",
        payload: {
          rigs: rigIds,
        },
      });
      return true;
    },
    [dispatch],
  );

  const updateStatuses = (f: WellStatusType[]) => {
    setShowStatusPopup(false);
    dispatch({
      type: "ADMIN_WELL_ACCESS_SET_WELL_STATUS",
      payload: {
        wellStatus: f,
      },
    });
    return true;
  };

  const updateSearch = () => {
    dispatch({
      type: "ADMIN_WELL_ACCESS_SET_SEARCH",
      payload: {
        search: quickSearchValue,
      },
    });
    return true;
  };

  const claimsPassthrough = useCallback(
    (x: UserClaimDto[] | OperatorClaimDto[]) => {
      setClaims(x as UserClaimDto[]);
      allClaimsCallback(x);
      setHasClaims(x.filter((e) => e.permission !== ClaimPermissionType.None).length > 0);
    },
    [allClaimsCallback],
  );

  useEffect(() => {
    if (rigs.data) {
      updateRigs(rigs.data.list.map((e) => e.id));
    }
  }, [rigs.data, updateRigs]);

  return (
    <PlanSectionLayout
      header={
        <ActionsHeader>
          <Row gutter={12} justify="space-between" align="middle" style={{ height: "64px" }}>
            <Col flex="0 auto">
              <Space>
                <Space direction="horizontal" size={4}>
                  <Input
                    allowClear
                    placeholder="Search by Well name"
                    style={{ width: "300px" }}
                    value={quickSearchValue}
                    onChange={(e) => setQuickSearchValue(e.target.value)}
                  />
                  <Button
                    size="large"
                    type="primary"
                    icon={<PDComponent.SvgIcon name="search" />}
                    onClick={updateSearch}
                  />
                </Space>

                <PDComponent.VerticalDivider />

                <Tooltip title="Time Range">
                  <DatePicker allowAllDates selection={period} onApply={updateTimeRange} />
                </Tooltip>

                <Tooltip title="Rigs">
                  <Popover
                    content={
                      <PDComponent.ComboBoxMultiSelect
                        placeholder="Search Rigs"
                        options={(rigs.data?.list ?? []).map((e) => ({ id: e.id, name: e.shortName || "" }))}
                        values={rigIdsState ?? []}
                        onChange={(rigIds: number[]) => {
                          updateRigs(rigIds);
                          setShowRigPopup(false);
                          return void 0;
                        }}
                      />
                    }
                    trigger="click"
                    placement="bottom"
                    open={showRigPopup}
                    onOpenChange={(e) => setShowRigPopup(e)}
                    destroyTooltipOnHide
                  >
                    <Button
                      size="large"
                      icon={<PDComponent.SvgIcon name="rig" />}
                      type={
                        rigIdsState === null || rigIdsState.length !== rigs.data?.list.length ? "primary" : "default"
                      }
                      ghost={rigIdsState !== null && rigIdsState?.length !== rigs.data?.list.length}
                      $engaged={showRigPopup}
                    />
                  </Popover>
                </Tooltip>

                <Tooltip title="Well Status">
                  <Popover
                    content={
                      <PDComponent.Pickoff
                        options={Object.values(WellStatusType).map((e) => ({ id: e, name: e }))}
                        values={statusState || []}
                        onChange={(e) => updateStatuses(e)}
                      />
                    }
                    trigger="click"
                    placement="bottom"
                    open={showStatusPopup}
                    onOpenChange={(e) => setShowStatusPopup(e)}
                    destroyTooltipOnHide
                  >
                    <Button
                      icon={<PDComponent.SvgIcon name="checkmarkOutline" />}
                      type={statusState?.length !== Object.keys(WellStatusType).length ? "primary" : "default"}
                      ghost={statusState?.length !== Object.keys(WellStatusType).length}
                      $engaged={showStatusPopup}
                    />
                  </Popover>
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </ActionsHeader>
      }
      content={
        <>
          {singleUser.data?.role === UserRoleType.Administrator && (
            <Pane>
              <Title level={3} variant={atomThemeVariant}>
                Administrators have access to all Wells &amp; Rigs.
              </Title>
            </Pane>
          )}
          {singleUser.data?.role !== UserRoleType.Administrator && (
            <>
              {userWellAccess.isLoading ? <Loader centered /> : null}
              {!userWellAccess.isLoading && (
                <>
                  <Header justify="space-between" align="middle">
                    <Col flex="0 auto">
                      <CheckboxSelector
                        checked={selected === CheckboxState.Selected}
                        indeterminate={selected === CheckboxState.Partially}
                        onChange={() => {
                          deselectFunc.current(!hasClaims);
                        }}
                      >
                        <Title level={3} variant={atomThemeVariant}>
                          {selected === CheckboxState.Selected ? "Deselect" : "Select"} All
                        </Title>
                      </CheckboxSelector>
                    </Col>
                    <Col flex="0 auto">
                      <Row gutter={8}>
                        <Col flex="0 auto">
                          <CheckboxSelector
                            checked={userWellAccess.data?.operatorFutureRigAccess ? futureRigSelected : undefined}
                            disabled={!userWellAccess.data?.operatorFutureRigAccess}
                            onChange={() => {
                              setFutureRigSelected(!futureRigSelected);
                              setCanUpdate(true);
                            }}
                          >
                            <Title
                              level={3}
                              variant={userWellAccess.data?.operatorFutureRigAccess ? atomThemeVariant : "faded"}
                            >
                              Future Rigs
                            </Title>
                          </CheckboxSelector>
                        </Col>
                        <Col flex="0 auto">
                          <CheckboxSelector
                            disabled={
                              userWellAccess.data?.wellAccess?.length === 0 ||
                              futureWellSelected === CheckboxState.Disabled
                            }
                            checked={
                              userWellAccess.data?.wellAccess?.length !== 0 &&
                              futureWellSelected === CheckboxState.Selected
                            }
                            indeterminate={futureWellSelected === CheckboxState.Partially}
                            onChange={() => {
                              if (futureWellSelected !== CheckboxState.Selected) {
                                setCommonFuture(true);
                                setFutureWellSelected(CheckboxState.Selected);
                              } else {
                                setCommonFuture(false);
                                setFutureWellSelected(CheckboxState.None);
                              }
                            }}
                          >
                            <Title
                              level={3}
                              variant={
                                userWellAccess?.data?.wellAccess?.length === 0 ||
                                futureWellSelected === CheckboxState.Disabled
                                  ? "faded"
                                  : atomThemeVariant
                              }
                            >
                              Future Wells
                            </Title>
                          </CheckboxSelector>
                        </Col>
                      </Row>
                    </Col>
                  </Header>
                  <WellAccessMultiple
                    selector={userId}
                    mode="user"
                    allRigs={userWellAccess?.data?.wellAccess?.sort((a, b) => sortByRigs(a.rigId, b.rigId)) || []}
                    allClaimsCallback={claimsPassthrough}
                    setCanUpdate={setCanUpdate}
                    deselectBack={(f) => (deselectFunc.current = f)}
                    commonFuture={commonFuture}
                  />
                </>
              )}
              {!userWellAccess.isLoading && (userWellAccess.data?.wellAccess?.length ?? 0) === 0 && (
                <Pane>
                  <Title level={3} variant={atomThemeVariant}>
                    No Wells &amp; Rigs to show.
                  </Title>
                </Pane>
              )}
            </>
          )}
        </>
      }
    />
  );
};

export default WellAccess;
