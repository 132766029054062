/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TagBottomFingerprintUserLensTrackItemDto,
    TagBottomFingerprintUserLensTrackItemDtoFromJSON,
    TagBottomFingerprintUserLensTrackItemDtoFromJSONTyped,
    TagBottomFingerprintUserLensTrackItemDtoToJSON,
} from './TagBottomFingerprintUserLensTrackItemDto';

/**
 * 
 * @export
 * @interface TagBottomFingerprintUserLensDto
 */
export interface TagBottomFingerprintUserLensDto {
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintUserLensDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintUserLensDto
     */
    lensTabId: number;
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintUserLensDto
     */
    lensTemplateId: number;
    /**
     * 
     * @type {boolean}
     * @memberof TagBottomFingerprintUserLensDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TagBottomFingerprintUserLensDto
     */
    label: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TagBottomFingerprintUserLensDto
     */
    isSystem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TagBottomFingerprintUserLensDto
     */
    squeezesDisplay: boolean;
    /**
     * 
     * @type {Array<TagBottomFingerprintUserLensTrackItemDto>}
     * @memberof TagBottomFingerprintUserLensDto
     */
    userLensTrackItems: Array<TagBottomFingerprintUserLensTrackItemDto>;
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintUserLensDto
     */
    standCount?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TagBottomFingerprintUserLensDto
     */
    showOverallView: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TagBottomFingerprintUserLensDto
     */
    showAlphaComparison: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TagBottomFingerprintUserLensDto
     */
    isBithDepthNormalized: boolean;
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintUserLensDto
     */
    normalizationReferenceBitDepth?: number | null;
}

export function TagBottomFingerprintUserLensDtoFromJSON(json: any): TagBottomFingerprintUserLensDto {
    return TagBottomFingerprintUserLensDtoFromJSONTyped(json, false);
}

export function TagBottomFingerprintUserLensDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagBottomFingerprintUserLensDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'lensTabId': json['LensTabId'],
        'lensTemplateId': json['LensTemplateId'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'label': json['Label'],
        'isSystem': json['IsSystem'],
        'squeezesDisplay': json['SqueezesDisplay'],
        'userLensTrackItems': ((json['UserLensTrackItems'] as Array<any>).map(TagBottomFingerprintUserLensTrackItemDtoFromJSON)),
        'standCount': !exists(json, 'StandCount') ? undefined : json['StandCount'],
        'showOverallView': json['ShowOverallView'],
        'showAlphaComparison': json['ShowAlphaComparison'],
        'isBithDepthNormalized': json['IsBithDepthNormalized'],
        'normalizationReferenceBitDepth': !exists(json, 'NormalizationReferenceBitDepth') ? undefined : json['NormalizationReferenceBitDepth'],
    };
}

export function TagBottomFingerprintUserLensDtoToJSON(value?: TagBottomFingerprintUserLensDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'LensTabId': value.lensTabId,
        'LensTemplateId': value.lensTemplateId,
        'IsAvailableOnReports': value.isAvailableOnReports,
        'Label': value.label,
        'IsSystem': value.isSystem,
        'SqueezesDisplay': value.squeezesDisplay,
        'UserLensTrackItems': ((value.userLensTrackItems as Array<any>).map(TagBottomFingerprintUserLensTrackItemDtoToJSON)),
        'StandCount': value.standCount,
        'ShowOverallView': value.showOverallView,
        'ShowAlphaComparison': value.showAlphaComparison,
        'IsBithDepthNormalized': value.isBithDepthNormalized,
        'NormalizationReferenceBitDepth': value.normalizationReferenceBitDepth,
    };
}

