import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { OperatorsApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDOperatorsActiveRigs } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const operators = new OperatorsApi(apiConfig);

export function useOperatorsActiveRigs(
  operatorIds: number[] | null,
  filterEverGreenOnly: boolean,
  options?: Omit<UseQueryOptions<Array<number>>, "queryKey" | "queryFn">,
) {
  const OperatorsActiveRigsQueryKey: PDOperatorsActiveRigs = {
    uid: RequestUID.operatorsActiveRigs,
    type: PDQueryType.OPERATORS_ACTIVE_RIG,
    requestBody: operatorIds ?? undefined,
    filterEverGreenOnly,
  };

  return useQuery<Array<number>>({
    queryKey: [OperatorsActiveRigsQueryKey],
    queryFn: () => {
      return operators.apiOperatorsActiveRigIdsFilterEverGreenOnlyPut({
        requestBody: operatorIds ?? undefined,
        filterEverGreenOnly,
      });
    },
    ...options,
    refetchOnMount: false,
  });
}
