/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    PlanDetailsDto,
    PlanDetailsDtoFromJSON,
    PlanDetailsDtoFromJSONTyped,
    PlanDetailsDtoToJSON,
} from './PlanDetailsDto';
import {
    WellDrillingDetailsDto,
    WellDrillingDetailsDtoFromJSON,
    WellDrillingDetailsDtoFromJSONTyped,
    WellDrillingDetailsDtoToJSON,
} from './WellDrillingDetailsDto';
import {
    WellJobsDto,
    WellJobsDtoFromJSON,
    WellJobsDtoFromJSONTyped,
    WellJobsDtoToJSON,
} from './WellJobsDto';
import {
    WellStatusType,
    WellStatusTypeFromJSON,
    WellStatusTypeFromJSONTyped,
    WellStatusTypeToJSON,
} from './WellStatusType';

/**
 * 
 * @export
 * @interface WellDto
 */
export interface WellDto {
    /**
     * 
     * @type {number}
     * @memberof WellDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof WellDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof WellDto
     */
    operatorId: number;
    /**
     * 
     * @type {WellStatusType}
     * @memberof WellDto
     */
    status: WellStatusType;
    /**
     * 
     * @type {string}
     * @memberof WellDto
     */
    externalId: string;
    /**
     * 
     * @type {string}
     * @memberof WellDto
     */
    padExternalId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WellDto
     */
    isEverGreen: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WellDto
     */
    isBess: boolean;
    /**
     * 
     * @type {Array<WellJobsDto>}
     * @memberof WellDto
     */
    jobs: Array<WellJobsDto>;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDto
     */
    lastWellFactUpdateAt: DateDto;
    /**
     * 
     * @type {number}
     * @memberof WellDto
     */
    latitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WellDto
     */
    longitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WellDto
     */
    targetFormationId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WellDto
     */
    basinId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WellDto
     */
    county?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WellDto
     */
    state?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WellDto
     */
    countryId: number;
    /**
     * 
     * @type {WellDrillingDetailsDto}
     * @memberof WellDto
     */
    drillingDetails: WellDrillingDetailsDto;
    /**
     * 
     * @type {PlanDetailsDto}
     * @memberof WellDto
     */
    planDetails: PlanDetailsDto;
}

export function WellDtoFromJSON(json: any): WellDto {
    return WellDtoFromJSONTyped(json, false);
}

export function WellDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WellDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': json['Name'],
        'operatorId': json['OperatorId'],
        'status': WellStatusTypeFromJSON(json['Status']),
        'externalId': json['ExternalId'],
        'padExternalId': !exists(json, 'PadExternalId') ? undefined : json['PadExternalId'],
        'isEverGreen': json['IsEverGreen'],
        'isBess': json['IsBess'],
        'jobs': ((json['Jobs'] as Array<any>).map(WellJobsDtoFromJSON)),
        'lastWellFactUpdateAt': DateDtoFromJSON(json['LastWellFactUpdateAt']),
        'latitude': !exists(json, 'Latitude') ? undefined : json['Latitude'],
        'longitude': !exists(json, 'Longitude') ? undefined : json['Longitude'],
        'targetFormationId': !exists(json, 'TargetFormationId') ? undefined : json['TargetFormationId'],
        'basinId': !exists(json, 'BasinId') ? undefined : json['BasinId'],
        'county': !exists(json, 'County') ? undefined : json['County'],
        'state': !exists(json, 'State') ? undefined : json['State'],
        'countryId': json['CountryId'],
        'drillingDetails': WellDrillingDetailsDtoFromJSON(json['DrillingDetails']),
        'planDetails': PlanDetailsDtoFromJSON(json['PlanDetails']),
    };
}

export function WellDtoToJSON(value?: WellDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'Name': value.name,
        'OperatorId': value.operatorId,
        'Status': WellStatusTypeToJSON(value.status),
        'ExternalId': value.externalId,
        'PadExternalId': value.padExternalId,
        'IsEverGreen': value.isEverGreen,
        'IsBess': value.isBess,
        'Jobs': ((value.jobs as Array<any>).map(WellJobsDtoToJSON)),
        'LastWellFactUpdateAt': DateDtoToJSON(value.lastWellFactUpdateAt),
        'Latitude': value.latitude,
        'Longitude': value.longitude,
        'TargetFormationId': value.targetFormationId,
        'BasinId': value.basinId,
        'County': value.county,
        'State': value.state,
        'CountryId': value.countryId,
        'DrillingDetails': WellDrillingDetailsDtoToJSON(value.drillingDetails),
        'PlanDetails': PlanDetailsDtoToJSON(value.planDetails),
    };
}

