/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ParameterByDepthRoadmapBoundariesDto,
    ParameterByDepthRoadmapBoundariesDtoFromJSON,
    ParameterByDepthRoadmapBoundariesDtoFromJSONTyped,
    ParameterByDepthRoadmapBoundariesDtoToJSON,
} from './ParameterByDepthRoadmapBoundariesDto';
import {
    ParameterByDepthTrackValuesDto,
    ParameterByDepthTrackValuesDtoFromJSON,
    ParameterByDepthTrackValuesDtoFromJSONTyped,
    ParameterByDepthTrackValuesDtoToJSON,
} from './ParameterByDepthTrackValuesDto';
import {
    TrackFactType,
    TrackFactTypeFromJSON,
    TrackFactTypeFromJSONTyped,
    TrackFactTypeToJSON,
} from './TrackFactType';

/**
 * 
 * @export
 * @interface ParameterByDepthTrackDetailsDto
 */
export interface ParameterByDepthTrackDetailsDto {
    /**
     * 
     * @type {Array<ParameterByDepthTrackValuesDto>}
     * @memberof ParameterByDepthTrackDetailsDto
     */
    trackValues: Array<ParameterByDepthTrackValuesDto>;
    /**
     * 
     * @type {number}
     * @memberof ParameterByDepthTrackDetailsDto
     */
    trackId: number;
    /**
     * 
     * @type {TrackFactType}
     * @memberof ParameterByDepthTrackDetailsDto
     */
    type: TrackFactType;
    /**
     * 
     * @type {number}
     * @memberof ParameterByDepthTrackDetailsDto
     */
    position: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterByDepthTrackDetailsDto
     */
    average: number;
    /**
     * 
     * @type {Array<ParameterByDepthRoadmapBoundariesDto>}
     * @memberof ParameterByDepthTrackDetailsDto
     */
    boundaries?: Array<ParameterByDepthRoadmapBoundariesDto> | null;
}

export function ParameterByDepthTrackDetailsDtoFromJSON(json: any): ParameterByDepthTrackDetailsDto {
    return ParameterByDepthTrackDetailsDtoFromJSONTyped(json, false);
}

export function ParameterByDepthTrackDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterByDepthTrackDetailsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trackValues': ((json['TrackValues'] as Array<any>).map(ParameterByDepthTrackValuesDtoFromJSON)),
        'trackId': json['TrackId'],
        'type': TrackFactTypeFromJSON(json['Type']),
        'position': json['Position'],
        'average': json['Average'],
        'boundaries': !exists(json, 'Boundaries') ? undefined : (json['Boundaries'] === null ? null : (json['Boundaries'] as Array<any>).map(ParameterByDepthRoadmapBoundariesDtoFromJSON)),
    };
}

export function ParameterByDepthTrackDetailsDtoToJSON(value?: ParameterByDepthTrackDetailsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'TrackValues': ((value.trackValues as Array<any>).map(ParameterByDepthTrackValuesDtoToJSON)),
        'TrackId': value.trackId,
        'Type': TrackFactTypeToJSON(value.type),
        'Position': value.position,
        'Average': value.average,
        'Boundaries': value.boundaries === undefined ? undefined : (value.boundaries === null ? null : (value.boundaries as Array<any>).map(ParameterByDepthRoadmapBoundariesDtoToJSON)),
    };
}

