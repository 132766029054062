/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Int32DecimalFactIndicatorDto,
    Int32DecimalFactIndicatorDtoFromJSON,
    Int32DecimalFactIndicatorDtoFromJSONTyped,
    Int32DecimalFactIndicatorDtoToJSON,
} from './Int32DecimalFactIndicatorDto';

/**
 * 
 * @export
 * @interface Int32DecimalFactIndicatorSetDto
 */
export interface Int32DecimalFactIndicatorSetDto {
    /**
     * 
     * @type {Array<Int32DecimalFactIndicatorDto>}
     * @memberof Int32DecimalFactIndicatorSetDto
     */
    holeSections?: Array<Int32DecimalFactIndicatorDto> | null;
    /**
     * 
     * @type {Array<Int32DecimalFactIndicatorDto>}
     * @memberof Int32DecimalFactIndicatorSetDto
     */
    directionIntervals?: Array<Int32DecimalFactIndicatorDto> | null;
    /**
     * 
     * @type {Array<Int32DecimalFactIndicatorDto>}
     * @memberof Int32DecimalFactIndicatorSetDto
     */
    formations?: Array<Int32DecimalFactIndicatorDto> | null;
}

export function Int32DecimalFactIndicatorSetDtoFromJSON(json: any): Int32DecimalFactIndicatorSetDto {
    return Int32DecimalFactIndicatorSetDtoFromJSONTyped(json, false);
}

export function Int32DecimalFactIndicatorSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): Int32DecimalFactIndicatorSetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'holeSections': !exists(json, 'HoleSections') ? undefined : (json['HoleSections'] === null ? null : (json['HoleSections'] as Array<any>).map(Int32DecimalFactIndicatorDtoFromJSON)),
        'directionIntervals': !exists(json, 'DirectionIntervals') ? undefined : (json['DirectionIntervals'] === null ? null : (json['DirectionIntervals'] as Array<any>).map(Int32DecimalFactIndicatorDtoFromJSON)),
        'formations': !exists(json, 'Formations') ? undefined : (json['Formations'] === null ? null : (json['Formations'] as Array<any>).map(Int32DecimalFactIndicatorDtoFromJSON)),
    };
}

export function Int32DecimalFactIndicatorSetDtoToJSON(value?: Int32DecimalFactIndicatorSetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'HoleSections': value.holeSections === undefined ? undefined : (value.holeSections === null ? null : (value.holeSections as Array<any>).map(Int32DecimalFactIndicatorDtoToJSON)),
        'DirectionIntervals': value.directionIntervals === undefined ? undefined : (value.directionIntervals === null ? null : (value.directionIntervals as Array<any>).map(Int32DecimalFactIndicatorDtoToJSON)),
        'Formations': value.formations === undefined ? undefined : (value.formations === null ? null : (value.formations as Array<any>).map(Int32DecimalFactIndicatorDtoToJSON)),
    };
}

