/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BoilerSlotType,
    BoilerSlotTypeFromJSON,
    BoilerSlotTypeFromJSONTyped,
    BoilerSlotTypeToJSON,
} from './BoilerSlotType';
import {
    FuelType,
    FuelTypeFromJSON,
    FuelTypeFromJSONTyped,
    FuelTypeToJSON,
} from './FuelType';
import {
    GeneratorSlotType,
    GeneratorSlotTypeFromJSON,
    GeneratorSlotTypeFromJSONTyped,
    GeneratorSlotTypeToJSON,
} from './GeneratorSlotType';

/**
 * 
 * @export
 * @interface FuelConsumptionByGeneratorUserLensDto
 */
export interface FuelConsumptionByGeneratorUserLensDto {
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionByGeneratorUserLensDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionByGeneratorUserLensDto
     */
    lensTabId: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionByGeneratorUserLensDto
     */
    lensTemplateId: number;
    /**
     * 
     * @type {boolean}
     * @memberof FuelConsumptionByGeneratorUserLensDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FuelConsumptionByGeneratorUserLensDto
     */
    label: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FuelConsumptionByGeneratorUserLensDto
     */
    isSystem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FuelConsumptionByGeneratorUserLensDto
     */
    squeezesDisplay: boolean;
    /**
     * 
     * @type {Array<GeneratorSlotType>}
     * @memberof FuelConsumptionByGeneratorUserLensDto
     */
    selectedGenerators: Array<GeneratorSlotType>;
    /**
     * 
     * @type {FuelType}
     * @memberof FuelConsumptionByGeneratorUserLensDto
     */
    selectedFuel: FuelType;
    /**
     * 
     * @type {Array<BoilerSlotType>}
     * @memberof FuelConsumptionByGeneratorUserLensDto
     */
    selectedBoilers: Array<BoilerSlotType>;
}

export function FuelConsumptionByGeneratorUserLensDtoFromJSON(json: any): FuelConsumptionByGeneratorUserLensDto {
    return FuelConsumptionByGeneratorUserLensDtoFromJSONTyped(json, false);
}

export function FuelConsumptionByGeneratorUserLensDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FuelConsumptionByGeneratorUserLensDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'lensTabId': json['LensTabId'],
        'lensTemplateId': json['LensTemplateId'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'label': json['Label'],
        'isSystem': json['IsSystem'],
        'squeezesDisplay': json['SqueezesDisplay'],
        'selectedGenerators': ((json['SelectedGenerators'] as Array<any>).map(GeneratorSlotTypeFromJSON)),
        'selectedFuel': FuelTypeFromJSON(json['SelectedFuel']),
        'selectedBoilers': ((json['SelectedBoilers'] as Array<any>).map(BoilerSlotTypeFromJSON)),
    };
}

export function FuelConsumptionByGeneratorUserLensDtoToJSON(value?: FuelConsumptionByGeneratorUserLensDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'LensTabId': value.lensTabId,
        'LensTemplateId': value.lensTemplateId,
        'IsAvailableOnReports': value.isAvailableOnReports,
        'Label': value.label,
        'IsSystem': value.isSystem,
        'SqueezesDisplay': value.squeezesDisplay,
        'SelectedGenerators': ((value.selectedGenerators as Array<any>).map(GeneratorSlotTypeToJSON)),
        'SelectedFuel': FuelTypeToJSON(value.selectedFuel),
        'SelectedBoilers': ((value.selectedBoilers as Array<any>).map(BoilerSlotTypeToJSON)),
    };
}

