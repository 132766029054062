/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PivotKey,
    PivotKeyFromJSON,
    PivotKeyFromJSONTyped,
    PivotKeyToJSON,
} from './PivotKey';

/**
 * 
 * @export
 * @interface PivtoTargetSegmentItemDto
 */
export interface PivtoTargetSegmentItemDto {
    /**
     * 
     * @type {PivotKey}
     * @memberof PivtoTargetSegmentItemDto
     */
    key: PivotKey;
    /**
     * 
     * @type {number}
     * @memberof PivtoTargetSegmentItemDto
     */
    target?: number | null;
}

export function PivtoTargetSegmentItemDtoFromJSON(json: any): PivtoTargetSegmentItemDto {
    return PivtoTargetSegmentItemDtoFromJSONTyped(json, false);
}

export function PivtoTargetSegmentItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PivtoTargetSegmentItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': PivotKeyFromJSON(json['Key']),
        'target': !exists(json, 'Target') ? undefined : json['Target'],
    };
}

export function PivtoTargetSegmentItemDtoToJSON(value?: PivtoTargetSegmentItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Key': PivotKeyToJSON(value.key),
        'Target': value.target,
    };
}

