/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScoreComplianceStateType,
    ScoreComplianceStateTypeFromJSON,
    ScoreComplianceStateTypeFromJSONTyped,
    ScoreComplianceStateTypeToJSON,
} from './ScoreComplianceStateType';

/**
 * 
 * @export
 * @interface WellBarDto
 */
export interface WellBarDto {
    /**
     * 
     * @type {number}
     * @memberof WellBarDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof WellBarDto
     */
    operatorScore?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WellBarDto
     */
    rigScore?: number | null;
    /**
     * 
     * @type {ScoreComplianceStateType}
     * @memberof WellBarDto
     */
    operatorScoreComplianceState: ScoreComplianceStateType;
    /**
     * 
     * @type {ScoreComplianceStateType}
     * @memberof WellBarDto
     */
    rigScoreComplianceState: ScoreComplianceStateType;
    /**
     * 
     * @type {ScoreComplianceStateType}
     * @memberof WellBarDto
     */
    improvementScoreComplianceState: ScoreComplianceStateType;
}

export function WellBarDtoFromJSON(json: any): WellBarDto {
    return WellBarDtoFromJSONTyped(json, false);
}

export function WellBarDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WellBarDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'operatorScore': !exists(json, 'OperatorScore') ? undefined : json['OperatorScore'],
        'rigScore': !exists(json, 'RigScore') ? undefined : json['RigScore'],
        'operatorScoreComplianceState': ScoreComplianceStateTypeFromJSON(json['OperatorScoreComplianceState']),
        'rigScoreComplianceState': ScoreComplianceStateTypeFromJSON(json['RigScoreComplianceState']),
        'improvementScoreComplianceState': ScoreComplianceStateTypeFromJSON(json['ImprovementScoreComplianceState']),
    };
}

export function WellBarDtoToJSON(value?: WellBarDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'OperatorScore': value.operatorScore,
        'RigScore': value.rigScore,
        'OperatorScoreComplianceState': ScoreComplianceStateTypeToJSON(value.operatorScoreComplianceState),
        'RigScoreComplianceState': ScoreComplianceStateTypeToJSON(value.rigScoreComplianceState),
        'ImprovementScoreComplianceState': ScoreComplianceStateTypeToJSON(value.improvementScoreComplianceState),
    };
}

