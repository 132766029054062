/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TvdSectionDto
 */
export interface TvdSectionDto {
    /**
     * 
     * @type {number}
     * @memberof TvdSectionDto
     */
    sectionId: number;
    /**
     * 
     * @type {number}
     * @memberof TvdSectionDto
     */
    startDepth: number;
    /**
     * 
     * @type {number}
     * @memberof TvdSectionDto
     */
    endDepth: number;
    /**
     * 
     * @type {number}
     * @memberof TvdSectionDto
     */
    startDynamicDepth: number;
    /**
     * 
     * @type {number}
     * @memberof TvdSectionDto
     */
    endDynamicDepth: number;
}

export function TvdSectionDtoFromJSON(json: any): TvdSectionDto {
    return TvdSectionDtoFromJSONTyped(json, false);
}

export function TvdSectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TvdSectionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sectionId': json['SectionId'],
        'startDepth': json['StartDepth'],
        'endDepth': json['EndDepth'],
        'startDynamicDepth': json['StartDynamicDepth'],
        'endDynamicDepth': json['EndDynamicDepth'],
    };
}

export function TvdSectionDtoToJSON(value?: TvdSectionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'SectionId': value.sectionId,
        'StartDepth': value.startDepth,
        'EndDepth': value.endDepth,
        'StartDynamicDepth': value.startDynamicDepth,
        'EndDynamicDepth': value.endDynamicDepth,
    };
}

