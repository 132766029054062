/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface GeneratorLoadProfileFactDto
 */
export interface GeneratorLoadProfileFactDto {
    /**
     * 
     * @type {DateDto}
     * @memberof GeneratorLoadProfileFactDto
     */
    at: DateDto;
    /**
     * 
     * @type {number}
     * @memberof GeneratorLoadProfileFactDto
     */
    value: number;
    /**
     * 
     * @type {number}
     * @memberof GeneratorLoadProfileFactDto
     */
    availablePower: number;
}

export function GeneratorLoadProfileFactDtoFromJSON(json: any): GeneratorLoadProfileFactDto {
    return GeneratorLoadProfileFactDtoFromJSONTyped(json, false);
}

export function GeneratorLoadProfileFactDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneratorLoadProfileFactDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'at': DateDtoFromJSON(json['At']),
        'value': json['Value'],
        'availablePower': json['AvailablePower'],
    };
}

export function GeneratorLoadProfileFactDtoToJSON(value?: GeneratorLoadProfileFactDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'At': DateDtoToJSON(value.at),
        'Value': value.value,
        'AvailablePower': value.availablePower,
    };
}

