/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface RigDto
 */
export interface RigDto {
    /**
     * 
     * @type {number}
     * @memberof RigDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof RigDto
     */
    externalId: number;
    /**
     * 
     * @type {string}
     * @memberof RigDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RigDto
     */
    shortName: string;
    /**
     * 
     * @type {number}
     * @memberof RigDto
     */
    contractorRigNumber: number;
    /**
     * 
     * @type {string}
     * @memberof RigDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof RigDto
     */
    _class: string;
    /**
     * 
     * @type {number}
     * @memberof RigDto
     */
    countryId: number;
    /**
     * 
     * @type {string}
     * @memberof RigDto
     */
    operatingCenter: string;
    /**
     * 
     * @type {number}
     * @memberof RigDto
     */
    horsePower: number;
    /**
     * 
     * @type {boolean}
     * @memberof RigDto
     */
    isAlpha: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RigDto
     */
    isActive: boolean;
    /**
     * 
     * @type {DateDto}
     * @memberof RigDto
     */
    lastFactUpdatedAt: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof RigDto
     */
    lastActiveFactAt: DateDto;
}

export function RigDtoFromJSON(json: any): RigDto {
    return RigDtoFromJSONTyped(json, false);
}

export function RigDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'externalId': json['ExternalId'],
        'name': json['Name'],
        'shortName': json['ShortName'],
        'contractorRigNumber': json['ContractorRigNumber'],
        'type': json['Type'],
        '_class': json['Class'],
        'countryId': json['CountryId'],
        'operatingCenter': json['OperatingCenter'],
        'horsePower': json['HorsePower'],
        'isAlpha': json['IsAlpha'],
        'isActive': json['IsActive'],
        'lastFactUpdatedAt': DateDtoFromJSON(json['LastFactUpdatedAt']),
        'lastActiveFactAt': DateDtoFromJSON(json['LastActiveFactAt']),
    };
}

export function RigDtoToJSON(value?: RigDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'ExternalId': value.externalId,
        'Name': value.name,
        'ShortName': value.shortName,
        'ContractorRigNumber': value.contractorRigNumber,
        'Type': value.type,
        'Class': value._class,
        'CountryId': value.countryId,
        'OperatingCenter': value.operatingCenter,
        'HorsePower': value.horsePower,
        'IsAlpha': value.isAlpha,
        'IsActive': value.isActive,
        'LastFactUpdatedAt': DateDtoToJSON(value.lastFactUpdatedAt),
        'LastActiveFactAt': DateDtoToJSON(value.lastActiveFactAt),
    };
}

