import styled from "styled-components";
import { Row } from "utils/componentLibrary";

export const FormGroupContainer = styled.div`
  & + & {
    margin-top: 24px;
  }
`;

export const FormLabel = styled.label`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;

export const FormattedRow = styled(Row)<{ $hasBorder?: boolean }>`
  margin-bottom: ${({ $hasBorder }) => $hasBorder ? "0px" : "12px"};
`;