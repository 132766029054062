/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SingleStandKpiDetailByShiftDto
 */
export interface SingleStandKpiDetailByShiftDto {
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiDetailByShiftDto
     */
    dayTargetValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiDetailByShiftDto
     */
    nightTargetValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiDetailByShiftDto
     */
    dayValue: number;
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiDetailByShiftDto
     */
    nightValue: number;
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiDetailByShiftDto
     */
    operationCount: number;
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiDetailByShiftDto
     */
    dayOperationCount: number;
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiDetailByShiftDto
     */
    nightOperationCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof SingleStandKpiDetailByShiftDto
     */
    isOutlier: boolean;
}

export function SingleStandKpiDetailByShiftDtoFromJSON(json: any): SingleStandKpiDetailByShiftDto {
    return SingleStandKpiDetailByShiftDtoFromJSONTyped(json, false);
}

export function SingleStandKpiDetailByShiftDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SingleStandKpiDetailByShiftDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dayTargetValue': !exists(json, 'DayTargetValue') ? undefined : json['DayTargetValue'],
        'nightTargetValue': !exists(json, 'NightTargetValue') ? undefined : json['NightTargetValue'],
        'dayValue': json['DayValue'],
        'nightValue': json['NightValue'],
        'operationCount': json['OperationCount'],
        'dayOperationCount': json['DayOperationCount'],
        'nightOperationCount': json['NightOperationCount'],
        'isOutlier': json['IsOutlier'],
    };
}

export function SingleStandKpiDetailByShiftDtoToJSON(value?: SingleStandKpiDetailByShiftDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DayTargetValue': value.dayTargetValue,
        'NightTargetValue': value.nightTargetValue,
        'DayValue': value.dayValue,
        'NightValue': value.nightValue,
        'OperationCount': value.operationCount,
        'DayOperationCount': value.dayOperationCount,
        'NightOperationCount': value.nightOperationCount,
        'IsOutlier': value.isOutlier,
    };
}

