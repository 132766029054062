/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    RigCardAverages,
    RigCardAveragesFromJSON,
    RigCardAveragesFromJSONTyped,
    RigCardAveragesToJSON,
} from './RigCardAverages';
import {
    RigCardGroupFactDto,
    RigCardGroupFactDtoFromJSON,
    RigCardGroupFactDtoFromJSONTyped,
    RigCardGroupFactDtoToJSON,
} from './RigCardGroupFactDto';
import {
    RigScorecardComplianceRate,
    RigScorecardComplianceRateFromJSON,
    RigScorecardComplianceRateFromJSONTyped,
    RigScorecardComplianceRateToJSON,
} from './RigScorecardComplianceRate';
import {
    ScoreSummaryRootDto,
    ScoreSummaryRootDtoFromJSON,
    ScoreSummaryRootDtoFromJSONTyped,
    ScoreSummaryRootDtoToJSON,
} from './ScoreSummaryRootDto';
import {
    StopWatchDto,
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';

/**
 * 
 * @export
 * @interface RigCardFactSetDto
 */
export interface RigCardFactSetDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof RigCardFactSetDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {ResultDataState}
     * @memberof RigCardFactSetDto
     */
    dataState: ResultDataState;
    /**
     * 
     * @type {boolean}
     * @memberof RigCardFactSetDto
     */
    usesExceptions: boolean;
    /**
     * 
     * @type {number}
     * @memberof RigCardFactSetDto
     */
    totalDowntime?: number | null;
    /**
     * 
     * @type {RigCardAverages}
     * @memberof RigCardFactSetDto
     */
    averages: RigCardAverages;
    /**
     * 
     * @type {RigScorecardComplianceRate}
     * @memberof RigCardFactSetDto
     */
    complianceRate: RigScorecardComplianceRate;
    /**
     * 
     * @type {Array<RigCardGroupFactDto>}
     * @memberof RigCardFactSetDto
     */
    cardGroups: Array<RigCardGroupFactDto>;
    /**
     * 
     * @type {ScoreSummaryRootDto}
     * @memberof RigCardFactSetDto
     */
    scoreSummary: ScoreSummaryRootDto;
}

export function RigCardFactSetDtoFromJSON(json: any): RigCardFactSetDto {
    return RigCardFactSetDtoFromJSONTyped(json, false);
}

export function RigCardFactSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigCardFactSetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
        'usesExceptions': json['UsesExceptions'],
        'totalDowntime': !exists(json, 'TotalDowntime') ? undefined : json['TotalDowntime'],
        'averages': RigCardAveragesFromJSON(json['Averages']),
        'complianceRate': RigScorecardComplianceRateFromJSON(json['ComplianceRate']),
        'cardGroups': ((json['CardGroups'] as Array<any>).map(RigCardGroupFactDtoFromJSON)),
        'scoreSummary': ScoreSummaryRootDtoFromJSON(json['ScoreSummary']),
    };
}

export function RigCardFactSetDtoToJSON(value?: RigCardFactSetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value.stopWatch),
        'DataState': ResultDataStateToJSON(value.dataState),
        'UsesExceptions': value.usesExceptions,
        'TotalDowntime': value.totalDowntime,
        'Averages': RigCardAveragesToJSON(value.averages),
        'ComplianceRate': RigScorecardComplianceRateToJSON(value.complianceRate),
        'CardGroups': ((value.cardGroups as Array<any>).map(RigCardGroupFactDtoToJSON)),
        'ScoreSummary': ScoreSummaryRootDtoToJSON(value.scoreSummary),
    };
}

