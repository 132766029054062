/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BasinAreaDto,
    BasinAreaDtoFromJSON,
    BasinAreaDtoFromJSONTyped,
    BasinAreaDtoToJSON,
} from './BasinAreaDto';

/**
 * 
 * @export
 * @interface BasinDto
 */
export interface BasinDto {
    /**
     * 
     * @type {number}
     * @memberof BasinDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BasinDto
     */
    name: string;
    /**
     * 
     * @type {Array<BasinAreaDto>}
     * @memberof BasinDto
     */
    basinAreas: Array<BasinAreaDto>;
}

export function BasinDtoFromJSON(json: any): BasinDto {
    return BasinDtoFromJSONTyped(json, false);
}

export function BasinDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BasinDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': json['Name'],
        'basinAreas': ((json['BasinAreas'] as Array<any>).map(BasinAreaDtoFromJSON)),
    };
}

export function BasinDtoToJSON(value?: BasinDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'Name': value.name,
        'BasinAreas': ((value.basinAreas as Array<any>).map(BasinAreaDtoToJSON)),
    };
}

