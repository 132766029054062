/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GeneratorSlotType,
    GeneratorSlotTypeFromJSON,
    GeneratorSlotTypeFromJSONTyped,
    GeneratorSlotTypeToJSON,
} from './GeneratorSlotType';

/**
 * 
 * @export
 * @interface RigPowerUsageSliceDto
 */
export interface RigPowerUsageSliceDto {
    /**
     * 
     * @type {number}
     * @memberof RigPowerUsageSliceDto
     */
    sliceValue: number;
    /**
     * 
     * @type {number}
     * @memberof RigPowerUsageSliceDto
     */
    positionalValue: number;
    /**
     * 
     * @type {number}
     * @memberof RigPowerUsageSliceDto
     */
    ghgEmissions: number;
    /**
     * 
     * @type {number}
     * @memberof RigPowerUsageSliceDto
     */
    position: number;
    /**
     * 
     * @type {GeneratorSlotType}
     * @memberof RigPowerUsageSliceDto
     */
    generator: GeneratorSlotType;
}

export function RigPowerUsageSliceDtoFromJSON(json: any): RigPowerUsageSliceDto {
    return RigPowerUsageSliceDtoFromJSONTyped(json, false);
}

export function RigPowerUsageSliceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigPowerUsageSliceDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sliceValue': json['SliceValue'],
        'positionalValue': json['PositionalValue'],
        'ghgEmissions': json['GhgEmissions'],
        'position': json['Position'],
        'generator': GeneratorSlotTypeFromJSON(json['Generator']),
    };
}

export function RigPowerUsageSliceDtoToJSON(value?: RigPowerUsageSliceDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'SliceValue': value.sliceValue,
        'PositionalValue': value.positionalValue,
        'GhgEmissions': value.ghgEmissions,
        'Position': value.position,
        'Generator': GeneratorSlotTypeToJSON(value.generator),
    };
}

