/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DashboardQueryDto,
    DashboardQueryDtoFromJSON,
    DashboardQueryDtoToJSON,
    DashboardType,
    DashboardTypeFromJSON,
    DashboardTypeToJSON,
    UserLensDto,
    UserLensDtoFromJSON,
    UserLensDtoToJSON,
    UserLensPositionSetDto,
    UserLensPositionSetDtoFromJSON,
    UserLensPositionSetDtoToJSON,
    UserLensSetDto,
    UserLensSetDtoFromJSON,
    UserLensSetDtoToJSON,
    UserLensTabSetDto,
    UserLensTabSetDtoFromJSON,
    UserLensTabSetDtoToJSON,
} from '../models';

export interface ApiGenericUserLensesIdDeleteRequest {
    id: number;
}

export interface ApiGenericUserLensesObsoleteGetRequest {
    userLensDto?: UserLensDto;
}

export interface ApiGenericUserLensesPositionsDashboardTypeGetRequest {
    dashboardType: DashboardType;
}

export interface ApiGenericUserLensesPositionsPutRequest {
    userLensPositionSetDto?: UserLensPositionSetDto;
}

export interface ApiGenericUserLensesPutRequest {
    dashboardQueryDto?: DashboardQueryDto;
}

export interface ApiGenericUserLensesTabsPutRequest {
    dashboardQueryDto?: DashboardQueryDto;
}

/**
 * 
 */
export class GenericUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiGenericUserLensesIdDeleteRaw(requestParameters: ApiGenericUserLensesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGenericUserLensesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/GenericUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiGenericUserLensesIdDelete(requestParameters: ApiGenericUserLensesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.apiGenericUserLensesIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiGenericUserLensesObsoleteGetRaw(requestParameters: ApiGenericUserLensesObsoleteGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/GenericUserLenses/Obsolete`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            body: UserLensDtoToJSON(requestParameters.userLensDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiGenericUserLensesObsoleteGet(requestParameters: ApiGenericUserLensesObsoleteGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserLensDto> {
        const response = await this.apiGenericUserLensesObsoleteGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiGenericUserLensesPositionsDashboardTypeGetRaw(requestParameters: ApiGenericUserLensesPositionsDashboardTypeGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<UserLensPositionSetDto>>> {
        if (requestParameters.dashboardType === null || requestParameters.dashboardType === undefined) {
            throw new runtime.RequiredError('dashboardType','Required parameter requestParameters.dashboardType was null or undefined when calling apiGenericUserLensesPositionsDashboardTypeGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/GenericUserLenses/Positions/{dashboardType}`.replace(`{${"dashboardType"}}`, encodeURIComponent(String(requestParameters.dashboardType))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserLensPositionSetDtoFromJSON));
    }

    /**
     */
    async apiGenericUserLensesPositionsDashboardTypeGet(requestParameters: ApiGenericUserLensesPositionsDashboardTypeGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<UserLensPositionSetDto>> {
        const response = await this.apiGenericUserLensesPositionsDashboardTypeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiGenericUserLensesPositionsPutRaw(requestParameters: ApiGenericUserLensesPositionsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserLensPositionSetDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/GenericUserLenses/Positions`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserLensPositionSetDtoToJSON(requestParameters.userLensPositionSetDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserLensPositionSetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiGenericUserLensesPositionsPut(requestParameters: ApiGenericUserLensesPositionsPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserLensPositionSetDto> {
        const response = await this.apiGenericUserLensesPositionsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiGenericUserLensesPutRaw(requestParameters: ApiGenericUserLensesPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserLensSetDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/GenericUserLenses`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardQueryDtoToJSON(requestParameters.dashboardQueryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserLensSetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiGenericUserLensesPut(requestParameters: ApiGenericUserLensesPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserLensSetDto> {
        const response = await this.apiGenericUserLensesPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiGenericUserLensesTabsPutRaw(requestParameters: ApiGenericUserLensesTabsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<UserLensTabSetDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/GenericUserLenses/Tabs`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardQueryDtoToJSON(requestParameters.dashboardQueryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserLensTabSetDtoFromJSON));
    }

    /**
     */
    async apiGenericUserLensesTabsPut(requestParameters: ApiGenericUserLensesTabsPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<UserLensTabSetDto>> {
        const response = await this.apiGenericUserLensesTabsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
