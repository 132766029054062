/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DieselNatGasRatioStatsUserLensDto
 */
export interface DieselNatGasRatioStatsUserLensDto {
    /**
     * 
     * @type {number}
     * @memberof DieselNatGasRatioStatsUserLensDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof DieselNatGasRatioStatsUserLensDto
     */
    lensTabId: number;
    /**
     * 
     * @type {number}
     * @memberof DieselNatGasRatioStatsUserLensDto
     */
    lensTemplateId: number;
    /**
     * 
     * @type {boolean}
     * @memberof DieselNatGasRatioStatsUserLensDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DieselNatGasRatioStatsUserLensDto
     */
    label: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DieselNatGasRatioStatsUserLensDto
     */
    isSystem: boolean;
}

export function DieselNatGasRatioStatsUserLensDtoFromJSON(json: any): DieselNatGasRatioStatsUserLensDto {
    return DieselNatGasRatioStatsUserLensDtoFromJSONTyped(json, false);
}

export function DieselNatGasRatioStatsUserLensDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DieselNatGasRatioStatsUserLensDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'lensTabId': json['LensTabId'],
        'lensTemplateId': json['LensTemplateId'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'label': json['Label'],
        'isSystem': json['IsSystem'],
    };
}

export function DieselNatGasRatioStatsUserLensDtoToJSON(value?: DieselNatGasRatioStatsUserLensDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'LensTabId': value.lensTabId,
        'LensTemplateId': value.lensTemplateId,
        'IsAvailableOnReports': value.isAvailableOnReports,
        'Label': value.label,
        'IsSystem': value.isSystem,
    };
}

