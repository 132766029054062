/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DashboardType,
    DashboardTypeFromJSON,
    DashboardTypeFromJSONTyped,
    DashboardTypeToJSON,
} from './DashboardType';
import {
    LensTemplateType,
    LensTemplateTypeFromJSON,
    LensTemplateTypeFromJSONTyped,
    LensTemplateTypeToJSON,
} from './LensTemplateType';
import {
    OperationCategoryType,
    OperationCategoryTypeFromJSON,
    OperationCategoryTypeFromJSONTyped,
    OperationCategoryTypeToJSON,
} from './OperationCategoryType';
import {
    WedgeBreakdownDto,
    WedgeBreakdownDtoFromJSON,
    WedgeBreakdownDtoFromJSONTyped,
    WedgeBreakdownDtoToJSON,
} from './WedgeBreakdownDto';

/**
 * 
 * @export
 * @interface WedgeLensTemplateDto
 */
export interface WedgeLensTemplateDto {
    /**
     * 
     * @type {number}
     * @memberof WedgeLensTemplateDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof WedgeLensTemplateDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WedgeLensTemplateDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WedgeLensTemplateDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {LensTemplateType}
     * @memberof WedgeLensTemplateDto
     */
    type: LensTemplateType;
    /**
     * 
     * @type {DashboardType}
     * @memberof WedgeLensTemplateDto
     */
    targetDashboardType: DashboardType;
    /**
     * 
     * @type {number}
     * @memberof WedgeLensTemplateDto
     */
    nbCondensedDataPoints: number;
    /**
     * 
     * @type {boolean}
     * @memberof WedgeLensTemplateDto
     */
    useBitDepth: boolean;
    /**
     * 
     * @type {number}
     * @memberof WedgeLensTemplateDto
     */
    position: number;
    /**
     * 
     * @type {number}
     * @memberof WedgeLensTemplateDto
     */
    wellAggregationThreshold: number;
    /**
     * 
     * @type {OperationCategoryType}
     * @memberof WedgeLensTemplateDto
     */
    operationCategory: OperationCategoryType;
    /**
     * 
     * @type {Array<WedgeBreakdownDto>}
     * @memberof WedgeLensTemplateDto
     */
    breakdowns?: Array<WedgeBreakdownDto> | null;
}

export function WedgeLensTemplateDtoFromJSON(json: any): WedgeLensTemplateDto {
    return WedgeLensTemplateDtoFromJSONTyped(json, false);
}

export function WedgeLensTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WedgeLensTemplateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': !exists(json, 'Name') ? undefined : json['Name'],
        'description': !exists(json, 'Description') ? undefined : json['Description'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'type': LensTemplateTypeFromJSON(json['Type']),
        'targetDashboardType': DashboardTypeFromJSON(json['TargetDashboardType']),
        'nbCondensedDataPoints': json['NbCondensedDataPoints'],
        'useBitDepth': json['UseBitDepth'],
        'position': json['Position'],
        'wellAggregationThreshold': json['WellAggregationThreshold'],
        'operationCategory': OperationCategoryTypeFromJSON(json['OperationCategory']),
        'breakdowns': !exists(json, 'Breakdowns') ? undefined : (json['Breakdowns'] === null ? null : (json['Breakdowns'] as Array<any>).map(WedgeBreakdownDtoFromJSON)),
    };
}

export function WedgeLensTemplateDtoToJSON(value?: WedgeLensTemplateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'Name': value.name,
        'Description': value.description,
        'IsAvailableOnReports': value.isAvailableOnReports,
        'Type': LensTemplateTypeToJSON(value.type),
        'TargetDashboardType': DashboardTypeToJSON(value.targetDashboardType),
        'NbCondensedDataPoints': value.nbCondensedDataPoints,
        'UseBitDepth': value.useBitDepth,
        'Position': value.position,
        'WellAggregationThreshold': value.wellAggregationThreshold,
        'OperationCategory': OperationCategoryTypeToJSON(value.operationCategory),
        'Breakdowns': value.breakdowns === undefined ? undefined : (value.breakdowns === null ? null : (value.breakdowns as Array<any>).map(WedgeBreakdownDtoToJSON)),
    };
}

