/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RigLeaderboardWellAco
 */
export interface RigLeaderboardWellAco {
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardWellAco
     */
    wellName: string;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardWellAco
     */
    curatedUidWellEdr: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof RigLeaderboardWellAco
     */
    jobNumbers: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardWellAco
     */
    operatorName: string;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardWellAco
     */
    overallWellScorePercentage: number | null;
}

export function RigLeaderboardWellAcoFromJSON(json: any): RigLeaderboardWellAco {
    return RigLeaderboardWellAcoFromJSONTyped(json, false);
}

export function RigLeaderboardWellAcoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigLeaderboardWellAco {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'wellName': json['WellName'],
        'curatedUidWellEdr': json['CuratedUidWellEdr'],
        'jobNumbers': json['JobNumbers'],
        'operatorName': json['OperatorName'],
        'overallWellScorePercentage': json['OverallWellScorePercentage'],
    };
}

export function RigLeaderboardWellAcoToJSON(value?: RigLeaderboardWellAco | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'WellName': value.wellName,
        'CuratedUidWellEdr': value.curatedUidWellEdr,
        'JobNumbers': value.jobNumbers,
        'OperatorName': value.operatorName,
        'OverallWellScorePercentage': value.overallWellScorePercentage,
    };
}

