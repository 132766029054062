/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SingleStandKpiComparisonInfoDto
 */
export interface SingleStandKpiComparisonInfoDto {
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiComparisonInfoDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiComparisonInfoDto
     */
    position: number;
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiComparisonInfoDto
     */
    average: number;
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiComparisonInfoDto
     */
    targetAverage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiComparisonInfoDto
     */
    absoluteDifference: number;
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiComparisonInfoDto
     */
    relativeDifference: number;
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiComparisonInfoDto
     */
    operationCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof SingleStandKpiComparisonInfoDto
     */
    isOutlier: boolean;
}

export function SingleStandKpiComparisonInfoDtoFromJSON(json: any): SingleStandKpiComparisonInfoDto {
    return SingleStandKpiComparisonInfoDtoFromJSONTyped(json, false);
}

export function SingleStandKpiComparisonInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SingleStandKpiComparisonInfoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'position': json['Position'],
        'average': json['Average'],
        'targetAverage': !exists(json, 'TargetAverage') ? undefined : json['TargetAverage'],
        'absoluteDifference': json['AbsoluteDifference'],
        'relativeDifference': json['RelativeDifference'],
        'operationCount': json['OperationCount'],
        'isOutlier': json['IsOutlier'],
    };
}

export function SingleStandKpiComparisonInfoDtoToJSON(value?: SingleStandKpiComparisonInfoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'Position': value.position,
        'Average': value.average,
        'TargetAverage': value.targetAverage,
        'AbsoluteDifference': value.absoluteDifference,
        'RelativeDifference': value.relativeDifference,
        'OperationCount': value.operationCount,
        'IsOutlier': value.isOutlier,
    };
}

