/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RigCardFactKpiValue,
    RigCardFactKpiValueFromJSON,
    RigCardFactKpiValueFromJSONTyped,
    RigCardFactKpiValueToJSON,
} from './RigCardFactKpiValue';
import {
    RigCardFactValue,
    RigCardFactValueFromJSON,
    RigCardFactValueFromJSONTyped,
    RigCardFactValueToJSON,
} from './RigCardFactValue';
import {
    ScoreComplianceStateType,
    ScoreComplianceStateTypeFromJSON,
    ScoreComplianceStateTypeFromJSONTyped,
    ScoreComplianceStateTypeToJSON,
} from './ScoreComplianceStateType';
import {
    ScoreSummaryRootDto,
    ScoreSummaryRootDtoFromJSON,
    ScoreSummaryRootDtoFromJSONTyped,
    ScoreSummaryRootDtoToJSON,
} from './ScoreSummaryRootDto';

/**
 * 
 * @export
 * @interface RigCardFactDto
 */
export interface RigCardFactDto {
    /**
     * 
     * @type {string}
     * @memberof RigCardFactDto
     */
    label: string;
    /**
     * 
     * @type {RigCardFactKpiValue}
     * @memberof RigCardFactDto
     */
    kpiValue: RigCardFactKpiValue;
    /**
     * 
     * @type {RigCardFactValue}
     * @memberof RigCardFactDto
     */
    operatorScore: RigCardFactValue;
    /**
     * 
     * @type {RigCardFactValue}
     * @memberof RigCardFactDto
     */
    rigScore: RigCardFactValue;
    /**
     * 
     * @type {RigCardFactValue}
     * @memberof RigCardFactDto
     */
    improvementScore: RigCardFactValue;
    /**
     * 
     * @type {RigCardFactValue}
     * @memberof RigCardFactDto
     */
    targetDeltaTime: RigCardFactValue;
    /**
     * 
     * @type {RigCardFactValue}
     * @memberof RigCardFactDto
     */
    invisibleLostTime: RigCardFactValue;
    /**
     * 
     * @type {RigCardFactValue}
     * @memberof RigCardFactDto
     */
    savedTime: RigCardFactValue;
    /**
     * 
     * @type {number}
     * @memberof RigCardFactDto
     */
    targetValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardFactDto
     */
    pdBenchmarkValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardFactDto
     */
    baseValue?: number | null;
    /**
     * 
     * @type {ScoreComplianceStateType}
     * @memberof RigCardFactDto
     */
    operatorScoreComplianceState: ScoreComplianceStateType;
    /**
     * 
     * @type {ScoreComplianceStateType}
     * @memberof RigCardFactDto
     */
    rigScoreComplianceState: ScoreComplianceStateType;
    /**
     * 
     * @type {ScoreComplianceStateType}
     * @memberof RigCardFactDto
     */
    improvementScoreComplianceState: ScoreComplianceStateType;
    /**
     * 
     * @type {ScoreSummaryRootDto}
     * @memberof RigCardFactDto
     */
    scoreSummary: ScoreSummaryRootDto;
}

export function RigCardFactDtoFromJSON(json: any): RigCardFactDto {
    return RigCardFactDtoFromJSONTyped(json, false);
}

export function RigCardFactDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigCardFactDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['Label'],
        'kpiValue': RigCardFactKpiValueFromJSON(json['KpiValue']),
        'operatorScore': RigCardFactValueFromJSON(json['OperatorScore']),
        'rigScore': RigCardFactValueFromJSON(json['RigScore']),
        'improvementScore': RigCardFactValueFromJSON(json['ImprovementScore']),
        'targetDeltaTime': RigCardFactValueFromJSON(json['TargetDeltaTime']),
        'invisibleLostTime': RigCardFactValueFromJSON(json['InvisibleLostTime']),
        'savedTime': RigCardFactValueFromJSON(json['SavedTime']),
        'targetValue': !exists(json, 'TargetValue') ? undefined : json['TargetValue'],
        'pdBenchmarkValue': !exists(json, 'PdBenchmarkValue') ? undefined : json['PdBenchmarkValue'],
        'baseValue': !exists(json, 'BaseValue') ? undefined : json['BaseValue'],
        'operatorScoreComplianceState': ScoreComplianceStateTypeFromJSON(json['OperatorScoreComplianceState']),
        'rigScoreComplianceState': ScoreComplianceStateTypeFromJSON(json['RigScoreComplianceState']),
        'improvementScoreComplianceState': ScoreComplianceStateTypeFromJSON(json['ImprovementScoreComplianceState']),
        'scoreSummary': ScoreSummaryRootDtoFromJSON(json['ScoreSummary']),
    };
}

export function RigCardFactDtoToJSON(value?: RigCardFactDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Label': value.label,
        'KpiValue': RigCardFactKpiValueToJSON(value.kpiValue),
        'OperatorScore': RigCardFactValueToJSON(value.operatorScore),
        'RigScore': RigCardFactValueToJSON(value.rigScore),
        'ImprovementScore': RigCardFactValueToJSON(value.improvementScore),
        'TargetDeltaTime': RigCardFactValueToJSON(value.targetDeltaTime),
        'InvisibleLostTime': RigCardFactValueToJSON(value.invisibleLostTime),
        'SavedTime': RigCardFactValueToJSON(value.savedTime),
        'TargetValue': value.targetValue,
        'PdBenchmarkValue': value.pdBenchmarkValue,
        'BaseValue': value.baseValue,
        'OperatorScoreComplianceState': ScoreComplianceStateTypeToJSON(value.operatorScoreComplianceState),
        'RigScoreComplianceState': ScoreComplianceStateTypeToJSON(value.rigScoreComplianceState),
        'ImprovementScoreComplianceState': ScoreComplianceStateTypeToJSON(value.improvementScoreComplianceState),
        'ScoreSummary': ScoreSummaryRootDtoToJSON(value.scoreSummary),
    };
}

