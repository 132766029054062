/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StandGroupSummaryDto
 */
export interface StandGroupSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof StandGroupSummaryDto
     */
    allAverage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StandGroupSummaryDto
     */
    focalAverage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StandGroupSummaryDto
     */
    comparisonAverage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StandGroupSummaryDto
     */
    comparisonAverageRelativeDiff?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StandGroupSummaryDto
     */
    focalDistribution?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StandGroupSummaryDto
     */
    comparisonDistribution?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StandGroupSummaryDto
     */
    comparisonDistributionRelativeDiff?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StandGroupSummaryDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof StandGroupSummaryDto
     */
    position: number;
    /**
     * 
     * @type {boolean}
     * @memberof StandGroupSummaryDto
     */
    isAggregate: boolean;
    /**
     * 
     * @type {number}
     * @memberof StandGroupSummaryDto
     */
    median: number;
}

export function StandGroupSummaryDtoFromJSON(json: any): StandGroupSummaryDto {
    return StandGroupSummaryDtoFromJSONTyped(json, false);
}

export function StandGroupSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandGroupSummaryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allAverage': !exists(json, 'AllAverage') ? undefined : json['AllAverage'],
        'focalAverage': !exists(json, 'FocalAverage') ? undefined : json['FocalAverage'],
        'comparisonAverage': !exists(json, 'ComparisonAverage') ? undefined : json['ComparisonAverage'],
        'comparisonAverageRelativeDiff': !exists(json, 'ComparisonAverageRelativeDiff') ? undefined : json['ComparisonAverageRelativeDiff'],
        'focalDistribution': !exists(json, 'FocalDistribution') ? undefined : json['FocalDistribution'],
        'comparisonDistribution': !exists(json, 'ComparisonDistribution') ? undefined : json['ComparisonDistribution'],
        'comparisonDistributionRelativeDiff': !exists(json, 'ComparisonDistributionRelativeDiff') ? undefined : json['ComparisonDistributionRelativeDiff'],
        'id': json['Id'],
        'position': json['Position'],
        'isAggregate': json['IsAggregate'],
        'median': json['Median'],
    };
}

export function StandGroupSummaryDtoToJSON(value?: StandGroupSummaryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AllAverage': value.allAverage,
        'FocalAverage': value.focalAverage,
        'ComparisonAverage': value.comparisonAverage,
        'ComparisonAverageRelativeDiff': value.comparisonAverageRelativeDiff,
        'FocalDistribution': value.focalDistribution,
        'ComparisonDistribution': value.comparisonDistribution,
        'ComparisonDistributionRelativeDiff': value.comparisonDistributionRelativeDiff,
        'Id': value.id,
        'Position': value.position,
        'IsAggregate': value.isAggregate,
        'Median': value.median,
    };
}

