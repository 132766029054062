/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    FuelConsumptionByGeneratorPowerUsageFactDto,
    FuelConsumptionByGeneratorPowerUsageFactDtoFromJSON,
    FuelConsumptionByGeneratorPowerUsageFactDtoFromJSONTyped,
    FuelConsumptionByGeneratorPowerUsageFactDtoToJSON,
} from './FuelConsumptionByGeneratorPowerUsageFactDto';
import {
    FuelFlowRateFactDto,
    FuelFlowRateFactDtoFromJSON,
    FuelFlowRateFactDtoFromJSONTyped,
    FuelFlowRateFactDtoToJSON,
} from './FuelFlowRateFactDto';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    ServiceMessageDto,
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import {
    StopWatchDto,
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';

/**
 * 
 * @export
 * @interface FuelConsumptionByGeneratorResultDto
 */
export interface FuelConsumptionByGeneratorResultDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof FuelConsumptionByGeneratorResultDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof FuelConsumptionByGeneratorResultDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionByGeneratorResultDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof FuelConsumptionByGeneratorResultDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {Array<FuelFlowRateFactDto>}
     * @memberof FuelConsumptionByGeneratorResultDto
     */
    dieselConsumptionFacts?: Array<FuelFlowRateFactDto> | null;
    /**
     * 
     * @type {Array<FuelFlowRateFactDto>}
     * @memberof FuelConsumptionByGeneratorResultDto
     */
    natGasConsumptionFacts?: Array<FuelFlowRateFactDto> | null;
    /**
     * 
     * @type {Array<FuelConsumptionByGeneratorPowerUsageFactDto>}
     * @memberof FuelConsumptionByGeneratorResultDto
     */
    generatorPowerUsageFacts?: Array<FuelConsumptionByGeneratorPowerUsageFactDto> | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof FuelConsumptionByGeneratorResultDto
     */
    dataState: ResultDataState;
}

export function FuelConsumptionByGeneratorResultDtoFromJSON(json: any): FuelConsumptionByGeneratorResultDto {
    return FuelConsumptionByGeneratorResultDtoFromJSONTyped(json, false);
}

export function FuelConsumptionByGeneratorResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FuelConsumptionByGeneratorResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': !exists(json, 'Messages') ? undefined : (json['Messages'] === null ? null : (json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'dieselConsumptionFacts': !exists(json, 'DieselConsumptionFacts') ? undefined : (json['DieselConsumptionFacts'] === null ? null : (json['DieselConsumptionFacts'] as Array<any>).map(FuelFlowRateFactDtoFromJSON)),
        'natGasConsumptionFacts': !exists(json, 'NatGasConsumptionFacts') ? undefined : (json['NatGasConsumptionFacts'] === null ? null : (json['NatGasConsumptionFacts'] as Array<any>).map(FuelFlowRateFactDtoFromJSON)),
        'generatorPowerUsageFacts': !exists(json, 'GeneratorPowerUsageFacts') ? undefined : (json['GeneratorPowerUsageFacts'] === null ? null : (json['GeneratorPowerUsageFacts'] as Array<any>).map(FuelConsumptionByGeneratorPowerUsageFactDtoFromJSON)),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function FuelConsumptionByGeneratorResultDtoToJSON(value?: FuelConsumptionByGeneratorResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value.stopWatch),
        'Messages': value.messages === undefined ? undefined : (value.messages === null ? null : (value.messages as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value.userLensId,
        'LastUpdatedAt': DateDtoToJSON(value.lastUpdatedAt),
        'DieselConsumptionFacts': value.dieselConsumptionFacts === undefined ? undefined : (value.dieselConsumptionFacts === null ? null : (value.dieselConsumptionFacts as Array<any>).map(FuelFlowRateFactDtoToJSON)),
        'NatGasConsumptionFacts': value.natGasConsumptionFacts === undefined ? undefined : (value.natGasConsumptionFacts === null ? null : (value.natGasConsumptionFacts as Array<any>).map(FuelFlowRateFactDtoToJSON)),
        'GeneratorPowerUsageFacts': value.generatorPowerUsageFacts === undefined ? undefined : (value.generatorPowerUsageFacts === null ? null : (value.generatorPowerUsageFacts as Array<any>).map(FuelConsumptionByGeneratorPowerUsageFactDtoToJSON)),
        'DataState': ResultDataStateToJSON(value.dataState),
    };
}

