/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    RigLeaderboardDto,
    RigLeaderboardDtoFromJSON,
    RigLeaderboardDtoFromJSONTyped,
    RigLeaderboardDtoToJSON,
} from './RigLeaderboardDto';
import {
    StopWatchDto,
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';

/**
 * 
 * @export
 * @interface RigLeaderboardFactListsDto
 */
export interface RigLeaderboardFactListsDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof RigLeaderboardFactListsDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {ResultDataState}
     * @memberof RigLeaderboardFactListsDto
     */
    dataState: ResultDataState;
    /**
     * 
     * @type {Array<RigLeaderboardDto>}
     * @memberof RigLeaderboardFactListsDto
     */
    rigLeaderboard: Array<RigLeaderboardDto>;
}

export function RigLeaderboardFactListsDtoFromJSON(json: any): RigLeaderboardFactListsDto {
    return RigLeaderboardFactListsDtoFromJSONTyped(json, false);
}

export function RigLeaderboardFactListsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigLeaderboardFactListsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
        'rigLeaderboard': ((json['RigLeaderboard'] as Array<any>).map(RigLeaderboardDtoFromJSON)),
    };
}

export function RigLeaderboardFactListsDtoToJSON(value?: RigLeaderboardFactListsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value.stopWatch),
        'DataState': ResultDataStateToJSON(value.dataState),
        'RigLeaderboard': ((value.rigLeaderboard as Array<any>).map(RigLeaderboardDtoToJSON)),
    };
}

