/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BatteryStateLensTemplateDto,
    BatteryStateLensTemplateDtoFromJSON,
    BatteryStateLensTemplateDtoFromJSONTyped,
    BatteryStateLensTemplateDtoToJSON,
} from './BatteryStateLensTemplateDto';
import {
    DieselNatGasRatioStatsLensTemplateDto,
    DieselNatGasRatioStatsLensTemplateDtoFromJSON,
    DieselNatGasRatioStatsLensTemplateDtoFromJSONTyped,
    DieselNatGasRatioStatsLensTemplateDtoToJSON,
} from './DieselNatGasRatioStatsLensTemplateDto';
import {
    DrillingProductivityLensTemplateDto,
    DrillingProductivityLensTemplateDtoFromJSON,
    DrillingProductivityLensTemplateDtoFromJSONTyped,
    DrillingProductivityLensTemplateDtoToJSON,
} from './DrillingProductivityLensTemplateDto';
import {
    FuelConsumptionByGeneratorLensTemplateDto,
    FuelConsumptionByGeneratorLensTemplateDtoFromJSON,
    FuelConsumptionByGeneratorLensTemplateDtoFromJSONTyped,
    FuelConsumptionByGeneratorLensTemplateDtoToJSON,
} from './FuelConsumptionByGeneratorLensTemplateDto';
import {
    FuelFlowRateLensTemplateDto,
    FuelFlowRateLensTemplateDtoFromJSON,
    FuelFlowRateLensTemplateDtoFromJSONTyped,
    FuelFlowRateLensTemplateDtoToJSON,
} from './FuelFlowRateLensTemplateDto';
import {
    FuelTankVolumeLensTemplateDto,
    FuelTankVolumeLensTemplateDtoFromJSON,
    FuelTankVolumeLensTemplateDtoFromJSONTyped,
    FuelTankVolumeLensTemplateDtoToJSON,
} from './FuelTankVolumeLensTemplateDto';
import {
    GeneratorLoadProfileLensTemplateDto,
    GeneratorLoadProfileLensTemplateDtoFromJSON,
    GeneratorLoadProfileLensTemplateDtoFromJSONTyped,
    GeneratorLoadProfileLensTemplateDtoToJSON,
} from './GeneratorLoadProfileLensTemplateDto';
import {
    GeneratorStatsLensTemplateDto,
    GeneratorStatsLensTemplateDtoFromJSON,
    GeneratorStatsLensTemplateDtoFromJSONTyped,
    GeneratorStatsLensTemplateDtoToJSON,
} from './GeneratorStatsLensTemplateDto';
import {
    GeneratorStatusBarLensTemplateDto,
    GeneratorStatusBarLensTemplateDtoFromJSON,
    GeneratorStatusBarLensTemplateDtoFromJSONTyped,
    GeneratorStatusBarLensTemplateDtoToJSON,
} from './GeneratorStatusBarLensTemplateDto';
import {
    GhgEmissionsLensTemplateDto,
    GhgEmissionsLensTemplateDtoFromJSON,
    GhgEmissionsLensTemplateDtoFromJSONTyped,
    GhgEmissionsLensTemplateDtoToJSON,
} from './GhgEmissionsLensTemplateDto';
import {
    KpiGroupLensTemplateDto,
    KpiGroupLensTemplateDtoFromJSON,
    KpiGroupLensTemplateDtoFromJSONTyped,
    KpiGroupLensTemplateDtoToJSON,
} from './KpiGroupLensTemplateDto';
import {
    KpiTypeLensTemplateDto,
    KpiTypeLensTemplateDtoFromJSON,
    KpiTypeLensTemplateDtoFromJSONTyped,
    KpiTypeLensTemplateDtoToJSON,
} from './KpiTypeLensTemplateDto';
import {
    NaturalGasStateLensTemplateDto,
    NaturalGasStateLensTemplateDtoFromJSON,
    NaturalGasStateLensTemplateDtoFromJSONTyped,
    NaturalGasStateLensTemplateDtoToJSON,
} from './NaturalGasStateLensTemplateDto';
import {
    ParameterByDepthLensTemplateDto,
    ParameterByDepthLensTemplateDtoFromJSON,
    ParameterByDepthLensTemplateDtoFromJSONTyped,
    ParameterByDepthLensTemplateDtoToJSON,
} from './ParameterByDepthLensTemplateDto';
import {
    ParameterHeatmapLensTemplateDto,
    ParameterHeatmapLensTemplateDtoFromJSON,
    ParameterHeatmapLensTemplateDtoFromJSONTyped,
    ParameterHeatmapLensTemplateDtoToJSON,
} from './ParameterHeatmapLensTemplateDto';
import {
    PivotKpiGroupLensTemplateDto,
    PivotKpiGroupLensTemplateDtoFromJSON,
    PivotKpiGroupLensTemplateDtoFromJSONTyped,
    PivotKpiGroupLensTemplateDtoToJSON,
} from './PivotKpiGroupLensTemplateDto';
import {
    PivotKpiTypeLensTemplateDto,
    PivotKpiTypeLensTemplateDtoFromJSON,
    PivotKpiTypeLensTemplateDtoFromJSONTyped,
    PivotKpiTypeLensTemplateDtoToJSON,
} from './PivotKpiTypeLensTemplateDto';
import {
    PowerConsumptionByOperationLensTemplateDto,
    PowerConsumptionByOperationLensTemplateDtoFromJSON,
    PowerConsumptionByOperationLensTemplateDtoFromJSONTyped,
    PowerConsumptionByOperationLensTemplateDtoToJSON,
} from './PowerConsumptionByOperationLensTemplateDto';
import {
    PowerLoadDistributionLensTemplateDto,
    PowerLoadDistributionLensTemplateDtoFromJSON,
    PowerLoadDistributionLensTemplateDtoFromJSONTyped,
    PowerLoadDistributionLensTemplateDtoToJSON,
} from './PowerLoadDistributionLensTemplateDto';
import {
    PowerLoadEfficiencyLensTemplateDto,
    PowerLoadEfficiencyLensTemplateDtoFromJSON,
    PowerLoadEfficiencyLensTemplateDtoFromJSONTyped,
    PowerLoadEfficiencyLensTemplateDtoToJSON,
} from './PowerLoadEfficiencyLensTemplateDto';
import {
    RigFleetPerformanceCardLensTemplateDto,
    RigFleetPerformanceCardLensTemplateDtoFromJSON,
    RigFleetPerformanceCardLensTemplateDtoFromJSONTyped,
    RigFleetPerformanceCardLensTemplateDtoToJSON,
} from './RigFleetPerformanceCardLensTemplateDto';
import {
    RigPowerLensTemplateDto,
    RigPowerLensTemplateDtoFromJSON,
    RigPowerLensTemplateDtoFromJSONTyped,
    RigPowerLensTemplateDtoToJSON,
} from './RigPowerLensTemplateDto';
import {
    RigScorecardLensTemplateDto,
    RigScorecardLensTemplateDtoFromJSON,
    RigScorecardLensTemplateDtoFromJSONTyped,
    RigScorecardLensTemplateDtoToJSON,
} from './RigScorecardLensTemplateDto';
import {
    StatCardLensTemplateDto,
    StatCardLensTemplateDtoFromJSON,
    StatCardLensTemplateDtoFromJSONTyped,
    StatCardLensTemplateDtoToJSON,
} from './StatCardLensTemplateDto';
import {
    StickSlipLensTemplateDto,
    StickSlipLensTemplateDtoFromJSON,
    StickSlipLensTemplateDtoFromJSONTyped,
    StickSlipLensTemplateDtoToJSON,
} from './StickSlipLensTemplateDto';
import {
    TagBottomFingerprintLensTemplateDto,
    TagBottomFingerprintLensTemplateDtoFromJSON,
    TagBottomFingerprintLensTemplateDtoFromJSONTyped,
    TagBottomFingerprintLensTemplateDtoToJSON,
} from './TagBottomFingerprintLensTemplateDto';
import {
    TorqueAndDragLensTemplateDto,
    TorqueAndDragLensTemplateDtoFromJSON,
    TorqueAndDragLensTemplateDtoFromJSONTyped,
    TorqueAndDragLensTemplateDtoToJSON,
} from './TorqueAndDragLensTemplateDto';
import {
    WedgeLensTemplateDto,
    WedgeLensTemplateDtoFromJSON,
    WedgeLensTemplateDtoFromJSONTyped,
    WedgeLensTemplateDtoToJSON,
} from './WedgeLensTemplateDto';
import {
    WellDrillingSummaryLensTemplateDto,
    WellDrillingSummaryLensTemplateDtoFromJSON,
    WellDrillingSummaryLensTemplateDtoFromJSONTyped,
    WellDrillingSummaryLensTemplateDtoToJSON,
} from './WellDrillingSummaryLensTemplateDto';

/**
 * 
 * @export
 * @interface LensTemplateSetDto
 */
export interface LensTemplateSetDto {
    /**
     * 
     * @type {Array<KpiTypeLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    kpiTypeLensTemplates?: Array<KpiTypeLensTemplateDto> | null;
    /**
     * 
     * @type {Array<PivotKpiTypeLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    pivotKpiTypeLensTemplates?: Array<PivotKpiTypeLensTemplateDto> | null;
    /**
     * 
     * @type {Array<KpiGroupLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    kpiGroupLensTemplates?: Array<KpiGroupLensTemplateDto> | null;
    /**
     * 
     * @type {Array<PivotKpiGroupLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    pivotKpiGroupLensTemplates?: Array<PivotKpiGroupLensTemplateDto> | null;
    /**
     * 
     * @type {Array<WedgeLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    wedgeLensTemplates?: Array<WedgeLensTemplateDto> | null;
    /**
     * 
     * @type {Array<ParameterByDepthLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    parameterByDepthLensTemplates?: Array<ParameterByDepthLensTemplateDto> | null;
    /**
     * 
     * @type {Array<WellDrillingSummaryLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    wellDrillingSummaryTemplates?: Array<WellDrillingSummaryLensTemplateDto> | null;
    /**
     * 
     * @type {Array<ParameterHeatmapLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    parameterHeatmapLensTemplates?: Array<ParameterHeatmapLensTemplateDto> | null;
    /**
     * 
     * @type {Array<TorqueAndDragLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    torqueAndDragLensTemplates?: Array<TorqueAndDragLensTemplateDto> | null;
    /**
     * 
     * @type {Array<DrillingProductivityLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    drillingProductivityLensTemplates?: Array<DrillingProductivityLensTemplateDto> | null;
    /**
     * 
     * @type {Array<BatteryStateLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    batteryStateLensTemplates?: Array<BatteryStateLensTemplateDto> | null;
    /**
     * 
     * @type {Array<RigPowerLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    rigPowerLensTemplates?: Array<RigPowerLensTemplateDto> | null;
    /**
     * 
     * @type {Array<PowerConsumptionByOperationLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    powerConsumptionByOperationLensTemplates?: Array<PowerConsumptionByOperationLensTemplateDto> | null;
    /**
     * 
     * @type {Array<StatCardLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    statCardLensTemplates?: Array<StatCardLensTemplateDto> | null;
    /**
     * 
     * @type {Array<NaturalGasStateLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    naturalGasStateLensTemplates?: Array<NaturalGasStateLensTemplateDto> | null;
    /**
     * 
     * @type {Array<GeneratorStatusBarLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    generatorStatusBarLensTemplates?: Array<GeneratorStatusBarLensTemplateDto> | null;
    /**
     * 
     * @type {Array<FuelFlowRateLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    fuelFlowRateLensTemplates?: Array<FuelFlowRateLensTemplateDto> | null;
    /**
     * 
     * @type {Array<GeneratorStatsLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    generatorStatsLensTemplates?: Array<GeneratorStatsLensTemplateDto> | null;
    /**
     * 
     * @type {Array<GeneratorLoadProfileLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    generatorLoadProfileLensTemplates?: Array<GeneratorLoadProfileLensTemplateDto> | null;
    /**
     * 
     * @type {Array<PowerLoadDistributionLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    powerLoadDistributionLensTemplates?: Array<PowerLoadDistributionLensTemplateDto> | null;
    /**
     * 
     * @type {Array<GhgEmissionsLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    ghgEmissionsLensTemplates?: Array<GhgEmissionsLensTemplateDto> | null;
    /**
     * 
     * @type {Array<FuelTankVolumeLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    fuelTankVolumeLensTemplates?: Array<FuelTankVolumeLensTemplateDto> | null;
    /**
     * 
     * @type {Array<PowerLoadEfficiencyLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    powerLoadEfficiencyLensTemplates?: Array<PowerLoadEfficiencyLensTemplateDto> | null;
    /**
     * 
     * @type {Array<FuelConsumptionByGeneratorLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    fuelConsumptionByGeneratorLensTemplates?: Array<FuelConsumptionByGeneratorLensTemplateDto> | null;
    /**
     * 
     * @type {Array<DieselNatGasRatioStatsLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    dieselNatGasRatioStatsLensTemplates?: Array<DieselNatGasRatioStatsLensTemplateDto> | null;
    /**
     * 
     * @type {Array<RigFleetPerformanceCardLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    rigFleetPerformanceCardLensTemplates?: Array<RigFleetPerformanceCardLensTemplateDto> | null;
    /**
     * 
     * @type {Array<RigScorecardLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    rigScorecardLensTemplates?: Array<RigScorecardLensTemplateDto> | null;
    /**
     * 
     * @type {Array<TagBottomFingerprintLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    tagBottomFingerprintLensTemplates?: Array<TagBottomFingerprintLensTemplateDto> | null;
    /**
     * 
     * @type {Array<StickSlipLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    stickSlipLensTemplates?: Array<StickSlipLensTemplateDto> | null;
}

export function LensTemplateSetDtoFromJSON(json: any): LensTemplateSetDto {
    return LensTemplateSetDtoFromJSONTyped(json, false);
}

export function LensTemplateSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LensTemplateSetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kpiTypeLensTemplates': !exists(json, 'KpiTypeLensTemplates') ? undefined : (json['KpiTypeLensTemplates'] === null ? null : (json['KpiTypeLensTemplates'] as Array<any>).map(KpiTypeLensTemplateDtoFromJSON)),
        'pivotKpiTypeLensTemplates': !exists(json, 'PivotKpiTypeLensTemplates') ? undefined : (json['PivotKpiTypeLensTemplates'] === null ? null : (json['PivotKpiTypeLensTemplates'] as Array<any>).map(PivotKpiTypeLensTemplateDtoFromJSON)),
        'kpiGroupLensTemplates': !exists(json, 'KpiGroupLensTemplates') ? undefined : (json['KpiGroupLensTemplates'] === null ? null : (json['KpiGroupLensTemplates'] as Array<any>).map(KpiGroupLensTemplateDtoFromJSON)),
        'pivotKpiGroupLensTemplates': !exists(json, 'PivotKpiGroupLensTemplates') ? undefined : (json['PivotKpiGroupLensTemplates'] === null ? null : (json['PivotKpiGroupLensTemplates'] as Array<any>).map(PivotKpiGroupLensTemplateDtoFromJSON)),
        'wedgeLensTemplates': !exists(json, 'WedgeLensTemplates') ? undefined : (json['WedgeLensTemplates'] === null ? null : (json['WedgeLensTemplates'] as Array<any>).map(WedgeLensTemplateDtoFromJSON)),
        'parameterByDepthLensTemplates': !exists(json, 'ParameterByDepthLensTemplates') ? undefined : (json['ParameterByDepthLensTemplates'] === null ? null : (json['ParameterByDepthLensTemplates'] as Array<any>).map(ParameterByDepthLensTemplateDtoFromJSON)),
        'wellDrillingSummaryTemplates': !exists(json, 'WellDrillingSummaryTemplates') ? undefined : (json['WellDrillingSummaryTemplates'] === null ? null : (json['WellDrillingSummaryTemplates'] as Array<any>).map(WellDrillingSummaryLensTemplateDtoFromJSON)),
        'parameterHeatmapLensTemplates': !exists(json, 'ParameterHeatmapLensTemplates') ? undefined : (json['ParameterHeatmapLensTemplates'] === null ? null : (json['ParameterHeatmapLensTemplates'] as Array<any>).map(ParameterHeatmapLensTemplateDtoFromJSON)),
        'torqueAndDragLensTemplates': !exists(json, 'TorqueAndDragLensTemplates') ? undefined : (json['TorqueAndDragLensTemplates'] === null ? null : (json['TorqueAndDragLensTemplates'] as Array<any>).map(TorqueAndDragLensTemplateDtoFromJSON)),
        'drillingProductivityLensTemplates': !exists(json, 'DrillingProductivityLensTemplates') ? undefined : (json['DrillingProductivityLensTemplates'] === null ? null : (json['DrillingProductivityLensTemplates'] as Array<any>).map(DrillingProductivityLensTemplateDtoFromJSON)),
        'batteryStateLensTemplates': !exists(json, 'BatteryStateLensTemplates') ? undefined : (json['BatteryStateLensTemplates'] === null ? null : (json['BatteryStateLensTemplates'] as Array<any>).map(BatteryStateLensTemplateDtoFromJSON)),
        'rigPowerLensTemplates': !exists(json, 'RigPowerLensTemplates') ? undefined : (json['RigPowerLensTemplates'] === null ? null : (json['RigPowerLensTemplates'] as Array<any>).map(RigPowerLensTemplateDtoFromJSON)),
        'powerConsumptionByOperationLensTemplates': !exists(json, 'PowerConsumptionByOperationLensTemplates') ? undefined : (json['PowerConsumptionByOperationLensTemplates'] === null ? null : (json['PowerConsumptionByOperationLensTemplates'] as Array<any>).map(PowerConsumptionByOperationLensTemplateDtoFromJSON)),
        'statCardLensTemplates': !exists(json, 'StatCardLensTemplates') ? undefined : (json['StatCardLensTemplates'] === null ? null : (json['StatCardLensTemplates'] as Array<any>).map(StatCardLensTemplateDtoFromJSON)),
        'naturalGasStateLensTemplates': !exists(json, 'NaturalGasStateLensTemplates') ? undefined : (json['NaturalGasStateLensTemplates'] === null ? null : (json['NaturalGasStateLensTemplates'] as Array<any>).map(NaturalGasStateLensTemplateDtoFromJSON)),
        'generatorStatusBarLensTemplates': !exists(json, 'GeneratorStatusBarLensTemplates') ? undefined : (json['GeneratorStatusBarLensTemplates'] === null ? null : (json['GeneratorStatusBarLensTemplates'] as Array<any>).map(GeneratorStatusBarLensTemplateDtoFromJSON)),
        'fuelFlowRateLensTemplates': !exists(json, 'FuelFlowRateLensTemplates') ? undefined : (json['FuelFlowRateLensTemplates'] === null ? null : (json['FuelFlowRateLensTemplates'] as Array<any>).map(FuelFlowRateLensTemplateDtoFromJSON)),
        'generatorStatsLensTemplates': !exists(json, 'GeneratorStatsLensTemplates') ? undefined : (json['GeneratorStatsLensTemplates'] === null ? null : (json['GeneratorStatsLensTemplates'] as Array<any>).map(GeneratorStatsLensTemplateDtoFromJSON)),
        'generatorLoadProfileLensTemplates': !exists(json, 'GeneratorLoadProfileLensTemplates') ? undefined : (json['GeneratorLoadProfileLensTemplates'] === null ? null : (json['GeneratorLoadProfileLensTemplates'] as Array<any>).map(GeneratorLoadProfileLensTemplateDtoFromJSON)),
        'powerLoadDistributionLensTemplates': !exists(json, 'PowerLoadDistributionLensTemplates') ? undefined : (json['PowerLoadDistributionLensTemplates'] === null ? null : (json['PowerLoadDistributionLensTemplates'] as Array<any>).map(PowerLoadDistributionLensTemplateDtoFromJSON)),
        'ghgEmissionsLensTemplates': !exists(json, 'GhgEmissionsLensTemplates') ? undefined : (json['GhgEmissionsLensTemplates'] === null ? null : (json['GhgEmissionsLensTemplates'] as Array<any>).map(GhgEmissionsLensTemplateDtoFromJSON)),
        'fuelTankVolumeLensTemplates': !exists(json, 'FuelTankVolumeLensTemplates') ? undefined : (json['FuelTankVolumeLensTemplates'] === null ? null : (json['FuelTankVolumeLensTemplates'] as Array<any>).map(FuelTankVolumeLensTemplateDtoFromJSON)),
        'powerLoadEfficiencyLensTemplates': !exists(json, 'PowerLoadEfficiencyLensTemplates') ? undefined : (json['PowerLoadEfficiencyLensTemplates'] === null ? null : (json['PowerLoadEfficiencyLensTemplates'] as Array<any>).map(PowerLoadEfficiencyLensTemplateDtoFromJSON)),
        'fuelConsumptionByGeneratorLensTemplates': !exists(json, 'FuelConsumptionByGeneratorLensTemplates') ? undefined : (json['FuelConsumptionByGeneratorLensTemplates'] === null ? null : (json['FuelConsumptionByGeneratorLensTemplates'] as Array<any>).map(FuelConsumptionByGeneratorLensTemplateDtoFromJSON)),
        'dieselNatGasRatioStatsLensTemplates': !exists(json, 'DieselNatGasRatioStatsLensTemplates') ? undefined : (json['DieselNatGasRatioStatsLensTemplates'] === null ? null : (json['DieselNatGasRatioStatsLensTemplates'] as Array<any>).map(DieselNatGasRatioStatsLensTemplateDtoFromJSON)),
        'rigFleetPerformanceCardLensTemplates': !exists(json, 'RigFleetPerformanceCardLensTemplates') ? undefined : (json['RigFleetPerformanceCardLensTemplates'] === null ? null : (json['RigFleetPerformanceCardLensTemplates'] as Array<any>).map(RigFleetPerformanceCardLensTemplateDtoFromJSON)),
        'rigScorecardLensTemplates': !exists(json, 'RigScorecardLensTemplates') ? undefined : (json['RigScorecardLensTemplates'] === null ? null : (json['RigScorecardLensTemplates'] as Array<any>).map(RigScorecardLensTemplateDtoFromJSON)),
        'tagBottomFingerprintLensTemplates': !exists(json, 'TagBottomFingerprintLensTemplates') ? undefined : (json['TagBottomFingerprintLensTemplates'] === null ? null : (json['TagBottomFingerprintLensTemplates'] as Array<any>).map(TagBottomFingerprintLensTemplateDtoFromJSON)),
        'stickSlipLensTemplates': !exists(json, 'StickSlipLensTemplates') ? undefined : (json['StickSlipLensTemplates'] === null ? null : (json['StickSlipLensTemplates'] as Array<any>).map(StickSlipLensTemplateDtoFromJSON)),
    };
}

export function LensTemplateSetDtoToJSON(value?: LensTemplateSetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'KpiTypeLensTemplates': value.kpiTypeLensTemplates === undefined ? undefined : (value.kpiTypeLensTemplates === null ? null : (value.kpiTypeLensTemplates as Array<any>).map(KpiTypeLensTemplateDtoToJSON)),
        'PivotKpiTypeLensTemplates': value.pivotKpiTypeLensTemplates === undefined ? undefined : (value.pivotKpiTypeLensTemplates === null ? null : (value.pivotKpiTypeLensTemplates as Array<any>).map(PivotKpiTypeLensTemplateDtoToJSON)),
        'KpiGroupLensTemplates': value.kpiGroupLensTemplates === undefined ? undefined : (value.kpiGroupLensTemplates === null ? null : (value.kpiGroupLensTemplates as Array<any>).map(KpiGroupLensTemplateDtoToJSON)),
        'PivotKpiGroupLensTemplates': value.pivotKpiGroupLensTemplates === undefined ? undefined : (value.pivotKpiGroupLensTemplates === null ? null : (value.pivotKpiGroupLensTemplates as Array<any>).map(PivotKpiGroupLensTemplateDtoToJSON)),
        'WedgeLensTemplates': value.wedgeLensTemplates === undefined ? undefined : (value.wedgeLensTemplates === null ? null : (value.wedgeLensTemplates as Array<any>).map(WedgeLensTemplateDtoToJSON)),
        'ParameterByDepthLensTemplates': value.parameterByDepthLensTemplates === undefined ? undefined : (value.parameterByDepthLensTemplates === null ? null : (value.parameterByDepthLensTemplates as Array<any>).map(ParameterByDepthLensTemplateDtoToJSON)),
        'WellDrillingSummaryTemplates': value.wellDrillingSummaryTemplates === undefined ? undefined : (value.wellDrillingSummaryTemplates === null ? null : (value.wellDrillingSummaryTemplates as Array<any>).map(WellDrillingSummaryLensTemplateDtoToJSON)),
        'ParameterHeatmapLensTemplates': value.parameterHeatmapLensTemplates === undefined ? undefined : (value.parameterHeatmapLensTemplates === null ? null : (value.parameterHeatmapLensTemplates as Array<any>).map(ParameterHeatmapLensTemplateDtoToJSON)),
        'TorqueAndDragLensTemplates': value.torqueAndDragLensTemplates === undefined ? undefined : (value.torqueAndDragLensTemplates === null ? null : (value.torqueAndDragLensTemplates as Array<any>).map(TorqueAndDragLensTemplateDtoToJSON)),
        'DrillingProductivityLensTemplates': value.drillingProductivityLensTemplates === undefined ? undefined : (value.drillingProductivityLensTemplates === null ? null : (value.drillingProductivityLensTemplates as Array<any>).map(DrillingProductivityLensTemplateDtoToJSON)),
        'BatteryStateLensTemplates': value.batteryStateLensTemplates === undefined ? undefined : (value.batteryStateLensTemplates === null ? null : (value.batteryStateLensTemplates as Array<any>).map(BatteryStateLensTemplateDtoToJSON)),
        'RigPowerLensTemplates': value.rigPowerLensTemplates === undefined ? undefined : (value.rigPowerLensTemplates === null ? null : (value.rigPowerLensTemplates as Array<any>).map(RigPowerLensTemplateDtoToJSON)),
        'PowerConsumptionByOperationLensTemplates': value.powerConsumptionByOperationLensTemplates === undefined ? undefined : (value.powerConsumptionByOperationLensTemplates === null ? null : (value.powerConsumptionByOperationLensTemplates as Array<any>).map(PowerConsumptionByOperationLensTemplateDtoToJSON)),
        'StatCardLensTemplates': value.statCardLensTemplates === undefined ? undefined : (value.statCardLensTemplates === null ? null : (value.statCardLensTemplates as Array<any>).map(StatCardLensTemplateDtoToJSON)),
        'NaturalGasStateLensTemplates': value.naturalGasStateLensTemplates === undefined ? undefined : (value.naturalGasStateLensTemplates === null ? null : (value.naturalGasStateLensTemplates as Array<any>).map(NaturalGasStateLensTemplateDtoToJSON)),
        'GeneratorStatusBarLensTemplates': value.generatorStatusBarLensTemplates === undefined ? undefined : (value.generatorStatusBarLensTemplates === null ? null : (value.generatorStatusBarLensTemplates as Array<any>).map(GeneratorStatusBarLensTemplateDtoToJSON)),
        'FuelFlowRateLensTemplates': value.fuelFlowRateLensTemplates === undefined ? undefined : (value.fuelFlowRateLensTemplates === null ? null : (value.fuelFlowRateLensTemplates as Array<any>).map(FuelFlowRateLensTemplateDtoToJSON)),
        'GeneratorStatsLensTemplates': value.generatorStatsLensTemplates === undefined ? undefined : (value.generatorStatsLensTemplates === null ? null : (value.generatorStatsLensTemplates as Array<any>).map(GeneratorStatsLensTemplateDtoToJSON)),
        'GeneratorLoadProfileLensTemplates': value.generatorLoadProfileLensTemplates === undefined ? undefined : (value.generatorLoadProfileLensTemplates === null ? null : (value.generatorLoadProfileLensTemplates as Array<any>).map(GeneratorLoadProfileLensTemplateDtoToJSON)),
        'PowerLoadDistributionLensTemplates': value.powerLoadDistributionLensTemplates === undefined ? undefined : (value.powerLoadDistributionLensTemplates === null ? null : (value.powerLoadDistributionLensTemplates as Array<any>).map(PowerLoadDistributionLensTemplateDtoToJSON)),
        'GhgEmissionsLensTemplates': value.ghgEmissionsLensTemplates === undefined ? undefined : (value.ghgEmissionsLensTemplates === null ? null : (value.ghgEmissionsLensTemplates as Array<any>).map(GhgEmissionsLensTemplateDtoToJSON)),
        'FuelTankVolumeLensTemplates': value.fuelTankVolumeLensTemplates === undefined ? undefined : (value.fuelTankVolumeLensTemplates === null ? null : (value.fuelTankVolumeLensTemplates as Array<any>).map(FuelTankVolumeLensTemplateDtoToJSON)),
        'PowerLoadEfficiencyLensTemplates': value.powerLoadEfficiencyLensTemplates === undefined ? undefined : (value.powerLoadEfficiencyLensTemplates === null ? null : (value.powerLoadEfficiencyLensTemplates as Array<any>).map(PowerLoadEfficiencyLensTemplateDtoToJSON)),
        'FuelConsumptionByGeneratorLensTemplates': value.fuelConsumptionByGeneratorLensTemplates === undefined ? undefined : (value.fuelConsumptionByGeneratorLensTemplates === null ? null : (value.fuelConsumptionByGeneratorLensTemplates as Array<any>).map(FuelConsumptionByGeneratorLensTemplateDtoToJSON)),
        'DieselNatGasRatioStatsLensTemplates': value.dieselNatGasRatioStatsLensTemplates === undefined ? undefined : (value.dieselNatGasRatioStatsLensTemplates === null ? null : (value.dieselNatGasRatioStatsLensTemplates as Array<any>).map(DieselNatGasRatioStatsLensTemplateDtoToJSON)),
        'RigFleetPerformanceCardLensTemplates': value.rigFleetPerformanceCardLensTemplates === undefined ? undefined : (value.rigFleetPerformanceCardLensTemplates === null ? null : (value.rigFleetPerformanceCardLensTemplates as Array<any>).map(RigFleetPerformanceCardLensTemplateDtoToJSON)),
        'RigScorecardLensTemplates': value.rigScorecardLensTemplates === undefined ? undefined : (value.rigScorecardLensTemplates === null ? null : (value.rigScorecardLensTemplates as Array<any>).map(RigScorecardLensTemplateDtoToJSON)),
        'TagBottomFingerprintLensTemplates': value.tagBottomFingerprintLensTemplates === undefined ? undefined : (value.tagBottomFingerprintLensTemplates === null ? null : (value.tagBottomFingerprintLensTemplates as Array<any>).map(TagBottomFingerprintLensTemplateDtoToJSON)),
        'StickSlipLensTemplates': value.stickSlipLensTemplates === undefined ? undefined : (value.stickSlipLensTemplates === null ? null : (value.stickSlipLensTemplates as Array<any>).map(StickSlipLensTemplateDtoToJSON)),
    };
}

