/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ByDepthUserLensQueryDto,
    ByDepthUserLensQueryDtoFromJSON,
    ByDepthUserLensQueryDtoToJSON,
    FiltersDto,
    FiltersDtoFromJSON,
    FiltersDtoToJSON,
    StickSlipByDepthSetDto,
    StickSlipByDepthSetDtoFromJSON,
    StickSlipByDepthSetDtoToJSON,
    StickSlipByDepthUserLensDto,
    StickSlipByDepthUserLensDtoFromJSON,
    StickSlipByDepthUserLensDtoToJSON,
} from '../models';

export interface ApiStickSlipByDepthUserLensesAvailableFiltersPutRequest {
    byDepthUserLensQueryDto?: ByDepthUserLensQueryDto;
}

export interface ApiStickSlipByDepthUserLensesIdFactsPutRequest {
    id: number;
    byDepthUserLensQueryDto?: ByDepthUserLensQueryDto;
}

export interface ApiStickSlipByDepthUserLensesIdPutRequest {
    id: number;
    stickSlipByDepthUserLensDto?: StickSlipByDepthUserLensDto;
}

export interface ApiStickSlipByDepthUserLensesPostRequest {
    stickSlipByDepthUserLensDto?: StickSlipByDepthUserLensDto;
}

/**
 * 
 */
export class StickSlipByDepthUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiStickSlipByDepthUserLensesAvailableFiltersPutRaw(requestParameters: ApiStickSlipByDepthUserLensesAvailableFiltersPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FiltersDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/StickSlipByDepthUserLenses/AvailableFilters`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ByDepthUserLensQueryDtoToJSON(requestParameters.byDepthUserLensQueryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FiltersDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiStickSlipByDepthUserLensesAvailableFiltersPut(requestParameters: ApiStickSlipByDepthUserLensesAvailableFiltersPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FiltersDto> {
        const response = await this.apiStickSlipByDepthUserLensesAvailableFiltersPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStickSlipByDepthUserLensesIdFactsPutRaw(requestParameters: ApiStickSlipByDepthUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StickSlipByDepthSetDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiStickSlipByDepthUserLensesIdFactsPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/StickSlipByDepthUserLenses/{id}/Facts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ByDepthUserLensQueryDtoToJSON(requestParameters.byDepthUserLensQueryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StickSlipByDepthSetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiStickSlipByDepthUserLensesIdFactsPut(requestParameters: ApiStickSlipByDepthUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StickSlipByDepthSetDto> {
        const response = await this.apiStickSlipByDepthUserLensesIdFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStickSlipByDepthUserLensesIdPutRaw(requestParameters: ApiStickSlipByDepthUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StickSlipByDepthUserLensDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiStickSlipByDepthUserLensesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/StickSlipByDepthUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StickSlipByDepthUserLensDtoToJSON(requestParameters.stickSlipByDepthUserLensDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StickSlipByDepthUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiStickSlipByDepthUserLensesIdPut(requestParameters: ApiStickSlipByDepthUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StickSlipByDepthUserLensDto> {
        const response = await this.apiStickSlipByDepthUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStickSlipByDepthUserLensesPostRaw(requestParameters: ApiStickSlipByDepthUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StickSlipByDepthUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/StickSlipByDepthUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StickSlipByDepthUserLensDtoToJSON(requestParameters.stickSlipByDepthUserLensDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StickSlipByDepthUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiStickSlipByDepthUserLensesPost(requestParameters: ApiStickSlipByDepthUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StickSlipByDepthUserLensDto> {
        const response = await this.apiStickSlipByDepthUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
