/* eslint-disable react/no-multi-comp */
import type { WellDrillingSummaryDto } from "apis/oag";
import { WellStatusType } from "apis/oag";
import { Button } from "atoms/Form";
import { Title } from "atoms/Typography";
import { Spinner } from "components/Loader/Spinner";
import { PDComponent } from "components/PDComponents";
import { useDashboardType } from "hooks/useDashboardType";
import { useOperators } from "hooks/useOperators";
import { useRigs } from "hooks/useRigs";
import { useWellSummaries } from "hooks/useWellSummaries";
import { useWellTvDPoints } from "hooks/useWellTvDPoints";
import { useWellsMapViewContext } from "pages/AllWells/components/MapView/useWellsMapView";
import type { FC } from "react";
import { memo, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch } from "reducers/store";
import { useAppSelector } from "reducers/store";
import { Track } from "services/Mixpanel";
import styled from "styled-components";
import colors from "utils/colors";
import { responsiveBreakpoints } from "utils/common";
import { Col, Row, Space, Tooltip } from "utils/componentLibrary";
import { PdCol } from "utils/componentLibrary/Col";
import { DashboardTypeSlugs } from "utils/enums";
import { dimensionMap, SECONDS_IN_DAY, valueToString } from "utils/format";
import { formatTime } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

import MiniTVD from "./MiniTVD";

const WellTitle = styled(Title).attrs({
  level: 3,
})`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const IconHolder = styled.div`
  height: 44px;
  width: 44px;
  font-size: 20px;
  background: white;
  border-radius: 100%;
  display: flex;
  box-shadow: 0px 1px 2px 0px ${colors.buttons_clicked};
`;

const StyledSpace = styled(Space)`
  max-width: 100%;
  h3 {
    max-width: 100%;
  }
`;

const StyledButton = styled(Button)`
  height: 44px !important;
  width: 44px !important;
  background: white;

  .pd-icon {
    pointer-events: none;
  }
`;

const StyledSpecialRow = styled(Row)`
  * {
    transition: all 0.3s ease;
  }
  .ant-typography-ellipsis-single-line {
    vertical-align: initial;
  }

  &.exluded {
    .dim-on-exclusion {
      opacity: 0.5;
    }
    .hide-on-exclusion {
      opacity: 0;
    }
  }

  @media only screen and (max-width: ${responsiveBreakpoints.xl}px) {
    ${PdCol}.hide-on-exclusion {
      padding: 0 4px !important;
    }
  }

  @media only screen and (min-width: ${responsiveBreakpoints.xl + 1}px) {
    ${PdCol}.hide-on-exclusion {
      padding: 0 8px !important;
    }
  }

  padding: 9.5px 16px;
  background: ${({ theme: { isDark } }) => (isDark ? colors.revolver : colors.neutral_white)};
`;

const StyledWellCol = styled(Col)`
  --data-row-width: 710px;
  @media only screen and (min-width: ${responsiveBreakpoints.xxl}px) {
    --data-row-width: 770px;
  }
  @media only screen and (max-width: ${responsiveBreakpoints.xl}px) {
    --data-row-width: 665px;
  }
  width: calc(100% - var(--data-row-width));
`;
const StyledWellTitle = styled(Col)`
  width: calc(100% - 60px);
`;

const KpiRow = styled(Row)`
  @media only screen and (min-width: ${responsiveBreakpoints.xxl}px) {
    margin-left: 8px;
  }
`;

const MiniTvDLoader = styled.div`
  height: 25px;
  width: 70px;
  display: inline-flex;
  place-content: center;
`;

const MiniTvDLoading: FC = memo(function MiniTvDLoading() {
  return (
    <MiniTvDLoader>
      <Spinner borderColor={colors.gray} borderSize={2} />
    </MiniTvDLoader>
  );
});
interface ISingleKPI {
  data: string | number;
  width?: number;
  isCentered?: boolean;
}

const SingleKPI: FC<ISingleKPI> = ({ data, width, isCentered }) => {
  const { atomThemeVariant } = useCustomTheme();

  return (
    <KpiRow style={{ width: width ? `${width}px` : "78px" }}>
      <Col span={24}>
        <Title
          level={3}
          variant={atomThemeVariant}
          style={{
            textAlign: isCentered ? "center" : "start",
          }}
        >
          {data}
        </Title>
      </Col>
    </KpiRow>
  );
};
const CenteredIcon = styled.div<{ iconType?: "play" | "paused" | "completed" | "pending" }>`
  .pd-icon {
    display: grid;
    place-items: center;
    font-size: 30px;
    color: ${(props) => {
      switch (props.iconType) {
        case "play":
          return "#0d9b83";
        case "completed":
        case "paused":
          return "#828c91";
        case "pending":
          return "#d5aba4";
      }
    }};
    opacity: ${(props) => {
      switch (props.iconType) {
        case "play":
          return 1;
        case "completed":
        case "paused":
        case "pending":
          return 0.7;
      }
    }};
  }
`;

const SingleWell: FC<{ wellDetails: WellDrillingSummaryDto }> = ({ wellDetails }) => {
  const wellSummaries = useWellSummaries();
  const { isEvergreen } = useDashboardType();
  const operators = useOperators();
  const rigs = useRigs();

  const dispatch = useAppDispatch();
  const exclusionsState = useAppSelector((state) => state.allWells.exclusions);

  const unitState = useAppSelector((state) => state.global.unit);

  const excluded = useMemo(() => exclusionsState.includes(wellDetails.id), [exclusionsState, wellDetails.id]);

  const uniqueRigIdsLabel = useMemo(() => {
    const uniqueRigIds = [...new Set((wellDetails.jobs || []).map((e) => e.rigId))];
    return uniqueRigIds
      .map((e) => rigs?.data?.byId[e]?.shortName || "")
      .sort((a, b) => a.localeCompare(b))
      .join(", ");
  }, [wellDetails.jobs, rigs?.data]);

  const [isMouseOver, setIsMouseOver] = useState(false);
  const { setCenter } = useWellsMapViewContext();

  const wellPoints = useWellTvDPoints({ wellId: wellDetails.id, points: 50 }, !!wellDetails?.lastTvdFactUpdatedAt);

  const isLoading = useMemo(
    () => wellSummaries.isLoading || operators.isLoading || rigs.isLoading,
    [operators.isLoading, rigs.isLoading, wellSummaries.isLoading],
  );

  const toggleExclusion = () => {
    if (isLoading) return;
    const action = !excluded;
    Track.interact("All wells - Toggle Well KPI", {
      Action: excluded ? "Add" : "Remove",
      "Well Name": wellDetails.name,
      Operator: operators.data.byId[wellDetails.operatorId]?.name ?? "",
      "Well Rig": uniqueRigIdsLabel,
    });
    dispatch({
      type: action ? "ALL_WELLS_EXCLUSION_ADD" : "ALL_WELLS_EXCLUSION_REMOVE",
      payload: {
        well: wellDetails.id,
      },
    });
  };

  const { atomThemeVariant } = useCustomTheme();

  const whichIcon = useMemo(() => {
    if (isLoading) {
      return (
        <span>
          <Spinner borderColor={colors.gray} borderSize={2} />
        </span>
      );
    }
    if (!isMouseOver) {
      switch (wellDetails?.status) {
        case WellStatusType.Active:
          return (
            <CenteredIcon iconType="play">
              <PDComponent.SvgIcon name="playOutline" />
            </CenteredIcon>
          );
        case WellStatusType.Completed:
          return (
            <CenteredIcon iconType="completed">
              <PDComponent.SvgIcon name="checkmarkOutline" />
            </CenteredIcon>
          );
        case WellStatusType.Paused:
          return (
            <CenteredIcon iconType="paused">
              <PDComponent.SvgIcon name="pauseOutline" />
            </CenteredIcon>
          );
        case WellStatusType.Pending:
          return (
            <CenteredIcon iconType="pending">
              <PDComponent.SvgIcon name="time" />
            </CenteredIcon>
          );
        default:
          return null;
      }
    }
    if (excluded) {
      return (
        <CenteredIcon>
          <PDComponent.SvgIcon name="viewOff" />
        </CenteredIcon>
      );
    }
    return (
      <CenteredIcon>
        <PDComponent.SvgIcon name="view" />
      </CenteredIcon>
    );
  }, [excluded, isLoading, isMouseOver, wellDetails?.status]);

  const handleOnCenterMap = useCallback(() => {
    if (wellDetails.latitude && wellDetails.longitude) {
      setCenter([wellDetails.longitude, wellDetails.latitude]);
    }
  }, [setCenter, wellDetails.latitude, wellDetails.longitude]);

  return (
    <StyledSpecialRow justify="space-between" align="middle" gutter={0} className={excluded ? "exluded" : undefined}>
      <StyledWellCol>
        <Row gutter={14} align="middle">
          <Col flex="0 auto">
            <IconHolder>
              <StyledButton
                type="text"
                size="large"
                shape="circle"
                icon={whichIcon}
                onClick={toggleExclusion}
                onMouseOver={() => setIsMouseOver(true)}
                onMouseOut={() => setIsMouseOver(false)}
                className="dim-on-exclusion"
              />
            </IconHolder>
          </Col>
          {/* Todo: remove class-name and prefer styled components */}
          <StyledWellTitle className="dim-on-exclusion">
            <Link to={`/${isEvergreen ? DashboardTypeSlugs.Evergreen : DashboardTypeSlugs.Well}/${wellDetails?.id}`}>
              <StyledSpace direction="vertical" size={0}>
                {wellDetails ? (
                  <>
                    <WellTitle variant={atomThemeVariant}>{wellDetails.name}</WellTitle>
                    {!excluded && (
                      <Title level={3} variant="faded" ellipsis>
                        {operators.data?.byId[wellDetails.operatorId]?.name ?? ""}
                      </Title>
                    )}
                  </>
                ) : null}
              </StyledSpace>
            </Link>
          </StyledWellTitle>
        </Row>
      </StyledWellCol>
      <Col flex="0 auto">
        <Row
          justify="end"
          align="middle"
          style={{
            marginLeft: "-8px",
            marginRight: "-8px",
          }}
        >
          <Col flex="0 auto" className="hide-on-exclusion">
            <SingleKPI width={84} data={uniqueRigIdsLabel} />
          </Col>
          <Col flex="0 auto" className="hide-on-exclusion">
            <SingleKPI
              width={95}
              isCentered
              data={
                wellDetails?.status !== WellStatusType.Pending
                  ? formatTime(wellDetails?.spudDateTime, { localeFormat: true, localeFormatStr: "L" })
                  : "- -"
              }
            />
          </Col>
          <Col flex="0 auto" className="hide-on-exclusion">
            <SingleKPI
              width={95}
              isCentered
              data={
                wellDetails?.status === WellStatusType.Completed
                  ? formatTime(wellDetails?.lastWellFactUpdateAt, { localeFormat: true, localeFormatStr: "L" })
                  : "- -"
              }
            />
          </Col>
          <Col flex="0 auto" className="hide-on-exclusion">
            <SingleKPI width={56} data={valueToString((wellDetails?.cumulativeDuration ?? 0) / SECONDS_IN_DAY)} />
          </Col>
          <Col flex="0 auto" className="hide-on-exclusion">
            <SingleKPI
              data={dimensionMap.Metres[unitState](wellDetails?.lastHoleDepth || 0, { unit: "", fractionDigits: 0 })}
            />
          </Col>
          <Col flex="0 auto" className="hide-on-exclusion">
            <SingleKPI
              data={dimensionMap.Metres[unitState](wellDetails?.lastBitDepth || 0, { unit: "", fractionDigits: 0 })}
            />
          </Col>
          <Col
            flex="0 auto"
            className="dim-on-exclusion"
            style={{
              paddingLeft: "8px",
              paddingRight: "8px",
            }}
          >
            {wellPoints.isLoading ? (
              <MiniTvDLoading />
            ) : (
              <MiniTVD
                data={wellPoints.data ?? []}
                excluded={excluded}
                isActive={wellDetails.status === WellStatusType.Active}
              />
            )}
          </Col>

          <Col flex="0 auto" className="hide-on-exclusion">
            <Tooltip title="Center on Map" placement="right">
              <Button
                trackingName="All wells - Center on Map"
                trackingProps={{ "Well Id": wellDetails.id }}
                style={{ width: "36px" }}
                icon={<PDComponent.SvgIcon name="map" height={18} />}
                onClick={handleOnCenterMap}
                disabled={!wellDetails.latitude || !wellDetails.longitude}
              />
            </Tooltip>
          </Col>
        </Row>
      </Col>
    </StyledSpecialRow>
  );
};

export default memo(SingleWell);
