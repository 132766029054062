/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RigScorecardLensType,
    RigScorecardLensTypeFromJSON,
    RigScorecardLensTypeFromJSONTyped,
    RigScorecardLensTypeToJSON,
} from './RigScorecardLensType';
import {
    StandKpiType,
    StandKpiTypeFromJSON,
    StandKpiTypeFromJSONTyped,
    StandKpiTypeToJSON,
} from './StandKpiType';

/**
 * 
 * @export
 * @interface RigScorecardUserLensDto
 */
export interface RigScorecardUserLensDto {
    /**
     * 
     * @type {number}
     * @memberof RigScorecardUserLensDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof RigScorecardUserLensDto
     */
    lensTabId: number;
    /**
     * 
     * @type {number}
     * @memberof RigScorecardUserLensDto
     */
    lensTemplateId: number;
    /**
     * 
     * @type {boolean}
     * @memberof RigScorecardUserLensDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RigScorecardUserLensDto
     */
    label: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RigScorecardUserLensDto
     */
    isSystem: boolean;
    /**
     * 
     * @type {StandKpiType}
     * @memberof RigScorecardUserLensDto
     */
    cardKpiType: StandKpiType;
    /**
     * 
     * @type {boolean}
     * @memberof RigScorecardUserLensDto
     */
    acceptsExceptions: boolean;
    /**
     * 
     * @type {RigScorecardLensType}
     * @memberof RigScorecardUserLensDto
     */
    scorecardType: RigScorecardLensType;
}

export function RigScorecardUserLensDtoFromJSON(json: any): RigScorecardUserLensDto {
    return RigScorecardUserLensDtoFromJSONTyped(json, false);
}

export function RigScorecardUserLensDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigScorecardUserLensDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'lensTabId': json['LensTabId'],
        'lensTemplateId': json['LensTemplateId'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'label': json['Label'],
        'isSystem': json['IsSystem'],
        'cardKpiType': StandKpiTypeFromJSON(json['CardKpiType']),
        'acceptsExceptions': json['AcceptsExceptions'],
        'scorecardType': RigScorecardLensTypeFromJSON(json['ScorecardType']),
    };
}

export function RigScorecardUserLensDtoToJSON(value?: RigScorecardUserLensDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'LensTabId': value.lensTabId,
        'LensTemplateId': value.lensTemplateId,
        'IsAvailableOnReports': value.isAvailableOnReports,
        'Label': value.label,
        'IsSystem': value.isSystem,
        'CardKpiType': StandKpiTypeToJSON(value.cardKpiType),
        'AcceptsExceptions': value.acceptsExceptions,
        'ScorecardType': RigScorecardLensTypeToJSON(value.scorecardType),
    };
}

