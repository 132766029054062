/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    NotificationType,
    NotificationTypeFromJSON,
    NotificationTypeFromJSONTyped,
    NotificationTypeToJSON,
} from './NotificationType';
import {
    ScorecardExceptionStatusType,
    ScorecardExceptionStatusTypeFromJSON,
    ScorecardExceptionStatusTypeFromJSONTyped,
    ScorecardExceptionStatusTypeToJSON,
} from './ScorecardExceptionStatusType';

/**
 * 
 * @export
 * @interface ExceptionRequestNotificationDto
 */
export interface ExceptionRequestNotificationDto {
    /**
     * 
     * @type {number}
     * @memberof ExceptionRequestNotificationDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ExceptionRequestNotificationDto
     */
    title?: string | null;
    /**
     * 
     * @type {NotificationType}
     * @memberof ExceptionRequestNotificationDto
     */
    type: NotificationType;
    /**
     * 
     * @type {boolean}
     * @memberof ExceptionRequestNotificationDto
     */
    isRead: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExceptionRequestNotificationDto
     */
    isArchived: boolean;
    /**
     * 
     * @type {DateDto}
     * @memberof ExceptionRequestNotificationDto
     */
    createdAt: DateDto;
    /**
     * 
     * @type {number}
     * @memberof ExceptionRequestNotificationDto
     */
    kpiCommentId: number;
    /**
     * 
     * @type {number}
     * @memberof ExceptionRequestNotificationDto
     */
    wellId: number;
    /**
     * 
     * @type {number}
     * @memberof ExceptionRequestNotificationDto
     */
    rigId: number;
    /**
     * 
     * @type {string}
     * @memberof ExceptionRequestNotificationDto
     */
    description: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ExceptionRequestNotificationDto
     */
    jobExternalIds: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof ExceptionRequestNotificationDto
     */
    isUpdated: boolean;
    /**
     * 
     * @type {ScorecardExceptionStatusType}
     * @memberof ExceptionRequestNotificationDto
     */
    exceptionStatus: ScorecardExceptionStatusType;
}

export function ExceptionRequestNotificationDtoFromJSON(json: any): ExceptionRequestNotificationDto {
    return ExceptionRequestNotificationDtoFromJSONTyped(json, false);
}

export function ExceptionRequestNotificationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExceptionRequestNotificationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'title': !exists(json, 'Title') ? undefined : json['Title'],
        'type': NotificationTypeFromJSON(json['Type']),
        'isRead': json['IsRead'],
        'isArchived': json['IsArchived'],
        'createdAt': DateDtoFromJSON(json['CreatedAt']),
        'kpiCommentId': json['KpiCommentId'],
        'wellId': json['WellId'],
        'rigId': json['RigId'],
        'description': json['Description'],
        'jobExternalIds': json['JobExternalIds'],
        'isUpdated': json['IsUpdated'],
        'exceptionStatus': ScorecardExceptionStatusTypeFromJSON(json['ExceptionStatus']),
    };
}

export function ExceptionRequestNotificationDtoToJSON(value?: ExceptionRequestNotificationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'Title': value.title,
        'Type': NotificationTypeToJSON(value.type),
        'IsRead': value.isRead,
        'IsArchived': value.isArchived,
        'CreatedAt': DateDtoToJSON(value.createdAt),
        'KpiCommentId': value.kpiCommentId,
        'WellId': value.wellId,
        'RigId': value.rigId,
        'Description': value.description,
        'JobExternalIds': value.jobExternalIds,
        'IsUpdated': value.isUpdated,
        'ExceptionStatus': ScorecardExceptionStatusTypeToJSON(value.exceptionStatus),
    };
}

