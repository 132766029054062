/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const WellStatusType = {
    Pending: 'Pending',
    Active: 'Active',
    Paused: 'Paused',
    Completed: 'Completed'
} as const;
export type WellStatusType = typeof WellStatusType[keyof typeof WellStatusType];


export function WellStatusTypeFromJSON(json: any): WellStatusType {
    return WellStatusTypeFromJSONTyped(json, false);
}

export function WellStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): WellStatusType {
    return json as WellStatusType;
}

export function WellStatusTypeToJSON(value?: WellStatusType | null): any {
    return value as any;
}

