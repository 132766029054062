/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NotificationType,
    NotificationTypeFromJSON,
    NotificationTypeFromJSONTyped,
    NotificationTypeToJSON,
} from './NotificationType';

/**
 * 
 * @export
 * @interface NotificationSubscriptionDto
 */
export interface NotificationSubscriptionDto {
    /**
     * 
     * @type {number}
     * @memberof NotificationSubscriptionDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationSubscriptionDto
     */
    rigId: number;
    /**
     * 
     * @type {Array<NotificationType>}
     * @memberof NotificationSubscriptionDto
     */
    type: Array<NotificationType>;
}

export function NotificationSubscriptionDtoFromJSON(json: any): NotificationSubscriptionDto {
    return NotificationSubscriptionDtoFromJSONTyped(json, false);
}

export function NotificationSubscriptionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationSubscriptionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'rigId': json['RigId'],
        'type': ((json['Type'] as Array<any>).map(NotificationTypeFromJSON)),
    };
}

export function NotificationSubscriptionDtoToJSON(value?: NotificationSubscriptionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'RigId': value.rigId,
        'Type': ((value.type as Array<any>).map(NotificationTypeToJSON)),
    };
}

