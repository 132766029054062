/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WellStatusType,
    WellStatusTypeFromJSON,
    WellStatusTypeFromJSONTyped,
    WellStatusTypeToJSON,
} from './WellStatusType';

/**
 * 
 * @export
 * @interface WellAccessQueryDto
 */
export interface WellAccessQueryDto {
    /**
     * 
     * @type {string}
     * @memberof WellAccessQueryDto
     */
    search?: string | null;
    /**
     * 
     * @type {Array<WellStatusType>}
     * @memberof WellAccessQueryDto
     */
    status?: Array<WellStatusType> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof WellAccessQueryDto
     */
    rigIds?: Array<number> | null;
    /**
     * 
     * @type {Date}
     * @memberof WellAccessQueryDto
     */
    spudDateStart?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof WellAccessQueryDto
     */
    spudDateEnd?: Date | null;
}

export function WellAccessQueryDtoFromJSON(json: any): WellAccessQueryDto {
    return WellAccessQueryDtoFromJSONTyped(json, false);
}

export function WellAccessQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WellAccessQueryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'search': !exists(json, 'Search') ? undefined : json['Search'],
        'status': !exists(json, 'Status') ? undefined : (json['Status'] === null ? null : (json['Status'] as Array<any>).map(WellStatusTypeFromJSON)),
        'rigIds': !exists(json, 'RigIds') ? undefined : json['RigIds'],
        'spudDateStart': !exists(json, 'SpudDateStart') ? undefined : (json['SpudDateStart'] === null ? null : new Date(json['SpudDateStart'])),
        'spudDateEnd': !exists(json, 'SpudDateEnd') ? undefined : (json['SpudDateEnd'] === null ? null : new Date(json['SpudDateEnd'])),
    };
}

export function WellAccessQueryDtoToJSON(value?: WellAccessQueryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Search': value.search,
        'Status': value.status === undefined ? undefined : (value.status === null ? null : (value.status as Array<any>).map(WellStatusTypeToJSON)),
        'RigIds': value.rigIds,
        'SpudDateStart': value.spudDateStart === undefined ? undefined : (value.spudDateStart === null ? null : value.spudDateStart.toISOString()),
        'SpudDateEnd': value.spudDateEnd === undefined ? undefined : (value.spudDateEnd === null ? null : value.spudDateEnd.toISOString()),
    };
}

