/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ZTorqueOnOffPair
 */
export interface ZTorqueOnOffPair {
    /**
     * 
     * @type {number}
     * @memberof ZTorqueOnOffPair
     */
    on: number;
    /**
     * 
     * @type {number}
     * @memberof ZTorqueOnOffPair
     */
    off: number;
    /**
     * 
     * @type {number}
     * @memberof ZTorqueOnOffPair
     */
    readonly onRatio: number;
    /**
     * 
     * @type {number}
     * @memberof ZTorqueOnOffPair
     */
    readonly offRatio: number;
    /**
     * 
     * @type {number}
     * @memberof ZTorqueOnOffPair
     */
    readonly diff: number;
    /**
     * 
     * @type {number}
     * @memberof ZTorqueOnOffPair
     */
    readonly diffPct: number;
}

export function ZTorqueOnOffPairFromJSON(json: any): ZTorqueOnOffPair {
    return ZTorqueOnOffPairFromJSONTyped(json, false);
}

export function ZTorqueOnOffPairFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZTorqueOnOffPair {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'on': json['On'],
        'off': json['Off'],
        'onRatio': json['OnRatio'],
        'offRatio': json['OffRatio'],
        'diff': json['Diff'],
        'diffPct': json['DiffPct'],
    };
}

export function ZTorqueOnOffPairToJSON(value?: ZTorqueOnOffPair | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'On': value.on,
        'Off': value.off,
    };
}

