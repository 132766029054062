/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClaimWellInfo,
    ClaimWellInfoFromJSON,
    ClaimWellInfoFromJSONTyped,
    ClaimWellInfoToJSON,
} from './ClaimWellInfo';

/**
 * 
 * @export
 * @interface AccountRigAccessDto
 */
export interface AccountRigAccessDto {
    /**
     * 
     * @type {number}
     * @memberof AccountRigAccessDto
     */
    rigId: number;
    /**
     * 
     * @type {number}
     * @memberof AccountRigAccessDto
     */
    rigExternalId: number;
    /**
     * 
     * @type {Array<ClaimWellInfo>}
     * @memberof AccountRigAccessDto
     */
    wells?: Array<ClaimWellInfo> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccountRigAccessDto
     */
    futureWellAccess: boolean;
}

export function AccountRigAccessDtoFromJSON(json: any): AccountRigAccessDto {
    return AccountRigAccessDtoFromJSONTyped(json, false);
}

export function AccountRigAccessDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountRigAccessDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rigId': json['RigId'],
        'rigExternalId': json['RigExternalId'],
        'wells': !exists(json, 'Wells') ? undefined : (json['Wells'] === null ? null : (json['Wells'] as Array<any>).map(ClaimWellInfoFromJSON)),
        'futureWellAccess': json['FutureWellAccess'],
    };
}

export function AccountRigAccessDtoToJSON(value?: AccountRigAccessDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'RigId': value.rigId,
        'RigExternalId': value.rigExternalId,
        'Wells': value.wells === undefined ? undefined : (value.wells === null ? null : (value.wells as Array<any>).map(ClaimWellInfoToJSON)),
        'FutureWellAccess': value.futureWellAccess,
    };
}

