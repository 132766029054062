/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DashboardBlockSize,
    DashboardBlockSizeFromJSON,
    DashboardBlockSizeFromJSONTyped,
    DashboardBlockSizeToJSON,
} from './DashboardBlockSize';
import {
    UserLensPositionDto,
    UserLensPositionDtoFromJSON,
    UserLensPositionDtoFromJSONTyped,
    UserLensPositionDtoToJSON,
} from './UserLensPositionDto';

/**
 * 
 * @export
 * @interface UserLensPositionSetDto
 */
export interface UserLensPositionSetDto {
    /**
     * 
     * @type {DashboardBlockSize}
     * @memberof UserLensPositionSetDto
     */
    dashboardWidth: DashboardBlockSize;
    /**
     * 
     * @type {Array<UserLensPositionDto>}
     * @memberof UserLensPositionSetDto
     */
    positions: Array<UserLensPositionDto>;
}

export function UserLensPositionSetDtoFromJSON(json: any): UserLensPositionSetDto {
    return UserLensPositionSetDtoFromJSONTyped(json, false);
}

export function UserLensPositionSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserLensPositionSetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dashboardWidth': DashboardBlockSizeFromJSON(json['DashboardWidth']),
        'positions': ((json['Positions'] as Array<any>).map(UserLensPositionDtoFromJSON)),
    };
}

export function UserLensPositionSetDtoToJSON(value?: UserLensPositionSetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DashboardWidth': DashboardBlockSizeToJSON(value.dashboardWidth),
        'Positions': ((value.positions as Array<any>).map(UserLensPositionDtoToJSON)),
    };
}

