/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TvdHoleDirectionDto
 */
export interface TvdHoleDirectionDto {
    /**
     * 
     * @type {number}
     * @memberof TvdHoleDirectionDto
     */
    directionalIntervalId: number;
    /**
     * 
     * @type {number}
     * @memberof TvdHoleDirectionDto
     */
    startDynamicDuration: number;
    /**
     * 
     * @type {number}
     * @memberof TvdHoleDirectionDto
     */
    endDynamicDuration: number;
}

export function TvdHoleDirectionDtoFromJSON(json: any): TvdHoleDirectionDto {
    return TvdHoleDirectionDtoFromJSONTyped(json, false);
}

export function TvdHoleDirectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TvdHoleDirectionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'directionalIntervalId': json['DirectionalIntervalId'],
        'startDynamicDuration': json['StartDynamicDuration'],
        'endDynamicDuration': json['EndDynamicDuration'],
    };
}

export function TvdHoleDirectionDtoToJSON(value?: TvdHoleDirectionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DirectionalIntervalId': value.directionalIntervalId,
        'StartDynamicDuration': value.startDynamicDuration,
        'EndDynamicDuration': value.endDynamicDuration,
    };
}

