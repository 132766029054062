/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    FiltersDto,
    FiltersDtoFromJSON,
    FiltersDtoFromJSONTyped,
    FiltersDtoToJSON,
} from './FiltersDto';

/**
 * 
 * @export
 * @interface ParameterHeatmapUserLensQueryDto
 */
export interface ParameterHeatmapUserLensQueryDto {
    /**
     * 
     * @type {boolean}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    useMockData: boolean;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    sampleCount: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    comparisonWellIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    selectedRigIds?: Array<number> | null;
    /**
     * 
     * @type {FiltersDto}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    selectedFilters: FiltersDto;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    zoomStartDuration?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    zoomEndDuration?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    zoomStartDepth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    zoomEndDepth?: number | null;
    /**
     * 
     * @type {DateDto}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    from: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    to: DateDto;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    wellId: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    wellAggregationThreshold: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    maxLookAheadDepthFromFocal?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    xBinCount: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    yBinCount: number;
}

export function ParameterHeatmapUserLensQueryDtoFromJSON(json: any): ParameterHeatmapUserLensQueryDto {
    return ParameterHeatmapUserLensQueryDtoFromJSONTyped(json, false);
}

export function ParameterHeatmapUserLensQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterHeatmapUserLensQueryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'useMockData': json['UseMockData'],
        'sampleCount': json['SampleCount'],
        'comparisonWellIds': !exists(json, 'ComparisonWellIds') ? undefined : json['ComparisonWellIds'],
        'selectedRigIds': !exists(json, 'SelectedRigIds') ? undefined : json['SelectedRigIds'],
        'selectedFilters': FiltersDtoFromJSON(json['SelectedFilters']),
        'zoomStartDuration': !exists(json, 'ZoomStartDuration') ? undefined : json['ZoomStartDuration'],
        'zoomEndDuration': !exists(json, 'ZoomEndDuration') ? undefined : json['ZoomEndDuration'],
        'zoomStartDepth': !exists(json, 'ZoomStartDepth') ? undefined : json['ZoomStartDepth'],
        'zoomEndDepth': !exists(json, 'ZoomEndDepth') ? undefined : json['ZoomEndDepth'],
        'from': DateDtoFromJSON(json['From']),
        'to': DateDtoFromJSON(json['To']),
        'wellId': json['WellId'],
        'wellAggregationThreshold': json['WellAggregationThreshold'],
        'maxLookAheadDepthFromFocal': !exists(json, 'MaxLookAheadDepthFromFocal') ? undefined : json['MaxLookAheadDepthFromFocal'],
        'xBinCount': json['XBinCount'],
        'yBinCount': json['YBinCount'],
    };
}

export function ParameterHeatmapUserLensQueryDtoToJSON(value?: ParameterHeatmapUserLensQueryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'UseMockData': value.useMockData,
        'SampleCount': value.sampleCount,
        'ComparisonWellIds': value.comparisonWellIds,
        'SelectedRigIds': value.selectedRigIds,
        'SelectedFilters': FiltersDtoToJSON(value.selectedFilters),
        'ZoomStartDuration': value.zoomStartDuration,
        'ZoomEndDuration': value.zoomEndDuration,
        'ZoomStartDepth': value.zoomStartDepth,
        'ZoomEndDepth': value.zoomEndDepth,
        'From': DateDtoToJSON(value.from),
        'To': DateDtoToJSON(value.to),
        'WellId': value.wellId,
        'WellAggregationThreshold': value.wellAggregationThreshold,
        'MaxLookAheadDepthFromFocal': value.maxLookAheadDepthFromFocal,
        'XBinCount': value.xBinCount,
        'YBinCount': value.yBinCount,
    };
}

