/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StopWatchLapStep
 */
export interface StopWatchLapStep {
    /**
     * 
     * @type {number}
     * @memberof StopWatchLapStep
     */
    seconds: number;
    /**
     * 
     * @type {string}
     * @memberof StopWatchLapStep
     */
    description: string;
}

export function StopWatchLapStepFromJSON(json: any): StopWatchLapStep {
    return StopWatchLapStepFromJSONTyped(json, false);
}

export function StopWatchLapStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): StopWatchLapStep {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'seconds': json['Seconds'],
        'description': json['Description'],
    };
}

export function StopWatchLapStepToJSON(value?: StopWatchLapStep | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Seconds': value.seconds,
        'Description': value.description,
    };
}

