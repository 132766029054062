/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    RigScorecardLensType,
    RigScorecardLensTypeFromJSON,
    RigScorecardLensTypeFromJSONTyped,
    RigScorecardLensTypeToJSON,
} from './RigScorecardLensType';
import {
    ScorecardExceptionStatusType,
    ScorecardExceptionStatusTypeFromJSON,
    ScorecardExceptionStatusTypeFromJSONTyped,
    ScorecardExceptionStatusTypeToJSON,
} from './ScorecardExceptionStatusType';
import {
    StandKpiType,
    StandKpiTypeFromJSON,
    StandKpiTypeFromJSONTyped,
    StandKpiTypeToJSON,
} from './StandKpiType';

/**
 * 
 * @export
 * @interface ScorecardKpiCommentInfoDto
 */
export interface ScorecardKpiCommentInfoDto {
    /**
     * 
     * @type {number}
     * @memberof ScorecardKpiCommentInfoDto
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof ScorecardKpiCommentInfoDto
     */
    deletedAtUtc?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ScorecardKpiCommentInfoDto
     */
    wellId: number;
    /**
     * 
     * @type {string}
     * @memberof ScorecardKpiCommentInfoDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScorecardKpiCommentInfoDto
     */
    description?: string | null;
    /**
     * 
     * @type {StandKpiType}
     * @memberof ScorecardKpiCommentInfoDto
     */
    kpiType: StandKpiType;
    /**
     * 
     * @type {RigScorecardLensType}
     * @memberof ScorecardKpiCommentInfoDto
     */
    scorecardType: RigScorecardLensType;
    /**
     * 
     * @type {number}
     * @memberof ScorecardKpiCommentInfoDto
     */
    exceptionTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScorecardKpiCommentInfoDto
     */
    exceptionApproverId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScorecardKpiCommentInfoDto
     */
    authorId: number;
    /**
     * 
     * @type {string}
     * @memberof ScorecardKpiCommentInfoDto
     */
    authorDisplayName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScorecardKpiCommentInfoDto
     */
    exceptionApproverDisplayName?: string | null;
    /**
     * 
     * @type {DateDto}
     * @memberof ScorecardKpiCommentInfoDto
     */
    createdAt: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof ScorecardKpiCommentInfoDto
     */
    updatedAt: DateDto;
    /**
     * 
     * @type {ScorecardExceptionStatusType}
     * @memberof ScorecardKpiCommentInfoDto
     */
    exceptionStatus: ScorecardExceptionStatusType;
}

export function ScorecardKpiCommentInfoDtoFromJSON(json: any): ScorecardKpiCommentInfoDto {
    return ScorecardKpiCommentInfoDtoFromJSONTyped(json, false);
}

export function ScorecardKpiCommentInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScorecardKpiCommentInfoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'deletedAtUtc': !exists(json, 'DeletedAtUtc') ? undefined : (json['DeletedAtUtc'] === null ? null : new Date(json['DeletedAtUtc'])),
        'wellId': json['WellId'],
        'title': !exists(json, 'Title') ? undefined : json['Title'],
        'description': !exists(json, 'Description') ? undefined : json['Description'],
        'kpiType': StandKpiTypeFromJSON(json['KpiType']),
        'scorecardType': RigScorecardLensTypeFromJSON(json['ScorecardType']),
        'exceptionTime': !exists(json, 'ExceptionTime') ? undefined : json['ExceptionTime'],
        'exceptionApproverId': !exists(json, 'ExceptionApproverId') ? undefined : json['ExceptionApproverId'],
        'authorId': json['AuthorId'],
        'authorDisplayName': !exists(json, 'AuthorDisplayName') ? undefined : json['AuthorDisplayName'],
        'exceptionApproverDisplayName': !exists(json, 'ExceptionApproverDisplayName') ? undefined : json['ExceptionApproverDisplayName'],
        'createdAt': DateDtoFromJSON(json['CreatedAt']),
        'updatedAt': DateDtoFromJSON(json['UpdatedAt']),
        'exceptionStatus': ScorecardExceptionStatusTypeFromJSON(json['ExceptionStatus']),
    };
}

export function ScorecardKpiCommentInfoDtoToJSON(value?: ScorecardKpiCommentInfoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'DeletedAtUtc': value.deletedAtUtc === undefined ? undefined : (value.deletedAtUtc === null ? null : value.deletedAtUtc.toISOString()),
        'WellId': value.wellId,
        'Title': value.title,
        'Description': value.description,
        'KpiType': StandKpiTypeToJSON(value.kpiType),
        'ScorecardType': RigScorecardLensTypeToJSON(value.scorecardType),
        'ExceptionTime': value.exceptionTime,
        'ExceptionApproverId': value.exceptionApproverId,
        'AuthorId': value.authorId,
        'AuthorDisplayName': value.authorDisplayName,
        'ExceptionApproverDisplayName': value.exceptionApproverDisplayName,
        'CreatedAt': DateDtoToJSON(value.createdAt),
        'UpdatedAt': DateDtoToJSON(value.updatedAt),
        'ExceptionStatus': ScorecardExceptionStatusTypeToJSON(value.exceptionStatus),
    };
}

