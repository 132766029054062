/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BroomstickCurveSetDto,
    BroomstickCurveSetDtoFromJSON,
    BroomstickCurveSetDtoFromJSONTyped,
    BroomstickCurveSetDtoToJSON,
} from './BroomstickCurveSetDto';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    ServiceMessageDto,
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import {
    StopWatchDto,
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';
import {
    SurveyDto,
    SurveyDtoFromJSON,
    SurveyDtoFromJSONTyped,
    SurveyDtoToJSON,
} from './SurveyDto';
import {
    TorqueAndDragDataSetDto,
    TorqueAndDragDataSetDtoFromJSON,
    TorqueAndDragDataSetDtoFromJSONTyped,
    TorqueAndDragDataSetDtoToJSON,
} from './TorqueAndDragDataSetDto';

/**
 * 
 * @export
 * @interface TorqueAndDragResultDto
 */
export interface TorqueAndDragResultDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof TorqueAndDragResultDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof TorqueAndDragResultDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof TorqueAndDragResultDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof TorqueAndDragResultDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {ResultDataState}
     * @memberof TorqueAndDragResultDto
     */
    dataState: ResultDataState;
    /**
     * 
     * @type {TorqueAndDragDataSetDto}
     * @memberof TorqueAndDragResultDto
     */
    torqueAndDragMeasures: TorqueAndDragDataSetDto;
    /**
     * 
     * @type {BroomstickCurveSetDto}
     * @memberof TorqueAndDragResultDto
     */
    broomsticks: BroomstickCurveSetDto;
    /**
     * 
     * @type {SurveyDto}
     * @memberof TorqueAndDragResultDto
     */
    survey: SurveyDto;
}

export function TorqueAndDragResultDtoFromJSON(json: any): TorqueAndDragResultDto {
    return TorqueAndDragResultDtoFromJSONTyped(json, false);
}

export function TorqueAndDragResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TorqueAndDragResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': !exists(json, 'Messages') ? undefined : (json['Messages'] === null ? null : (json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
        'torqueAndDragMeasures': TorqueAndDragDataSetDtoFromJSON(json['TorqueAndDragMeasures']),
        'broomsticks': BroomstickCurveSetDtoFromJSON(json['Broomsticks']),
        'survey': SurveyDtoFromJSON(json['Survey']),
    };
}

export function TorqueAndDragResultDtoToJSON(value?: TorqueAndDragResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value.stopWatch),
        'Messages': value.messages === undefined ? undefined : (value.messages === null ? null : (value.messages as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value.userLensId,
        'LastUpdatedAt': DateDtoToJSON(value.lastUpdatedAt),
        'DataState': ResultDataStateToJSON(value.dataState),
        'TorqueAndDragMeasures': TorqueAndDragDataSetDtoToJSON(value.torqueAndDragMeasures),
        'Broomsticks': BroomstickCurveSetDtoToJSON(value.broomsticks),
        'Survey': SurveyDtoToJSON(value.survey),
    };
}

