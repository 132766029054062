/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ScorecardExceptionStatusType = {
    Unknown: 'Unknown',
    Pending: 'Pending',
    Approved: 'Approved',
    Rejected: 'Rejected'
} as const;
export type ScorecardExceptionStatusType = typeof ScorecardExceptionStatusType[keyof typeof ScorecardExceptionStatusType];


export function ScorecardExceptionStatusTypeFromJSON(json: any): ScorecardExceptionStatusType {
    return ScorecardExceptionStatusTypeFromJSONTyped(json, false);
}

export function ScorecardExceptionStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScorecardExceptionStatusType {
    return json as ScorecardExceptionStatusType;
}

export function ScorecardExceptionStatusTypeToJSON(value?: ScorecardExceptionStatusType | null): any {
    return value as any;
}

