import { PDComponent } from "components/PDComponents";
import { useCurrentUser } from "hooks/useCurrentUser";
import { AnonymizedData } from "pages/AllRigs/components/LeaderboardSection/Card/Table";
import { memo, useCallback } from "react";
import { Track } from "services/Mixpanel";

import type { RigViewModel } from "./RigList";
import { useSelectedRigsContext } from "./SelectedRigsContext";
import {
  StyledBulbButton,
  StyledDescriptionNumber,
  StyledLink,
  StyledOperatorName,
  StyledRigDescriptionContainer,
  StyledRigScoreIcon,
  StyledRow,
} from "./styles";

export const RigListRow = memo(({ rig }: { rig: RigViewModel }) => {
  const { id: rigId, shortName: rigName, operatorName } = rig;
  const { isDataAnonymity, setSelectedRigIds, selectedRigIds } = useSelectedRigsContext();
  const isSelected = selectedRigIds.includes(rigId);
  const { data: currentUser } = useCurrentUser();

  const toggleRigIdInSelection = useCallback(
    (rigId: number) => {
      setSelectedRigIds((selectedRigIds) => {
        if (selectedRigIds.includes(rigId)) {
          return selectedRigIds.filter((id) => id !== rigId);
        }
        return [...selectedRigIds, rigId];
      });
    },
    [setSelectedRigIds],
  );

  return (
    <StyledRow $isSelected={isSelected}>
      <StyledBulbButton
        onClick={(ev) => {
          ev.preventDefault();
          toggleRigIdInSelection(rigId);
          Track.interact("Rig Leaderboard - Highlight Rig", { id: rigId });
        }}
        $isSelected={isSelected}
      >
        {isSelected ? (
          <PDComponent.SvgIcon name="ideaSelected" width="18" height="19" />
        ) : (
          <PDComponent.SvgIcon name="idea" />
        )}
      </StyledBulbButton>
      <StyledRigDescriptionContainer>
        <StyledLink
          to={`/rig/${rigId}`}
          onClick={() => {
            Track.interact("All Rigs - Open Rig", { id: rigId });
          }}
        >
          {isDataAnonymity && !selectedRigIds.includes(rigId) ? (
            <AnonymizedData />
          ) : (
            <StyledDescriptionNumber>{rigName}</StyledDescriptionNumber>
          )}

          {isDataAnonymity && !selectedRigIds.includes(rigId) ? (
            <AnonymizedData
              style={{
                alignSelf: "flex-end",
              }}
            />
          ) : (
            <StyledOperatorName title={operatorName}>{operatorName}</StyledOperatorName>
          )}
        </StyledLink>
      </StyledRigDescriptionContainer>
      {currentUser?.canAccessRigScorecard ? (
        <StyledRigDescriptionContainer>
          <StyledLink
            to={`/rig/scorecard/${rigId}`}
            onClick={() => {
              Track.interact("All Rigs - Open Rig Scorecard", { id: rigId });
            }}
          >
            <StyledRigScoreIcon width={16} height={18} />
          </StyledLink>
        </StyledRigDescriptionContainer>
      ) : null}
    </StyledRow>
  );
});
