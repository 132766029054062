/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FuelType = {
    Unknown: 'Unknown',
    Diesel: 'Diesel',
    NaturalGas: 'NaturalGas',
    BiFuel: 'BiFuel'
} as const;
export type FuelType = typeof FuelType[keyof typeof FuelType];


export function FuelTypeFromJSON(json: any): FuelType {
    return FuelTypeFromJSONTyped(json, false);
}

export function FuelTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FuelType {
    return json as FuelType;
}

export function FuelTypeToJSON(value?: FuelType | null): any {
    return value as any;
}

