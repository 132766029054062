/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    FuelFlowRateFactDto,
    FuelFlowRateFactDtoFromJSON,
    FuelFlowRateFactDtoFromJSONTyped,
    FuelFlowRateFactDtoToJSON,
} from './FuelFlowRateFactDto';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    ServiceMessageDto,
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import {
    StopWatchDto,
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';

/**
 * 
 * @export
 * @interface FuelFlowRateResultDto
 */
export interface FuelFlowRateResultDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof FuelFlowRateResultDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof FuelFlowRateResultDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof FuelFlowRateResultDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof FuelFlowRateResultDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {Array<FuelFlowRateFactDto>}
     * @memberof FuelFlowRateResultDto
     */
    dieselFacts?: Array<FuelFlowRateFactDto> | null;
    /**
     * 
     * @type {Array<FuelFlowRateFactDto>}
     * @memberof FuelFlowRateResultDto
     */
    naturalGasFacts?: Array<FuelFlowRateFactDto> | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof FuelFlowRateResultDto
     */
    dataState: ResultDataState;
}

export function FuelFlowRateResultDtoFromJSON(json: any): FuelFlowRateResultDto {
    return FuelFlowRateResultDtoFromJSONTyped(json, false);
}

export function FuelFlowRateResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FuelFlowRateResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': !exists(json, 'Messages') ? undefined : (json['Messages'] === null ? null : (json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'dieselFacts': !exists(json, 'DieselFacts') ? undefined : (json['DieselFacts'] === null ? null : (json['DieselFacts'] as Array<any>).map(FuelFlowRateFactDtoFromJSON)),
        'naturalGasFacts': !exists(json, 'NaturalGasFacts') ? undefined : (json['NaturalGasFacts'] === null ? null : (json['NaturalGasFacts'] as Array<any>).map(FuelFlowRateFactDtoFromJSON)),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function FuelFlowRateResultDtoToJSON(value?: FuelFlowRateResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value.stopWatch),
        'Messages': value.messages === undefined ? undefined : (value.messages === null ? null : (value.messages as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value.userLensId,
        'LastUpdatedAt': DateDtoToJSON(value.lastUpdatedAt),
        'DieselFacts': value.dieselFacts === undefined ? undefined : (value.dieselFacts === null ? null : (value.dieselFacts as Array<any>).map(FuelFlowRateFactDtoToJSON)),
        'NaturalGasFacts': value.naturalGasFacts === undefined ? undefined : (value.naturalGasFacts === null ? null : (value.naturalGasFacts as Array<any>).map(FuelFlowRateFactDtoToJSON)),
        'DataState': ResultDataStateToJSON(value.dataState),
    };
}

