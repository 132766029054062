/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DimensionType,
    DimensionTypeFromJSON,
    DimensionTypeFromJSONTyped,
    DimensionTypeToJSON,
} from './DimensionType';
import {
    StandKpiType,
    StandKpiTypeFromJSON,
    StandKpiTypeFromJSONTyped,
    StandKpiTypeToJSON,
} from './StandKpiType';

/**
 * 
 * @export
 * @interface StandKpiTypeDto
 */
export interface StandKpiTypeDto {
    /**
     * 
     * @type {StandKpiType}
     * @memberof StandKpiTypeDto
     */
    id: StandKpiType;
    /**
     * 
     * @type {string}
     * @memberof StandKpiTypeDto
     */
    categoryName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StandKpiTypeDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StandKpiTypeDto
     */
    shortName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StandKpiTypeDto
     */
    description?: string | null;
    /**
     * 
     * @type {DimensionType}
     * @memberof StandKpiTypeDto
     */
    dimension: DimensionType;
    /**
     * 
     * @type {boolean}
     * @memberof StandKpiTypeDto
     */
    includesFlatTime: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StandKpiTypeDto
     */
    includesSlidingTime: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StandKpiTypeDto
     */
    includesRotatingTime: boolean;
}

export function StandKpiTypeDtoFromJSON(json: any): StandKpiTypeDto {
    return StandKpiTypeDtoFromJSONTyped(json, false);
}

export function StandKpiTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandKpiTypeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': StandKpiTypeFromJSON(json['Id']),
        'categoryName': !exists(json, 'CategoryName') ? undefined : json['CategoryName'],
        'name': !exists(json, 'Name') ? undefined : json['Name'],
        'shortName': !exists(json, 'ShortName') ? undefined : json['ShortName'],
        'description': !exists(json, 'Description') ? undefined : json['Description'],
        'dimension': DimensionTypeFromJSON(json['Dimension']),
        'includesFlatTime': json['IncludesFlatTime'],
        'includesSlidingTime': json['IncludesSlidingTime'],
        'includesRotatingTime': json['IncludesRotatingTime'],
    };
}

export function StandKpiTypeDtoToJSON(value?: StandKpiTypeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': StandKpiTypeToJSON(value.id),
        'CategoryName': value.categoryName,
        'Name': value.name,
        'ShortName': value.shortName,
        'Description': value.description,
        'Dimension': DimensionTypeToJSON(value.dimension),
        'IncludesFlatTime': value.includesFlatTime,
        'IncludesSlidingTime': value.includesSlidingTime,
        'IncludesRotatingTime': value.includesRotatingTime,
    };
}

