/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    KpiPivotFactSetDto,
    KpiPivotFactSetDtoFromJSON,
    KpiPivotFactSetDtoToJSON,
    KpiTypeUserLensDto,
    KpiTypeUserLensDtoFromJSON,
    KpiTypeUserLensDtoToJSON,
    PivotKpiTypeUserLensDto,
    PivotKpiTypeUserLensDtoFromJSON,
    PivotKpiTypeUserLensDtoToJSON,
    RigKpiPivotQueryDto,
    RigKpiPivotQueryDtoFromJSON,
    RigKpiPivotQueryDtoToJSON,
} from '../models';

export interface ApiPivotKpiTypeUserLensesIdPivotFactsPutRequest {
    id: number;
    rigKpiPivotQueryDto?: RigKpiPivotQueryDto;
}

export interface ApiPivotKpiTypeUserLensesIdPutRequest {
    id: number;
    pivotKpiTypeUserLensDto?: PivotKpiTypeUserLensDto;
}

export interface ApiPivotKpiTypeUserLensesPostRequest {
    pivotKpiTypeUserLensDto?: PivotKpiTypeUserLensDto;
}

/**
 * 
 */
export class PivotKpiTypeUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiPivotKpiTypeUserLensesIdPivotFactsPutRaw(requestParameters: ApiPivotKpiTypeUserLensesIdPivotFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<KpiPivotFactSetDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPivotKpiTypeUserLensesIdPivotFactsPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/PivotKpiTypeUserLenses/{id}/PivotFacts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RigKpiPivotQueryDtoToJSON(requestParameters.rigKpiPivotQueryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KpiPivotFactSetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiPivotKpiTypeUserLensesIdPivotFactsPut(requestParameters: ApiPivotKpiTypeUserLensesIdPivotFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<KpiPivotFactSetDto> {
        const response = await this.apiPivotKpiTypeUserLensesIdPivotFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPivotKpiTypeUserLensesIdPutRaw(requestParameters: ApiPivotKpiTypeUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<KpiTypeUserLensDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPivotKpiTypeUserLensesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/PivotKpiTypeUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PivotKpiTypeUserLensDtoToJSON(requestParameters.pivotKpiTypeUserLensDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KpiTypeUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiPivotKpiTypeUserLensesIdPut(requestParameters: ApiPivotKpiTypeUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<KpiTypeUserLensDto> {
        const response = await this.apiPivotKpiTypeUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPivotKpiTypeUserLensesPostRaw(requestParameters: ApiPivotKpiTypeUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<KpiTypeUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/PivotKpiTypeUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PivotKpiTypeUserLensDtoToJSON(requestParameters.pivotKpiTypeUserLensDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KpiTypeUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiPivotKpiTypeUserLensesPost(requestParameters: ApiPivotKpiTypeUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<KpiTypeUserLensDto> {
        const response = await this.apiPivotKpiTypeUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
