/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BaseFuelQueryDto,
    BaseFuelQueryDtoFromJSON,
    BaseFuelQueryDtoToJSON,
    DieselNatGasRatioStatsResultDto,
    DieselNatGasRatioStatsResultDtoFromJSON,
    DieselNatGasRatioStatsResultDtoToJSON,
    DieselNatGasRatioStatsUserLensDto,
    DieselNatGasRatioStatsUserLensDtoFromJSON,
    DieselNatGasRatioStatsUserLensDtoToJSON,
} from '../models';

export interface ApiDieselNatGasRatioStatsUserLensesIdFactsPutRequest {
    id: number;
    baseFuelQueryDto?: BaseFuelQueryDto;
}

export interface ApiDieselNatGasRatioStatsUserLensesIdPutRequest {
    id: number;
    dieselNatGasRatioStatsUserLensDto?: DieselNatGasRatioStatsUserLensDto;
}

export interface ApiDieselNatGasRatioStatsUserLensesPostRequest {
    dieselNatGasRatioStatsUserLensDto?: DieselNatGasRatioStatsUserLensDto;
}

/**
 * 
 */
export class DieselNatGasRatioStatsUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiDieselNatGasRatioStatsUserLensesIdFactsPutRaw(requestParameters: ApiDieselNatGasRatioStatsUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DieselNatGasRatioStatsResultDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDieselNatGasRatioStatsUserLensesIdFactsPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/DieselNatGasRatioStatsUserLenses/{id}/Facts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BaseFuelQueryDtoToJSON(requestParameters.baseFuelQueryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DieselNatGasRatioStatsResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiDieselNatGasRatioStatsUserLensesIdFactsPut(requestParameters: ApiDieselNatGasRatioStatsUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DieselNatGasRatioStatsResultDto> {
        const response = await this.apiDieselNatGasRatioStatsUserLensesIdFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDieselNatGasRatioStatsUserLensesIdPutRaw(requestParameters: ApiDieselNatGasRatioStatsUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DieselNatGasRatioStatsUserLensDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDieselNatGasRatioStatsUserLensesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/DieselNatGasRatioStatsUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DieselNatGasRatioStatsUserLensDtoToJSON(requestParameters.dieselNatGasRatioStatsUserLensDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DieselNatGasRatioStatsUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiDieselNatGasRatioStatsUserLensesIdPut(requestParameters: ApiDieselNatGasRatioStatsUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DieselNatGasRatioStatsUserLensDto> {
        const response = await this.apiDieselNatGasRatioStatsUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDieselNatGasRatioStatsUserLensesPostRaw(requestParameters: ApiDieselNatGasRatioStatsUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DieselNatGasRatioStatsUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/DieselNatGasRatioStatsUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DieselNatGasRatioStatsUserLensDtoToJSON(requestParameters.dieselNatGasRatioStatsUserLensDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DieselNatGasRatioStatsUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiDieselNatGasRatioStatsUserLensesPost(requestParameters: ApiDieselNatGasRatioStatsUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DieselNatGasRatioStatsUserLensDto> {
        const response = await this.apiDieselNatGasRatioStatsUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
