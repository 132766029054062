/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BaseDisplayOptionUserLensQueryDto,
    BaseDisplayOptionUserLensQueryDtoFromJSON,
    BaseDisplayOptionUserLensQueryDtoToJSON,
    DrillingProductivityUserLensDto,
    DrillingProductivityUserLensDtoFromJSON,
    DrillingProductivityUserLensDtoToJSON,
    SingleStandKpiComparisonDto,
    SingleStandKpiComparisonDtoFromJSON,
    SingleStandKpiComparisonDtoToJSON,
    SingleStandKpiDto,
    SingleStandKpiDtoFromJSON,
    SingleStandKpiDtoToJSON,
} from '../models';

export interface ApiDrillingProductivityUserLensesIdComparisonFactsPutRequest {
    id: number;
    baseDisplayOptionUserLensQueryDto?: BaseDisplayOptionUserLensQueryDto;
}

export interface ApiDrillingProductivityUserLensesIdFactsPutRequest {
    id: number;
    baseDisplayOptionUserLensQueryDto?: BaseDisplayOptionUserLensQueryDto;
}

export interface ApiDrillingProductivityUserLensesIdPutRequest {
    id: number;
    drillingProductivityUserLensDto?: DrillingProductivityUserLensDto;
}

export interface ApiDrillingProductivityUserLensesPostRequest {
    drillingProductivityUserLensDto?: DrillingProductivityUserLensDto;
}

/**
 * 
 */
export class DrillingProductivityUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiDrillingProductivityUserLensesIdComparisonFactsPutRaw(requestParameters: ApiDrillingProductivityUserLensesIdComparisonFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SingleStandKpiComparisonDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDrillingProductivityUserLensesIdComparisonFactsPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/DrillingProductivityUserLenses/{id}/ComparisonFacts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BaseDisplayOptionUserLensQueryDtoToJSON(requestParameters.baseDisplayOptionUserLensQueryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SingleStandKpiComparisonDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiDrillingProductivityUserLensesIdComparisonFactsPut(requestParameters: ApiDrillingProductivityUserLensesIdComparisonFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SingleStandKpiComparisonDto> {
        const response = await this.apiDrillingProductivityUserLensesIdComparisonFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDrillingProductivityUserLensesIdFactsPutRaw(requestParameters: ApiDrillingProductivityUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SingleStandKpiDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDrillingProductivityUserLensesIdFactsPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/DrillingProductivityUserLenses/{id}/Facts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BaseDisplayOptionUserLensQueryDtoToJSON(requestParameters.baseDisplayOptionUserLensQueryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SingleStandKpiDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiDrillingProductivityUserLensesIdFactsPut(requestParameters: ApiDrillingProductivityUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SingleStandKpiDto> {
        const response = await this.apiDrillingProductivityUserLensesIdFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDrillingProductivityUserLensesIdPutRaw(requestParameters: ApiDrillingProductivityUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DrillingProductivityUserLensDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDrillingProductivityUserLensesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/DrillingProductivityUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DrillingProductivityUserLensDtoToJSON(requestParameters.drillingProductivityUserLensDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DrillingProductivityUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiDrillingProductivityUserLensesIdPut(requestParameters: ApiDrillingProductivityUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DrillingProductivityUserLensDto> {
        const response = await this.apiDrillingProductivityUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDrillingProductivityUserLensesPostRaw(requestParameters: ApiDrillingProductivityUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DrillingProductivityUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/DrillingProductivityUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DrillingProductivityUserLensDtoToJSON(requestParameters.drillingProductivityUserLensDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DrillingProductivityUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiDrillingProductivityUserLensesPost(requestParameters: ApiDrillingProductivityUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DrillingProductivityUserLensDto> {
        const response = await this.apiDrillingProductivityUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
