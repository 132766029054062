import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import type { DataFeedRefreshInfoDto, DataFeedWellDatesDto } from "apis/oag";
import { DataFeedsApi } from "apis/oag";
import { RealTimeDataEnum } from "components/RealTimeIndicator";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useCallback, useMemo } from "react";
import { useAppSelector } from "reducers/store";
import { useDebounce } from "use-debounce";
import { API_REFRESH_INTERVAL, apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const DataFeeds = new DataFeedsApi(apiConfig);

export const useRigsDataFeed = (
  isEnabled: boolean,
  isDetailed: boolean,
  options?: UseQueryOptions<Array<DataFeedRefreshInfoDto>>,
) => {
  const queryClient = useQueryClient();
  const lastWellRefreshDate = useAppSelector((state) => state.rigDashboard.lastWellRefreshDate);

  const [realtimeData] = useStateQuery<RealTimeDataEnum>(
    isDetailed ? URL_STATE_PARAM.REALTIME_DATA_WIDGET : URL_STATE_PARAM.REALTIME_DATA_DASHBOARD,
    RealTimeDataEnum.ACTIVE,
  );

  const [selectedWells] = useStateQuery<Array<number>>(URL_STATE_PARAM.SELECTED_WELLS_RIG_WIDGET, []);

  const requestQuery: Array<DataFeedWellDatesDto> = useMemo(
    () =>
      // TODO is this correct?
      selectedWells.map((well) => ({
        lastBatteryStateRefreshDate: defaultDateDto.from,
        lastPowerManagementRefreshDate: defaultDateDto.from,
        lastFuelManagementRefreshDate: defaultDateDto.from,
        lastWellRefreshDate: defaultDateDto.from,
        lastTvdRefreshDate: defaultDateDto.from,
        lastPerStandRefreshDate: defaultDateDto.from,
        lastByDepthRefreshDate: defaultDateDto.from,
        lastRigStateRefreshDate: lastWellRefreshDate || defaultDateDto.from,
        wellId: well,
      })),
    [lastWellRefreshDate, selectedWells],
  );

  const refreshAllRigs = useCallback(
    (shallRefresh: DataFeedRefreshInfoDto[]) => {
      if (shallRefresh.some((shallRefetchRig) => shallRefetchRig.requiresRigStateRefresh)) {
        queryClient.invalidateQueries({ queryKey: [{ uid: RequestUID.rigsActiveOperators }], ...{ exact: false } });
        queryClient.invalidateQueries({
          queryKey: [{ type: PDQueryType.WELL_SUMMARIES }],
          ...{ exact: false },
        });
        queryClient.invalidateQueries({
          queryKey: [{ uid: RequestUID.kpiGroupPivotFacts }],
          ...{ exact: false },
        });
      }
    },
    [queryClient],
  );

  const [debouncedQuery] = useDebounce(requestQuery, 1000, {
    leading: true,
    equalityFn: (a, b) => JSON.stringify(a) === JSON.stringify(b),
  });

  useQuery<DataFeedRefreshInfoDto[]>({
    queryKey: [{ uid: RequestUID.wellsDataFeed, debouncedQuery }],
    queryFn: () => {
      if (!isEnabled || !lastWellRefreshDate || realtimeData !== RealTimeDataEnum.ACTIVE) return [];
      return DataFeeds.apiDataFeedsPut({
        feedUpdateRequestDto: { wellDates: debouncedQuery },
      }).then(
        (data: DataFeedRefreshInfoDto[]) => {
          refreshAllRigs(data);
          return data;
        });
    },
    ...{
      ...options,
      enabled: isEnabled && realtimeData === RealTimeDataEnum.ACTIVE && !!lastWellRefreshDate,
      refetchIntervalInBackground: true,
      refetchInterval: API_REFRESH_INTERVAL,
    },
  });
};
