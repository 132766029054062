/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ServiceMessageType = {
    Info: 'Info',
    Unauthorized: 'Unauthorized'
} as const;
export type ServiceMessageType = typeof ServiceMessageType[keyof typeof ServiceMessageType];


export function ServiceMessageTypeFromJSON(json: any): ServiceMessageType {
    return ServiceMessageTypeFromJSONTyped(json, false);
}

export function ServiceMessageTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceMessageType {
    return json as ServiceMessageType;
}

export function ServiceMessageTypeToJSON(value?: ServiceMessageType | null): any {
    return value as any;
}

