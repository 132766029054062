/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RigScorecardLensType = {
    Default: 'Default',
    VerticalOnlyNoSurface: 'VerticalOnlyNoSurface',
    BuildOnly: 'BuildOnly',
    LateralOnly: 'LateralOnly',
    IntermediateOnly: 'IntermediateOnly',
    ProductionOnly: 'ProductionOnly',
    OverallWellScores: 'OverallWellScores'
} as const;
export type RigScorecardLensType = typeof RigScorecardLensType[keyof typeof RigScorecardLensType];


export function RigScorecardLensTypeFromJSON(json: any): RigScorecardLensType {
    return RigScorecardLensTypeFromJSONTyped(json, false);
}

export function RigScorecardLensTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigScorecardLensType {
    return json as RigScorecardLensType;
}

export function RigScorecardLensTypeToJSON(value?: RigScorecardLensType | null): any {
    return value as any;
}

