/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WellJobsDto
 */
export interface WellJobsDto {
    /**
     * 
     * @type {number}
     * @memberof WellJobsDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof WellJobsDto
     */
    externalId: number;
    /**
     * 
     * @type {number}
     * @memberof WellJobsDto
     */
    rigId: number;
}

export function WellJobsDtoFromJSON(json: any): WellJobsDto {
    return WellJobsDtoFromJSONTyped(json, false);
}

export function WellJobsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WellJobsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'externalId': json['ExternalId'],
        'rigId': json['RigId'],
    };
}

export function WellJobsDtoToJSON(value?: WellJobsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'ExternalId': value.externalId,
        'RigId': value.rigId,
    };
}

