/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScoreSummaryLensDto
 */
export interface ScoreSummaryLensDto {
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryLensDto
     */
    targetDeltaTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryLensDto
     */
    invisibleLostTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryLensDto
     */
    lensId: number;
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryLensDto
     */
    operatorScore?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryLensDto
     */
    rigScore?: number | null;
}

export function ScoreSummaryLensDtoFromJSON(json: any): ScoreSummaryLensDto {
    return ScoreSummaryLensDtoFromJSONTyped(json, false);
}

export function ScoreSummaryLensDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScoreSummaryLensDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'targetDeltaTime': !exists(json, 'TargetDeltaTime') ? undefined : json['TargetDeltaTime'],
        'invisibleLostTime': !exists(json, 'InvisibleLostTime') ? undefined : json['InvisibleLostTime'],
        'lensId': json['LensId'],
        'operatorScore': !exists(json, 'OperatorScore') ? undefined : json['OperatorScore'],
        'rigScore': !exists(json, 'RigScore') ? undefined : json['RigScore'],
    };
}

export function ScoreSummaryLensDtoToJSON(value?: ScoreSummaryLensDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'TargetDeltaTime': value.targetDeltaTime,
        'InvisibleLostTime': value.invisibleLostTime,
        'LensId': value.lensId,
        'OperatorScore': value.operatorScore,
        'RigScore': value.rigScore,
    };
}

