/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DashboardType,
    DashboardTypeFromJSON,
    DashboardTypeFromJSONTyped,
    DashboardTypeToJSON,
} from './DashboardType';
import {
    LensTabStateType,
    LensTabStateTypeFromJSON,
    LensTabStateTypeFromJSONTyped,
    LensTabStateTypeToJSON,
} from './LensTabStateType';
import {
    OperationCategoryType,
    OperationCategoryTypeFromJSON,
    OperationCategoryTypeFromJSONTyped,
    OperationCategoryTypeToJSON,
} from './OperationCategoryType';
import {
    ProfileOriginType,
    ProfileOriginTypeFromJSON,
    ProfileOriginTypeFromJSONTyped,
    ProfileOriginTypeToJSON,
} from './ProfileOriginType';

/**
 * 
 * @export
 * @interface LensTabWithVisibilityDto
 */
export interface LensTabWithVisibilityDto {
    /**
     * 
     * @type {number}
     * @memberof LensTabWithVisibilityDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LensTabWithVisibilityDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LensTabWithVisibilityDto
     */
    shortName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LensTabWithVisibilityDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LensTabWithVisibilityDto
     */
    isLocked: boolean;
    /**
     * 
     * @type {OperationCategoryType}
     * @memberof LensTabWithVisibilityDto
     */
    operationCategoryType: OperationCategoryType;
    /**
     * 
     * @type {ProfileOriginType}
     * @memberof LensTabWithVisibilityDto
     */
    origin: ProfileOriginType;
    /**
     * 
     * @type {DashboardType}
     * @memberof LensTabWithVisibilityDto
     */
    dashboardType: DashboardType;
    /**
     * 
     * @type {number}
     * @memberof LensTabWithVisibilityDto
     */
    position: number;
    /**
     * 
     * @type {string}
     * @memberof LensTabWithVisibilityDto
     */
    group?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LensTabWithVisibilityDto
     */
    profileId: number;
    /**
     * 
     * @type {LensTabStateType}
     * @memberof LensTabWithVisibilityDto
     */
    state: LensTabStateType;
    /**
     * 
     * @type {Date}
     * @memberof LensTabWithVisibilityDto
     */
    sharedAtUtc?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof LensTabWithVisibilityDto
     */
    sharedById?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof LensTabWithVisibilityDto
     */
    rigIds?: Array<number> | null;
}

export function LensTabWithVisibilityDtoFromJSON(json: any): LensTabWithVisibilityDto {
    return LensTabWithVisibilityDtoFromJSONTyped(json, false);
}

export function LensTabWithVisibilityDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LensTabWithVisibilityDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': json['Name'],
        'shortName': !exists(json, 'ShortName') ? undefined : json['ShortName'],
        'description': !exists(json, 'Description') ? undefined : json['Description'],
        'isLocked': json['IsLocked'],
        'operationCategoryType': OperationCategoryTypeFromJSON(json['OperationCategoryType']),
        'origin': ProfileOriginTypeFromJSON(json['Origin']),
        'dashboardType': DashboardTypeFromJSON(json['DashboardType']),
        'position': json['Position'],
        'group': !exists(json, 'Group') ? undefined : json['Group'],
        'profileId': json['ProfileId'],
        'state': LensTabStateTypeFromJSON(json['State']),
        'sharedAtUtc': !exists(json, 'SharedAtUtc') ? undefined : (json['SharedAtUtc'] === null ? null : new Date(json['SharedAtUtc'])),
        'sharedById': !exists(json, 'SharedById') ? undefined : json['SharedById'],
        'rigIds': !exists(json, 'RigIds') ? undefined : json['RigIds'],
    };
}

export function LensTabWithVisibilityDtoToJSON(value?: LensTabWithVisibilityDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'Name': value.name,
        'ShortName': value.shortName,
        'Description': value.description,
        'IsLocked': value.isLocked,
        'OperationCategoryType': OperationCategoryTypeToJSON(value.operationCategoryType),
        'Origin': ProfileOriginTypeToJSON(value.origin),
        'DashboardType': DashboardTypeToJSON(value.dashboardType),
        'Position': value.position,
        'Group': value.group,
        'ProfileId': value.profileId,
        'State': LensTabStateTypeToJSON(value.state),
        'SharedAtUtc': value.sharedAtUtc === undefined ? undefined : (value.sharedAtUtc === null ? null : value.sharedAtUtc.toISOString()),
        'SharedById': value.sharedById,
        'RigIds': value.rigIds,
    };
}

