/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ScoreComplianceStateType = {
    NoActionRequired: 'NoActionRequired',
    ActionCompleted: 'ActionCompleted',
    ActionPending: 'ActionPending',
    CommentedOnWithoutActionRequired: 'CommentedOnWithoutActionRequired'
} as const;
export type ScoreComplianceStateType = typeof ScoreComplianceStateType[keyof typeof ScoreComplianceStateType];


export function ScoreComplianceStateTypeFromJSON(json: any): ScoreComplianceStateType {
    return ScoreComplianceStateTypeFromJSONTyped(json, false);
}

export function ScoreComplianceStateTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScoreComplianceStateType {
    return json as ScoreComplianceStateType;
}

export function ScoreComplianceStateTypeToJSON(value?: ScoreComplianceStateType | null): any {
    return value as any;
}

