import type { CheckboxChangeEvent } from "antd/lib/checkbox";
import type { DirectionalIntervalDto, FiltersDto, HoleSectionDto, HoleSizeDto } from "apis/oag";
import FiltersPopup from "components/General/FiltersPopup";
import SelectFilter from "components/General/SelectFilter";
import type { Dict } from "mixpanel-browser";
import type { FC } from "react";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "reducers/store";
import type { IFiltersType } from "reducers/types";
import { Track } from "services/Mixpanel";
import { useCustomHoleSizeUom } from "utils/format";

export type HiddenFilters = (keyof IFiltersType)[];

interface IFilters {
  appliedState: IFiltersType;
  lens?: boolean;
  onCancel?: () => void;
  report?: boolean;
  setFilterData?: (obj: IFiltersType, state: boolean) => void;
  trackingProps?: Dict;
  visible: boolean;
  holeSections?: HoleSectionDto[];
  holeSizes?: HoleSizeDto[];
  directionalIntervals?: DirectionalIntervalDto[];
  availableFilters?: FiltersDto;
  hiddenFilters?: HiddenFilters;
}
export interface IFilterOptions {
  label: string;
  value: string | number;
  gray?: boolean;
}


const filtersOrder = ["directionalIntervals", "options", "sections", "holeSizes"];

export const filterBaseOptions = {
  operationTime: {
    label: "Operation Time",
    options: [
      {
        label: "Rotating Time",
        value: "Rotating Time",
      },
      {
        label: "Sliding Time",
        value: "Sliding Time",
      },
      {
        label: "Flat Time",
        value: "Flat Time",
      },
    ] as IFilterOptions[],
  },
  sections: {
    label: "Sections",
    options: [] as IFilterOptions[],
  },
  holeSizes: {
    label: "Hole Size",
    options: [] as IFilterOptions[],
  },
  directionalIntervals: {
    label: "Directional Intervals",
    options: [] as IFilterOptions[],
  },
};


const getFilterOptions = ({
  currentFilterOptions,
  holeSections,
  holeSizes,
  directionalIntervals,
  availableFilters,
  filtersOverview,
  holesizeUom,

}: {
  currentFilterOptions: typeof filterBaseOptions;
  holeSections: HoleSectionDto[];
  holeSizes: HoleSizeDto[];
  directionalIntervals: DirectionalIntervalDto[];
  availableFilters: FiltersDto;
  filtersOverview: FiltersDto;
  holesizeUom: ReturnType<typeof useCustomHoleSizeUom>;
}) => {

  currentFilterOptions.sections.options = holeSections
    .filter((e) => (availableFilters?.sectionIds ? availableFilters.sectionIds.includes(e.id) : true))
    .map((f) => ({
      gray: !filtersOverview?.sectionIds?.includes(f.id),
      label: f.name ?? "",
      value: f.id,
    }));
  currentFilterOptions.holeSizes.options = holeSizes
    .filter((e) => (availableFilters?.holeSizeIds ? availableFilters.holeSizeIds.includes(e.id) : true))
    .sort((a, b) => {
      if (a.value < 0 || b.value < 0) return -Number.MAX_VALUE;
      return b.value - a.value; // Hole sizes are displayed descending (`Unknown` remains at the end though)
    })
    .map((f) => ({
      gray: !filtersOverview?.holeSizeIds?.includes(f.id),
      label: f.value < 0 ? "Unknown" : holesizeUom.display(f.value, { metricOption: true }),
      value: f.id,
    }));
  currentFilterOptions.directionalIntervals.options = directionalIntervals
    .filter((e) =>
      availableFilters?.directionalIntervalIds ? availableFilters.directionalIntervalIds.includes(e.id) : true,
    )
    .map((f) => ({
      gray: !filtersOverview?.directionalIntervalIds?.includes(f.id),
      label: f.name ?? "",
      value: f.id,
    }));
  currentFilterOptions.operationTime.options = currentFilterOptions.operationTime.options.map((opTime) => {
    return {
      ...opTime,
      gray: !filtersOverview?.[
        `include${opTime.label.replace(" ", "")}` as unknown as keyof typeof filtersOverview
      ],
    };
  });

  return [{
    directionalIntervals: currentFilterOptions.directionalIntervals.options.map((e) => e.value as number),
    operationTime: currentFilterOptions.operationTime.options.map((e) => e.value as string),
    sections: currentFilterOptions.sections.options.map((e) => e.value as number),
    holeSizes: currentFilterOptions.holeSizes.options.map((e) => e.value as number),
  }, currentFilterOptions] as const;
}

const emptyState: IFiltersType = {
  operationTime: [],
  sections: [],
  directionalIntervals: [],
  holeSizes: [],
};

const Filters: FC<IFilters> = ({
  appliedState,
  lens = false,
  onCancel,
  report = false,
  setFilterData,
  trackingProps,
  visible,
  holeSections,
  holeSizes,
  directionalIntervals,
  availableFilters,
  hiddenFilters = [],
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [filterOptions, setFilterOptions] = useState(filterBaseOptions);
  const [initialValues, setInitialValues] = useState(emptyState);
  const [filterValues, setFilterValues] = useState<IFiltersType>(appliedState || emptyState);
  const filtersOverview = useAppSelector((state) => state.state.availableFilters);

  const holesizeUom = useCustomHoleSizeUom();

  useEffect(() => {
    if (visible) {
      setFilterValues(() => ({
        operationTime: appliedState?.operationTime ?? initialValues.operationTime,
        sections: appliedState?.sections ?? initialValues.sections,
        directionalIntervals: appliedState?.directionalIntervals ?? initialValues.directionalIntervals,
        holeSizes: appliedState?.holeSizes ?? initialValues.holeSizes,
      }));
    }
  }, [appliedState, initialValues, visible]);

  const onCommonCheckboxChange = (key: keyof typeof filterOptions) => (e: CheckboxChangeEvent) =>
    setFilterValues((x) => ({
      ...x,
      [key]: e.target.checked ? filterOptions[key].options.map((f: IFilterOptions) => f.value) : [],
    }));

  const onCheckboxChange =
    (filterGroup: keyof typeof filterOptions) => (checkboxKey: string | number, e: CheckboxChangeEvent) => {
      setFilterValues((x) => {
        const filterValues = x[filterGroup];
        return {
          ...x,
          [filterGroup]:
            e.target.checked && filterValues
              ? [...filterValues, checkboxKey]
              : (x[filterGroup] as Array<string | number>).filter((item) => item !== checkboxKey),
        };
      });
    };

  const deselectAll = () => {
    if (
      (Object.keys(filterValues) as Array<keyof typeof filterOptions>).reduce(
        (a, b) => a + (filterValues?.[b]?.length || 0),
        0,
      ) ===
      (Object.keys(filterOptions) as Array<keyof typeof filterOptions>).reduce(
        (a, b) => a + filterOptions[b].options.length,
        0,
      )
    ) {
      setFilterValues(emptyState);
    } else {
      setFilterValues({
        operationTime: filterOptions.operationTime.options.map((e) => e.value as string),
        sections: filterOptions.sections.options.map((e) => e.value as number),
        directionalIntervals: filterOptions.directionalIntervals.options.map((e) => e.value as number),
        holeSizes: filterOptions.holeSizes.options.map((e) => e.value as number),
      });
    }
  };

  useEffect(() => {
    if (directionalIntervals && holeSections && holeSizes && isLoading && availableFilters) {
      setFilterOptions((currentFilterOptions) => {
        const [initials, newFilterOptions] = getFilterOptions(
          {
            currentFilterOptions,
            holeSections,
            holeSizes,
            directionalIntervals,
            availableFilters,
            filtersOverview,
            holesizeUom,
          }
        );
        setInitialValues(initials);
        return newFilterOptions;
      });
      setIsLoading(false);
    }
  }, [
    availableFilters,
    directionalIntervals,
    filterOptions,
    filtersOverview,
    holeSections,
    holeSizes,
    holesizeUom,
    isLoading,
  ]);

  const applyCustomization = () => {
    if (setFilterData) {
      setFilterData(
        filterValues,
        (Object.keys(filterValues) as Array<keyof typeof filterOptions>).reduce(
          (a, b) => a + (filterValues[b]?.length || 0),
          0,
        ) !==
        (Object.keys(filterOptions) as Array<keyof typeof filterOptions>).reduce(
          (a, b) => a + filterOptions[b].options.length,
          0,
        ),
      );
    }

    const trackLocation = () => {
      if (report) return "Report";
      if (lens) return "Lens";
      return "Well Dashboard";
    };
    Track.interact(`${trackLocation()}-Filter`, {
      ...trackingProps,
      Selection: {
        "Directional Intervals": filterValues?.directionalIntervals?.map(
          (id) => directionalIntervals?.find((e) => e.id === id)?.name,
        ),
        Section: filterValues?.sections?.map((id) => holeSections?.find((section) => section.id === id)?.name),
        "Operation Time": filterValues.operationTime,
        "Hole Size": filterValues?.holeSizes?.map((id) => {
          const holeSize = holeSizes?.find((size) => size.id === id)?.value;
          if (holeSize && holeSize <= 0) return "Unknown";
          else return holesizeUom.display(holeSize, { metricOption: true }).toString();
        }),
      },
    });
    if (onCancel) {
      onCancel();
    }
  };

  const isAllSelected = useMemo(() => {
    return (
      (Object.keys(filterValues) as Array<keyof typeof filterOptions>).reduce(
        (a, b) => a + (filterValues[b]?.length || 0),
        0,
      ) ===
      (Object.keys(filterOptions) as Array<keyof typeof filterOptions>).reduce(
        (a, b) => a + filterOptions[b].options.length,
        0,
      )
    );
  }, [filterOptions, filterValues]);

  const visibleOptions = useMemo(
    () =>
      (Object.keys(filterOptions) as Array<keyof IFiltersType>)
        .sort((a, b) => filtersOrder.indexOf(a) - filtersOrder.indexOf(b))
        .filter((filterKey) => !hiddenFilters.includes(filterKey)),
    [filterOptions, hiddenFilters],
  );

  return (
    <FiltersPopup
      isAllSelected={isAllSelected}
      trackingProps={trackingProps}
      deselectAll={deselectAll}
      onCancel={onCancel}
      applyCustomization={applyCustomization}
      hideSecondCol={visibleOptions.length < 2}
      numRows={visibleOptions.length < 2 ? 1 : undefined}
    >
      {visibleOptions.map((key) => (
        <div key={key}>
          <SelectFilter
            filterValues={filterValues[key] || []}
            label={filterOptions[key].label}
            options={filterOptions[key].options}
            onChangeGroup={onCommonCheckboxChange(key)}
            onChange={onCheckboxChange(key)}
          />
        </div>
      ))}
    </FiltersPopup>
  );
};

export default Filters;
