/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    DrillingTaskSummary,
    DrillingTaskSummaryFromJSON,
    DrillingTaskSummaryFromJSONTyped,
    DrillingTaskSummaryToJSON,
} from './DrillingTaskSummary';
import {
    FiltersDto,
    FiltersDtoFromJSON,
    FiltersDtoFromJSONTyped,
    FiltersDtoToJSON,
} from './FiltersDto';
import {
    Int32Int64FactIndicatorSetDto,
    Int32Int64FactIndicatorSetDtoFromJSON,
    Int32Int64FactIndicatorSetDtoFromJSONTyped,
    Int32Int64FactIndicatorSetDtoToJSON,
} from './Int32Int64FactIndicatorSetDto';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    ServiceMessageDto,
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import {
    StackedStandKpiDetailByDateShiftDto,
    StackedStandKpiDetailByDateShiftDtoFromJSON,
    StackedStandKpiDetailByDateShiftDtoFromJSONTyped,
    StackedStandKpiDetailByDateShiftDtoToJSON,
} from './StackedStandKpiDetailByDateShiftDto';
import {
    StackedStandKpiDetailByShiftDto,
    StackedStandKpiDetailByShiftDtoFromJSON,
    StackedStandKpiDetailByShiftDtoFromJSONTyped,
    StackedStandKpiDetailByShiftDtoToJSON,
} from './StackedStandKpiDetailByShiftDto';
import {
    StackedStandKpiSummaryDto,
    StackedStandKpiSummaryDtoFromJSON,
    StackedStandKpiSummaryDtoFromJSONTyped,
    StackedStandKpiSummaryDtoToJSON,
} from './StackedStandKpiSummaryDto';
import {
    StandKpiDetailsDto,
    StandKpiDetailsDtoFromJSON,
    StandKpiDetailsDtoFromJSONTyped,
    StandKpiDetailsDtoToJSON,
} from './StandKpiDetailsDto';
import {
    StandKpiSliceDto,
    StandKpiSliceDtoFromJSON,
    StandKpiSliceDtoFromJSONTyped,
    StandKpiSliceDtoToJSON,
} from './StandKpiSliceDto';
import {
    StopWatchDto,
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';

/**
 * 
 * @export
 * @interface StackedStandKpiDto
 */
export interface StackedStandKpiDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof StackedStandKpiDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof StackedStandKpiDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof StackedStandKpiDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof StackedStandKpiDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {FiltersDto}
     * @memberof StackedStandKpiDto
     */
    availableFilters: FiltersDto;
    /**
     * 
     * @type {StackedStandKpiSummaryDto}
     * @memberof StackedStandKpiDto
     */
    summary: StackedStandKpiSummaryDto;
    /**
     * 
     * @type {DrillingTaskSummary}
     * @memberof StackedStandKpiDto
     */
    drillingTaskSummary: DrillingTaskSummary;
    /**
     * 
     * @type {Int32Int64FactIndicatorSetDto}
     * @memberof StackedStandKpiDto
     */
    indicators: Int32Int64FactIndicatorSetDto;
    /**
     * 
     * @type {Array<StandKpiSliceDto>}
     * @memberof StackedStandKpiDto
     */
    summaryByKpi?: Array<StandKpiSliceDto> | null;
    /**
     * 
     * @type {Array<StandKpiDetailsDto>}
     * @memberof StackedStandKpiDto
     */
    detailsByStandDepth?: Array<StandKpiDetailsDto> | null;
    /**
     * 
     * @type {Array<StackedStandKpiDetailByDateShiftDto>}
     * @memberof StackedStandKpiDto
     */
    detailsByDateShift?: Array<StackedStandKpiDetailByDateShiftDto> | null;
    /**
     * 
     * @type {StackedStandKpiDetailByShiftDto}
     * @memberof StackedStandKpiDto
     */
    detailsByShift: StackedStandKpiDetailByShiftDto;
    /**
     * 
     * @type {ResultDataState}
     * @memberof StackedStandKpiDto
     */
    dataState: ResultDataState;
}

export function StackedStandKpiDtoFromJSON(json: any): StackedStandKpiDto {
    return StackedStandKpiDtoFromJSONTyped(json, false);
}

export function StackedStandKpiDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StackedStandKpiDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': !exists(json, 'Messages') ? undefined : (json['Messages'] === null ? null : (json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'availableFilters': FiltersDtoFromJSON(json['AvailableFilters']),
        'summary': StackedStandKpiSummaryDtoFromJSON(json['Summary']),
        'drillingTaskSummary': DrillingTaskSummaryFromJSON(json['DrillingTaskSummary']),
        'indicators': Int32Int64FactIndicatorSetDtoFromJSON(json['Indicators']),
        'summaryByKpi': !exists(json, 'SummaryByKpi') ? undefined : (json['SummaryByKpi'] === null ? null : (json['SummaryByKpi'] as Array<any>).map(StandKpiSliceDtoFromJSON)),
        'detailsByStandDepth': !exists(json, 'DetailsByStandDepth') ? undefined : (json['DetailsByStandDepth'] === null ? null : (json['DetailsByStandDepth'] as Array<any>).map(StandKpiDetailsDtoFromJSON)),
        'detailsByDateShift': !exists(json, 'DetailsByDateShift') ? undefined : (json['DetailsByDateShift'] === null ? null : (json['DetailsByDateShift'] as Array<any>).map(StackedStandKpiDetailByDateShiftDtoFromJSON)),
        'detailsByShift': StackedStandKpiDetailByShiftDtoFromJSON(json['DetailsByShift']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function StackedStandKpiDtoToJSON(value?: StackedStandKpiDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value.stopWatch),
        'Messages': value.messages === undefined ? undefined : (value.messages === null ? null : (value.messages as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value.userLensId,
        'LastUpdatedAt': DateDtoToJSON(value.lastUpdatedAt),
        'AvailableFilters': FiltersDtoToJSON(value.availableFilters),
        'Summary': StackedStandKpiSummaryDtoToJSON(value.summary),
        'DrillingTaskSummary': DrillingTaskSummaryToJSON(value.drillingTaskSummary),
        'Indicators': Int32Int64FactIndicatorSetDtoToJSON(value.indicators),
        'SummaryByKpi': value.summaryByKpi === undefined ? undefined : (value.summaryByKpi === null ? null : (value.summaryByKpi as Array<any>).map(StandKpiSliceDtoToJSON)),
        'DetailsByStandDepth': value.detailsByStandDepth === undefined ? undefined : (value.detailsByStandDepth === null ? null : (value.detailsByStandDepth as Array<any>).map(StandKpiDetailsDtoToJSON)),
        'DetailsByDateShift': value.detailsByDateShift === undefined ? undefined : (value.detailsByDateShift === null ? null : (value.detailsByDateShift as Array<any>).map(StackedStandKpiDetailByDateShiftDtoToJSON)),
        'DetailsByShift': StackedStandKpiDetailByShiftDtoToJSON(value.detailsByShift),
        'DataState': ResultDataStateToJSON(value.dataState),
    };
}

