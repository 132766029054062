/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface DataFeedWellDatesDto
 */
export interface DataFeedWellDatesDto {
    /**
     * 
     * @type {DateDto}
     * @memberof DataFeedWellDatesDto
     */
    lastWellRefreshDate: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof DataFeedWellDatesDto
     */
    lastTvdRefreshDate: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof DataFeedWellDatesDto
     */
    lastPerStandRefreshDate: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof DataFeedWellDatesDto
     */
    lastRigStateRefreshDate: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof DataFeedWellDatesDto
     */
    lastByDepthRefreshDate: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof DataFeedWellDatesDto
     */
    lastBatteryStateRefreshDate: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof DataFeedWellDatesDto
     */
    lastPowerManagementRefreshDate: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof DataFeedWellDatesDto
     */
    lastFuelManagementRefreshDate: DateDto;
    /**
     * 
     * @type {number}
     * @memberof DataFeedWellDatesDto
     */
    wellId: number;
}

export function DataFeedWellDatesDtoFromJSON(json: any): DataFeedWellDatesDto {
    return DataFeedWellDatesDtoFromJSONTyped(json, false);
}

export function DataFeedWellDatesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataFeedWellDatesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastWellRefreshDate': DateDtoFromJSON(json['LastWellRefreshDate']),
        'lastTvdRefreshDate': DateDtoFromJSON(json['LastTvdRefreshDate']),
        'lastPerStandRefreshDate': DateDtoFromJSON(json['LastPerStandRefreshDate']),
        'lastRigStateRefreshDate': DateDtoFromJSON(json['LastRigStateRefreshDate']),
        'lastByDepthRefreshDate': DateDtoFromJSON(json['LastByDepthRefreshDate']),
        'lastBatteryStateRefreshDate': DateDtoFromJSON(json['LastBatteryStateRefreshDate']),
        'lastPowerManagementRefreshDate': DateDtoFromJSON(json['LastPowerManagementRefreshDate']),
        'lastFuelManagementRefreshDate': DateDtoFromJSON(json['LastFuelManagementRefreshDate']),
        'wellId': json['WellId'],
    };
}

export function DataFeedWellDatesDtoToJSON(value?: DataFeedWellDatesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'LastWellRefreshDate': DateDtoToJSON(value.lastWellRefreshDate),
        'LastTvdRefreshDate': DateDtoToJSON(value.lastTvdRefreshDate),
        'LastPerStandRefreshDate': DateDtoToJSON(value.lastPerStandRefreshDate),
        'LastRigStateRefreshDate': DateDtoToJSON(value.lastRigStateRefreshDate),
        'LastByDepthRefreshDate': DateDtoToJSON(value.lastByDepthRefreshDate),
        'LastBatteryStateRefreshDate': DateDtoToJSON(value.lastBatteryStateRefreshDate),
        'LastPowerManagementRefreshDate': DateDtoToJSON(value.lastPowerManagementRefreshDate),
        'LastFuelManagementRefreshDate': DateDtoToJSON(value.lastFuelManagementRefreshDate),
        'WellId': value.wellId,
    };
}

