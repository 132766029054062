import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { ApiKpiGroupUserLensesIdComparisonFactsPutRequest, DateDto, StackedStandKpiComparisonDto } from "apis/oag";
import { DisplayOption, KpiGroupUserLensesApi } from "apis/oag";
import { initialZoomData } from "components/WellDashboard/ChartControls/index";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useSelectedWell } from "hooks/useSelectedWell";
import { useAppDispatch, useAppSelector } from "reducers/store";
import type { IFiltersType, IZoomData } from "reducers/types";
import { comparisonDisplayOptions } from "reducers/widgetReducer";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto, TVD_SAMPLE_COUNT } from "utils/common";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const api = new KpiGroupUserLensesApi(apiConfig);

export function useKpiGroupStackedComparisonFacts(
  lensId: number,
  options?: UseQueryOptions<StackedStandKpiComparisonDto>,
) {
  const wellDataID = useSelectedWell();
  const dispatch = useAppDispatch();

  const [
    { sections: sectionIds, operationTime, directionalIntervals: directionalIntervalIds, holeSizes: holeSizeIds },
  ] = useStateQuery<IFiltersType>(URL_STATE_PARAM.FILTERS_WIDGET, {
    sections: null,
    directionalIntervals: null,
    operationTime: null,
    holeSizes: null,
  });
  const [
    { ts_start: zoomStartDuration, ts_end: zoomEndDuration, depth_start: zoomStartDepth, depth_end: zoomEndDepth },
  ] = useStateQuery<IZoomData>(URL_STATE_PARAM.ZOOM_WIDGET, initialZoomData);
  const [offsetWells] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WIDGET, []);
  const displayOption = useAppSelector((state) => state.widgetOptions.display_options);

  const requestParameters: ApiKpiGroupUserLensesIdComparisonFactsPutRequest = {
    id: lensId ?? -1,
    baseDisplayOptionUserLensQueryDto: {
      selectedFilters: {
        sectionIds,
        directionalIntervalIds,
        holeSizeIds,
        includeFlatTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Flat Time"),
        includeSlidingTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Sliding Time"),
        includeRotatingTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Rotating Time"),
        includeNullHoleDepth: true,
      },
      zoomStartDepth,
      zoomEndDepth,
      zoomStartDuration,
      zoomEndDuration,
      wellId: wellDataID,
      displayOption: comparisonDisplayOptions.includes(displayOption) ? displayOption : DisplayOption.Well,
      comparisonWellIds: offsetWells.map((id) => +id),
      useMockData: false,
      sampleCount: TVD_SAMPLE_COUNT,
      from: defaultDateDto.from,
      to: defaultDateDto.to,
    },
  };

  const queryKey: PDLensFactsQueryKey<ApiKpiGroupUserLensesIdComparisonFactsPutRequest> = {
    type: PDQueryType.FACTS,
    uid: RequestUID.kpiGroupStackedFacts,
    lensId,
    params: requestParameters,
    comparisonType: "comparison",
  };

  return useQuery<StackedStandKpiComparisonDto>({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      api.apiKpiGroupUserLensesIdComparisonFactsPut(requestParameters, { signal }).then((data) => {
        if (data?.lastUpdatedAt) {
          const payload: { [key: number]: DateDto } = {};
          data?.comparisons.forEach((e) => (payload[e.wellId] = e.lastUpdatedAt));
          dispatch({
            type: "SET_PER_STAND_REFRESH_DATE",
            payload,
          });
        }
        return data;
      }),
    ...options,
  });
}
