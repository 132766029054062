/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BaseFuelQueryDto,
    BaseFuelQueryDtoFromJSON,
    BaseFuelQueryDtoToJSON,
    FuelTankVolumeResultDto,
    FuelTankVolumeResultDtoFromJSON,
    FuelTankVolumeResultDtoToJSON,
    FuelTankVolumeUserLensDto,
    FuelTankVolumeUserLensDtoFromJSON,
    FuelTankVolumeUserLensDtoToJSON,
} from '../models';

export interface ApiFuelTankVolumeUserLensesIdFactsPutRequest {
    id: number;
    baseFuelQueryDto?: BaseFuelQueryDto;
}

export interface ApiFuelTankVolumeUserLensesIdPutRequest {
    id: number;
    fuelTankVolumeUserLensDto?: FuelTankVolumeUserLensDto;
}

export interface ApiFuelTankVolumeUserLensesPostRequest {
    fuelTankVolumeUserLensDto?: FuelTankVolumeUserLensDto;
}

/**
 * 
 */
export class FuelTankVolumeUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiFuelTankVolumeUserLensesIdFactsPutRaw(requestParameters: ApiFuelTankVolumeUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FuelTankVolumeResultDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFuelTankVolumeUserLensesIdFactsPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/FuelTankVolumeUserLenses/{id}/Facts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BaseFuelQueryDtoToJSON(requestParameters.baseFuelQueryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FuelTankVolumeResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFuelTankVolumeUserLensesIdFactsPut(requestParameters: ApiFuelTankVolumeUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FuelTankVolumeResultDto> {
        const response = await this.apiFuelTankVolumeUserLensesIdFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFuelTankVolumeUserLensesIdPutRaw(requestParameters: ApiFuelTankVolumeUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FuelTankVolumeUserLensDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFuelTankVolumeUserLensesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/FuelTankVolumeUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FuelTankVolumeUserLensDtoToJSON(requestParameters.fuelTankVolumeUserLensDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FuelTankVolumeUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFuelTankVolumeUserLensesIdPut(requestParameters: ApiFuelTankVolumeUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FuelTankVolumeUserLensDto> {
        const response = await this.apiFuelTankVolumeUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFuelTankVolumeUserLensesPostRaw(requestParameters: ApiFuelTankVolumeUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FuelTankVolumeUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/FuelTankVolumeUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FuelTankVolumeUserLensDtoToJSON(requestParameters.fuelTankVolumeUserLensDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FuelTankVolumeUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFuelTankVolumeUserLensesPost(requestParameters: ApiFuelTankVolumeUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FuelTankVolumeUserLensDto> {
        const response = await this.apiFuelTankVolumeUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
