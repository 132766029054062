/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    SingleStandKpiComparisonInfoDto,
    SingleStandKpiComparisonInfoDtoFromJSON,
    SingleStandKpiComparisonInfoDtoFromJSONTyped,
    SingleStandKpiComparisonInfoDtoToJSON,
} from './SingleStandKpiComparisonInfoDto';

/**
 * 
 * @export
 * @interface SingleStandKpiComparisonWellDto
 */
export interface SingleStandKpiComparisonWellDto {
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiComparisonWellDto
     */
    wellId: number;
    /**
     * 
     * @type {boolean}
     * @memberof SingleStandKpiComparisonWellDto
     */
    isFocalWell: boolean;
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiComparisonWellDto
     */
    average: number;
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiComparisonWellDto
     */
    operationCount: number;
    /**
     * 
     * @type {DateDto}
     * @memberof SingleStandKpiComparisonWellDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {SingleStandKpiComparisonInfoDto}
     * @memberof SingleStandKpiComparisonWellDto
     */
    info: SingleStandKpiComparisonInfoDto;
    /**
     * 
     * @type {Array<SingleStandKpiComparisonInfoDto>}
     * @memberof SingleStandKpiComparisonWellDto
     */
    detailsByDisplayOption?: Array<SingleStandKpiComparisonInfoDto> | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof SingleStandKpiComparisonWellDto
     */
    dataState: ResultDataState;
}

export function SingleStandKpiComparisonWellDtoFromJSON(json: any): SingleStandKpiComparisonWellDto {
    return SingleStandKpiComparisonWellDtoFromJSONTyped(json, false);
}

export function SingleStandKpiComparisonWellDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SingleStandKpiComparisonWellDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'wellId': json['WellId'],
        'isFocalWell': json['IsFocalWell'],
        'average': json['Average'],
        'operationCount': json['OperationCount'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'info': SingleStandKpiComparisonInfoDtoFromJSON(json['Info']),
        'detailsByDisplayOption': !exists(json, 'DetailsByDisplayOption') ? undefined : (json['DetailsByDisplayOption'] === null ? null : (json['DetailsByDisplayOption'] as Array<any>).map(SingleStandKpiComparisonInfoDtoFromJSON)),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function SingleStandKpiComparisonWellDtoToJSON(value?: SingleStandKpiComparisonWellDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'WellId': value.wellId,
        'IsFocalWell': value.isFocalWell,
        'Average': value.average,
        'OperationCount': value.operationCount,
        'LastUpdatedAt': DateDtoToJSON(value.lastUpdatedAt),
        'Info': SingleStandKpiComparisonInfoDtoToJSON(value.info),
        'DetailsByDisplayOption': value.detailsByDisplayOption === undefined ? undefined : (value.detailsByDisplayOption === null ? null : (value.detailsByDisplayOption as Array<any>).map(SingleStandKpiComparisonInfoDtoToJSON)),
        'DataState': ResultDataStateToJSON(value.dataState),
    };
}

