import { useRigScorecardCardFacts } from "hooks/useRigScorecardCardFacts";
import { useAppSelector } from "reducers/store";

export const useRigScores = ({ selectedWells }: {
  selectedWells: number[]
}) => {
  const exceptions = useAppSelector((state) => state.allRigs.exceptions);
  const { data: factSet } = useRigScorecardCardFacts(selectedWells, exceptions, {
    staleTime: Infinity,
    refetchOnMount: false,
  });

  return factSet;
};
