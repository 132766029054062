/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    GeneratorStatsFactDto,
    GeneratorStatsFactDtoFromJSON,
    GeneratorStatsFactDtoFromJSONTyped,
    GeneratorStatsFactDtoToJSON,
} from './GeneratorStatsFactDto';
import {
    GeneratorStatsSummaryDto,
    GeneratorStatsSummaryDtoFromJSON,
    GeneratorStatsSummaryDtoFromJSONTyped,
    GeneratorStatsSummaryDtoToJSON,
} from './GeneratorStatsSummaryDto';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    ServiceMessageDto,
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import {
    StopWatchDto,
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';

/**
 * 
 * @export
 * @interface GeneratorStatsResultDto
 */
export interface GeneratorStatsResultDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof GeneratorStatsResultDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof GeneratorStatsResultDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof GeneratorStatsResultDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof GeneratorStatsResultDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {Array<GeneratorStatsFactDto>}
     * @memberof GeneratorStatsResultDto
     */
    facts?: Array<GeneratorStatsFactDto> | null;
    /**
     * 
     * @type {GeneratorStatsSummaryDto}
     * @memberof GeneratorStatsResultDto
     */
    summary: GeneratorStatsSummaryDto;
    /**
     * 
     * @type {ResultDataState}
     * @memberof GeneratorStatsResultDto
     */
    dataState: ResultDataState;
}

export function GeneratorStatsResultDtoFromJSON(json: any): GeneratorStatsResultDto {
    return GeneratorStatsResultDtoFromJSONTyped(json, false);
}

export function GeneratorStatsResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneratorStatsResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': !exists(json, 'Messages') ? undefined : (json['Messages'] === null ? null : (json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'facts': !exists(json, 'Facts') ? undefined : (json['Facts'] === null ? null : (json['Facts'] as Array<any>).map(GeneratorStatsFactDtoFromJSON)),
        'summary': GeneratorStatsSummaryDtoFromJSON(json['Summary']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function GeneratorStatsResultDtoToJSON(value?: GeneratorStatsResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value.stopWatch),
        'Messages': value.messages === undefined ? undefined : (value.messages === null ? null : (value.messages as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value.userLensId,
        'LastUpdatedAt': DateDtoToJSON(value.lastUpdatedAt),
        'Facts': value.facts === undefined ? undefined : (value.facts === null ? null : (value.facts as Array<any>).map(GeneratorStatsFactDtoToJSON)),
        'Summary': GeneratorStatsSummaryDtoToJSON(value.summary),
        'DataState': ResultDataStateToJSON(value.dataState),
    };
}

