/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PivotOrderType,
    PivotOrderTypeFromJSON,
    PivotOrderTypeFromJSONTyped,
    PivotOrderTypeToJSON,
} from './PivotOrderType';
import {
    PivotType,
    PivotTypeFromJSON,
    PivotTypeFromJSONTyped,
    PivotTypeToJSON,
} from './PivotType';

/**
 * 
 * @export
 * @interface PivotTypeSortingOptionsDto
 */
export interface PivotTypeSortingOptionsDto {
    /**
     * 
     * @type {PivotType}
     * @memberof PivotTypeSortingOptionsDto
     */
    key: PivotType;
    /**
     * 
     * @type {Array<PivotOrderType>}
     * @memberof PivotTypeSortingOptionsDto
     */
    options: Array<PivotOrderType>;
}

export function PivotTypeSortingOptionsDtoFromJSON(json: any): PivotTypeSortingOptionsDto {
    return PivotTypeSortingOptionsDtoFromJSONTyped(json, false);
}

export function PivotTypeSortingOptionsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PivotTypeSortingOptionsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': PivotTypeFromJSON(json['Key']),
        'options': ((json['Options'] as Array<any>).map(PivotOrderTypeFromJSON)),
    };
}

export function PivotTypeSortingOptionsDtoToJSON(value?: PivotTypeSortingOptionsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Key': PivotTypeToJSON(value.key),
        'Options': ((value.options as Array<any>).map(PivotOrderTypeToJSON)),
    };
}

