import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { RigCardFactSetDto, RigFleetPerformanceFactQueryDto, WellStatusType } from "apis/oag";
import { DashboardType } from "apis/oag";
import { RigFleetPerformanceCardUserLensesApi } from "apis/oag";
import { useSelectedRigsContext } from "pages/AllRigs/RigList/SelectedRigsContext";
import { useAllRigOptions } from "pages/AllRigs/utils";
import { useMemo } from "react";
import { useAppSelector } from "reducers/store";
import type { IFiltersType } from "reducers/types";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import { RigTechnology } from "utils/enums";
import { dateToDateOnlyDto } from "utils/helper";
import type { PDRigFleetPerformanceFactQueryDtokey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

import { URL_STATE_PARAM, useStateQuery } from "./navigation/useQueryState";

const rigCardApi = new RigFleetPerformanceCardUserLensesApi(apiConfig);

export function useRigFleetPerformanceCardFacts(
  selectedTabId?: number[],
  options?: Omit<UseQueryOptions<RigCardFactSetDto>, "queryFn" | "queryKey">,
) {
  const allWellsIncluded = useAppSelector((state) => state.allRigs.allWellsIncluded);
  const operatorsState = useAppSelector((state) => state.allRigs.operators);
  const rigIdsState = useAppSelector((state) => state.allRigs.rigIds);
  const periodState = useAppSelector((state) => state.allRigs.period);
  const filterState = useAppSelector((state) => state.allRigs.filters);
  const exceptions = useAppSelector((state) => state.allRigs.exceptions);
  const formationIds = useAppSelector((state) => state.allRigs.formationIds);

  const { rigOptions } = useAllRigOptions();
  const { selectedRigIds } = useSelectedRigsContext();
  const [{ operationTime }] = useStateQuery<IFiltersType>(URL_STATE_PARAM.FILTERS_WIDGET, {
    sections: null,
    directionalIntervals: null,
    operationTime: null,
    holeSizes: null,
  });

  const requestQuery: RigFleetPerformanceFactQueryDto = useMemo(() => {
    return {
      highlightedRigIds: selectedRigIds,
      formationIds,
      selectedFilters: {
        sectionIds: filterState?.holeSection.map((hs) => +(hs.id || -1)),
        directionalIntervalIds: filterState?.directionalInterval.map((hs) => +(hs.id || -1)),
        holeSizeIds: filterState?.holeSize.map((hs) => +(hs.id || -1)),
        includeFlatTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Flat Time"),
        includeSlidingTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Sliding Time"),
        includeRotatingTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Rotating Time"),
        includeNullHoleDepth: true,
      },
      dashboard: DashboardType.RigFleetPerformance,
      operatorIds: operatorsState,
      rigIds: rigIdsState,
      from: dateToDateOnlyDto(periodState?.startDate || defaultDateDto.from.utc),
      to: dateToDateOnlyDto(periodState?.endDate || defaultDateDto.to.utc),
      includeFullWellFacts: allWellsIncluded,
      includeAlphaRigs: filterState?.technology.map((tech) => tech.id).includes(RigTechnology.Alpha) || false,
      includeNoneAlphaRigs: filterState?.technology.map((tech) => tech.id).includes(RigTechnology.NonAlpha) || false,
      selectedClasses: filterState?.type.map((t) => (t?.id || -1).toString()),
      selectedCountryIds: filterState?.country.map((c) => +(c?.id || -1)),
      selectedHorsePowers: filterState?.horsePower.map((hp) => +(hp?.id || -1)),
      selectedOperatingCenters: filterState?.operatingCenter.map((oc) => (oc?.id || -1).toString()),
      selectedWellStatusTypes: filterState?.wellStatus.map((ws) => ws.id) as WellStatusType[],
      pivot: rigOptions.pivot,
      useExceptions: exceptions,
      tabIds: selectedTabId,

      selectedWellIds: [], // will be ignored by the BE
    };
  }, [
    formationIds,
    allWellsIncluded,
    filterState?.country,
    filterState?.directionalInterval,
    filterState?.holeSection,
    filterState?.holeSize,
    filterState?.horsePower,
    filterState?.operatingCenter,
    filterState?.technology,
    filterState?.type,
    filterState?.wellStatus,
    operationTime,
    operatorsState,
    periodState.endDate,
    periodState.startDate,
    rigIdsState,
    selectedRigIds,
    rigOptions,
    exceptions,
    selectedTabId,
  ]);

  const RigsCardQueryKey: PDRigFleetPerformanceFactQueryDtokey = {
    uid: RequestUID.rigsCardFacts,
    type: PDQueryType.FACTS,
    params: requestQuery,
  };

  return useQuery<RigCardFactSetDto>({
    queryKey: [RigsCardQueryKey],
    queryFn: ({ signal }) => {
      return rigCardApi.apiRigFleetPerformanceCardUserLensesAllFactsPut(
        {
          rigFleetPerformanceFactQueryDto: requestQuery,
        },
        { signal },
      );
    },
    ...options,
    enabled: filterState !== null,
  });
}
