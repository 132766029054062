import { GridLayout, StyledCard } from "atoms/common";
import { Loader } from "components/Loader";
import { useRigSummaries } from "hooks/useRigSummaries";
import { Suspense, useLayoutEffect, useState } from "react";
import { useAppDispatch } from "reducers/store";
import { usePageView } from "services/Mixpanel";
import { zIndexLayer } from "utils/zIndex";

import { LeaderboardMetricSelectionContextProvider } from "./components/LeaderboardSection/LeaderboardMetricSelectionContext";
import LeaderboardSection from "./components/LeaderboardSection/LeaderboardSection";
import Header from "./Header";
import { RigList } from "./RigList/RigList";
import { SelectedRigsContextProvider } from "./RigList/SelectedRigsContext";
import { getAllRigsOptions, RigOptionsContext } from "./utils";

export const AllRigs = () => {
  usePageView("All Rigs");
  const { data: summaries } = useRigSummaries({ staleTime: Infinity });

  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    dispatch({
      type: "ALL_RIGS_FULL_WELLS_INCLUDED",
      payload: true,
    });
    dispatch({ type: "RESET_RIG_DASHBOARD" });
    dispatch({ type: "RESET_ALL_RIGS_FILTERS_90_DAYS" });
  }, [dispatch]);

  const [rigOptions, setRigOptions] = useState(getAllRigsOptions());

  return (
    <SelectedRigsContextProvider>
      <Header totalRigs={summaries?.length ?? 0} />
      <GridLayout>
        <StyledCard>
          <RigList />
        </StyledCard>
        <StyledCard>
          <Suspense fallback={<Loader centered zIndex={zIndexLayer.mars} position="absolute" />}>
            <LeaderboardMetricSelectionContextProvider>
              <RigOptionsContext.Provider value={[rigOptions, setRigOptions]}>
                <LeaderboardSection />
              </RigOptionsContext.Provider>
            </LeaderboardMetricSelectionContextProvider>
          </Suspense>
        </StyledCard>
      </GridLayout>
    </SelectedRigsContextProvider>
  );
};
