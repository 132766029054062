/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    WedgeValueDto,
    WedgeValueDtoFromJSON,
    WedgeValueDtoFromJSONTyped,
    WedgeValueDtoToJSON,
} from './WedgeValueDto';

/**
 * 
 * @export
 * @interface WedgeComparisonWellDto
 */
export interface WedgeComparisonWellDto {
    /**
     * 
     * @type {number}
     * @memberof WedgeComparisonWellDto
     */
    wellId: number;
    /**
     * 
     * @type {boolean}
     * @memberof WedgeComparisonWellDto
     */
    isFocalWell: boolean;
    /**
     * 
     * @type {number}
     * @memberof WedgeComparisonWellDto
     */
    aggregatedWellsCount: number;
    /**
     * 
     * @type {Array<WedgeValueDto>}
     * @memberof WedgeComparisonWellDto
     */
    values?: Array<WedgeValueDto> | null;
    /**
     * 
     * @type {number}
     * @memberof WedgeComparisonWellDto
     */
    total: number;
    /**
     * 
     * @type {DateDto}
     * @memberof WedgeComparisonWellDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {ResultDataState}
     * @memberof WedgeComparisonWellDto
     */
    dataState: ResultDataState;
}

export function WedgeComparisonWellDtoFromJSON(json: any): WedgeComparisonWellDto {
    return WedgeComparisonWellDtoFromJSONTyped(json, false);
}

export function WedgeComparisonWellDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WedgeComparisonWellDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'wellId': json['WellId'],
        'isFocalWell': json['IsFocalWell'],
        'aggregatedWellsCount': json['AggregatedWellsCount'],
        'values': !exists(json, 'Values') ? undefined : (json['Values'] === null ? null : (json['Values'] as Array<any>).map(WedgeValueDtoFromJSON)),
        'total': json['Total'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function WedgeComparisonWellDtoToJSON(value?: WedgeComparisonWellDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'WellId': value.wellId,
        'IsFocalWell': value.isFocalWell,
        'AggregatedWellsCount': value.aggregatedWellsCount,
        'Values': value.values === undefined ? undefined : (value.values === null ? null : (value.values as Array<any>).map(WedgeValueDtoToJSON)),
        'Total': value.total,
        'LastUpdatedAt': DateDtoToJSON(value.lastUpdatedAt),
        'DataState': ResultDataStateToJSON(value.dataState),
    };
}

