import { PivotType } from "apis/oag";
import { createContext, useContext } from "react";

export enum RigCardLayoutType {
  Split = "Split",
  BarsOnly = "BarsOnly",
  TableOny = "TableOny",
}
export interface IAllRigsOptions {
  pivot: PivotType;
  layoutType: RigCardLayoutType;
}

const ALL_RIGS_KEY = "allRigsOptions";

const defaultOptions = {
  pivot: PivotType.Rig,
  layoutType: RigCardLayoutType.Split,
};

const setAllRigsOptions = (value: IAllRigsOptions) => {
  localStorage.setItem(ALL_RIGS_KEY, JSON.stringify(value));
};

export const getAllRigsOptions = () => {
  const optionsString = localStorage.getItem(ALL_RIGS_KEY);
  return optionsString ? (JSON.parse(optionsString) as IAllRigsOptions) : defaultOptions;
};

export const RigOptionsContext = createContext<
  [IAllRigsOptions, React.Dispatch<React.SetStateAction<IAllRigsOptions>>] | undefined
>(undefined);

export function useAllRigOptions() {
  const contextValues = useContext(RigOptionsContext);

  if (contextValues === undefined) {
    throw new Error("useAllRigOptions must be within context of RigOptionsContext ");
  }

  const [rigOptions, setRigOptions] = contextValues;

  const setStateWrapper = (value: IAllRigsOptions) => {
    setAllRigsOptions(value);
    setRigOptions(value);
  };

  const setPivot = (pivot: PivotType) => {
    setStateWrapper({ ...rigOptions, pivot });
  };

  const setLayoutType = (layoutType: RigCardLayoutType) => {
    setStateWrapper({ ...rigOptions, layoutType });
  };

  return { rigOptions, setRigOptions, setPivot, setLayoutType };
}
