/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    DrillingStandDto,
    DrillingStandDtoFromJSON,
    DrillingStandDtoFromJSONTyped,
    DrillingStandDtoToJSON,
} from './DrillingStandDto';
import {
    StandKpiType,
    StandKpiTypeFromJSON,
    StandKpiTypeFromJSONTyped,
    StandKpiTypeToJSON,
} from './StandKpiType';

/**
 * 
 * @export
 * @interface RichStandKpiCommentInfoDto
 */
export interface RichStandKpiCommentInfoDto {
    /**
     * 
     * @type {number}
     * @memberof RichStandKpiCommentInfoDto
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof RichStandKpiCommentInfoDto
     */
    deletedAtUtc?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof RichStandKpiCommentInfoDto
     */
    wellId: number;
    /**
     * 
     * @type {string}
     * @memberof RichStandKpiCommentInfoDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RichStandKpiCommentInfoDto
     */
    description?: string | null;
    /**
     * 
     * @type {StandKpiType}
     * @memberof RichStandKpiCommentInfoDto
     */
    kpiType: StandKpiType;
    /**
     * 
     * @type {number}
     * @memberof RichStandKpiCommentInfoDto
     */
    standId: number;
    /**
     * 
     * @type {number}
     * @memberof RichStandKpiCommentInfoDto
     */
    startRangeStandId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RichStandKpiCommentInfoDto
     */
    endRangeStandId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RichStandKpiCommentInfoDto
     */
    authorId: number;
    /**
     * 
     * @type {string}
     * @memberof RichStandKpiCommentInfoDto
     */
    authorDisplayName?: string | null;
    /**
     * 
     * @type {DateDto}
     * @memberof RichStandKpiCommentInfoDto
     */
    createdAt: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof RichStandKpiCommentInfoDto
     */
    updatedAt: DateDto;
    /**
     * 
     * @type {DrillingStandDto}
     * @memberof RichStandKpiCommentInfoDto
     */
    drillingStand: DrillingStandDto;
    /**
     * 
     * @type {DrillingStandDto}
     * @memberof RichStandKpiCommentInfoDto
     */
    startRangeDrillingStand: DrillingStandDto;
    /**
     * 
     * @type {DrillingStandDto}
     * @memberof RichStandKpiCommentInfoDto
     */
    endRangeDrillingStand: DrillingStandDto;
}

export function RichStandKpiCommentInfoDtoFromJSON(json: any): RichStandKpiCommentInfoDto {
    return RichStandKpiCommentInfoDtoFromJSONTyped(json, false);
}

export function RichStandKpiCommentInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RichStandKpiCommentInfoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'deletedAtUtc': !exists(json, 'DeletedAtUtc') ? undefined : (json['DeletedAtUtc'] === null ? null : new Date(json['DeletedAtUtc'])),
        'wellId': json['WellId'],
        'title': !exists(json, 'Title') ? undefined : json['Title'],
        'description': !exists(json, 'Description') ? undefined : json['Description'],
        'kpiType': StandKpiTypeFromJSON(json['KpiType']),
        'standId': json['StandId'],
        'startRangeStandId': !exists(json, 'StartRangeStandId') ? undefined : json['StartRangeStandId'],
        'endRangeStandId': !exists(json, 'EndRangeStandId') ? undefined : json['EndRangeStandId'],
        'authorId': json['AuthorId'],
        'authorDisplayName': !exists(json, 'AuthorDisplayName') ? undefined : json['AuthorDisplayName'],
        'createdAt': DateDtoFromJSON(json['CreatedAt']),
        'updatedAt': DateDtoFromJSON(json['UpdatedAt']),
        'drillingStand': DrillingStandDtoFromJSON(json['DrillingStand']),
        'startRangeDrillingStand': DrillingStandDtoFromJSON(json['StartRangeDrillingStand']),
        'endRangeDrillingStand': DrillingStandDtoFromJSON(json['EndRangeDrillingStand']),
    };
}

export function RichStandKpiCommentInfoDtoToJSON(value?: RichStandKpiCommentInfoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'DeletedAtUtc': value.deletedAtUtc === undefined ? undefined : (value.deletedAtUtc === null ? null : value.deletedAtUtc.toISOString()),
        'WellId': value.wellId,
        'Title': value.title,
        'Description': value.description,
        'KpiType': StandKpiTypeToJSON(value.kpiType),
        'StandId': value.standId,
        'StartRangeStandId': value.startRangeStandId,
        'EndRangeStandId': value.endRangeStandId,
        'AuthorId': value.authorId,
        'AuthorDisplayName': value.authorDisplayName,
        'CreatedAt': DateDtoToJSON(value.createdAt),
        'UpdatedAt': DateDtoToJSON(value.updatedAt),
        'DrillingStand': DrillingStandDtoToJSON(value.drillingStand),
        'StartRangeDrillingStand': DrillingStandDtoToJSON(value.startRangeDrillingStand),
        'EndRangeDrillingStand': DrillingStandDtoToJSON(value.endRangeDrillingStand),
    };
}

