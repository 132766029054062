/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SystemOfMeasurementType,
    SystemOfMeasurementTypeFromJSON,
    SystemOfMeasurementTypeFromJSONTyped,
    SystemOfMeasurementTypeToJSON,
} from './SystemOfMeasurementType';

/**
 * 
 * @export
 * @interface TagBottomFingerprintUserLensTrackItemDto
 */
export interface TagBottomFingerprintUserLensTrackItemDto {
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintUserLensTrackItemDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintUserLensTrackItemDto
     */
    position: number;
    /**
     * 
     * @type {boolean}
     * @memberof TagBottomFingerprintUserLensTrackItemDto
     */
    isManualYaxis: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TagBottomFingerprintUserLensTrackItemDto
     */
    label: boolean;
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintUserLensTrackItemDto
     */
    yaxisStart?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintUserLensTrackItemDto
     */
    yaxisEnd?: number | null;
    /**
     * 
     * @type {SystemOfMeasurementType}
     * @memberof TagBottomFingerprintUserLensTrackItemDto
     */
    systemOfMeasurementType: SystemOfMeasurementType;
    /**
     * 
     * @type {boolean}
     * @memberof TagBottomFingerprintUserLensTrackItemDto
     */
    isUomUnlocked: boolean;
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintUserLensTrackItemDto
     */
    trackId: number;
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintUserLensTrackItemDto
     */
    userLensId: number;
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintUserLensTrackItemDto
     */
    positionDisplay?: number | null;
}

export function TagBottomFingerprintUserLensTrackItemDtoFromJSON(json: any): TagBottomFingerprintUserLensTrackItemDto {
    return TagBottomFingerprintUserLensTrackItemDtoFromJSONTyped(json, false);
}

export function TagBottomFingerprintUserLensTrackItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagBottomFingerprintUserLensTrackItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'position': json['Position'],
        'isManualYaxis': json['IsManualYaxis'],
        'label': json['Label'],
        'yaxisStart': !exists(json, 'YaxisStart') ? undefined : json['YaxisStart'],
        'yaxisEnd': !exists(json, 'YaxisEnd') ? undefined : json['YaxisEnd'],
        'systemOfMeasurementType': SystemOfMeasurementTypeFromJSON(json['SystemOfMeasurementType']),
        'isUomUnlocked': json['IsUomUnlocked'],
        'trackId': json['TrackId'],
        'userLensId': json['UserLensId'],
        'positionDisplay': !exists(json, 'PositionDisplay') ? undefined : json['PositionDisplay'],
    };
}

export function TagBottomFingerprintUserLensTrackItemDtoToJSON(value?: TagBottomFingerprintUserLensTrackItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'Position': value.position,
        'IsManualYaxis': value.isManualYaxis,
        'Label': value.label,
        'YaxisStart': value.yaxisStart,
        'YaxisEnd': value.yaxisEnd,
        'SystemOfMeasurementType': SystemOfMeasurementTypeToJSON(value.systemOfMeasurementType),
        'IsUomUnlocked': value.isUomUnlocked,
        'TrackId': value.trackId,
        'UserLensId': value.userLensId,
        'PositionDisplay': value.positionDisplay,
    };
}

