import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { ApiKpiTypeUserLensesIdSingleFactsPutRequest, SingleStandKpiDto } from "apis/oag";
import { DisplayOption, KpiTypeUserLensesApi } from "apis/oag";
import { initialZoomData } from "components/WellDashboard/ChartControls/index";
import { getAxisBreak } from "hooks/lens-facts/utils";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useSelectedWell } from "hooks/useSelectedWell";
import { useAppDispatch, useAppSelector } from "reducers/store";
import type { IFiltersType, IZoomData } from "reducers/types";
import { singleWellDisplayOptions } from "reducers/widgetReducer";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto, TVD_SAMPLE_COUNT } from "utils/common";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const api = new KpiTypeUserLensesApi(apiConfig);

export function useKpiTypeFacts(lensId: number, options?: UseQueryOptions<SingleStandKpiDto>) {
  const wellDataID = useSelectedWell();
  const dispatch = useAppDispatch();

  const [
    { sections: sectionIds, operationTime, directionalIntervals: directionalIntervalIds, holeSizes: holeSizeIds },
  ] = useStateQuery<IFiltersType>(URL_STATE_PARAM.FILTERS_WIDGET, {
    sections: null,
    directionalIntervals: null,
    operationTime: null,
    holeSizes: null,
  });
  const [zoomState] = useStateQuery<IZoomData>(URL_STATE_PARAM.ZOOM_WIDGET, initialZoomData);

  const {
    ts_start: zoomStartDuration,
    ts_end: zoomEndDuration,
    depth_start: zoomStartDepth,
    depth_end: zoomEndDepth,
  } = zoomState ?? initialZoomData;
  const displayOption = useAppSelector((state) => state.widgetOptions.display_options);

  const requestParameters: ApiKpiTypeUserLensesIdSingleFactsPutRequest = {
    id: lensId ?? -1,
    baseDisplayOptionUserLensQueryDto: {
      selectedFilters: {
        sectionIds,
        directionalIntervalIds,
        holeSizeIds,
        includeFlatTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Flat Time"),
        includeSlidingTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Sliding Time"),
        includeRotatingTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Rotating Time"),
        includeNullHoleDepth: true,
      },
      zoomStartDepth,
      zoomEndDepth,
      zoomStartDuration,
      zoomEndDuration,
      wellId: wellDataID,
      displayOption: singleWellDisplayOptions.includes(displayOption) ? displayOption : DisplayOption.StandDepth,
      sampleCount: TVD_SAMPLE_COUNT,
      useMockData: false,
      comparisonWellIds: null,

      from: defaultDateDto.from,
      to: defaultDateDto.to,
    },
  };

  const queryKey: PDLensFactsQueryKey<ApiKpiTypeUserLensesIdSingleFactsPutRequest> = {
    type: PDQueryType.FACTS,
    uid: RequestUID.kpiTypeFacts,
    params: requestParameters,
    lensId,
    comparisonType: "single",
  };
  const result = useQuery<SingleStandKpiDto>({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      api.apiKpiTypeUserLensesIdSingleFactsPut(requestParameters, { signal }).then((data) => {
        if (data?.lastUpdatedAt) {
          dispatch({
            type: "SET_PER_STAND_REFRESH_DATE",
            payload: { [wellDataID]: data?.lastUpdatedAt },
          });
        }
        return data;
      }),
    ...options,
  });
  const facts = result.data;
  if (!facts) return result;
  const axisBreak = getAxisBreak(
    displayOption === DisplayOption.StandDepth
      ? facts.detailsByStandDepth?.map((i) => i.value ?? 0) ?? []
      : facts.detailsByDateShift?.flatMap((i) => [i.dayValue ?? 0, i.nightValue ?? 0]) ?? [],
  );
  return { ...result, data: { ...facts, axisBreak } };
}

export type KpiTypeFactsData = ReturnType<typeof useKpiTypeFacts>["data"];
