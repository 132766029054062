import { useIsFetching } from "@tanstack/react-query";
import type { FiltersDto } from "apis/oag";
import { Button } from "atoms/Form";
import { PDComponent } from "components/PDComponents";
import { filtersDtoToFiltersType, initialFiltersState } from "components/RigDashboard/ControlHeader";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useDashboardType } from "hooks/useDashboardType";
import { useEffectExceptOnMount } from "hooks/useEffectExceptOnMount";
import { useFiltersLoading } from "hooks/useFiltersLoading";
import { useTimelineEventsList } from "hooks/useTimelineEventsList";
import { xor } from "lodash";
import isEqual from "lodash/isEqual";
import { Suspense, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "reducers/store";
import type { IFiltersType } from "reducers/types";
import { Track } from "services/Mixpanel";
import { Popover, Tooltip } from "utils/componentLibrary";
import { PDQueryType } from "utils/queryNamespaces";

import Filters from ".";

export default function FiltersButton() {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const { isEvergreen } = useDashboardType();
  const isFetchingWellOverview = useIsFetching({ queryKey: [{ type: PDQueryType.WELL_OVERVIEW }], exact: false });
  const [filtersState, setFilters] = useStateQuery<IFiltersType>(
    URL_STATE_PARAM.FILTERS_DASHBOARD,
    {
      operationTime: null,
      sections: null,
      directionalIntervals: null,
      holeSizes: null,
    },
    [URL_STATE_PARAM.FILTERS_WIDGET],
  );

  const { filtersLoading, availableFilters, directionalIntervals, holeSections, holeSizes } = useFiltersLoading();

  const { actualEvents, planEvents } = useAppSelector((state) => state.timeline.events);
  const { planTimelineEventsList, actualTimelineEventsList } = useTimelineEventsList({ actualEvents, planEvents });
  const disableFiltering = useMemo(
    () => (actualTimelineEventsList?.length ?? 0) === 0 && (planTimelineEventsList?.length ?? 0) === 0,
    [actualTimelineEventsList?.length, planTimelineEventsList?.length],
  );

  const filtersActiveState =
    !isEqual(filtersState, {
      operationTime: null,
      sections: null,
      directionalIntervals: null,
      holeSizes: null,
    }) &&
    !(
      availableFilters &&
      filtersState &&
      xor(filtersState.directionalIntervals, availableFilters.directionalIntervalIds).length === 0 &&
      xor(filtersState.holeSizes, availableFilters.holeSizeIds).length === 0 &&
      xor(filtersState.sections, availableFilters.sectionIds).length === 0 &&
      (filtersState?.operationTime ?? []).includes("Flat Time") === availableFilters.includeFlatTime &&
      (filtersState?.operationTime ?? []).includes("Sliding Time") === availableFilters.includeSlidingTime &&
      (filtersState?.operationTime ?? []).includes("Rotating Time") === availableFilters.includeRotatingTime
    );

  const [previousAvailableFilters, setPreviousAvailableFilters] = useState<FiltersDto | null>(null);

  useEffect(() => {
    if (!availableFilters) return;
    if (previousAvailableFilters === null || isEqual(initialFiltersState, filtersState)) {
      setPreviousAvailableFilters(availableFilters);
      return;
    }
    const isSame = isEqual(availableFilters, previousAvailableFilters);
    if (isSame) return;
    const newFilterValues = filtersState;
    type FilterValuesKeys = keyof IFiltersType;
    const optionsPreviousAvailableFilter = previousAvailableFilters
      ? filtersDtoToFiltersType(previousAvailableFilters)
      : initialFiltersState;
    const newFilterOptions = filtersDtoToFiltersType(availableFilters);
    for (const untypedKey of Object.keys(initialFiltersState)) {
      const key = untypedKey as FilterValuesKeys;
      const filterValues = filtersState[key];
      const optionsCurrentFilter = optionsPreviousAvailableFilter[key] ?? [];
      const optionsNewFilter = newFilterOptions[key] ?? [];

      const newOptions = optionsNewFilter.filter((e) => {
        return !(optionsCurrentFilter as Array<typeof e>).includes(e);
      });
      const currentFiltersByKey = newFilterValues[key] ?? [];
      const savedFiltersValues = filterValues === null ? null : [...filterValues, ...newOptions];
      (newFilterValues[key] as typeof currentFiltersByKey) = savedFiltersValues as typeof currentFiltersByKey;
    }

    setPreviousAvailableFilters(availableFilters);
    setFilters(newFilterValues);
    // we want to run this effect just based on the changes in available filters
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableFilters]);
  useEffectExceptOnMount(() => {
    Track.interact("Well Dashboard - Filters", {
      Enabled: filtersActiveState,
    });
  }, [filtersActiveState]);

  return (
    <Tooltip title="Filter">
      <Popover
        content={
          <Suspense fallback={null}>
            {!filtersLoading && (
              <Filters
                appliedState={filtersState}
                availableFilters={availableFilters}
                hiddenFilters={isEvergreen ? ["directionalIntervals", "holeSizes", "sections"] : []}
                directionalIntervals={directionalIntervals}
                holeSections={holeSections}
                holeSizes={holeSizes}
                onCancel={() => setIsFiltersOpen(false)}
                setFilterData={setFilters}
                visible={isFiltersOpen}
              />
            )}
          </Suspense>
        }
        trigger={["click", "scroll"]}
        placement="bottomRight"
        open={isFiltersOpen}
        onOpenChange={(e) => setIsFiltersOpen(e)}
        destroyTooltipOnHide
      >
        <Button
          size="large"
          disabled={isFetchingWellOverview !== 0 || disableFiltering}
          icon={<PDComponent.SvgIcon name="filter" />}
          onClick={() => setIsFiltersOpen((fl) => !fl)}
          $engaged={isFiltersOpen}
          loading={filtersLoading}
          type={filtersActiveState ? "primary" : "default"}
          ghost={filtersActiveState}
        />
      </Popover>
    </Tooltip>
  );
}
