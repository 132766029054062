/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StandKpiType,
    StandKpiTypeFromJSON,
    StandKpiTypeFromJSONTyped,
    StandKpiTypeToJSON,
} from './StandKpiType';

/**
 * 
 * @export
 * @interface StandKpiSliceDto
 */
export interface StandKpiSliceDto {
    /**
     * 
     * @type {number}
     * @memberof StandKpiSliceDto
     */
    distribution: number;
    /**
     * 
     * @type {number}
     * @memberof StandKpiSliceDto
     */
    average: number;
    /**
     * 
     * @type {number}
     * @memberof StandKpiSliceDto
     */
    position: number;
    /**
     * 
     * @type {StandKpiType}
     * @memberof StandKpiSliceDto
     */
    kpiTypeId: StandKpiType;
    /**
     * 
     * @type {number}
     * @memberof StandKpiSliceDto
     */
    targetValue?: number | null;
}

export function StandKpiSliceDtoFromJSON(json: any): StandKpiSliceDto {
    return StandKpiSliceDtoFromJSONTyped(json, false);
}

export function StandKpiSliceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandKpiSliceDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'distribution': json['Distribution'],
        'average': json['Average'],
        'position': json['Position'],
        'kpiTypeId': StandKpiTypeFromJSON(json['KpiTypeId']),
        'targetValue': !exists(json, 'TargetValue') ? undefined : json['TargetValue'],
    };
}

export function StandKpiSliceDtoToJSON(value?: StandKpiSliceDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Distribution': value.distribution,
        'Average': value.average,
        'Position': value.position,
        'KpiTypeId': StandKpiTypeToJSON(value.kpiTypeId),
        'TargetValue': value.targetValue,
    };
}

