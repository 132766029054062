/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ReportGenerationParametersDto,
    ReportGenerationParametersDtoFromJSON,
    ReportGenerationParametersDtoToJSON,
    ReportStatusDto,
    ReportStatusDtoFromJSON,
    ReportStatusDtoToJSON,
} from '../models';

export interface ApiReportsIdDownloadGetRequest {
    id: number;
}

export interface ApiReportsIdPayloadGetRequest {
    id: number;
}

export interface ApiReportsIdRequeuePostRequest {
    id: number;
}

export interface ApiReportsIdStatusGetRequest {
    id: number;
}

export interface ApiReportsQueuePostRequest {
    reportGenerationParametersDto?: ReportGenerationParametersDto;
}

/**
 * 
 */
export class ReportsApi extends runtime.BaseAPI {

    /**
     */
    async apiReportsIdDownloadGetRaw(requestParameters: ApiReportsIdDownloadGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiReportsIdDownloadGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Reports/{id}/Download`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiReportsIdDownloadGet(requestParameters: ApiReportsIdDownloadGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Blob> {
        const response = await this.apiReportsIdDownloadGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiReportsIdPayloadGetRaw(requestParameters: ApiReportsIdPayloadGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiReportsIdPayloadGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Reports/{id}/Payload`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiReportsIdPayloadGet(requestParameters: ApiReportsIdPayloadGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<string> {
        const response = await this.apiReportsIdPayloadGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiReportsIdRequeuePostRaw(requestParameters: ApiReportsIdRequeuePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiReportsIdRequeuePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Reports/{id}/Requeue`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiReportsIdRequeuePost(requestParameters: ApiReportsIdRequeuePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<number> {
        const response = await this.apiReportsIdRequeuePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiReportsIdStatusGetRaw(requestParameters: ApiReportsIdStatusGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ReportStatusDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiReportsIdStatusGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Reports/{id}/Status`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiReportsIdStatusGet(requestParameters: ApiReportsIdStatusGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ReportStatusDto> {
        const response = await this.apiReportsIdStatusGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiReportsQueuePostRaw(requestParameters: ApiReportsQueuePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Reports/Queue`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportGenerationParametersDtoToJSON(requestParameters.reportGenerationParametersDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiReportsQueuePost(requestParameters: ApiReportsQueuePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<number> {
        const response = await this.apiReportsQueuePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
