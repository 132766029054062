import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type {
  ApiParameterByDepthUserLensesAvailableFiltersPutRequest,
  ApiWedgeUserLensesAvailableFiltersPutRequest,
  ApiWellsAvailableFiltersPutRequest,
  BaseFocalQueryDto,
  FiltersDto,
} from "apis/oag";
import { ParameterByDepthUserLensesApi, WedgeUserLensesApi, WellsApi } from "apis/oag";
import { useDashboardType } from "hooks/useDashboardType";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "reducers/store";
import type { IFiltersType, IZoomData } from "reducers/types";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto, TVD_SAMPLE_COUNT } from "utils/common";
import type { PDLensFiltersQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

import { URL_STATE_PARAM, useStateQuery } from "./navigation/useQueryState";
import type { LensTemplate } from "./useLensTemplates";
import { isParameterByDepthTemplate, isWedgeTemplate } from "./useLensTemplates";
import { useSelectedWell } from "./useSelectedWell";

const wellsApi = new WellsApi(apiConfig);
const wedgeLensApi = new WedgeUserLensesApi(apiConfig);
const parameterLensApi = new ParameterByDepthUserLensesApi(apiConfig);

export function useAvailableFilters(
  template: LensTemplate | null,
  zoomState: IZoomData,
  filterState: IFiltersType,
  options?: UseQueryOptions<FiltersDto>,
) {
  const location = useLocation();

  const { dashboardType } = useDashboardType();

  let [offsetWells] = useStateQuery<Array<number>>(
    location.pathname.includes("rig") ? URL_STATE_PARAM.OFFSET_WELLS_RIGS_WIDGET : URL_STATE_PARAM.OFFSET_WIDGET,
    [],
  );
  const selectedOffsetWellsForReport = useAppSelector((state) => state.report.offsetWells);

  if (offsetWells.length === 0 && selectedOffsetWellsForReport.length > 0) {
    offsetWells = selectedOffsetWellsForReport;
  }

  const wellDataId = useSelectedWell();

  const requestParameters: ApiWellsAvailableFiltersPutRequest = useMemo(() => {
    const { sections, directionalIntervals, operationTime, holeSizes } = filterState;
    const baseFocalQueryDto: BaseFocalQueryDto = {
      wellId: wellDataId || -1,
      sampleCount: TVD_SAMPLE_COUNT,
      selectedFilters: {
        sectionIds: sections,
        directionalIntervalIds: directionalIntervals,
        holeSizeIds: holeSizes,
        includeFlatTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Flat Time"),
        includeSlidingTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Sliding Time"),
        includeRotatingTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Rotating Time"),
        includeNullHoleDepth: true,
      },
      comparisonWellIds: offsetWells,
      zoomStartDepth: zoomState?.depth_start,
      zoomEndDepth: zoomState?.depth_end,
      zoomStartDuration: zoomState?.ts_start,
      zoomEndDuration: zoomState?.ts_end,
      useMockData: false,
      from: defaultDateDto.from,
      to: defaultDateDto.to,
    };

    return {
      id: wellDataId,
      baseFocalQueryDto,
    };
  }, [filterState, offsetWells, zoomState, wellDataId]);

  const queryKey: PDLensFiltersQueryKey<ApiWellsAvailableFiltersPutRequest> = {
    type: PDQueryType.LENS_FILTERS,
    uid: RequestUID.availableFilters,
    dashboardType,
    params: requestParameters,
    offsetWells,
  };

  // TODO #querykey #understand why this query gets repeated on lens navigation
  const queryOptions: UseQueryOptions<FiltersDto> = {
    queryKey: [queryKey],
    queryFn: () => {
      // No need to call the API for Single and Stacked lenses
      if (template && isWedgeTemplate(template)) {
        const reqParams: ApiWedgeUserLensesAvailableFiltersPutRequest = {
          wedgeUserLensQueryDto: {
            ...requestParameters.baseFocalQueryDto,
            useMockData: false,
            sampleCount: TVD_SAMPLE_COUNT,
            comparisonWellIds: null,
            selectedFilters: {
              includeFlatTime: true,
              includeRotatingTime: true,
              includeSlidingTime: true,
              includeNullHoleDepth: true,
            },
            zoomStartDuration: null,
            zoomEndDuration: null,
            zoomStartDepth: null,
            zoomEndDepth: null,
            from: defaultDateDto.from,
            to: defaultDateDto.to,
            wellId: wellDataId || -1,
            wellAggregationThreshold: 6,
          },
        };

        return wedgeLensApi.apiWedgeUserLensesAvailableFiltersPut(reqParams);
      } else if (template && isParameterByDepthTemplate(template)) {
        const reqParams: ApiParameterByDepthUserLensesAvailableFiltersPutRequest = {
          byDepthUserLensQueryDto: {
            ...requestParameters.baseFocalQueryDto,
            useMockData: false,
            sampleCount: TVD_SAMPLE_COUNT,
            comparisonWellIds: null,
            selectedFilters: {
              includeFlatTime: true,
              includeRotatingTime: true,
              includeSlidingTime: true,
              includeNullHoleDepth: true,
            },
            zoomStartDuration: null,
            zoomEndDuration: null,
            zoomStartDepth: null,
            zoomEndDepth: null,
            from: defaultDateDto.from,
            to: defaultDateDto.to,
            wellId: wellDataId || -1,
            wellAggregationThreshold: 6,
          },
        };

        return parameterLensApi.apiParameterByDepthUserLensesAvailableFiltersPut(reqParams);
      } else {
        return wellsApi.apiWellsAvailableFiltersPut(requestParameters);
      }
    },
    ...options,
    enabled: options?.enabled && !!wellDataId,
  };
  return useQuery<FiltersDto>(queryOptions);
}
