/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DashboardType,
    DashboardTypeFromJSON,
    DashboardTypeFromJSONTyped,
    DashboardTypeToJSON,
} from './DashboardType';
import {
    LensTemplateType,
    LensTemplateTypeFromJSON,
    LensTemplateTypeFromJSONTyped,
    LensTemplateTypeToJSON,
} from './LensTemplateType';
import {
    StackingType,
    StackingTypeFromJSON,
    StackingTypeFromJSONTyped,
    StackingTypeToJSON,
} from './StackingType';
import {
    StandKpiGroup,
    StandKpiGroupFromJSON,
    StandKpiGroupFromJSONTyped,
    StandKpiGroupToJSON,
} from './StandKpiGroup';

/**
 * 
 * @export
 * @interface PivotKpiGroupLensTemplateDto
 */
export interface PivotKpiGroupLensTemplateDto {
    /**
     * 
     * @type {number}
     * @memberof PivotKpiGroupLensTemplateDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PivotKpiGroupLensTemplateDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PivotKpiGroupLensTemplateDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PivotKpiGroupLensTemplateDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {LensTemplateType}
     * @memberof PivotKpiGroupLensTemplateDto
     */
    type: LensTemplateType;
    /**
     * 
     * @type {DashboardType}
     * @memberof PivotKpiGroupLensTemplateDto
     */
    targetDashboardType: DashboardType;
    /**
     * 
     * @type {number}
     * @memberof PivotKpiGroupLensTemplateDto
     */
    nbCondensedDataPoints: number;
    /**
     * 
     * @type {boolean}
     * @memberof PivotKpiGroupLensTemplateDto
     */
    useBitDepth: boolean;
    /**
     * 
     * @type {number}
     * @memberof PivotKpiGroupLensTemplateDto
     */
    position: number;
    /**
     * 
     * @type {boolean}
     * @memberof PivotKpiGroupLensTemplateDto
     */
    canSelectTimeUnit: boolean;
    /**
     * 
     * @type {Array<StandKpiGroup>}
     * @memberof PivotKpiGroupLensTemplateDto
     */
    selectableKpiGroupIds?: Array<StandKpiGroup> | null;
    /**
     * 
     * @type {StackingType}
     * @memberof PivotKpiGroupLensTemplateDto
     */
    defaultStackingType: StackingType;
}

export function PivotKpiGroupLensTemplateDtoFromJSON(json: any): PivotKpiGroupLensTemplateDto {
    return PivotKpiGroupLensTemplateDtoFromJSONTyped(json, false);
}

export function PivotKpiGroupLensTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PivotKpiGroupLensTemplateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': !exists(json, 'Name') ? undefined : json['Name'],
        'description': !exists(json, 'Description') ? undefined : json['Description'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'type': LensTemplateTypeFromJSON(json['Type']),
        'targetDashboardType': DashboardTypeFromJSON(json['TargetDashboardType']),
        'nbCondensedDataPoints': json['NbCondensedDataPoints'],
        'useBitDepth': json['UseBitDepth'],
        'position': json['Position'],
        'canSelectTimeUnit': json['CanSelectTimeUnit'],
        'selectableKpiGroupIds': !exists(json, 'SelectableKpiGroupIds') ? undefined : (json['SelectableKpiGroupIds'] === null ? null : (json['SelectableKpiGroupIds'] as Array<any>).map(StandKpiGroupFromJSON)),
        'defaultStackingType': StackingTypeFromJSON(json['DefaultStackingType']),
    };
}

export function PivotKpiGroupLensTemplateDtoToJSON(value?: PivotKpiGroupLensTemplateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'Name': value.name,
        'Description': value.description,
        'IsAvailableOnReports': value.isAvailableOnReports,
        'Type': LensTemplateTypeToJSON(value.type),
        'TargetDashboardType': DashboardTypeToJSON(value.targetDashboardType),
        'NbCondensedDataPoints': value.nbCondensedDataPoints,
        'UseBitDepth': value.useBitDepth,
        'Position': value.position,
        'CanSelectTimeUnit': value.canSelectTimeUnit,
        'SelectableKpiGroupIds': value.selectableKpiGroupIds === undefined ? undefined : (value.selectableKpiGroupIds === null ? null : (value.selectableKpiGroupIds as Array<any>).map(StandKpiGroupToJSON)),
        'DefaultStackingType': StackingTypeToJSON(value.defaultStackingType),
    };
}

