/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TorqueAndDragType,
    TorqueAndDragTypeFromJSON,
    TorqueAndDragTypeFromJSONTyped,
    TorqueAndDragTypeToJSON,
} from './TorqueAndDragType';

/**
 * 
 * @export
 * @interface BroomstickDto
 */
export interface BroomstickDto {
    /**
     * 
     * @type {number}
     * @memberof BroomstickDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof BroomstickDto
     */
    externalId: number;
    /**
     * 
     * @type {number}
     * @memberof BroomstickDto
     */
    frictionFactor: number;
    /**
     * 
     * @type {TorqueAndDragType}
     * @memberof BroomstickDto
     */
    type: TorqueAndDragType;
    /**
     * 
     * @type {string}
     * @memberof BroomstickDto
     */
    section?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BroomstickDto
     */
    activity?: string | null;
}

export function BroomstickDtoFromJSON(json: any): BroomstickDto {
    return BroomstickDtoFromJSONTyped(json, false);
}

export function BroomstickDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BroomstickDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'externalId': json['ExternalId'],
        'frictionFactor': json['FrictionFactor'],
        'type': TorqueAndDragTypeFromJSON(json['Type']),
        'section': !exists(json, 'Section') ? undefined : json['Section'],
        'activity': !exists(json, 'Activity') ? undefined : json['Activity'],
    };
}

export function BroomstickDtoToJSON(value?: BroomstickDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'ExternalId': value.externalId,
        'FrictionFactor': value.frictionFactor,
        'Type': TorqueAndDragTypeToJSON(value.type),
        'Section': value.section,
        'Activity': value.activity,
    };
}

