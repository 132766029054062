/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScorecardExceptionStatusType,
    ScorecardExceptionStatusTypeFromJSON,
    ScorecardExceptionStatusTypeFromJSONTyped,
    ScorecardExceptionStatusTypeToJSON,
} from './ScorecardExceptionStatusType';

/**
 * 
 * @export
 * @interface RigLeaderboardScoreCardCommentAco
 */
export interface RigLeaderboardScoreCardCommentAco {
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    authorDisplayName: string | null;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    exceptionApproverName: string | null;
    /**
     * 
     * @type {ScorecardExceptionStatusType}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    exceptionStatus: ScorecardExceptionStatusType;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    exceptionTimeHrs: number | null;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    kpiGroupName: string;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    kpiSubgroupName: string;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    kpiName: string;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    kpiDescription: string;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    wellName: string;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    curatedUidWellEdr: string;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    factScorecardCommentsKey: number;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    primaryCategoryClassification: string;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    secondaryCategoryClassification: string;
}

export function RigLeaderboardScoreCardCommentAcoFromJSON(json: any): RigLeaderboardScoreCardCommentAco {
    return RigLeaderboardScoreCardCommentAcoFromJSONTyped(json, false);
}

export function RigLeaderboardScoreCardCommentAcoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigLeaderboardScoreCardCommentAco {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authorDisplayName': json['AuthorDisplayName'],
        'exceptionApproverName': json['ExceptionApproverName'],
        'exceptionStatus': ScorecardExceptionStatusTypeFromJSON(json['ExceptionStatus']),
        'exceptionTimeHrs': json['ExceptionTimeHrs'],
        'kpiGroupName': json['KpiGroupName'],
        'kpiSubgroupName': json['KpiSubgroupName'],
        'kpiName': json['KpiName'],
        'kpiDescription': json['KpiDescription'],
        'wellName': json['WellName'],
        'curatedUidWellEdr': json['CuratedUidWellEdr'],
        'description': json['Description'],
        'factScorecardCommentsKey': json['FactScorecardCommentsKey'],
        'primaryCategoryClassification': json['PrimaryCategoryClassification'],
        'secondaryCategoryClassification': json['SecondaryCategoryClassification'],
    };
}

export function RigLeaderboardScoreCardCommentAcoToJSON(value?: RigLeaderboardScoreCardCommentAco | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AuthorDisplayName': value.authorDisplayName,
        'ExceptionApproverName': value.exceptionApproverName,
        'ExceptionStatus': ScorecardExceptionStatusTypeToJSON(value.exceptionStatus),
        'ExceptionTimeHrs': value.exceptionTimeHrs,
        'KpiGroupName': value.kpiGroupName,
        'KpiSubgroupName': value.kpiSubgroupName,
        'KpiName': value.kpiName,
        'KpiDescription': value.kpiDescription,
        'WellName': value.wellName,
        'CuratedUidWellEdr': value.curatedUidWellEdr,
        'Description': value.description,
        'FactScorecardCommentsKey': value.factScorecardCommentsKey,
        'PrimaryCategoryClassification': value.primaryCategoryClassification,
        'SecondaryCategoryClassification': value.secondaryCategoryClassification,
    };
}

