/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DashboardType,
    DashboardTypeFromJSON,
    DashboardTypeFromJSONTyped,
    DashboardTypeToJSON,
} from './DashboardType';
import {
    LensTemplateType,
    LensTemplateTypeFromJSON,
    LensTemplateTypeFromJSONTyped,
    LensTemplateTypeToJSON,
} from './LensTemplateType';

/**
 * 
 * @export
 * @interface DrillingProductivityLensTemplateDto
 */
export interface DrillingProductivityLensTemplateDto {
    /**
     * 
     * @type {number}
     * @memberof DrillingProductivityLensTemplateDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof DrillingProductivityLensTemplateDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DrillingProductivityLensTemplateDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DrillingProductivityLensTemplateDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {LensTemplateType}
     * @memberof DrillingProductivityLensTemplateDto
     */
    type: LensTemplateType;
    /**
     * 
     * @type {DashboardType}
     * @memberof DrillingProductivityLensTemplateDto
     */
    targetDashboardType: DashboardType;
    /**
     * 
     * @type {number}
     * @memberof DrillingProductivityLensTemplateDto
     */
    nbCondensedDataPoints: number;
    /**
     * 
     * @type {boolean}
     * @memberof DrillingProductivityLensTemplateDto
     */
    useBitDepth: boolean;
    /**
     * 
     * @type {number}
     * @memberof DrillingProductivityLensTemplateDto
     */
    position: number;
    /**
     * 
     * @type {boolean}
     * @memberof DrillingProductivityLensTemplateDto
     */
    canSelectTimeUnit: boolean;
}

export function DrillingProductivityLensTemplateDtoFromJSON(json: any): DrillingProductivityLensTemplateDto {
    return DrillingProductivityLensTemplateDtoFromJSONTyped(json, false);
}

export function DrillingProductivityLensTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrillingProductivityLensTemplateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': !exists(json, 'Name') ? undefined : json['Name'],
        'description': !exists(json, 'Description') ? undefined : json['Description'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'type': LensTemplateTypeFromJSON(json['Type']),
        'targetDashboardType': DashboardTypeFromJSON(json['TargetDashboardType']),
        'nbCondensedDataPoints': json['NbCondensedDataPoints'],
        'useBitDepth': json['UseBitDepth'],
        'position': json['Position'],
        'canSelectTimeUnit': json['CanSelectTimeUnit'],
    };
}

export function DrillingProductivityLensTemplateDtoToJSON(value?: DrillingProductivityLensTemplateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'Name': value.name,
        'Description': value.description,
        'IsAvailableOnReports': value.isAvailableOnReports,
        'Type': LensTemplateTypeToJSON(value.type),
        'TargetDashboardType': DashboardTypeToJSON(value.targetDashboardType),
        'NbCondensedDataPoints': value.nbCondensedDataPoints,
        'UseBitDepth': value.useBitDepth,
        'Position': value.position,
        'CanSelectTimeUnit': value.canSelectTimeUnit,
    };
}

