import styled from "styled-components";
import { zIndexLayer } from "utils/zIndex";

export const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.themeStyle.colors.alt_quaterniary_bg};
  padding: 16px 32px;
  position: fixed;
  width: 100%;
  z-index: ${zIndexLayer.sky};
  box-shadow: 0 1px 3px rgb(0 0 0 / 0.2);
`;

export const PaddedContainer = styled.div`
  padding-right: 16px;
`;
