/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LensTabWithVisibilityDto,
    LensTabWithVisibilityDtoFromJSON,
    LensTabWithVisibilityDtoFromJSONTyped,
    LensTabWithVisibilityDtoToJSON,
} from './LensTabWithVisibilityDto';
import {
    ZoomType,
    ZoomTypeFromJSON,
    ZoomTypeFromJSONTyped,
    ZoomTypeToJSON,
} from './ZoomType';

/**
 * 
 * @export
 * @interface DashboardDto
 */
export interface DashboardDto {
    /**
     * 
     * @type {Array<LensTabWithVisibilityDto>}
     * @memberof DashboardDto
     */
    personalTabs: Array<LensTabWithVisibilityDto>;
    /**
     * 
     * @type {Array<LensTabWithVisibilityDto>}
     * @memberof DashboardDto
     */
    operatorTabs: Array<LensTabWithVisibilityDto>;
    /**
     * 
     * @type {ZoomType}
     * @memberof DashboardDto
     */
    defaultZoom: ZoomType;
}

export function DashboardDtoFromJSON(json: any): DashboardDto {
    return DashboardDtoFromJSONTyped(json, false);
}

export function DashboardDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'personalTabs': ((json['PersonalTabs'] as Array<any>).map(LensTabWithVisibilityDtoFromJSON)),
        'operatorTabs': ((json['OperatorTabs'] as Array<any>).map(LensTabWithVisibilityDtoFromJSON)),
        'defaultZoom': ZoomTypeFromJSON(json['DefaultZoom']),
    };
}

export function DashboardDtoToJSON(value?: DashboardDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PersonalTabs': ((value.personalTabs as Array<any>).map(LensTabWithVisibilityDtoToJSON)),
        'OperatorTabs': ((value.operatorTabs as Array<any>).map(LensTabWithVisibilityDtoToJSON)),
        'DefaultZoom': ZoomTypeToJSON(value.defaultZoom),
    };
}

