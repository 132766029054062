/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    FiltersDto,
    FiltersDtoFromJSON,
    FiltersDtoFromJSONTyped,
    FiltersDtoToJSON,
} from './FiltersDto';
import {
    ResultDataState,
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import {
    ServiceMessageDto,
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import {
    StackedStandKpiComparisonWellDto,
    StackedStandKpiComparisonWellDtoFromJSON,
    StackedStandKpiComparisonWellDtoFromJSONTyped,
    StackedStandKpiComparisonWellDtoToJSON,
} from './StackedStandKpiComparisonWellDto';
import {
    StandGroupSummaryDto,
    StandGroupSummaryDtoFromJSON,
    StandGroupSummaryDtoFromJSONTyped,
    StandGroupSummaryDtoToJSON,
} from './StandGroupSummaryDto';
import {
    StopWatchDto,
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';

/**
 * 
 * @export
 * @interface StackedStandKpiComparisonDto
 */
export interface StackedStandKpiComparisonDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof StackedStandKpiComparisonDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof StackedStandKpiComparisonDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof StackedStandKpiComparisonDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof StackedStandKpiComparisonDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {FiltersDto}
     * @memberof StackedStandKpiComparisonDto
     */
    availableFilters: FiltersDto;
    /**
     * 
     * @type {Array<StackedStandKpiComparisonWellDto>}
     * @memberof StackedStandKpiComparisonDto
     */
    comparisons: Array<StackedStandKpiComparisonWellDto>;
    /**
     * 
     * @type {Array<StandGroupSummaryDto>}
     * @memberof StackedStandKpiComparisonDto
     */
    summaryByDisplayOption?: Array<StandGroupSummaryDto> | null;
    /**
     * 
     * @type {Array<StandGroupSummaryDto>}
     * @memberof StackedStandKpiComparisonDto
     */
    summaryByKpi?: Array<StandGroupSummaryDto> | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof StackedStandKpiComparisonDto
     */
    dataState: ResultDataState;
}

export function StackedStandKpiComparisonDtoFromJSON(json: any): StackedStandKpiComparisonDto {
    return StackedStandKpiComparisonDtoFromJSONTyped(json, false);
}

export function StackedStandKpiComparisonDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StackedStandKpiComparisonDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': !exists(json, 'Messages') ? undefined : (json['Messages'] === null ? null : (json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'availableFilters': FiltersDtoFromJSON(json['AvailableFilters']),
        'comparisons': ((json['Comparisons'] as Array<any>).map(StackedStandKpiComparisonWellDtoFromJSON)),
        'summaryByDisplayOption': !exists(json, 'SummaryByDisplayOption') ? undefined : (json['SummaryByDisplayOption'] === null ? null : (json['SummaryByDisplayOption'] as Array<any>).map(StandGroupSummaryDtoFromJSON)),
        'summaryByKpi': !exists(json, 'SummaryByKpi') ? undefined : (json['SummaryByKpi'] === null ? null : (json['SummaryByKpi'] as Array<any>).map(StandGroupSummaryDtoFromJSON)),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function StackedStandKpiComparisonDtoToJSON(value?: StackedStandKpiComparisonDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value.stopWatch),
        'Messages': value.messages === undefined ? undefined : (value.messages === null ? null : (value.messages as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value.userLensId,
        'LastUpdatedAt': DateDtoToJSON(value.lastUpdatedAt),
        'AvailableFilters': FiltersDtoToJSON(value.availableFilters),
        'Comparisons': ((value.comparisons as Array<any>).map(StackedStandKpiComparisonWellDtoToJSON)),
        'SummaryByDisplayOption': value.summaryByDisplayOption === undefined ? undefined : (value.summaryByDisplayOption === null ? null : (value.summaryByDisplayOption as Array<any>).map(StandGroupSummaryDtoToJSON)),
        'SummaryByKpi': value.summaryByKpi === undefined ? undefined : (value.summaryByKpi === null ? null : (value.summaryByKpi as Array<any>).map(StandGroupSummaryDtoToJSON)),
        'DataState': ResultDataStateToJSON(value.dataState),
    };
}

