/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RigPowerDistributionBarSliceDto,
    RigPowerDistributionBarSliceDtoFromJSON,
    RigPowerDistributionBarSliceDtoFromJSONTyped,
    RigPowerDistributionBarSliceDtoToJSON,
} from './RigPowerDistributionBarSliceDto';

/**
 * 
 * @export
 * @interface RigPowerDistributionBarDto
 */
export interface RigPowerDistributionBarDto {
    /**
     * 
     * @type {number}
     * @memberof RigPowerDistributionBarDto
     */
    powerFrom: number;
    /**
     * 
     * @type {number}
     * @memberof RigPowerDistributionBarDto
     */
    powerTo: number;
    /**
     * 
     * @type {number}
     * @memberof RigPowerDistributionBarDto
     */
    readonly totalActiveSeconds: number;
    /**
     * 
     * @type {Array<RigPowerDistributionBarSliceDto>}
     * @memberof RigPowerDistributionBarDto
     */
    slices: Array<RigPowerDistributionBarSliceDto>;
}

export function RigPowerDistributionBarDtoFromJSON(json: any): RigPowerDistributionBarDto {
    return RigPowerDistributionBarDtoFromJSONTyped(json, false);
}

export function RigPowerDistributionBarDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigPowerDistributionBarDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'powerFrom': json['PowerFrom'],
        'powerTo': json['PowerTo'],
        'totalActiveSeconds': json['TotalActiveSeconds'],
        'slices': ((json['Slices'] as Array<any>).map(RigPowerDistributionBarSliceDtoFromJSON)),
    };
}

export function RigPowerDistributionBarDtoToJSON(value?: RigPowerDistributionBarDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PowerFrom': value.powerFrom,
        'PowerTo': value.powerTo,
        'Slices': ((value.slices as Array<any>).map(RigPowerDistributionBarSliceDtoToJSON)),
    };
}

