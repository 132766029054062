import type { Dispatch, SetStateAction } from "react";
import { createContext, useContext, useState } from "react";

interface SelectedRigsContextValue {
  selectedRigIds: number[];
  setSelectedRigIds: Dispatch<SetStateAction<number[]>>;
  isDataAnonymity: boolean;
  setIsDataAnonymity: Dispatch<SetStateAction<boolean>>;
}

export const SelectedRigsContext = createContext<SelectedRigsContextValue | undefined>(undefined);

export const useSelectedRigsContext = () => {
  const context = useContext(SelectedRigsContext);
  if (context === undefined) {
    throw new Error("useSelectedRigsContext must be used within a SelectedRigsContextProvider");
  }
  return context;
};

export const SelectedRigsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedRigIds, setSelectedRigIds] = useState<number[]>([]);
  const [isDataAnonymity, setIsDataAnonymity] = useState(false);

  return (
    <SelectedRigsContext.Provider value={{ selectedRigIds, setSelectedRigIds, isDataAnonymity, setIsDataAnonymity }}>
      {children}
    </SelectedRigsContext.Provider>
  );
};
