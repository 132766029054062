/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BaseFuelQueryDto,
    BaseFuelQueryDtoFromJSON,
    BaseFuelQueryDtoToJSON,
    FuelConsumptionByGeneratorResultDto,
    FuelConsumptionByGeneratorResultDtoFromJSON,
    FuelConsumptionByGeneratorResultDtoToJSON,
    FuelConsumptionByGeneratorUserLensDto,
    FuelConsumptionByGeneratorUserLensDtoFromJSON,
    FuelConsumptionByGeneratorUserLensDtoToJSON,
} from '../models';

export interface ApiFuelConsumptionByGeneratorUserLensesIdFactsPutRequest {
    id: number;
    baseFuelQueryDto?: BaseFuelQueryDto;
}

export interface ApiFuelConsumptionByGeneratorUserLensesIdPutRequest {
    id: number;
    fuelConsumptionByGeneratorUserLensDto?: FuelConsumptionByGeneratorUserLensDto;
}

export interface ApiFuelConsumptionByGeneratorUserLensesPostRequest {
    fuelConsumptionByGeneratorUserLensDto?: FuelConsumptionByGeneratorUserLensDto;
}

/**
 * 
 */
export class FuelConsumptionByGeneratorUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiFuelConsumptionByGeneratorUserLensesIdFactsPutRaw(requestParameters: ApiFuelConsumptionByGeneratorUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FuelConsumptionByGeneratorResultDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFuelConsumptionByGeneratorUserLensesIdFactsPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/FuelConsumptionByGeneratorUserLenses/{id}/Facts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BaseFuelQueryDtoToJSON(requestParameters.baseFuelQueryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FuelConsumptionByGeneratorResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFuelConsumptionByGeneratorUserLensesIdFactsPut(requestParameters: ApiFuelConsumptionByGeneratorUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FuelConsumptionByGeneratorResultDto> {
        const response = await this.apiFuelConsumptionByGeneratorUserLensesIdFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFuelConsumptionByGeneratorUserLensesIdPutRaw(requestParameters: ApiFuelConsumptionByGeneratorUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FuelConsumptionByGeneratorUserLensDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFuelConsumptionByGeneratorUserLensesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/FuelConsumptionByGeneratorUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FuelConsumptionByGeneratorUserLensDtoToJSON(requestParameters.fuelConsumptionByGeneratorUserLensDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FuelConsumptionByGeneratorUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFuelConsumptionByGeneratorUserLensesIdPut(requestParameters: ApiFuelConsumptionByGeneratorUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FuelConsumptionByGeneratorUserLensDto> {
        const response = await this.apiFuelConsumptionByGeneratorUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFuelConsumptionByGeneratorUserLensesPostRaw(requestParameters: ApiFuelConsumptionByGeneratorUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FuelConsumptionByGeneratorUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/FuelConsumptionByGeneratorUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FuelConsumptionByGeneratorUserLensDtoToJSON(requestParameters.fuelConsumptionByGeneratorUserLensDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FuelConsumptionByGeneratorUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFuelConsumptionByGeneratorUserLensesPost(requestParameters: ApiFuelConsumptionByGeneratorUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FuelConsumptionByGeneratorUserLensDto> {
        const response = await this.apiFuelConsumptionByGeneratorUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
