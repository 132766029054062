/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ParameterHeatmapTrackUserLensDto,
    ParameterHeatmapTrackUserLensDtoFromJSON,
    ParameterHeatmapTrackUserLensDtoFromJSONTyped,
    ParameterHeatmapTrackUserLensDtoToJSON,
} from './ParameterHeatmapTrackUserLensDto';

/**
 * 
 * @export
 * @interface ParameterHeatmapUserLensDto
 */
export interface ParameterHeatmapUserLensDto {
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensDto
     */
    lensTabId: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensDto
     */
    lensTemplateId: number;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterHeatmapUserLensDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterHeatmapUserLensDto
     */
    label: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterHeatmapUserLensDto
     */
    isSystem: boolean;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensDto
     */
    xTrackId: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensDto
     */
    yTrackId: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensDto
     */
    zTrackId: number;
    /**
     * 
     * @type {ParameterHeatmapTrackUserLensDto}
     * @memberof ParameterHeatmapUserLensDto
     */
    xTrack: ParameterHeatmapTrackUserLensDto;
    /**
     * 
     * @type {ParameterHeatmapTrackUserLensDto}
     * @memberof ParameterHeatmapUserLensDto
     */
    yTrack: ParameterHeatmapTrackUserLensDto;
    /**
     * 
     * @type {ParameterHeatmapTrackUserLensDto}
     * @memberof ParameterHeatmapUserLensDto
     */
    zTrack: ParameterHeatmapTrackUserLensDto;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterHeatmapUserLensDto
     */
    showActiveBin: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterHeatmapUserLensDto
     */
    showFocalRecommendation: boolean;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensDto
     */
    focalRecommendationDepthRange: number;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterHeatmapUserLensDto
     */
    showOffsetRecommendation: boolean;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensDto
     */
    offsetRecommendationDepthRange: number;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterHeatmapUserLensDto
     */
    showOffsetData: boolean;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensDto
     */
    binDistanceThreshold: number;
}

export function ParameterHeatmapUserLensDtoFromJSON(json: any): ParameterHeatmapUserLensDto {
    return ParameterHeatmapUserLensDtoFromJSONTyped(json, false);
}

export function ParameterHeatmapUserLensDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterHeatmapUserLensDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'lensTabId': json['LensTabId'],
        'lensTemplateId': json['LensTemplateId'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'label': json['Label'],
        'isSystem': json['IsSystem'],
        'xTrackId': json['XTrackId'],
        'yTrackId': json['YTrackId'],
        'zTrackId': json['ZTrackId'],
        'xTrack': ParameterHeatmapTrackUserLensDtoFromJSON(json['XTrack']),
        'yTrack': ParameterHeatmapTrackUserLensDtoFromJSON(json['YTrack']),
        'zTrack': ParameterHeatmapTrackUserLensDtoFromJSON(json['ZTrack']),
        'showActiveBin': json['ShowActiveBin'],
        'showFocalRecommendation': json['ShowFocalRecommendation'],
        'focalRecommendationDepthRange': json['FocalRecommendationDepthRange'],
        'showOffsetRecommendation': json['ShowOffsetRecommendation'],
        'offsetRecommendationDepthRange': json['OffsetRecommendationDepthRange'],
        'showOffsetData': json['ShowOffsetData'],
        'binDistanceThreshold': json['BinDistanceThreshold'],
    };
}

export function ParameterHeatmapUserLensDtoToJSON(value?: ParameterHeatmapUserLensDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'LensTabId': value.lensTabId,
        'LensTemplateId': value.lensTemplateId,
        'IsAvailableOnReports': value.isAvailableOnReports,
        'Label': value.label,
        'IsSystem': value.isSystem,
        'XTrackId': value.xTrackId,
        'YTrackId': value.yTrackId,
        'ZTrackId': value.zTrackId,
        'XTrack': ParameterHeatmapTrackUserLensDtoToJSON(value.xTrack),
        'YTrack': ParameterHeatmapTrackUserLensDtoToJSON(value.yTrack),
        'ZTrack': ParameterHeatmapTrackUserLensDtoToJSON(value.zTrack),
        'ShowActiveBin': value.showActiveBin,
        'ShowFocalRecommendation': value.showFocalRecommendation,
        'FocalRecommendationDepthRange': value.focalRecommendationDepthRange,
        'ShowOffsetRecommendation': value.showOffsetRecommendation,
        'OffsetRecommendationDepthRange': value.offsetRecommendationDepthRange,
        'ShowOffsetData': value.showOffsetData,
        'BinDistanceThreshold': value.binDistanceThreshold,
    };
}

