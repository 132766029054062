/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ClaraEndpointType,
    ClaraEndpointTypeFromJSON,
    ClaraEndpointTypeToJSON,
    DateDto,
    DateDtoFromJSON,
    DateDtoToJSON,
    SystemOfMeasurementType,
    SystemOfMeasurementTypeFromJSON,
    SystemOfMeasurementTypeToJSON,
    UiThemeType,
    UiThemeTypeFromJSON,
    UiThemeTypeToJSON,
    UserClaimDto,
    UserClaimDtoFromJSON,
    UserClaimDtoToJSON,
    UserClaimsRequestDto,
    UserClaimsRequestDtoFromJSON,
    UserClaimsRequestDtoToJSON,
    UserDto,
    UserDtoFromJSON,
    UserDtoToJSON,
    UserQueryDto,
    UserQueryDtoFromJSON,
    UserQueryDtoToJSON,
    UserRigAccessDto,
    UserRigAccessDtoFromJSON,
    UserRigAccessDtoToJSON,
    UserRigAccessSetDto,
    UserRigAccessSetDtoFromJSON,
    UserRigAccessSetDtoToJSON,
    UserStatusType,
    UserStatusTypeFromJSON,
    UserStatusTypeToJSON,
    UserWithAccessCountDto,
    UserWithAccessCountDtoFromJSON,
    UserWithAccessCountDtoToJSON,
    WellAccessQueryDto,
    WellAccessQueryDtoFromJSON,
    WellAccessQueryDtoToJSON,
} from '../models';

export interface ApiUsersEmailWelcomeLinkGetRequest {
    userId?: number;
}

export interface ApiUsersEmailWelcomeResendGetRequest {
    userId?: number;
}

export interface ApiUsersIdAccessInfoGetRequest {
    id: number;
}

export interface ApiUsersIdClaimsGetRequest {
    id: number;
}

export interface ApiUsersIdClaimsPutRequest {
    id: number;
    userClaimsRequestDto?: UserClaimsRequestDto;
}

export interface ApiUsersIdDeleteRequest {
    id: number;
}

export interface ApiUsersIdGetRequest {
    id: number;
}

export interface ApiUsersIdPutRequest {
    id: number;
    userDto?: UserDto;
}

export interface ApiUsersIdStatusPutRequest {
    id: number;
    body?: string;
}

export interface ApiUsersIdWellAccessPutRequest {
    id: number;
    wellAccessQueryDto?: WellAccessQueryDto;
}

export interface ApiUsersMeApproversWellIdGetRequest {
    wellId: number;
}

export interface ApiUsersMeCheckinCurrentWeekReferenceDateGetRequest {
    referenceDate: Date;
}

export interface ApiUsersMeCheckinPutRequest {
    dateDto?: DateDto;
}

export interface ApiUsersMeClaraEndpointEndpointPutRequest {
    endpoint: ClaraEndpointType;
}

export interface ApiUsersMeDemoModeIsInDemoModePutRequest {
    isInDemoMode: boolean;
}

export interface ApiUsersMeProfileProfileIdPutRequest {
    profileId: number;
}

export interface ApiUsersMePutRequest {
    userDto?: UserDto;
}

export interface ApiUsersMeSystemOfMeasurementPutRequest {
    body?: string;
}

export interface ApiUsersMeThemePutRequest {
    body?: string;
}

export interface ApiUsersPostRequest {
    userDto?: UserDto;
}

export interface ApiUsersPutRequest {
    userQueryDto?: UserQueryDto;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     */
    async apiUsersEmailWelcomeLinkGetRaw(requestParameters: ApiUsersEmailWelcomeLinkGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Users/Email/Welcome/Link`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiUsersEmailWelcomeLinkGet(requestParameters: ApiUsersEmailWelcomeLinkGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<string> {
        const response = await this.apiUsersEmailWelcomeLinkGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersEmailWelcomeResendGetRaw(requestParameters: ApiUsersEmailWelcomeResendGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Users/Email/Welcome/Resend`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiUsersEmailWelcomeResendGet(requestParameters: ApiUsersEmailWelcomeResendGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<string> {
        const response = await this.apiUsersEmailWelcomeResendGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<UserDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserDtoFromJSON));
    }

    /**
     */
    async apiUsersGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<UserDto>> {
        const response = await this.apiUsersGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersIdAccessInfoGetRaw(requestParameters: ApiUsersIdAccessInfoGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserWithAccessCountDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUsersIdAccessInfoGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Users/{id}/AccessInfo`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserWithAccessCountDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiUsersIdAccessInfoGet(requestParameters: ApiUsersIdAccessInfoGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserWithAccessCountDto> {
        const response = await this.apiUsersIdAccessInfoGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersIdClaimsGetRaw(requestParameters: ApiUsersIdClaimsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<UserClaimDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUsersIdClaimsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Users/{id}/Claims`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserClaimDtoFromJSON));
    }

    /**
     */
    async apiUsersIdClaimsGet(requestParameters: ApiUsersIdClaimsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<UserClaimDto>> {
        const response = await this.apiUsersIdClaimsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersIdClaimsPutRaw(requestParameters: ApiUsersIdClaimsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<UserClaimDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUsersIdClaimsPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Users/{id}/Claims`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserClaimsRequestDtoToJSON(requestParameters.userClaimsRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserClaimDtoFromJSON));
    }

    /**
     */
    async apiUsersIdClaimsPut(requestParameters: ApiUsersIdClaimsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<UserClaimDto>> {
        const response = await this.apiUsersIdClaimsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersIdDeleteRaw(requestParameters: ApiUsersIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUsersIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUsersIdDelete(requestParameters: ApiUsersIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.apiUsersIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiUsersIdGetRaw(requestParameters: ApiUsersIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUsersIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiUsersIdGet(requestParameters: ApiUsersIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserDto> {
        const response = await this.apiUsersIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersIdPutRaw(requestParameters: ApiUsersIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUsersIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserDtoToJSON(requestParameters.userDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiUsersIdPut(requestParameters: ApiUsersIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserDto> {
        const response = await this.apiUsersIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersIdStatusPutRaw(requestParameters: ApiUsersIdStatusPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUsersIdStatusPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Users/{id}/Status`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiUsersIdStatusPut(requestParameters: ApiUsersIdStatusPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.apiUsersIdStatusPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersIdWellAccessPutRaw(requestParameters: ApiUsersIdWellAccessPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserRigAccessSetDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUsersIdWellAccessPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Users/{id}/WellAccess`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WellAccessQueryDtoToJSON(requestParameters.wellAccessQueryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserRigAccessSetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiUsersIdWellAccessPut(requestParameters: ApiUsersIdWellAccessPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserRigAccessSetDto> {
        const response = await this.apiUsersIdWellAccessPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersMeApproversWellIdGetRaw(requestParameters: ApiUsersMeApproversWellIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<UserDto>>> {
        if (requestParameters.wellId === null || requestParameters.wellId === undefined) {
            throw new runtime.RequiredError('wellId','Required parameter requestParameters.wellId was null or undefined when calling apiUsersMeApproversWellIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Users/Me/Approvers/{wellId}`.replace(`{${"wellId"}}`, encodeURIComponent(String(requestParameters.wellId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserDtoFromJSON));
    }

    /**
     */
    async apiUsersMeApproversWellIdGet(requestParameters: ApiUsersMeApproversWellIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<UserDto>> {
        const response = await this.apiUsersMeApproversWellIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersMeCheckinCurrentWeekReferenceDateGetRaw(requestParameters: ApiUsersMeCheckinCurrentWeekReferenceDateGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<DateDto>>> {
        if (requestParameters.referenceDate === null || requestParameters.referenceDate === undefined) {
            throw new runtime.RequiredError('referenceDate','Required parameter requestParameters.referenceDate was null or undefined when calling apiUsersMeCheckinCurrentWeekReferenceDateGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Users/Me/Checkin/CurrentWeek/{referenceDate}`.replace(`{${"referenceDate"}}`, encodeURIComponent(String(requestParameters.referenceDate))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DateDtoFromJSON));
    }

    /**
     */
    async apiUsersMeCheckinCurrentWeekReferenceDateGet(requestParameters: ApiUsersMeCheckinCurrentWeekReferenceDateGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<DateDto>> {
        const response = await this.apiUsersMeCheckinCurrentWeekReferenceDateGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersMeCheckinPutRaw(requestParameters: ApiUsersMeCheckinPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Users/Me/Checkin`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DateDtoToJSON(requestParameters.dateDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiUsersMeCheckinPut(requestParameters: ApiUsersMeCheckinPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.apiUsersMeCheckinPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersMeClaraEndpointEndpointPutRaw(requestParameters: ApiUsersMeClaraEndpointEndpointPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.endpoint === null || requestParameters.endpoint === undefined) {
            throw new runtime.RequiredError('endpoint','Required parameter requestParameters.endpoint was null or undefined when calling apiUsersMeClaraEndpointEndpointPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Users/Me/Clara/Endpoint/{endpoint}`.replace(`{${"endpoint"}}`, encodeURIComponent(String(requestParameters.endpoint))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiUsersMeClaraEndpointEndpointPut(requestParameters: ApiUsersMeClaraEndpointEndpointPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.apiUsersMeClaraEndpointEndpointPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersMeDemoModeIsInDemoModePutRaw(requestParameters: ApiUsersMeDemoModeIsInDemoModePutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.isInDemoMode === null || requestParameters.isInDemoMode === undefined) {
            throw new runtime.RequiredError('isInDemoMode','Required parameter requestParameters.isInDemoMode was null or undefined when calling apiUsersMeDemoModeIsInDemoModePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Users/Me/DemoMode/{isInDemoMode}`.replace(`{${"isInDemoMode"}}`, encodeURIComponent(String(requestParameters.isInDemoMode))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiUsersMeDemoModeIsInDemoModePut(requestParameters: ApiUsersMeDemoModeIsInDemoModePutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.apiUsersMeDemoModeIsInDemoModePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersMeGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Users/Me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiUsersMeGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserDto> {
        const response = await this.apiUsersMeGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersMeProfileProfileIdPutRaw(requestParameters: ApiUsersMeProfileProfileIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.profileId === null || requestParameters.profileId === undefined) {
            throw new runtime.RequiredError('profileId','Required parameter requestParameters.profileId was null or undefined when calling apiUsersMeProfileProfileIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Users/Me/Profile/{profileId}`.replace(`{${"profileId"}}`, encodeURIComponent(String(requestParameters.profileId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiUsersMeProfileProfileIdPut(requestParameters: ApiUsersMeProfileProfileIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.apiUsersMeProfileProfileIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersMePutRaw(requestParameters: ApiUsersMePutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Users/Me`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserDtoToJSON(requestParameters.userDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiUsersMePut(requestParameters: ApiUsersMePutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserDto> {
        const response = await this.apiUsersMePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersMeSystemOfMeasurementPutRaw(requestParameters: ApiUsersMeSystemOfMeasurementPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Users/Me/SystemOfMeasurement`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiUsersMeSystemOfMeasurementPut(requestParameters: ApiUsersMeSystemOfMeasurementPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.apiUsersMeSystemOfMeasurementPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersMeThemePutRaw(requestParameters: ApiUsersMeThemePutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Users/Me/Theme`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiUsersMeThemePut(requestParameters: ApiUsersMeThemePutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.apiUsersMeThemePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersMeWellAccessGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<UserRigAccessDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Users/me/WellAccess`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserRigAccessDtoFromJSON));
    }

    /**
     */
    async apiUsersMeWellAccessGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<UserRigAccessDto>> {
        const response = await this.apiUsersMeWellAccessGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersPostRaw(requestParameters: ApiUsersPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserDtoToJSON(requestParameters.userDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiUsersPost(requestParameters: ApiUsersPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserDto> {
        const response = await this.apiUsersPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersPutRaw(requestParameters: ApiUsersPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<UserWithAccessCountDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Users`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserQueryDtoToJSON(requestParameters.userQueryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserWithAccessCountDtoFromJSON));
    }

    /**
     */
    async apiUsersPut(requestParameters: ApiUsersPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<UserWithAccessCountDto>> {
        const response = await this.apiUsersPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
