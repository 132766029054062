/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    WellStatusType,
    WellStatusTypeFromJSON,
    WellStatusTypeFromJSONTyped,
    WellStatusTypeToJSON,
} from './WellStatusType';

/**
 * 
 * @export
 * @interface AllWellsDataFeedQueryDto
 */
export interface AllWellsDataFeedQueryDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof AllWellsDataFeedQueryDto
     */
    formationIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AllWellsDataFeedQueryDto
     */
    operatorIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AllWellsDataFeedQueryDto
     */
    rigIds?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof AllWellsDataFeedQueryDto
     */
    search?: string | null;
    /**
     * 
     * @type {Array<WellStatusType>}
     * @memberof AllWellsDataFeedQueryDto
     */
    status?: Array<WellStatusType> | null;
    /**
     * 
     * @type {DateDto}
     * @memberof AllWellsDataFeedQueryDto
     */
    from: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof AllWellsDataFeedQueryDto
     */
    to: DateDto;
    /**
     * 
     * @type {boolean}
     * @memberof AllWellsDataFeedQueryDto
     */
    includeEverGreenOnly: boolean;
    /**
     * 
     * @type {DateDto}
     * @memberof AllWellsDataFeedQueryDto
     */
    lastWellRefreshDate: DateDto;
}

export function AllWellsDataFeedQueryDtoFromJSON(json: any): AllWellsDataFeedQueryDto {
    return AllWellsDataFeedQueryDtoFromJSONTyped(json, false);
}

export function AllWellsDataFeedQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllWellsDataFeedQueryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'formationIds': !exists(json, 'FormationIds') ? undefined : json['FormationIds'],
        'operatorIds': !exists(json, 'OperatorIds') ? undefined : json['OperatorIds'],
        'rigIds': !exists(json, 'RigIds') ? undefined : json['RigIds'],
        'search': !exists(json, 'Search') ? undefined : json['Search'],
        'status': !exists(json, 'Status') ? undefined : (json['Status'] === null ? null : (json['Status'] as Array<any>).map(WellStatusTypeFromJSON)),
        'from': DateDtoFromJSON(json['From']),
        'to': DateDtoFromJSON(json['To']),
        'includeEverGreenOnly': json['IncludeEverGreenOnly'],
        'lastWellRefreshDate': DateDtoFromJSON(json['LastWellRefreshDate']),
    };
}

export function AllWellsDataFeedQueryDtoToJSON(value?: AllWellsDataFeedQueryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'FormationIds': value.formationIds,
        'OperatorIds': value.operatorIds,
        'RigIds': value.rigIds,
        'Search': value.search,
        'Status': value.status === undefined ? undefined : (value.status === null ? null : (value.status as Array<any>).map(WellStatusTypeToJSON)),
        'From': DateDtoToJSON(value.from),
        'To': DateDtoToJSON(value.to),
        'IncludeEverGreenOnly': value.includeEverGreenOnly,
        'LastWellRefreshDate': DateDtoToJSON(value.lastWellRefreshDate),
    };
}

