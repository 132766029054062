/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ServiceMessageType,
    ServiceMessageTypeFromJSON,
    ServiceMessageTypeFromJSONTyped,
    ServiceMessageTypeToJSON,
} from './ServiceMessageType';

/**
 * 
 * @export
 * @interface ServiceMessageDto
 */
export interface ServiceMessageDto {
    /**
     * 
     * @type {string}
     * @memberof ServiceMessageDto
     */
    text?: string | null;
    /**
     * 
     * @type {ServiceMessageType}
     * @memberof ServiceMessageDto
     */
    type: ServiceMessageType;
}

export function ServiceMessageDtoFromJSON(json: any): ServiceMessageDto {
    return ServiceMessageDtoFromJSONTyped(json, false);
}

export function ServiceMessageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceMessageDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'text': !exists(json, 'Text') ? undefined : json['Text'],
        'type': ServiceMessageTypeFromJSON(json['Type']),
    };
}

export function ServiceMessageDtoToJSON(value?: ServiceMessageDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Text': value.text,
        'Type': ServiceMessageTypeToJSON(value.type),
    };
}

