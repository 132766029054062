import CoreLayout, { Section, SectionLayout } from "components/Layout";
import { MatrixAvailability } from "components/Lenses/ContainerLens/common/utils/constants";
import { Timeline } from "components/Timeline";
import { TvDChart } from "components/TvDChart";
import Stats from "components/TvDChart/components/Stats";
import ChartControls, { initialZoomData } from "components/WellDashboard/ChartControls";
import ControlHeader from "components/WellDashboard/ControlHeader";
import Zoom from "components/WellDashboard/ControlHeader/atoms/Zoom";
import { DrillingDetailsHeader } from "components/WellDashboard/DrillingDetailsHeader";
import { ZeroStateTimeline } from "components/WellDashboard/ZeroStates";
import { URL_STATE_PARAM, useResetQueryState, useStateQuery } from "hooks/navigation/useQueryState";
import type { FC } from "react";
import { Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import type { TimelineStates } from "reducers/reportReducer";
import { useAppDispatch } from "reducers/store";
import { useAppSelector } from "reducers/store";
import { type IFiltersType, initialFilters, type IZoomData } from "reducers/types";
import { Track } from "services/Mixpanel";
import { PLAN_SERIES_ID } from "utils/common";
import { Col, Row } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";
import { zIndexLayer } from "utils/zIndex";

import { LensView } from "./LensView";
import { StyledTabs } from "./style";
import type { ITimelineInfo } from "./types";

const Main: FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams<{ wellId: string }>();
  const wellId = useMemo(() => Number(params.wellId), [params]);
  const [zoomOpen, setZoomOpen] = useState<boolean>(false);
  const [timelineOverride, setTimelineOverride] = useState<ITimelineInfo | null>(null);

  const selectedWell = useAppSelector((state) => state.state.selectedWell);

  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const loadingTvD = false;

  const [resetQ] = useResetQueryState([
    [URL_STATE_PARAM.ZOOM_WIDGET, URL_STATE_PARAM.ZOOM_WELL],
    [URL_STATE_PARAM.FILTERS_WIDGET, URL_STATE_PARAM.FILTERS_DASHBOARD],
    [URL_STATE_PARAM.OFFSET_WIDGET, URL_STATE_PARAM.OFFSET_WELL],
  ]);
  const [filters] = useStateQuery<IFiltersType>(URL_STATE_PARAM.FILTERS_DASHBOARD, initialFilters);
  const [offsetWells] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WELL, []);

  const [zoom] = useStateQuery<IZoomData>(URL_STATE_PARAM.ZOOM_WELL, initialZoomData);
  const [zoomData, setZoom] = useStateQuery<IZoomData>(URL_STATE_PARAM.ZOOM_WELL, initialZoomData, [
    URL_STATE_PARAM.ZOOM_WIDGET,
  ]);
  useEffect(() => {
    resetQ();
    dispatch({
      type: "SET_OFFSET_WELLS_WIDGET",
      payload: {
        wells: offsetWells,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const timelineState = useAppSelector((state) => state.state.timeline_state);

  useEffect(() => {
    dispatch({
      type: "SET_ZOOM_WIDGET",
      payload: {
        zoom,
        state: true,
      },
    });
    if (zoom)
      dispatch({
        type: "SET_REPORT_ZOOM",
        payload: { zoom, state: true },
      });
  }, [zoom, dispatch]);

  useEffect(() => {
    dispatch({
      type: "SET_OFFSET_WELLS_WIDGET",
      payload: {
        wells: offsetWells,
      },
    });
    dispatch({
      type: "SET_REPORT_OFFSET_WELLS",
      payload: {
        wells: offsetWells,
      },
    });
  }, [dispatch, offsetWells]);
  useEffect(() => {
    if (selectedWell !== wellId) {
      // useMemo gives a different reference even if the value is the same, hence this check.
      // TODO maybe try doing it with the stringify thing
      dispatch({
        type: "SET_SELECTED_WELL",
        payload: {
          well: Number(wellId),
        },
      });
    }
  }, [dispatch, selectedWell, wellId]);

  useEffect(() => {
    dispatch({
      type: "SET_FILTERS_WIDGET",
      payload: {
        filters,
        state: true,
      },
    });
    dispatch({
      type: "SET_REPORT_FILTERS",
      payload: {
        filters,
        state: true,
      },
    });
    dispatch({
      type: "SET_INDICATORS_WIDGET",
      payload: {
        indicators: [],
      },
    });
  }, [filters, dispatch]);

  useEffect(() => {
    dispatch({
      type: "SET_LEGEND_ALT_STYLE",
      payload: false,
    });
  }, [dispatch]);

  const refr = useRef<HTMLDivElement>(null);
  const sectionRef = useRef<HTMLDivElement>(null);
  const resetZoomData = useCallback(() => {
    setZoom(initialZoomData);
    dispatch({
      type: "RESET_ZOOM_WIDGET",
    });
    dispatch({
      type: "RESET_REPORT_ZOOM",
    });
    setZoomOpen(false);
  }, [dispatch, setZoom]);

  const setZoomData = useCallback(
    (zoom: IZoomData) => {
      setZoom(zoom);
      if (zoom)
        dispatch({
          type: "SET_REPORT_ZOOM",
          payload: { zoom, state: true },
        });
      setZoomOpen(false);
    },
    [dispatch, setZoom],
  );

  const onWheel = useCallback(() => {
    if (sectionRef.current && refr.current) {
      if (sectionRef.current?.getBoundingClientRect()?.top < 190) {
        refr.current.style.position = "fixed";
        refr.current.style.top = "120px";
        refr.current.style.width = refr.current.parentElement?.clientWidth + "px";
      } else if (refr.current?.style) {
        refr.current.style.position = "relative";
        refr.current.style.top = "0px";
      }
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (refr.current?.style) {
        refr.current.style.width = refr.current.parentElement?.clientWidth + "px";
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [refr.current?.parentElement?.clientWidth]);

  const onChange = useCallback(
    (e: string) => {
      Track.clickEvent(`Change Timeline - ${e}`);
      if (e === "Plan") {
        dispatch({
          type: "SET_SELECTED_SERIES",
          payload: PLAN_SERIES_ID,
        });
      }
      if (e === "Actual") {
        dispatch({
          type: "SET_SELECTED_SERIES",
          payload: wellId,
        });
      }
      dispatch({
        type: "SET_TIMELINE_STATE",
        payload: e as TimelineStates,
      });
      dispatch({
        type: "SET_EDIT_EVENT",
        payload: null,
      });
      dispatch({
        type: "SET_ACTION_ENABLED",
        payload: false,
      });
    },
    [dispatch, wellId],
  );

  return (
    <>
      <CoreLayout
        bgColor={themeColors.primary_bg}
        onWheel={onWheel}
        sidebarColor={themeColors.legend_bg}
        sidebar={
          <SectionLayout
            additionalStyle={{
              zIndex: zIndexLayer.sky_plus,
            }}
            showScrollHints
            header={
              <StyledTabs
                onChange={onChange}
                activeKey={timelineState.toString()}
                type="line"
                size="large"
                items={[
                  { key: "Plan", label: "Plan" },
                  { key: "Actual", label: "Actual" },
                  { key: "Legend", label: "Legend" },
                ]}
              ></StyledTabs>
            }
          >
            <Row gutter={[0, 16]} style={{ height: "100%" }}>
              <Col span={24}>
                <Suspense fallback={<ZeroStateTimeline />}>
                  <Timeline timelineOverride={timelineOverride} setTimelineOverride={setTimelineOverride} />
                </Suspense>
              </Col>
            </Row>
          </SectionLayout>
        }
      >
        <SectionLayout
          useScrollCol
          id="scroll-container-id-fix-tbf"
          header={
            <Section style={{ marginBottom: "14px" }}>
              <Suspense fallback={null}>
                <ControlHeader zoomData={zoomData} setZoomOpen={setZoomOpen} />
              </Suspense>
            </Section>
          }
          additionalStyle={{
            zIndex: zIndexLayer.moon,
          }}
        >
          <Row style={{ overflow: "hidden", width: "100%" }}>
            <Col style={{ width: "100%" }}>
              <Row justify="space-between" style={{ background: themeColors.primary_bg }}>
                <Col style={{ padding: "16px 24px" }}>
                  <Row>
                    <Col>
                      <DrillingDetailsHeader />
                    </Col>
                    {!loadingTvD && <Stats />}
                  </Row>
                </Col>
                <Col>
                  <ChartControls />
                </Col>
              </Row>
              <TvDChart
                isReport={false}
                timelineOverride={timelineOverride}
                setTimelineOverride={setTimelineOverride}
              />
            </Col>
            <Suspense fallback={null}>
              <LensView refr={refr} sectionRef={sectionRef} />
            </Suspense>
          </Row>
        </SectionLayout>
      </CoreLayout>
      <Suspense fallback={null}>
        <Zoom
          visible={zoomOpen}
          onCancel={() => setZoomOpen(false)}
          setZoomData={setZoomData}
          resetZoomData={resetZoomData}
          zoomData={zoomData}
          availableActions={{
            time: MatrixAvailability.AVAILABLE,
            date: MatrixAvailability.AVAILABLE,
            depth: MatrixAvailability.AVAILABLE,
          }}
        />
      </Suspense>
    </>
  );
};

export default Main;
