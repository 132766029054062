/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LensTabCreationQueryDto,
    LensTabCreationQueryDtoFromJSON,
    LensTabCreationQueryDtoToJSON,
    LensTabVisibilityUpdateDto,
    LensTabVisibilityUpdateDtoFromJSON,
    LensTabVisibilityUpdateDtoToJSON,
    UserLensTabDto,
    UserLensTabDtoFromJSON,
    UserLensTabDtoToJSON,
    UserLensTabShareDto,
    UserLensTabShareDtoFromJSON,
    UserLensTabShareDtoToJSON,
} from '../models';

export interface ApiUserLensTabsIdDeleteRequest {
    id: number;
}

export interface ApiUserLensTabsIdLockIsLockedPutRequest {
    id: number;
    isLocked: boolean;
}

export interface ApiUserLensTabsIdPositionNewPositionPutRequest {
    id: number;
    newPosition: number;
}

export interface ApiUserLensTabsIdRenameNewNamePutRequest {
    id: number;
    newName: string;
}

export interface ApiUserLensTabsIdVisibilitiesPutRequest {
    id: number;
    lensTabVisibilityUpdateDto?: LensTabVisibilityUpdateDto;
}

export interface ApiUserLensTabsPostRequest {
    lensTabCreationQueryDto?: LensTabCreationQueryDto;
}

export interface ApiUserLensTabsSharePostRequest {
    userLensTabShareDto?: UserLensTabShareDto;
}

/**
 * 
 */
export class UserLensTabsApi extends runtime.BaseAPI {

    /**
     */
    async apiUserLensTabsIdDeleteRaw(requestParameters: ApiUserLensTabsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUserLensTabsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/UserLensTabs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiUserLensTabsIdDelete(requestParameters: ApiUserLensTabsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.apiUserLensTabsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUserLensTabsIdLockIsLockedPutRaw(requestParameters: ApiUserLensTabsIdLockIsLockedPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUserLensTabsIdLockIsLockedPut.');
        }

        if (requestParameters.isLocked === null || requestParameters.isLocked === undefined) {
            throw new runtime.RequiredError('isLocked','Required parameter requestParameters.isLocked was null or undefined when calling apiUserLensTabsIdLockIsLockedPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/UserLensTabs/{id}/Lock/{isLocked}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"isLocked"}}`, encodeURIComponent(String(requestParameters.isLocked))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiUserLensTabsIdLockIsLockedPut(requestParameters: ApiUserLensTabsIdLockIsLockedPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.apiUserLensTabsIdLockIsLockedPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUserLensTabsIdPositionNewPositionPutRaw(requestParameters: ApiUserLensTabsIdPositionNewPositionPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUserLensTabsIdPositionNewPositionPut.');
        }

        if (requestParameters.newPosition === null || requestParameters.newPosition === undefined) {
            throw new runtime.RequiredError('newPosition','Required parameter requestParameters.newPosition was null or undefined when calling apiUserLensTabsIdPositionNewPositionPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/UserLensTabs/{id}/Position/{newPosition}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"newPosition"}}`, encodeURIComponent(String(requestParameters.newPosition))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiUserLensTabsIdPositionNewPositionPut(requestParameters: ApiUserLensTabsIdPositionNewPositionPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<number> {
        const response = await this.apiUserLensTabsIdPositionNewPositionPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUserLensTabsIdRenameNewNamePutRaw(requestParameters: ApiUserLensTabsIdRenameNewNamePutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUserLensTabsIdRenameNewNamePut.');
        }

        if (requestParameters.newName === null || requestParameters.newName === undefined) {
            throw new runtime.RequiredError('newName','Required parameter requestParameters.newName was null or undefined when calling apiUserLensTabsIdRenameNewNamePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/UserLensTabs/{id}/Rename/{newName}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"newName"}}`, encodeURIComponent(String(requestParameters.newName))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiUserLensTabsIdRenameNewNamePut(requestParameters: ApiUserLensTabsIdRenameNewNamePutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<string> {
        const response = await this.apiUserLensTabsIdRenameNewNamePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUserLensTabsIdVisibilitiesPutRaw(requestParameters: ApiUserLensTabsIdVisibilitiesPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUserLensTabsIdVisibilitiesPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/UserLensTabs/{id}/Visibilities`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LensTabVisibilityUpdateDtoToJSON(requestParameters.lensTabVisibilityUpdateDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiUserLensTabsIdVisibilitiesPut(requestParameters: ApiUserLensTabsIdVisibilitiesPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<number>> {
        const response = await this.apiUserLensTabsIdVisibilitiesPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUserLensTabsPostRaw(requestParameters: ApiUserLensTabsPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserLensTabDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/UserLensTabs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LensTabCreationQueryDtoToJSON(requestParameters.lensTabCreationQueryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserLensTabDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiUserLensTabsPost(requestParameters: ApiUserLensTabsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserLensTabDto> {
        const response = await this.apiUserLensTabsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUserLensTabsSharePostRaw(requestParameters: ApiUserLensTabsSharePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/UserLensTabs/Share`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserLensTabShareDtoToJSON(requestParameters.userLensTabShareDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiUserLensTabsSharePost(requestParameters: ApiUserLensTabsSharePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<number> {
        const response = await this.apiUserLensTabsSharePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
