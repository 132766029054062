import type { AccountRigAccessDto, OperatorClaimDto, UserClaimDto } from "apis/oag";
import { ClaimPermissionType, WellStatusType } from "apis/oag";
import type { RangeType } from "atoms/DatePicker";
import { DatePicker } from "atoms/DatePicker";
import { Button, Input, StyledCheckboxElem } from "atoms/Form";
import { Title } from "atoms/Typography";
import { ActionsHeader, PlanSectionLayout } from "components/Layout/Tabbed";
import { Loader } from "components/Loader";
import { PDComponent } from "components/PDComponents";
import { WellAccessMultiple } from "components/WellAccess";
import Pane from "components/WellEditor/Pane";
import { useAdminSingleAccountWellAccess } from "hooks/admin/useAdminSingleAccountWellAccess";
import { useRigs } from "hooks/useRigs";
import { useRigSort } from "hooks/useRigSort";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch } from "reducers/store";
import { useAppSelector } from "reducers/store";
import styled from "styled-components";
import { Col, Popover, Row, Space, Tooltip } from "utils/componentLibrary";
import { CheckboxState } from "utils/enums";
import { useCustomTheme } from "utils/useTheme";

export const Header = styled(Row)`
  background: ${({ theme }) => theme.themeStyle.colors.primary_chart_bg};
  border-radius: 10px;
  padding: 12px !important;
  margin-bottom: 16px;
`;

export const CheckboxSelector = styled(StyledCheckboxElem)`
  background-color: ${({ theme }) => theme.themeStyle.colors.off_secondary_bg};
  border-radius: 4px;
  padding: 8px 16px;
  border: solid 1px;
  display: inline-flex;
  border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};
`;

const WellAccess = ({
  accountId,
  allClaimsCallback,
}: {
  accountId: number;
  allClaimsCallback: (x: UserClaimDto[] | OperatorClaimDto[]) => void;
}) => {
  const dispatch = useAppDispatch();

  const rigs = useRigs();

  const { atomThemeVariant } = useCustomTheme();

  const accountWellAccess = useAdminSingleAccountWellAccess(accountId);
  const deselectFunc = useRef<(y: boolean) => void>(() => void 0);

  const period = useAppSelector((state) => state.admin.wellAccess.period);
  const rigIdsState = useAppSelector((state) => state.admin.wellAccess.rigs);
  const statusState = useAppSelector((state) => state.admin.wellAccess.wellStatus);
  const searchState = useAppSelector((state) => state.admin.wellAccess.search);

  const [showRigPopup, setShowRigPopup] = useState(false);
  const [showStatusPopup, setShowStatusPopup] = useState(false);

  const [quickSearchValue, setQuickSearchValue] = useState<string>(searchState || "");
  const [commonFuture, setCommonFuture] = useState<boolean | null>(null);

  const [hasClaims, setHasClaims] = useState(false);
  const [selected, setSelected] = useState(CheckboxState.None);
  const [futureWellSelected, setFutureWellSelected] = useState(CheckboxState.None);
  const [futureRigSelected, setFutureRigSelected] = useState(accountWellAccess.data?.futureRigAccess);
  const [claims, setClaims] = useState<UserClaimDto[] | OperatorClaimDto[]>([]);
  const [futureRigs, setFutureRigs] = useState<AccountRigAccessDto | null>(null);
  useEffect(() => {
    if (!accountWellAccess.isLoading && futureRigSelected === null) {
      const futureRig = accountWellAccess?.data?.wellAccess?.find(
        (e) => (e as unknown as OperatorClaimDto).type === "FutureRigAccess",
      );
      if (futureRig) setFutureRigs(futureRig);
    }
  }, [accountWellAccess, futureRigSelected]);

  useEffect(() => {
    setFutureRigSelected(accountWellAccess.data?.futureRigAccess);
  }, [accountWellAccess.data?.futureRigAccess]);

  const sortByRigs = useRigSort();

  useEffect(() => {
    const futureAccess = claims.filter((claim) => claim?.type === "FutureWellAccess");
    if (futureAccess.length > 0 && futureAccess.every((e) => e?.permission === "All")) {
      setFutureWellSelected(CheckboxState.Selected);
    } else if (futureAccess.length > 0 && futureAccess.some((e) => e?.permission === "All"))
      setFutureWellSelected(CheckboxState.Partially);
    else {
      setFutureWellSelected(CheckboxState.None);
    }

    if (claims.length > 0 && claims.every((e) => e.permission === "All")) setSelected(CheckboxState.Selected);
    else if (claims.length > 0 && claims.some((e) => e.permission === "All")) setSelected(CheckboxState.Partially);
    else setSelected(CheckboxState.None);
    if (futureRigSelected)
      allClaimsCallback(
        [
          ...claims.filter((e) => e.type !== "FutureRigAccess"),
          futureRigs === null
            ? {
                type: "FutureRigAccess",
                permission: "All",
                operatorId: accountId,
              }
            : futureRigs,
        ].filter((x): x is OperatorClaimDto => x !== null),
      );
    else {
      const filteredClaims = [...claims.filter((e) => e.type !== "FutureRigAccess")];
      allClaimsCallback(filteredClaims as UserClaimDto[] | OperatorClaimDto[]);
    }
  }, [accountId, allClaimsCallback, claims, futureRigSelected, futureRigs]);

  const updateTimeRange = (period: RangeType) => {
    dispatch({
      type: "ADMIN_WELL_ACCESS_SET_TIME_RANGE",
      payload: {
        period,
      },
    });
    return true;
  };

  const updateRigs = useCallback(
    (rigIds: number[]) => {
      dispatch({
        type: "ADMIN_WELL_ACCESS_SET_RIGS",
        payload: {
          rigs: rigIds,
        },
      });
      return true;
    },
    [dispatch],
  );

  const updateStatuses = (f: WellStatusType[]) => {
    setShowStatusPopup(false);
    dispatch({
      type: "ADMIN_WELL_ACCESS_SET_WELL_STATUS",
      payload: {
        wellStatus: f,
      },
    });
    return true;
  };

  const updateSearch = () => {
    dispatch({
      type: "ADMIN_WELL_ACCESS_SET_SEARCH",
      payload: {
        search: quickSearchValue,
      },
    });
    return true;
  };

  const claimsPassthrough = useCallback(
    (x: UserClaimDto[] | OperatorClaimDto[]) => {
      setClaims(x);
      allClaimsCallback(x);
      setHasClaims((x as Array<UserClaimDto>).filter((e) => e.permission !== ClaimPermissionType.None).length > 0);
    },
    [allClaimsCallback],
  );

  useEffect(() => {
    if (rigs.data) {
      updateRigs(rigs.data.list.map((e) => e.id));
    }
  }, [rigs.data, updateRigs]);

  return (
    <PlanSectionLayout
      header={
        <ActionsHeader>
          <Row gutter={12} justify="space-between" align="middle" style={{ height: "64px" }}>
            <Col flex="0 auto">
              <Space>
                <Space direction="horizontal" size={4}>
                  <Input
                    allowClear
                    placeholder="Search by Well name"
                    style={{ width: "300px" }}
                    value={quickSearchValue}
                    onChange={(e) => setQuickSearchValue(e.target.value)}
                  />
                  <Button
                    size="large"
                    type="primary"
                    icon={<PDComponent.SvgIcon name="search" />}
                    onClick={updateSearch}
                  />
                </Space>

                <PDComponent.VerticalDivider />

                <Tooltip title="Time Range">
                  <DatePicker allowAllDates selection={period} onApply={updateTimeRange} />
                </Tooltip>

                <Tooltip title="Rigs">
                  <Popover
                    content={
                      <PDComponent.ComboBoxMultiSelect
                        placeholder="Search Rigs"
                        options={(rigs.data?.list ?? []).map((e) => ({ id: e.id, name: e.shortName || "" }))}
                        values={rigIdsState ?? []}
                        onChange={(rigIds: number[]) => {
                          updateRigs(rigIds);
                          setShowRigPopup(false);
                          return void 0;
                        }}
                      />
                    }
                    trigger="click"
                    placement="bottom"
                    open={showRigPopup}
                    onOpenChange={(e) => setShowRigPopup(e)}
                    destroyTooltipOnHide
                  >
                    <Button
                      size="large"
                      icon={<PDComponent.SvgIcon name="rig" />}
                      type={
                        rigIdsState === null || rigIdsState?.length !== rigs.data?.list.length ? "primary" : "default"
                      }
                      ghost={rigIdsState ? rigIdsState?.length !== rigs.data?.list.length : false}
                      $engaged={showRigPopup}
                    />
                  </Popover>
                </Tooltip>

                <Tooltip title="Well Status">
                  <Popover
                    content={
                      <PDComponent.Pickoff
                        options={Object.values(WellStatusType).map((e) => ({ id: e, name: e }))}
                        values={statusState || []}
                        onChange={(e) => updateStatuses(e)}
                      />
                    }
                    trigger="click"
                    placement="bottom"
                    open={showStatusPopup}
                    onOpenChange={(e) => setShowStatusPopup(e)}
                    destroyTooltipOnHide
                  >
                    <Button
                      icon={<PDComponent.SvgIcon name="checkmarkOutline" />}
                      type={statusState?.length !== Object.keys(WellStatusType).length ? "primary" : "default"}
                      ghost={statusState?.length !== Object.keys(WellStatusType).length}
                      $engaged={showStatusPopup}
                    />
                  </Popover>
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </ActionsHeader>
      }
      content={
        <>
          {accountWellAccess.isLoading ? (
            <Loader centered />
          ) : (
            <>
              <Header justify="space-between" align="middle">
                <Col flex="0 auto">
                  <CheckboxSelector
                    checked={selected === CheckboxState.Selected}
                    indeterminate={selected === CheckboxState.Partially}
                    onChange={() => {
                      deselectFunc.current(!hasClaims);
                    }}
                  >
                    <Title level={3} variant={atomThemeVariant}>
                      {selected === CheckboxState.Selected ? "Deselect" : "Select"} All
                    </Title>
                  </CheckboxSelector>
                </Col>
                <Col flex="0 auto">
                  <Row gutter={8}>
                    <Col flex="0 auto">
                      <CheckboxSelector
                        checked={!!futureRigSelected}
                        onChange={() => {
                          setFutureRigSelected(!futureRigSelected);
                        }}
                      >
                        <Title level={3} variant={atomThemeVariant}>
                          Future Rigs
                        </Title>
                      </CheckboxSelector>
                    </Col>
                    <Col flex="0 auto">
                      <CheckboxSelector
                        disabled={
                          accountWellAccess.data?.wellAccess?.length === 0 ||
                          futureWellSelected === CheckboxState.Disabled
                        }
                        checked={futureWellSelected === CheckboxState.Selected}
                        indeterminate={futureWellSelected === CheckboxState.Partially}
                        onChange={() => {
                          if (futureWellSelected !== CheckboxState.Selected) {
                            setFutureWellSelected(CheckboxState.Selected);
                            setCommonFuture(true);
                          } else {
                            setFutureWellSelected(CheckboxState.None);
                            setCommonFuture(false);
                          }
                        }}
                      >
                        <Title
                          level={3}
                          variant={accountWellAccess.data?.wellAccess?.length === 0 ? "faded" : atomThemeVariant}
                        >
                          Future Wells
                        </Title>
                      </CheckboxSelector>
                    </Col>
                  </Row>
                </Col>
              </Header>
              <WellAccessMultiple
                selector={accountId}
                mode="operator"
                commonFuture={!!commonFuture}
                allRigs={accountWellAccess?.data?.wellAccess?.sort((a, b) => sortByRigs(a.rigId, b.rigId)) || []}
                allClaimsCallback={claimsPassthrough}
                deselectBack={(f) => (deselectFunc.current = f)}
              />
            </>
          )}
          {!accountWellAccess.isLoading && accountWellAccess.data?.wellAccess?.length === 0 && (
            <Pane>
              <Title level={3} variant={atomThemeVariant}>
                No Wells &amp; Rigs to show.
              </Title>
            </Pane>
          )}
        </>
      }
    />
  );
};

export default WellAccess;
