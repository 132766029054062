/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateDto,
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import {
    OperationCategoryType,
    OperationCategoryTypeFromJSON,
    OperationCategoryTypeFromJSONTyped,
    OperationCategoryTypeToJSON,
} from './OperationCategoryType';

/**
 * 
 * @export
 * @interface WellDrillingDetailsDto
 */
export interface WellDrillingDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    wellId: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    cumulativeDuration: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    cumulativeDurationActiveOnly: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    lastHoleDepth: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    maxHoleDepth: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    lastBitDepth: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    drillingDistance: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    numberOfStrings: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    weightToWeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    weightToWeightCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    slipToSlip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    slipToSlipCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    drillingDuration: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    trippingPipeTravelDistance?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    trippingRunningSpeed?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WellDrillingDetailsDto
     */
    hasZTorque: boolean;
    /**
     * 
     * @type {OperationCategoryType}
     * @memberof WellDrillingDetailsDto
     */
    lastOperationCategory: OperationCategoryType;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDrillingDetailsDto
     */
    spudDateTime: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDrillingDetailsDto
     */
    lastWellFactUpdateAt: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDrillingDetailsDto
     */
    lastTvdFactUpdateAt: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDrillingDetailsDto
     */
    lastStandKpiFactUpdateAt: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDrillingDetailsDto
     */
    lastRigStateFactUpdateAt: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDrillingDetailsDto
     */
    lastDepthFactUpdateAt: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDrillingDetailsDto
     */
    lastActiveRigStateFactAt: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDrillingDetailsDto
     */
    lastBatteryStateFactUpdateAt: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDrillingDetailsDto
     */
    lastRigPowerFactUpdateAt: DateDto;
}

export function WellDrillingDetailsDtoFromJSON(json: any): WellDrillingDetailsDto {
    return WellDrillingDetailsDtoFromJSONTyped(json, false);
}

export function WellDrillingDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WellDrillingDetailsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'wellId': json['WellId'],
        'cumulativeDuration': json['CumulativeDuration'],
        'cumulativeDurationActiveOnly': json['CumulativeDurationActiveOnly'],
        'lastHoleDepth': json['LastHoleDepth'],
        'maxHoleDepth': json['MaxHoleDepth'],
        'lastBitDepth': json['LastBitDepth'],
        'drillingDistance': json['DrillingDistance'],
        'numberOfStrings': json['NumberOfStrings'],
        'weightToWeight': !exists(json, 'WeightToWeight') ? undefined : json['WeightToWeight'],
        'weightToWeightCount': !exists(json, 'WeightToWeightCount') ? undefined : json['WeightToWeightCount'],
        'slipToSlip': !exists(json, 'SlipToSlip') ? undefined : json['SlipToSlip'],
        'slipToSlipCount': !exists(json, 'SlipToSlipCount') ? undefined : json['SlipToSlipCount'],
        'drillingDuration': json['DrillingDuration'],
        'trippingPipeTravelDistance': !exists(json, 'TrippingPipeTravelDistance') ? undefined : json['TrippingPipeTravelDistance'],
        'trippingRunningSpeed': !exists(json, 'TrippingRunningSpeed') ? undefined : json['TrippingRunningSpeed'],
        'hasZTorque': json['HasZTorque'],
        'lastOperationCategory': OperationCategoryTypeFromJSON(json['LastOperationCategory']),
        'spudDateTime': DateDtoFromJSON(json['SpudDateTime']),
        'lastWellFactUpdateAt': DateDtoFromJSON(json['LastWellFactUpdateAt']),
        'lastTvdFactUpdateAt': DateDtoFromJSON(json['LastTvdFactUpdateAt']),
        'lastStandKpiFactUpdateAt': DateDtoFromJSON(json['LastStandKpiFactUpdateAt']),
        'lastRigStateFactUpdateAt': DateDtoFromJSON(json['LastRigStateFactUpdateAt']),
        'lastDepthFactUpdateAt': DateDtoFromJSON(json['LastDepthFactUpdateAt']),
        'lastActiveRigStateFactAt': DateDtoFromJSON(json['LastActiveRigStateFactAt']),
        'lastBatteryStateFactUpdateAt': DateDtoFromJSON(json['LastBatteryStateFactUpdateAt']),
        'lastRigPowerFactUpdateAt': DateDtoFromJSON(json['LastRigPowerFactUpdateAt']),
    };
}

export function WellDrillingDetailsDtoToJSON(value?: WellDrillingDetailsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'WellId': value.wellId,
        'CumulativeDuration': value.cumulativeDuration,
        'CumulativeDurationActiveOnly': value.cumulativeDurationActiveOnly,
        'LastHoleDepth': value.lastHoleDepth,
        'MaxHoleDepth': value.maxHoleDepth,
        'LastBitDepth': value.lastBitDepth,
        'DrillingDistance': value.drillingDistance,
        'NumberOfStrings': value.numberOfStrings,
        'WeightToWeight': value.weightToWeight,
        'WeightToWeightCount': value.weightToWeightCount,
        'SlipToSlip': value.slipToSlip,
        'SlipToSlipCount': value.slipToSlipCount,
        'DrillingDuration': value.drillingDuration,
        'TrippingPipeTravelDistance': value.trippingPipeTravelDistance,
        'TrippingRunningSpeed': value.trippingRunningSpeed,
        'HasZTorque': value.hasZTorque,
        'LastOperationCategory': OperationCategoryTypeToJSON(value.lastOperationCategory),
        'SpudDateTime': DateDtoToJSON(value.spudDateTime),
        'LastWellFactUpdateAt': DateDtoToJSON(value.lastWellFactUpdateAt),
        'LastTvdFactUpdateAt': DateDtoToJSON(value.lastTvdFactUpdateAt),
        'LastStandKpiFactUpdateAt': DateDtoToJSON(value.lastStandKpiFactUpdateAt),
        'LastRigStateFactUpdateAt': DateDtoToJSON(value.lastRigStateFactUpdateAt),
        'LastDepthFactUpdateAt': DateDtoToJSON(value.lastDepthFactUpdateAt),
        'LastActiveRigStateFactAt': DateDtoToJSON(value.lastActiveRigStateFactAt),
        'LastBatteryStateFactUpdateAt': DateDtoToJSON(value.lastBatteryStateFactUpdateAt),
        'LastRigPowerFactUpdateAt': DateDtoToJSON(value.lastRigPowerFactUpdateAt),
    };
}

