/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RigCardFactSetDto,
    RigCardFactSetDtoFromJSON,
    RigCardFactSetDtoFromJSONTyped,
    RigCardFactSetDtoToJSON,
} from './RigCardFactSetDto';
import {
    ScorecardKpiCommentInfoDto,
    ScorecardKpiCommentInfoDtoFromJSON,
    ScorecardKpiCommentInfoDtoFromJSONTyped,
    ScorecardKpiCommentInfoDtoToJSON,
} from './ScorecardKpiCommentInfoDto';

/**
 * 
 * @export
 * @interface WellKpiCommentDetailsDto
 */
export interface WellKpiCommentDetailsDto {
    /**
     * 
     * @type {ScorecardKpiCommentInfoDto}
     * @memberof WellKpiCommentDetailsDto
     */
    comment: ScorecardKpiCommentInfoDto;
    /**
     * 
     * @type {RigCardFactSetDto}
     * @memberof WellKpiCommentDetailsDto
     */
    factSet: RigCardFactSetDto;
}

export function WellKpiCommentDetailsDtoFromJSON(json: any): WellKpiCommentDetailsDto {
    return WellKpiCommentDetailsDtoFromJSONTyped(json, false);
}

export function WellKpiCommentDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WellKpiCommentDetailsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comment': ScorecardKpiCommentInfoDtoFromJSON(json['Comment']),
        'factSet': RigCardFactSetDtoFromJSON(json['FactSet']),
    };
}

export function WellKpiCommentDetailsDtoToJSON(value?: WellKpiCommentDetailsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Comment': ScorecardKpiCommentInfoDtoToJSON(value.comment),
        'FactSet': RigCardFactSetDtoToJSON(value.factSet),
    };
}

