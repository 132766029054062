/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlanActivityDto
 */
export interface PlanActivityDto {
    /**
     * 
     * @type {number}
     * @memberof PlanActivityDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof PlanActivityDto
     */
    position: number;
    /**
     * 
     * @type {number}
     * @memberof PlanActivityDto
     */
    phaseId: number;
    /**
     * 
     * @type {number}
     * @memberof PlanActivityDto
     */
    duration: number;
    /**
     * 
     * @type {string}
     * @memberof PlanActivityDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PlanActivityDto
     */
    endHoleDepth: number;
    /**
     * 
     * @type {number}
     * @memberof PlanActivityDto
     */
    startHoleDepth: number;
    /**
     * 
     * @type {number}
     * @memberof PlanActivityDto
     */
    startCumulativeDuration: number;
    /**
     * 
     * @type {number}
     * @memberof PlanActivityDto
     */
    endCumulativeDuration: number;
}

export function PlanActivityDtoFromJSON(json: any): PlanActivityDto {
    return PlanActivityDtoFromJSONTyped(json, false);
}

export function PlanActivityDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanActivityDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'position': json['Position'],
        'phaseId': json['PhaseId'],
        'duration': json['Duration'],
        'description': !exists(json, 'Description') ? undefined : json['Description'],
        'endHoleDepth': json['EndHoleDepth'],
        'startHoleDepth': json['StartHoleDepth'],
        'startCumulativeDuration': json['StartCumulativeDuration'],
        'endCumulativeDuration': json['EndCumulativeDuration'],
    };
}

export function PlanActivityDtoToJSON(value?: PlanActivityDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'Position': value.position,
        'PhaseId': value.phaseId,
        'Duration': value.duration,
        'Description': value.description,
        'EndHoleDepth': value.endHoleDepth,
        'StartHoleDepth': value.startHoleDepth,
        'StartCumulativeDuration': value.startCumulativeDuration,
        'EndCumulativeDuration': value.endCumulativeDuration,
    };
}

